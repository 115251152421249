<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date</th>
              <th>Account</th>
              <th>Name <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('regName')"/></th>
              <th>Number</th>
              <th>Type</th>
              
              <th>Card Status</th>              
            </tr>
            <tr v-for="card in filteredData" :key="card.id">
              <td><span>Account Date</span>{{ card.cardCreatedAt | date('MM/D/YYYY') }}</td>
              <td>
                <span>Account</span>
                <a v-if="!partner" :href="`/system-customers/registrations/${card.regId}`">{{ card.accountNumber }}</a>
                <template v-else>{{ card.accountNumber }}</template>
              </td>
              <td>
                <span>Name</span>
                {{ card.regName }}
              </td>
              <td><span>Account ID</span>
                {{ card.cardLast4 }}
              </td>
              <td><span>Account Type</span>
                <template v-if="card.cardType">
                  {{ card.cardType.charAt(0).toUpperCase() }}{{ card.cardType.slice(1) }}
                </template>
              </td>             
              <td><span>Account Status</span><em v-if="card" :class="statusColorClasses[card.cardStatus]">
                {{ getStatus(card.cardStatus) }}</em></td>              
            </tr>
            <tr v-if="!cards.length">
              <td colspan="9" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>

    <card-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import CardFilters from './filters/CardFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'CustomerCards',

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    CardFilters,
    AppliedFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,

    activeInfo: true,

    cardTypes: {
      physical: 'Physical',
      virtual: 'Virtual'
    },

    sortName: null,
    sortFlag: true,

    loading: false,
    page: 1,
    perPage: 25,
    pages: [],
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        number: 'Card Number',
        name: 'Name',
        type: 'Card Type',
        status: 'Card Status'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    accountStatuses () {
      return window.accountStatuses
    },

    profileStatuses () {
      return window.profileStatuses
    },

    accountStatusesFiltered () {
      const array = [...window.accountStatuses]
      array.splice(0, 2)
      return array
    },

    totalNumber() {
      return this.cards.length
    },

    cards () {
      let cards = this.$store.state.cards.data || []
      if (this.filters) {
        if (this.filters.name) {
          cards = cards.filter(card => {
            return card.regName.toLowerCase().includes(this.filters.name.toLowerCase())
          })
        }

        if (this.filters ?.status) {
          cards = cards.filter(card => card.cardStatus === this.filters.status)
        }

        if (this.filters.number) {
          cards = cards.filter(card => {
            return card.cardLast4.includes(this.filters.number)
          })
        }

        if (this.filters.type) {
          cards = cards.filter(card => card.cardType === this.filters.type)
        }
      }

      if(this.sortName != null) {
        let aName, bName
        if(this.sortName == 'regName'){
          if(this.sortFlag) {
            cards.sort((a, b) => {
              if (a.regName > b.regName) return 1
              if (a.regName < b.regName) return -1
              return 0
            })
          }else{
            cards.sort((a, b) => {
              if (a.regName > b.regName) return -1
              if (a.regName < b.regName) return 1
              return 0
            })
          }
        }
      }
      return cards
    },

    statusColorClasses () {
      return {
        active: 'c1',
        pendingActivation: 'c2',
        canceled: 'c3',
        inactive: 'c3',
      }
    },

    currentUser () {
      return window.appData.currentUser
    },

    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    filteredData () {
      return this.paginate(this.cards);
    }
  },

  async created () {
    if(this.currentUser.parId) {
      await this.$store.dispatch('partners/get', this.currentUser.parId)
    }
    await this.refreshCards();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },
    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }
      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },
 
    getStatus (status) {
      const names = {
        pendingActivation: 'Pending Activation',
        Active: 'Open'
      }
      return names[status] || status?.charAt(0).toUpperCase() + status?.slice(1)
    },
    getFilterValue (key, value) {
      if (key === 'status') return this.getStatus(value)
      if (key === 'type') return this.cardTypes[value]
      
      return value
    },

//     getStatus (status) {
//       return window.cardStatuses[status]
//     },
// 
    sortTemplateName(sortName) {
      if(this.sortName == sortName){
        this.sortFlag = !this.sortFlag
      }else {
        this.sortName = sortName
        this.sortFlag = true
      }
    },

    async refreshCards () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.cardStatus = ['!=',  null];

      if(this.partner) {
        const accounts = await this.$store.dispatch('accounts/load',{ params: { partnerId: this.partner.id }})
        params.accountId=['OR']
        accounts?.forEach(account => {
          params.accountId.push(account.id)
        })
        if(params.accountId == ['OR']) {
          params.accountId=null
        }
      }

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0');
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        params.cardCreatedAt=[">=", yesterdayDate+" 00:00:00"];
      } else {
        if (this.filters.fromDate)
          params.cardCreatedAtAfter=[">=", this.filters.fromDate+" 00:00:00", "cardCreatedAt"];
        if (this.filters.toDate)
          params.cardCreatedAtBefore=["<=", this.filters.toDate+" 23:59:59", "cardCreatedAt"];
      }

      await this.$store.dispatch('cards/load', { order: ['cardCreatedAt:DESC'], params})
      this.loading=false;
      this.page = 1
      loader.hide()
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },
  },

  watch: {
    appliedFilters () {
      this.refreshCards();
    },

    filteredData () {
      this.setPages();
    },

    page() {
      this.setPages();
    },

    cards () {
      this.setPages();
    },

    perPage() {
      if(this.page == 1){
        this.setPages();
      }else{
        this.page = 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>

