<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>New Fee Plan</h3>
    </div>
    <div class="scrollable">
      <div class="name-type-div" v-if="!nextFlag">
        <div class="content-form">
          <div class="form-row justify-content-center">
            <form-field-text v-model="form.name" label="Enter New Plan Name" :validator="$v.form.name" required />
          </div>
        </div>
        <div class="type-div">
          <div>
            <multi-select-dropdown :options="accountTypesOptions" v-model="form.accountTypes" :validator="$v.form.accountTypes" :isMulti="true" placeholder="Account Types"/>
          </div>
        </div>
      </div>
      <div v-else>
        <table class="content-table content-table-split">
          <tr>
            <th style="width: 15%;">Fee Name</th>
            <th style="width: 10%;">Fee</th>
            <th style="width: 10%;">Deposit</th>
            <th style="width: 10%;">Withdrawal</th>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Volume Fee
            </td>
            <td>
              <span>Fee</span>
              <input
                v-model="feeSchedule.volumeFee.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.volumeFee.fee"
              />%
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Monthly Access Fee
            </td>
            <td>
              <span>Fee</span>
              $ <input
                v-model="feeSchedule.monthlyAccessFee.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.monthlyAccessFee.fee"
              />
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Domestic Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.domesticWire.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.domesticWire.per_deposit"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.domesticWire.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.domesticWire.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              International Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.internationalWire.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.internationalWire.per_deposit"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.internationalWire.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.internationalWire.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              ACH
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.ach.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.ach.per_deposit"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.ach.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.ach.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Account Transfer
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.accountTransfer.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.accountTransfer.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Checks
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.checks.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.checks.per_deposit"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.checks.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.checks.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Exchange
            </td>
            <td>
              <span>Fee</span>
              $ <input
                v-model="feeSchedule.exchange.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.exchange.fee"
              />
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Assets
            </td>
            <td>
              <span>Fee</span>
              $ <input
                v-model="feeSchedule.assets.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.fee"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.assets.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.per_deposit"
              />
            </td>
            <td>
              $ <input
                v-model="feeSchedule.assets.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.per_withdrawal"
              />
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Dormant Accounts
            </td>
            <td>
              <span>Fee</span>
              $ <input
                v-model="feeSchedule.dormantAccounts.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.dormantAccounts.fee"
              />
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Debit Card
            </td>
            <td>
              <span>Fee</span>
              $ <input
                v-model="feeSchedule.debitCard.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.debitCard.fee"
              />
            </td>
            <td>
              
            </td>
            <td>
              $ <input
                v-model="feeSchedule.debitCard.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.debitCard.per_withdrawal"
              />
            </td>
          </tr>
        </table>
      </div>
      <div v-if="!nextFlag" class="buttons text-center">
        <button class="main" @click="nextFlag = true" :disabled="$v.form.$invalid">Next</button>
        <button class="second" @click="open = false">Cancel</button>
      </div>
      <div v-else class="buttons text-center">
        <button class="main" @click="save()" :disabled="this.$v.feeSchedule.$invalid">Save</button>
        <button class="second" @click="open = false">Close</button>
      </div>
    </div>
  </popup>
</template>

<script>
import { required, numeric, email, url, requiredIf, maxLength, between } from 'vuelidate/lib/validators'

import MultiSelectDropdown from '../../components/MultiselectDropdown'

export default {

  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
    MultiSelectDropdown,
  },

  data() {
    return {
      form: {
        name: null,
        accountTypes: null,
      },
      nextFlag: false,
      open: true,
      accountTypesOptions :[
        {
          label : 'Personal',
          value : 'Personal'
        },
        {
          label : 'Business',
          value : 'Business'
        }
      ],

      feeSchedule : {
        volumeFee : {
          text: 'Volume Fee',
          fee: null 
        },
        monthlyAccessFee: { 
          text: 'Monthly Access Fee',
          fee: null 
        },
        domesticWire: { 
          text: 'Domestic Wire',
          per_deposit: null, 
          per_withdrawal: null, 
        },
        internationalWire: { 
          text: 'International Wire',
          per_deposit: null, 
          per_withdrawal: null, 
        },
        ach: { 
          text: 'ACH',
          per_deposit: null, 
          per_withdrawal: null, 
        },
        accountTransfer: { 
          text: 'Account Transfer',
          per_withdrawal: null, 
        },
        checks: { 
          text: 'Checks',
          per_deposit: null, 
          per_withdrawal: null, 
        },
        exchange: { 
          text: 'Exchange',
          fee: null, 
        },
        assets: { 
          text: 'Assets',
          fee: null, 
          per_deposit: null, 
          per_withdrawal: null, 
        },
        dormantAccounts: { 
          text: 'Dormant Accounts',
          fee: null, 
        },
        debitCard: { 
          text: 'Debit Card',
          fee: null, 
          per_withdrawal: null, 
        },
      }
    }
  },

  computed: {
    
  },

  async created () {
    await this.$store.dispatch('fee_schedule/load')
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    getFeeName(name) {
      const names = {
          'Domestic Wire Transfer': 'Domestic Wire',
          'International Wire Transfer': 'International Wire',
          'ACH Transfer': 'ACH',
          'Account to Account Transfer': 'Account Transfer',
          'Exchange Transactions': 'Exchange',
        }
        return names[name] || name
    },

    async save() {
      this.$v.$touch()
      if (this.$v.form.$invalid || this.$v.feeSchedule.$invalid) {
        return
      }
      const loader = this.$loading.show()
      let payload;
      payload = {
        name: this.form.name,
        accountTypes: this.form.accountTypes,
        feeSchedule: this.feeSchedule,
      }
      await this.$store.dispatch('fee_plan/create', payload)
      this.open = false
      loader.hide()
    },
  },

  validations () {
    let rules = {
      form: {
        name : { required },
        accountTypes : { required },
      },
      feeSchedule: {
        volumeFee : { 
          fee: { required }
        },
        monthlyAccessFee : { 
          fee: { required }
        },
        domesticWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        internationalWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        ach : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        accountTransfer : { 
          per_withdrawal: { required }
        },
        checks : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        exchange : { 
          fee: { required },
        },
        assets : { 
          fee: { required },
          per_deposit: { required },
          per_withdrawal: { required }
        },
        dormantAccounts : { 
          fee: { required },
        },
        debitCard : { 
          fee: { required },
          per_withdrawal: { required }
        },
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .fee-input {
    max-width: 50px;
  }
  .fee-input::-webkit-inner-spin-button,
  .fee-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .name-type-div {
    display: flex;
    justify-content: center;
  }

  .type-div {
    padding-top: 15px;
    width: 200px;
    margin-bottom: 65px;
    margin-left: 30px;
  }
</style>