import Vue from 'vue'
import Vuex from 'vuex'
import usermgmt from '../usermgmt/store'
import system from '../monbi/system/store'
import customers from '../monbi/customers/store'
import settings from '../monbi/settings/store'
import transactions from '../monbi/transactions/store'
import statements from '../monbi/accounting/store'
import tools from '../monbi/tools/store'
import compliance from '../monbi/compliance/store'
import support from '../monbi/support/store'
import partners from '../monbi/partners/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...system,
    ...usermgmt,
    ...customers,
    ...settings,
    ...transactions,
    ...statements,
    ...tools,
    ...compliance,
    ...support,
    ...partners,
  }
})