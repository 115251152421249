<template>
  <section style="margin: 30px 0;">
    <h2>Services</h2>

    <table class="content-table">
      <tr>
        <th>Account</th>
        <th>Compliance</th>
        <th>ACH/EFT</th>
        <th>Acct/Acct</th>
        <th>Checks</th>
        <th>Dom Wires</th>
        <th>Intl Wires</th>
        <th>Assets</th>
        <th>Multi-Currency</th>
        <th>Debit Cards</th>
        <th v-if="$hasPerm('update-profile')">
          <!--Options-->
        </th>
      </tr>
      <tr>
        <td><span>Account</span>
          <mnb-select v-model="form.provider" :options="providerOptions" :disabled="!updateServices"/>
        </td>
         <td><span>Compliance</span>
          <template v-if="provider">
            {{ provider.kyc == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>ACH</span>
          <template v-if="provider">
            {{ provider.ach == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>ACH</span>
          <template v-if="provider">
            {{ provider.acct == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Checks</span>
          <template v-if="provider">
            {{ provider.checks == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="provider">
            {{ provider.domWires == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="provider">
            {{ provider.intlWires == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Assets</span>
          <template v-if="provider">
            {{ provider.assets == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Multi-Currency</span>
          <template v-if="provider">
            {{ provider.multiCurrency == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Debit Cards</span>
          <template v-if="provider">
            {{ provider.debitCards == 1 ? 'Enabled' : 'Disabled' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td v-if="$hasPerm('update-profile')"><span>Options</span>
          <!--<button v-if="updateServices" class="main" :disabled="$v.form.$invalid" @click="save()">Save</button>
          <button v-else class="second" @click="update()">Update</button>-->
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import MnbSelect from '../../components/ui/forms/Select'

export default {
  components: {
    MnbSelect
  },

  props: {
    registration: {
      type: Object,
      required: true
    }
  },

    data () {
    return {
      form: {
        provider: null
      },
      updateServices: false
    }
  },
  
  computed: {
    serviceProviders () {
      return this.registration?.serviceProviders || null
    },
    
    provider () {
      return this.providers.find(provider => provider.id === this.form.provider)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.kyc).map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    }
  },

  created () {
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    this.form.provider = this.serviceProviders?.provider?.id
  },

  methods: {
    async update () {
      this.updateServices = true
    },

    async save () {
      if (this.$v.form.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('reg_service_providers/update', {
          id: this.serviceProviders?.id,
          payload: this.form
        })
        await this.setLastUpdatedAt()
        this.updateServices = false
        this.$toast.success('Services successfully updated.')
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        loader.hide()
      }
    },

    setLastUpdatedAt () {
      return Promise.all([
        this.$store.dispatch('registrations/update', { id: this.registration.id, payload: { updatedAt: 'CURRENT_TIMESTAMP' } }),
        this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: this.registration.id,
          notes: 'Profile Services updated',
          createdAt: 'CURRENT_TIMESTAMP'
        })
      ])
    }
  },

  validations: {
    form: {
      provider: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 20px; 
    color: #0075be; 
    padding: 10px;
  }
</style>