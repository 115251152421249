<template>
  <div class="cp-box" v-if="registration">
    <ul class="sub-tabs">
      <li :class="{ active: activeTab === 'compliance' }" @click="activeTab = 'compliance'"><a>Profile Review</a></li>
      <li :class="{ active: activeTab === 'account_status' }" @click="activeTab = 'account_status'"><a>Profile Status</a></li>
      <li v-if="featureStatus" :class="{ active: activeTab === 'scans' }" @click="activeTab = 'scans'"><a>Scans</a></li>
    </ul>
    <hr>
    <compliance-settings v-if="activeTab === 'compliance'" :registration="registration" />
    <compliance-scans v-if="activeTab === 'scans'" :registration="registration" />
    <compliance-account-status v-if="activeTab === 'account_status'" :registration="registration" />
  </div>  
</template>

<script>
import ComplianceScans from './ComplianceScans'
import ComplianceSettings from './ComplianceSettings'
import ComplianceAccountStatus from './ComplianceAccountStatus'

export default {
  components: {
    ComplianceScans,
    ComplianceSettings,
    ComplianceAccountStatus
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'compliance',
      featureStatus : window.systemFeatures['Ongoing Compliance']
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  }
}
</script>
