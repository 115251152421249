<template>
<div>
  <section>
    <div class="flex-between">
      <h2 class="inner-headers"></h2>

      <div style="padding: 24px 30px 10px 10px;">
        <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
      </div>
    </div>
  </section>

  <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />
  
  <div v-if="perms" class="accordion">
    <div :class="{'cof-box-active' : activeInfo}">
      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Date</th>
            <th>Number</th>
            <th>Start</th>
            <th>End</th>
            <th>Account</th>
            <th>Name</th>
            <th>Type</th>
            <th>Filename</th>
            <th>Options</th>
          </tr>
          <tr v-for="statement in sortStatements" :key="statement.id">
            <td><span>Statement Date</span>
              {{ statement.createdAt | date('MM/D/YYYY') }}
            </td>
            <td><span>Number</span>
              {{ statement.number }}
            </td>
            <td><span>Start Date</span>
              {{ statement.start | date('MM/D/YYYY') }}
            </td>
            <td><span>End Date</span>
              {{ statement.end | date('MM/D/YYYY') }}
            </td>
            <td><span>Account</span>
              {{ statement.accountId }}
            </td>
            <td><span>Account Name</span>
              {{ statement.account ? statement.account.accountName : null }}
            </td>
            <td><span>Account Type</span>
              {{ statement.account? statement.account.accountType : null }}
            </td>
            <td><span>Filename</span>
              <template v-if="statement.file">
                <a :href="`/download/Statement/${statement.id}`" target="_blank">
                  {{ statement.file }}
                </a>
              </template>
            </td>
            <td><span>Options</span>
            </td>
          </tr>
        </table>
      </section>
    </div>
  </div>
  <section class="popup" :class="{open: !open}">
    <div class="pop-inner" :style="{ maxWidth: `400px` }">
      <div class="pop-box">
        <h2>Enter Finance PIN to Continue</h2>
        <div class="pf-row">
          <div class="pf-field">
            <input
              v-model="pin"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type = "number"
              maxlength = "4"
              class="pin-code"
              v-on:keyup="pinInput"
            />
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <template v-if="pinFailed">Invalid PIN</template>
          </div>
        </div>
        <div class="pf-row buttons">
          <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
          <button class="pop-btn" @click="checkPin()">Confirm</button>
        </div>
      </div>
    </div>
  </section>

  <corp-statement-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
</div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import CorpStatementFilters from './filters/CorpStatementFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    AppliedFilters,
    CorpStatementFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,

      registration: {},

      open: true,
      pin: '',
      pinFailed: false,
      activeInfo: true,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      perms: window.appData.currentUser?.role?.perms?.includes('corporate-statements') || window.appData.currentUser?.perms?.includes('corporate-statements')
    }
  },
  computed: {
    appliedFilters () {
      const filterNames = {
       account: 'Account',
        accountType: 'Account Type',
        number: 'Statement Number'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    statements() {
      return this.$store.state.statements.data || []
    },

    nonCorporateStatements () {
      return this.statements.filter(statement => parseInt(statement.corporate) === 1)
    },

    sortStatements () {
      let statements = this.nonCorporateStatements

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) return statements

      if (this.filters.account) {
        statements = statements.filter(statement => parseInt(statement.accountId) === parseInt(this.filters.account))
      }

      if (this.filters.accountType) {
        statements = statements.filter(statement => statement.account.accountType === this.filters.accountType)
      }

      if (this.filters.number) {
        statements = statements.filter(statement => parseInt(statement.number) === parseInt(this.filters.number))
      }

      return statements
    },

    totalNumber() {
      return this.sortStatements.length
    },
  },

  async created () {
    await this.refreshStatements();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'accountType') return window.corpAccountTypes[value]

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },
    
    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    async refreshStatements () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if(this.filters.fromDate && this.filters.toDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          if(to.diff(from, 'days') > 60) {
            this.dateRangeLimitError = true
            this.loading=false;
            loader.hide()
            return
          }else{
            this.dateRangeLimitError = false
          }
        }
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
      }

      await this.$store.dispatch('statements/load', { order: ['ID:DESC'], params, skip: ['registration', 'createdBy'] })
      this.loading=false;
      loader.hide()
    },

    cancel() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckFinancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },
  },

  watch: {
    appliedFilters () {
      this.refreshStatements();
    },
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>