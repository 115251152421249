<template>
  <section  class="content-tlist">
    <table class="content-table">            
      <tr>
        <th>Review Date</th>
        <th>Profile</th>
        <th>Name</th>
        <th>Profile Type</th>
        <th>Status</th>
        <th>Options</th>
      </tr>
      <tr v-for="review in data" :key="review.id">
        <td><span>Review Date</span>{{ review.updatedAt | date('MM/D/YYYY hh:mm A') }}</td>
        <td><span>Profile</span><a :href="`/system-customers/profiles/${review.id}`">{{ review.profileNumber }}</a></td>
        <td><span>Name</span>{{ review.name }}</td>
        <td><span>Account Type</span>{{ review.type }}</td>
         <td><span>Status</span>
          <em :class="statusColorClasses[review.status]">
            {{ review.status === 'Complete' ? 'Activation' : review.status }}
          </em>
        </td>            
        <td>
          <span>Options</span>
          <template v-if="$hasPerm('start-reiew') && review.complianceReview">
            <button v-if="review.complianceReview.done != 2" class="action-button save" @click="startReview(review.complianceReview.id)">Start Review</button>
          </template>
          <template v-if="$hasPerm('complete-review') && review.complianceReview">
            <button v-if="review.complianceReview.done == 2" class="action-button save" @click="completeReview(review)">Complete Review</button>
          </template>
        </td>
      </tr>
      <tr v-if="!data.length">
        <td colspan="7" class="no-data">No data</td>
      </tr>
    </table>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      statusColorClasses: {
        Active: 'c1',
        Restricted: 'c2',
        Review: 'c2',
        Complete: 'c2',
        Suspended: 'c2',
        Dormant: 'c3',
        Closed: 'c1'
      }
    }
  },

  methods: {
    async startReview (id) {
      const loader = this.$loading.show()
      await this.$store.dispatch('compliance_reviews/update', {
        id,
        payload: {
          done: 2
        }
      })
      this.$toast.success('Profile Review started successfully.')
      this.$store.dispatch('registrations/load', {
        params: {
          status: 'Review',
        }
      })
      loader.hide()
    },

    async completeReview (review) {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_reviews/update', {
          id: review.complianceReview.id,
          payload: {
            done: 3
          }
        })
        const response = await this.$store.dispatch('registrations/action', {
          id: review.id,
          action: 'ResetComplianceReview'
        })
        this.$toast.success('Profile Review completed successfully.')
      } catch {
        this.$toast.error('Something went wrong.')
      } finally {
        this.$store.dispatch('registrations/load', {
          params: {
            status: 'Review',
          }
        })
        loader.hide()
      }
    }
  }
}
</script>