<template>
  <div class="cp-box">
    <ul class="cp-rows active">
      <li>
        <button v-if="$hasPerm('partners-update') && canEdit" class="btn btn-success" @click="newItemStatus = true">Add New</button>
      </li>
      <li>
        <section class="content-filters">
          <div class="">
            <div class="cof-row">
              <div class="cof-field">
                <label>Date Search</label>
                <div class="cof-multi flex">
                  <date-time-picker
                    v-model="searchForm.fromDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    :max-date="today" >
                  </date-time-picker>
                  <span>To</span>
                  <date-time-picker
                    v-model="searchForm.toDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    :max-date="today" >
                  </date-time-picker>
                  &nbsp;
                  <button class="main" @click="search()">Search</button>
                  &nbsp;
                  <button class="second" @click="resetItem()">Reset Filters</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section  class="content-tlist" style="margin: 30px 0;">
          <table class="content-table">           
            <tr>
              <th>Date/Time</th>                        
              <th>Added By</th>              
              <th>Note</th>                        
            </tr>
            <tr v-for="note in notes" :key="note.id">
              <td><span>Date/Time</span>{{ note.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
              <td><span>Added By</span>
                <template v-if="note.system">System</template>
                <template v-else>{{ note.createdBy.firstName }} {{ note.createdBy.lastName }}</template>
              </td>
              <td><span>Note</span><div v-html="note.notes"></div></td>
            </tr>
            <tr v-if="!notes.length">
              <td class="empty-table-text" colspan="3">There are no notes</td>
            </tr>
          </table>
        </section>

      <li v-if="newItemStatus">
        <h4>Add New Note</h4>
        <ul class="cp-fields">
            <textarea rows="5" class="textarea-profile-notes" v-model="profileNote"></textarea>
        </ul>
        <ul class="cp-fields">
          <li>
            <button class="btn btn-success" @click="saveProfileNotes()">Save</button>
            <button class="btn btn-warning" @click="newItemStatus = false">Cancel</button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  components: {
    DateTimePicker
  },

  data () {
    return {
      profileNote: '',
      newItemStatus: false,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      appliedFilters: null,
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    },

    canEdit () {
      return !['Denied', 'Archived'].includes(this.partner.status)
    },

    notes () {
      let data = this.$store.state.partner_notes.data || []
      if (!this.appliedFilters) {
        data = data.filter(item => {
          return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= moment("00:00:00", "HH:mm:ss")
        })
      }else{
        if (this.appliedFilters.fromDate) {
          const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= from
          })
        }

        if (this.appliedFilters.toDate) {
          const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') <= to
          })
        }
      }

      return data.filter(note => parseInt(note.partnerId) === parseInt(this.id))
    }
  },

  methods: {
    search() {
      this.appliedFilters = {...this.searchForm}
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
    },

    saveProfileNotes (event) {
      this.$store.dispatch('partner_notes/create', {
        createdBy: window.appData.currentUser.id,
        partnerId: this.id,
        notes: this.profileNote,
        createdAt: 'CURRENT_TIMESTAMP'
      })
      this.newItemStatus = false
    }
  }
}
</script>
