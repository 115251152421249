<template>
  <div>
    <section style="display:none">
      <div class="flex settings-header">
        <h2 class="inner-headers"></h2>        
      </div>
    </section>
    <settings-fee-schedule-settings v-if="openedSettings" @apply="applyFilters($event)" @close="openedSettings = false" :fee = "fees[0]"/>
    <section>
      <div class="flex header-flex">
        <div>
          <button v-if="$hasPerm('update-fee-Schedule')" class="main" @click="modalType = 'modal'"><i class="el-icon-plus" /> New Plan</button>
        </div>
        <div class="flex">
          <div style="margin-right: 10px;">
            Plan
            <select v-model="plan">
              <option></option>
              <option v-for="item in plans" :value="item.id">{{ item.text }}</option>
            </select>
          </div>
        </div>
        <div>
          <button class="second" @click="openedSettings = true"><i class="el-icon-s-operation"></i> Settings</button>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Fee Name</th>
          <th style="width: 10%;">Fee</th>
          <th style="width: 10%;">Deposit</th>
          <th style="width: 10%;">Withdrawal</th>
        </tr>
        <template v-if="feeSchedule">
          <tr>
            <td>
              <span>Fee Name</span>
              Volume Fee
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                {{ feeSchedule.volumeFee.fee | formattedTwoDecimal }} %
              </template>
              <template v-else>
                <input
                  v-model="feeSchedule.volumeFee.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.volumeFee.fee"
                />%
              </template>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Monthly Access Fee
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.monthlyAccessFee.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.monthlyAccessFee.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.monthlyAccessFee.fee"
                />
              </template>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Domestic Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.domesticWire.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.domesticWire.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.domesticWire.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.domesticWire.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.domesticWire.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.domesticWire.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              International Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.internationalWire.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.internationalWire.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.internationalWire.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.internationalWire.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.internationalWire.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.internationalWire.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              ACH
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.ach.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.ach.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.ach.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.ach.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.ach.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.ach.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Account Transfer
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.accountTransfer.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.accountTransfer.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.accountTransfer.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Checks
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.checks.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.checks.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.checks.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.checks.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.checks.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.checks.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Exchange
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.exchange.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.exchange.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.exchange.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Assets
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.fee"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Dormant Accounts
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.dormantAccounts.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.dormantAccounts.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.dormantAccounts.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Debit Card
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.debitCard.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.debitCard.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.debitCard.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.debitCard.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.debitCard.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.debitCard.per_withdrawal"
                />
              </template>
            </td>
          </tr>
        </template>
      </table>
    </section>
    <section v-if="feeSchedule">
      <div v-if="$hasPerm('update-fee-Schedule')" class="flex header-flex">
        <div>
          <button v-if="updateFlag" class="main" @click="save()">Save</button>
          <button v-else class="main" @click="updateFlag = true">Update</button>
        </div>
      </div>
    </section>
    <fee-plan-popup v-if="modalType"
      :modal-type="modalType"
      @close="modalType = null" />
  </div>
</template>

<script>
import { required, numeric, email, url, requiredIf, maxLength, between } from 'vuelidate/lib/validators'
import SettingsFeeScheduleSettings from './settings-fee-schedule-settings'
import FeePlanPopup from './fee-plan-popup'

export default {
  name: 'settings-fee-schedule',

  components: {
    FeePlanPopup,
    SettingsFeeScheduleSettings,
  },

  data() {
    return {
      accountType: 'personal',
      modalType: null,
      plan: null,
      openedSettings: false,
      updateFlag: false,
    }
  },

  computed: {
    fees () {
      const data = this.$store.state.fee_schedule.data || []
      return data
    },

    plans () {
      let data = this.$store.state.fee_plan.data || []
      return data.map(acc => ({
          id: acc.id,
          text: acc.name
        }))
    },

    feeSchedule () {
      let data = this.$store.state.fee_plan.data || []
      return data.find(item => item.id == this.plan)?.feeSchedule
    }
  },

  async created () {
    await this.$store.dispatch('fee_schedule/load')
    await this.$store.dispatch('fee_plan/load')
  },

  methods: {
    async save () {
      this.$v.feeSchedule.$touch()
      if (this.$v.feeSchedule.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('fee_plan/update', {
          id: this.plan,
          payload: {
            feeSchedule: this.feeSchedule
          }
        })
        this.$toast.success('Fee Schedule successfully updated.')
        this.updateFlag = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
  },

  validations () {
    let rules = {
      feeSchedule: {
        volumeFee : { 
          fee: { required }
        },
        monthlyAccessFee : { 
          fee: { required }
        },
        domesticWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        internationalWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        ach : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        accountTransfer : { 
          per_withdrawal: { required }
        },
        checks : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        exchange : { 
          fee: { required },
        },
        assets : { 
          fee: { required },
          per_deposit: { required },
          per_withdrawal: { required }
        },
        dormantAccounts : { 
          fee: { required },
        },
        debitCard : { 
          fee: { required },
          per_withdrawal: { required }
        },
      },
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .fee-input {
    max-width: 50px;
  }
  .fee-input::-webkit-inner-spin-button,
  .fee-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .description {
    max-width: 100%;
  }
  .header-flex {
    margin: 30px;
    justify-content: space-between;
  }

  .settings-header {
    justify-content: space-between;
    align-items: center;
  }

  .settings-header button {
    margin-right: 50px;
  }
</style>