<template>
  <form-wrapper
    :label="label"
    :required="isRequired"
    :errors="errors"
    :class="{ focused, filled: focused || data, disabled: disabled }">
    <div
      class="select"
      :class="{ error: hasError, disabled }">
      <span>{{ selected }}</span>
      <select
        v-model="data"
        :required="isRequired"
        :disabled="disabled"
        @focus="focused=true"
        @blur="focused=false"
        @input="$emit('input', $event.target.value)">
        <option :value="null" v-if="!noNull">&nbsp;</option>
        <template v-if="optionGroup">
          <optgroup v-for="(groupOptions, group) in options" :key="group" :label="group">
            <option v-for="option in groupOptions" :key="option.id" :value="option.id" :disabled="option.disabled">{{ option.text }}</option>
          </optgroup>
        </template>
        <template v-else>
          <option v-for="option in parsedOptions" :key="option.id" :value="option.id" :disabled="option.disabled">{{ option.text }}</option>
        </template>
      </select>
    </div>
  </form-wrapper>
</template>

<script>
import inputMixin from './input-mixin'

export default {
  mixins: [inputMixin],

  props: {
    noNull: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Object, Array],
      default: () => []
    },

    optionGroup: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selected () {
      const options = this.optionGroup
        ? Object.values(this.options).reduce((acc, arr) => acc.concat(arr), [])
        : this.parsedOptions
      
      return options.find(opt => opt.id === this.data)?.text


    },

    parsedOptions () {
      if (Array.isArray(this.options)) return this.options
      return Object.keys(this.options).map(key => ({
        id: key,
        text: this.options[key]
      }))
    },
  }
}
</script>
<style lang="scss" scoped>
  option:disabled {
    color: rgb(170, 170, 170) !important; ;
  }
</style>
