<template>
  <div>
    <template v-if="featureStatus !=null && !featureStatus">
      <section class="content-filters">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Trade Desk</h3>
      </section>
    </template>
    <template v-else-if="featureStatus !=null && featureStatus">
      <section>
        <div class="flex-between">
          <h2 class="inner-headers"></h2>

          <div style="padding: 24px 30px 10px 10px;">
            <button class="second" @click="openedFilters = true">
              <i class="el-icon-s-operation" /> Filters
            </button>
          </div>
        </div>
      </section>

      <template v-if="!this.currentUser.parId">
        <button class="btn btn-success" @click="openTradeModal()" style="margin-top:-60px"><i class="el-icon-plus" /> New Trade</button>
      </template>

      <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />
      
      <section v-if="$hasPerm('update-transaction')">
        <div class="text-center" v-if="currentUser.role && currentUser.role.group.id==1">
          <button v-if="systemMaintenanceStatus" class="main forcebtt" @click="updateTrasactions()">Update Transactions</button>
        </div>
      </section>
      <div class="accordion">
        <div :class="{'cof-box-active' : activeInfo}">
          <section  class="content-tlist" v-if="!loading">
            <el-table class="content-table" :data="filteredData" @row-click="transactionsTableClick" ref="transactionsTable">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="popup-header" v-for="selectedTrade in [[props.row]]">
                    <h3>Trade</h3>
                    <h3 v-if="selectedTrade">{{ selectedTrade[0].tradeNumber }}</h3>
                    <h3 v-if="selectedTrade">Status: {{ letterUpper(getStatus(selectedTrade[0].quoteStatus)) }}</h3>
                  </div>
                  <template v-for="selectedTrade in [[props.row]]">
                    <div class="scrollable">
                      <div class="information">
                        <template v-if="selectedTrade">
                          <div>
                            <p><b>Account</b>: ****{{ selectedTrade[0].accountId.substr(4, 3) }}</p>
                            <p><b>Created</b>: {{ selectedTrade[0].createdAt | date('MM/D/YYYY hh:mm A')  }}</p>
                          </div>
                          <div>
                            <p><b>Profile</b>: {{ selectedTrade[0].profileId }}</p>
                            <p><b>Completed By</b>: {{ selectedTrade[0].completedBy }}</p>
                          </div>
                        </template>
                      </div>
                      <div class="popup-header details">
                        <h3>Details</h3>
                      </div>
                      <template v-if="selectedTrade">
                        <div class="information">
                          <div>
                            <p><b>Quote</b>: {{ selectedTrade[0].quoteNumber }}</p>
                            <p><b>Trade Type</b>: {{ selectedTrade[0].quoteType }}</p>
                            <p><b>Base Amount</b>: {{ selectedTrade[0].baseAmount | balance }}</p>
                            <p><b>Total Amount</b>: {{ selectedTrade[0].totalAmount | balance }}</p>
                            <p><b>Unit Count</b>: {{ parseFloat(selectedTrade[0].unitCount) }}</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p><b>Completed On</b>: <template v-if="selectedTrade[0].trade">{{ selectedTrade[0].trade.providerSettledAt | date('MM/D/YYYY hh:mm A') }}</template></p>
                          </div>
                          <div>
                            <p><b>Trade</b>: {{ selectedTrade[0].tradeNumber }}</p>
                            <p><b>Asset</b>: {{ selectedTrade[0].assetName }}</p>
                            <p><b>Broker Fee</b>: {{ Math.abs(selectedTrade[0].feeAmount) | balance }}</p>
                            <p><b>Price Per Unit</b>: {{ parseFloat(selectedTrade[0].pricePerUnit) }}</p>
                            <p><b>Trade Fee</b>: {{ Math.abs(selectedTrade[0].tradeFee) | balance }}</p>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="popup-header notes">
                      <h3>Options</h3>
                    </div>
                    <div class="buttons">
                      <div></div>
                      <button class="main" @click="print(selectedTrade[0].id)">Print</button>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="Date">
                <template slot-scope="props">
                  <p>{{ props.row.createdAt | date('MM/D/YYYY') }}<br/>
                  {{ props.row.createdAt | date('hh:mm A') }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Account">
                <template slot-scope="props">
                  <a v-if="!isCustomer" :href="`/system-customers/registrations/${props.row.regId}?tab=Accounts`">{{ props.row.accountId }}</a>
                  <template v-else>{{ item.accountId }}</template>
                </template>
              </el-table-column>
              <el-table-column
                label="Quote"
                prop="quoteNumber">
              </el-table-column>
              <el-table-column label="Trade">
                <template slot-scope="props">
                  <template v-if="props.row.trade">{{ props.row.trade.tradeNumber }}</template>
                </template>
              </el-table-column>
              <el-table-column
                label="Type"
                prop="quoteType">
              </el-table-column>
              <el-table-column
                label="Asset"
                prop="assetName">
              </el-table-column>
              <el-table-column label="Amount">
                <template slot-scope="props">
                  {{ props.row.baseAmount | balance }}
                </template>
              </el-table-column>
              <el-table-column label="Broker Fee">
                <template slot-scope="props">
                  {{ Math.abs(props.row.feeAmount) | balance }}
                </template>
              </el-table-column>
              <el-table-column label="Total">
                <template slot-scope="props">
                  {{ props.row.totalAmount | balance }}
                </template>
              </el-table-column>
              <el-table-column label="Per Unit">
                <template slot-scope="props">
                  {{ parseFloat(props.row.pricePerUnit) }}
                </template>
              </el-table-column>
              <el-table-column label="Unit Count">
                <template slot-scope="props">
                  {{ parseFloat(props.row.unitCount) }}
                </template>
              </el-table-column>
              <el-table-column label="Status">
                <template slot-scope="props">
                  <em :class="statusColorClasses[getStatus(props.row.quoteStatus)]">{{ letterUpper(getStatus(props.row.quoteStatus)) }}</em>
                </template>
              </el-table-column>
              <el-table-column label="Options">
                
              </el-table-column>
            </el-table>
          </section>
          <section class="pagination-section" v-if="!loading">
            <div>
              Per page
              <select v-model="perPage" @change="setPages()">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
            </div>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
                </li>
                <li class="page-item">
                  <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
                </li>
                <li class="page-item">
                  <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
                </li>
              </ul>
            </nav>
          </section>
        </div>
      </div>
    </template>
    <manage-trade-desk v-if="addModal" @reload="reload()" @close="addModal = false" />
    <trade-desk-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { alphaNum, assetsAmount } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import MnbSelect from '../../components/ui/forms/Select'
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import Templates from "./Templates.vue";
import ManageTradeDesk from './forms/ManageTradeDesk'
import TradeDeskFilters from './filters/TradeDeskFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'Transactions',

  components: {
    Templates,
    MnbSelect,
    VueEnglishdatepicker,
    DateTimePicker,
    AppliedFilters,
    ManageTradeDesk,
    TradeDeskFilters,
  },

  data () {
    return {
      form: {
        account: null,
        tradeType: null,
        depositType: null,
        assetsCurrency: null,
        fundsAmount: null,
        unitsAmount: null,
      },
      fee: 0,
      invalidBalance: false,
      countDown : 10,
      countDownRun : true,

      openedFilters: false,
      filters: null,

      page: 1,
      perPage: 25,
      pages: [],
      totalNumber: 0,

      addModal: false,
      addConfirmModal: false,
      cancelled: false,
      accepted: false,
      confirmSuccess: true,
      addApproveModal: false,
      approveSuccess: true,

      selectedTrade: null,
      addNote: false,
      note: null,
      loading: false,
      systemMaintenanceStatus: window.maintenance,
      trade : null,
      quote : null,
      error : false,
      failed : false,
      errorSell : false,
      errorMessage : null,
      featureStatus : null,
      activeInfo: true,

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        account: 'Account',
        quoteNumber: 'Quote Number',
        tradeNumber: 'Trade Number',
        quoteType: 'Type',
        totalAmount: 'Total Amount',
        quoteStatus: 'Status',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Trade Date: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    currencies () {
      let data = window.currencies || []
      return data
    },

    trades () {
      let data =  this.$store.state.quotes.data || []
      this.totalNumber = this.$store.state.quotes.count || 0
      return data
    },

    filteredData () {
      return this.trades;
    },

    transactionTypeText(){
      return {
        checks: 'Check',
        ach: 'ACH',
        domestic_wire: 'Domestic Wire',
        international_wires: 'International Wire',
        acc_to_acc: 'Account to Account',
        Asset: 'Asset'
      }
    },

    statusColorClasses () {
      return {
        New: 'c1',
        pending: 'c2',
        Review: 'c2',
        'On Hold': 'c2',
        Denied: 'c4',
        executed: 'c1',
        settled: 'c1',
        complete: 'c3',
        cancelled: 'c3',
        Expired: 'c3',
        Error: 'c3',
        failed: 'c3',
      }
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []

      registrations.forEach(reg => {
        if (reg.accounts?.length && (!this.currentUser.regId || reg.id==this.currentUser.regId)) {
          reg.accounts.forEach(account => {
            if(account.profileSettings?.assets && account.profileSettings?.trade_desk) {
              accounts.push({
                id: account.id,
                regId: reg.id,
                status: account.accountStatus,
                text: `${account.accountNumber}-${reg.name}`,
                accountNumber: account.accountNumber,
                provider: account.provider,
                profileSettings: account.profileSettings,
                fundsCurrencies: account.fundsCurrencies,
                assetsWallets: account.assetsWallets,
              })
            }
          })
        }
      })

      accounts = accounts.filter(account => ['Open'].includes(account.status))

      return accounts
    },

    depositTypeOptions () {
      const options = [
        {
          id: 'Funds',
          text: 'Funds',
        },
        {
          id: 'Units',
          text: 'Units',
        }
      ]
      return options
    },

    tradeTypeOptions () {
      const options = [
        {
          id: 'Buy',
          text: 'Buy',
        },
        {
          id: 'Sell',
          text: 'Sell',
        }
      ]
      return options
    },

    assetsCurrencyOptions () {
      let currencies = window.currencies || []
      let options = []
      let disabled = true
      currencies.forEach(currency => {
        if(currency.tradeDesk){
          if(this.selectedAccount) {
              if(this.selectedAccount.assetsWallets){
                let wallets
                wallets = this.selectedAccount.assetsWallets.filter(wallet => {
                  return wallet.currencyId === currency.id
                })
                if(wallets[0] && wallets[0].incomingAssetWalletAddress) {
                  if(currency.systemCurrencyType == 'Assets') {
                    options.push({
                      id: currency.id,
                      text: currency.currencyNickname,
                    })
                  }
                }else{
                  disabled = true
                }
              }
          }
        }
      })
      return options
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.form.account))
      return account
    },

    totalFunds () {
        return (parseFloat(this.form.fundsAmount)) || 0
    },

    totalUnits () {
      return (parseFloat(this.form.unitsAmount)) || 0
    },

    withdrawalLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
    },

    tradeLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings['trade_desk_limit']) : null
    },

    unitLimit () {
      if(this.form.assetsCurrency) {
        let wallets
        wallets = this.selectedAccount.assetsWallets.filter(wallet => {
          return wallet.currencyId === this.form.assetsCurrency
        })
        return wallets[0]? wallets[0].systemAssetAvailableBalance : null
      }
    },
  },

  async created () {
    await this.getFeatureStatus();
    await this.refreshTrades();
    await this.$store.dispatch('registrations/load', {skip: ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'profileNumber', 'partnerId', 'currency', 'status', 'matched', 'verified', 'boardingApproval', 'createdAt', 'updatedAt', 'approvalDate', 'browser', 'browser_lang', 'os', 'device', 'documentId', 'serviceProvidersId', 'termId', 'confirmId', 'createdBy', 'partner', 'result', 'file', 'cipStatus', 'amlStatus', 'agreementId', 'kycStatus', 'confirm', 'exception', 'providerAccountNumber', 'providerContingentHold', 'providerNonContingentHold', 'providerPendingTransfer', 'linked', 'solidLinked', 'response', 'providerCreatedAt', 'primeAccount', 'solidAccount', 'cipMessage', 'cipMessageDetail', 'amlMessage', 'amlMessageDetail', 'kycMessage', 'kycMessageDetail', 'reviewDetail', 'QPaymentReference', 'statusUpdatedAt', 'accountKey', 'contactFundsTransferReferencesId', 'personId', 'businessId', 'kybStatus', 'idvStatus', 'phoneVerified', 'emailVerified', 'kycResults', 'kybResults', 'idvResults', 'documentResults', 'reviewCode', 'reviewMessage', 'metadata', 'programID', 'idv', 'disclosureStatus', 'members', 'membersKYCResults', 'membersIDVResults', 'membersDocumentResults', 'cards', 'owedFee', 'owedFeeLastUpdate', 'agreementResponse', 'webhookId', 'webhookResponse',  'partnerId', 'chargeOff', 'chargeOffBalance', 'accountPlan', 'feeSchedule', 'feePlan']})
  },

  watch: {
    trades () {
      this.setPages();
    },
    appliedFilters () {
      this.refreshTrades();
    },

    page() {
      this.refreshTrades();
    },

    perPage() {
      if(this.page == 1){
        this.refreshTrades();
      }else{
        this.page = 1
      }
    },

    'form.account' () {
      if(this.form.account){
        this.getFee()
      }
    },

    'form.fundsAmount' () {
      this.$v.form.fundsAmount.$touch()

      if(this.form.tradeType == 'Buy'){
        if (parseFloat(this.withdrawalLimit) < (parseFloat(this.form.fundsAmount) + parseFloat(this.fee))) {
          this.invalidBalance = true
          return
        } else {
          this.invalidBalance = false
          return
        }
      }
    },

    'form.unitsAmount' () {
      this.$v.form.unitsAmount.$touch()
      if(this.form.tradeType == 'Sell'){
        if (parseFloat(this.unitLimit) < (parseFloat(this.form.unitsAmount) )) {
          this.invalidBalance = true
          return
        } else {
          this.invalidBalance = false
          return
        }
      }
    },
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },
    
    clearFilters () {
      this.filters = null
    },
    
    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    getFilterValue (key, value) {
      if (key === 'totalAmount') return window.formatMoney(value)
      if (key === 'quoteStatus') return window.tradeDeskStatuses[value]

      return value
    },
    
    getStatus(status) {
      const names = {
        executed_pending_settlement: 'pending',
      }
      return names[status] || status
    },

    letterUpper(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async refreshTrades () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (this.isCustomer) {
        const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
        if (reg?.mainAccount?.accountNumber)
        params.accountId=reg.mainAccount.accountNumber;
      }
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.account) {
          params.accountId = this.filters.account
        }

        if (this.filters.quoteNumber) {
          params.quoteNumber = this.filters.quoteNumber
        }

        if (this.filters.tradeNumber) {
          params.tradeNumber = this.filters.tradeNumber
        }

        if (this.filters.quoteType) {
          params.quoteType = this.filters.quoteType
        }

        if (this.filters.totalAmount) {
          params.totalAmount = this.filters.totalAmount
        }

        if (this.filters.quoteStatus) {
          params.quoteStatus = this.filters.quoteStatus
        }
      }

      await this.$store.dispatch('quotes/load', { order: ['createdAt:DESC'], params, limit: this.perPage, start: (this.page-1) * this.perPage })
      this.loading=false;
      loader.hide()
    },

    openTradeModal () {
      this.form.account = null
      this.form.tradeType = null
      this.form.depositType = null
      this.form.assetsCurrency = null
      this.form.fundsAmount = null
      this.form.unitsAmount = null
      this.accepted = false
      this.cancelled = false
      this.addConfirmModal = false
      this.addModal =true;
      this.error = false
      this.errorSell = false
    },

    async getFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: 'Assets',
        entryType: 'Fee'
      }
      this.fee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
    },
    
    async reloadPage() {
      location.replace(location.href);
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false

      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        fundsAmount: this.form.fundsAmount,
        unitsAmount: this.form.unitsAmount,
        tradeType: this.form.tradeType,
        depositType: this.form.depositType,
        transactionFee: this.fee,
        completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        currencyId: this.form.assetsCurrency
      }
      let response = (await api.action("Quotes", "new", "Quotes", payload))
      this.quote = response.item
      loader.hide()
      if(this.quote.providerCreatedAt) {
        let providerCreatedAtString = this.quote.providerCreatedAt
          , reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
          , [, year, month, day, hours, minutes, seconds] = reggie.exec(providerCreatedAtString)
          , providerCreatedAt = new Date(year, month-1, day, hours, minutes, seconds);
        let quoteExpiresAtString = this.quote.quoteExpiresAt
          , quotereggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
          , [, quoteyear, quotemonth, quoteday, quotehours, quoteminutes, quoteseconds] = quotereggie.exec(quoteExpiresAtString)
          , quoteExpiresAt = new Date(quoteyear, quotemonth-1, quoteday, quotehours, quoteminutes, quoteseconds);
        const diffTime = Math.abs(quoteExpiresAt - providerCreatedAt);
        this.countDown = diffTime/1000 - 5
        this.countDownRun = true
        this.countDownTimer()
        this.addConfirmModal = true
      }else if(response.action.error){
        this.error = true
        this.errorMessage = response.action.errorMessage
      }else if(response.action.errorSell){
        this.errorSell = true
      }else{
        this.form.account = null
        this.form.tradeType = null
        this.form.depositType = null
        this.form.assetsCurrency = null
        this.form.fundsAmount = null
        this.form.unitsAmount = null
        this.error = false
        this.errorSell = false
      }
    },

    async cancel () {
      this.countDownRun = false
      const loader = this.$loading.show()
      this.quote = (await api.action("Quotes", this.quote.id, "Cancel")).item
      loader.hide()
      this.cancelled = true
    },

    async accept () {
      this.countDownRun = false
      const loader = this.$loading.show()
      let response = (await api.action("Quotes", this.quote.id, "Accept"))
      this.trade = response.action.trade
      this.error = response.action.error
      this.failed = response.action.failed
      this.quote = response.item
      loader.hide()
      this.accepted = true
    },

    async countDownTimer() {
      if(this.countDownRun){
        if(this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }else{
          this.trade = (await api.action("Quotes", this.quote.id, "Expire")).action.trade
        }
      }
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Trade Desk']
    },

    async print (id) {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Quotes", id, "Print")).action
        window.open("/files/trades/"+id+"/"+id+"-trade.pdf");
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    transactionsTableClick(row) {
      this.$refs.transactionsTable.toggleRowExpansion(row);
    },
  },

  validations () {
    const rules = {
      form: {
        account: { required },
        depositType: { required },
        tradeType: { required },
        assetsCurrency: { required }
      }
    }
    if (this.form.depositType == 'Funds') {
      rules.form.fundsAmount = { required, decimal }
      if(this.form.tradeType == 'Buy'){
        if (this.tradeLimit) {
          rules.form.fundsAmount.limit = maxValue(this.tradeLimit)
        }
      }
    }
    if (this.form.depositType == 'Units') {
      rules.form.unitsAmount = { required, assetsAmount }
      if(this.form.tradeType == 'Sell'){
        if (this.unitLimit) {
          rules.form.unitsAmount.limit = maxValue(this.unitLimit)
        }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  tr {
    td {
      padding: 5px;
    }
  }
}
.buttons {
  margin-top: 30px;
}
.information {
  display: flex;
  justify-content: space-between;

  div {
    width: 50%; 
  }
}
.buttons {
  display: flex;
  justify-content: space-between;

  .optionBtns .main {
    margin-left: 15px; 
  }
}
.form-max {
  div {
    width: 100%;
  }
}
button.cancel {
  background: #666;
  color: white;
  min-width: 120px;
}
button.approve {
  background: #67c23a;
  color: white;
  min-width: 120px;
}
.btn-approve {
  background: #67c23a ;
  color: white;
}


.search-item {
  input {
    max-width: 110px;
  }
}

.quote-panel{
  display: inline-block;
  text-align: left;
}

.quote-buttons{
  justify-content: center !important;
}

.quote-title{
  font-size: 18px;
}
.href-link {
  cursor: pointer;
}

.content-tlist {
  border: none;
}
</style>
