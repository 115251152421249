<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <div class="mnb-field">
      <label>Date Added</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          class="position-bottom-right"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
      </div>
    </div>

    <div class="mnb-field">
      <label>Account</label>
      <input type="text" v-model="form.account" class="mnb-input" />
    </div>

    <div class="mnb-field">
      <label>Template Name</label>
      <input type="text" v-model="form.templateName" class="mnb-input" />
    </div>

    <div class="mnb-field">
      <label>Payment Method</label>
      <select v-model="form.method" class="mnb-input">
        <option></option>
        <option value="ach">ACH</option>
        <option value="checks">Check</option>
        <option value="domestic_wire">Domestic Wire</option>
        <option value="international_wires">International Wire</option>
        <option value="acc_to_acc">Account Transfer</option>
        <option value="Asset">Asset</option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        account: null,
        templateName: null,
        method: null
      },

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
