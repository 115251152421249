<template>
  <div>
    <ul class="cp-rows active">
      <li v-if="showEdit" style="flex-direction: row;">
        <template v-if="$hasPerm('partners-manage')">
          <button v-if="!editStatus" class="btn btn-success" @click="edit()">Edit</button>
        </template>
        <button v-if="editStatus" class="btn btn-success" @click="save()">Save</button>
        <button v-if="editStatus" class="btn btn-default" @click="editStatus=false">Cancel</button>
      </li>
      <li v-if="!hideBasics">
        <ul class="cp-fields">
          <li>
            <span>Profile Type</span>
            <div class="val">
              {{ registration.type }}
            </div>
          </li>
          <li>
            <span>Business Type</span>
            <div v-if="registration.business && !editStatus" class="val">
              {{ registration.type }}
            </div>
            <select v-if="editStatus" v-model="form.business_type" class="fullw">
              <option v-for="(businessType, key) in businessTypes" :key="key" :value="key">{{ businessType }}</option>
            </select>
          </li>
          <li>
            <span>Registration Date</span>
            <div class="val">
              {{ registration.createdAt | date('dddd, MMMM D YYYY') }}
              <br>
              {{ registration.createdAt | date('hh:mm:ss A') }}
            </div>
          </li>
          <li>
            <span>Registration ID</span>
            <div class="val">
              {{ registration.uuid }}
            </div>
          </li>
        </ul>
      </li>
      <template v-if="!hideInfo">
        <li>
          <h4>Main Partner Information</h4>
          <br v-if="hideBasics" />
          <ul class="cp-fields">
            <li>
              <span class="form-label">Business Legal Name <span v-if="editStatus && $v.form.legal_name.$invalid" class="error-message">&nbsp; *</span></span>
              <input type="text" v-if="editStatus" v-model="form.legal_name" :class="{ error: $v.form.legal_name.$invalid }"/>
              <div v-else class="val">
                {{ registration.business.legal_name }}
              </div>
              <div v-if="editStatus && $v.form.legal_name.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span class="form-label">Business DBA <span v-if="editStatus && $v.form.dba_name.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.dba_name }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.dba_name" :class="{ error: $v.form.dba_name.$invalid }"/>
              <div v-if="editStatus && $v.form.dba_name.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span class="form-label">Business Phone <span v-if="editStatus && $v.form.phone.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.phone }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.phone" :class="{ error: $v.form.phone.$invalid }"/>
              <div v-if="editStatus && $v.form.phone.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <template v-if="registration.status != 'New'">
                <span class="form-label">Business Email</span>
                <div class="val">
                  {{ registration.business.email }}
                </div>
              </template>
              <template v-else>
                <span class="form-label">Business Email <span v-if="editStatus && $v.form.email.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-if="editStatus" type="text" v-model="form.email" :class="{ error: $v.form.email.$invalid }"/>
                <div v-else class="val">
                  {{ registration.business.email }}
                </div>
                <div v-if="editStatus && $v.form.email.$invalid"><span class="error-message">This field is required.</span></div>
              </template>
            </li>
            <li>
              <span class="form-label">Business Website <span v-if="editStatus && $v.form.website.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.website }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.website" :class="{ error: $v.form.website.$invalid }"/>
              <div v-if="editStatus && $v.form.website.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
          </ul>
        </li>
        <li v-if="!hideAddress">
          <h4>Partner Address Information</h4>
          <ul class="cp-fields">
            <li>
              <span class="form-label">Business Address <span v-if="editStatus && $v.form.address.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.address }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.address" :class="{ error: $v.form.address.$invalid }"/>
              <div v-if="editStatus && $v.form.address.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span>Business Address 2</span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.address2 }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.address2"/>
            </li>
            <li>
              <span class="form-label">Business City <span v-if="editStatus && $v.form.city.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.city }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.city" :class="{ error: $v.form.city.$invalid }"/>
              <div v-if="editStatus && $v.form.city.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span class="form-label">Business State/Province <span v-if="editStatus && $v.form.state.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">{{ registration.business.state | state }}</div>
              <template v-else>
                <select v-if="stateOptions(form.country)" v-model="form.state" class="fullw" :class="{ error: $v.form.state.$invalid }">
                  <option v-for="(state, key) in stateOptions(form.country)" :key="key" :value="key">{{ state }}</option>
                </select>
                <input v-else type="text" v-model="form.state" :class="{ error: $v.form.state.$invalid }"/>
                <div v-if="editStatus && $v.form.state.$invalid"><span class="error-message">This field is required.</span></div>   
              </template>
            </li>
            <li>
              <span class="form-label">Business Zip/Postal Code <span v-if="editStatus && $v.form.zip.$invalid" class="error-message">&nbsp; *</span></span>
              <div v-if="!editStatus" class="val">
                {{ registration.business.zip }}
              </div>
              <input type="text" v-if="editStatus" v-model="form.zip" :class="{ error: $v.form.zip.$invalid }"/>
              <div v-if="editStatus && $v.form.zip.$invalid"><span class="error-message">This field is required.</span></div> 
            </li>
            <li>
              <span class="form-label">Business Country <span v-if="editStatus && $v.form.country.$invalid" class="error-message">&nbsp; *</span></span>
              <select v-if="editStatus" v-model="form.country" class="fullw" :class="{ error: $v.form.country.$invalid }">
                <option v-for="(country, key) in countryOptions" :key="key" :value="key">{{ country }}</option>
              </select>
              <div v-else class="val">{{ registration.business.country | country }}</div>
              <div v-if="editStatus && $v.form.country.$invalid"><span class="error-message">This field is required.</span></div> 
            </li>
          </ul>
        </li>
      </template>
      <li v-if="!hideContacts">
        <h4>Partner Contacts Information</h4>
        <div v-for="(contact, index) in registration.business.contacts" :key="index">
          <hr v-if="index > 0">
          <ul class="cp-fields" style="margin-top: 10px;">
            <li>
              <span class="form-label">First Name <span v-if="editStatus && $v.form.contacts.$each[index].firstName.$invalid" class="error-message">&nbsp; *</span></span>
              <input type="text" v-if="editStatus" v-model="form.contacts[index].firstName" :class="{ error: $v.form.contacts.$each[index].firstName.$invalid }"/>
              <div v-else class="val">
                {{ contact.firstName }}
              </div>
              <div v-if="editStatus && $v.form.contacts.$each[index].firstName.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span class="form-label">Last Name <span v-if="editStatus && $v.form.contacts.$each[index].lastName.$invalid" class="error-message">&nbsp; *</span></span>
              <input type="text" v-if="editStatus" v-model="form.contacts[index].lastName" :class="{ error: $v.form.contacts.$each[index].lastName.$invalid }"/>
              <div v-else class="val">
                {{ contact.lastName }}
              </div>
              <div v-if="editStatus && $v.form.contacts.$each[index].lastName.$invalid"><span class="error-message">This field is required.</span></div>
            </li>
            <li>
              <span class="form-label">Phone <span v-if="editStatus && $v.form.contacts.$each[index].phone.$invalid" class="error-message">&nbsp; *</span></span>
              <div class="val">
                {{ !editStatus ? contact.phone : ''  }}
                <input type="text" v-if="editStatus" v-model="form.contacts[index].phone" :class="{ error: $v.form.contacts.$each[index].phone.$invalid }"/>
                <div v-if="editStatus && $v.form.contacts.$each[index].phone.$invalid"><span class="error-message">This field is required.</span></div>
              </div>
            </li>
            <li>
              <span>Mobile</span>
              <div class="val">
                {{ !editStatus ? contact.mobile : ''  }}
                <input type="text" v-if="editStatus" v-model="form.contacts[index].mobile"/>
              </div>
            </li>
            <li>
              <span class="form-label">Email <span v-if="editStatus && $v.form.contacts.$each[index].email.$invalid" class="error-message">&nbsp; *</span></span>
              <div class="val">
                <input v-if="editStatus" v-model="form.contacts[index].email" type="text" :class="{ error: $v.form.contacts.$each[index].email.$invalid }"/>
                <template v-else>
                  {{ contact.email }}
                </template>
                <div v-if="editStatus && $v.form.contacts.$each[index].email.$invalid"><span class="error-message">This field is required.</span></div>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <template v-if="!hideInfo">
        <li>
          <h4>Partner Business Description</h4>
          <ul class="cp-fields" style="margin-top: 10px;">
            <li>
              <div v-if="!editStatus" class="val">
                {{ registration.business.description }}
              </div>
              <textarea ref="description" v-if="editStatus" v-model="form.description" class="fullw" :class="{ error: $v.form.description.$invalid }"/>
              <!-- <div v-if="editStatus && $v.form.description.$invalid"><span class="error-message">This field is required.</span></div> -->
            </li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </li>
      </template>
    </ul>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Management PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>

    <popup :open.sync="addModal" :width="1250">
      <template v-if="addModal">
        <h4>Add Contact</h4>
        <div class="">
          <div class="content-form">
            <template v-for="(beneficialOwner, i) in beneficialOwners" >
              <div v-if="beneficialOwner.type === 'Beneficial Owner'" :key="beneficialOwner.email"  class="beneficial-owner-content">
                <p>Beneficial Owner - </p>
                <p> {{ beneficialOwner.firstName }} {{ beneficialOwner.lastName }}</p>
                <p class="beneficial-owner-percent">Ownership Percent</p>
                <div v-if="addContactForm.type !== 'Beneficial Owner'" class="beneficial-owner-percent-content">{{ beneficialOwner.ownership_percentage }}</div>
                <input v-else v-model="editContactForm.contacts[i].ownership_percentage" type="text" class="addContactsInput"/>
              </div>
            </template>
            <ul class="cp-fields" style="margin-top: 10px; justify-content: flex-start;">
              <li>
                <span class="form-label">Contact Type <span v-if="$v.addContactForm.type.$dirty && $v.addContactForm.type.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.type">
                  <option v-if="registration.status !== 'Active' && registration.status !== 'Complete'" value="Beneficial Owner">Beneficial Owner</option>
                  <option value="Authorized Person">Authorized Person</option>
                </select>
                <div v-if="$v.addContactForm.type.$dirty && $v.addContactForm.type.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li v-if="addContactForm.type === 'Beneficial Owner'">
                <span class="form-label">Owner Percentage <span v-if="$v.addContactForm.ownership_percentage.$dirty && $v.addContactForm.ownership_percentage.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.ownership_percentage" type="text"/>
                  <div v-if="$v.addContactForm.ownership_percentage.$dirty && $v.addContactForm.ownership_percentage.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
            </ul>
            <ul class="cp-fields" style="margin-top: 10px;">
              <li>
                <span class="form-label">First Name <span v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.firstName" type="text"/>
                <div v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Last Name <span v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.lastName" type="text"/>
                <div v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Sex <span v-if="$v.addContactForm.sex.$dirty && $v.addContactForm.sex.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.sex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div v-if="$v.addContactForm.sex.$dirty && $v.addContactForm.sex.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">SSN/Fiscal Number <span v-if="$v.addContactForm.sin.$dirty && $v.addContactForm.sin.$invalid" class="error-message">&nbsp; *</span></span>
                <div >
                  <template >
                    <input v-model="addContactForm.sin" type="text"/>
                  </template>
                  <div v-if="$v.addContactForm.sin.$dirty && $v.addContactForm.sin.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li class="dob">
                <span class="form-label">Date of Birth <span v-if="$v.addContactForm.dob.$dirty && $v.addContactForm.dob.$invalid" class="error-message">&nbsp; *</span></span>
                <VueDatePicker v-model="addContactForm.dob" format="YYYY-MM-DD" :min-date="date1" :max-date="date2" />
                <div v-if="$v.addContactForm.dob.$dirty && $v.addContactForm.dob.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Phone <span v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.phone" type="text" />
                  <div v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Mobile</span>
                <div>
                  <input v-model="addContactForm.mobile" type="text"/>
                </div>
              </li>
              <li>
                <span class="form-label">Email <span v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.email" type="text"/>
                  <div v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
            </ul>
            <ul class="cp-fields" style="margin-top: 10px; margin-bottom: 30px;">
              <li>
                <span class="form-label">Contact Country <span v-if="$v.addContactForm.country.$dirty && $v.addContactForm.country.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.country" class="fullw" >
                  <option v-for="(country, key) in contactCountryOptions" :key="key" :value="key">{{ country }}</option>
                </select>
                <div v-if="$v.addContactForm.country.$dirty && $v.addContactForm.country.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Contact Address <span v-if="$v.addContactForm.address.$dirty && $v.addContactForm.address.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.address" type="text"/>
                <div v-if="$v.addContactForm.address.$dirty && $v.addContactForm.address.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Contact Address 2</span>
                <input v-model="addContactForm.address2" type="text"/>
                <!-- <div ><span class="error-message">This field is required.</span></div> -->
              </li>
              <li>
                <span class="form-label">Contact City <span v-if="$v.addContactForm.city.$dirty && $v.addContactForm.city.$invalid" class="error-message">&nbsp; *</span></span>
                <div >
                  <template >
                    <input v-model="addContactForm.city" type="text"/>
                  </template>
                  <div v-if="$v.addContactForm.city.$dirty && $v.addContactForm.city.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Contact State/Province <span v-if="$v.addContactForm.state.$dirty && $v.addContactForm.state.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <select v-if="stateOptions(addContactForm.country)" v-model="addContactForm.state" class="fullw">
                    <option v-for="(state, key) in stateOptions(addContactForm.country)" :key="key" :value="key">{{ state }}</option>
                  </select>
                  <input v-else v-model="addContactForm.state" type="text" />
                  <div v-if="$v.addContactForm.state.$dirty && $v.addContactForm.state.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Contact Zip/Postal Code <span v-if="$v.addContactForm.zip.$dirty && $v.addContactForm.zip.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.zip" type="text"/>
                </div>
                <div v-if="$v.addContactForm.zip.$dirty && $v.addContactForm.zip.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
            </ul>
            Documents
            <ul class="document" style="margin-top: 10px; margin-bottom: 30px;">
              <li>
                <span class="form-label">Government Issued ID Type</span>
                <select v-model="addContactForm.id_types[0].id_type" class="fullw" >
                  <option v-for="(country, key) in idTypes" :key="key" :value="key">{{ country }}</option>
                </select>
              </li>
              <li>
                <form-field-text v-model="addContactForm.id_types[0].id_number" :maxlength="20" label="Government Issued ID Number" required />
              </li>
              <li>
                <div class="form-row form-fullw" style="position: relative;width: 400px;">
                  <form-field-upload v-model="addContactForm.id_types[0].document" :label="`Contact ${addContactForm.id_types[0].id_type || 'Government Issued ID Type'}`" />
                </div>
              </li>
              <li>
                <div class="form-row form-fullw" style="position: relative;width: 345px;">
                  <form-field-upload v-model="addContactForm.addressConfirm" :label="`Contact Address Confirmation`" />
                </div>
              </li>
            </ul>
<!--            <ul class="document" style="margin-top: 10px; margin-bottom: 30px;">
            </ul>-->
          </div>
        </div>
        <div class="buttons">
          <button class="main" @click="addContact()">Add</button>
          <button class="second" @click="addModal = false">Cancel</button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import helperMixin from '../../components/common/helper-mixin'
import autosize from 'autosize'
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker'

import { mustBeTrue, alpha, dashNumbers, alphaNum, alphaVersion, phone, webSiteUrl } from '../../lib/validators'
import { required, numeric, email, url, requiredIf, between } from 'vuelidate/lib/validators'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

const contact = {
  type: null,
  country: null,
  city: null,
  state: null,
  zip: null,
  address: null,
  address2: null,
  sex: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
  id_types: [
    {
      id_type: null,
      id_number: null,
      document: null
    }
  ],
  id_number: null,
  ownership_percentage: null,
  doc_address_confirmation: null
}

export default {
  mixins: [helperMixin],
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },

    registration: {
      type: Object,
      required: true
    },

    hideBasics: {
      type: Boolean,
      default: false
    },

    hideInfo: {
      type: Boolean,
      default: false
    },

    hideAddress: {
      type: Boolean,
      default: false
    },

    mainProfileOnlyName: {
      type: Boolean,
      default: false
    },

    hideContacts: {
      type: Boolean,
      default: false
    },

    showRemoveContactButton: {
      type: Boolean,
      default: true
    }
  },

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    VueDatePicker,
  },

  data () {
    return {
      form: {
        
      },
      editStatus: false,
      pin: '',
      pinFailed: false,
      open: false,
      showSin: false,
      editSin: '',
      contactsEditSin: {},
      addModal: false,
      addContactForm: {},
      editContactForm: {},
      idTypes: {
        'Passport': 'Passport',
        'Drivers License': 'Drivers License'
      },
      businessTypes: [
        'Sole Proprietor',
        'Single Member LLC',
        'LLC',
        'General Partnership',
        'Corporation',
        'Publicly Traded Corporation',
        'Association',
        'Non Profit',
        'Government Organization',
        'Revocable Trust',
        'Irrevocable Trust',
        'Estate',
      ],
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    countryOptions () {
      return { ...window.countriesOptions }
    },

    ownershipError () {
      if (this.registration.type !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.type === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    addContactOwnershipError () {
      if (this.registration.type !== 'Business') return false
      const contacts = this.editContactForm.contacts.filter(contact => contact.type === 'Beneficial Owner')
      this.addContactForm.type === 'Beneficial Owner' ? contacts.push(this.addContactForm) : null
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    beneficialOwners () {
      return this.registration.data.contacts
    },
    
    noOwner () {
      if (this.registration.type !== 'Business') return false
      return this.form.contacts.filter(contact => contact.type === 'Beneficial Owner').length==0;
    },

    contactCountryOptions () {
      return { ...window.contactCountriesOptions }
    },

    isPersonal () {
      return this.registration?.type === 'Personal'
    },
    isAdmin () {
      return appData.currentUser.role.group.id == 1;
    },
    questions () {
      return window.questions
    },

    date1 () {
      let today = new Date()
      let yesterday = new Date(today)
      yesterday.setFullYear(yesterday.getFullYear() - 100)
      var dd = String(yesterday.getDate()).padStart(2, '0');
      var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = yesterday.getFullYear();
      var yesterdayDate = yyyy + '-' + mm + '-' + dd;
      return yesterdayDate
    },

    date2 () {
      let today = new Date()
      let yesterday = new Date(today)
      yesterday.setFullYear(yesterday.getFullYear() - 18)
      var dd = String(yesterday.getDate()).padStart(2, '0');
      var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = yesterday.getFullYear();
      var yesterdayDate = yyyy + '-' + mm + '-' + dd;
      return yesterdayDate
    }
  },

  watch: {
    editStatus (value) {
      if (value) this.$nextTick(() => autosize(this.$refs.description))
    },

    'addContactForm.type' (value) {
        this.editNewContact()
    }
 
    // contactsEditSin:  {
    //   deep: true,

    //   handler (value) {
    //     console.log('muuu')
        
    //   }
    // },

    // editSin:  {
    //   deep: true,

    //   handler (value) {
    //     if(value !== `${Array(this.form.sin.length - 3).join('*')}${this.form.sin.slice(-4)}`) {
    //       this.closeEditSin()
    //     }
    //   }
    // }
  },

  methods: {
    async addContact () {
      this.$v.addContactForm.$touch()
      if (this.$v.addContactForm.$invalid) return

      if (this.addContactOwnershipError) {
        this.$toast.error('Ownership Percentage of all Beneficial Owners needs to be a total of 100%')
        return
      }

      const loader = this.$loading.show()

      const payload = {
        updatedAt: 'CURRENT_TIMESTAMP',
        data: this.editContactForm
      }
      const contact = { ...this.addContactForm }
      contact.dob = moment(contact.dob).format('YYYY-MM-DD')
      if ((await api.action("Registration", "new", "duplicateContact", contact)).action.result) {
        this.$toast.error("Cannot add contact. Duplicate entry found.");
        loader.hide()
        return;
      }
      
      payload.data.contacts.push(contact)
      try {
        await this.$store.dispatch('registrations/update', { id: this.registration.id, payload })
        await this.addNote('Contact added')
        var token =  (await api.action("Registration", this.registration.id, "Token", {})).action.token
        var index = payload.data.contacts.length
        const prefix = `Contact ${index} `
        const contactsName = `${contact.firstName} ${contact.lastName}`
        var id_types = ['Passport', 'Drivers License'];
        id_types.forEach(id_type => {
          if(this.addContactForm.id_types[0].id_type == id_type) {
            var type = this.addContactForm.id_types[0];
            this.documentPromise(this.registration.id, token, `${contactsName}-${type.id_type}-${type.id_number}`, 'contact_government_issued_id', index, prefix + 'Government Issued ID', type.id_type, type.id_number, type.document)
          }else{
            this.documentPromise(this.registration.id, token, `${contactsName}-${id_type}- `, 'contact_government_issued_id', index, prefix + 'Government Issued ID', id_type, '', '')
          }
        })
        this.documentPromise(this.registration.id, token, `${contactsName}-Address Confirm`, 'contact_address', index, prefix + 'Address Confirmation', 'Address', 'Address', this.addContactForm.addressConfirm)

        this.$toast.success('Contact added successfully.')
        this.addModal = false
        Object.keys(this.addContactForm).forEach(item => delete this.addContactForm[item])
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async documentPromise (regId, token, filename, docFor, docAdditional, description, type, number, file) {
      var data={
        regId,
        type,
        docFor,
        docAdditional,
        description,
        number,
        createdAt: 'CURRENT_TIMESTAMP',
        deletable: false
      };
      await api.registerUpload(file?this.changeFileName(file, filename):null, {id: regId, token, obj: data});
    },

    openAddContact () {
      this.$nextTick(() => { this.$v.$reset() })
      this.$set(this, 'addContactForm', { ...contact })
      this.addModal = true
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async askRemoveContact (index, contact) {
      if (!confirm(`Are you sure you want to delete contact ${contact.firstName} ${contact.lastName}`)) return

      //this.edit()
      this.editStatus = false
      this.$v.$touch()
      this.form.contacts.splice(index, 1)
      this.save(index+1)
    },


    stateOptions (country) {
      if (!['CA', 'US'].includes(country)) return null

      return window.states[country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },

    editNewContact () {
      if (JSON.parse(JSON.stringify(this.registration.data)).questions) {
        this.editContactForm = JSON.parse(JSON.stringify(this.registration.data)) 
      } else {
        this.editContactForm ={ ...JSON.parse(JSON.stringify(this.registration.data)), ...{ questions: {
              purpose_of_account: '',
              association_with_other_accounts: '',
              source_of_assets_and_income: '',
              intended_use_of_account: '',
              anticipated_types_of_assets: '',
              anticipated_monthly_cash_volume: '',
              anticipated_trading_patterns: '',
              anticipated_monthly_transactions_incoming: '',
              anticipated_monthly_transactions_outgoing: '',
            }
          } 
        }
      }
    },

    edit () {
      this.form = JSON.parse(JSON.stringify(this.registration.business)) 
      this.editStatus = true
    },

    async save(docAdditional) {
      const loader = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('You need to fill all required fields. We marked them with red color so that you know what fields you have missed.')
        loader.hide()
        return
      }
      try {

        const payload = {
          business: this.form,
          updatedAt: 'CURRENT_TIMESTAMP',
        }

        // find internal duplicates
        if(this.form.contacts) {
          for(var i1 in this.form.contacts) {
            for(var i2 in this.form.contacts) {
              if (i1==i2)
                continue;
              var c1=this.form.contacts[i1];
              var c2=this.form.contacts[i2];
              if ((c1.email && c1.email==c2.email) ||
                  (c1.phone && c1.phone==c2.phone) ||
                  (c1.mobile && c1.mobile==c2.mobile)) {
                this.$toast.error("Cannot save. Duplicate entry found.");
                loader.hide()
                return;
              }
            }
          }
        }

        this.editStatus = false
        
      const notes = this.hideContacts ? this.getChangedFields() : 'Profile Info Contacts updated'
        await this.$store.dispatch('partners/update', { id: this.registration.id, payload })
        await this.$store.dispatch('partner_notes/create', {
          createdBy: window.appData.currentUser.id,
          partnerId: this.registration.id,
          notes,
          createdAt: 'CURRENT_TIMESTAMP'
        })

        this.$toast.success('Profile Saved')
        this.showSin = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    getChangedFields () {
      const translation = {
        address:'Business Address 1',
        address2: 'Business Address 2',
        city: 'Business City',
        state: 'Business State/Province',
        zip: 'Business Zip/Postal Code',
        country: 'Country',
        dba_name: 'Business DBA',
        description: 'Business Description',
        email: 'Business Email',
        legal_name: 'Business Legal Name',
        phone: 'Business Phone',
        website: 'Business Website'
      }
      const fields = Object.keys(translation).filter(key => this.form[key] !== this.registration.business[key]).map(key => translation[key]).join(', ')

      return `Profile Info updated: ${fields}`
    },

    cancelPin() {
      this.open = false
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckManagementPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
        this.showSin = true
      }else{
        this.pinFailed = true
      }
    },

    pinInput (e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    closeEditSin () {
      this.editSin = '' 
      this.form.sin = ''
    },

    closeContactEditSin (i) {
      delete this.contactsEditSin[i]
      this.form.contacts[i].sin = ''
    }
  },

  validations () {
    const rules = {
      form: {},
      addContactForm: {}
    }

      rules.form = {
        legal_name: { required, alphaNum },
        dba_name: { required, alphaNum },
        address: { required },
        city: { required },
        country: { required },
        state: { required },
        phone: { required, dashNumbers, phone },
        description: { required },
        email: { required, email },
        website: { webSiteUrl },
        zip: { required, alphaVersion },
      }

      if (!this.hideContacts) {
        rules.form.contacts = {
          $each: {
            firstName: { required, alphaNum },
            lastName: { required, alphaNum },
            phone: { required, phone },
            email: { required, email },
            mobile: { numeric },
          }
        }
      }

      if(this.addModal) {
        rules.addContactForm = {
          firstName: { required, alphaNum },
          lastName: { required, alphaNum },
          phone: { required, numeric },
          mobile: { numeric },
          email: { required, email },
        }
      }


    return rules
  }
}
</script>

<style lang="scss" scoped>
// .fullw {
//   width: 100% !important;
// }

.error-message {
  color: red !important;
}
.error {
  border: 1px solid red !important;
}
.form-label {
  display: flex !important;
  justify-content: flex-start;
}
.beneficial-owner-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}
.beneficial-owner-percent {
  margin-left: 40px;
}
.beneficial-owner-percent-content {
  background-color: rgb(238, 238, 238);
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
.addContactsInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  cursor: #616F7B;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}

.dob {
  min-width: 150px;
}
</style>
