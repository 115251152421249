<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>New File Transfer</h3>
    </div>
    <template>
      <div class="scrollable">
        <template>
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-select-search v-model="form.profile" label="Select Profile" :validator="$v.form.profile" :options="accountOptions" required />
            </div>
            <div class="form-row form-fullw">
              <form-field-upload v-model="form.file" label="File Attachment" />
            </div>
            <div class="form-row form-fullw">
            <textarea v-model="form.description" placeholder="Description" style="width: 100%;" rows="5" :class="{ error: $v.form.description.$invalid }"></textarea>
            </div>
          </div>
        </template>
        <div class="buttons">
          <template>
            <button class="main" @click="submit()" :disabled="$v.form.$invalid">Submit</button>
            <button class="second" @click="open = false">Close</button>
          </template>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import { required } from 'vuelidate/lib/validators'


export default {
  name: 'FileTransferModal',

  components: {
  },
  props: {
    modalType: {
      type: String,
      required: true
    },
  },

  data: () => ({
    openedFilters: false,
    filters: null,

    form: {
      profile: null,
      file: null,
      description: null
    },
    open: true
  }),

  computed: {
    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []
        registrations.forEach(reg => {
          {
            accounts.push({
              id: reg.id,
              text: `${reg.profileNumber}-${reg.name}`,
            })
          }
        })
      return accounts
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Compliance').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    currentUser () {
      return window.appData.currentUser
    },
  },

  async created () {

  },

  methods: {
    async submit () {
      const loader = this.$loading.show()
      try {
        let payload;
        payload = {
          profile: this.form.profile,
          description: this.form.description
        }
        const response = (await api.action("FileTransfer", "new", "Submit", payload))
        if (this.form.file) await this.$store.dispatch('file_transfer/upload', { id: response.item.id, file: this.form.file })
        this.$parent.refreshFileTransfers();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
        this.open = false
      }
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        profile: { required },
        file: { required },
        description: { required },
      }
    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
  .buttons {
    margin-top: 70px;
  }
</style>
