<template>
  <div>
    <div v-if="loading && !hasNumber">
      <div style="padding: 24px 30px 10px 10px;">
        <input v-model="providerId" placeholder="Provider ID" />
        <button class="btn btn-success" @click.prevent="loadAccounts">
          Select provider
        </button>
      </div>
    </div>
    <div v-else-if="loading">Loading...</div>
    <section v-if="!loading">
      <div style="padding: 24px 30px 10px 10px;" v-if="closed">
        All templates listed below are deleted
      </div>
      <div style="padding: 24px 30px 10px 10px;">
        <button class="btn btn-success" @click.prevent="closeAll">
          Delete all shown templates
          {{ progress?'('+progress+'/'+accounts.length+')':'' }}
        </button>
      </div>

      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th style="width: 11%; text-align: center;">Created</th>
            <th style="width: 11%; text-align: center;">Updated</th>
            <th style="width: 5%; text-align: center;">By</th>
            <th style="width: 6%; text-align: center;">Account</th>
            <th style="width: 13%; text-align: center;">Template</th>
            <th style="width: 13%; text-align: center;">Beneficiary</th>
            <th style="width: 7%; text-align: center;">Method</th>
          </tr>
          <tr v-for="(item, i) in accounts" :key="i">
            <td style="text-align: center;"><span>Date Created</span>{{ item.createdAt | date('MM/D/YYYY') }}</td>
            <td style="text-align: center;"><span>Last Updated</span>
              <template v-if="item.updatedAt">{{ item.updatedAt | date('MM/D/YYYY') }}</template>
            </td>
            <td style="text-align: center;"><span>By</span>
              {{ item.savedBy }}
            </td>
            <template>
              <td style="text-align: center;"><span>Account</span>{{ item.account }}</td>
            </template>
            <td style="text-align: center;"><span>Template Name</span>{{ item.templateName }}</td>
            <td style="text-align: center;"><span>Beneficiary</span>
              <template v-if="item.method == 'Asset'">{{ item.payeeToName }}</template>
              <template v-else-if="item.method == 'checks'">{{ item.payee }}</template>
              <template v-else>{{ item.accountName }}</template>
            </td>
            <td style="text-align: center;"><span>Method</span>{{ item.method }}</td>
        <!--    <td style="text-align: center;"><span>Currency</span>{{ item.currency }}</td> -->
          </tr>
          <tr v-if="!accounts.length">
            <td colspan="11" class="no-data">No data</td>
          </tr>
        </table>
      </section>

    </section>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    accounts: [],
    loading: true,
    hasNumber: false,
    progress: 0,
    closed: false,
    providerId: "",
  }),
  methods: {
    async loadAccounts() {
      if (!(this.providerId*1))
        return false;
      this.hasNumber=true;
      this.accounts=(await api.list("Templates")).items.filter(item=>{
        if (item.provider.id==this.providerId) return true;
        return false;
      });
      this.loading=false;
    },
    async closeAll() {
      if (confirm("Are you sure?")) {
        for(var i in this.accounts) {
          var account=this.accounts[i];
          this.progress=i*1+1;
          await api.delete("Templates", account.id);
        }
        this.progress=0;
        this.closed=true;
      }
    },
  },
  mounted() {
  },
}
</script>
