<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>

    <section v-if="registration" class="content-tabs">
      <div class="content-tabs-container">
        <div class="cs-tri">
          <div>Profile: <strong>{{ registration.profileNumber }}</strong></div>
          <div>Name: <strong>{{ registration.type === 'Personal' ? registration.data.firstName + " " + registration.data.lastName :  registration.data.legal_name}}</strong></div>
          <div>Status: <strong>{{ status }}</strong></div>
          <div>Updated: <strong>{{ registration.updatedAt | date }}</strong></div>
        </div>
        <ul class="ctabs first-row">
          <li
            v-for="tab in tabs[1]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
        <ul class="ctabs second-row">
          <li
            v-for="tab in tabs[2]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </section>

    <section class="client-properties active">
      <registration-data v-if="activeTab === 'Boarding'" :id="id" />
      <general-info v-if="activeTab === 'General'" :id="id" />
      <profile-info v-if="activeTab === 'Profile'" :id="id" />
      <profile-documents v-if="activeTab === 'Documents'" :id="id"/>
      <notes v-if="activeTab === 'Notes'" :id="id" />
      <emails v-if="activeTab === 'Emails'" :id="id" />
      <access v-if="activeTab === 'Access'" :id="id" />
      <accounts v-if="activeTab === 'Accounts'" :id="id" />
      <compliance v-if="activeTab === 'Compliance'" :id="id" />
      <services v-if="activeTab === 'Services'" :id="id" />
      <settings v-if="activeTab === 'Settings'" :id="id" />
      <card-services v-if="activeTab === 'Card Services'" :id="id" />
    </section>
  </div>
</template>

<script>
import helperMixin from '../../components/common/helper-mixin'

import Notes from './Notes'
import Emails from './Emails'
import Access from './Access'
import Accounts from './Accounts'
import Services from './Services'
import Settings from './Settings'
import Compliance from './Compliance'
import GeneralInfo from './GeneralInfo'
import ProfileInfo from './ProfileInfo'
import ProfileDocuments from './ProfileDocuments'
import RegistrationData from './RegistrationData'
import CardServices from './CardServices.vue'

export default {
  name: 'CustomerRegistration',

  mixins: [helperMixin],

  components: {
    Notes,
    Access,
    Emails,
    Services,
    Accounts,
    Settings,
    Compliance,
    GeneralInfo,
    ProfileInfo,
    RegistrationData,
    ProfileDocuments,
    CardServices
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    },

    page: {
      type: String,
      default: 'registrations'
    }
  },

  data () {
    return {
      activeTab: 'Boarding'
    }
  },

  computed: {
    status () {
      let names = {}
      if (this.page === 'profiles') {
        names = {
          Complete: 'Activation'
        }
      }
      // TO DO: When Custom Accounts page comes up
      // if (this.page === 'accounts') {
      //   names = {
      //     Complete: 'Pending',
      //     Active: 'Open'
      //   }
      // }
      
      return names[this.registration?.status] || this.registration?.status
    },

    profileId () {
      return this.isComplete ? this.registration?.mainAccount?.profileNumber : this.registration?.regId
    },

    isComplete () {
      let status =['Complete', 'Active', 'Restricted', 'Suspended', 'Dormant', 'Closed', 'Activation', 'Review', 'Hold']
      return  status.includes(this.registration?.status)
    },

    tabs () {
      // if (!this.isComplete) return {
      //   1: ['Registration', 'Documents', 'Notes', 'Emails'],
      //   2: []
      // }

      //if(this.isComplete) {
        if(this.registration.accounts[this.registration.accounts.length-1]?.complianceScan?.confirm === 'Pass') {
          return {
            1: ['General', 'Profile', 'Documents', 'Notes', 'Emails'],
            2: ['Access', 'Compliance', 'Accounts', 'Settings']
          }
        }else{
          return {
            1: ['Boarding', 'General', 'Profile', 'Documents', 'Notes', 'Emails'],
            2: ['Access', 'Compliance', 'Accounts', 'Settings']
          }
        }
      //}

    },

    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },

  async created () {
    await this.$store.dispatch('registrations/get', this.id)
    await this.$store.dispatch('profilenotes/load', { params: { registrationId: this.id } })

    const tab = this.getUrl('tab')
    if (this.tabs[1].concat(this.tabs[2]).includes(tab)) {
      this.activeTab = tab
    }
    if(this.registration.accounts[this.registration.accounts.length-1]?.complianceScan?.confirm === 'Pass') {
      this.activeTab = 'General'
    }
  }
}
</script>
