<template>
  <div>
    <template v-if="open">
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>
      <template v-if="featureStatus !=null && !featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Account Reconciliation</h3>
      </template>
      <template v-else>
        <template v-if="perms">
          <button class="btn btn-success" @click="openModal()"><i class="el-icon-money" /> New Reconciliation</button>
        </template>
        <section v-if="perms" class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Account</th>
              <th>Currency</th>
              <th>Available</th>
              <th>Balance</th>
              <th>Status</th>
              <th>Provider Available</th>
              <th>Provider Balance</th>
              <th>Provider Status</th>
              <th>Options</th>
            </tr>
            <template v-if="currencyType == 'Funds'">
              <template v-if="accounts.length > 0 && (accounts[0].serviceProviders.provider_name == 'Solid' || accounts[0].serviceProviders.provider_name == 'Solid Test')">
                <tr v-for="(item, i) in accounts" :class="getSystemFundsAvailableBalance(item) != getProviderFundsAvailableBalance(item) ?'highlight-yellow':''" v-if="item.accountStatus != 'Pending' && statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed'">
                  <td><span>Account</span>
                    <a v-if="!(item.regCorp == 1)" :href="`/system-customers/registrations/${item.regId}`">{{item.accountNumber}}</a>
                    <template v-else>
                      {{item.accountNumber}}
                    </template>
                  </td>
                  <td><span>Currency</span>{{ currencyName }}</td>
                  <td><span>Available</span>{{ currencySymbol }}{{getSystemFundsAvailableBalance(item) | balance_without_symbol}}</td>
                  <td><span>Total</span>{{ currencySymbol }}{{getSystemFundsTotalBalance(item) | balance_without_symbol}}</td>
                  <td><span>Account Status</span><em :class="statusColorClasses[getStatus(item.accountStatus)]">
                    {{ getStatus(item.accountStatus) }}</em></td>
                  <td><span>Provider Available</span>{{ currencySymbol }}{{getProviderFundsAvailableBalance(item) | balance_without_symbol}}</td>
                  <td><span>Provider Balance</span>
                    <template v-if="getProviderFundsTotalBalance(item)">{{ currencySymbol }}{{getProviderFundsTotalBalance(item) | balance_without_symbol}}</template>
                  </td>
                  <td><span>Provider Account Status</span>
                    <template v-if="item.solidAccount.length > 0">
                      <em :class="statusColorClasses[getStatus(item.solidAccount[0].accountStatus)]">
                      {{ getStatus(item.solidAccount[0].accountStatus) }}
                      </em>
                    </template>
                  </td>
                  <td><span>Options</span>
                  </td>
                </tr>
                <tr>
                  <td><b>Totals</b></td>
                  <td></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsAvailable | balance_without_symbol }}</b></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsBalance | balance_without_symbol }}</b></td>
                  <td></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsProviderAvailable | balance_without_symbol }}</b></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsProviderBalance | balance_without_symbol }}</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item, i) in accounts" :class="getSystemFundsAvailableBalance(item) != getProviderFundsAvailableBalance(item) || getSystemFundsTotalBalance(item) != getProviderFundsTotalBalance(item)?'highlight-yellow':''" v-if="item.accountStatus != 'Pending' && statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed'">
                  <td><span>Account</span>
                    <a v-if="!(item.regCorp == 1)" :href="`/system-customers/registrations/${item.regId}`">{{item.accountNumber}}</a>
                    <template v-else>
                      {{item.accountNumber}}
                    </template>
                  </td>
                  <td><span>Currency</span>{{ currencyName }}</td>
                  <td><span>Available</span>{{ currencySymbol }}{{getSystemFundsAvailableBalance(item) | balance_without_symbol}}</td>
                  <td><span>Total</span>{{ currencySymbol }}{{getSystemFundsTotalBalance(item) | balance_without_symbol}}</td>
                  <td><span>Account Status</span><em :class="statusColorClasses[getStatus(item.accountStatus)]">
                    {{ getStatus(item.accountStatus) }}</em></td>
                  <td><span>Provider Available</span>{{ currencySymbol }}{{getProviderFundsAvailableBalance(item) | balance_without_symbol}}</td>
                  <td><span>Provider Balance</span>
                    <template v-if="getProviderFundsTotalBalance(item)">{{ currencySymbol }}{{getProviderFundsTotalBalance(item) | balance_without_symbol}}</template>
                  </td>
                  <td><span>Provider Account Status</span><em :class="statusColorClasses[getStatus(item.result)]">
                    {{ getStatus(item.result) }}</em></td>
                  <td><span>Options</span>
                  </td>
                </tr>
                <tr>
                  <td><b>Totals</b></td>
                  <td></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsAvailable | balance_without_symbol }}</b></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsBalance | balance_without_symbol }}</b></td>
                  <td></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsProviderAvailable | balance_without_symbol }}</b></td>
                  <td><b>{{ currencySymbol }} {{ totalFundsProviderBalance | balance_without_symbol }}</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </template>
            <template v-else-if="currencyType == 'Assets'">
              <tr v-for="(item, i) in accounts" :class="getSystemAssetsAvailableBalance(item) != getProviderAssetsAvailableBalance(item) || getSystemAssetsTotalBalance(item) != getProviderAssetsTotalBalance(item)?'highlight-yellow':''" v-if="item.accountStatus != 'Pending' && statusAssetsActivated(item) && item.accountStatus != 'Closed'">
                <td><span>Account</span>
                  <a v-if="!(item.regCorp == 1)" :href="`/system-customers/registrations/${item.regId}`">{{item.accountNumber}}</a>
                  <template v-else>
                    {{item.accountNumber}}
                  </template>
                </td>
                <td><span>Currency</span>{{ currencyName }}</td>
                <td><span>Available</span>{{getSystemAssetsAvailableBalance(item)}}</td>
                <td><span>Total</span>{{getSystemAssetsTotalBalance(item)}}</td>
                <td><span>Account Status</span><em :class="statusColorClasses[getStatus(item.accountStatus)]">
                  {{ getStatus(item.accountStatus) }}</em></td>
                <td><span>Provider Available</span>{{getProviderAssetsAvailableBalance(item)}}</td>
                <td><span>Provider Balance</span>{{getProviderAssetsTotalBalance(item)}}</td>
                <td><span>Provider Account Status</span><em :class="statusColorClasses[getStatus(item.result)]">
                  {{ getStatus(item.result) }}</em></td>
                <td><span>Options</span>
                </td>
              </tr>
              <tr>
                <td><b>Totals</b></td>
                <td></td>
                <td><b>{{ totalAssetsAvailable }}</b></td>
                <td><b>{{ totalAssetsBalance }}</b></td>
                <td></td>
                <td><b>{{ totalAssetsProviderAvailable }}</b></td>
                <td><b>{{ totalAssetsProviderBalance }}</b></td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </table>
        </section>
      </template>
    </template>
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Finance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
    <reconcile-form
      v-if="modalType"
      :modal-type="modalType"
      @close="modalType = null" />
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import ReconcileForm from './ReconcileForm.vue'

  export default {
    name: 'reconcile-accounts',

    components: {
      ReconcileForm,
    },

    data() {
      return {
        open: true,
        pin: '',
        pinFailed: false,
        featureStatus: null,
        modalType: null,
        currencyId: null,
        currencyType: null,
        perms: window.appData.currentUser?.role?.perms?.includes('reconcile-accounts') || window.appData.currentUser?.perms?.includes('reconcile-accounts')
      }
    },

    computed: {
      accounts() {
        return this.$store.state.accounts.data || []
      },

      statusColorClasses() {
        return {
          Open: 'c1',
          Restricted: 'c2',
          Pending: 'c2',
          Closed: 'c4',
          Error: 'c4',
          Dormant: 'c3',
          Opened: 'c1',
          Active: 'c1',
        }
      },

      currencyName() {
        let currencies = window.currencies || []
        let currency = currencies.find(item => item.id == this.currencyId)
        if(currency) {
          return `${currency.currencyNickname}-${currency.name}`
        }else{
          return null
        }
      },

      currencyNickName() {
        let currencies = window.currencies || []
        let currency = currencies.find(item => item.id == this.currencyId)
        if(currency) {
          return currency.currencyNickname
        }else{
          return null
        }
      },

      currencySymbol() {
        let currencies = window.currencies || []
        let currency = currencies.find(item => item.id == this.currencyId)
        if(currency) {
          return currency.symbol
        }else{
          return null
        }
      },

      totalFundsAvailable() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed') {
            if(this.currencyNickName == 'USD') {
              total += item.systemAvailableBalance? parseFloat(item.systemAvailableBalance) : 0
            }else{
              let fundsCurrencies = item.fundsCurrencies || []
              fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
                return fundsCurrency.currency.currencyNickname === this.currencyNickName
              })
              total += fundsCurrencies[0]? parseFloat(fundsCurrencies[0].systemAvailableBalance) : 0
            }
          }
          
        })
        return total
      },

      totalFundsBalance() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed') {
            if(this.currencyNickName == 'USD') {
              total += item.systemTotalBalance? parseFloat(item.systemTotalBalance) : 0
            }else{
              let fundsCurrencies = item.fundsCurrencies || []
              fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
                return fundsCurrency.currency.currencyNickname === this.currencyNickName
              })
              total += fundsCurrencies[0]? parseFloat(fundsCurrencies[0].systemTotalBalance) : 0
            }
          }
          
        })
        return total
      },

      totalFundsProviderAvailable() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed') {
            if(this.currencyNickName == 'USD') {
              total += item.providerAvailableBalance? parseFloat(item.providerAvailableBalance) : 0
            }else{
              let fundsCurrencies = item.fundsCurrencies || []
              fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
                return fundsCurrency.currency.currencyNickname === this.currencyNickName
              })
              total += fundsCurrencies[0]? parseFloat(fundsCurrencies[0].providerAvailableBalance) : 0
            }
          }
          
        })
        return total
      },

      totalFundsProviderBalance() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusCurrencyActivated(item) && item.accountStatus != 'New' && item.accountStatus != 'Closed') {
            if(this.currencyNickName == 'USD') {
              total += item.providerTotalBalance? parseFloat(item.providerTotalBalance) : 0
            }else{
              let fundsCurrencies = item.fundsCurrencies || []
              fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
                return fundsCurrency.currency.currencyNickname === this.currencyNickName
              })
              total += fundsCurrencies[0]? parseFloat(fundsCurrencies[0].providerTotalBalance) : 0
            }
          }
          
        })
        return total
      },

      totalAssetsAvailable() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusAssetsActivated(item) && item.accountStatus != 'Closed') {
            let assetsWallets = item.assetsWallets || []
            assetsWallets = assetsWallets.filter(assetsWallet => {
              return assetsWallet.currency.currencyNickname === this.currencyNickName
            })
            total += assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].systemAssetAvailableBalance) : 0
          }
        })
        return total
      },

      totalAssetsBalance() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusAssetsActivated(item) && item.accountStatus != 'Closed') {
            assetsWallets = assetsWallets.filter(assetsWallet => {
              return assetsWallet.currency.currencyNickname === this.currencyNickName
            })
            total += assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].systemAssetTotalBalance) : 0
          }
        })
        return total
      },

      totalAssetsProviderAvailable() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusAssetsActivated(item) && item.accountStatus != 'Closed') {
            assetsWallets = assetsWallets.filter(assetsWallet => {
              return assetsWallet.currency.currencyNickname === this.currencyNickName
            })
            total += assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].providerAssetAvailableBalance) : 0
          }
        })
        return total
      },

      totalAssetsProviderBalance() {
        var total = 0
        this.accounts.forEach(item => {
          if(item.accountStatus != 'Pending' && this.statusAssetsActivated(item) && item.accountStatus != 'Closed') {
            assetsWallets = assetsWallets.filter(assetsWallet => {
              return assetsWallet.currency.currencyNickname === this.currencyNickName
            })
            total += assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].providerAssetTotalBalance) : 0
          }
        })
        return total
      },
    },
    

    async created() {
      await this.getFeatureStatus();
    },

    methods: {
      getStatus(status) {
        const names = {
          Complete: 'Pending',
          Active: 'Open',
          active: 'Active',
          400: 'Error'
        }
        return names[status] || status
      },

      cancel() {
        window.history.back()
      },

      async checkPin() {
        const action = (await api.action("Pin", "new", "CheckFinancePin", { pin: this.pin })).action
        if(action.pin) {
          this.pinFailed = false
          this.open = false
        }else{
          this.pinFailed = true
        }
      },

      pinInput: function(e) {
        if (e.keyCode === 13) {
          this.checkPin()
        }
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Account Reconciliation']
      },

      openModal () {
        this.modalType = 'Open'
      },

      statusCurrencyActivated(item) {
        if(this.currencyNickName == 'USD') {
          return true
        }else{
          let fundsCurrencies = item.fundsCurrencies || []
          fundsCurrencies = fundsCurrencies.find(fundsCurrency =>  fundsCurrency.currency.currencyNickname === this.currencyNickName)
          if(fundsCurrencies) {
            return fundsCurrencies.currencyEnabled
          }else{
            return false
          }
        }
      },

      statusAssetsActivated(item) {
        let assetsWallets = item.assetsWallets || []
        assetsWallets = assetsWallets.filter(assetsWallet => {
          return assetsWallet.currency.currencyNickname === this.currencyNickName
        })
        if(assetsWallets[0]) {
          if(assetsWallets[0].incomingAssetWalletAddress) {
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },

      getSystemFundsAvailableBalance(item) {
        if(this.currencyNickName == 'USD') {
          return item.systemAvailableBalance
        }else{
          let fundsCurrencies = item.fundsCurrencies || []
          fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
            return fundsCurrency.currency.currencyNickname === this.currencyNickName
          })
          return fundsCurrencies[0]? fundsCurrencies[0].systemAvailableBalance : null
        }
      },

      getSystemFundsTotalBalance(item) {
        if(this.currencyNickName == 'USD') {
          return item.systemTotalBalance
        }else{
          let fundsCurrencies = item.fundsCurrencies || []
          fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
            return fundsCurrency.currency.currencyNickname === this.currencyNickName
          })
          return fundsCurrencies[0]? fundsCurrencies[0].systemTotalBalance : null
        }
      },

      getProviderFundsAvailableBalance(item) {
        if(this.currencyNickName == 'USD') {
          return item.providerAvailableBalance
        }else{
          let fundsCurrencies = item.fundsCurrencies || []
          fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
            return fundsCurrency.currency.currencyNickname === this.currencyNickName
          })
          return fundsCurrencies[0]? fundsCurrencies[0].providerAvailableBalance : null
        }
      },

      getProviderFundsTotalBalance(item) {
        if(this.currencyNickName == 'USD') {
          return item.providerTotalBalance
        }else{
          let fundsCurrencies = item.fundsCurrencies || []
          fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
            return fundsCurrency.currency.currencyNickname === this.currencyNickName
          })
          return fundsCurrencies[0]? fundsCurrencies[0].providerTotalBalance : null
        }
      },

      getSystemAssetsAvailableBalance(item) {
        let assetsWallets = item.assetsWallets || []
        assetsWallets = assetsWallets.filter(assetsWallet => {
          return assetsWallet.currency.currencyNickname === this.currencyNickName
        })
        return assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].systemAssetAvailableBalance) : null
      },

      getSystemAssetsTotalBalance(item) {
        let assetsWallets = item.assetsWallets || []
        assetsWallets = assetsWallets.filter(assetsWallet => {
          return assetsWallet.currency.currencyNickname === this.currencyNickName
        })
        return assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].systemAssetTotalBalance) : null
      },

      getProviderAssetsAvailableBalance(item) {
        let assetsWallets = item.assetsWallets || []
        assetsWallets = assetsWallets.filter(assetsWallet => {
          return assetsWallet.currency.currencyNickname === this.currencyNickName
        })
        return assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].providerAssetAvailableBalance) : null
      },

      getProviderAssetsTotalBalance(item) {
        let assetsWallets = item.assetsWallets || []
        assetsWallets = assetsWallets.filter(assetsWallet => {
          return assetsWallet.currency.currencyNickname === this.currencyNickName
        })
        return assetsWallets[0]? Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(assetsWallets[0].providerAssetTotalBalance) : null
      },
    },

    validations () {

    }
  }
</script>

<style lang="scss" scoped>
  .content-tlist {
    border: none;
  }
</style>