<template>
  <div>
    <button v-if="$hasPerm('status-update')" class="btn main" :disabled="getStatus(registration.status) == 'Active' || getStatus(registration.status) == 'Activation' || getStatus(registration.status) == 'Closed'" @click="openStatus()">Active</button>
    <button v-if="$hasPerm('status-update')" class="btn orange-btn" :disabled="getStatus(registration.status) == 'Review' || getStatus(registration.status) == 'Activation' || getStatus(registration.status) == 'Closed'" @click="openStatusReview()">Review</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Restricted' || getStatus(registration.status) == 'Activation' || getStatus(registration.status) == 'Closed'" @click="openStatusRestricted()">Restricted</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Hold' || getStatus(registration.status) == 'Activation' || getStatus(registration.status) == 'Closed'" @click="openStatusHold()">Hold</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Dormant' || getStatus(registration.status) == 'Activation' || getStatus(registration.status) == 'Closed'" @click="openStatusDormant()">Dormant</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Closed' || getStatus(registration.status) == 'Active' || getStatus(registration.status) == 'Review' || getStatus(registration.status) == 'Restricted' || getStatus(registration.status) == 'Activation'" @click="openStatusClosed()">Closed</button>

    <section style="margin: 20px 0;">
      <div class="cof-box">
        <div class="cof-form">
          <a class="cof-close"></a>
          <div class="cof-row">
            <div class="cof-field">
              <label>Date Search</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="form.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>&nbsp;&nbsp;
                <button class="main" @click="search()">Search</button>&nbsp;&nbsp;
                <button class="second" @click="resetItem()">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist" style="margin: 20px 0;">
      <table class="content-table">           
        <tr>
          <th>Date/Time</th>              
          <th>By</th>
          <th>Status</th>
          <th>Status Update</th>
        </tr>
        <tr v-for="(item, i) in histories" :key="i">
          <td>{{ item.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
          <td>{{ item.addedBy }}</td>
          <td>{{ item.beforeStatus }}</td>
          <td>{{ item.updateStatus }}</td>
        </tr>
      </table>
    </section>

    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Compliance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  components: {
    DateTimePicker
  },

  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    form: {
      fromDate: null,
      toDate: null,
    },
    appliedFilters: null,
    open: false,
    pin: '',
    pinFailed: false,
    popupStatus: null,
    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
  }),

  computed: {
    histories () {
      let data =  this.$store.state.account_status_history.data || []
      return data
    },

    users () {
      return this.$store.getters['users/list'].filter(user => parseInt(user.id) === parseInt(this.registration.user.id) || parseInt(user.subuserOf) === parseInt(this.registration.user.id))
    }
  },

  async created () {
    await this.getHistories();
    if (this.registration?.user?.id) {
      this.$store.dispatch('users/get', this.registration.user.id)
      this.$store.dispatch('users/load', { params: { subuserOf: this.registration.user.id } })
    }
  },

  methods: {
    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
      }
      return names[status] || status
    },

    async getHistories() {
      const loader = this.$loading.show()
      let params = {}
      if (!this.appliedFilters) {
        params.createdAtAfter=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"), "createdAt"];
      }else{
        if (this.appliedFilters.fromDate)
          params.createdAtAfter=[">=", this.appliedFilters.fromDate+" 00:00:00", "createdAt"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore=["<=", this.appliedFilters.toDate+" 23:59:59", "createdAt"];
      }
      params.accountId = this.registration.mainAccount?.id
      await this.$store.dispatch('account_status_history/load', { order: ['createdAt:DESC'], params })
      loader.hide()
    },

    async updateStatus(status) {
      const loader = this.$loading.show()
      let payload = {
        status: status
      }

      if(status == 'Closed') {
        for (const account of this.registration.accounts) {
          const action = (await api.action("Account", account.id, "SetStatusClosed", { status: status })).action
          if(action.error) {
            this.$toast.error("Account Balances are not $0.00. Cannot complete")
            loader.hide()
            return false;
          }else if(action.errorOwedFee) {
            this.$toast.error("Has Owed Fee Balance. Cannot complete")
            loader.hide()
            return false;
          }
        }

        for(const user of this.users) {
          await this.$store.dispatch('users/action', { id: user.id, action: 'Block' })
        }

        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            status: status
          }
        })

      }else if(status != 'Review'){

        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            status: status
          }
        })

        this.registration.accounts.forEach(account => {
          this.$store.dispatch('accounts/action', {
            id: account.id,
            action: 'SetStatus',
            payload
          })
        })

      }else{

        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            status: status
          }
        })

      }

      await this.$store.dispatch('registrations/get', this.registration.id)
      this.getHistories()
      loader.hide()
    },

    openStatus () {
      this.open = true
      this.popupStatus = 'Active'
    },

    openStatusRestricted () {
      this.open = true
      this.popupStatus = 'Restricted'
    },

    openStatusReview () {
      this.open = true
      this.popupStatus = 'Review'
    },

    openStatusHold () {
      this.open = true
      this.popupStatus = 'Hold'
    },

    openStatusDormant () {
      this.open = true
      this.popupStatus = 'Dormant'
    },

    async openStatusClosed () {
      this.open = true
      this.popupStatus = 'Closed'
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
        this.pin = null
        if(this.popupStatus == 'Active') {
          this.updateStatus('Active')
        }else if(this.popupStatus == 'Review'){
          this.updateStatus('Review')
        }else if(this.popupStatus == 'Hold'){
          this.updateStatus('Hold')
        }else if(this.popupStatus == 'Dormant'){
          this.updateStatus('Dormant')
        }else if(this.popupStatus == 'Closed'){
          this.updateStatus('Closed')
        }else{
          this.updateStatus('Restricted')
        }
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    cancel() {
      this.open = false
      this.pinFailed = false
      this.pin = null
    }
  },

  watch: {
    appliedFilters () {
      this.getHistories();
    },
  },
}
</script>

<style type="scss" scoped>
  .orange-btn {
    background: #ffa700 !important;
    color: white !important;
  }
</style>