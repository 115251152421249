<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section>
      <div class="text-center">
        Latest Version
        <input type="text" v-if="logs.length>0" v-model="logs[0].version" :disabled="!updateLatestVersion" />
<!--        <button v-if="!updateLatestVersion" class="main" @click="updateLatestVersion = true">Update</button>
        <button v-else class="main" @click="saveLatestVersion()">Save</button>-->
      </div>
    </section>
    <section class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="cof-field">
            <mnb-input
              v-model="form.version"
              label="Version" />
            <span v-if="$v.form.version.$dirty && $v.form.version.$invalid" class="error">This field is required !</span>
          </div>
          <div class="full-w">
            <mnb-textarea
              v-model="form.description"
              label="Description"
              @input="mixin_autoResize_resize"
              :classes="{ 'full': true, 'noResize': true }"
              full/>
            <span v-if="$v.form.description.$dirty && $v.form.description.$invalid" class="error">This field is required !</span>
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Change Log</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetField()">Reset Fields</button>
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field">
            <label>Date Search</label>
            <div class="cof-multi flex">
              <date-time-picker
                v-model="searchForm.fromDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                :max-date="today" >
              </date-time-picker>
              <span>To</span>
              <date-time-picker
                v-model="searchForm.toDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                :max-date="today" >
              </date-time-picker>
              &nbsp;
              <button class="main" @click="search()">Search</button>
              &nbsp;
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">            
        <tr>                
          <th style="width: 20%">Date</th>
          <th style="width: 20%">Version</th>
          <th style="width: 40%">Description</th>
          <th style="width: 20%">Options</th>
        </tr>
        <tr v-for="(item, i) in logs" :key="i">
          <template>
            <td><span>Date</span>{{ item.createdAt | date('dddd, MMMM D YYYY') }}</td>
            <td>
              <span>Version</span>

              <template v-if="!isEdit(i)">{{ item.version }}</template>
              <mnb-input
                v-else
                :ref="`input_${i}`"
                v-model="editingVersion[i]"/>
            </td>
            <td style="width: 500px; padding-right: 10px;">
              <span>Description</span>
              <mnb-textarea
                v-if="!isEdit(i)"
              v-model="item.description"
              :classes="{'full': true }"
                :disabled="true"
              @input="mixin_autoResize_resize"
              full/>
              <mnb-textarea
                v-else
                v-model="editingText[i]"
                :classes="{ 'full': true, 'noResize': true }"
                @input="mixin_autoResize_resize"
                full/>
            </td>
            <td>
              <span>Options</span>
              <template v-if="isEdit(i)">
                <button @click="saveUpdate(i)" class="action-button save">Save</button>
                <button @click="cancelEdit(i)" class="action-button cancel">Cancel</button>
              </template>
              <template v-else>
                <button @click="editLog(i)" class="action-button update">Update</button>
                <button @click="deleteLog(item.id)" class="action-button remove">Remove</button>
              </template>
            </td>
          </template>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { alphaVersion } from '../../lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbTextarea from '../../components/ui/forms/Textarea'

import mixinAutoResize from "../../components/ui/forms/autoresize.js"
import inputMixin from "../../components/ui/forms/input-mixin.js"
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  name: 'system-change-log',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbTextarea,
    DateTimePicker
  },

  data() {
    return {
      form: {
        version: null,
        description: null
      },
      editing: [],
      editingText: {},
      editingVersion: {},
      updateLatestVersion: false,
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      loading: false,
      appliedFilters: null,
    }
  },

  computed: {
    logs () {
      const data = this.$store.state.changeLog.data || []
      return data
    }
  },

  async created () {
    await this.refreshLogs()
  },

  methods: {
    search () {
      this.appliedFilters = { ...this.searchForm }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
    },

    async refreshLogs() {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (!this.appliedFilters) {
        const today = new Date()
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var startDate = yyyy + '-' + mm + '-' + '01';
        var endDate = yyyy + '-' + mm + '-' + '31';
        params.createdAtAfter = [">=", startDate + " 00:00:00", "createdAt"];
        params.createdAtBefore = ["<=", endDate + " 23:59:59", "createdAt"];
      } else {
        if (this.appliedFilters.fromDate)
          params.createdAtAfter = [">=", this.appliedFilters.fromDate + " 00:00:00", "createdAt"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore = ["<=", this.appliedFilters.toDate + " 23:59:59", "createdAt"];
      }
      await this.$store.dispatch('changeLog/load', { order: ['createdAt:DESC'], params})
      this.loading=false;
      loader.hide()
    },

    isEdit (index) {
      return this.editing.includes(index)
    },
    
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = {
            version: this.form.version,
            description: this.form.description,
            createdAt: 'CURRENT_TIMESTAMP'
          }
          
          await this.$store.dispatch('changeLog/create', payload)
          await this.refreshLogs()
          this.$toast.success('Change Log successfully created.')
          this.form.version = null
          this.form.description = null
          this.$v.$reset()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    resetField() {
      this.form.version = null
      this.form.description = null
    },

    async deleteLog(id) {
      if(confirm(`Are you sure to delete Change Log?`)) {
        this.$store.dispatch('changeLog/delete', id)
        await this.refreshLogs()
        this.$toast.success('Change Log deleted successfully.')
      }
    },

    async saveUpdate (i) {
      if (this.editingText[i]) {
        try {
          const payload = {
            id: this.logs[i].id,
            payload: {
              version: this.editingVersion[i],
              description: this.editingText[i]
            }
          }
          
          await this.$store.dispatch('changeLog/update', payload)
          await this.refreshLogs()
          this.$toast.success('Change Log successfully updated.')
          this.cancelEdit(i)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      delete this.editingText[index]
      delete this.editingVersion[index]
    },

    editLog(index) {
      this.editing.push(index)
      this.editingText[index] = this.logs[index].description
      this.editingVersion[index] = this.logs[index].version
      this.$nextTick(() => this.$refs[`input_${index}`][0].$el.getElementsByTagName('input')[0].focus())
    },

    async saveLatestVersion () {
      const payload = {
        id: this.logs[0].id,
        payload: {
          version: this.logs[0].version
        }
      }

      await this.$store.dispatch('changeLog/update', payload)
      await this.$store.refreshLogs()
      this.updateLatestVersion = false
    },
  },

  watch: {
    appliedFilters() {
      this.refreshLogs();
    },
  },

  validations: {
    form: {
      version: { required, alphaVersion },
      description: { required }
    }
  }
}
</script>
<style lang="scss" scoped>
  .error {
    display: block;
    margin-top: 5px;
    color: #d23131;
  }
</style>
