<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <h2>Partner Settings</h2>
    <template v-if="featureFundsStatus != null">
      <table class="content-table">
        <template v-if="hideVerificationPermissionsReview">
          <tr class="space">
            <th width="200"></th>
            <th>Registration Tracking</th>
          </tr>
          <tr class="content-filters">
            <td><span>Verifications</span> Enable</td>
            <td><span>Administrative</span>
              <mnb-switch v-model="form.registrationTracking" :validator="$v.form.registrationTracking" :disabled="!updateSettings" />
            </td>
            <td colspan="2"></td>
          </tr>
          <tr class="space">
            <th width="200">Permissions</th>
            <th>Create Sub-Users</th>
            <th colspan="2"></th>
          </tr>
          <tr class="content-filters">
            <td><span>Permissions</span> Enable</td>
            <td><span>Create Sub-Users</span>
              <mnb-switch v-model="form.create_subusers" :validator="$v.form.create_subusers" :disabled="!updateSettings" />
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
        <tr class="space">
          <th width="200">Options</th>
          <th>Send Confirmation Emails</th>
          <th v-if="hideVerificationPermissionsReview">Partner Review Days</th>
          <th>Profile Language</th>
        </tr>
        <tr class="content-filters">
          <td></td>
          <td><span>Send Confirmation Emails</span>
            <mnb-switch v-model="form.welcome_email" :validator="$v.form.welcome_email" :disabled="!updateSettings" />
          </td>
          <td v-if="hideVerificationPermissionsReview"><span>Profile Review Days</span>
            <mnb-select v-if="updateSettings" v-model="form.review_days" :validator="$v.form.review_days" class="select"  :options="reviewOptions" />
            <template v-else>{{ form.review_days }}</template>
          </td>
          <td><span>Profile Language</span>
            <mnb-select v-if="updateSettings" v-model="form.language" :validator="$v.form.language" class="select"  :options="languageOptions" />
            <template v-else>{{ form.language }}</template>
          </td>
        </tr>
      </table>
    </template>

    <div v-if="noDateUpdate" class="footer space">
      <span>
        <b>&nbsp;</b><br>
        <template v-if="updateSettings">&nbsp;</template>
        <template v-else>&nbsp;<br>&nbsp;</template>
      </span>
      <div>
        <template v-if="$hasPerm('partners-update')">
          <button v-if="updateSettings" class="main" @click="save()">Save</button>
          <button v-else class="second" @click="update()">Update</button>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MnbSwitch from '../../components/ui/forms/Switch'

import { required } from 'vuelidate/lib/validators'

const initialForm = {
  registrationTracking: false,
  create_subusers: false,

  welcome_email: false,
  review_days: null,
  language: null,
}

export default {
  props: {
    registration: {
      type: Object,
      required: true
    },
    noDateUpdate: {
      type: Boolean,
      default: false
    },
    hideVerificationPermissionsReview: {
      type: Boolean,
      default: false
    },
  },

  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch
  },

  data () {
    return {
      form: { ...initialForm },

      updateSettings: false,

      reviewOptions: [
        {
          id: 30,
          text: 30
        },
        {
          id: 60,
          text: 60
        },
        {
          id: 90,
          text: 90
        },
        {
          id: 180,
          text: 180
        },
        {
          id: 365,
          text: 365
        }
      ],

      languageOptions: [
        {
          id: 'English',
          text: 'English'
        }
      ],

      featureFundsStatus: null,
      featureAssetsStatus: null,
      featureTradeStatus: null,
    }
  },

  computed: {
    profileSettings () {
      return this.registration.settings || null
    },
  },

  async created () {
    if (this.profileSettings) {
      this.form = this.profileSettings
    }
    await this.getFeatureStatus();
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('partners/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            settings: {
              ...this.form,
            }
          }
        })
        await this.setLastUpdatedAt()
        this.updateSettings = false
        this.$toast.success('Partner Settings successfully created.')
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        loader.hide()
      }
    },

    setLastUpdatedAt () {
      return this.$store.dispatch('partner_notes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes: 'Partner settings updated',
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async update () {
      this.updateSettings = true
    },

    async getFeatureStatus () {
      this.featureAssetsStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      this.featureTradeStatus = window.systemFeatures['Trade Desk']
    },
  },

  validations () {
    const rules = {
      form: {
        registrationTracking: { required },
        create_subusers: { required },
        welcome_email: { required },
        review_days: { required },
        language: { required }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 20px; 
  color: #0075be; 
  padding: 10px;
}

.checkbox-large {
  width: 17px;
  height: 17px;
}

.money-input {
  display: flex;
  span {
    display: inline-block;
    width: 15px;
    line-height: 29px;
  }
  .input {
    width: 100px;
  }
}
.select {
  width: 115px;
}

.space {
  td, th {
    padding-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 0 34px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.switch {
  display: inline-block;
  margin-right: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 999px;
  line-height: 20px;
  padding: 3px 3px 3px 10px;
  box-sizing: border-box;
  font-size: 13px;
  color: #666;
  float: left;
  min-width: 90px;
  text-align: right;
}
.switch i {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border-radius: 999px;
  margin-left: 3px;
}
.switch i.green {
  background-color: #67C23A;
}
.switch i.grey {
  background-color: #666;
}

</style>
