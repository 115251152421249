<template>
  <div>
    <section  class="content-tlist" style="margin: 30px 0;">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Registration Date</th>
          <th style="width: 15%;">Name</th>
          <th style="width: 15%;">Profile Number</th>
          <th style="width: 15%;">Account Number</th>
          <th style="width: 15%;">Provider</th>
          <th style="width: 10%;">Result</th>
          <th style="width: 15%;">Options</th>
        </tr>
      </table>
    </section>
    <div class="accordion">
    <section v-if="registration.status == 'Active'" :class="{'cof-box' : true, 'cof-box-active' : activeInfo, 'content-tlist': true}" style="margin: 30px 0;">
      <table class="content-table accordion-headlin" style="font-size: 14px;" @click="activeInfo = !activeInfo">
        <tr>
          <th style="width: 15%;">
            {{ registration.createdAt | date }}
          </th>
          <th style="width: 15%;">
            <template v-if="registration.type == 'Personal'">
              {{ registration.data.firstName }} {{ registration.data.lastName }}
            </template>
            <template v-else>
              {{ registration.data.legal_name }}
            </template>

          </th>
          <th style="width: 15%;">{{ registration.accounts[0].profileNumber }}</th>
          <th style="width: 15%;">
            <template v-if="serviceProviderName == 'Solid' || serviceProviderName == 'Solid Test'">
              <template v-if="solidAccount">{{ solidAccount.accountNumber }}</template>
            </template>
            <template v-else>
              <template v-if="!complianceScan">N/A</template>
              <template v-else>{{ complianceScan.accountNumber }}</template>
            </template>
          </th>
          <th style="width: 15%;">{{ serviceProviderName }}</th>
          <th style="width: 15%;">
            <template v-if="serviceProviderName == 'Solid' || serviceProviderName == 'Solid Test'">
              <template v-if="registration.type != 'Business'">
                <template v-if="complianceScan && complianceScan.kycStatus == 'approved'">Pass</template>
                <template v-if="complianceScan && complianceScan.kycStatus == 'declined'">Declined</template>
              </template>
              <template v-else>
                <template v-if="complianceScan && complianceScan.kycStatus == 'approved' && complianceScan.kybStatus == 'approved'">Pass</template>
                <template v-if="complianceScan && (complianceScan.kycStatus == 'declined' || complianceScan.kybStatus == 'declined')">Declined</template>
              </template>
            </template>
            <template v-else>
              {{ complianceScan ? complianceScan.confirm : '' }}
            </template>
          </th>
          <th></th>
        </tr>
      </table>
      <checklist-steps :registration="registration" />
    </section>
    </div>
  </div>
</template>

<script>
import Documents from './checklist/Documents'
import ComplianceScan from './checklist/ComplianceScan'
import ProfileSettings from './checklist/ProfileSettings'
import ServiceProviders from './checklist/ServiceProviders'
import TermsAndConditions from './checklist/TermsAndConditions'
import AccountConfirmation from './checklist/AccountConfirmation'
import ProfileAccountCreation from './checklist/ProfileAccountCreation'
import ChecklistSteps from './checklist/ChecklistSteps'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    activeInfo: false,
  }),

  components: { 
    Documents,
    ComplianceScan,
    ProfileSettings,
    ServiceProviders,
    TermsAndConditions,
    AccountConfirmation,
    ProfileAccountCreation,
    ChecklistSteps,
  },

  computed: {
    isAvailable() {
      return !!(this.registration.terms &&
        this.registration.confirmation &&
        this.registration.documentsChecklist &&
        this.registration.serviceProviders &&
        this.registration.profileSettings &&
        this.registration.mainAccount)
    },

    serviceProviders() {
      return this.registration?.serviceProviders || null
    },

    complianceScan() {
      return this.registration.accounts[0]?.complianceScan || null
    },

    providers() {
      return this.$store.state.providersList.data || []
    },

    serviceProviderName() {
      return this.serviceProviders?.provider?.provider_name || 'Not Assigned'
    },

    solidAccount() {
      return this.registration.accounts[0]?.solidAccount[0] || null
    },
  },

  async created () {
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    this.provider = this.complianceScan?.provider || null
  },
}
</script>
