<template>
  <div class="content-form content-form-modern">
    <div class="form-row form-split">
      <form-field-select v-model="form.country" label="Country" :validator="v.country" :options="countryOptions" required :disabled="disabledField" />
      <form-field-select v-if="stateOptions" v-model="form.state" label="Province/State" :validator="v.state" :options="stateOptions" required :disabled="disabledField" />
      <form-field-text v-else v-model="form.state" :validator="v.state" label="Province/State" required :disabled="disabledField" />
    </div>
    <div class="form-row form-four">
      <form-field-text v-model="form.address" label="Address 1" :validator="v.address" required :disabled="disabledField" />
      <form-field-text v-model="form.address2" label="Address 2" :validator="v.address2" required :disabled="disabledField" />
      <form-field-text v-model="form.city" label="City" :validator="v.city" required :disabled="disabledField" />
      <form-field-text v-model="form.postal" label="Postal" :validator="v.postal" required :disabled="disabledField" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    disabledField: {
      type: Boolean,
      default: false
    },

    transactionCountries: {
      type: Array,
      required: false
    },
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal'
        },
        {
          id: 'business',
          text: 'Business'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ]
    }
  },

  computed: {
    countryOptions () {
      {
        if(this.transactionCountries) {
          return window.fullCountries.filter(a => {
            return this.transactionCountries.includes(a.id)
          })
        }else{
          return window.countriesOptions
        }
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.country' (value) {
      this.form.state = null
    },

    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 64px;
    cursor: pointer;
    font-size: 13px;
  }

  .form-four >div {
    width: calc(25% - 18px);
    @media screen and (max-width: 760px) {
      width: 100%;
    }
  }
</style>
