<template>
  <div style="display: flex; justify-content: space-around;">
    <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
      <div class="popup-header">
        <h3>Account Link</h3>
      </div>
      <div class="scrollable">
        <template v-if="!next">
          <div class="content-form content-form-modern">
            <div class="form-row form-split">
              <form-field-select-search v-model="form.account" label="Select Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
              <form-field-select v-model="form.linkType" label="What would you like to do" :validator="$v.form.linkType" :options="linkTypeOptions" required />
            </div>
          </div>
          <div class="buttons text-center">
            <button class="main" @click="next = true" :disabled="this.$v.form.$invalid">Next</button>
            <button class="second" @click="open = false">Close</button>
          </div>
        </template>
        <template v-else>
          <template v-if="form.linkType == 'NewAccountLink'">
            <div class="content-form content-form-modern text-center">
              <div class="form-row justify-content-center">
                <form-field-text v-model="formKey.accountKey" label="Enter Account Key to Link" :validator="$v.formKey.accountKey" required />
              </div>
              <div class="form-row justify-content-center">
                <p v-if="account" class="text-center">
                  {{ getAccountNumber(account.accountNumber) }} - {{ account.regName }}
                </p>
                <p v-if="error" class="text-center">
                  Account Key not found.
                  Please check your information and try again.
                </p>
                <p v-if="errorExist" class="text-center">
                  Account Link already exists.
                  Please check your information and try again.
                </p>
                <p v-if="errorProvider" class="text-center">
                  Unable to link to this account.
                </p>
              </div>
            </div>
            <div class="buttons text-center">
              <button class="second" @click="next = false; error = false; errorExist = false; formKey.accountKey = null; account = null">Back</button>
              <button v-if="!account" class="main" @click="checkAccountKey()" :disabled="this.$v.formKey.$invalid">Submit</button>
              <button v-else class="main" @click="link()" :disabled="this.$v.formKey.$invalid">Link</button>
              <button class="second" @click="open = false">Close</button>
            </div>
          </template>
          <template v-if="form.linkType == 'ViewLinkedAccounts'">
            <div class="content-form content-form-modern text-center">
              <div class="form-row justify-content-center">
                <p class="text-center">
                  Linked Accounts
                </p>
              </div>
            </div>
            <table class="content-table">
              <tr>
                <th>Date Added</th>
                <th>By</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Options</th>
              </tr>
              <tr v-for="(item, i) in linkedAccounts" :key="i">
                <td><span>Date</span>{{ item.createdAt | date('MMMM D YYYY') }}</td>
                <td><span>By</span>{{ item.createdBy }}</td>
                <td><span>Account Name</span>{{ item.receiverAccountName }}</td>
                <td><span>Account Number</span>{{ item.receiverAccountNumber }}</td>
                <td><span>Options</span>
                  <button class="main" @click="unlink(item.id)">Unlink</button>
                </td>
              </tr>
            </table>
            <div class="buttons text-center">
              <button class="second" @click="next = false; error = false; errorExist = false; formKey.accountKey = null; account = null">Back</button>
              <button class="second" @click="open = false">Close</button>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalAccountLinkType: {
      type: String,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      form: {
        account: null,
        linkType: null
      },

      formKey: {
        accountKey: null
      },

      open: true,
      next: false,
      error: false,
      account: null,
      errorExist: false,
      errorProvider: false,
      linkedAccounts: []
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const regId = (accounts.find(item => item.id === this.form.account))?.regId
      return this.$store.getters['registrations/get'](regId)
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []

      registrations.forEach(reg => {
        if (reg.accounts?.length) {
          reg.accounts.forEach(account => {
            if(account.serviceProviders?.status != 'Disabled') {
              if(account.accountStatus == 'Open'){
                accounts.push({
                  id: account.id,
                  regId: reg.id,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${reg.name}`,
                  accountNumber: account.accountNumber,
                  provider: account.provider
                })
              }else{
                accounts.push({
                  id: account.id,
                  regId: reg.id,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${reg.name}`,
                  accountNumber: account.accountNumber,
                  provider: account.provider,
                  disabled: true
                })
              }
            }
          })
        }
      })

      if (this.isCustomer) {
        accounts = accounts.filter(account => ['Open'].includes(account.status))
      } else {
        const categories = {}
        const availableStatuses = ['Open', 'Restricted', 'Suspended', 'Dormant']
        availableStatuses.forEach(status => {
          const accs = accounts.filter(account => account.status === status)
          if (accs.length) categories[status] = accs
        })
        return categories
      }

      return accounts
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    linkTypeOptions () {
      const options = [
        {
          id: 'NewAccountLink',
          text: 'New Account Link',
        },
        {
          id: 'ViewLinkedAccounts',
          text: 'View Linked Accounts',
        }
      ]
      return options
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.getLinkedAccounts()
    },
  },

  methods: {
    async checkAccountKey() {
      this.$v.$touch()
      if (this.$v.formKey.$invalid) {
        return
      }
      const loader = this.$loading.show()
      let payload;
      payload = {
        accountKey: this.formKey.accountKey,
        sendingAccount: this.form.account,
      }
      const response = (await api.action("Account", "new", "CheckAccountKey", payload))
      if(response.action.error){
        this.errorExist = false
        this.errorProvider = false
        this.error = true
        loader.hide()
      }else if(response.action.errorExist){
        this.error = false
        this.errorProvider = false
        this.errorExist = true
        loader.hide()
      }else if(response.action.errorProvider){
        this.error = false
        this.errorExist = false
        this.errorProvider = true
        loader.hide()
      }else{
        this.account = response.action.account
        this.error = false
        this.errorExist = false
        this.errorProvider = false
        loader.hide()
      }
    },

    async link() {
      this.$v.$touch()
      if (this.$v.formKey.$invalid) {
        return
      }
      const loader = this.$loading.show()
      const payload = {
        createdBy:  window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        createdAt: 'CURRENT_TIMESTAMP',
        sendingAccount: this.form.account,
        receivingAccount: this.account.id,
      }
      await this.$store.dispatch('linkedAccount/create', payload)
      this.$toast.success('Successfully linked.')
      this.formKey.accountKey = null
      this.account = null
      this.$v.$reset()
      loader.hide()
    },

    async getLinkedAccounts() {
      let params = {
        accountId: this.form.account
      }
      this.linkedAccounts = (await api.action("LinkedAccount", "new", "getLinkedAccounts", params)).action.item
    },

    async unlink(id) {
      const loader = this.$loading.show()
      await this.$store.dispatch('linkedAccount/delete', id)
      let params = {
        accountId: this.form.account
      }
      this.linkedAccounts = (await api.action("LinkedAccount", "new", "getLinkedAccounts", params)).action.item
      this.$toast.success('Successfully unlinked.')
      loader.hide()
    },

    getAccountNumber(accountNumber) {
      let number = ''
      for(var i = 0; i < accountNumber.length-3; i++) {
        number = number + '*'
      }
      number = number + accountNumber.substr(-3)
      return number
    }
  },

  async created () {
    await this.$store.dispatch('registrations/load', {params: { corporate: ['!=', 1] }, skip: ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'profileNumber', 'partnerId', 'currency', 'matched', 'verified', 'boardingApproval', 'createdAt', 'updatedAt', 'approvalDate', 'browser', 'browser_lang', 'os', 'device', 'documentId', 'serviceProvidersId', 'termId', 'confirmId', 'createdBy', 'partner', 'result', 'file', 'cipStatus', 'amlStatus', 'agreementId', 'kycStatus', 'confirm', 'exception', 'providerAccountNumber', 'providerContingentHold', 'providerNonContingentHold', 'providerPendingTransfer', 'linked', 'solidLinked', 'response', 'providerCreatedAt', 'primeAccount', 'solidAccount', 'cipMessage', 'cipMessageDetail', 'amlMessage', 'amlMessageDetail', 'kycMessage', 'kycMessageDetail', 'reviewDetail', 'QPaymentReference', 'statusUpdatedAt', 'accountKey', 'contactFundsTransferReferencesId', 'personId', 'businessId', 'kybStatus', 'idvStatus', 'phoneVerified', 'emailVerified', 'kycResults', 'kybResults', 'idvResults', 'documentResults', 'reviewCode', 'reviewMessage', 'metadata', 'programID', 'idv', 'disclosureStatus', 'members', 'membersKYCResults', 'membersIDVResults', 'membersDocumentResults', 'cards', 'owedFee', 'owedFeeLastUpdate', 'agreementResponse', 'webhookId', 'webhookResponse',  'partnerId', 'chargeOff', 'chargeOffBalance', 'accountPlan', 'feeSchedule', 'feePlan']})
  },

  validations () {
    const rules = {
      form: {
        account: { required },
        linkType: { required },
      },

      formKey: {
        accountKey: { required }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 70px;
  }

  .content-tlist {
    background: none; 
    overflow: unset;
  }
  
  .content-table {
    min-width: unset;
  }
</style>