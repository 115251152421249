<template>
  <section class="form-box reg-form">
    <h1 v-if="!internal" class="logo">
      <img :src="base + system.logo" :alt="`${system.logo} Logo`" />
    </h1>
    <h2 v-if="!internal"><span>{{ system.name }}</span></h2>
    <h3 v-if="!internal" style="margin-bottom: 10px;">PARTNER REGISTRATION</h3>

    <template v-if="system.registration_form">
      <h4 class="app-page-text" v-if="!internal">{{ system.app_page_text }}</h4>

      <template v-if="!submitted">
        <div class="content-form">
          <h4>Partner Type <span>*</span></h4>
          <div class="form-row form-fullw">
            <form-field-select label="Partner Type" :required="true" :options="partnerOptions" v-model="partnerType" />
          </div>
        </div>
        
        <template v-if="partnerType && form">
          <div class="content-form">
            <partner-form
              v-model="form"
              :v="$v.form"
              @add-contact="addContact()"
              @remove-contact="removeContact()"
              @add-document="addDocument()"
              @remove-document="removeDocument()"
              />

            <div class="form-row form-fullw">
              <div class="txtc">
                <p>Do you agree to our:</p>
              </div>
            </div>

            <div class="form-row form-fullw">
              <div class="autoh">
                <ul class="cmarks">
                  <li>
                    <label class="check" :class="{ error: errors.terms }">
                      <input v-model="terms" type="checkbox" />
                      <span></span>
                      <a @click.prevent="termsModal = true">Terms &amp; Conditions</a>
                    </label>
                  </li>
                  <li>
                    <label class="check" :class="{ error: errors.privacy_policy }">
                      <input v-model="privacy_policy" type="checkbox" />
                      <span></span>
                      <a @click.prevent="privacyModal = true">Privacy Policy</a>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-row form-center">
              <div class="">
                <button v-if="!fileError" :disabled="saving" @click="submit()">Submit Partner</button>
                <button v-else @click="retry()">Retry Partner</button>
              </div>
            </div>
          </div>
        </template>
      </template>

      <div v-else class="submitted-note">
        <div v-if="success" class="success">
          Partner Successfully Submitted!
          <div class="register-shade" v-if="internal"></div>
        </div>
        <div v-else class="denied">
          <span>We are unable to complete your request at this time. Please contact support at <b>{{ system.support_email }}</b> or by phone at <b>{{ system.support_phone }}</b> to complete your request.</span>
          <br><br>Thank you, 
          <br><br><b>{{ system.dba_name }}</b>
        </div>
      </div>
    </template>

    <h4 v-else class="app-page-text">{{ system.disabled_form_text }}</h4>

    <popup :open.sync="termsModal">
      <div class="privacy-popup scrollable" v-on:scroll.passive="handleScroll">
        <terms-popup></terms-popup>
      </div>
      <div class="buttons" v-if="submitClicked">
        <button :class="reachTerms? 'main': 'second'" @click="acceptTerms()">Accept</button>
        <button class="second" @click="termsModal = false">Cancel</button>
        <template v-if="showTermsMessage">You must complete to the bottom of the Terms in order to accept.</template>
      </div>
    </popup>
    <popup :open.sync="privacyModal" >
      <div class="privacy-popup scrollable">
        <privacy-popup></privacy-popup>
      </div>
    </popup>
    <popup :open.sync="fileErrorPopup">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>Document upload error</strong></h2>
          <p>
            There was an issue with your registration documents. Please select a new file to upload and click below to retry your registration.
          </p>
          <p>
            Please contact support if you continue to experience issues.
          </p>
        </div>
      </div>
    </popup>
  </section>
</template>

<script>
import helperMixin from './components/common/helper-mixin'
import { mustBeTrue, dashNumbers, alphaNum, alphaVersionNoSpace, alphaOnly, alphaNumNoSpace, alphaVersion, phone, webSiteUrl, addressValidation, alpha } from './lib/validators'
import { required, numeric, email, url, requiredIf, between, minLength } from 'vuelidate/lib/validators'

import UAParser from 'ua-parser-js'
import Popup from './monbi/popup'
import PartnerForm from './RegisterFormPartner'
import PersonalForm from './RegisterForm2Personal'
import PrivacyPopup from './PrivacyPopup.vue'
import TermsPopup from './TermsPopup.vue'

const contact = {
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
}

const document = {
  file: null,
  description: null
}

const forms = {
    legal_name: null,
    dba_name: null,
    description: null,
    country: null,
    address: null,
    address2: null,
    zip: null,
    city: null,
    state: null,
    phone: null,
    email: null,
    website: '',
    contacts: [{ ...contact }],
    documents: [{ ...document }],
}

const fileSizeValidator=function(maxSize) {
  return function (value) {
    if (value==null || value==undefined)
      return true;
    if (!value instanceof File)
      return false;
    return value.size<=maxSize;
  };
}

var maxFileSize=10*1024*1024; // 10 MB

export default {
  mixins: [helperMixin],
  
  props: ["internal"],

  components: {
    Popup,
    PartnerForm,
    PersonalForm,
    PrivacyPopup,
    TermsPopup
  },

  data: ()=>({
    form: null,

    hasAccount: null,
    partnerType: null,
    accountCurrency: 'USD',

    terms: false,
    privacy_policy: false,

    base: '/files/system/',

    submitClicked: false,

    termsModal: false,
    privacyModal: false,
    submitted: false,
    success: false,
    saving: false,
    reachTerms: false,
    showTermsMessage: false,
    fileError: false,
    fileErrorPopup: false,
    regId: null,
    token: null
  }),

  computed: {
    errors () {
      const keys = ['terms', 'privacy_policy']
      return keys.reduce((acc, key) => {
        acc[key] = this.$v[key].$dirty && this.$v[key].$invalid && !this.$v[key].$pending
        return acc
      }, {})
    },

    isPersonal () {
      return this.accountType == 'Personal'
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    ownershipError () {
      if (this.accountType !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.type === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    noOwner () {
      if (this.accountType !== 'Business') return false
      return this.form.contacts.filter(contact => contact.type === 'Beneficial Owner').length==0;
    },

    partnerOptions () {
      let options = [
        {
          id: "Referral",
          text: "Referral"
        },
        // {
        //   id: "Broker",
        //   text: "Broker"
        // },
        // {
        //   id: "Developer",
        //   text: "Developer"
        // }
      ]
      return options
    }
  },

  watch: {
    termsModal (value) {
      if (!value) this.submitClicked = false
    },

    partnerType (partnerType) {
      if (partnerType) {
        this.form = JSON.parse(JSON.stringify(forms))
      }
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
    if (this.internal) {
      this.hasAccount="No";
    }
  },

  methods: {
    acceptTerms () {
      if (this.reachTerms) {
        this.terms = true
        if (this.submitClicked) {
          this.save()
          this.submitClicked = false
        }
        this.termsModal = false
      } else {
        this.showTermsMessage = true
      }
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('You need to fill all required fields. We marked them with red color so that you know what fields you have missed.')
        return
      }
      if (this.ownershipError) {
        this.$toast.error('Ownership Percentage of all Beneficial Owners needs to be a total of 100%')
        return
      }
      if (this.noOwner) {
        this.$toast.error('At least one Beneficial Owner must be selected')
        return
      }
      this.save()
      this.submitClicked = false
      this.termsModal = false
    },

    async save (status) {
      if (this.saving) return
      this.termsModal = false
      this.terms = true
      this.saving = true
      const parser = (new UAParser()).getResult()
      const payload = {
        type: this.partnerType,
        business: this.form,
        createdAt: 'CURRENT_TIMESTAMP',
      }
      const loader = this.$loading.show()
      try {
        const response = await api.request('POST', '/partner', { obj: payload })
        this.regId = response.id
        this.token = response.token
        await this.uploadDocuments(response.id, response.token)

        if(!this.fileError) {
          if (response.status=="Denied") {
            loader.hide();
            this.submitted = true
            this.success = false
            return
          }
          const responseFinal = await api.request('POST', '/partner', { obj: {status: 'New'}, id: this.regId, token: this.token })
          this.submitted = true
          this.success = true
          if (this.internal)
            setTimeout(()=>location.href="/system-partners/partners", 2000);
        } else {
          this.fileErrorPopup = true
        }
      } catch (e) {
        this.$toast.error('There was an unknown error. Please try again later or contact the support.')
      }finally {
        loader.hide()
      }
    },

    async retry (status) {
      const parser = (new UAParser()).getResult()
      const payload = {
        uuid: 'CUSTOM_REGISTRATION_ID',
        type: this.accountType,
        currency: this.accountCurrency,
        data: this.form,
        createdAt: 'CURRENT_TIMESTAMP',
        browser: `${parser.browser.name} ${parser.browser.major}`,
        browser_lang: window.navigator.userLanguage || window.navigator.language,
        device: parser.device.model || parser.device.type ? `${parser.device.model} ${parser.device.type}` : null,
        os: `${parser.os.name} ${parser.os.version}`
      }
      const loader = this.$loading.show()
      try {
        this.fileError = false
        await this.uploadDocuments(this.regId, this.token)

        if(!this.fileError) {
          await api.request('POST', '/register', { obj: {status: 'New'}, id: this.regId, token: this.token })
          this.submitted = true
          this.success = true
          if (this.internal)
            setTimeout(()=>location.href="/system-customers/profiles", 2000);
        } else {
          this.fileErrorPopup = true
        }

      } catch (e) {
        this.$toast.error('There was an unknown error. Please try again later or contact the support.')
      }finally {
        loader.hide()
      }
    },

    async uploadDocuments (id, token) {
      var promises=[];
      {
        this.form.documents.forEach((document, index) => {
          promises.push(this.documentPromise(id, token, document.file, document.description))
        })
      }
      await Promise.all(promises).then(values => {
        console.log('then', values);
        values.forEach(value => {
          if(value.success == false) {
            this.fileError = true
          }
        })
      })
        .catch(err => {
            this.fileError = true
        });
    },

    async documentPromise (partnerId, token, file, description) {
      var data={
        partnerId,
        description,
        createdAt: 'CURRENT_TIMESTAMP',
        deletable: false
      };
      const action = (await api.partnerUpload(file, {id: partnerId, token, obj: data}));
      return action;
    },

    removeContact () {
      this.form.contacts.splice(this.form.contacts.length - 1, 1)
    },

    addContact () {
      this.form.contacts.push(JSON.parse(JSON.stringify(contact)))
    },

    removeDocument () {
      this.form.documents.splice(this.form.documents.length - 1, 1)
    },

    addDocument () {
      this.form.documents.push(JSON.parse(JSON.stringify(document)))
    },

    handleScroll: function(e) {
      if ((e.target.scrollHeight-700) <= e.target.scrollTop) {
        this.reachTerms = true
      }
    }
  },

  validations () {
    const rules = {
      form: {},
      terms: { mustBeTrue },
      privacy_policy: { mustBeTrue },
    }

    rules.form = {
      legal_name: { required, alphaNum },
      dba_name: { alphaNum },
      country: { required },
      address: { required, addressValidation },
      address2: { addressValidation },
      city: { required, alpha },
      state: { required, alphaVersion },
      phone: { required, dashNumbers, phone },
      email: { required, email },
      website: { webSiteUrl },
      zip: { required, alphaVersionNoSpace },
      contacts: {
        $each: {
          firstName: { required, alphaNum },
          lastName: { required, alphaNum },
          phone: { required, phone },
          mobile: { phone },
          email: { required, email },
        }
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .app-page-text {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .content-form .form-row div .check {
    position: unset;
    pointer-events: unset;
  }
  .buttons {
    margin-top: 20px;
  }
  
  .login-link {
    text-decoration: none;
    color: #fff;
  }

  .submitted-note {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #000;
    text-align: center;
    .success {
      font-size: 25px;
      color: #00B100;
    }
    .denied {
      text-align: left;
      font-size: 16px;
      span {
        display: block;
        font-size: 20px;
        color: #b10000;
      }

    }
  }
  .register-shade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.1);
    z-index: 1;
  }
</style>
