<template>
  <div>
    <div class="content-form">
      <div class="form-row form-corporate">
        <form-field-select v-model="form.type" :validator="v.type" label="Contact Type" :options="contactTypeOptions" :disabled="disabled" required />
        <form-field-select v-model="form.sex" :validator="v.sex" label="Sex" :options="{Male: 'Male', Female: 'Female'}" :disabled="disabled" required />
        <form-field-date v-model="form.dob" :dirty="v.$dirty" label="Date of Birth" :disabled="disabled" required />
        <form-field-text v-model="form.firstName" :maxlength="25" :validator="v.firstName" label="First Name" :disabled="disabled" required />
      </div>
      <div class="form-row form-corporate">
        <form-field-text v-model="form.lastName" :maxlength="25" :validator="v.lastName" label="Last Name" :disabled="disabled" required />
        <form-field-select v-model="form.country" :validator="v.country" label="Country" :options="countryOptions" :disabled="disabled" required />
        <form-field-text v-model="form.city" :validator="v.city" label="City" :disabled="disabled" required />
        <form-field-text label="Address" v-model="form.address" :validator="v.address" :maxlength="50" :disabled="disabled" required />
      </div>
      <div class="form-row form-corporate">
        <form-field-text label="Address 2" v-model="form.address2" :maxlength="50" :disabled="disabled"/>
        <form-field-select v-if="stateOptions" v-model="form.state" :validator="v.state" label="State/Province" :options="stateOptions" :disabled="disabled" required />
        <form-field-text v-else v-model="form.state" :validator="v.state" label="State/Province" :disabled="disabled" required />
        <form-field-text label="ZIP / Postal Code" v-model="form.zip" :validator="v.zip" :disabled="disabled" required />
        <form-field-text v-model="form.phone" :maxlength="15" :validator="v.phone" label="Phone Number" :disabled="disabled" required />
      </div>
      <div class="form-row form-corporate">
        <form-field-text v-model="form.mobile" :maxlength="15" :validator="v.mobile" label="Mobile Number" :disabled="disabled" required />            
        <form-field-text v-model="form.email" :validator="v.email" label="Email Address" :disabled="disabled" required />
        <form-field-text  v-model="form.passport" :maxlength="20" :validator="v.passport" label="Passport Number" :disabled="disabled" required />
        <form-field-select v-model="form.id_type" :validator="v.id_type" label="Government Issued ID Type" :options="{'Drivers License': 'Drivers License', 'Citizenship Card': 'Citizenship Card', 'Government ID': 'Government ID'}" :disabled="disabled" required />
      </div>
      <div class="form-row form-corporate" style="justify-content: flex-start;">
        <form-field-text v-model="form.id_number" :maxlength="20" :validator="v.id_number" label="Government Issued ID Number" :disabled="disabled" required />
        <div v-if="disabled" @click="open = true">
          <form-field-text v-if="showSin" v-model="form.sin" :maxlength="20" :validator="v.sin" label="SSN/Fiscal Number" required :disabled="disabled"/>
          <form-field-text v-else v-model="hiddenSin" :maxlength="20" :validator="v.sin" label="SSN/Fiscal Number" required :disabled="disabled"/>
        </div>
        <div v-if="!disabled">
          <div @mousedown="editSin()" v-if="!showEditSin">
            <form-field-text v-model="hiddenSin" :maxlength="20" :validator="v.sin" label="SSN/Fiscal Number" required :disabled="disabled"/>
          </div>
          <form-field-text v-else v-model="form.sin" :maxlength="20" :validator="v.sin" label="SSN/Fiscal Number" required :disabled="disabled"/>
        </div>
        
        <div v-if="form.type === 'Beneficial Owner'" style="margin-left: 7px;">
          <form-field-text v-model="form.ownership_percentage" :validator="v.ownership_percentage" label="Ownership Percentage" required :disabled="disabled"/>
        </div>
      </div>
    </div>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Management PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    v: {
      type: Object,
      required: true
    },

    initialLoad: {
      type: Boolean,
      required: true
    },

    showSin: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      form: this.value,
      pin: '',
      pinFailed: false,
      open: false,
      showEditSin: false
    }
  },
  
  computed: {
    contactTypeOptions () {
      return {
        'Beneficial Owner': 'Beneficial Owner',
        'Authorized Person': 'Authorized Person'
      }
    },

    hiddenSin () {
      if(this.form.sin) {
        return `${Array(this.form.sin.length - 3).join('*')}${this.form.sin.slice(-4)}`
      }
    },

    countryOptions () {
      const options = { ...window.countriesOptions }
      return options
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.country' (value) {
      if(this.initialLoad) {
        this.form.state = null
      }
    },

    value: {
      handler (value) {
        this.form = value
      },
      immediate: true,
      deep: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    cancelPin() {
      this.open = false
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckManagementPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
        this.showSin = true
      }else{
        this.pinFailed = true
      }
    },

    pinInput (e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    editSin () {
      this.form.sin = ''
      this.showEditSin = true
    }
  }
}
</script>