<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section  class="content-tlist">
      <table class="content-table">            
        <tr>                
          <th style="width: 20%">Date</th>
          <th style="width: 20%">Version</th>
          <th style="width: 40%">Description</th>
        </tr>
        <tr v-for="(item, i) in logs" :key="i">
          <template>
            <td><span>Date</span>{{ item.createdAt | date('dddd, MMMM D YYYY') }}</td>
            <td>
              <span>Version</span>
              {{ item.version }}
            </td>
            <td style="width: 500px; padding-right: 10px;">
              <span>Description</span>
              <mnb-textarea
              v-model="item.description"
              :classes="{'full': true }"
                :disabled="true"
              @input="mixin_autoResize_resize"
              full/>
            </td>
          </template>
        </tr>
      </table>
    </section>

    <change-log-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import MnbTextarea from '../../components/ui/forms/Textarea'

import mixinAutoResize from "../../components/ui/forms/autoresize.js"
import DateTimePicker from 'vue-vanilla-datetime-picker';
import ChangeLogFilters from './filters/ChangeLogFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'system-change-log-readonly',

  mixins: [mixinAutoResize],

  components: {
    MnbTextarea,
    DateTimePicker,
    AppliedFilters,
    ChangeLogFilters
  },

  data() {
    return {
      openedFilters: false,
      filters: null,
      loading: false,
    }
  },

  computed: {
    appliedFilters () {
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')

      if (hasDateFilters) {
        return [{
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        }]
      }

      return []
    },

    logs () {
      const data = this.$store.state.changeLog.data || []
      return data
    }
  },

  async created () {
    await this.refreshLogs()
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    async refreshLogs() {
      const loader = this.$loading.show()
      this.loading = true;
      let params = {}
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var startDate = yyyy + '-' + mm + '-' + '01';
        var endDate = yyyy + '-' + mm + '-' + '31';
        params.createdAtAfter = [">=", startDate + " 00:00:00", "createdAt"];
        params.createdAtBefore = ["<=", endDate + " 23:59:59", "createdAt"];
      } else {
        if (this.filters.fromDate)
          params.createdAtAfter = [">=", this.filters.fromDate + " 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore = ["<=", this.filters.toDate + " 23:59:59", "createdAt"];
      }
      await this.$store.dispatch('changeLog/load', {order: ['createdAt:DESC'], params})
      this.loading = false;
      loader.hide()
    },
  },

  watch: {
    appliedFilters() {
      this.refreshLogs();
    },
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>