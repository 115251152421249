<template>
  <div>
      <section>
        <div>
          <h2 class="inner-headers">Manage &gt; Session Management</h2>
        </div>
      </section>

      <div class="content-filters">
        <basic-form>
          <div class="cof-row">
            <mnb-select v-model="form.sessionTimeout" :options="accountNumberLengthOptions" label="Session Timeout (Minutes)" :disabled="!updateInformations"/>
          </div>

          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="updateInformations = true">Update</button>
            </div>
          </div>
        </basic-form>
      </div>
  </div>
</template>

<script>
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbUploadInput from '../../components/ui/forms/UploadInput'

  export default {
    name: 'session-management',

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      MnbUploadInput
    },

    data () {
      return {
        form: {

        },

        updateInformations: false,

        accountNumberLengthOptions: [
          {
            id: 5,
            text: '5'
          },          
          {
            id: 10,
            text: '10'
          },         
          {
            id: 15,
            text: '15'
          },
          {
            id: 30,
            text: '30'
          },
          {
            id: 45,
            text: '45'
          },
          {
            id: 60,
            text: '60'
          },
        ],
      }
    },

    computed: {

    },

    watch: {

    },

    async created () {
      this.form = (await api.get('System', 1)).item
    },

    methods: {
      async save () {
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.form })
          this.$toast.success('Session Timeout saved successfully.')
          this.updateInformations = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },
    }
  }
</script>
