<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <div class="mnb-field">
      <label>Date Added</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          class="position-bottom-right"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
      </div>
    </div>

    <div class="mnb-field">
      <label>Provider</label>
      <select v-model="form.provider" class="mnb-input">
        <option></option>
        <option v-for="option in providerOptions" :key="option.id" :value="option.id">{{ option.text }}</option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Failed</label>
      <select v-model="form.failed" class="mnb-input">
        <option></option>
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Retries</label>
      <select v-model="form.retries" class="mnb-input">
        <option></option>
        <option v-for="option in retriesOptions" :value="option.id" :key="option.id">{{ option.text }}</option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        provider: null,
        failed: null,
        retries: null,
      },

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  computed: {
    providerOptions () {
      let data =  this.$store.state.providersList.data || []
      data = data.filter(item => (item.status == 'Active') || (item.status =='Testing'))
      let options = []
      data.forEach(provider => {
        options.push({
          id: provider.id,
          text: `${provider.provider_name}`,
        })
      })
      return options
    },

    retriesOptions () {
      let options = []
      for(var i=1; i <= 20; i++) {
        options.push({
          id: i,
          text: i,
        })
      }
      return options
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
