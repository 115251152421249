<template>
<div class="mobile-banner" v-if="isMobile">
  <span>The portal is best viewed using our mobile app.</span>
  <a v-if="mobileLink" :href="mobileLink">Download</a>
</div>
</template>

<script>
export default {
  props: ["androidUrl", "iosUrl"],
  computed: {
    isMobile() {
      return (navigator.userAgent.indexOf('Mobile')!=-1);
    },
    mobileLink() {
      if (navigator.userAgent.indexOf('Android')!=-1)
        return this.androidUrl;
      if (navigator.userAgent.indexOf('iPhone')!=-1)
        return this.iosUrl;
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-banner {
  display: block;
  padding: 15px 15px;
  background: #f7f9fa;
  border-bottom: 2px solid white;
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
    flex-grow: 1;
  }
  a {
    text-align: center;
    line-height: 35px;
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    border: 0;
    outline: none;
    height: 36px;
    width: 80px;
    cursor: pointer;
    border-radius: 18px;
    background: #008A00;
    background: linear-gradient(86deg, var(--site-button-color-left), var(--site-button-color-left) 20%, var(--site-button-color-right));
    color: white;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
  }
}
</style>
