export default {
  register: {
    title: 'REGISTRO DE NUEVA CUENTA',
    subtitle_q: 'Escoja el idioma*',
    have_acc_yes: 'SÍ',
    have_acc_label: '¿Tiene una cuenta existente?',
    yes_description: 'Si ya sos un cliente, ¡agregar una nueva cuenta es rápido y sin complicaciones! Simplemente hacé clic en "Nueva Cuenta" en el Dashboard del Área de Cliente. También podes agregar una nueva cuenta y administrar la configuración de tu cuenta en Mi perfil. Contactanos para más información.',
    login_button: 'Iniciar sesión',
    acc_info: 'Información de la cuenta',
    acc_info_rq: 'Requerido',
    acc_type_selection: 'Tipo de Cuenta',
    acc_curr_selection: 'Moneda',
    acc_type_bussines: 'Empresa',
    acc_type_personal: 'Personal',
    agree_terms: 'Aceptar:',
    terms: 'Términos y condiciones',
    privacy: 'Política de privacidad',
    submit_reg: 'Enviar registro',
    retry_reg: 'Reintentar registro',
    accept_button: 'Aceptar',
    cancel_button: 'Cancelar',
    must_complete_terms: 'Debe completar hasta el final de los Términos para aceptar.',
    document_upload_error: 'Error al subir documento',
    document_upload_error_text_1: 'Hubo un problema con sus documentos de registro. Seleccione un nuevo archivo para cargar y haga clic a continuación para volver a intentar su registro.',
    document_upload_error_text_2: 'Póngase en contacto con el soporte si continúa experimentando problemas.',
    business_acc_info_header: 'Información de Empresa',
    buss_legal_name: 'Nombre legal de la empresa',
    buss_dba_name: 'Nombre comercial de la empresa',
    buss_inc_date: 'Fecha de incorporación',
    buss_tax_name: 'Identificación fiscal de la empresa',
    country_formation: 'País de formación',
    prohibited_c: 'Países prohibidos',
    state_formation: 'Estado/Provincia de formación',
    business_types: 'Tipo de negocio', 
    bus_type_sole: 'Propietario único',
    bus_type_smllc: 'Compañía de responsabilidad limitada de miembro único',
    bus_type_llc: 'Compañía de responsabilidad limitada',
    bus_type_gen_par: 'Sociedad General',
    bus_type_corp: 'Corporación',
    bus_type_ptc: 'Corporación que cotiza en bolsa',
    bus_type_association: 'Asociación',
    bus_type_n_profit: 'Sin fines de lucro',
    bus_type_gov_org: 'Organización gubernamental',
    bus_type_rev_trust: 'Fideicomiso revocable',
    bus_type_irr_trust: 'Fideicomiso irrevocable',
    bus_type_estate: 'Bienes',
    business_address: 'Dirección de la empresa',
    business_address_2: 'Dirección de la empresa 2',
    business_city: 'Ciudad de la Empresa',
    business_state: 'Estado/Provincia de la Empresa',
    business_zip: 'Código postal de la empresa',
    business_phone: 'Teléfono de la empresa',
    business_email: 'Email de la empresa',
    business_website: 'Sitio web de la empresa',
    account_contacts: 'Información de Contactos',
    add_ID: 'Agregar otra identificación',
    remove_ID: 'Quitar identificación',
    add_another_contact: 'Agregar otro contacto',
    remove_contact: 'Quitar Contacto',
    contact_type: 'Tipo de Contacto',
    ben_owner: 'Propietario efectivo',
    auth_person: 'Persona autorizada',
    contact_gender: 'Sexo',
    gender_m: 'Masculino',
    gender_f: 'Femenino',
    contact_birth: 'Fecha de Nacimiento',
    contact_FN: 'Nombre',
    contact_LN: 'Apellido',
    contact_country: 'País',
    contact_city: 'Ciudad',
    contact_address: 'Dirección',
    contact_address_2: 'Dirección 2',
    contact_state: 'Estado/Provincia',
    contact_zip: 'Código Postal',
    contact_phone: 'Número de teléfono',
    contact_mobile: 'Número de teléfono celular',
    contact_email: 'Correo electrónico',
    contact_ssn: 'SSN/Número de identificación fiscal',
    whats_this: '¿Qué es esto?',
    gov_issued_id_type: 'Tipo de identificación emitida por el gobierno',
    gov_issued_id_num: 'Número de identificación emitido por el gobierno',
    gov_doc_passport: 'Pasaporte',
    gov_doc_drivers_license: 'DNI/Licencia de conducir',
    ownership_percent: 'Porcentaje de propiedad',
    modal_ssn: 'SSN/Número de identificación fiscal',
    modal_ssn_line1: 'Ciudadanos estadounidenses: ingresar número de seguro social',
    modal_ssn_line2: 'Para ciudadanos fuera de los EE. UU.: Ingresar número de identificación fiscal del gobierno',
    year_format: 'AA',
    business_desc_questions: 'Cuenta de Empresa: Preguntas Sobre la Empresa',
    business_purpose: 'Propósito de la cuenta',
    business_purpose_select_1: 'Custodia de Fondos',
    business_purpose_select_2: 'Custodia de Activos Digitales',
    business_purpose_select_3: 'Custodia de Fondos y Activos Digitales',
    business_associations_other_acc: 'Asociación con otras cuentas',
    business_associations_other_acc_select_1: 'Ninguna',
    business_associations_other_acc_select_2: 'También tengo una cuenta personal',
    business_associations_other_acc_select_3: 'También tengo una cuenta de empresa',
    business_source_assets: 'Fuente de Activos e Ingresos',
    business_source_assets_personal: 'Personal',
    business_source_assets_business: 'Empresa',
    business_intended_use_of_account: 'Uso previsto de la cuenta',
    business_intended_use_of_account_select_1: 'Custodia de Fondos para Uso Personal',
    business_intended_use_of_account_select_2: 'Custodia de Activos Digitales para Uso Personal',
    business_intended_use_of_account_select_3: 'Custodia de Fondos y Activos Digitales para Uso Personal',
    business_intended_use_of_account_select_4: 'Custodia de Fondos para Uso Comercial',
    business_intended_use_of_account_select_5: 'Custodia de Activos Digitales para Uso Comercial',
    business_intended_use_of_account_select_6: 'Custodia de Fondos y Activos Digitales para Uso Comercial',
    business_anticipated_types_of_assets: 'Tipos de Activos Anticipados',
    business_anticipated_types_of_assets_select_1: 'Fondos',
    business_anticipated_types_of_assets_select_2: 'Activos digitales',
    business_anticipated_types_of_assets_select_3: 'Fondos y activos digitales',
    business_anticipated_monthly_cash_volume: 'Volumen de efectivo anticipado por mes',
    business_anticipated_trading_patterns: 'Patrones comerciales anticipados',
    business_anticipated_trading_patterns_select_1: 'Ninguno',
    business_anticipated_trading_patterns_select_2: 'Regulares',
    business_anticipated_trading_patterns_select_3: 'Inusuales',
    business_anticipated_monthly_transactions_incoming: 'Transacciones Mensuales Anticipadas - Entrantes',
    business_anticipated_monthly_transactions_outgoing: 'Transacciones Mensuales Anticipadas - Salientes',
    business_industry: 'Rubro de la empresa',
    administrative_services: 'Servicios administrativos',
	advertising: 'Publicidad',
	animal_farming_production: 'Producción ganadera',
	art_photography: 'Fotografía artística',
	auto_dealers: 'Concesionarios de automóviles',
	automotive: 'Industria automotriz',
	bank: 'Bancos',
	beauty_or_barber_shops: 'Belleza o Peluquería',
	biotechnology: 'Biotecnología',
	building_materials_hardware: 'Materiales de construcción',
	car_wash: 'Lavado de autos',
	clothing_apparel: 'Ropa de vestir',
	college_university_schools: 'Universidades',
	computer_service_repair: 'Servicio de reparación de computadoras',
	construction: 'Construcción',
	consumer_goods: 'Bienes de consumo',
	crop_farming: 'Producción agrícola',
	data_analytics: 'Análisis de datos',
	design: 'Diseño',
	ecommerce: 'Ecommerce',
	electronics: 'Electrónica',
	employment_services: 'Servicios de empleo',
	energy: 'Energía',
	engineering: 'Ingeniería',
	events: 'Eventos',
	financial_investments: 'Inversiones financieras',
	financial_services: 'Servicios financieros',
	financial_technology: 'Tecnología financiera',
	fishing_hunting: 'Pesca y caza',
	fitness_sports_centers: 'Centros deportivos y gimnasios',
	food: 'Gastronomía',
	forestry: 'Silvicultura',
	freelance_professional: 'Profesional independiente',
	funds_trusts_other: 'Fideicomiso de fondos',
	gaming: 'Gaming',
	gasoline_service_station: 'Estación de servicio',
	government_agency: 'Agencia gubernamental',
	hardware: 'Herramientas',
	health_services: 'Servicios de salud',
	home_furnishing: 'Muebles',
	hospitals: 'Hospitales',
	hotel_motel: 'Hotel y motel',
	industrial_machinery: 'Maquinaria industrial',
	information_technology: 'Tecnologías de la información',
	insurance: 'Seguros',
	landscape_services: 'Paisajismo',
	legal_services: 'Servicios juridico',
	lifestyle: 'Estilo de vida',
	massage_tanning_services: 'Servicios de masajes y bronceado',
	mobile: 'Servicios de telecomunicación',
	money_transfer_remittance: 'Transferencias de dinero',
	museums: 'Museos',
	music: 'Música',
	natural_resources: 'Recursos naturales',
	non_government: 'No Gubernamental',
	online_retailer: 'Ventas minoristas en línea',
	other_accommodation: 'Otros alojamientos',
	other_arts_entertainment: 'Otros entretenimientos artísticos',
	other_education_services: 'Otros servicios educativos',
	other_farming_hunting: 'Otros caz y pesca',
	other_food_services: 'Otros servicios de comida',
	other_health_fitness: 'Otros servicios de bienestar físico',
	other_manufacturing: 'Otra Manufactura',
	other_professional_services: 'Otros servicios profesionales',
	other_trade_contractor: 'Otro contratista comercial',
	other_transport_services: 'Otros transportes de servicios',
	other_travel_services: 'Otros servicios de viaje',
	parking_garages: 'Garajes de estacionamiento',
	payments: 'Pagos',
	plumbing_hvac: 'Plomería',
	private_investment: 'Inversiones privadas',
	publishing: 'Publicación y Editorial',
	real_estate: 'Bienes raíces',
	religious_organization: 'Organizaciones religiosas',
	restaurants_with_cash: 'Restaurantes Con Efectivo',
	restaurants: 'Restaurantes',
	retail_jeweler: 'Joyería minorista',
	retail_with_cash: 'Venta al por menor con efectivo',
	retail: 'Venta al por menor',
	sales_marketing: 'Ventas y Marketing',
	security: 'Seguridad',
	software_publisher: 'Software',
	sports_teams_clubs: 'Clubes de equipos deportivos',
	sports: 'Deportes',
	sustainability: 'Sustentabilidad',
	taxi: 'Taxi',
	telecommunication: 'Telecomunicación',
	tour_operator: 'Operadores de Tours',
	transportation: 'Transporte',
	travel_agency: 'Agencia de viajes',
	trucking_shipping: 'Transporte por carretera y envíos',
	unions: 'Sindicatos',
	used_clothes_dealer: 'Distribuidor de ropa usada',
	video: 'Video',
	warehouse_distribution: 'Distribución de almacén',
	wellness_services: 'Servicios de Bienestar',
	wholesale_jeweler: 'Venta de joyeria al por mayor',
	wholesale: 'Venta al por mayor',

    business_desc: 'Descripción de la Empresa',
    business_doc_submission: 'Documentación',
    business_nature: 'Naturaleza del negocio de la Empresa',
    business_inc_doc: 'Documentos de incorporación comercial (10 MB máx.)',
    business_address_confirmation: 'Confirmación de dirección de la Empresa (10 MB máx.)',
    contact_increment_ID: 'Contacto',
    contact_increment_ID_text: 'identificación emitida por el gobierno',
    contact_increment_address_text: 'Confirmación de dirección',
    bus_inc_doc_header: 'Documentos de incorporación comercial',
    bus_inc_doc_text: 'Enviar documento completos de incorporación comercial, que puede incluir el estatuto de la empresa, los artículos de incorporación, el acuerdo operativo, el registro de acciones de propiedad, etc.',
    bus_address_conf_header: 'Confirmación de dirección de la Empresa',
    bus_address_conf_text: 'Proporcionar un comprobante de domicilio (artículo de correo) fechado dentro de los últimos 90 días (p. ej., factura de servicios, resumen de tarjeta de crédito/banco, contrato de arrendamiento de alquiler, registro del vehículo, copia del título de propiedad inmobiliaria o factura de impuestos sobre la propiedad).',
    bus_gov_issues_ID_popup_header: 'IDENTIFICACIÓN',
    bus_gov_issues_ID_popup_line1: 'Siga estas instrucciones cuando envíe su identificación emitida por el gobierno. Los documentos de identificación presentados que no cumplan con los siguientes criterios pueden causar demoras en la aprobación.',
    bus_gov_issues_ID_popup_line2: 'Pasaporte: el escaneo del pasaporte debe ser de la página completa de la fotografía y mostrar también la página opuesta completa. Ambas páginas deben mostrar su margen completo. El escaneo debe ser en color y tener la menor cantidad posible de reflejos.',
    bus_gov_issues_ID_popup_line3: 'Licencia de conducir: el escaneo de la licencia de conducir debe estar completo y mostrar todos los márgenes. El escaneo debe ser en color y tener la menor cantidad posible de reflejos.',
    choose_file: 'Seleccionar archivo',
    personal_acc_heading: 'Información de contacto',
    personal_address_conf_header: 'Dirección',
    personal_doc_submission: 'Documentación',
    unable_1: 'No podemos completar tu solicitud en este momento. Por favor comunicarse con soporte a',
    unable_2: 'o por teléfono al',
    unable_3: 'para completar su solicitud',
    unable_4: 'Gracias',
    final_acc_activation: 'La activación final de la cuenta solo ocurrirá una vez que se hayan recibido todos los documentos.',
    required1: 'Necesitas llenar todos los campos requeridos. Los marcamos con color rojo para que sepas qué campos te has perdido.',
    required2: 'OEl porcentaje de propiedad de todos los beneficiarios reales debe ser un total de 100%',
    required3: 'Se debe seleccionar al menos un beneficiario real',
    form_error_required: 'Este campo es obligatorio.',
    form_error_email: 'Este debe ser un correo electrónico válido.',
    form_error_sameAs: 'Passwords must match.',
    form_error_integer: 'El valor debe ser entero.',
    form_error_numeric: 'Este campo debe ser numérico.',
    form_error_decimal: 'Este campo debe ser un número.',
    form_error_between: 'El valor esta fuera de rango.',
    form_error_alpha: 'Este campo debe ser solo de letras.',
    form_error_alphaNum: 'Este campo no admite caracteres especiales.',
    form_error_alphaNumNoSpace: 'Este campo debe ser alfanumérico.',
    form_error_alphaOnly: 'Este campo debe ser solo de letras.',
    form_error_alphaVersionNoSpace: 'Este campo no admite caracteres especiales.',
    form_error_addressValidation: 'Este campo no admite caracteres especiales.',
    form_error_dashNumbers: 'Este campo no admite caracteres especiales.',
    form_error_ipAddress: 'El valor debe ser una dirección IPv4 válida.',
    form_error_url: 'El valor debe ser una URL válida.',
    form_error_minLength: 'Se requieren más caracteres.',
    form_error_maxLength: 'Límite máximo de caracteres superado.',
    form_error_minValue: 'The value can not be less than zero.',
    form_error_limit: 'Amount Entered Exceeds Limit',
    form_error_routingNumber: 'Routing number can only start with 0, 1, 2 or 3',
    form_error_monbiAccountNumber: 'This is not a valid Account number. Please check information and try again.',
    form_error_swift: 'Enter a valid SWIFT code.',
    form_error_minLimit: 'The Amount must be greater than the Fee.',
    form_error_webSiteUrl: 'Este campo debe tener formato URL.',
  },
  months: {
  	1: 'Enero',
  	2: 'Febrero',
  	3: 'Marzo',
  	4: 'Abril',
  	5: 'Mayo',
  	6: 'Junio',
  	7: 'Julio',
  	8: 'Agosto',
  	9: 'Septiembre',
  	10: 'Octubre',
  	11: 'Noviembre',
  	12: 'Diciembre',
  },
};
