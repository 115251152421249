<template>
  <div>
    <!--<h4>Compliance Settings</h4> -->
    <ul class="cp-fields">
      <li>
        <span>Profile Review Days</span>
        <div class="val">
          {{ days }}
        </div>
      </li>
      <li>
        <span>Days Remaining</span>
        <div class="val">
          {{ daysRemaining }}
        </div>
      </li>
      <li />
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      review: null
    }
  },

  computed: {
    days () {
      return this.registration.profileSettings.review_days
    },

    daysRemaining () {
      const date = this.registration.user?.activationDate
      if (!date || !this.review) return 'N/A'
      const left = moment(this.review.review).diff(moment(), 'days')
      return left < 0 ? 0 : left
    }
  },

  async created () {
    const reviews = await this.$store.dispatch('compliance_reviews/load', {
      params: {
        regId: this.registration.id,
        done: 0
      }
    })
    this.review = reviews.length ? reviews[reviews.length-1] : null

    if(this.review){
      const left = moment(this.review.review).diff(moment(), 'days')
      if(left <= 0){
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            status: 'Review'
          }
        })
      }
    }
  }
}
</script>