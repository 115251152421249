<template>
  <div>
    <div class="content-form content-form-modern">
      <div class="form-row form-split">
        <h3 class="subtitle">BANK & BENEFICIARY INFORMATION</h3>
      </div>
      <div class="form-row form-four routing-number-validate">
        <div>
          <form-field-text v-model="form.routingNumber" label="Bank Routing Number" :validator="v.routingNumber" required :disabled="disabledField" />
          <button :disabled="!showBtn" class="btn btn-success show-btn" @click="validateRoutingNumber()"><i class="el-icon-search" /> Validate</button>
        </div>
        <form-field-select v-model="form.accountType" label="Account Type" :validator="v.accountType" :options="accountOptions" required :disabled="disabledField" />
        <form-field-text v-model="form.accountName" label="Account Name" :validator="v.accountName" required :disabled="disabledField" />
        <form-field-text v-model="form.accountNumber" label="Account Number" :validator="v.accountNumber" required :disabled="disabledField" />
        <form-field-select v-if="!hideBankAccountType" v-model="form.bankAccountType" label="Bank Account Type" :validator="v.bank_account_type" :options="bankTypeOptions" required :disabled="disabledField" />
      </div>
      <p class="bank-name"><template v-if="bankInfo">{{ bankName }}, {{bankInfo[0].attributes['phone-number-id']}}</template>, <template v-if="bankAddress">{{ bankAddress['street-1'] }},  {{ bankAddress['street-2'] }}</template>, <template v-if="bankAddress">{{ bankAddress['city'] }}, {{ bankAddress['region'] }}, {{ bankAddress['postal-code'] }}, {{ bankAddress['country'] }}</template>&nbsp;</p>
    </div>
    <popup :open.sync="showConfirm">
      <div class="popup-header">
        <h3>Bank Routing Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form bank-info">
          <p v-if="bankError">Error searching Banks. {{ bankErrorMsg }}</p>
          <p v-if="notFound">Intermediary Bank not found. Please check information and try again.</p>
          <template v-if="bankInfo">
            <div class="form-row form-split">
              <label>
                <b>Routing Number: </b>
              </label>
              <label>
                {{ bankInfo.routingNumber }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Name: </b>
              </label>
              <label>
                {{ bankInfo.name }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address: </b>
              </label>
              <label>
                {{ bankInfo.street }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address 2: </b>
              </label>
              <label v-if="bankAddress">
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>City: </b>
              </label>
              <label>
                {{ bankInfo.city }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>State/Province: </b>
              </label>
              <label>
                {{ bankInfo.state }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Postal/Zip: </b>
              </label>
              <label>
                {{ bankInfo.zip }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Country: </b>
              </label>
              <label>
              
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Phone: </b>
              </label>
              <label>
                {{ bankInfo.phone }}
              </label>
            </div>
          </template>
        </div>
        <div class="buttons">
          <template v-if="notFound || bankError">
            <button class="second" @click="showConfirm = false">Close</button>
          </template>
          <template v-else>
            <button class="main" @click="confirmIntermediary()">Confirm</button>
            <button class="second" @click="showConfirm = false">Cancel</button>
          </template>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: true
    },

    disabledField: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal Checking'
        },
        {
          id: 'business',
          text: 'Business Checking'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ],

      showBtn: false,

      bankError: false,
      notFound: false,
      bankInfo: null,
      bankErrorMsg: null,
      bankAddress: null,
      showConfirm: false,
      bankName: null
    }
  },

  watch: {
    'form.routingNumber' () {
      this.v.routingNumber.$touch()
      if(!this.v.routingNumber.$invalid) {
        this.showBtn = true
      }
    },

    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    async validateRoutingNumber() {
      const loader = this.$loading.show()
      let payload;
      payload = {
        type: 'bank_routing_number',
        value: this.form.routingNumber,
      }
      const response = (await api.action("Transactions", "new", "Bank", payload))
      if(response.action.error){
        this.bankError = true
        this.bankErrorMsg = response.action.errors
        this.notFound = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      } else if(response.action.notFound){
        this.notFound = true
        this.bankError = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      }else{
        this.bankInfo = response.action.data
        this.bankAddress = response.action.address
        this.bankError = false
        this.notFound = false
        this.showConfirm = true
        loader.hide()
      }
    },

    confirmIntermediary() {
      this.bankName = this.bankInfo.name
      this.form.bankName = this.bankInfo.name
      this.showConfirm = false
    }
  },
}
</script>
<style lang="scss" scoped>
  .routing-number-validate {
    position: relative;

    .show-btn {
      width: 100px;
      height: 30px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .bank-info {
    label {
      width: 50%;
    }
  }

  .account-number-alert {
    margin-top: -15px !important;
    padding: 0 !important;
  }

  .use_intermediary_bank_div {
    display: flex;
    justify-content: space-between;
  }

  .center-checkbox {
    width: 50%;
  }

  .bank-name {
    margin-top: 0;
    font-size: 10px;
  }

  .form-four >div {
    width: calc(25% - 18px);
    @media screen and (max-width: 760px) {
      width: 100%;
    }
  }
</style>