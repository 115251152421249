<template>
  <div
    class="message"
    :class="{ unread: !message.read }">
    <div class="wrapper">
      <div>
        <span class="date">{{ message.createdAt | date }}</span>
        <span
          class="text"
          :class="{ bold: !message.read }">
          <el-tag
            v-if="!message.read"
            size="mini">
            {{ message.updatedAt ? 'UPDATED' : 'NEW' }}
          </el-tag>
          {{ message.message }}
        </span>
      </div>
      <div class="buttons">
        <el-tooltip effect="dark" content="Mark as read" placement="top">
          <el-button
            v-if="!message.read"
            type="success"
            size="mini"
            icon="el-icon-check"
            plain
            circle
            @click="read()" />
        </el-tooltip>
        <el-tooltip effect="dark" content="Discard" placement="top">
          <el-button
            v-if="!message.discarded"
            type="danger"
            size="mini"
            icon="el-icon-close"
            plain
            circle
            @click="discard()" />
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },

  methods: {
    read () {
      this.$store.dispatch('readed/create', {
        itemType: 'SYSTEM_MESSAGES',
        itemId: this.message.id,
        user: window.appData.currentUser.id
      })
    },

    discard () {
      this.$store.dispatch('discarded/create', {
        itemType: 'SYSTEM_MESSAGES',
        itemId: this.message.id,
        user: window.appData.currentUser.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 7px;
  margin-bottom: 5px;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date {
      display: block;
      font-size: 12px;
      color: #b3b3b3;
    }
    .text {
      display: block;
      font-size: 15px;
      color: #0e243a;
      margin-top: 5px;
      word-break: break-word;
      text-align: left;
      padding-right: 10px;
      &.bold {
        font-weight: bold;
      }
    }
    &.unread {
      background-color: #f9f7ff;
    }
    .buttons {
      flex-shrink: 0;
    }
  }
}
</style>