<template>
  <div>
    <template v-if="!open">
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>

      <div v-if="perms" class="content-filters">
        <basic-form>
          <h4>Identification Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.name" label="System Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.company_name" label="System Company Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.dba_name" label="System DBA Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.url" label="System URL" :disabled="!updateInformations"/>
            <mnb-input v-model="form.representativeName" label="Representative Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.representativeTitle" label="Representative Title" :disabled="!updateInformations"/>
            <div>
              <mnb-upload-input v-model="representativeSignature" label="Representative Signature" :disabled="!updateInformations"/>
              <a v-if="system.logo" :href="`/files/system/${system.representativeSignature}`" target="_blank" class="uploaded-logo">
                {{ system.representativeSignature }}
              </a>
            </div>
          </div>

          <h4 class="pt-2">Address Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.address" label="System Address" :disabled="!updateInformations"/>
            <mnb-input v-model="form.city" label="System City" :disabled="!updateInformations"/>
            <mnb-input v-model="form.postal" label="System Postal Code/Zip" :disabled="!updateInformations"/>
            <mnb-select v-model="form.country" :options="countryOptions" label="System Country" :disabled="!updateInformations"/>
            <mnb-select v-model="form.state" :disabled="!countryHasState || !updateInformations" :options="stateOptions" label="System Province/State" />
          </div>

          <h4 class="pt-2">Contact Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.phone" label="System Main Phone" :disabled="!updateInformations"/>
            <mnb-input v-model="form.email" label="System Main Email" :disabled="!updateInformations"/>
            <mnb-input v-model="form.main_website" label="System Main Website" :disabled="!updateInformations"/>
            <mnb-input v-model="form.support_phone" label="System Support Phone" :disabled="!updateInformations"/>
            <mnb-input v-model="form.support_email" label="System Support Email" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Communications Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.mail_from_name" label="System Mail From Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.mail_from_email" label="System Mail From Email Address" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Display Text Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.login_text" label="System Login Text" :disabled="!updateInformations"/>
            <mnb-input v-model="form.app_page_text" label="Application Page Text" :disabled="!updateInformations"/>
            <mnb-input v-model="form.reg_message_text" label="Registration Message Text" :disabled="!updateInformations"/>
            <mnb-input v-model="form.loginPageLine1" label="Login Page Line 1" :disabled="!updateInformations"/>
            <mnb-input v-model="form.loginPageLine2" label="Login Page Line 2" :disabled="!updateInformations"/>
            <mnb-input v-model="form.loginPageLine3" label="Login Page Line 3" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Registration Form</h4>
          <div class="cof-row">
            <mnb-select v-model="form.registration_form" :options="enableDisableOptions" label="Registration Form" :disabled="!updateInformations" :validator="$v.form.registration_form"/>
            <mnb-select v-model="form.personal_registrations" :options="enableDisableOptions" label="Personal Registrations" :disabled="!updateInformations || !form.registration_form" :validator="$v.form.personal_registrations"/>
            <mnb-select v-model="form.business_registrations" :options="enableDisableOptions" label="Business Registrations" :disabled="!updateInformations || !form.registration_form" :validator="$v.form.business_registrations"/>
            <mnb-input v-model="form.disabled_form_text" label="Disabled Form Text" :maxlength="500" :disabled="!updateInformations || form.registration_form"/>
          </div>

          <h4 class="pt-2">Transaction Control</h4>
          <div class="cof-row">
            <mnb-select v-model="form.transaction_deposits" :options="enableDisableOptions" label="Deposits" :disabled="!updateInformations"  :validator="$v.form.transaction_deposits"/>
            <mnb-input v-model="form.disabled_deposits_text" label="Disabled Deposits Text" :maxlength="500" :disabled="!updateInformations || form.transaction_deposits"/>
            <mnb-select v-model="form.transaction_withdrawals" :options="enableDisableOptions" label="Withdrawals" :disabled="!updateInformations" :validator="$v.form.transaction_withdrawals"/>
            <mnb-input v-model="form.disabled_withdrawals_text" label="Disabled Withdrawals Text" :maxlength="500" :disabled="!updateInformations || form.transaction_withdrawals"/>
            <mnb-select v-model="form.transaction_trade" :options="enableDisableOptions" label="Trades" :disabled="!updateInformations" :validator="$v.form.transaction_trade"/>
            <mnb-input v-model="form.disabled_trade_text" label="Disabled Trades Text" :maxlength="500" :disabled="!updateInformations || form.transaction_trade"/>
          </div>

          <h4 class="pt-2">Design Information</h4>
          <div class="cof-row">
            <div>
              <mnb-upload-input v-model="logo" label="System Logo" :disabled="!updateInformations"/>
              <a v-if="system.logo" :href="`/files/system/${system.logo}`" target="_blank" class="uploaded-logo">
                {{ system.logo }}
              </a>
            </div>
            <div>
              <mnb-upload-input v-model="menuLogo" label="System Menu Logo" :disabled="!updateInformations"/>              
              <a v-if="system.menu_logo" :href="`/files/system/${system.menu_logo}`" target="_blank" class="uploaded-logo">
                {{ system.menu_logo }}
              </a>
            </div>
            <div>
              <mnb-upload-input v-model="favicon" label="System Favicon" :disabled="!updateInformations"/>
              <a v-if="system.favicon" :href="`/files/system/${system.favicon}`" target="_blank" class="uploaded-logo">
                {{ system.favicon }}
              </a>
            </div>
            <div>
              <mnb-upload-input v-model="statementLogo" label="Statement Logo" :disabled="!updateInformations"/>              
              <a v-if="system.statement_logo" :href="`/files/system/${system.statement_logo}`" target="_blank" class="uploaded-logo">
                {{ system.statement_logo }}
              </a>
            </div>
            <div>
              <mnb-upload-input v-model="emailHeaderLogo" label="Email Header Logo" :disabled="!updateInformations"/>
              <a v-if="system.email_header_logo" :href="`/files/system/${system.email_header_logo}`" target="_blank" class="uploaded-logo">
                {{ system.email_header_logo }}
              </a>
            </div>
            <div>
              <mnb-upload-input v-model="emailFooterLogo" label="Email Footer Logo" :disabled="!updateInformations"/>
              <a v-if="system.email_footer_logo" :href="`/files/system/${system.email_footer_logo}`" target="_blank" class="uploaded-logo">
                {{ system.email_footer_logo }}
              </a>
            </div>
            <mnb-upload-input v-if="false" v-model="emailLogo" label="System Email Logo" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Auto Send Information</h4>
          <div class="cof-row">
            <mnb-select v-model="form.reg_email" :options="yesNoOptions" label="Registration Email" :disabled="!updateInformations"/>
            <mnb-select v-model="form.welcome_email" :options="yesNoOptions" label="Activation/Welcome Email" :disabled="!updateInformations"/>
            <mnb-select v-model="form.transaction_confirm" :options="yesNoOptions" label="Transaction Submit Confirm" :disabled="!updateInformations"/>
            <mnb-select v-model="form.trade_confirm" :options="yesNoOptions" label="Trade Confirmation" :disabled="!updateInformations"/>
            <mnb-select v-model="form.statements" :options="yesNoOptions" label="Statements" :disabled="!updateInformations"/>
            <mnb-select v-model="form.new_acc_request" :options="yesNoOptions" label="New Account Request" :disabled="!updateInformations"/>
          </div>

          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button v-if="$hasPerm('update-system-information')" class="main" @click="updateInformations = true">Update</button>
            </div>
            <div class="cof-field mobfinl">
              <label>&nbsp;</label>
              <button v-if="$hasPerm('reset-system-information')" class="second">Reset Fields</button>
            </div>
          </div>
        </basic-form>
      </div>
    </template>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Admin PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MnbUploadInput from '../../components/ui/forms/UploadInput'
import { required, email, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  components: {
    MnbInput,
    BasicForm,
    MnbSelect,
    MnbUploadInput
  },

  data () {
    return {
      form: {
        name: null,
        company_name: null,
        dba_name: null,
        url: null,

        address: null,
        city: null,
        state: null,
        postal: null,
        country: null,

        phone: null,
        email: null,
        support_phone: null,
        main_website: null,
        support_email: null,

        mail_from_name: null,
        mail_from_email: null,

        login_text: null,
        app_page_text: null,
        reg_message_text: null,

        registration_form: null,
        personal_registrations: null,
        business_registrations: null,
        disabled_form_text: null,

        transaction_deposits: null,
        disabled_deposits_text: null,
        transaction_withdrawals: null,
        disabled_withdrawals_text: null,

        logo: null,
        menu_logo: null,
        email_logo: null,
        favicon: null,

        reg_email: null,
        welcome_email: null,
        transaction_confirm: null,
        trade_confirm: null,
        statements: null,
        new_acc_request: null,
        loginPageLine1: null,
        loginPageLine2: null,
        loginPageLine3: null,
      },

      logo: null,
      menuLogo: null,
      statementLogo: null,
      emailLogo: null,
      emailHeaderLogo: null,
      emailFooterLogo: null,
      favicon: null,
      representativeSignature: null,

      updateInformations: false,

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],

      enableDisableOptions: [
        {
          id: true,
          text: 'Enable'
        },
        {
          id: false,
          text: 'Disable'
        },
      ],

      pin: '',
      pinFailed: false,
      open: true,
      perms: window.appData.currentUser?.role?.perms?.includes('update-system-information') || window.appData.currentUser?.perms?.includes('update-system-information')
    }
  },

  computed: {
    countryOptions () {
      return window.countries
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    },

    countryHasState () {
      return ['CA', 'US'].includes(this.form.country)
    },

    cancelPin() {
      window.history.back()
    },

    system () {
      return this.$store.getters['system/get'](window.appData.system.id)
    }
  },

  watch: {
    'form.country' (value) {
      this.form.state = null
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
    this.form = JSON.parse(JSON.stringify(window.appData.system))
    this.$nextTick(() => (this.form.state = window.appData.system.state))
  },

  methods: {
    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if (action.pin) {
        this.pinFailed = false
        this.open = false
      } else {
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    async save () {
      try {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          return
        }
        await this.$store.dispatch('system/update', { id: 1, payload: this.form })

        if (this.logo) await this.$store.dispatch('system/upload', { id: 1, file: this.logo })
        if (this.menuLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.menuLogo, params: { field: 'menu_logo' } })
        if (this.favicon) await this.$store.dispatch('system/upload', { id: 1, file: this.favicon, params: { field: 'favicon' } })
        if (this.statementLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.statementLogo, params: { field: 'statement_logo' } })
        if (this.representativeSignature) await this.$store.dispatch('system/upload', { id: 1, file: this.representativeSignature, params: { field: 'representativeSignature' } })
        if (this.emailHeaderLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.emailHeaderLogo, params: { field: 'email_header_logo' } })
        if (this.emailFooterLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.emailFooterLogo, params: { field: 'email_footer_logo' } })
        // if (this.emailLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.emailLogo, params: { field: 'email_logo' } })
        // this.emailLogo = null

        this.logo = null
        this.$toast.success('System Information saved successfully.')
        this.updateInformations = false
        if (this.menuLogo) location.reload()
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    }
  },

  validations () {
    const rules = {
      form: {
        registration_form: { required },
        transaction_deposits: { required },
        transaction_withdrawals: { required },
        transaction_trade: { required },
        personal_registrations: { required },
        business_registrations: { required },
      }
    }

    return rules
  }
}
</script>

<style scoped>
.uploaded-logo {
  display: block;
  color: #139ada;
  margin-top: 5px;
  padding-right: 15px;
}
</style>
