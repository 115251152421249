<template>
<div>
<div class="popup" :class="{open: show}">
  <div class="pop-inner">
    <div class="pop-box">
      <a class="close" @click="$parent.currentUser=null"></a>
      <h2>Edit User</h2>
      <form>
        <div class="pf-field">
          <label>Username</label>
          <input type="text" class="" v-model="currentUser.username" disabled />
        </div>
        <div class="pf-field">
          <label>Role</label>
          <select class="" v-model="currentUser.newRole" disabled>
            <option v-for="role in roles" :value="role.id">{{ role.name }}</option>
          </select>
        </div>
        <div class="pf-field">
          <label>Email</label>
          <input type="text" class="" v-model="currentUser.email" disabled />
        </div>
        <div class="pf-field">
          <label>Title</label>
          <input type="text" class="" v-model="currentUser.title" disabled />
        </div>
        <div class="pf-field">
          <label>First Name</label>
          <input type="text" class="" v-model="currentUser.firstName" disabled />
        </div>
        <div class="pf-field">
          <label>Last Name</label>
          <input type="text" class="" v-model="currentUser.lastName" disabled />
        </div>
        <div class="pf-field">
          <label>Phone</label>
          <input type="text" class="" v-model="currentUser.phone" disabled />
        </div>
        <div class="pf-field">
          <label>Password</label>
          <input type="text" class="" v-model="currentUser.newPassword" disabled />
        </div>
        <div class="pf-field">
          <label>Permissions</label>
        </div>
        <div class="pf-row">
          <permissions v-model="currentUser.perms" :inherited="[]" :disabled="true" :no-buttons="true" />
        </div>
      </form>
      <div class="pf-row buttons">
        <a class="pop-btn" @click.prevent="$parent.currentUser=null">Close</a>
<!--         <button type="button" class="pop-btn" @click.prevent="saveUser()">Save</button> -->
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  props: ["user", "roles"],
  data: ()=>({
    currentUser: {perms: []},
    show: false,
  }),
  watch: {
    'currentUser.newRole': function() {
      this.$set(this.currentUser, 'role', this.roles.find((a)=>a.id==this.currentUser.newRole));
    },
  },
  methods: {
    async saveUser() {
      var user=JSON.parse(JSON.stringify(this.currentUser));
      delete user.role;
      delete user.password;
      delete user.passChange;
      if (user.newRole)
        user.role=user.newRole;
      if (user.id)
        await api.update("User", user.id, user);
      else
        await api.create("User", user);
      this.$parent.currentUser=null;
      this.$parent.loadUsers();
    },
  },
  mounted() {
    this.currentUser=JSON.parse(JSON.stringify(this.user));
    this.$set(this.currentUser, "perms", this.currentUser.perms || []);
    this.$set(this.currentUser, "newRole", this.currentUser.role && this.currentUser.role.id);
    this.$nextTick(()=>this.show=true);
  },
}
</script>
