import factory from '../../../store/factory'

export default {
  system: factory('System'),
  systemMessage: factory('SystemMessage'),
  readed: factory('Readed'),
  discarded: factory('Discarded'),
  providersList: factory('ProvidersList'),
  integration: factory('Integration'),
  connex: factory('Connex'),
  pin: factory('Pin'),
  changeLog: factory('ChangeLog'),
  webhook: factory('Webhooks'),
  apilog: factory('ApiLog'),
  maintenance: factory('Maintenance'),
  currency: factory('Currency'),
  systemFeatures: factory('SystemFeatures'),
  accountNumberManagement: factory('AccountNumberManagement'),
  themes: factory('Themes'),
  issueReports: factory('IssueReports'),
  systemStatus: factory('SystemStatus'),
}
