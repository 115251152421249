<template>
  <div v-if="loading">
    <div v-if="systemMaintenanceStatus" class="cof-field mobinl" style="margin-top: 30px;">
      <label>&nbsp;</label>
      <template v-if="systemMaintenanceStatus">
        <button class="main forcebtt" @click="generateQReference()">Q Reference</button>
        <button class="main forcebtt">Account Name</button>
      </template>
    </div>
    <template v-if="featureFundsStatus == null"></template>
    <template v-else>
      <section class="content-filters" style="margin: 0 0 30px 0;">
        <div class="cof-form">
          <div class="cof-row">
            <div class="cof-field">
              <label>Select Account</label>
              <mnb-select v-model="account" :options="accountOptions"/>
            </div>
            <div v-if="featureAdvancedAccountRoutingStatus && $hasPerm('new-account') && (registration.status == 'Activation' || registration.status == 'Active' || registration.status == 'Review' || registration.status == 'Dormant')" class="cof-field">
              <label>&nbsp;</label>
              <button v-if="this.providerOptions.length > 0" class="main" @click="newAccount = true"><i class="el-icon-plus" /> New Account</button>
            </div>

          </div>
        </div>
      </section>
      <template v-if="account">
        <template>
          <div style="display: flex; column-gap: 50px;" class="content-table-split">
            <div style="width: 30%;">
              <h2 class="balance">Details</h2>
              <section  class="content-tlist" style="margin: 30px 0;">
                <table class="content-table">
                  <tr class="content-filters">
                    <td class="title">Number</td>
                    <td>{{ mainAccount.accountNumber }}</td>
                  </tr>
                  <tr>
                    <td class="title">Label</td>
                    <td>{{ mainAccount.serviceProviders.label }}</td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title">Status</td>
                    <td>
                      <em v-if="!showStatus" :class="statusColorClasses[getStatus(mainAccount.accountStatus)]">
                      {{ getStatus(mainAccount.accountStatus) }}
                    </em>
                      <mnb-select v-else v-model="updateStatusValue" :options="statusOptions"/>
                    </td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title">Key</td>
                    <td>
                      {{ mainAccount.accountKey }}
                    </td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title">Plan</td>
                    <td>
                      {{ mainAccount.accountPlan? mainAccount.feePlan.name : '' }}
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Settings</td>
                    <td>
                      <a class="link" @click="modalFeeScheduleType = 'Fee'">View</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Provider</td>
                    <td>
                      <a class="link" @click="modalProvider = true"> {{ mainAccount.serviceProviders.provider_name }}</a>
                    </td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title" colspan="2" style="color: black;">Options</td>
                  </tr>
                  <tr class="content-filters">
                    <td colspan="2">
                      <template v-if="mainAccount.accountStatus == 'Closed'">
                        <button v-if="mainAccount.result != 'Pending Closure'" class="btn main" @click="providerClose(registration.id)">Provider Close</button>
                      </template>
                      <template v-else>
                        <template  v-if="$hasPerm('status-update') && registration.status != 'Activation'">
                          <button v-if="showStatus" class="btn btn-secondary" @click="saveStatus()">Save</button>
                          <button v-else-if="!updateStatus" class="btn btn-secondary" @click="updateStatus = true">Status</button>
                        </template>
                        <button v-if="!mainAccount.accountKey" class="btn main" @click="accountKey(mainAccount.id)">Key</button>
                        <button class="btn main" @click="generateARL(registration.id)">ARL</button>
                      </template>
                    </td>
                  </tr>
                </table>
                <div class="footer space">
                  <div>
                    <template>
                      <button class="second" style="visibility: hidden;">&nbsp;</button>
                    </template>
                  </div>
                </div>
              </section>
            </div>
            <div style="width: 30%;">
              <h2 class="balance">Balances</h2>
              <section  class="content-tlist" style="margin: 30px 0;">
                <table class="content-table">
                  <tr class="content-filters">
                    <td class="title">Currency</td>
                    <td>USD</td>
                  </tr>
                  <tr>
                    <td class="title">Balance</td>
                    <td>{{ mainAccount.systemTotalBalance | balance }}</td>
                  </tr>
                  <tr>
                    <td class="title">Available</td>
                    <td>{{ mainAccount.systemAvailableBalance | balance }}</td>
                  </tr>
                  <tr>
                    <td class="title">Owed Fees</td>
                    <td>{{ mainAccount.owedFee | balance }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                  </tr>
                  <tr class="content-filters">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title" colspan="2" style="color: black;">Options</td>
                  </tr>
                  <tr class="content-filters">
                    <td colspan="2">
                    </td>
                  </tr>
                </table>
                <div class="footer space">
                  <div>
                    <template>
                      <button class="second" style="visibility: hidden;">&nbsp;</button>
                    </template>
                  </div>
                </div>
              </section>
            </div>
            <div v-if="mainAccount.profileSettings && mainAccount.profileSettings.debitCards" style="width: 30%;">
              <h2 class="balance">Cards</h2>
              <section  class="content-tlist" style="margin: 30px 0;">
                <table class="content-table">
                  <tr>
                    <td class="title">Type</td>
                    <td>
                      <template v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus && mainAccount.cards[mainAccount.cards.length-1].cardType">
                      {{ mainAccount.cards[mainAccount.cards.length-1].cardType.charAt(0).toUpperCase() }}{{ mainAccount.cards[mainAccount.cards.length-1].cardType.slice(1) }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Label</td>
                    <td>
                      <template v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus">
                      {{ mainAccount.cards[mainAccount.cards.length-1].label }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Status</td>
                    <td>
                      <em v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus" :class="statusColorClasses[mainAccount.cards[mainAccount.cards.length-1].cardStatus]">
                        {{ mainAccount.cards[mainAccount.cards.length-1].cardStatus }}</em>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Number</td>
                    <td>
                      <template v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus">
                        {{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(0,6) }}******{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(-4) }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Expiry</td>
                    <td>
                      <template v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus">
                        {{ mainAccount.cards[mainAccount.cards.length-1].expiryMonth }} / {{ mainAccount.cards[mainAccount.cards.length-1].expiryYear }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">Settings</td>
                    <td>
                      <a style="cursor: pointer;" v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus" @click="openCard = true">Settings</a>
                      &nbsp;&nbsp;&nbsp;
                      <template v-if="mainAccount.cards.length > 0 && mainAccount.cards[mainAccount.cards.length-1].cardStatus">
                        <a style="cursor: pointer;" v-if="mainAccount.cards[mainAccount.cards.length-1].cardStatus == 'pendingActivation' && mainAccount.cards[mainAccount.cards.length-1].cardType != 'virtual'" @click="openDelivery = true">Delivery</a>
                      </template>
                    </td>
                  </tr>
                  <tr class="content-filters">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="content-filters">
                    <td class="title" colspan="2" style="color: black;">Options</td>
                  </tr>
                  <tr class="content-filters">
                    <td colspan="2">
                      <template v-if="!(mainAccount.cards.length > 0)" >
                        <button v-if="$hasPerm('new_card') && featureCardServicesStatus" class="main" @click="modalType = 'Card'">New Card</button>
                      </template>
                      <template v-else-if="mainAccount.cards[mainAccount.cards.length-1].cardStatus == 'Active' && $hasPerm('update_card')">
                        <button class="main" @click="suspend()">Suspend</button>
                        <button class="main" @click="openLoadCard = true">Load</button>
                        <button class="main" @click="openDebitCard = true">Debit</button>
                        <button class="main" @click="openViewCard = true">View Card</button>
                      </template>
                      <template v-else-if="mainAccount.cards[mainAccount.cards.length-1].cardStatus == 'Suspended'">
                        <button v-if="$hasPerm('update_card')" class="main" @click="unsuspend()">Unsuspended</button>
                        <button v-if="$hasPerm('cancel_card')" class="main" @click="disableCard()">Disable</button>
                        <button v-if="$hasPerm('update_card')" class="main" @click="openViewCard = true">View Card</button>
                      </template>
                      <template v-else-if="mainAccount.cards[mainAccount.cards.length-1].cardStatus == 'Inactive'">
                       
                      </template>
                    </td>
                  </tr>
                </table>
                <div class="footer space">
                  <div>
                    <template>
                      <button class="second" style="visibility: hidden;">&nbsp;</button>
                    </template>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </template>
      </template>

      <popup :open.sync="open" :width="400">
        <div class="scrollable">
          <p>{{ message }}</p>
        </div>
      </popup>

      <popup :open.sync="newAccount">
        <div class="popup-header">
          <h3>New Account</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="newAccountForm.provider" label="Select Provider" :validator="$v.newAccountForm.provider" :options="providerOptions" required />
              <form-field-select v-model="newAccountForm.currency" label="Select Currency" :validator="$v.newAccountForm.currency" :options="currencyOptions" required />
            </div>
          </div>
          <div class="buttons text-center">
            <button class="main" @click="submitNewAccount()" :disabled="this.$v.newAccountForm.$invalid">Submit</button>
            <button class="second" @click="newAccount = false">Cancel</button>
          </div>
        </div>
      </popup>

      <popup :open.sync="openLoadCard">
        <div class="popup-header">
          <h3>Load Card</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-text v-model="loadCardForm.amount" :validator="$v.loadCardForm.amount" label="Amount" />
            </div>
            <div class="form-row form-fullw">
              <form-field-text v-model="loadCardForm.description" label="Description" />
            </div>
          </div>
          <div class="buttons text-center">
            <button class="main" @click="submitLoadCard()" :disabled="this.$v.loadCardForm.$invalid">Submit</button>
            <button class="second" @click="openLoadCard = false, loadCardForm = {}">Cancel</button>
          </div>
        </div>
      </popup>

      <popup :open.sync="openDebitCard">
        <div class="popup-header">
          <h3>Debit Card</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-text v-model="loadCardForm.amount" :validator="$v.loadCardForm.amount" label="Amount" />
            </div>
            <div class="form-row form-fullw">
              <form-field-text v-model="loadCardForm.description" label="Description" />
            </div>
          </div>
          <div class="buttons text-center">
            <button class="main" @click="submitDebitCard()" :disabled="this.$v.loadCardForm.$invalid">Submit</button>
            <button class="second" @click="openDebitCard = false, loadCardForm = {}">Cancel</button>
          </div>
        </div>
      </popup>

      <popup :open.sync="openViewCard" class="view-card">
        <div class="scrollable">
          <div class="card-data-container" v-if="mainAccount && mainAccount.cards.length > 0">
           <div class="card-number">
            {{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(0,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(4,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(8,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(12, 4) }}
           </div>
           <div class="card-exp-m">
            {{ mainAccount.cards[mainAccount.cards.length-1].expiryMonth }}/{{ mainAccount.cards[mainAccount.cards.length-1].expiryYear.substr(-2) }}
           </div>
           <div class="card-cvv">
            {{ mainAccount.cards[mainAccount.cards.length-1].cvv }}
           </div>
           <div class="cardholder">
            {{ this.registration.data.firstName }} {{ this.registration.data.lastName }}
           </div>
          </div>
          <div class="buttons text-center">
            <button class="second" @click="openViewCard = false">Close</button>
          </div>
        </div>
      </popup>

      <popup :open.sync="openCard">
        <div class="popup-header">
          <h3>Card Settings</h3>
        </div>
        <div v-if="account && mainAccount.cards.length > 0" class="scrollable">
          <div class="content-form">
            <div class="form-row form-three">
              <div>
                <p><b>ATM Access</b></p>
                <p v-if="mainAccount.cards[mainAccount.cards.length-1].atmAccess">Yes</p>
                <p v-else>No</p>
              </div>
              <div>
                <p><b>Limit Amount</b></p>
                <p>
                  <template v-if="mainAccount.cards[mainAccount.cards.length-1].limitAmount == 0 || mainAccount.cards[mainAccount.cards.length-1].limitAmount == null">
                    None
                  </template>
                  <template v-else>
                    {{ mainAccount.cards[mainAccount.cards.length-1].limitAmount }}
                  </template>
                </p>
              </div>
              <div>
                <p><b>Limit Interval</b></p>
                <p>
                  <template v-if="mainAccount.cards[mainAccount.cards.length-1].limitInterval">
                    {{ mainAccount.cards[mainAccount.cards.length-1].limitInterval.charAt(0).toUpperCase() }}{{ mainAccount.cards[mainAccount.cards.length-1].limitInterval.slice(1) }}
                  </template>
                  <template v-else>None</template>
                </p>
              </div>
            </div>
          </div>
          <div class="content-form">
            <p><b>Allowed Categories</b></p>
            <p>{{ mainAccount.cards[mainAccount.cards.length-1].allowedCategories }}</p>
          </div>
          <div class="content-form">
            <p><b>Blocked Categories</b></p>
            <p>{{ mainAccount.cards[mainAccount.cards.length-1].blockedCategories }}</p>
          </div>
          <div class="content-form">
            <p><b>Allowed Merchants</b></p>
            <p>{{ mainAccount.cards[mainAccount.cards.length-1].allowedMerchants }}</p>
          </div>
          <div class="content-form">
            <p><b>Blocked Merchants</b></p>
            <p>{{ mainAccount.cards[mainAccount.cards.length-1].blockedMerchants }}</p>
          </div>
          <div class="buttons">
            <button class="second" @click="openCard = false">Close</button>
          </div>
        </div>
      </popup>

      <popup :open.sync="openDelivery">
        <div class="popup-header">
          <h3>Card Delivery</h3>
        </div>
        <div v-if="account && mainAccount.cards.length > 0" class="scrollable">
          <div class="content-form">
            <div class="form-row">
              <div>
                <p><b>Delivery Status</b></p>
                <p v-if="mainAccount.cards[mainAccount.cards.length-1].shipping && mainAccount.cards[mainAccount.cards.length-1].shipping.deliveryStatus">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.deliveryStatus.charAt(0).toUpperCase() }}{{ mainAccount.cards[mainAccount.cards.length-1].shipping.deliveryStatus.slice(1) }}</p>
              </div>
              <div>
                <p><b>ETA</b></p>
                <p v-if="mainAccount.cards[mainAccount.cards.length-1].shipping">
                  {{ mainAccount.cards[mainAccount.cards.length-1].shipping.eta }}
                </p>
              </div>
              <div>
                <p><b>Delivery Partner</b></p>
                <p v-if="mainAccount.cards[mainAccount.cards.length-1].shipping">
                  {{ mainAccount.cards[mainAccount.cards.length-1].shipping.deliveryPartner }}
                </p>
              </div>
              <div>
                <p><b>Tracking Number</b></p>
                <p v-if="mainAccount.cards[mainAccount.cards.length-1].shipping">
                  {{ mainAccount.cards[mainAccount.cards.length-1].shipping.trackingNumber }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="mainAccount.cards[mainAccount.cards.length-1].shipping && mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress" class="content-form">
            <p><b>Shipping Address</b></p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.line1 }}</p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.line2 }}</p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.city }}</p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.state }}</p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.country }}</p>
            <p class="address-line">{{ mainAccount.cards[mainAccount.cards.length-1].shipping.shippingAddress.postalCode }}</p>
          </div>
          <div class="buttons">
            <button class="second" @click="openDelivery = false">Close</button>
          </div>
        </div>
      </popup>

      <popup v-if="mainAccount" :open.sync="modalProvider">
        <div class="popup-header">
          <h3>{{ serviceProverName }}</h3>
        </div>
        <div>
          <div class="content-form">
            <table class="content-table">
              <tr class="content-filters">
                <td class="title">Created</td>
                <td>
                  <template v-if="mainAccount.providerCreatedAt">
                    {{ mainAccount.providerCreatedAt | date('MM/D/YYYY') }}
                  </template>
                  <template v-else>
                    Not Available
                  </template>
                </td>
              </tr>
              <tr class="content-filters">
                <td class="title">Number</td>
                <td>
                  {{ mainAccount.providerAccountNumber }}
                </td>
              </tr>
              <tr class="content-filters">
                <td class="title">Status</td>
                <td>
                  <em v-if="mainAccount.argenseAccount.length > 0" :class="statusColorClasses[mainAccount.argenseAccount[0].accountStatus]">
                    {{ mainAccount.argenseAccount[0].accountStatus }}
                  </em>
                </td>
              </tr>
            </table>
          </div>
          <div class="buttons">
            <button class="second" @click="modalProvider = false">Close</button>
          </div>
        </div>
      </popup>

      <section class="popup" :class="{open: updateStatus}">
        <div class="pop-inner" :style="{ maxWidth: `400px` }">
          <div class="pop-box">
            <h2>Enter Compliance PIN to Continue</h2>
            <div class="pf-row">
              <div class="pf-field">
                <input
                  v-model="pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  type = "number"
                  maxlength = "4"
                  class="pin-code"
                  v-on:keyup="pinInput"
                />
              </div>
            </div>
            <div class="pf-row">
              <div class="pf-field">
                <template v-if="pinFailed">Invalid PIN</template>
              </div>
            </div>
            <div class="pf-row buttons">
              <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
              <button class="pop-btn" @click="checkPin()">Confirm</button>
            </div>
          </div>
        </div>
      </section>

      <accounts-card-modal :account="mainAccount" :registration="registration" v-if="modalType" :modal-type="modalType" @close="modalType = null"/>
      <accounts-card-update-modal :account="mainAccount" :registration="registration" v-if="modalUpdateType" :modal-type="modalUpdateType" @close="modalUpdateType = null"/>
      <accounts-card-cancel-modal :account="mainAccount" :registration="registration" v-if="modalCancelType" :modal-type="modalCancelType" @close="modalCancelType = null"/>
      <accounts-card-activation-modal :account="mainAccount" :registration="registration" v-if="modalActivationType" :modal-type="modalActivationType" @close="modalActivationType = null"/>
      <accounts-card-pin-modal :account="mainAccount" :registration="registration" :pinData="modalPinData" v-if="modalPinData && modalPinData.provider=='Solid'" @close="modalPinData = null" @refresh="loadPinModal" />
      <accounts-card-data-modal :account="mainAccount" :registration="registration" :pinData="modalCardData" v-if="modalCardData" @close="modalCardData = null" @refresh="loadCardModal" />
      <accounts-fee-schedule-modal :account="mainAccount" :registration="registration" v-if="modalFeeScheduleType" :modal-type="modalFeeScheduleType" :provider="provider" @close="modalFeeScheduleType = null"/>
    </template>
  </div>
</template>

<script>
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbSwitch from '../../components/ui/forms/Switch'
  import AccountsCardModal from './AccountsCardModal.vue'
  import VgvDisplayContainer from '../../components/vgv/VgvDisplayContainer'
  import VgvDisplay from '../../components/vgv/VgvDisplay'

  import { required, decimal } from 'vuelidate/lib/validators'

  const initialForm = {
    domWires: null,
    intlWires: null,
    ach: null,
    checks: null,
    remoteDeposit: null,
    exchange: false,
    acc_to_acc: null,
    assets: null,
    trade_desk: null,
    multiCurrency: null,
    debitCards: null,
    domWires_limit: null,
    intlWires_limit: null,
    ach_limit: null,
    checks_limit: null,
    exchange_limit: null,
    acc_to_acc_limit: null,
    assets_limit: null,
    trade_desk_limit: null,
    multi_currency_limit: null,
    debit_cards_limit: null,

    administrative: null,
    double_verification: null,

    create_subusers: null,
    create_subprofiles: null,

    welcome_email: null,
    review_days: null,
    language: null
  }

export default {
  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch,
    AccountsCardModal,
    VgvDisplay,
    VgvDisplayContainer
  },

  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    customEnv: appData.customEnv,
    systemMaintenanceStatus: window.maintenance,
    open: false,
    openCard: false,
    openDelivery: false,
    message: null,
    featureStatus: true,
    featureFundsStatus: null,
    featureAssetsStatus: null,
    featureTradeStatus: null,
    featureAdvancedAccountRoutingStatus: null,
    featureCardServicesStatus: null,
    loading: false,
    modalType: null,
    modalActivationType: null,
    modalUpdateType: null,
    modalCancelType: null,
    modalFeeScheduleType: null,
    modalPinData: null,
    modalCardData: null,
    modalProvider: null,

    currencyStatus : {
      AUD : false,
      CAD : false,
      EUR : false,
      GBP : false,
      JPY : false,
    },
    account: null,
    form: { ...initialForm },

    updateSettings: false,

    yesNoOptions: [
      {
        id: true,
        text: 'Yes'
      },
      {
        id: false,
        text: 'No'
      },
    ],

    newAccount: false,
    newAccountForm: {
      provider: null,
      currency: null
    },
    loadCardForm: {
      amount: null,
      description: null,
    },
    updateStatus: false,
    pin: '',
    pinFailed: false,
    showStatus: false,
    updateStatusValue: null,
    feeScheduleOpen: false,
    openLoadCard: false,
    openDebitCard: false,
    openViewCard: false,

    permsActivate: window.appData.currentUser?.role?.perms?.includes('activate_accounts') || window.appData.currentUser?.perms?.includes('activate_accounts'),
    permsEnable: window.appData.currentUser?.role?.perms?.includes('enable_accounts') || window.appData.currentUser?.perms?.includes('enable_accounts'),
    permsDisable: window.appData.currentUser?.role?.perms?.includes('disable_accounts') || window.appData.currentUser?.perms?.includes('disable_accounts'),
  }),

  computed: {
    statusColorClasses () {
      return {
        Open: 'c1',
        Opened: 'c1',
        Pending: 'c2',
        Closed: 'c4',
        pending_closure: 'c4',
        Dormant: 'c3',
        Hold: 'c2',
        Restricted: 'c2',
        active: 'c1',
        Active: 'c1',
        pendingActivation: 'c2',
        canceled: 'c3',
        inactive: 'c3',
      }
    },

    assetsList () {
      let currencies = window.currencies || []
      let assets = [];
      currencies = currencies.filter(currency => {
        return currency.systemCurrencyType === 'Assets'
      })
      currencies.forEach(currency => {
        assets.push(currency.currencyNickname)
      })
      return assets;
    },

    accountOptions () {
      let accounts = []
      this.registration.accounts.forEach(account => {
        accounts.push({
          id: account.id,
          text: `${account.accountNumber} - ${account.serviceProviders.label}`,
        })
      })
      return accounts
    },

    mainAccount () {
      return this.registration.accounts.find(item => item.id === this.account)
    },

    solidAccount () {
      return this.mainAccount?.solidAccount[0] || null
    },

    profileSettings () {
      return this.mainAccount?.profileSettings || null
    },

    provider () {
      return this.providers.find(provider => provider.id === this.mainAccount.serviceProviders?.id)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    serviceProverName () {
      return this.provider?.provider_name || 'Not Assigned'
    },

    currencyOptions () {
      const options = [
        {
          id: 'USD',
          text: 'USD'
        }
      ]
      return options
    },

    providerOptions () {
      let accountProviders = []
      this.registration.accounts.forEach(account => {
        if(account.provider) {
          accountProviders.push(
            account.provider
          )
        }
      })
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && !accountProviders.includes(provider.id) && provider.providerType == 'Accounts').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    statusColorText () {
      return {
        approved: 'Approved',
        inReview: 'In Review',
        declined: 'Declined',
        active: 'Active',
        submitted: 'Submitted',
      }
    },

    statusOptions () {
      const options = [
        {
          id: 'Open',
          text: 'Open'
        },
        {
          id: 'Restricted',
          text: 'Restricted'
        },
        {
          id: 'Hold',
          text: 'Hold'
        },
        {
          id: 'Dormant',
          text: 'Dormant'
        },
        {
          id: 'Closed',
          text: 'Closed',
          disabled: true
        }
      ]
      return options
    },
  },

  watch: {
    'account' () {
      this.form = this.profileSettings
    }
  },

  async created () {
    const loader = this.$loading.show()
    this.getFeatureStatus();
    var promises = [
      this.checkMultiCurrency(),
      this.refreshFundsCurrency(),
      this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    ];
    await Promise.all(promises);
    if (this.profileSettings) {
      this.form = this.profileSettings
    }
    loader.hide()
    this.loading = true
  },

  methods: {
    formatCurrency(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open',
        pendingActivation: 'Pending Activation',
        active: 'Active',
        inactive: 'Inactive',
        canceled: 'Canceled',
      }
      return names[status] || status
    },

    getCurrencyName (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    getTagRequired (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? ( currencies[0].requiresTag? 'Yes' : 'No' ) : 'No'
    },

    getAssetsSystemAssetAvailableBalance(currencyNickname) {
      let wallets = this.mainAccount.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetAvailableBalance : null
    },

    getAssetsSystemAssetTotalBalance(currencyNickname) {
      let wallets = this.mainAccount.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetTotalBalance : null
    },

    getSystemFundsAvailableBalance(currencyNickname) {
      let fundsCurrencies = this.mainAccount.fundsCurrencies || []
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currencyNickname)
      if(fundsCurrency){
        return fundsCurrency.systemAvailableBalance
      }else{
        return null
      }
    },

    getSystemFundsTotalBalance(currencyNickname) {
      let fundsCurrencies = this.mainAccount.fundsCurrencies || []
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currencyNickname)
      if(fundsCurrency){
        return fundsCurrency.systemTotalBalance
      }else{
        return null
      }
    },

    getFundsCurrencySymbol(currencyNickname) {
      let fundsCurrencies = this.mainAccount.fundsCurrencies || []
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currencyNickname)
      if(fundsCurrency){
        return fundsCurrency.currency.symbol
      }else{
        return null
      }
    },

    getIncomingAssetWalletAddress(currencyNickname) {
      let wallets = this.mainAccount.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].incomingAssetWalletAddress : null
    },

    getTag(currencyNickname) {
      let wallets = this.mainAccount.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].tag : null
    },

    async generateQReference() {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_scan/action', {
            id: this.registration.complianceScan.id,
            action: 'GenerateQPaymentReference',
            payload: {
              registrationId: this.registration.id
          }
        })
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async wallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'Wallet',
          payload: {
            currencyNickname: currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if(response.action.error) {
          this.open=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async getWallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'GetWallet',
          payload: {
            currencyNickname: currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if(response.action.error) {
          this.open=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async enableCurrency(currency) {
      const loader = this.$loading.show()
      let payload;
      let fundsCurrencies = this.$store.state.fundsCurrency.data || []
      let id = (fundsCurrencies.find(item => item.currency.currencyNickname == currency))?.id

      payload = {
        status: 1,
      }
      let response = (await api.action("AccountsFundsCurrencies", id, "Status", payload)).item
      this.refreshFundsCurrency()
      loader.hide()
    },

    async disableCurrency(currency) {
      const loader = this.$loading.show()
      let payload;
      let fundsCurrencies = this.$store.state.fundsCurrency.data || []
      let id = (fundsCurrencies.find(item => item.currency.currencyNickname == currency))?.id

      payload = {
        status: 0,
      }
      let response = (await api.action("AccountsFundsCurrencies", id, "Status", payload)).item
      this.refreshFundsCurrency()
      loader.hide()
    },

    getCurrencyStatus(currency) {
      let fundsCurrencies = this.$store.state.fundsCurrency.data || []
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currency)
      if(fundsCurrency){
        return fundsCurrency.currencyEnabled
      }else{
        return null
      }
    },

    async activateCurrency(currency) {
      const loader = this.$loading.show()
      let payload;
      payload = {
        accountId: this.mainAccount.id,
        currency: currency,
      }
      let response = (await api.action("AccountsFundsCurrencies", "new", "Activate", payload)).item
      this.refreshFundsCurrency()
      loader.hide()
    },

    async refreshFundsCurrency() {
      await this.$store.dispatch('fundsCurrency/load', { order: ['id:DESC'], params : { 'accountId' : this.registration.mainAccount?.id }})
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      this.featureAssetsStatus = window.systemFeatures['Digital Assets']
      this.featureTradeStatus = window.systemFeatures['Trade Desk']
      this.featureAdvancedAccountRoutingStatus = window.systemFeatures['Advanced Account Routing']
      this.featureCardServicesStatus = window.systemFeatures['Card Services']
    },

    async accountKey(id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'AccountKey',
          payload: {

          }
        }))
        if(response.action.error) {

        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async checkMultiCurrency() {
      const loader = this.$loading.show()
      try {
        var result = await this.$store.dispatch('registrations/action', {
          id: this.registration.id,
          action: 'CheckMultiCurrency',
          payload: {
          }
        })
        this.$store.commit('setSingle', result.item);
//         this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async generateARL() {
      const loader = this.$loading.show()
      try {
        var result = await this.$store.dispatch('registrations/action', {
          id: this.registration.id,
          action: 'GenerateARL',
          payload: {
            accountId: this.mainAccount.id
          }
        })
        window.open("/files/registrations/"+this.registration.id+"/"+this.mainAccount.accountNumber+" " +this.mainAccount.serviceProviders.provider_name+" - ARL.pdf");
//         this.$store.dispatch('registrations/get', this.registration.id)
        this.$store.commit('setSingle', result.item);
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async providerClose() {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/action', {
          id: this.mainAccount.id,
          action: 'ProviderClose',
        })

        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/update', {
          id: this.mainAccount.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            profileSettings: {
              ...this.form,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        await this.setLastUpdatedAt()
        this.updateSettings = false
        this.$toast.success('Profile Settings successfully created.')
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        loader.hide()
      }
    },

    async update () {
      this.updateSettings = true
    },

    setLastUpdatedAt () {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes: 'Profile settings updated',
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async submitNewAccount () {
      this.$v.newAccountForm.$touch()
      if (this.$v.newAccountForm.$invalid) return
      const loader = this.$loading.show()
      try {
        const payload = {
          currency: this.newAccountForm.currency,
          createdAt: 'CURRENT_TIMESTAMP'
        }
        const confirmation = await this.$store.dispatch('account_confirmation/create', payload)
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            confirmation: confirmation.id
          }
        })
        await this.addNote('Profile Account confirmed')

        const response = await this.$store.dispatch('registrations/action', {
          id: this.registration.id,
          action: 'RunAccountCreation',
          payload: {
            provider: this.newAccountForm.provider,
            currency: this.newAccountForm.currency
          }
        })
        this.form = response.action
        //this.$toast.success('Run for Profile & Account Creation successfully finished.')

        const action = (await this.$store.dispatch('accounts/create', {
          ...this.form,
          regId: this.registration.id,
          main: true,
          partnerId: this.registration.partnerId,
          accountStatus: 'New',
          createdAt: 'CURRENT_TIMESTAMP',
          provider: this.newAccountForm.provider
        }))
        await this.$store.dispatch('accounts/action', {
          id: action.id,
          action: 'SetUUid',
          payload: {

          }
        })
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: { updatedAt: 'CURRENT_TIMESTAMP' }
        })
        await this.addNote('Profile & Account created')
        //this.$toast.success('Profile & Account Creation successfully created.')

        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            serviceProviders: {
              ...this.providerForm,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        await this.addNote('Service Providers created')
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            documentsChecklist: null
          }
        })
        location.href="/system-customers/registrations/" + this.registration.id + "?tab=setup_checklist"
        //this.$toast.success('Service Providers successfully created.')
        this.$toast.success('Profile & Account successfully created.')
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.updateStatus = false
        this.pin = null
        this.showStatus = true
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    cancel() {
      this.updateStatus = false
      this.pinFailed = false
      this.pin = null
    },

    async saveStatus() {
      const loader = this.$loading.show()
      try {
        if(this.updateStatusValue) {
          await this.$store.dispatch('accounts/update', {
            id: this.mainAccount.id,
            payload: {
              accountStatus: this.updateStatusValue,
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('registrations/get', this.registration.id)
          this.showStatus = false
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async loadPinModal() {
      const loader = this.$loading.show()
      try {
        var data=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "pinToken")).action;
        this.modalPinData=data;
      } finally {
        loader.hide();
      }
    },

    async loadCardModal() {
      const loader = this.$loading.show()
      try {
        var data=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "pinToken")).action;
        this.modalCardData=data;
      } finally {
        loader.hide();
      }
    },

    async chargeOff () {
      const loader = this.$loading.show()
      try {
        if(confirm('Are you sure you want to charge-off this account?')){
          await this.$store.dispatch('accounts/update', {
            id: this.mainAccount.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              chargeOff: true,
              chargeOffBalance: this.mainAccount.owedFee,
              chargeOffDate: 'CURRENT_TIMESTAMP',
              owedFee: 0,
            }
          })
          await this.addNote(this.mainAccount.accountNumber + ' Charged-off')
          this.$store.dispatch('registrations/get', this.registration.id)
        }
      } catch (e) {
        this.$toast.error(e.message)
      } finally {
        loader.hide()
      }
    },

    async fullPayment(item) {
      if(confirm(`Confirm Payment?`)) {
        const loader = this.$loading.show()
        let payload = {
          accountId: item.id,
          provider: this.provider.id,
        }
        try {
          (await api.action("Transactions", "new", "FullPayment", payload))
          this.$store.dispatch('registrations/get', this.registration.id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async partialPayment(item) {
      if(confirm(`Confirm Payment?`)) {
        const loader = this.$loading.show()
        let payload = {
          accountId: item.id,
          provider: this.provider.id,
        }
        try {
          (await api.action("Transactions", "new", "PartialPayment", payload))
          this.$store.dispatch('registrations/get', this.registration.id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async suspend() {
      if(confirm("Suspend Card?")) {
        const loader = this.$loading.show()
        let payload;

        var response=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "SuspendCard"));
        if(response.action.error) {
          this.$toast.error('Error: ' + response.action.errorMsg)
        }else{
          await this.addNote('Card Suspended')
          this.$store.dispatch('registrations/get', this.registration.id)
        }

        loader.hide()
      }
    },

    async unsuspend() {
      if(confirm("Unsuspend Card?")) {
        const loader = this.$loading.show()
        let payload;

        var response=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "SuspendCard"));
        if(response.action.error) {
          this.$toast.error('Error: ' + response.action.errorMsg)
        }else{
          await this.addNote('Card Unsuspended')
          this.$store.dispatch('registrations/get', this.registration.id)
        }

        loader.hide()
      }
    },

    async disableCard() {
      if(confirm("Disable Card?")) {
        const loader = this.$loading.show()
        let payload;
        var response=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "DisableCard"));
        await this.addNote('Card Disabled')
        this.$store.dispatch('registrations/get', this.registration.id)
        loader.hide()
      }
    },

    async submitLoadCard() {
      const loader = this.$loading.show()
      let payload;

      var response=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "LoadCard", this.loadCardForm));
      if(response.action.error) {
        this.$toast.error('Error: ' + response.action.errorMsg)
      }else{
        await this.addNote('Card Load')
        this.$store.dispatch('registrations/get', this.registration.id)
        this.loadCardForm = {}
        this.openLoadCard = false
      }

      loader.hide()
    },

    async submitDebitCard() {
      const loader = this.$loading.show()
      let payload;

      var response=(await api.action("Cards", this.mainAccount.cards[this.mainAccount.cards.length-1].id, "DebitCard", this.loadCardForm));
      if(response.action.error) {
        this.$toast.error('Error: ' + response.action.errorMsg)
      }else{
        await this.addNote('Card Debit')
        this.$store.dispatch('registrations/get', this.registration.id)
        this.loadCardForm = {}
        this.openDebitCard = false
      }

      loader.hide()
    },
  },

  validations () {
    const rules = {
      form: {
        domWires: { required },
        intlWires: { required },
        ach: { required },
        checks: { required },
        exchange: { required },
        acc_to_acc: { required },
        assets: { required },
        trade_desk: { required },
      },

      newAccountForm: {
        provider: { required },
        currency: { required },
      },

      loadCardForm: {
        amount: { required, decimal },
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .balance {
    color: #1f2d4e;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .checkbox-large {
    width: 17px;
    height: 17px;
  }

  .money-input {
    display: flex;
    span {
      display: inline-block;
      width: 15px;
      line-height: 29px;
    }
    .input {
      width: 100px;
    }
  }
  .select {
    width: 115px;
  }

  .space {
    td, th {
      padding-top: 20px;
    }
  }

  .footer {
    display: flex;
    padding: 0 34px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    display: block;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #67c23a;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #67c23a;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .address-line {
    margin: 0;
  }

  .content-table .title{
    color: #99aabb;
    font-weight: bold;
  }

  .content-table td{
    font-weight: bold;
  }

  .card-number, .card-exp-m, .card-cvv, .cardholder {
    font-weight: bold;
    font-size: 18px;
  }
</style>
