<template>
  <div>
    <button v-if="$hasPerm('status-update')" class="btn main" :disabled="getStatus(registration.status) == 'Active'" @click="openStatus()">Active</button>
    <button v-if="$hasPerm('status-update')" class="btn orange-btn" :disabled="getStatus(registration.status) == 'Review'" @click="openStatusReview()">Review</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Restricted'" @click="openStatusRestricted()">Restricted</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) == 'Closed'" @click="openStatusClosed()">Closed</button>
    <button v-if="$hasPerm('status-update')" class="btn btn-primary" :disabled="getStatus(registration.status) != 'Closed' || getStatus(registration.status) == 'Archived'" @click="openStatusArchived()">Archived</button> 

    <section style="margin: 20px 0;">
      <div class="cof-box">
        <div class="cof-form">
          <a class="cof-close"></a>
          <div class="cof-row">
            <div class="cof-field">
              <label>Date Search</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="form.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>&nbsp;&nbsp;
                <button class="main" @click="search()">Search</button>&nbsp;&nbsp;
                <button class="second" @click="resetItem()">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist" style="margin: 20px 0;">
      <table class="content-table">           
        <tr>
          <th>Date/Time</th>              
          <th>By</th>
          <th>Status</th>
          <th>Status Update</th>
        </tr>
        <tr v-for="(item, i) in histories" :key="i">
          <td>{{ item.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
          <td>{{ item.addedBy }}</td>
          <td>{{ item.beforeStatus }}</td>
          <td>{{ item.updateStatus }}</td>
        </tr>
      </table>
    </section>

    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Compliance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  components: {
    DateTimePicker
  },

  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    form: {
      fromDate: null,
      toDate: null,
    },
    appliedFilters: null,
    open: false,
    pin: '',
    pinFailed: false,
    popupStatus: null,
    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
  }),

  computed: {
    histories () {
      let data =  this.$store.state.partner_status_history.data || []
      return data
    },
  },

  async created () {
    await this.getHistories();
  },

  methods: {
    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },

    getStatus (status) {
      return status
    },

    async getHistories() {
      const loader = this.$loading.show()
      let params = {}
      if (!this.appliedFilters) {
        params.createdAtAfter=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"), "createdAt"];
      }else{
        if (this.appliedFilters.fromDate)
          params.createdAtAfter=[">=", this.appliedFilters.fromDate+" 00:00:00", "createdAt"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore=["<=", this.appliedFilters.toDate+" 23:59:59", "createdAt"];
      }
      params.partnerId = this.registration.id
      await this.$store.dispatch('partner_status_history/load', { order: ['createdAt:DESC'], params })
      loader.hide()
    },

    async updateStatus(status) {
      const loader = this.$loading.show()
      let payload = {
        status: status
      }
      
      await this.$store.dispatch('partners/action', {
          id: this.registration.id,
          action: 'SetStatus',
          payload
        })
      
      this.getHistories()
      loader.hide()
    },

    openStatus () {
      this.open = true
      this.popupStatus = 'Active'
    },

    openStatusRestricted () {
      this.open = true
      this.popupStatus = 'Restricted'
    },

    openStatusReview () {
      this.open = true
      this.popupStatus = 'Review'
    },

    openStatusHold () {
      this.open = true
      this.popupStatus = 'Hold'
    },

    openStatusDormant () {
      this.open = true
      this.popupStatus = 'Dormant'
    },

    async openStatusClosed () {
      this.open = true
      this.popupStatus = 'Closed'
    },

    async openStatusArchived () {
      this.open = true
      this.popupStatus = 'Archived'
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
        this.pin = null
        if(this.popupStatus == 'Active') {
          this.updateStatus('Active')
        }else if(this.popupStatus == 'Review'){
          this.updateStatus('Review')
        }else if(this.popupStatus == 'Hold'){
          this.updateStatus('Hold')
        }else if(this.popupStatus == 'Dormant'){
          this.updateStatus('Dormant')
        }else if(this.popupStatus == 'Closed'){
          this.updateStatus('Closed')
        }else if(this.popupStatus == 'Archived'){
          this.updateStatus('Archived')
        }else{
          this.updateStatus('Restricted')
        }
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    cancel() {
      this.open = false
      this.pinFailed = false
      this.pin = null
    }
  },

  watch: {
    appliedFilters () {
      this.getHistories();
    },
  },
}
</script>

<style type="scss" scoped>
  .orange-btn {
    background: #ffa700 !important;
    color: white !important;
  }
</style>