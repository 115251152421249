<template>
  <div class="applied-filters">
    <el-tag
      v-for="filter in filters"
      :key="filter.key"
      type="primary"
      effect="dark"
      class="applied-filter"
      size="small"
      closable
      @close="$emit('remove', filter)">
      {{ filter.text }}
    </el-tag>

    <button v-if="filters.length > 1" class="dark" @click="$emit('clear')">
      <i class="el-icon-close" /> Clear Filters
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
  .applied-filters {
    padding: 10px 30px;
    .el-tag {
      margin: 3px;
    }
  }
</style>