<template>
  <div>
    <section class="content-filters">
      <div class="">
        <div class="cof-row">
          <div class="cof-field">
            <label>Date Search</label>
            <div class="cof-multi flex">
              <date-time-picker
                v-model="searchForm.fromDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                :max-date="today" >
              </date-time-picker>
              <span>To</span>
              <date-time-picker
                v-model="searchForm.toDate"
                value-type="String"
                value-format="yyyy-LL-dd"
                format="yyyy-LL-dd"
                :time-picker="false"
                :auto-close="true"
                :max-date="today" >
              </date-time-picker>
              &nbsp;
              <button class="main" @click="search()">Search</button>
              &nbsp;
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Statement</th>              
          <th>Statement Date</th>
          <th>Filename</th>                     
          <th>Options</th>                     
        </tr>
        <tr v-for="statement in statements" :key="statement.id">
          <td><span>Statement</span>
            {{ statement.number }}
          </td>               
          <td><span>Statement Date</span>
            {{ statement.createdAt | date('LL') }}
          </td>
          <td><span>Filename</span>
            <template v-if="statement.file">
              <a :href="`/download/PartnerStatement/${statement.id}`" target="_blank">
                {{ statement.file }}
              </a>
            </template>
          </td>                           
          <td><span>Options</span>
           <a :href="`/download/PartnerStatement/${statement.id}`" target="_blank">
              <button class="action-button">View</button>
           </a>
          </td>                           
        </tr>
      </table>
    </section>
  </div>
</template>
<script>

import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  components: {
    DateTimePicker
  },

  data () {
    return {
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      appliedFilters: null,
    }
  },

  computed: {
    statements() {
      let data =  this.$store.state.partner_statement.data || []
      if (!this.appliedFilters) {
        data = data.filter(item => {
          return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= moment("00:00:00", "HH:mm:ss")
        })
      }else{
        if (this.appliedFilters.fromDate) {
          const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= from
          })
        }

        if (this.appliedFilters.toDate) {
          const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') <= to
          })
        }
      }
      return data
    }
  },

  async created () {
    await this.$store.dispatch('partner_statement/load', {order: ['createdAt:DESC'], params: {partnerId: this.partner.id}})
  },

  methods: {
    search() {
      this.appliedFilters = {...this.searchForm}
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
    },
  }
}
</script>
