<template>
<div class="filled">
  <label>{{ label }} <em v-if="required">*</em></label> 
  <div class="vgv-field" ref="fieldBox">
    
  </div>
</div>
</template>
<script>
export default {
  props: ["label", "required", "fieldName", "css"],
  
  data: function() {
    return {
      fields: {
        last4: {
          el: this.$refs.setLast4,
          extraArgs: {
            placeholder: "0000",
            validations: ["required", '/^([0-9]{4})$/'],
            showCardIcon: true}},
        expiryMonth: {
          el: this.$refs.setExpMonth,
          extraArgs: {
            placeholder: "00",
            validations: ["required", '/^([0-9]{2})$/']}},
        expiryYear: {
          el: this.$refs.setExpYear,
          extraArgs: {
            placeholder: "2000",
            validations: ["required", '/^([0-9]{4})$/']}},
        pin: {
          el: this.$refs.setPin,
          extraArgs: {
            placeholder: "0000",
            validations: ["required", '/^([0-9]{4})$/']}}
      },
    };
  },
  
  mounted() {
    var field=this.fields[this.fieldName];
    if (field) {
      this.$parent.collectForm.field(this.$refs.fieldBox, {
        type: "text",
        name: this.fieldName,
        successColor: "black",
        errorColor: "#D8000C",
        css: this.css || {},
        ...(field.extraArgs || {}),
      });
    }
  },
}
</script>

<style>
.vgv-field {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: white;
  border: 1px solid #bec9d4;
  line-height: 46px;
  padding: 0 12px;
  font-size: 15px;
  color: #41566c;
  font-weight: bold;
  outline: none;
}
.vgv-field iframe {
  height: 50px;
  width: 100%;
}
</style>
