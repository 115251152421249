<template>
  <drawer
    v-model="drawer"
    title="Settings"
    destroy-on-close
    @closed="$emit('close')">

    <div class="mnb-field">
      <label>Fees Option</label>
      <select v-model="fee.feesOption" class="mnb-input" disabled>
        <option></option>
        <option value="Prepay" selected>Prepay</option>
        <option value="Post-pay">Post-pay</option>
      </select>
    </div>
    <div class="mnb-field">
      <label>Volume Fee</label>
      <select v-model="fee.volumeFee" class="mnb-input" :disabled="!update">
        <option></option>
        <option value="Prepay">Prepay</option>
        <option value="Post-pay">Post-pay</option>
      </select>
    </div>

    <template #footer>
      <template   v-if="!update">
        <button
          class="button secondary mr-5"
          @click="$emit('close')">
          Close
        </button>
        <button
          v-if="$hasPerm('update-fee-Schedule')"
          class="button main"
          type="primary"
          @click="update = true">
          Update
        </button>
      </template>
      <template v-else>
        <button
          class="button secondary mr-5"
          @click="update = false">
          Cancel
        </button>
        <button
          class="button main"
          type="primary"
          @click="updateFeeOption()">
          Save
        </button>
      </template>
      
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../components/Drawer'

export default {
  props: {
    fee: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
  },

  data () {
    return {
      drawer: true,
      update: false,
    }
  },

  computed: {
    
  },

  created () {
    
  },

  methods: {
    async updateFeeOption () {
      const payload = {
        id: this.fee.id,
        payload: {
          feesOption: this.fee.feesOption,
          volumeFee: this.fee.volumeFee
        }
      }

      await this.$store.dispatch('fee_schedule/update', payload)
      this.$toast.success('Fee Option successfully updated.')
    },

    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
