<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <template>
        <template v-if="perms">
          <button class="btn btn-success" @click="openModal()"><i class="el-icon-data-analysis" /> New Analysis</button>
        </template>
        <section v-if="perms && accounts && currencyType == 'Dormant Accounts'" class="content-tlist">
          <button v-if="accounts && accounts.length > 1" class="btn btn-success check-all" @click="checkSelectAll()">All</button>
          <button v-if="accounts && accounts.length > 0" class="btn btn-primary" @click="dormant()">Dormant</button>
          <table class="content-table">
            <tr>
              <th>Select</th>
              <th>Analysis</th>
              <th>Account</th>
              <th>Type</th>
              <th>Status</th>
              <th>Admin Review</th>
              <th>Review Days</th>
              <th>Options</th>
            </tr>
            <template v-if="dormantStatus">
              <tr v-for="(item, i) in accounts" :key="i" v-if="item.accountStatus != 'Closed'">
                <td><span>Select</span>
                  <input v-if="checkAll" type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" checked="checked" />
                  <input v-else type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" />
                </td>
                <td><span>Analysis Type</span>
                  {{currencyType}}
                </td>
                <td><span>Account</span><a :href="`/system-customers/registrations/${item.regId}?tab=Accounts`">{{ item.accountNumber }}</a></td>
                <td><span>Type</span>{{ item.regType }}</td>
                <td><span>Status</span><em :class="statusColorClasses[getStatus(item.accountStatus)]">
                  {{ getStatus(item.accountStatus) }}</em></td>
                <td><span>Admin Review</span>{{ item.regAdministrative == 1? 'Yes' : 'No' }}</td>
                <td><span>Review Days</span>{{ item.regReviewDays }}</td>
                <td><span>Options</span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(item, i) in accounts" :key="i" v-if="item.accountStatus != 'Closed' && item.accountStatus != 'Dormant'">
                <td><span>Select</span>
                  <input v-if="checkAll" type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" checked="checked" />
                  <input v-else type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" />
                </td>
                <td><span>Analysis Type</span>
                  {{currencyType}}
                </td>
                <td><span>Account</span><a :href="`/system-customers/registrations/${item.regId}?tab=Accounts`">{{ item.accountNumber }}</a></td>
                <td><span>Type</span>{{ item.regType }}</td>
                <td><span>Multi-Currency</span>{{ item.fundsCurrencies? 'Yes' : 'No' }}</td>
                <td><span>Assets</span>{{ item.assetsWallets? 'Yes' : 'No' }}</td>
                <td><span>Status</span><em :class="statusColorClasses[getStatus(item.accountStatus)]">
                  {{ getStatus(item.accountStatus) }}</em></td>
                <td><span>Admin Review</span>{{ item.regAdministrative == 1? 'Yes' : 'No' }}</td>
                <td><span>Review Days</span>{{ item.regReviewDays }}</td>
                <td><span>Options</span>
                </td>
              </tr>
            </template>
          </table>
        </section>
        <section v-if="perms && accounts && currencyType == 'Charged-Off Accounts'" class="content-tlist">
          <button v-if="accounts && accounts.length > 1" class="btn btn-success check-all" @click="checkSelectAll()">All</button>
          <table class="content-table">
            <tr>
              <th>Select</th>
              <th>Analysis</th>
              <th>Account</th>
              <th>Type</th>
              <th>Name</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Options</th>
            </tr>
            <template>
              <tr v-for="(item, i) in accounts" :key="i" >
                <td><span>Select</span>
                  <input v-if="checkAll" type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" checked="checked" />
                  <input v-else type="checkbox" v-model="item.checked" class="fee-checkbox" @change="" />
                </td>
                <td><span>Analysis Type</span>
                  {{currencyType}}
                </td>
                <td><span>Account</span><a :href="`/system-customers/registrations/${item.regId}?tab=Accounts`">{{ item.accountNumber }}</a></td>
                <td><span>Type</span>{{ item.regType }}</td>
                <td><span>Name</span>{{ item.regName }}</td>
                <td><span>Charged-Off Date</span>{{ item.chargeOffDate | date('YYYY-MM-DD') }}</td> 
                <td><span>Charged-Off Amount</span>
                  $ {{ item.chargeOffBalance }}</td>
                <td><span>Options</span>
                </td>
              </tr>
            </template>
          </table>
        </section>
      </template>
    <analysis-form
      v-if="modalType"
      :modal-type="modalType"
      @close="modalType = null" />
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Compliance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import AnalysisForm from './AnalysisForm.vue'

  export default {
    name: 'account-analysis',

    components: {
      AnalysisForm
    },

    data() {
      return {
        open: true,
        pin: '',
        pinFailed: false,
        featureStatus: null,
        modalType: null,
        currencyType: null,
        accounts: null,
        checkAll: false,
        dormantStatus: false,
        perms: window.appData.currentUser?.role?.perms?.includes('account-analysis') || window.appData.currentUser?.perms?.includes('account-analysis')
      }
    },

    computed: {

      statusColorClasses() {
        return {
          Open: 'c1',
          Restricted: 'c2',
          Pending: 'c2',
          Closed: 'c4',
          Dormant: 'c3',
          Opened: 'c1',
        }
      },
    },

    async created() {
      await this.getFeatureStatus();
    },

    methods: {
      getStatus(status) {
        const names = {
          Complete: 'Pending',
          Active: 'Open'
        }
        return names[status] || status
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Account Reconciliation']
      },

      openModal () {
        this.modalType = 'Open'
      },

      checkSelectAll() {
        this.checkAll = this.checkAll?  false : true
        if(this.checkAll) {
          this.accounts.forEach(account => {
            account.checked = true
          })
        }else{
          this.accounts.forEach(account => {
            account.checked = false
          })
        }
      },

      async dormant() {
        const loader = this.$loading.show()
        let accountIds = []
        this.accounts.forEach(account => {
          if(account.checked) {
            accountIds.push(account.id)
          }
        })
        let payload = {
          accountIds: accountIds,
        }
        let action
        await api.action("Account", "new", "SetDormantAccounts", payload)
        action = (await api.action("Account", "new", "DormantAccounts")).action
        this.accounts = action.accounts
        this.dormantStatus = true
        loader.hide()
      },

      cancel() {
        window.history.back()
      },

      async checkPin() {
        const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
        if(action.pin) {
          this.pinFailed = false
          this.open = false
        }else{
          this.pinFailed = true
        }
      },

      pinInput: function(e) {
        if (e.keyCode === 13) {
          this.checkPin()
        }
      },
    },

    validations () {

    }
  }
</script>

<style lang="scss" scoped>
  .check-all {
    margin-left: 34px;
    margin-right: 45%;
  }

  .content-tlist {
    border: none;
  }
</style>