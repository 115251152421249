<template>
  <div class="report-issue">
    <div class="flex">
      <p></p>
      <p><a @click="open = true">Report Issue</a></p>
      <p></p>
    </div>
    <popup :open.sync="open">
      <div class="popup-header">
        <h3>Report Issue</h3>
      </div>
      <div class="scrollable">
        <template v-if="success">
          <div class="content-form">
            <div class="form-row form-fullw text-center">
              <div class="text-center success">
                <p>Issue report has been sent.</p>
                <p>We will contact you back shortly.</p>
                <p>Thank you!</p>
              </div>
            </div>
          </div>
          <div class="buttons text-center">
            <button class="second" @click="cancel()">Close</button>
          </div>
        </template>
        <template v-else>
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-text v-model="form.name" label="Name" :validator="$v.form.name" required />
            </div>
            <div class="form-row form-fullw">
              <form-field-text v-model="form.emailAddress" label="Email Address" :validator="$v.form.emailAddress" required />
            </div>
            <div class="form-row form-fullw">
              <form-field-text v-model="form.accountNumber" label="Account Number" :validator="$v.form.accountNumber" />
            </div>
            <div class="form-row form-fullw">
              <form-field-upload v-model="form.file" label="File Attachment" />
            </div>
            <div class="form-row form-fullw">
            <textarea v-model="form.issueDescription" placeholder="Please describe your issue *" style="width: 100%;" rows="5" :class="{ error: $v.form.issueDescription.$invalid }"
            ></textarea>
            </div>
          </div>
          <div class="buttons">
            <button class="main" @click="submit()" >Submit</button>
            <button class="second" @click="cancel()">Cancel</button>
          </div>
        </template>
      </div>
  </popup>
  </div>
</template>

<script>
  import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
  import { required, email, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'
  import mixinAutoResize from "../../components/ui/forms/autoresize.js"
  import inputMixin from "../../components/ui/forms/input-mixin.js"
export default {
  name: 'issue-reports',
  mixins: [mixinAutoResize],
  components: {
  },

  data () {
    return {
      open: false,
      success: false,

      form: {
        name: null,
        emailAddress: null,
        accountNumber: null,
        issueDescription: null,
        file: null,
      },
    }
  },

  computed: {

  },

  async created () {

  },

  methods: {
    cancel () {
      this.open = false
      this.success = false
      this.form.name = null
      this.form.emailAddress = null
      this.form.accountNumber = null
      this.form.issueDescription = null
      this.form.file = null
    },

    async submit() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      const loader = this.$loading.show()
      let payload;
      payload = {
        name: this.form.name,
        emailAddress: this.form.emailAddress,
        accountNumber: this.form.accountNumber,
        issueDescription: this.form.issueDescription,
      }
      const response = (await api.action("IssueReports", "new", "Submit", payload))
      if (this.form.file) await this.$store.dispatch('issueReports/upload', { id: response.item.id, file: this.form.file })
      await api.action("IssueReports", response.item.id, "SendEmail", payload)
      this.success = true
      loader.hide()
    }
  },
  validations () {
    const rules = {
      form: {
        name: { required, maxLength: maxLength(50), alphaNum },
        emailAddress: { required, email, maxLength: maxLength(75) },
        accountNumber: { numeric, maxLength: maxLength(20) },
        issueDescription: { required, maxLength: maxLength(500) }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
 .success p{
   color: #000;
 }

  .error {
    border-color: #ff0000;
  }
</style>