<template>
  <div class="cp-box">
    <ul class="sub-tabs" style="margin-bottom: 12px">
      <li :class="{ active: activeTab === 'settings' }" @click="activeTab = 'settings'"><a>Profile Settings</a></li>
    </ul>
    <hr v-if="$hasPerm('update-profile')">

    <settings-setup v-if="activeTab === 'settings'" :registration="registration" :noDateUpdate="true" :hideVerificationPermissionsReview="true"/>
  </div>
</template>

<script>
import SettingsSetup from './SettingsSetup'

export default {

  components: {
    SettingsSetup,
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'settings'
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },
}
</script>