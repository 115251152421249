<template>
  <popup :width="600" open @close="close()">
    <div class="popup-header">
      <h3>New Trade</h3>
    </div>

    <template v-if="!error">
      <template v-if="!addConfirmModal">
        <template v-if="system.transaction_trade">
          <div class="scrollable">
            <div class="content-form">
              <div class="form-row">
                <div class="margin-center">
                  The Trade Desk currently supports: <b>USD, BTC, ETH, LTC, USDC, USDT</b>
                </div>
              </div>
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
                <form-field-select v-model="form.tradeType" label="I want to..." :validator="$v.form.tradeType" :options="tradeTypeOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-select v-model="form.assetsCurrency" label="Assets Type" :validator="$v.form.assetsCurrency" :options="assetsCurrencyOptions" required />
                <form-field-select v-model="form.depositType" label="Amount is in..." :validator="$v.form.depositType" :options="depositTypeOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-text v-if="form.depositType == 'Funds'" v-model="form.fundsAmount" label="Amount" :validator="$v.form.fundsAmount" required />
                <form-field-text v-else v-model="form.unitsAmount" label="Amount" :validator="$v.form.unitsAmount" required />
              </div>
              <div class="invalid-balance">
                <div class="error-message" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
              </div>
              <div class="sum">
                <div>Trade Fee: <b>{{ fee | money }}</b></div>
                <div v-if="form.depositType == 'Funds'">Total Amount: <b>{{ totalFunds | money }}</b></div>
                <div v-if="form.depositType == 'Units'">Total Units: <b>{{ totalUnits | unit_asset_balance  }}</b></div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button class="main" @click="submit()">Submit</button>
            <button style="margin-right: auto; margin-left: 12px;" class="second" @click="close()">Close</button>
          </div>
        </template>
        <template v-else>
          <div class="scrollable">
            <div class="disabledText"><b>{{ system.disabled_trade_text }}</b></div>
          </div>
          <div class="buttons">
            <button style="margin-right: auto; margin-left: 12px;" class="second" @click="close()">Close</button>
          </div>
        </template>
      </template>

      <template v-else-if="!accepted">
        <div class="scrollable">
          <h3 class="subtitle quote-title">Quote Details</h3>
          <div class="content-form text-center">
            <div class="quote-panel">
              <p><b>Date: </b> {{ quote.createdAt | date }}</p>
              <p><b>Quote: </b> {{ quote.quoteNumber }}</p>
              <p><b>Type: </b> {{ quote.quoteType }}</p>
              <p><b>Asset: </b> {{ quote.assetName }}</p>
              <p><b>Base Amount: </b> {{ quote.baseAmount | balance }}</p>
              <p><b>Broker Fee: </b> {{ quote.feeAmount | balance }}</p>
              <p><b>Total Amount: </b> {{ quote.totalAmount | balance }}</p>
              <p><b>Price Per Unit: </b> {{ quote.pricePerUnit }}</p>
              <p><b>Unit Count: </b> {{ quote.unitCount }}</p>
              <p><b>Trade Fee: </b> {{ quote.tradeFee | balance }}</p>
              <br/>
              <br/>
              <template v-if="!cancelled">
                <p v-if="countDown != 0">This quote expires in {{ countDown }} seconds...</p>
                <p v-else>This quote has expired.</p>
              </template>
              <p v-if="cancelled">This Quote has been cancelled</p>
              <p v-if="errorSell">Cannot Complete - Quote amount, exceeds available balance.</p>
            </div>
          </div>
        </div>

        <div class="flex buttons quote-buttons">
          <template v-if="errorSell">
            <button style="margin-left: 12px;" class="second" @click="close()">Close</button>
          </template>
          <template v-else>
            <template v-if="countDown != 0">
              <button v-if="!cancelled" class="main" @click="accept()">Accept</button>
              <!--<button v-if="!cancelled" style="margin-left: 12px;" class="second" @click="cancel()">Cancel</button>-->
              <button v-if="cancelled" style="margin-left: 12px;" class="second" @click="close()">Close</button>
            </template>
            <template v-else>
              <button style="margin-left: 12px;" class="second" @click="close()">Close</button>
            </template>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="scrollable">
          <h3 class="subtitle quote-title">Trade Confirmation</h3>
          <div class="content-form text-center">
            <div class="quote-panel" v-if="error || failed">
              <span v-if="error">Error</span>
              <span v-if="failed">Failed</span>
            </div>
            <div class="quote-panel" v-else>
              <p><b>Date: </b> {{ trade.createdAt | date }}</p>
              <p><b>Quote: </b> {{ trade.quoteNumber }}</p>
              <p><b>Trade: </b> {{ trade.tradeNumber }}</p>
              <p><b>Type: </b> {{ quote.quoteType }}</p>
              <p><b>Asset: </b> {{ quote.assetName }}</p>
              <p><b>Base Amount: </b> {{ quote.baseAmount | balance }}</p>
              <p><b>Broker Fee: </b> {{ quote.feeAmount | balance }}</p>
              <p><b>Total Amount: </b> {{ quote.totalAmount | balance }}</p>
              <p><b>Price Per Unit: </b> {{ quote.pricePerUnit }}</p>
              <p><b>Unit Count: </b> {{ quote.unitCount }}</p>
              <p><b>Trade Fee: </b> {{ quote.tradeFee | balance }}</p>
              <p><b>Completed On: </b> {{ quote.quoteExecutedAt | date }}</p>
              <br/>
              <br/>
              <p>This trade is completed</p>
            </div>
          </div>
        </div>
        <div class="flex buttons quote-buttons">
          <button class="second" @click="close()">Close</button>
        </div>
      </template>
    </template>

    <template v-if="error">
      <div class="scrollable">
        <div class="content-form text-center">
          <div class="quote-panel">
            <p>The quote cannot be completed at this time. Please try again later.</p>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
      </div>
      <div class="buttons quote-buttons">
        <button class="second" @click="close()">Close</button>
      </div>
    </template>
  </popup>      
</template>

<script>
import { assetsAmount } from '../../../lib/validators'
import { required, maxValue, decimal } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        account: null,
        tradeType: null,
        depositType: null,
        assetsCurrency: null,
        fundsAmount: null,
        unitsAmount: null,
      },
      
      countDown : 10,
      countDownRun : true,

      invalidBalance: false,

      addConfirmModal: false,
      quote : null,

      accepted: false,
      cancelled: false,
      
      error : false,
      failed : false,
      errorSell : false,
      errorMessage : null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    totalFunds () {
        return (parseFloat(this.form.fundsAmount) + parseFloat(this.fee)) || 0
    },

    totalUnits () {
      return (parseFloat(this.form.unitsAmount)) || 0
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []

      registrations.forEach(reg => {
        if (reg.accounts?.length && (!this.currentUser.regId || reg.id==this.currentUser.regId)) {
          reg.accounts.forEach(account => {
            if(account.profileSettings?.assets && account.profileSettings?.trade_desk) {
              accounts.push({
                id: account.id,
                regId: reg.id,
                status: account.accountStatus,
                text: `${account.accountNumber}-${reg.name}`,
                accountNumber: account.accountNumber,
                provider: account.provider,
                profileSettings: account.profileSettings,
                fundsCurrencies: account.fundsCurrencies,
                assetsWallets: account.assetsWallets,
                systemAvailableBalance: account.systemAvailableBalance,
                feeSchedule: account.feeSchedule,
              })
            }
          })
        }
      })

      accounts = accounts.filter(account => ['Open'].includes(account.status))

      return accounts
    },

    depositTypeOptions () {
      const options = [
        {
          id: 'Funds',
          text: 'Funds',
        },
        {
          id: 'Units',
          text: 'Units',
        }
      ]
      return options
    },

    tradeTypeOptions () {
      const options = [
        {
          id: 'Buy',
          text: 'Buy',
        },
        {
          id: 'Sell',
          text: 'Sell',
        }
      ]
      return options
    },

    assetsCurrencyOptions () {
      let currencies = window.currencies || []
      let options = []
      let disabled = true
      currencies.forEach(currency => {
        if(currency.tradeDesk){
          if(this.selectedAccount) {
              if(this.selectedAccount.assetsWallets){
                let wallets
                wallets = this.selectedAccount.assetsWallets.filter(wallet => {
                  return wallet.currencyId === currency.id
                })
                if(wallets[0] && wallets[0].incomingAssetWalletAddress) {
                  if(currency.systemCurrencyType == 'Assets') {
                    options.push({
                      id: currency.id,
                      text: currency.currencyNickname,
                    })
                  }
                }else{
                  disabled = true
                }
              }
          }
        }
      })
      return options
    },

    withdrawalLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.form.account))
      return account
    },

    tradeLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings['trade_desk_limit']) : null
    },

    unitLimit () {
      if(this.form.assetsCurrency) {
        let wallets
        wallets = this.selectedAccount.assetsWallets.filter(wallet => {
          return wallet.currencyId === this.form.assetsCurrency
        })
        return wallets[0]? wallets[0].systemAssetAvailableBalance : null
      }
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    fee () {
      if(this.selectedAccount) {
        return this.selectedAccount.feeSchedule?.assets.fee || 0
      }else{
        return 0
      }
    }
  },

  watch: {
    'form.account' () {
      
    },

    'form.fundsAmount' () {
      this.$v.form.fundsAmount.$touch()

      if(this.form.tradeType == 'Buy'){
        if(this.form.depositType == 'Funds') {
          if (parseFloat(this.withdrawalLimit) < (parseFloat(this.form.fundsAmount) + parseFloat(this.fee))) {
            this.invalidBalance = true
            return
          } else {
            this.invalidBalance = false
            return
          }
        }else{
          if (parseFloat(this.withdrawalLimit) < (parseFloat(this.fee))) {
            this.invalidBalance = true
            return
          } else {
            this.invalidBalance = false
            return
          }
        }
      }
    },

    'form.unitsAmount' () {
      this.$v.form.unitsAmount.$touch()
      if(this.form.tradeType == 'Sell'){
        if (parseFloat(this.unitLimit) < (parseFloat(this.form.unitsAmount) )) {
          this.invalidBalance = true
          return
        } else {
          this.invalidBalance = false
          return
        }
      }else{
        if (parseFloat(this.withdrawalLimit) < (parseFloat(this.fee))) {
          this.invalidBalance = true
          return
        } else {
          this.invalidBalance = false
          return
        }
      }
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
    this.form.account = null
    this.form.tradeType = null
    this.form.depositType = null
    this.form.assetsCurrency = null
    this.form.fundsAmount = null
    this.form.unitsAmount = null

    this.accepted = false
    this.cancelled = false
    this.addConfirmModal = false
    this.addModal =true;
    this.error = false
    this.errorSell = false
  },

  methods: {
    close () {
      this.$parent.refreshTrades();
      this.$emit('close')
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false

      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        fundsAmount: this.form.fundsAmount,
        unitsAmount: this.form.unitsAmount,
        tradeType: this.form.tradeType,
        depositType: this.form.depositType,
        transactionFee: this.fee,
        completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        currencyId: this.form.assetsCurrency
      }
      let response = (await api.action("Quotes", "new", "Quotes", payload))
      this.quote = response.item
      loader.hide()
      if(this.quote.providerCreatedAt) {
        let providerCreatedAtString = this.quote.providerCreatedAt
          , reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
          , [, year, month, day, hours, minutes, seconds] = reggie.exec(providerCreatedAtString)
          , providerCreatedAt = new Date(year, month-1, day, hours, minutes, seconds);
        let quoteExpiresAtString = this.quote.quoteExpiresAt
          , quotereggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
          , [, quoteyear, quotemonth, quoteday, quotehours, quoteminutes, quoteseconds] = quotereggie.exec(quoteExpiresAtString)
          , quoteExpiresAt = new Date(quoteyear, quotemonth-1, quoteday, quotehours, quoteminutes, quoteseconds);
        const diffTime = Math.abs(quoteExpiresAt - providerCreatedAt);
        this.countDown = diffTime/1000 - 5
        this.countDownRun = true
        this.countDownTimer()
        this.addConfirmModal = true
      }else if(response.action.error){
        this.error = true
        this.errorMessage = response.action.errorMessage
      }else if(response.action.errorSell){
        this.errorSell = true
      }else{
        this.form.account = null
        this.form.tradeType = null
        this.form.depositType = null
        this.form.assetsCurrency = null
        this.form.fundsAmount = null
        this.form.unitsAmount = null
        this.error = false
        this.errorSell = false
      }
    },

    async countDownTimer() {
      if(this.countDownRun){
        if(this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }else{
          this.trade = (await api.action("Quotes", this.quote.id, "Expire")).action.trade
        }
      }
    },

    async accept () {
      this.countDownRun = false
      const loader = this.$loading.show()
      let response = (await api.action("Quotes", this.quote.id, "Accept"))
      this.trade = response.action.trade
      this.error = response.action.error
      this.failed = response.action.failed
      this.quote = response.item
      this.$parent.refreshTrades();
      loader.hide()
      this.accepted = true
    },
  },

  validations () {
    const rules = {
      form: {
        account: { required },
        depositType: { required },
        tradeType: { required },
        assetsCurrency: { required }
      }
    }
    if (this.form.depositType == 'Funds') {
      rules.form.fundsAmount = { required, decimal }
      if(this.form.tradeType == 'Buy'){
        if (this.tradeLimit) {
          rules.form.fundsAmount.limit = maxValue(this.tradeLimit)
        }
      }
    }
    if (this.form.depositType == 'Units') {
      rules.form.unitsAmount = { required, assetsAmount }
      if(this.form.tradeType == 'Sell'){
        if (this.unitLimit) {
          rules.form.unitsAmount.limit = maxValue(this.unitLimit)
        }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.quote-panel{
  display: inline-block;
  text-align: left;
}

.quote-buttons{
  justify-content: center !important;
}

.quote-title{
  font-size: 18px;
}

.margin-center {
  margin: auto;
}
</style>