<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <h4>Issue Report Search</h4>
        <div class="cof-form">
          <a class="cof-close"></a>
          <h6>Issue Search</h6>
          <div class="cof-row">
            <div class="cof-field sysint-page">
              <mnb-select v-model="form.status" :options="statusOptions" label="Status" />
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="search()">Search</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 8%;">Date</th>
          <th style="width: 8%;">Updated</th>
          <th style="width: 8%;">Name</th>
          <th style="width: 8%;">Email</th>
          <th style="width: 8%;">Account</th>
          <th style="width: 8%;">File</th>
          <th style="width: 30%;">Description</th>
          <th style="width: 7%;">Status</th>
          <th style="width: 13%;">Options</th>
        </tr>
        <tr v-for="(item, i) in filteredData" :key="i">
          <td><span>Date</span>
            {{ item.createdAt | date('YYYY-MM-D') }}<br/>
            {{ item.createdAt | date('hh:mm A') }}
          </td>
          <td><span>Updated</span>
            <template v-if="item.updatedAt">
              {{ item.updatedAt | date('YYYY-MM-D') }}<br/>
              {{ item.updatedAt | date('hh:mm A') }}
            </template>
          </td>
          <td><span>Name</span>{{ item.name }}</td>
          <td><span>Email</span>{{ item.emailAddress }}</td>
          <td><span>Account</span>{{ item.accountNumber }}</td>
          <td><span>File</span>
            <a class="link" target="_blank" :href="`files/issues/${item.id}/${item.file}`">{{ item.file }}</a>
          </td>
          <td><span>Description</span>{{ item.issueDescription }}</td>
          <td><span>Status</span>
            <template v-if="isEdit != item.id">
              <strong :class="statusColorClasses[item.status]">{{ item.status }}</strong>
            </template>
            <select v-else v-model="item.status">
              <option value="Pending">Pending</option>
              <option value="In Process">In Process</option>
              <option value="Completed">Completed</option>
              <option value="Closed">Closed</option>
              <option value="Archived" disabled>Archived</option>
            </select>
          </td>
          <td>
            <span>Options</span>
            <template v-if="isEdit == item.id">
              <button @click="saveUpdate(item)" class="action-button save">Save</button>
              <button @click="cancelEdit(item)" class="action-button cancel">Cancel</button>
            </template>
            <template v-else>
              <button @click="update(item)" class="action-button update">Status</button>
              <button v-if="item.status == 'Closed'" @click="archive(item)" class="action-button cancel">Archive</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
    <section class="pagination-section" v-if="!loading">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import Popup from '../popup'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'issue-reports-manage',

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    Popup,
    DateTimePicker,
    JsonViewer
  },

  data () {
    return {
      form: {
        status: null,
      },

      appliedFilters: null,
      loading: false,

      selectedItem: null,

      page: 1,
      perPage: 25,
      pages: [],

      isEdit: null,

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],
      statusOptions: [
        {id: 'Pending', text: 'Pending'},
        {id: 'In Process', text: 'In Process'},
        {id: 'Completed', text: 'Completed'},
        {id: 'Closed', text: 'Closed'},
        {id: 'Archived', text: 'Archived'},
      ],

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    issues () {
      let data =  this.$store.state.issueReports.data || []

      if (this.appliedFilters) {
        if (this.appliedFilters.status) {
          data = data.filter(item => item.status == this.appliedFilters.status )
        }
      }else{
          data = data.filter(item => item.status != 'Archived' )
      }

      return data
    },

    filteredData () {
      return this.paginate(this.issues);
    },

    statusColorClasses () {
      return {
        'In Process': 'c2',
        Pending: 'c2',
        Closed: 'c3',
        Completed: 'c1',
        Archived: 'c3'
      }
    },
  },

  async created () {
    await this.refreshIssues();
  },

  methods: {
    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },

    setPages () {
      let numberOfPages = Math.ceil(this.issues.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    update(item) {
      this.isEdit = item.id
      this.selectedItem = item
    },

    async saveUpdate(item) {
      const loader = this.$loading.show()
      this.loading=true;
      try {
        item.updatedAt = 'CURRENT_TIMESTAMP'

        await this.$store.dispatch('issueReports/update', { id: item.id, payload: item })
        this.isEdit = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
      this.loading=false;
      loader.hide()
    },

    cancelEdit(item) {
      this.isEdit = null
      this.selectedItem = null
    },

    async archive(item) {
      const loader = this.$loading.show()
      this.loading=true;
      try {
        item.updatedAt = 'CURRENT_TIMESTAMP'
        item.status = 'Archived'

        await this.$store.dispatch('issueReports/update', { id: item.id, payload: item })
        this.isEdit = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
      this.loading=false;
      loader.hide()
    },

    async refreshIssues () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}

      await this.$store.dispatch('issueReports/load', { order: ['ID:DESC'], params })
      this.loading=false;
      loader.hide()
    },

  },

  watch: {
    logs () {
      this.setPages();
    },

    appliedFilters () {
      this.refreshIssues();
    },
  },

  validations () {

  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px; 
  }
  .errors {
    color: #ff0000 !important;
  }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
  .content-table strong.c1 {
    color: #13ce67;
  }
  .content-table strong.c2 {
    color: #ffa700;
  }
  .content-table strong.c3 {
    color: #41566c;
  }
  .content-table strong.c4 {
    color: #e23434;
  }
  .content-tlist {
    border: none;
  }
</style>
