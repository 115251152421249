<template>
  <div>
    <button class="btn main" >New Card</button>
    <section  class="content-tlist" style="margin: 20px 0;">
      <table class="content-table">           
        <tr>
          <th>Date/Time</th>              
          <th>Provider</th>
          <th>Card Type</th>
          <th>Card Number</th>
          <th>Options</th>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    open: false,
    popupStatus: null,
  }),

  computed: {
    histories () {
      let data =  this.$store.state.account_status_history.data || []
      return data
    },
  },

  async created () {
    await this.getHistories();
  },

  methods: {
    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    async getHistories() {
      let params = {}
      params.accountId = this.registration.mainAccount.id
      await this.$store.dispatch('account_status_history/load', { order: ['createdAt:DESC'], params })
    },

    async updateStatus(status) {
      const loader = this.$loading.show()
      let payload = {
        status: status
      }

      await this.$store.dispatch('accounts/action', {
        id: this.registration.mainAccount.id,
        action: 'SetStatus',
        payload
      })
      this.$store.dispatch('registrations/get', this.registration.id)
      this.getHistories()
      loader.hide()
    },

    openStatus () {
      this.open = true
      this.popupStatus = 'Open'
    },

    openStatusRestricted () {
      this.open = true
      this.popupStatus = 'Restricted'
    },

  }
}
</script>