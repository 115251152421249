<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers">Compliance > Profile Review</h2>
      </div>
    </section>

    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <h4>Accounts Search</h4>
        <div class="cof-form">
          <a class="cof-close"></a>
          <h6>Accounts Search</h6>
          <div class="cof-row">
            <div class="cof-field">
              <label>Date Range</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="form.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
              </div>
            </div>
            <div class="cof-field">
              <label>Profile</label>
              <input type="text" v-model="form.profile" />
            </div>
            <div class="cof-field">
              <label>Name</label>
              <input type="text" v-model="form.name" />
            </div>                
            <div class="cof-field">
              <label>Profile Type</label>
              <select v-model="form.type">
                <option></option>
                <option>Business</option>
                <option>Personal</option>
              </select>
            </div>
            <div class="cof-field">
              <label>Status</label>
              <select v-model="form.status">
                <option></option>
                <option value="Review">REVIEW</option>
                <option value="Active">ACTIVE</option>
                <option value="Restricted">RESTRICTED</option>
                <option value="Suspended">SUSPENDED</option>
                <option value="Dormant">DORMANT</option>
                <option value="Closed">CLOSED</option>
              </select>
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="search()">Search</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="accordion">
      <div :class="{'cof-box' : true, 'cof-box-active' : activeInfo}">
        <h4 class="accordion-headline" @click="activeInfo = !activeInfo">Profile Review</h4>
        <h3 class="total-number" @click="activeInfo = !activeInfo">({{ totalNumber }}) Click to expand</h3>
        <profile-reviews-table :data="reviews" />
      </div>
    </div>
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Please Enter Compliance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  name: 'Profile-Reviews',

  components: {
    VueEnglishdatepicker,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        profile: null,
        name: null,
        type: null,
        currency: null,
        status: null,
      },
      appliedFilters: null,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),

      open: true,
      pin: '',
      pinFailed: false,
      activeInfo: false,
    }
  },

  computed: {
    totalNumber() {
      return this.reviews.length
    },

    reviews () {
      let data = this.$store.getters['registrations/list'] || []
      if(!this.appliedFilters) {
        data = data.filter(item => item.status == 'Review')
      }
      if (this.appliedFilters) {

        if (this.appliedFilters.fromDate) {
          const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
          data = data.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date >= from
          })
        }

        if (this.appliedFilters.toDate) {
          const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
          data = data.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date <= to
          })
        }

        if (this.appliedFilters.name) {
          data = data.filter(item => item.name.toLowerCase().includes(this.appliedFilters.name.toLowerCase()))
        }

        if (this.appliedFilters.type) {
          data = data.filter(item => item.type == this.appliedFilters.type )
        }

        if (this.appliedFilters.profile) {
          data = data.filter(item => item.mainAccount.profileNumber.includes(this.appliedFilters.profile))
        }

        if (this.appliedFilters.currency) {
          data = data.filter(item => item.currency.includes(this.appliedFilters.currency))
        }

        if (this.appliedFilters.status) {
          data = data.filter(item => item.status == this.appliedFilters.status )
        }
      }
      return data;

    },

    now () {
      return moment().format('YYYY-MM-DD')
    }
  },

  created () {
    this.$store.dispatch('registrations/load', {
      params: {
        status: 'Review',
      }
    })
  },

  methods: {
    search() {
      this.appliedFilters = {...this.form}
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },

    cancel() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },
  }
}
</script>