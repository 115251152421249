<template>
  <div>
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>
      <template v-if="featureStatus !=null && !featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Control Center</h3>
      </template>
  </div>
</template>

<script>

  export default {
    name: 'control-center',

    components: {},

    data() {
      return {
        featureStatus: null,
      }
    },

    computed: {

    },

    async created() {
      await this.getFeatureStatus();
    },

    methods: {
      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Control Center']
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>