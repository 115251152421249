<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Documents</h2>
      <div class="status">
        Status
        <status-icon :color="documentsChecklist ? 'green' : null" />
      </div>
    </div>
    <table class="content-table" style="min-width: unset;">        
      <tr>
        <th>Description</th>
        <th>Type</th>
        <th>Filename</th>
        <th style="text-align: center;">Received</th>
        <th style="text-align: center;">Verified</th> 
        <th v-if="registration.status !== 'Complete' && !mainAccount" style="text-align: right;">Options</th>                      
      </tr>
      <tr v-for="document in documents" :key="document.id">
        <td><span>Description</span>{{ document.description }}</td>         
        <td><span>Type</span>{{ document.type }}</td>         
        <td><span>Filename</span>
          <a :href="`/download/RegDocument/${document.id}`" target="_blank">
            {{ document.file }}
          </a>
        </td>
        <td style="text-align: center;"><span>Received</span>
          <input type="checkbox" :checked="document.file" disabled>
        </td>        
        <td style="text-align: center;"><span>Verified</span>
          <input type="checkbox" v-model="form[document.id]" :disabled="documentsChecklist || !document.file" @change="checkChange()">
        </td>        
        <td><span>Options</span></td>        
      </tr>
      <tr>
        <td colspan="3" class="date-time">
          <p class="date">Date / Time:</p>
          <template v-if="!documentsChecklist">N/A</template>
          <template v-else>{{ documentsChecklist.createdAt | date('LL') }}<br>{{ documentsChecklist.createdAt | date('LT') }}</template>
        </td>  
        <td v-if="registration.status !== 'Complete'" colspan="3" style="text-align: right;"><span>Options</span>
          <button v-if="!documentsChecklist" :class="{main: true, disabled: !checked}" :disabled="!checked" @click="create()">Confirm</button>
          <button v-else-if="registration.status !='Active'" class="second" @click="remove()">Reset</button>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    },
    step: {}
  },

  components: {
    StatusIcon
  },

  data () {
    return {
      form: {},
      checked: false
    }
  },

  computed: {
    isPersonal () {
      return this.registration?.type === 'Personal'
    },

    documents () {
      return this.registration.documents.filter(item => item.file && !item.deletable && item.docFor !== 'terms_and_conditions').sort((a,b)=>{
        if ((a.docAdditional*1 || 0)<(b.docAdditional*1 || 0))
          return -1;
        if ((a.docAdditional*1 || 0)>(b.docAdditional*1 || 0))
          return 1;
        if ((a.id*1 || 0)<(b.id*1 || 0))
          return -1;
        if ((a.id*1 || 0)>(b.id*1 || 0))
          return 1;
        return 0;
      });
    },

    documentsChecklist () {
      return this.$store.getters['registrations/get'](this.registration.id).documentsChecklist 
    },

    mainAccount () {
      return this.registration.mainAccount || null
    }
  },

  async created () {
    await this.$store.dispatch('registrations/get', this.registration.id)
    this.form = this.registration.documentsChecklist?.data || {}
  },

  methods: {
    async create () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            documentsChecklist: {
              data: this.form,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        await this.addNote('Documents verification completed')
        this.$toast.success('Documents successfully checked.')
        this.$emit("update:step", this.step+1)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            documentsChecklist: null
          }
        })
        await this.addNote('Documents verification reset')
        this.form = {}
        this.checked = false
        this.$toast.success('Verification successfully reset.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    checkChange() {
      this.checked = this.documents.every(element => {
        return this.form[element.id] === true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}
.disabled {
  background-color: #f6f8f9;
  color: #616f7b;
  border: 1px solid #616f7b;
}
.date-time {
  padding: 5px 0 5px 34px; 
}
.date {
  color: #000;
}
</style>
