<template>
<div>
<div class="popup" :class="{open: show}">
  <div class="pop-inner">
    <div class="pop-box">
      <a class="close" @click="$parent.currentRole=null"></a>
      <h2>Edit Role Group</h2>
      <form>
        <div class="pf-field">
          <label>Role group name</label>
          <input type="text" class="" v-model="currentRole.name" />
        </div>

      </form>
      <div class="pf-row buttons">
        <a class="pop-btn" @click.prevent="$parent.currentRole=null">Close</a>
        <button type="button" class="pop-btn" @click.prevent="saveRole()">Save</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  props: ["role"],
  data: ()=>({
    currentRole: {perms: []},
    show: false,
  }),
  methods: {
    async saveRole() {
      var role=JSON.parse(JSON.stringify(this.currentRole));
      if (role.id)
        await api.update("RoleGroup", role.id, role);
      else
        await api.create("RoleGroup", role);
      this.$parent.currentRole=null;
      this.$parent.loadRoles();
    },
  },
  mounted() {
    this.currentRole=JSON.parse(JSON.stringify(this.role));
    this.$nextTick(()=>this.show=true);
  },
}
</script>
