<template>
  <div>
    <template v-if="$hasPerm('edit-profile')">
      <button v-if="!editStatus && showEditAdd" class="btn btn-success" @click="edit()">Edit</button>
      <button v-if="editStatus && showEditAdd" class="btn btn-success" @click="save()">Save</button>
      <button v-if="editStatus && showEditAdd" class="btn btn-default" @click="editStatus=false">Cancel</button>
      <button v-if="registration.type === 'Business' && showEditAdd" class="btn btn-primary" @click="openAddContact()">Add</button>
    </template>

    
    <registration-data-info
      v-if="registration.type === 'Business'"
      ref="contacts"
      :registration="registration"
      hide-basics
      hide-info
      show-remove-contact-button />
    <registration-data-info
      v-else
      ref="contacts"
      :registration="registration"
      hide-address
      hide-contacts
      hide-basics />

    
  <popup :open.sync="addModal" :width="1250">
      <template v-if="addModal">
        <h4>Add Contact</h4>
        <div class="">
          <div class="content-form">
            <template v-for="(beneficialOwner, i) in beneficialOwners" >
              <div v-if="beneficialOwner.type === 'Beneficial Owner'" :key="beneficialOwner.email"  class="beneficial-owner-content">
                <p>Beneficial Owner - </p>
                <p> {{ beneficialOwner.firstName }} {{ beneficialOwner.lastName }}</p>
                <p class="beneficial-owner-percent">Ownership Percent</p>
                <div v-if="addContactForm.type !== 'Beneficial Owner'" class="beneficial-owner-percent-content">{{ beneficialOwner.ownership_percentage }}</div>
                <input v-else v-model="editContactForm.contacts[i].ownership_percentage" type="text" class="addContactsInput"/>
              </div>
            </template>
            <ul class="cp-fields" style="margin-top: 10px; justify-content: flex-start;">
              <li>
                <span class="form-label">Contact Type <span v-if="$v.addContactForm.type.$dirty && $v.addContactForm.type.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.type">
                  <option v-if="registration.status !== 'Active' && registration.status !== 'Complete'" value="Beneficial Owner">Beneficial Owner</option>
                  <option value="Authorized Person">Authorized Person</option>
                </select>
                <div v-if="$v.addContactForm.type.$dirty && $v.addContactForm.type.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li v-if="addContactForm.type === 'Beneficial Owner'">
                <span class="form-label">Owner Percentage <span v-if="$v.addContactForm.ownership_percentage.$dirty && $v.addContactForm.ownership_percentage.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.ownership_percentage" type="text"/>
                  <div v-if="$v.addContactForm.ownership_percentage.$dirty && $v.addContactForm.ownership_percentage.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
            </ul>
            <ul class="cp-fields" style="margin-top: 10px;">
              <li>
                <span class="form-label">First Name <span v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.firstName" type="text"/>
                <div v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Last Name <span v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.lastName" type="text"/>
                <div v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Sex <span v-if="$v.addContactForm.sex.$dirty && $v.addContactForm.sex.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.sex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <div v-if="$v.addContactForm.sex.$dirty && $v.addContactForm.sex.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">SSN/Fiscal Number <span v-if="$v.addContactForm.sin.$dirty && $v.addContactForm.sin.$invalid" class="error-message">&nbsp; *</span></span>
                <div >
                  <template >
                    <input v-model="addContactForm.sin" type="text"/>
                  </template>
                  <div v-if="$v.addContactForm.sin.$dirty && $v.addContactForm.sin.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Date of Birth <span v-if="$v.addContactForm.dob.$dirty && $v.addContactForm.dob.$invalid" class="error-message">&nbsp; *</span></span>
                <vue-englishdatepicker v-model="addContactForm.dob"/>
                <div v-if="$v.addContactForm.dob.$dirty && $v.addContactForm.dob.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Phone <span v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.phone" type="text" />
                  <div v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Mobile</span>
                <div>
                  <input v-model="addContactForm.mobile" type="text"/>
                </div>
              </li>
              <li>
                <span class="form-label">Email <span v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.email" type="text"/>
                  <div v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
            </ul>
            <ul class="cp-fields" style="margin-top: 10px; margin-bottom: 30px;">
              <li>
                <span class="form-label">Contact Country <span v-if="$v.addContactForm.country.$dirty && $v.addContactForm.country.$invalid" class="error-message">&nbsp; *</span></span>
                <select v-model="addContactForm.country" class="fullw" >
                  <option v-for="(country, key) in contactCountryOptions" :key="key" :value="key">{{ country }}</option>
                </select>
                <div v-if="$v.addContactForm.country.$dirty && $v.addContactForm.country.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Contact Address <span v-if="$v.addContactForm.address.$dirty && $v.addContactForm.address.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.address" type="text"/>
                <div v-if="$v.addContactForm.address.$dirty && $v.addContactForm.address.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Contact Address 2</span>
                <input v-model="addContactForm.address2" type="text"/>
                <!-- <div ><span class="error-message">This field is required.</span></div> -->
              </li>
              <li>
                <span class="form-label">Contact City <span v-if="$v.addContactForm.city.$dirty && $v.addContactForm.city.$invalid" class="error-message">&nbsp; *</span></span>
                <div >
                  <template >
                    <input v-model="addContactForm.city" type="text"/>
                  </template>
                  <div v-if="$v.addContactForm.city.$dirty && $v.addContactForm.city.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Contact State/Province <span v-if="$v.addContactForm.state.$dirty && $v.addContactForm.state.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <select v-if="stateOptions(addContactForm.country)" v-model="addContactForm.state" class="fullw">
                    <option v-for="(state, key) in stateOptions(addContactForm.country)" :key="key" :value="key">{{ state }}</option>
                  </select>
                  <input v-else v-model="addContactForm.state" type="text" />
                  <div v-if="$v.addContactForm.state.$dirty && $v.addContactForm.state.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Contact Zip/Postal Code <span v-if="$v.addContactForm.zip.$dirty && $v.addContactForm.zip.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.zip" type="text"/>
                </div>
                <div v-if="$v.addContactForm.zip.$dirty && $v.addContactForm.zip.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
            </ul>
            Documents
            <ul class="document" style="margin-top: 10px; margin-bottom: 30px;">
              <li>
                <span class="form-label">Government Issued ID Type</span>
                <select v-model="addContactForm.id_types[0].id_type" class="fullw" >
                  <option v-for="(country, key) in idTypes" :key="key" :value="key">{{ country }}</option>
                </select>
              </li>
              <li>
                <form-field-text v-model="addContactForm.id_types[0].id_number" :maxlength="20" label="Government Issued ID Number" required />
              </li>
              <li>
                <div class="form-row form-fullw" style="position: relative;width: 500px;">
                  <form-field-upload v-model="addContactForm.id_types[0].document" :label="`Contact ${addContactForm.id_types[0].id_type || 'Government Issued ID Type'}`" />
                </div>
              </li>
            </ul>
            <ul class="document" style="margin-top: 10px; margin-bottom: 30px;">
              <li>
                <div class="form-row form-fullw" style="position: relative;width: 500px;">
                  <form-field-upload v-model="addContactForm.addressConfirm" :label="`Contact Address Confirmation`" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="buttons">
          <button class="main" @click="addContact()">Add</button>
          <button class="second" @click="addModal = false">Cancel</button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import helperMixin from '../../components/common/helper-mixin'
import { alpha, dashNumbers, alphaNum} from '../../lib/validators'
import { required, numeric, email, requiredIf, between } from 'vuelidate/lib/validators'

import RegistrationDataInfo from './RegistrationDataInfo'
import BisinessContact from '../../RegisterForm2BusinessContact'
import VueEnglishdatepicker from 'vue-englishdatepicker'

const contact = {
  type: null,
  country: null,
  city: null,
  state: null,
  zip: null,
  address: null,
  address2: null,
  sex: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
  id_types: [
    {
      id_type: null,
      id_number: null,
      document: null
    }
  ],
  id_number: null,
  ownership_percentage: null,
  doc_address_confirmation: null
}

export default {
  mixins: [helperMixin],

  components: {
    BisinessContact,
    RegistrationDataInfo,
    VueEnglishdatepicker
  },

  props: {
    registration: {
      type: Object,
      required: true
    },
    showEditAdd: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      form: {},
      editStatus: false,
      addModal: false,
      addContactForm: {},
      editContactForm: {},
      idTypes: {
        'Passport': 'Passport',
        'Drivers License': 'Drivers License'
      }
    }
  },

  computed: {
    countryOptions () {
      return { ...window.countriesOptions }
    },

    ownershipError () {
      if (this.registration.type !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.type === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    addContactOwnershipError () {
      if (this.registration.type !== 'Business') return false
      const contacts = this.editContactForm.contacts.filter(contact => contact.type === 'Beneficial Owner')
      this.addContactForm.type === 'Beneficial Owner' ? contacts.push(this.addContactForm) : null
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    beneficialOwners () {
      return this.registration.data.contacts
    },

    contactCountryOptions () {
      return { ...window.contactCountriesOptions }
    }
  },

  watch: {
    'addContactForm.type' (value) {
        this.editNewContact()
    }
  }, 

  methods: {
    async addContact () {
      this.$v.addContactForm.$touch()
      if (this.$v.addContactForm.$invalid) return

      if (this.addContactOwnershipError) {
        this.$toast.error('Ownership Percentage of all Beneficial Owners needs to be a total of 100%')
        return
      }

      const loader = this.$loading.show()

      const payload = {
        updatedAt: 'CURRENT_TIMESTAMP',
        data: this.editContactForm
      }
      const contact = { ...this.addContactForm }
      contact.dob = moment(contact.dob).format('YYYY-MM-DD')
      if ((await api.action("Registration", "new", "duplicateContact", contact)).action.result) {
        this.$toast.error("Cannot add contact. Duplicate entry found.");
        loader.hide()
        return;
      }
      payload.data.contacts.push(contact)
      try {
        await this.$store.dispatch('registrations/update', { id: this.registration.id, payload })
        await this.addNote('Contact added')
        var token =  (await api.action("Registration", this.registration.id, "Token", {})).action.token
        var index = payload.data.contacts.length
        const prefix = `Contact ${index} `
        const contactsName = `${contact.firstName} ${contact.lastName}`
        var id_types = ['Passport', 'Drivers License'];
        id_types.forEach(id_type => {
          if(this.addContactForm.id_types[0].id_type == id_type) {
            var type = this.addContactForm.id_types[0];
            this.documentPromise(this.registration.id, token, `${contactsName}-${type.id_type}-${type.id_number}`, 'contact_government_issued_id', index, prefix + 'Government Issued ID', type.id_type, type.id_number, type.document)
          }else{
            this.documentPromise(this.registration.id, token, `${contactsName}-${id_type}- `, 'contact_government_issued_id', index, prefix + 'Government Issued ID', id_type, '', '')
          }
        })
        this.documentPromise(this.registration.id, token, `${contactsName}-Address Confirm`, 'contact_address', index, prefix + 'Address Confirmation', 'Address', 'Address', this.addContactForm.addressConfirm)

        this.$toast.success('Contact added successfully.')
        this.addModal = false
        Object.keys(this.addContactForm).forEach(item => delete this.addContactForm[item])
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async documentPromise (regId, token, filename, docFor, docAdditional, description, type, number, file) {
      var data={
        regId,
        type,
        docFor,
        docAdditional,
        description,
        number,
        createdAt: 'CURRENT_TIMESTAMP',
        deletable: false
      };
      await api.registerUpload(file?this.changeFileName(file, filename):null, {id: regId, token, obj: data});
    },

    openAddContact () {
      this.$nextTick(() => { this.$v.$reset() })
      this.$set(this, 'addContactForm', { ...contact })
      this.addModal = true
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    editNewContact () {
      if (JSON.parse(JSON.stringify(this.registration.data)).questions) {
        this.editContactForm = JSON.parse(JSON.stringify(this.registration.data)) 
      } else {
        this.editContactForm ={ ...JSON.parse(JSON.stringify(this.registration.data)), ...{ questions: {
              purpose_of_account: '',
              association_with_other_accounts: '',
              source_of_assets_and_income: '',
              intended_use_of_account: '',
              anticipated_types_of_assets: '',
              anticipated_monthly_cash_volume: '',
              anticipated_trading_patterns: '',
              anticipated_monthly_transactions_incoming: '',
              anticipated_monthly_transactions_outgoing: '',
            }
          } 
        }
      }
    },

    edit () {
      this.editStatus = true
      this.$refs.contacts.edit()
    },

    save () {
      this.editStatus = false
      this.$refs.contacts.save()
    },

    stateOptions (country) {
      if (!['CA', 'US'].includes(country)) return null

      return window.states[country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },
  },

  validations: {
    addContactForm: {
      type: { required },
      country: { required },
      city: { required, alpha },
      state: { required },
      zip: { required, alphaNum },
      address: { required },
      sex: { required },
      dob: { required },
      firstName: { required, alphaNum },
      lastName: { required, alphaNum },
      phone: { required, numeric },
      mobile: { numeric },
      email: { required, email },
      sin: { required, dashNumbers },
      ownership_percentage: { required: requiredIf(nested => nested.type === 'Beneficial Owner'), numeric, between: between(1, 100) }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
}
.scrollable {
  padding: 20px 0;
}
.error-message {
  color: red !important;
}
.error {
  border: 1px solid red !important;
}
.form-label {
  display: flex !important;
  justify-content: flex-start;
}
.beneficial-owner-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}
.beneficial-owner-percent {
  margin-left: 40px;
}
.beneficial-owner-percent-content {
  background-color: rgb(238, 238, 238);
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
.addContactsInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  cursor: #616F7B;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
</style>
