<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section class="content-filters">
      <basic-form>
        <div class="maintenance-buttons">
          <div class="maintenance-button">
            <div class="cof-field mobinl" v-if="status">
              <button class="main forcebtt" @click="maintenance()">Full Maintenance</button>
              Disabled
            </div>
            <div class="cof-field mobinl" v-else>
              <button class="main forcebtt" @click="maintenanceDisable()">Full Maintenance</button>
              Enabled
            </div>
          </div>
          <div class="maintenance-button">
            <div class="cof-field mobinl" v-if="statusSystem">
              <button class="main forcebtt" @click="maintenanceSystem()">System Buttons</button>
              Disabled
            </div>
            <div class="cof-field mobinl" v-else>
              <button class="main forcebtt" @click="maintenanceSystemDisable()">System Buttons</button>
              Enabled
            </div>
          </div>
        </div>
      </basic-form>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <div class="cof-form">
          <a class="cof-close"></a>
          <div class="cof-row">
            <div class="cof-field">
              <label>Date Search</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="form.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
              </div>
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="search()">Search</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">            
        <tr>
          <th>Action</th>
          <th>Date/Time</th>
        </tr>
        <tr v-for="(maintenance, i) in maintenances" :key="i">
          <td><span></span>{{ maintenance.mAction? 'Enabled': 'Disabled' }} - {{ maintenance.mType == 1? 'System Buttons': 'Full Maintenance'}}</td>
          <td><span></span>
            {{ maintenance.mDate | date }}
          </td>
        </tr>
      </table>
    </section>
    <section class="pagination-section">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>
  </div>
</template>

<script>
import BasicForm from '../../components/ui/forms/Form'
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  name: 'maintenance',

  components: {
    BasicForm,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
      },
      appliedFilters: null,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      page: 1,
      perPage: 25,
      pages: [],
      status: true,
      statusSystem: true
    }
  },

  computed: {
    maintenances () {
      let data =  this.$store.state.maintenance.data || []
      return data
    },

    filteredData () {
      return this.paginate(this.maintenances);
    },
  },

  async created () {
    await this.refreshMaintenances();
  },

  methods: {
    async refreshMaintenances() {
      const loader = this.$loading.show()
      this.loading=true
      let params = {}
      if (!this.appliedFilters) {
        params.mDate=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.appliedFilters.fromDate)
          params.createdAtAfter=[">=", this.appliedFilters.fromDate+" 00:00:00", "mDate"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore=["<=", this.appliedFilters.toDate+" 23:59:59", "mDate"];
      }
      await this.$store.dispatch('maintenance/load', { order: ['ID:DESC'], params })
      this.loading=false
      loader.hide()
    },

    setPages () {
      let numberOfPages = Math.ceil(this.maintenances.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
      if(this.maintenances.length > 0) {
        for(var i = 0; i < this.maintenances.length; i++){
          if(this.maintenances[i].mType == 0){
            if(this.maintenances[i].mAction) {
              this.status = false
            }else{
              this.status = true
            }
            break;
          }
        }
        for(var i = 0; i < this.maintenances.length; i++){
          if(this.maintenances[i].mType == 1){
            if(this.maintenances[i].mAction) {
              this.statusSystem = false
            }else{
              this.statusSystem = true
            }
            break;
          }
        }
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async maintenance () {
      const loader = this.$loading.show()
      let payload;
      payload = {

      }
      const response = (await api.action("Maintenance", "new", "Enable", payload))
      this.$store.dispatch('maintenance/load', { order: ['ID:DESC'] })
      loader.hide()
    },

    async maintenanceDisable () {
      const loader = this.$loading.show()
      let payload;
      payload = {

      }
      const response = (await api.action("Maintenance", "new", "Disable", payload))
      this.$store.dispatch('maintenance/load', { order: ['ID:DESC'] })
      loader.hide()
    },

    async maintenanceSystem () {
      const loader = this.$loading.show()
      let payload;
      payload = {

      }
      const response = (await api.action("Maintenance", "new", "EnableSystem", payload))
      this.$store.dispatch('maintenance/load', { order: ['ID:DESC'] })
      loader.hide()
    },

    async maintenanceSystemDisable () {
      const loader = this.$loading.show()
      let payload;
      payload = {

      }
      const response = (await api.action("Maintenance", "new", "DisableSystem", payload))
      this.$store.dispatch('maintenance/load', { order: ['ID:DESC'] })
      loader.hide()
    },

    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },
  },

  watch: {
    maintenances () {
      this.setPages();
    },
    appliedFilters () {
      this.refreshMaintenances();
    },
  },
}
</script>

<style lang="scss" scoped>
  .maintenance-buttons {
    display: flex;

    .maintenance-button {
      width: 50%;
    }
  }
</style>
