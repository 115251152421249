<template>
  <div class="cp-box">
    <ul class="sub-tabs" style="margin-bottom: 12px">
      <li :class="{ active: activeTab === 'general' }" @click="activeTab = 'general'"><a>General</a></li>
      <li v-if="partner && (partner.status == 'New' || partner.status == 'Denied')" :class="{ active: activeTab === 'boarding' }" @click="activeTab = 'boarding'"><a>Boarding</a></li>
    </ul>
    <hr v-if="$hasPerm('update-profile')">

    <general v-if="activeTab === 'general'" :id="id" />
    <setup-checklist v-if="activeTab === 'boarding'" :partner="partner" :noUpdate="true"/>
  </div>
</template>

<script>
import General from './General'
import SetupChecklist from './SetupChecklist'

export default {

  components: {
    General,
    SetupChecklist
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'general'
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    }
  },
}
</script>