<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>

    <section  class="content-tlist" v-if="!open && perms">
      <table class="content-table">
        <tr>
          <th style="width: 10%">PIN Type</th>
          <th style="width: 20%">PIN Code</th>
          <th style="width: 50%">Description</th>
          <th v-if="$hasPerm('update-pins')" style="width: 20%"> Options</th>
        </tr>
        <tr v-for="(item, i) in pins" :key="i">
          <td>
            <span>PIN Type</span>
            <template v-if="!isEdit(i)">{{ item.type }}</template>
            <select
                   v-else
                   v-model="item.type"
            >
              <option value="Admin">Admin</option>
              <option value="Transactional">Transactional</option>
              <option value="Management">Management</option>
              <option value="Compliance">Compliance</option>
              <option value="Finance">Finance</option>
            </select>
          </td>
          <td>
            <span>PIN Code</span>
            <template v-if="!isEdit(i)"> {{ item.code }} </template>
            <input
                   v-else
                   v-model="item.code"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   type = "number"
                   maxlength = "4"
                   class="pin-code"
            />
          </td>
          <td>
            <span>Description</span>
            <template v-if="!isEdit(i)"> {{ item.description }}</template>
            <input type="text"
              v-else
              v-model="item.description"
              class="description"
              maxlength="50"
            />
          </td>
          <td v-if="$hasPerm('update-pins')">
            <span>Options</span>
            <template v-if="isEdit(i)">
              <button @click="saveUpdate(i)" class="action-button save">Save</button>
              <button @click="cancelEdit(i)" class="action-button cancel">Cancel</button>
            </template>
            <template v-else>
              <button @click="editPin(i)" class="action-button update">Update</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
            <h2>Enter Admin PIN to Continue</h2>
            <div class="pf-row">
              <div class="pf-field">
                <input
                  v-model="pin"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  type = "number"
                  maxlength = "4"
                  class="pin-code"
                  v-on:keyup="pinInput"
                />
              </div>
            </div>
            <div class="pf-row">
              <div class="pf-field">
                <template v-if="pinFailed">Invalid PIN</template>
              </div>
            </div>
            <div class="pf-row buttons">
              <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
              <button class="pop-btn" @click="checkPin()">Confirm</button>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'system-pin',

  data() {
    return {
      open: true,
      editing: [],
      pin: '',
      pinFailed: false,
      perms: window.appData.currentUser?.role?.perms?.includes('update-pins') || window.appData.currentUser?.perms?.includes('update-pins')
    }
  },

  computed: {
    pins () {
      const data = this.$store.state.pin.data || []
      return data
    }
  },

  methods: {
    isEdit (index) {
      return this.editing.includes(index)
    },

    async saveUpdate (i) {
        try {
          const payload = {
            id: this.pins[i].id,
            payload: {
              type: this.pins[i].type,
              code: this.pins[i].code,
              description: this.pins[i].description
            }
          }
          
          await this.$store.dispatch('pin/update', payload)
          this.$toast.success('Pin successfully updated.')
          this.cancelEdit(i)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
    },

    editPin(index) {
      this.editing.push(index)
    },

    cancel() {
      window.history.back()
    },

    async load(){
      await this.$store.dispatch('pin/load', { params: { type: ['!=', 'System'] } })
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if(action.pin) {
        this.load()
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

  },

}
</script>

