<template>
<div>
<a class="btn btn-success" href="" @click.prevent="newUser()" v-if="false">New User</a>
<section class="content-tlist">
<table class="content-table">
  <thead>
    <tr>
      <td>Username</td>
      <td>Role</td>
      <td>Actions</td>
    </tr>
  </thead>
  <tbody>
    <tr v-for="user in users">
      <td>{{ user.username }}</td>
      <td>{{ user.role && user.role.name }}</td>
      <td>
        <a class="btn btn-primary" href="" @click.prevent="editUser(user)">View</a>
        <a class="btn btn-danger" href="" @click.prevent="deleteUser(user)" v-if="false">Delete</a>
      </td>
    </tr>
  </tbody>
</table>
</section>
<user-edit v-if="currentUser" :user="currentUser" :roles="roles" />
</div>
</template>

<script>
export default {
  data: ()=>({
    users: [],
    roles: [],
    currentUser: null,
  }),
  methods: {
    async loadUsers() {
      this.roles=(await api.list("Role")).items;
      this.users=(await api.list("User")).items;
    },
    editUser(user) {
      this.currentUser=user;
    },
    deleteUser(user) {
    },
    newUser() {
      this.currentUser={
        username: "",
        perms: [],
        role: null,
        newRole: null,
      };
    },
  },
  mounted() {
    this.loadUsers();
  },
}
</script>
