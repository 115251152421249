<template>
  <div>
    <section  class="content-tlist" style="margin: 30px 0;">
      <table class="content-table">           
        <tr>
          <th>Last Login</th>              
          <th>Username</th>                        
          <th>Data Transfer</th>                        
          <th>IP Address</th>                        
        </tr>
        <tr v-for="log in logs" :key="log.id">
          <td><span>Last Login</span>{{ log.recorded | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Username</span>{{ log.user.username }}</td>         
          <td><span>Data Transfer</span>{{ log.transferred | fileSize }}</td>         
          <td><span>IP Address</span>{{ log.ipAddress }}</td>         
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      logs: [],
      loaded: false
    }
  },

  computed: {
    users () {
      return this.$store.getters['users/list'].filter(user => parseInt(user.id) === parseInt(this.registration.user.id) || parseInt(user.subuserOf) === parseInt(this.registration.user.id))
    }
  },

  watch: {
    users: {
      handler (users) {
        if (users && !this.loaded) this.loadLogs()
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async loadLogs () {
      this.loaded = true
      this.logs = []
      for(var i = 0; i < this.users.length; i++) {
        let logins = (await api.list('UserLogin', {'userId': this.users[i].id}, 'ID:DESC', 10)).items
        logins.forEach(login => this.logs.push(login))
      }
    }
  }
}
</script>

