<template>
  <div class="cp-box">
    <ul class="sub-tabs" style="margin-bottom: 12px">
      <li :class="{ active: activeTab === 'accounts' }" @click="activeTab = 'accounts'"><a>Accounts</a></li>
      <li :class="{ active: activeTab === 'statements' }" @click="activeTab = 'statements'"><a>Statements</a></li>
      <li v-if="featureStatus" :class="{ active: activeTab === 'DisputeManager' }" @click="activeTab = 'DisputeManager'"><a>DisputeManager</a></li>
    </ul>
    <hr>

    <accounts-setup v-if="activeTab === 'accounts'" :registration="registration"/>
    <account-statements v-if="activeTab === 'statements'" :registration="registration"/>
    <dispute-manager v-if="activeTab === 'DisputeManager'" :registration="registration" />
  </div>
</template>

<script>
import AccountsSetup from './AccountsSetup'
import AccountStatements from './AccountStatements'
import DisputeManager from './DisputeManager'

export default {

  components: {
    AccountsSetup,
    AccountStatements,
    DisputeManager
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'accounts',
      featureStatus: null,
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },

  async created() {
    await this.getFeatureStatus();
  },

  methods: {
    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Dispute Manager']
    },
  }
}
</script>