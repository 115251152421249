import { render, staticRenderFns } from "./ServicesSetup.vue?vue&type=template&id=77b55550&scoped=true&"
import script from "./ServicesSetup.vue?vue&type=script&lang=js&"
export * from "./ServicesSetup.vue?vue&type=script&lang=js&"
import style0 from "./ServicesSetup.vue?vue&type=style&index=0&id=77b55550&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b55550",
  null
  
)

export default component.exports