<template>
<div>
  <ul class="role-group-list">
    <li v-for="(roleGroup, roleGId) in permissions">
      <h2 :class="classes">{{ roleGroup.title }}</h2>
      <ul>
        <li v-for="(roleTitle, roleId) in roleGroup.items" :key="'role'+roleTitle+'x'+inherited">
          <div class="form-check" v-if="!isInherited(roleId)">
            <input class="form-check-input" type="checkbox" :value="roleId" :disabled="disabled" v-model="actualValue">
            <label class="form-check-label">
              {{ roleTitle }}
            </label>
          </div>
          <div class="form-check" v-else>
            <input class="form-check-input" type="checkbox" :value="true" checked disabled>
            <label class="form-check-label">
              {{ roleTitle }}
            </label>
          </div>
        </li>
      </ul>
    </li>
  </ul>

  <div v-if="!noButtons" class="edit-buttons">
    <button v-if="disabled" @click="editPerms" class=" second">Update</button>
    <template v-else>
      <button @click="updatePermissions()" class="main" style="margin-right: 10px;">Save</button>
      <button @click="editPerms" class=" second">Cancel</button>
    </template>
  </div>
</div>
</template>

<script>
export default {
  props: ["value", "inherited", "disabled", "classes", "role", "employee", 'noButtons'],
  data: ()=>({
    actualValue: [],
    permissions: JSON.parse(JSON.stringify(appData.permissions)),
  }),
  watch: {
    value() {
      this.actualValue=this.value;
    },
    actualValue() {
      this.$emit("input", this.actualValue);
    },
    role () {
      if(this.role == 1 || this.role == 2) {
        this.actualValue = ["update-fee-Schedule","add-employees","manage-employees","add-system-message","update-system-message","remove-system-message","update-system-information","reset-system-information","update-pins","corporate-update-information","themes","corporate-deposit","corporate-withdrawal","reconcile-accounts", "corporate-statements", "corporate-new-account", "mailcast", "reject","status-update","edit-profile","update-profile","setup-checklist","set-profile","remove-documents","access","new-account","generate-statements","generate-fees", "corporate-accounts","new-transaction","approve-transaction","update-transaction","refund-transaction","reverse-transaction", "cancel-transaction", "new-scan","start-reiew","complete-review","approve-review","clear-negative-list","clear-alert", "account-analysis", "boarding-approval", "activate_accounts", "enable_accounts", "disable_accounts", "accounts_processing", "funds_processing", "cards_processing", "new_card", "activate_card", "update_card", "set_card_pin", "cancel_card"]
      }
      if(this.role == 3) {
        this.actualValue = ["reject","status-update","edit-profile","update-profile","setup-checklist","set-profile","remove-documents","access","new-account","approve-transaction","update-transaction","refund-transaction","reverse-transaction", "cancel-transaction", "new-scan","start-reiew","complete-review","approve-review","clear-negative-list","clear-alert", "account-analysis", "boarding-approval", "activate_accounts", "enable_accounts", "disable_accounts", "accounts_processing"]
      }
      if(this.role == 4) {
        this.actualValue = ["update-fee-Schedule","update-profile","new-account","generate-statements","generate-fees", "corporate-accounts","new-transaction","approve-transaction","update-transaction","refund-transaction","reverse-transaction", "cancel-transaction", "activate_accounts", "enable_accounts", "disable_accounts", "reconcile-accounts", "corporate-statements"]
      }
      if(this.role == 5) {
        this.actualValue = ["add-employees","add-system-message","update-system-message","reject","status-update","edit-profile","update-profile","remove-documents","access","new-account","new-transaction","update-transaction","refund-transaction", "activate_accounts", "enable_accounts", "disable_accounts", "cancel-transaction", "accounts_processing", "funds_processing"]
      }
      if(this.role == 6) {
        this.actualValue = ["edit-profile","new-account", "activate_accounts", "enable_accounts", "disable_accounts", "cancel-transaction"]
      }
      if(this.role == 7) {
        this.actualValue = ["reject","status-update","edit-profile","access","new-account","new-transaction","refund-transaction", "activate_accounts", "enable_accounts", "disable_accounts", "cancel-transaction"]
      }
      if(this.role == 8) {
        this.actualValue = ["add-employees","manage-employees","add-system-message","update-system-message","remove-system-message","reject","edit-profile","remove-documents","access","new-account","update-transaction","refund-transaction","new-scan","start-reiew", "activate_accounts", "enable_accounts", "disable_accounts", "cancel-transaction", "mailcast"]
      }
      if(this.role == 13) {
        this.actualValue = ["corporate-deposit","corporate-withdrawal"]
      }
      if(!this.role) {
        this.actualValue = []
      }
    }
  },
  methods: {
    isInherited(roleId) {
      if (this.inherited && this.inherited.length)
        return this.inherited.indexOf(roleId)!=-1;
      return false;
    },

    editPerms(event) {
      this.$emit('editPerms')
    },

    async updatePermissions () {
      
      const payload = {
        perms: this.actualValue
      }
      
      const loader = this.$loading.show()
      try {
        await api.update("User", this.employee.user.id, payload);
        this.$toast.success('Permissions successfully updated.')
        this.$emit('editPerms')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    }
  },
  mounted() {
  },
  
};
</script>

<style lang="scss" scoped>
  .edit-buttons {
    margin-bottom: 15px;
  }
</style>
