<template>
  <div>
    <div class="cp-box">
      <div class="cof-field mobinl">
        <label>&nbsp;</label>
        <button v-if="$hasPerm('partners-update') && canEdit" class="main" @click="addNewDocument()">Add New</button>
      </div>
      <hr>
      <!--
      <section>
        <div class="main-header">
          <h2 class="inner-headers">Profile Documents</h2>
        </div>
      </section> -->

      <section class="content-filters">
        <div v-if="addDocument" class="cof-row" style="margin-bottom: 70px;">
          <mnb-input label="Description" v-model="descriptionOther" :validator="$v.descriptionOther" style="width: 60%;"/>
          <form-field-upload v-model="addDocumentForm.file" :value="addDocumentForm.file" :validator="$v.addDocumentForm.file" label="Document" :classes="{'add-document':true}" btnClasses="add-document-btn" style="width: 255px;" />
          
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>&nbsp;</label>
            <button @click="saveDocument()" class="main" style="min-width: 100px; margin-left: 20px">Submit</button>
          </div>
          <div class="cof-field mobinl" style="margin-left: 20px;">
            <label>&nbsp;</label>
            <button class="second">Reset Fields</button>
          </div>    
        </div>
      </section>

      <section  class="content-tlist" style="margin: 30px 0;">
        <table class="content-table">           
          <tr>
            <th>Description</th>              
            <th>Filename</th>
            <th v-if="canEdit">Options</th>                          
          </tr>
          <tr v-for="(document, i) in reorderedDocuments" :key="document.id">
            <template v-if="document.description == null && document.file == null">
            </template>
            <template v-else>
              <td><span>Description</span>
                <mnb-input v-if="isEdit(i) && document.deletable && !newCopy" v-model="editingForms[i].description" :validator="$v.editingForms[i].description" :classes="{ error: $v.editingForms[i].description.$invalid }"/>
                <template v-else>
                  {{ document.description}}
                </template>
              </td>               
              <td><span>Filename</span>
                <form-field-upload v-if="isEdit(i)" v-model="editingForms[i].file" label="" :validator="$v.editingForms[i].file" :classes="{error: $v.editingForms[i].file.$invalid, 'add-document': true}" btnClasses="add-document-btn-upload" style="width: 173px;" />
                <template v-else>
                  <template v-if="document.file">
                    <a :href="`/download/PartnerDocument/${document.id}`" target="_blank">
                      {{ document.file }}
                    </a>
                  </template>
                </template>
              </td>
              <td v-if="canEdit"><span>Options</span>
                <template v-if="isEdit(i)">
                  <button @click="saveUpdateDocument(i)" class="document-handle save">Save</button>
                  <button @click="cancelEdit(i)" class="document-handle cancel">Cancel</button>
                </template>
                <template v-else>
                  <button v-if="$hasPerm('update-profile')" @click="editDocument(i)" class="document-handle update">Update</button>
                  <button v-if="$hasPerm('remove-documents')" @click="deleteDocument(document.id, document.deletable)" class="document-handle remove">Remove</button>
                </template>
              </td>
            </template>
          </tr>
          <tr v-if="!documents.length">
            <td class="empty-table-text" colspan="5">There are no Documents</td>
          </tr>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import FormFieldUpload from '../../components/FormFieldUpload'

import { required } from 'vuelidate/lib/validators'
import helperMixin from '../../components/common/helper-mixin'

export default {
  name: 'ProfileDocuments',

  mixins: [helperMixin],

  components: {
    MnbInput,
    FormFieldUpload,
    MnbSelect
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      addDocument: false,
      addDocumentForm: {
        description: null,
        type: null,
        number: null,
        file: null
      },
      descriptionOther: null,
      typeOther: null,
      editing: [],
      editingForms: {},
      isCopy: false,
      documentChanged: false,
      newCopy: false
    }
  },

  watch: {
    'addDocumentForm.file' (value) {
      if (this.isCopy) {
        this.documentChanged = true
      }
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    },

    canEdit () {
      return true
    },

    documents () {
      return (this.$store.state.partner_documents.data || []).filter(doc => parseInt(doc.partnerId) === parseInt(this.id))
    },

    reorderedDocuments () {
      const reordered = []
      return this.documents
    },
  },

  created() {
    this.$store.dispatch('partner_documents/load', { params: { partnerId: this.id }, order: ['createdAt:DESC']})
  },

  methods: {
    addNewDocument () {
      this.addDocument = true
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    async saveDocument () {
      this.$v.$touch()
      if (!this.$v.addDocumentForm.$invalid && !this.$v.descriptionOther.$invalid) {
        try {
          const loader = this.$loading.show()
          const documentDescription = this.descriptionOther
          await this.documentPromise(this.id, true, documentDescription, this.addDocumentForm.file)
          await this.setLastUpdatedAt()
          this.$toast.success('Document successfully added.')
          Object.keys(this.addDocumentForm).map(item => this.addDocumentForm[item] = null)
          this.descriptionOther = null
          this.isCopy = false
          this.documentChanged = false
          this.$v.$reset()
          loader.hide()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async saveUpdateDocument (i) {
     
      if (this.editingForms[i]) {
        this.$v.editingForms[i].$touch()
        if (!this.$v.editingForms[i].$invalid) {
          const loader = this.$loading.show()
          try {
            await this.documentUpdatePromise(this.reorderedDocuments[i].id, this.editingForms[i].description, this.editingForms[i].file)
            await this.setLastUpdatedAt()
            this.$toast.success('Document successfully updated.')
            this.cancelEdit(i)
            this.newCopy = false
          } catch (e) {
            this.$toast.error(window.errorMessage)
          }
          loader.hide()
        }
      }
    },

    async documentPromise (partnerId, deletable, description, file) {
      const document = await this.$store.dispatch('partner_documents/create', {
        partnerId,
        deletable,
        description,
        file: this.isCopy ? file.name : null,
        isCopy: this.isCopy,
        createdAt: 'CURRENT_TIMESTAMP'
      })
      if(file && (!this.isCopy || this.documentChanged)) await this.$store.dispatch('partner_documents/upload', { 
        id: document.id, 
        file: file,
        params: {
          virusscan: true
        } })
    },

    async documentUpdatePromise (documentId, description, file) {
      const updateDocument = await this.$store.dispatch('partner_documents/update', { id: documentId, payload: {
        description,
      }})
      if(file) await this.$store.dispatch('partner_documents/upload', { 
        id: documentId, 
        file: file,
        params: {
          virusscan: true
        } })
    },

    async deleteDocument(id, deletable) {
      if(confirm(`Are you sure to delete document?`)) {
        if (deletable) {
          await this.$store.dispatch('partner_documents/delete', id)
        } else {
          await this.$store.dispatch('partner_documents/update', { id: id, payload: {
            file: null
          }})
        }
        await this.setLastUpdatedAt()
        this.$toast.success('Document deleted successfully.')
      }
    },

    setLastUpdatedAt () {
      return Promise.all([
        this.$store.dispatch('partners/update', { id: this.partner.id, payload: { updatedAt: 'CURRENT_TIMESTAMP' } }),
        this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: this.partner.id,
          notes: 'Documents updated',
          createdAt: 'CURRENT_TIMESTAMP'
        })
      ])
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      this.$delete(this.editingForms, i)
      this.newCopy = false
    },

    editDocument(index) {
      this.editing.push(index)
      this.$set(this.editingForms, index, {
        description: this.reorderedDocuments[index].description,
        type: this.reorderedDocuments[index].type,
        number: this.reorderedDocuments[index].number,
      })
    },

    async copy(i) {
      const loader = this.$loading.show()
      this.descriptionOther = this.reorderedDocuments[i].description
      if (this.reorderedDocuments[i]) {
        this.addDocumentForm.file = new File(["foo"], this.reorderedDocuments[i]?.file, {
          type: "text/plain",
        })
      }
      this.isCopy = true
      await this.saveDocument()
      if (this.reorderedDocuments[i].deletable) {
        await this.$store.dispatch('partner_documents/delete', this.reorderedDocuments[i].id)
      } else {
        await this.$store.dispatch('partner_documents/update', { id: this.reorderedDocuments[i].id, payload: {
          file: null
        }})
      }
      await this.setLastUpdatedAt()
      this.$toast.success('Document deleted successfully.')
      loader.hide()
    }
  },

  validations () {
    const rules = {
      addDocumentForm: {
        file: { required },
      },
      descriptionOther: { required },
      editingForms: {}
    }

    if (this.editingForms) {
      for (const index in this.editingForms) {
        rules.editingForms[index] = { 
          file: { required },
          description: { required },
        }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .mobinl {
    margin-bottom: 20px;
  }
  .document-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white; 
    border: none;
    outline: none; 

    &.update {
      background-color: #ffa700; 
    }
    &.remove {
      background-color: #d62b2b; 
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
  .content-filters {
    margin-top: 80px; 
  }
</style>
