<template>
  <div id="form-business">
    <template v-if="step===undefined || step===1">
    <h4>Business Information <span>*</span></h4>
    <div class="form-row form-split">
      <form-field-text v-model="form.legal_name" :validator="v.legal_name" label="Legal Name" :maxlength="50" required />
      <form-field-text v-model="form.dba_name" :validator="v.dba_name" label="DBA Name" :maxlength="50" required />
    </div>
    <div class="form-row form-fullw">
      <div style="position: relative;">
        <form-field-select v-model="form.country" :validator="v.formation_country" label= "Country" :options="countryOptions" required />
        <a class="what-this" @click.prevent="countryModal = true">Prohibited Countries</a>
      </div>
    </div>
    <div class="form-row form-split">
      <form-field-text label="Address" v-model="form.address" :validator="v.address" :maxlength="50" required />
      <form-field-text :validator="v.address2" label="Address 2" v-model="form.address2" :maxlength="50" />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.city" :validator="v.city" label="City" :maxlength="25" required />
      
      <form-field-select v-if="stateOptions" v-model="form.state" :validator="v.state" label="State/Province" :options="stateOptions" required />
      <form-field-text v-else v-model="form.state" :validator="v.state" label="State/Province" :maxlength="50" required />
    </div>
    <div class="form-row form-split">
      <form-field-text label="ZIP / Postal Code" v-model="form.zip" :validator="v.zip" :maxlength="15" required />
      <form-field-text label="Phone" v-model="form.phone" :validator="v.phone" :maxlength="20" required />
    </div>
    <div class="form-row form-split">
      <form-field-text label="Email" v-model="form.email" :validator="v.email" required />
      <form-field-text label="Website" v-model="form.website" :validator="v.website" />
    </div>
    </template>

    <template v-if="step===undefined || step===2">
    <div v-for="(contact, index) in form.contacts" :key="index">
      <h4>Contacts <template v-if="index"> {{ index + 1 }}</template> Information <span>*</span></h4>
      <partner-contact v-model="form.contacts[index]" :v="v.contacts.$each[index]" />
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.contacts.length < 6" @click="$emit('add-contact')">+ Add Another Contact</a>
      </div>
      <a v-if="form.contacts.length > 1" @click="$emit('remove-contact')">- Remove Contact</a>
    </div>
    </template>
    
    <template v-if="step===undefined || step===4">
    <h4>Business Description</h4>
    <div class="form-row form-fullw">
      <form-field-textarea
        v-model="form.description"
        label="Partner Business Description"
        :rows="3"
        :maxlength="150"
        :validator="v.description"
         />
    </div>
    </template>
    <template v-if="step===undefined || step===5">
    <h4>
      Document Submission
    </h4>

    <p v-if="form.submit_later">Final Account Activation will only occur once all documents have been received. </p>          
    <div v-for="(document, index) in form.documents" :key="'docs_' + index">
      <div class="form-row form-fullw">
        <form-field-upload v-model="form.documents[index].file" :label="`Document ${index + 1} (10MB max)`" />
      </div>
      <div class="form-row form-fullw">
        <form-field-text :label="`Document ${index + 1} Description`" v-model="form.documents[index].description" required :maxlength="30"/>
      </div>
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.documents.length < 4" @click="$emit('add-document')">+ Add Another Document</a>
      </div>
      <a v-if="form.documents.length > 1" @click="$emit('remove-document')">- Remove Document</a>
    </div>
    </template>
    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>PROHIBITED COUNTRIES</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="item in prohibitedCountries">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import PartnerContact from './RegisterFormPartnerContact'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },
    
    step: {
      type: Number,
      required: false,
    },
  },

  components: {
    PartnerContact
  },

  data () {
    return {
      form: this.value,
      issuedModal: false,
      countryModal: false,
      prohibitedCountries: window.prohibitedCountries
    }
  },

  computed: {
    groupedQuestions () {
      const list = []

      let group = []
      this.questions.forEach(question => {
        group.push(question)
        if (group.length === 2) {
          list.push(group)
          group = []
        }
      })

      if (group.length) {
        list.push(group)
      }

      return list
    },

    questions () {
      return window.questions
    },

    countryOptions () {
      return window.countriesOptions
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },
  },

  watch: {
    'form.formation_country' (value) {
      this.form.state = null
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>
