<template>
  <div v-if="loading">
    <section class="content-filters" style="margin: 0 0 30px 0;">
      <div class="flex-between">
        <h2 class="inner-headers">Customer Profiles</h2>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Registered</th>
          <th>Updated</th>
          <th>Approved</th>
          <th>Profile</th>
          <th>Type</th>
          <th>Name</th>
          <th>Status</th>
        </tr>
        <tr v-for="registration in registrations" :key="registration.id">
          <td><span>Registered Date</span>{{ registration.createdAt | date('MM/D/YYYY') }}</td>
          <td><span>Updated Date</span><template v-if="registration.updatedAt">{{ registration.updatedAt | date('MM/D/YYYY') }}</template></td>
          <td><span>Approved Date</span><template v-if="registration.approvalDate">{{ registration.approvalDate | date('MM/D/YYYY') }}</template></td>
          <td><span>Profile</span>
            <a :href="`/system-customers/registrations/${registration.id}`">{{  registration.profileNumber }}</a>
          </td>
          <td><span>Profile Type</span>{{ registration.type }}</td>
          <td>
            <span>Name</span>
            <template v-if="registration.type === 'Personal'">{{ registration.data.firstName }} {{ registration.data.lastName }}</template>
            <template v-else>{{ registration.data.legal_name }}</template>
          </td>
          <td><span>Status</span><em :class="statusColorClasses[registration.status]">
            {{ registration.status === 'Complete' ? 'Activation' : registration.status }}</em></td>
        </tr>
        <tr v-if="!registrations.length">
          <td colspan="7" class="no-data">No data</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
  

export default {
  components: {
   
  },

  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    loading: false,
  }),

  computed: {
    statusColorClasses () {
      return {
        Active: 'c1',
        Restricted: 'c2',
        Complete: 'c2',
        Activation: 'c2',
        Suspended: 'c2',
        Dormant: 'c3',
        Closed: 'c1'
      }
    },

    registrations () {
      let registrations = this.$store.state.registrations.data || []
      return registrations
    },
  },

  watch: {
    
  },

  async created () {
    const loader = this.$loading.show()
    await this.refreshRegistrations();
    loader.hide()
    this.loading = true
  },

  methods: {
    async refreshRegistrations () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.corporate = ['!=',  1];
      
      if(this.partner) {
        params.partnerId = this.partner.id
      }

      await this.$store.dispatch('registrations/load', { order: ['approvalDate:DESC'], params, skip: ['user', 'contact', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'primeAccount', 'accounts', 'complianceScan', 'mainAccount', 'serviceProviders', 'complianceReview', 'setupComplete', 'profileSettings'] })
      this.loading=false;
      loader.hide()
    },
    
  },

  validations () {
    
  }
}
</script>

<style lang="scss" scoped>
  .content-table .title{
    color: #99aabb;
    font-weight: bold;
  }

</style>
