<template>
  <div class="flex">
    <div class="half">
      <section class="content-filters" style="margin: 15px 30px;">
        <template v-if="registration && registration.accounts">
          <div style="margin: 0 30px; padding: 0 16px;">
            <h3 class="subtitle">My Accounts</h3>
          </div>
          <div class="accordion">
          <template v-for="account in registration.accounts">
            <div v-if="account.accountStatus == 'Open' || account.accountStatus == 'Active' || account.accountStatus == 'Restricted'" class="cof-customer-box cof-box-active">
              <div class="flex" style="justify-content: space-between;padding: 10px 10px;">
                <div>
                  <h2>{{ account.serviceProviders.label }}</h2>
                  <h3>{{ account.accountNumber }}</h3>
                </div>
                <div>
                  <h3>USD</h3>
                  <h2>{{ account.systemTotalBalance | balance }}</h2>
                </div>
              </div>
              <button v-if="account.cards && account.cards.length > 0 && (account.cards[account.cards.length-1].cardStatus == 'Active' || account.cards[account.cards.length-1].cardStatus == 'Suspended')" class="btn btn-success" @click="mainAccount = account, openViewCard = true">View Card</button>
            </div>
          </template>
          </div>
        </template>
        <div class="view-more" v-if="!this.currentUser.parId">
          <a href="/customers/myprofile?active=2">View Accounts &rarr;</a>
        </div>
        <div v-if="partner">
          <h2 class="inner-headers">Tracking Link</h2>
        </div>
        <table class="content-table" v-if="partner">           
          <tr>
            <th>Type</th>              
            <th>Link</th>
            <th>Options</th>                          
          </tr>
          <tr>
            <td>Registration Tracking Link</td>
            <td>{{ trackingLink }}</td>
            <td>
              <template v-if="$hasPerm('partners-manage')">
                <button class="main" v-if="!trackingLink" v-on:click.prevent="createCode" :disabled="!partner.settings.registrationTracking">New Link</button>
                <button class="main" v-else v-on:click.prevent="copyCode" :disabled="!partner.settings.registrationTracking">Copy</button>
              </template>
            </td>
          </tr>
        </table>
      </section>
      <popup :open.sync="openViewCard" class="view-card">
        <div class="scrollable">
          <div class="card-data-container" v-if="mainAccount && mainAccount.cards.length > 0">
           <div class="card-number">
            {{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(0,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(4,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(8,4) }}&nbsp;&nbsp;&nbsp;{{ mainAccount.cards[mainAccount.cards.length-1].cardLast4.substr(12, 4) }}
           </div>
           <div class="card-exp-m">
            {{ mainAccount.cards[mainAccount.cards.length-1].expiryMonth }}/{{ mainAccount.cards[mainAccount.cards.length-1].expiryYear.substr(-2) }}
           </div>
           <div class="card-cvv">
            {{ mainAccount.cards[mainAccount.cards.length-1].cvv }}
           </div>
           <div class="cardholder">
            {{ this.registration.data.firstName }} {{ this.registration.data.lastName }}
           </div>
          </div>
          <div class="buttons text-center">
            <button class="second" @click="openViewCard = false">Close</button>
          </div>
        </div>
      </popup>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      featureStatus: true,
      activeInfo: false,
      openViewCard: false,
      mainAccount: null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    registration () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },
    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    statusColorClasses () {
      return {
        Open: 'c1',
        Pending: 'c2',
        Closed: 'c4',
        Dormant: 'c3'
      }
    },

    assetsList () {
      let currencies = window.currencies || []
      let assets = [];
      currencies = currencies.filter(currency => {
        return currency.systemCurrencyType === 'Assets'
      })
      currencies.forEach(currency => {
        assets.push(currency.currencyNickname)
      })
      return assets;
    },

    trackingLink () {
      return this.partner?.absRefLink;
    },
  },

  async created () {
    await this.getFeatureStatus();
    if(this.currentUser.regId) {
      await this.$store.dispatch('registrations/get', this.currentUser.regId)
    }
    if(this.currentUser.parId) {
      await this.$store.dispatch('partners/get', this.currentUser.parId)
    }
  },

  methods: {
    async genRefLink() {
      if (this.partner)
        var data=await this.$store.dispatch('partners/action', {
          id: this.partner.id,
          action: 'genRefLink',
          payload: {}
        })
    },
    getCurrencyName (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    getAssetsSystemAssetAvailableBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetAvailableBalance : null
    },

    getAssetsSystemAssetTotalBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetTotalBalance : null
    },

    getIncomingAssetWalletAddress(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].incomingAssetWalletAddress : null
    },

    getCurrencyStatus(fundsCurrencies, currency) {
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currency)
      if(fundsCurrency){
        return fundsCurrency.currencyEnabled
      }else{
        return null
      }
    },

    getSystemFundsAvailableBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemAvailableBalance : null
    },

    getSystemFundsTotalBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemTotalBalance : null
    },

    getFundsCurrencySymbol(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].currency.symbol : null
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
    },

    async createCode () {
      var hasTrackingLink=this.trackingLink;
      if (hasTrackingLink) {
        if (!confirm('Are you sure you want to update the tracking link?')) return
      } else {
        if (!confirm('Are you sure you want to create the tracking link?')) return
      }

      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('partners/action', {
          id: this.partner.id,
          action: 'genRefLink',
          payload: {}
        })
        if (hasTrackingLink) {
          this.$toast.success('Tracking code created')
        } else {
          this.$toast.success('Tracking code created')
        }
        this.selected = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    copyCode () {
      navigator.clipboard.writeText(this.trackingLink)
    }
  },
}
</script>

<style lang="scss" scoped>
.view-more {
  margin-top: 7px;
  text-align: right;
  &:hover {
    font-weight: bold;
  }
}
  .link {
    color: #67C23A !important;
  }

.accordion .cof-customer-box {
  position: relative;
  height: 51px;
  overflow: hidden;
  padding: 0 16px;
  margin: 15px 30px;
  background: #f8f9fb;
  border-radius: 16px;
}

.accordion .cof-box-active {
  height: auto;
}

.card-number, .card-exp-m, .card-cvv, .cardholder {
  font-weight: bold;
  font-size: 18px;
}
</style>
