<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers">&nbsp;</h2>
        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>
    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section  class="content-tlist">
        <table class="content-table">
          <tr>
            <th v-for="(item, i) in header">{{ item }}</th>
            <th>Options</th>
          </tr>
          <tr v-for="account in accounts" :key="account.id">
            <td v-for="(item, i) in account" :key="i">
              <template v-if="i == 'providerCreatedAt'">
                <date-time-picker
                  v-if="isEdit(account.id)"
                  v-model="itemproviderCreatedAt"
                  value-type="String"
                  :auto-close="true"
                />
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else-if="i == 'providerAccountNumber'">
                <input type="text" v-model="itemproviderAccountNumber" v-if="isEdit(account.id)"/>
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else-if="i == 'routingNumber'">
                <input type="text" v-model="itemroutingNumber" v-if="isEdit(account.id)"/>
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else-if="i == 'result'">
                <input type="text" v-model="itemresult" v-if="isEdit(account.id)"/>
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else>{{ item }}</template>
            </td>
            <td><span>Options</span>
              <template v-if="isEdit(account.id)">
                <button @click="saveUpdate(account)" class="action-button save">Save</button>
                <button @click="cancelEdit(account.id)" class="action-button cancel">Cancel</button>
              </template>
              <template v-else-if="!editStatus">
                <button @click="edit(account)" class="action-button update">Edit</button>
              </template>
            </td>
          </tr>
        </table>
      </section>
    <section class="pagination-section">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>

    <account-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import AccountFilters from './filters/AccountFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'

  export default {
    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      AccountFilters,
      AppliedFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,
      activeInfo: false,
      page: 1,
      perPage: 25,
      pages: [],
      totalNumber: 0,
      editing: [],
      itemproviderCreatedAt: null,
      itemproviderAccountNumber: null,
      itemroutingNumber: null,
      itemresult: null,
      editStatus: false
    }),

    computed: {
      profileStatuses () {
        return window.profileStatuses
      },

      appliedFilters () {
        const filterNames = {
          accountId: 'Account ID',
          name: 'Name',
          type: 'Account Type',
          status: 'Account Status'
        }

        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },

      profileStatusesWithoutComplete () {
        const array = ['Complete', 'Active', 'Review', 'Restricted', 'Hold', 'Dormant', 'Closed']
        array.splice(0, 1)
        return array
      },


      accounts () {
        let accounts = this.$store.state.accounts.data || []
        this.totalNumber = this.$store.state.accounts.count || 0
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {

        }
        if (this.filters) {
          if (this.filters.name) {
            accounts = accounts.filter(account => {
              return account.regName?.toLowerCase().includes(this.filters.name.toLowerCase())
            })
          }

          if (this.filters ?.status) {
            accounts = accounts.filter(account => account.accountStatus === this.filters.status)
          }

          if (this.filters.accountId) {
            accounts = accounts.filter(account => {
              return account.accountNumber?.includes(this.filters.accountId)
            })
          }

          if (this.filters.type) {
            accounts = accounts.filter(account => account.regType === this.filters.type)
          }
          this.totalNumber = accounts.length
        }
        return accounts
      },

      header () {
        let header = []
        if(this.accounts.length > 0) {
          header = Object.keys(this.accounts[0])
        }
        return header
      },

      statusColorClasses () {
        return {
          Active: 'c1',
          Restricted: 'c2',
          Complete: 'c2',
          Activation: 'c2',
          Suspended: 'c2',
          Dormant: 'c3',
          Closed: 'c1'
        }
      },
    },

    async created () {
      await this.refreshAccounts();
    },

    methods: {
      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(account) {
        this.editStatus = true
        this.itemproviderCreatedAt = account.providerCreatedAt
        this.itemproviderAccountNumber = account.providerAccountNumber
        this.itemroutingNumber = account.routingNumber
        this.itemresult = account.result
        this.editing.push(account.id)
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
        this.editStatus = false
      },

      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      getFilterValue (key, value) {
        if (key === 'status') return window.customerProfileStatuses[value]

        return value
      },

      clearFilters () {
        this.filters = null
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      async refreshAccounts () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        params.regId=["!=", 1];
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {

        } else {
          if (this.filters.fromDate)
            params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
          if (this.filters.toDate)
            params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];

          if(this.filters.name) {
            params.regName = ['like', '%' + this.filters.name + '%'];
          }

          if(this.filters.status) {
            params.accountStatus = ['like', '%' + this.filters.status + '%'];
          }

          if(this.filters.status) {
            params.accountStatus = ['like', '%' + this.filters.status + '%'];
          }

          if (this.filters.accountId) {
            params.accountNumber = ['like', '%' + this.filters.accountId + '%'];
          }

          if (this.filters.type) {
            params.regType = ['like', '%' + this.filters.type + '%'];
          }
        }

        await this.$store.dispatch('accounts/load', { order: ['createdAt:DESC'], params, limit: this.perPage, start: (this.page-1) * this.perPage, skip: ['primeAccount', 'solidAccount', 'serviceProviders', 'assetsWallets', 'fundsCurrencies', 'complianceScan', 'profileSettings', 'cards', 'documents', 'primeAccount', 'currencyName', 'regName', 'regCorp', 'regType', 'regAdministrative', 'regReviewDays'] })
        this.loading=false;
        loader.hide()
      },

      setPages () {
        let numberOfPages = 0;
        numberOfPages = Math.ceil(this.totalNumber / this.perPage);

        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      async paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },

      async saveUpdate (account) {
        try {
          const payload = {
            id: account.id,
            payload: {
              providerCreatedAt: this.itemproviderCreatedAt,
              providerAccountNumber: this.itemproviderAccountNumber,
              routingNumber: this.itemroutingNumber,
              result: this.itemresult,
            }
          }

          await this.$store.dispatch('accounts/update', payload)
          this.refreshAccounts()
          this.$toast.success('Account successfully updated.')
          this.cancelEdit(account.id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

    },

    watch: {
      accounts () {
        this.setPages();
      },

      appliedFilters () {
        this.refreshAccounts();
      },

      page() {
        this.refreshAccounts();
      },

      perPage() {
        if(this.page == 1){
          this.refreshAccounts();
        }else{
          this.page = 1
        }
      }
    },
  }
</script>
<style scoped>
  th {
    padding: 5px;
  }

  .content-tlist {
    overflow: scroll;
    max-width: 1500px;
    max-height: 100vh;
  }


  .content-table td div {
    overflow: visible !important;
  }
</style>
