<template>
  <div class="cp-box" v-if="registration">
    <template v-if="featureStatus ==null"></template>
    <template v-else>
      <template v-if="!featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Card Services</h3>
      </template>
      <template v-else>
        <ul class="sub-tabs">
          <li :class="{ active: activeTab === 'DebitCard' }" @click="activeTab = 'DebitCard'"><a>Debit Card</a></li>
        </ul>
        <hr>
        <debit-card v-if="activeTab === 'DebitCard'" :registration="registration" />
      </template>
    </template>
  </div>
</template>

<script>
import DebitCard from './DebitCard.vue'
import DisputeManager from './DisputeManager'

export default {
  components: {
    DebitCard,
    DisputeManager
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'DebitCard',
      featureStatus: null,
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },

  async created() {
    await this.getFeatureStatus();
  },

  methods: {
    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Card Services']
    },
  }
}
</script>
