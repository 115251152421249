<template>
  <div style="display: flex; justify-content: space-around;">
    <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
      <div class="popup-header">
        <h3>Deposit Payment Details</h3>
      </div>
      <template v-if="!nextFlag">
        <div class="scrollable">
          <div class="content-form content-form-modern text-center">
            <div class="form-row form-split depositType">
              <form-field-select v-model="form.depositType" label="Deposit Type" :validator="$v.form.depositType" :options="depositTypeOptions" required />
            </div>
          </div>
          <div class="buttons">
            <button class="main" @click="moveNextPopup()">Next</button>
            <button class="second" @click="open = false">Cancel</button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="scrollable" v-if="form.depositType == 'Funds'">
          <div class="content-form content-form-modern">
            <div class="form-row form-split">
              <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required class="notranslate" />
              <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount || !form.currency" :label="`Deposit Type`" :validator="$v.form.deposit_type" :options="depositOptions" required />
            </div>
          </div>
          <div class="form-row payment-reference">
            <label v-if="infos">
              Deposit Payment Reference: {{ infos['reference'] }}
            </label>
            <label v-else>
              &nbsp;
            </label>
            <button v-if="!infos" class="main" @click="paymentDetails()">Submit</button>
          </div>
          <br/>
          <div class="content-form content-form-modern bank-info">
            <h3 class="subtitle">Deposit Details</h3>
            <div v-if="error" class="form-row form-fullw">
              {{ error }}
            </div>
            <template v-if="infos">
              <template v-if="infos['provider'] == 'Solid'">
                <template v-if="form.deposit_type == 'domestic_wire'">
                  <div class="form-row form-split">
                    <label>
                      <b>Account Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routingNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Address: </b>
                    </label>
                    <label class="notranslate">
                      <p>{{ infos['address']['street-1'] }}, {{ infos['address']['street-2'] }}</p>
                      <p>{{ infos['address']['city'] }}, {{ infos['address']['region'] }}, {{ infos['address']['postal-code'] }}</p>
                      <p>{{ infos['address']['country'] }}</p>
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankAddress'] }}
                    </label>
                  </div>
                </template>
                <template v-if="form.deposit_type == 'international_wires'">
                  <div class="form-row form-split">
                    <label>
                      <b>Account Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routingNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Type: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountType'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Address: </b>
                    </label>
                    <label class="notranslate">
                      <p>{{ infos['address']['street-1'] }}, {{ infos['address']['street-2'] }}</p>
                      <p>{{ infos['address']['city'] }}, {{ infos['address']['region'] }}, {{ infos['address']['postal-code'] }}</p>
                      <p>{{ infos['address']['country'] }}</p>
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Address: </b>
                    </label>
                    <label class="notranslate">
                      6070 Poplar Ave, Suite 200, Memphis, TN 38119
                    </label>
                  </div>
                  <div class="form-row">
                    <p>Correspondent/Intermediary Bank SWIFT: FRNAUS44</p>
                  </div>
                  <div class="form-row">
                    <p>Correspondent/Intermediary Bank Name: <span class="notranslate">First National Banker's Bank</span></p>
                  </div>
                  <div class="form-row">
                    <p>Correspondent/Intermediary Bank Address: <span class="notranslate">7813 Office Park Blvd Baton Rouge, LA 70809</span></p>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Special instructions: </b>
                    </label>
                    <label class="notranslate">
                      FBO Solid at Evolve, FFC {{ infos['accountName'] }},{{ infos['accountNumber'] }}
                    </label>
                  </div>
                </template>
                <template v-if="form.deposit_type == 'ach'">
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routingNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Type: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountType'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountName'] }}
                    </label>
                  </div>
                </template>
              </template>
              <template v-else-if="infos['provider'] == 'Think Green'">
                <template v-if="form.deposit_type == 'domestic_wire' || form.deposit_type == 'international_wires'">
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankName'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bankAddress'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Phone: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bank-phone'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routingNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank SWIFT Code: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['swiftCode'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Credit To: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['creditTo'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['address'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['accountNumber'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Reference Code: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['reference'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <br>
                    <p class="notranslate">
                      <b>Note: </b>Your account number <b>MUST</b> be included in the reference field on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.
                    </p>
                  </div>
                </template>
                
                <template v-if="form.deposit_type == 'ach'">
                  ACH deposit details are unavailable at this time.
                </template>
              </template>
              <template v-else>
                <template v-if="form.deposit_type == 'domestic_wire'">
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['depository-bank-name'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routing-number'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Credit To: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['credit-to'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['beneficiary-address'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['account-number'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Reference Code: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['reference'] }}, {{ selectedAccount.name }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bank-address'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Phone: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bank-phone'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <br>
                    <p class="notranslate">
                      <b>Note: </b>Your Q-Code and account name <b>MUST</b> be included on all deposit transactions. ACH deposits are not accepted. Your deposit instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative or contact support. Please follow any specific funding instructions to ensure the funds are received quickly.
                    </p>
                  </div>
                </template>
                <template v-if="form.deposit_type == 'international_wires'">
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Name: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['depository-bank-name'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Routing Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['routing-number'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank SWIFT Code: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['swift-code'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Credit To: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['credit-to'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['beneficiary-address'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Account Number: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['account-number'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Reference Code: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['reference'] }}, {{ selectedAccount.name }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Address: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bank-address'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <label>
                      <b>Bank Phone: </b>
                    </label>
                    <label class="notranslate">
                      {{ infos['bank-phone'] }}
                    </label>
                  </div>
                  <div class="form-row form-split">
                    <br>
                    <p class="notranslate">
                      <b>Note: </b>Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.
                    </p>
                  </div>
                </template>
              </template>
            </template>
          </div>
          <div class="buttons">
            <a v-if="infos && form.deposit_type == 'domestic_wire'" class="btn main" :href="`/files/paymentDetails/${form.account}/${form.account}-payment_details_domestic.pdf`" target="_blank">View</a>
            <a v-if="infos && form.deposit_type == 'international_wires'" class="btn main" :href="`/files/paymentDetails/${form.account}/${form.account}-payment_details_international.pdf`" target="_blank">View</a>
            <a v-if="infos && form.deposit_type == 'ach'" class="btn main" :href="`/files/paymentDetails/${form.account}/${form.account}-payment_details_ach.pdf`" target="_blank">View</a>
            <button class="second" @click="open = false">Close</button>
          </div>
        </div>
        <div class="scrollable" v-else>
          <div class="content-form content-form-modern">
            <div class="form-row form-split">
              <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
              <form-field-select v-model="form.assetsCurrency" label="Assets Type" :validator="$v.form.assetsCurrency" :options="assetsCurrencyOptions" required />
            </div>
          </div>
          <div class="form-row payment-reference">
            <label></label>
            <button v-if="!infos" class="main" @click="assetPaymentDetails()">Submit</button>
          </div>
          <div class="content-form content-form-modern">
            <h3 class="subtitle">Asset Deposit Details</h3>
            <template v-if="infos">
              <div class="form-row form-split depositType">
                <label>
                  <b>{{ infos.currency }} Wallet Address </b>
                </label>
              </div>
              <br/>
              <div class="form-row form-split depositType">
                <label class="wallet-address notranslate">
                  {{ infos.address }}
                </label>
              </div>
              <br/>
              <div class="form-row form-split depositType">
                <label>
                  <b> Destination Tag </b>
                </label>
              </div>
              <br/>
              <div class="form-row form-split depositType">
                <label class="wallet-address notranslate">
                  {{ infos.tag }}
                </label>
              </div>
              <br/>
              <div class="form-row form-split">
                <label class="notranslate">
                  <b>Note:</b> Send only {{ infos.currency }} to this wallet address. Any other asset type sent may be lost and unrecoverable.
                </label>
              </div>
            </template>
          </div>
          <div class="buttons">
            <a v-if="infos" class="btn main" :href="`/files/paymentDetails/${form.account}/${form.account}-asset_payment_details.pdf`" target="_blank">View</a>
            <button class="second" @click="open = false">Close</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import AchForm from './forms/ACH'
import AddressForm from './forms/Address'
import InternationalWireForm from './forms/InternationalWire'

export default {
  props: {
    modalPaymentType: {
      type: String,
      required: true
    }
  },

  components: {
    AchForm,
    AddressForm,
    InternationalWireForm
  },

  data () {
    return {
      form: {
        account: null,
        deposit_type: null,
        depositType: null,
        assetsCurrency: null,
      },

      open: true,
      addModal: true,
      infos: null,
      regId: null,
      nextFlag: false,
      featureStatus: true,
      featureFundsStatus: null,
      error: null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    selectedCorpAccount () {
      let registrations = this.$store.state.registrations.data || []
      if(registrations.length > 0) {
        let accounts = registrations[0].accounts
        return accounts.filter(acc => acc.id==this.form.account)
      }
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.form.account))
      return account
    },

    depositOptions () {
      const options = []

      if(this.selectedAccount?.serviceProviders?.ach) {
        options.push({
          id: 'ach',
          text: 'ACH',
        })
      }
      if(this.selectedAccount?.serviceProviders?.domWires) {
        options.push({
          id: 'domestic_wire',
          text: 'Domestic Wire',
          disabled: this.form.currency == 'USD'? false : true
        })
      }
      if(this.selectedAccount?.serviceProviders?.intlWires) {
        options.push({
          id: 'international_wires',
          text: 'International Wire',
        })
      }

     return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    isDeposit () {
      return this.modalType === 'deposit'
    },

    accountOptions () {
      let registrations = this.$store.state.registrations.data || []
      if(registrations.length > 0){
        this.regId = registrations[0].id
        let accounts = registrations[0].accounts
        return accounts.filter(acc => ['Active', 'Open'].includes(acc.accountStatus)).map(acc => ({
          id: acc.id,
          text: `${acc.accountNumber}-${acc.accountName}`,
          profileSettings: acc.profileSettings,
          serviceProviders: acc.serviceProviders,
        }))
      }
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    depositTypeOptions () {
      let options = [
        {
          id: 'Funds',
          text: 'Funds',
        },
      ]
      if(this.featureStatus) {
        options.push(
          {
            id: 'Assets',
            text: 'Assets',
            disabled: !this.featureStatus
          }
        )
      }
      return options
    },

    assetsCurrencyOptions () {
      let currencies = window.currencies || []
      let options = []
      let disabled = true
      currencies.forEach(currency => {
        if(this.selectedCorpAccount) {
          if(this.selectedCorpAccount[0]){
            if(this.selectedCorpAccount[0].assetsWallets){
              let wallets
              wallets = this.selectedCorpAccount[0].assetsWallets.filter(wallet => {
                return wallet.currencyId === currency.id
              })
              if(wallets[0] && wallets[0].incomingAssetWalletAddress) {
                disabled = false
              }else{
                disabled = true
              }
            }
          }
        }
        if(currency.systemCurrencyType == 'Assets') {
          options.push({
            id: currency.id,
            text: currency.currencyNickname,
            disabled: disabled
          })
        }
      })
      return options
    },

    currencyOptions () {
      let currencies = window.currencies || []
      let options = []
      options.push({
        id: 'USD',
        text: 'USD',
      })
      if(this.featureFundsStatus) {
        currencies.forEach(currency => {
          if (currency.systemCurrencyType == 'Funds') {
            if (this.selectedCorpAccount[0]) {
              if (this.selectedCorpAccount[0].fundsCurrencies) {
                let fundCurrency = this.selectedCorpAccount[0].fundsCurrencies.find(item => item.currencyId == currency.id)
                if (fundCurrency) {
                  if (fundCurrency.currencyEnabled) {
                    options.push({
                      id: currency.currencyNickname,
                      text: currency.currencyNickname,
                    })
                  }
                }
              }
            }
          }
        })
      }
      return options
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.form.deposit_type = null
    },

    'form.account_number' () {
    },

    'form.deposit_type' () {
      this.infos = null
    },

    'form.assetsCurrency' () {
      this.infos = null
    },

    'form.currency' () {
      this.infos = null
    },
  },

  methods: {
    moveNextPopup () {
      if(this.form.depositType == 'Funds' || this.form.depositType == 'Assets') {
        this.nextFlag = true
      }
    },

    async paymentDetails() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        transactionType: this.form.deposit_type,
        currency: this.form.currency,
      }
      const response = (await api.action("Transactions", "new", "PaymentCorpDetails", payload))
      if(response.action.error) {
        this.error = response.action.errors[0].detail
      }else if(response.action.data['provider'] == 'Solid' || response.action.data['provider'] == 'Think Green') {
        this.infos = response.action.data
      }else {
        if (this.form.currency == 'USD') {
          if (this.form.deposit_type == 'domestic_wire') {
            this.infos = response.action.data['wire-instructions']
          }
          if (this.form.deposit_type == 'international_wires') {
            this.infos = response.action.data['wire-instructions-intl']
          }
        } else {
          this.infos = response.action.data['alt-currency-wire-instructions'][this.form.currency]
        }
      }
      loader.hide()
    },

    async assetPaymentDetails() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        currencyId: this.form.assetsCurrency,
      }
      const response = (await api.action("Account", this.form.account, "GetWalletPaymentDetails", payload))
      this.infos = response.action.data
      loader.hide()
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
    },
  },

  async created () {
    await this.getFeatureStatus();
  },

  validations () {
    const rules = {
      form: {
        account: { required },
      }
    }
    if (this.form.depositType == 'Funds') {
      rules.form.deposit_type = { required }
    }
    if (this.form.depositType == 'Assets') {
      rules.form.assetsCurrency = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 110px;
  }
  .template-check {
    display: flex;
    align-items: center;

    input {
      display: inline-block !important;
      width: 20px !important;
      height: 20px !important;
    }

    label {
      display: inline !important;
      position: unset !important;

      &:before {
        position: unset !important;
      }
    }
  }

  input::selection {
    background: #0000ff;
    color: #fff;
  }

  .information p {
    margin: 5px;
  }

  .payment-reference {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bank-info {
    label {
      width: 50%;
    }
  }
  .btn.main {
    background: #67C23A;
    color: white;
    padding: 0 16px;
    font-size: 12px;
    font-weight: bold;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .depositType {
    justify-content: center;
  }
  .wallet-address {
    font-size: 18px;
  }

  .content-tlist {
    background: none; 
  }
  
  .content-table {
    min-width: unset;
  }
</style>
