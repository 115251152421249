import factory from '../../../store/factory'

export default {
  registrations: factory('Registration'),
  profilenotes: factory('ProfileNotes'),
  reg_documents: factory('RegDocument'),
  reg_terms: factory('RegTerms'),
  reg_profile_settings: factory('RegProfileSettings'),
  accounts: factory('Account'),
  reg_service_providers: factory('RegServiceProviders'),
  email_logs: factory('EmailLog'),
  account_confirmation: factory('AccountConfirmation'),
  compliance_scan: factory('ComplianceScan'),
  compliance_reviews: factory('ComplianceReview'),
  account_status_history: factory('AccountStatusHistory'),
  cards: factory('Cards')
}