<template>
  <div class="cp-box" v-if="registration">
    <ul class="sub-tabs">
      <li v-if="registration.type == 'Business'" :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'"><a>Business</a></li>
      <li :class="{ active: activeTab === 'contacts' }" @click="activeTab = 'contacts'"><a>Contacts</a></li>
    </ul>
    <hr v-if="$hasPerm('edit-profile')">
    <registration-data-info v-if="activeTab === 'profile'" :registration="registration" main-profile-only-name hide-basics hide-contacts show-edit />
    <contacts v-if="activeTab === 'contacts'" :registration="registration" :showEditAdd="true"/>
  </div>
</template>

<script>
import Contacts from './ProfileInfoContacts'
import RegistrationDataInfo from './RegistrationDataInfo'

export default {
  components: {
    Contacts,
    RegistrationDataInfo
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'profile'
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  }
}
</script>
