<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>Update Card Pin</h3>
    </div>
    <div class="scrollable">
      <vgv-form ref="vgvForm" :vaultId="pinData.vaultId" :environment="pinData.environment" :cardToken="pinData.pinToken" :cardId="pinData.id">
        <div class="form-row form-fullw">
          <vgv-field label="Last 4 digits" fieldName="last4" :css="fieldStyle" />
        </div>
        <div class="form-row form-split">
          <vgv-field label="Expiry month" fieldName="expiryMonth" :css="fieldStyle" />
          <vgv-field label="Expiry year" fieldName="expiryYear" :css="fieldStyle" />
        </div>
        <div class="form-row form-fullw">
          <vgv-field label="New pin" fieldName="pin" :css="fieldStyle" />
        </div>
      </vgv-form>
      <div class="error-box">
        <label v-if="submitError" class="error-message">{{ submitError }}</label>
      </div>
      <div class="buttons">
        <button class="main" @click="setPin()">Submit</button>
        <button class="second" @click="open = false">Cancel</button>
      </div>
    </div>
  </popup>
</template>

<script>
import VgvForm from '../../components/vgv/VgvForm'
import VgvField from '../../components/vgv/VgvField'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    },
    
    pinData: {
      type: Object,
      required: true
    },
  },
  
  components: {
    VgvForm,
    VgvField
  },

  data () {
    return {
      open: true,
      submitError: null,
      fieldStyle: {
        'font-size': '15px',
        'color': '#41566c',
      },
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },


  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    async setPin () {
      const loader = this.$loading.show()
      var result=await this.$refs.vgvForm.submit();
      loader.hide();
      if (result.message) {
        this.submitError=result.sysMessage || result.message;
        if (!result.clientError)
          this.$emit('refresh');
      } else {
        this.$emit('close');
        this.$toast.success('Card pin successfully updated.')
      }
    },
  },

  async created () {
    this.system = JSON.parse(JSON.stringify(window.appData.system))
  },

}
</script>

<style lang="scss" scoped>
.error-box {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -40px;
  margin-bottom: 20px;
}
</style>
