import $ from 'jquery';
import moment from 'moment';
import Vue from 'vue';
import Vuelidate from 'vuelidate'
import store from '../store'
import Toast, { POSITION } from 'vue-toastification'
import Loading from 'vue-loading-overlay'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'

import 'vue-loading-overlay/dist/vue-loading.css'
import 'vue-toastification/dist/index.css'

import i18n from '../lang'

window.validateFileExt=function(file) {
  var whitelistedExtensions=[".jpg", ".jpeg", ".gif", ".tif", ".tiff", ".pdf", ".png"];
  if (!file || !file.name)
    return false;
  var matches=file.name.match(/\.[^.]+$/);
  if (!matches)
    return false;
  var ext=matches[0].toLowerCase();
  return whitelistedExtensions.indexOf(ext)!=-1;
}

window.copyTextToClipboard=function(text) {
  var textArea=document.createElement("textarea");
  textArea.style.position="fixed";
  textArea.style.left="-1000px";
  
  textArea.value=text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  var successful=false;
  try {
    successful=document.execCommand('copy');
  } catch (err) {
  }
  document.body.removeChild(textArea);
  return successful;
}

Vue.use(ElementUI, { locale })
Vue.use(Loading)

Vue.use({
  install (Vue, options) {
    Vue.prototype.$hasPerm = (perm) => window.appData.currentUser?.perms?.includes(perm) || window.appData.currentUser?.role?.group?.id != 1
  }
})

Vue.use({
  install (Vue, options) {
    Vue.prototype.$user = window.appData.currentUser
  }
})

Vue.use({
  install (Vue, options) {
    Vue.prototype.$system = window.appData.system
  }
})

Vue.use(Toast, {
  timeout: 5000,
  closeOnClick: false,
  position: POSITION.TOP_RIGHT
})

Vue.use(Vuelidate)

window.serverDiff=Math.round((moment().unix()-moment(serverNow).unix())/60)*60;
window.vueMixins = window.vueMixins || {}

window.vueMixins.helpers = {
  computed: {
    currencies () {
      return [
        { value: "124", label: "CAD" },
        { value: "840", label: "USD" },
        // {value: "036", label: "AUD"},
        // {value: "986", label: "BRL"},
        // {value: "756", label: "CHF"},
        // {value: "978", label: "EUR"},
        // {value: "826", label: "GBP"},
        // {value: "344", label: "HKD"},
        // {value: "392", label: "JPY"},
        // {value: "554", label: "NZD"},
      ]
    }
  },
  methods: {
    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type);
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },
    formatDate(source, format) {
      if (!source)
        return "";
      return moment(source).format(format);
    },
    agoTimer(source, now) {
      if (typeof(now)=="number")
        now=undefined;
      var t=moment(source);
      var n=moment(now).subtract(serverDiff, "s");
      if (n.unix()-t.unix()<0)
        return "00:00:00";
      var diff=n.unix()-t.unix();
      var h=Math.floor(diff/3600);
      var m=Math.floor(diff/60)%60;
      var s=Math.floor(diff)%60;
      return (h<10?"0":"")+h+":"+(m<10?"0":"")+m+":"+(s<10?"0":"")+s;
    },
  }
}


$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");
    (new Vue({
      el: this,
      mixins: mixins,
      i18n,
      store
    }));
  });
});
