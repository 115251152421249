<template>
  <div id="form-business">
    <template v-if="step===undefined || step===1">
    <h4>{{ $t('register.business_acc_info_header') }} <span>*</span></h4>
    <div class="form-row form-split">
      <form-field-text v-model="form.legal_name" :validator="v.legal_name" :label="$t('register.buss_legal_name')" :maxlength="50" required />
      <form-field-text v-model="form.dba_name" :validator="v.dba_name" :label="$t('register.buss_dba_name')" :maxlength="50" required />
    </div>
    <div class="form-row form-split">
      <form-field-date v-model="form.incorporation_date" :dirty="v.$dirty" :label="$t('register.buss_inc_date')" required />
      <form-field-text v-model="form.tax_id" :validator="v.tax_id" :label="$t('register.buss_tax_name')" :maxlength="20" required />
    </div>
    <div class="form-row form-fullw">
      <form-field-select v-model="form.business_type" :label="$t('register.business_types')" :options="businessTypeOptions" :validator="v.business_type" required />
    </div>
    <div class="form-row form-fullw">
      <div style="position: relative;">
        <form-field-select v-model="form.formation_country" :validator="v.formation_country" :label="$t('register.country_formation')" :options="countryOptions" required />
        <a class="what-this" @click.prevent="countryModal = true">{{ $t('register.prohibited_c') }}</a>
      </div>
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_address')" v-model="form.address" :validator="v.address" :maxlength="50" required />
      <form-field-text :validator="v.address2" :label="$t('register.business_address_2')" v-model="form.address2" :maxlength="50" />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.city" :validator="v.city" :label="$t('register.business_city')" :maxlength="25" required />
      
      <form-field-select v-if="stateOptions" v-model="form.state" :validator="v.state" :label="$t('register.business_state')" :options="stateOptions" required />
      <form-field-text v-else v-model="form.state" :validator="v.state" :label="$t('register.business_state')" :maxlength="50" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_zip')" v-model="form.zip" :validator="v.zip" :maxlength="15" required />
      <form-field-text :label="$t('register.business_phone')" v-model="form.phone" :validator="v.phone" :maxlength="20" required />
    </div>
    <div class="form-row form-split">
      <form-field-text :label="$t('register.business_email')" v-model="form.email" :validator="v.email" required />
      <form-field-text :label="$t('register.business_website')" v-model="form.website" :validator="v.website" />
    </div>
    </template>

    <template v-if="step===undefined || step===2">
    <div v-for="(contact, index) in form.contacts" :key="index">
      <h4>{{ $t('register.account_contacts') }} <template v-if="index"> {{ index + 1 }}</template> <span>*</span></h4>
      <bisiness-contact v-model="form.contacts[index]" :v="v.contacts.$each[index]" />
    </div>
    <div class="form-row form-split">
      <div>
        <a v-if="form.contacts.length < 6" @click="$emit('add-contact')">+ {{ $t('register.add_another_contact') }}</a>
      </div>
      <a v-if="form.contacts.length > 1" @click="$emit('remove-contact')">- {{ $t('register.remove_contact') }}</a>
    </div>
    </template>

    <template v-if="step===undefined || step===3">
    <h4>{{ $t('register.business_desc') }}</h4>
    <div
      class="form-row form-split">
      <form-field-select
        v-model="form.questions.business_industry"
        label="Business Industry"
        :validator="v.questions.business_industry"
        :options="businessIndustryOptions"
        :required="true" />
    </div>
    <div class="form-row form-fullw">
      <form-field-textarea
        v-model="form.description"
        :label="$t('register.business_nature')"
        :rows="3"
        :maxlength="250"
        :validator="v.description"
        :required="true" />
    </div>
    </template>
    <template v-if="step===undefined || step===4">
    <h4>
      {{ $t('register.business_doc_submission') }}
      <span>*</span>
    <!--   <label class="check">
        <input type="checkbox" v-model="form.submit_later" />
        <span></span>
       <a>Submit later</a> 
      </label>-->
    </h4>

    <p v-if="form.submit_later">{{ $t('register.final_acc_activation') }}</p>          
    <div class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.doc_incorporation" :validator="v.doc_incorporation" :label="$t('register.business_inc_doc')" :disabled="form.submit_later" required />
      <a class="what-this" @click.prevent="incModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div class="form-row form-fullw" style="position: relative;">
      <form-field-upload v-model="form.doc_address_confirmation" :validator="v.doc_address_confirmation" :label="$t('register.business_address_confirmation')" :disabled="form.submit_later" required />
      <a class="what-this" @click.prevent="addressModal = true">{{ $t('register.whats_this') }}</a>
    </div>
    <div v-for="(contact, index) in form.contacts" :key="'docs_' + index">
      <div
        v-for="(type, idx) in contact.id_types"
        :key="'subdocs_' + idx"
        class="form-row form-fullw" style="position: relative;">
        <form-field-upload v-model="form.contacts[index].id_types[idx].document" :validator="v.contacts.$each[index].id_types.$each[idx].document" :label="`${$t('register.contact_increment_ID')} ${index + 1} ${type.id_type || $t('register.contact_increment_ID_text')} (10MB max)`" :disabled="form.submit_later" required />
        <a class="what-this" @click.prevent="issuedModal = true">{{ $t('register.whats_this') }}</a>
      </div>
      <div
        :key="'subdocs_address'"
        class="form-row form-fullw" style="position: relative;">
        <form-field-upload v-model="form.contacts[index].doc_address_confirmation" :validator="v.contacts.$each[index].doc_address_confirmation" :label="`${$t('register.contact_increment_ID')} ${index + 1} ${$t('register.contact_increment_address_text')} (10MB max)`" :disabled="form.submit_later" required />
        <a class="what-this" @click.prevent="issuedModal = true">{{ $t('register.whats_this') }}</a>
      </div>
    </div>
    </template>
    <popup :open.sync="incModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_inc_doc_header') }}</strong></h2>
          <p>
            {{ $t('register.bus_inc_doc_text') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="addressModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_address_conf_header') }}</strong></h2>
          <p>
            {{ $t('register.bus_address_conf_text') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="issuedModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.bus_gov_issues_ID_popup_header') }}</strong></h2>
          <p>
           {{ $t('register.bus_gov_issues_ID_popup_line1') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line2') }}
          </p>
          <br/>
          <p>
            {{ $t('register.bus_gov_issues_ID_popup_line3') }}
          </p>
        </div>
      </div>
    </popup>
    <popup :open.sync="countryModal">
      <div class="scrollable" v-on:scroll.passive="handleScroll">
        <div class="">
          <h2><strong>{{ $t('register.prohibited_c') }}</strong></h2>
          <br/>
          <ul style="column-count: 3;">
            <li v-for="item in prohibitedCountries">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import BisinessContact from './RegisterForm2BusinessContact'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },
    
    step: {
      type: Number,
      required: false,
    },
  },

  components: {
    BisinessContact
  },

  data () {
    return {
      form: this.value,
      incModal: false,
      addressModal: false,
      issuedModal: false,
      countryModal: false,
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    },

    groupedQuestions () {
      const list = []

      let group = []
      this.questions.forEach(question => {
        group.push(question)
        if (group.length === 2) {
          list.push(group)
          group = []
        }
      })

      if (group.length) {
        list.push(group)
      }

      return list
    },

    questions () {
      return window.questionsLang[this.$i18n.locale];
    },

    countryOptions () {
      if(this.system.formationCountry) {
        return window.fullCountries.filter(a => {
          return this.system.formationCountry.includes(a.id)
        })
      }else{
        return window.countriesOptions
      }
    },

    prohibitedCountries () {
      if(this.system.formationCountry) { 
        return window.fullCountries.filter(a => {
            return !this.system.formationCountry.includes(a.id)
          }).map(a =>(a.text))
      }else{
        return window.prohibitedCountries
      }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.formation_country)) return null

      return window.states[this.form.formation_country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },

    businessTypeOptions () {
      return [
        {
          id: "Sole Proprietor",
          text: this.$t("register.bus_type_sole")
        },
        {
          id: "Incorporation",
          text: "Incorporation"
        },
        {
          id: "Single Member LLC",
          text: this.$t("register.bus_type_smllc")
        },
        {
          id: "LLC",
          text: this.$t("register.bus_type_llc")
        },
        {
          id: "Limited",
          text: "Limited"
        },
        {
          id: "General Partnership",
          text: this.$t("register.bus_type_gen_par")
        },
        {
          id: "Corporation",
          text: this.$t("register.bus_type_corp")
        },
        {
          id: "Publicly Traded Corporation",
          text: this.$t("register.bus_type_ptc")
        },
        {
          id: "Association",
          text: this.$t("register.bus_type_association")
        },
        {
          id: "Non Profit",
          text: this.$t("register.bus_type_n_profit")
        },
        {
          id: "Government Organization",
          text: this.$t("register.bus_type_gov_org")
        },
        {
          id: "Other",
          text: "Other",
        },
      ]
    },

    businessIndustryOptions () {
      return [
        {
          id: "Agriculture/Farming",
          text: "Agriculture/Farming"
        },
        {
          id: "Airline",
          text: "Airline"
        },
        {
          id: "Auto Dealer/Mechanic",
          text: "Auto Dealer/Mechanic"
        },
        {
          id: "Auto Wash/Detailing",
          text: "Auto Wash/Detailing"
        },
        {
          id: "Business to Business",
          text: "Business to Business"
        },
        {
          id: "Community Group/Org",
          text: "Community Group/Org"
        },
        {
          id: "Convenience/Supermarket",
          text: "Convenience/Supermarket"
        },
        {
          id: "E-Commerce",
          text: "E-Commerce"
        },
        {
          id: "Education",
          text: "Education"
        },
        {
          id: "Electronics",
          text: "Electronics"
        },
        {
          id: "Entertainment",
          text: "Entertainment"
        },
        {
          id: "Events/Festivals",
          text: "Events/Festivals"
        },
        {
          id: "Financial Services",
          text: "Financial Services"
        },
        {
          id: "Freelance Professional",
          text: "Freelance Professional"
        },
        {
          id: "Games/Gaming",
          text: "Games/Gaming"
        },
        {
          id: "Gas/Oil",
          text: "Gas/Oil"
        },
        {
          id: "Government",
          text: "Government"
        },
        {
          id: "Gym/Fitness",
          text: "Gym/Fitness"
        },
        {
          id: "Health/Nutrition",
          text: "Health/Nutrition"
        },
        {
          id: "Hospitality",
          text: "Hospitality"
        },
        {
          id: "Insurance",
          text: "Insurance"
        },
        {
          id: "Landscaping",
          text: "Landscaping"
        },
        {
          id: "Medical/Clinic",
          text: "Medical/Clinic"
        },
        {
          id: "Professional Services",
          text: "Professional Services"
        },
        {
          id: "Retail",
          text: "Retail"
        },
        {
          id: "Spa/Salon",
          text: "Spa/Salon"
        },
        {
          id: "Sports/Sports Center",
          text: "Sports/Sports Center"
        },
        {
          id: "Tanning/Massage",
          text: "Tanning/Massage"
        },
        {
          id: "Taxi/Delivery",
          text: "Taxi/Delivery"
        },
        {
          id: "Technology",
          text: "Technology"
        },
        {
          id: "Tourism/Travel",
          text: "Tourism/Travel"
        },
        {
          id: "Transportation",
          text: "Transportation"
        },
        {
          id: "Utilities",
          text: "Utilities"
        },
        {
          id: "Other",
          text: "Other"
        },
      ]
    }
  },

  watch: {
    'form.formation_country' (value) {
      this.form.state = null
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>
