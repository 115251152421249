export const mustBeTrue = (value) => !!value
export const alpha = (value) => (new RegExp(/^[a-z_ ]*$/i)).test(value)
export const alphaOnly = (value) => (new RegExp(/^[a-z_]*$/i)).test(value)
export const alphaNum = (value) => (new RegExp(/^[a-z0-9_ ]*$/i)).test(value)
export const alphaNumNoSpace = (value) => (new RegExp(/^[a-z0-9_]*$/i)).test(value)
export const addressValidation = (value) => (new RegExp(/^[a-z0-9_\-/.# ]*$/i)).test(value)
export const passwordValidation = (value) => (/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /\d+/.test(value) && /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value) && !/ /.test(value)) && value.length >= 6
export const alphaVersion = (value) => (new RegExp(/^[-\p{Letter}0-9._ ]*$/iu)).test(value)
export const alphaVersionNoSpace = (value) => (new RegExp(/^[-\p{Letter}0-9._]*$/iu)).test(value)
export const dashNumbers = (value) => value ? (new RegExp(/^[-0-9]*$/i)).test(value) : true
export const phone = (value) => value ? (new RegExp(/^[\d][\d]*$/i)).test(value) : true
export const swift = (value) => [8, 11].includes(value?.length) && (new RegExp(/[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/i)).test(value)
export const monbiAccountNumber = (value) => ['31', '32', '11', '12', '92'].includes((value + '').substring(0, 2))
export const routingNumber = (value) => value ? ['0', '1', '2', '3'].includes(value.charAt(0)) : false
export const assetsAmount = (value) => (new RegExp(/^\d+(?:\.\d{1,10})?$/)).test(value)
export const webSiteUrl = (value) => value ? (new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i')).test(value) :  true
  
