<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <div class="content-filters">
        <basic-form>
          <h4>Terms and Conditions</h4>
          <mnb-input v-model="legalDisclaimers.termsAndConditionsHeader" label="Terms and Conditions Header" :disabled="!updateInformations" :maxlength="100"/>
          <br/>
          <label>Terms and Conditions Updated:</label>
          <date-time-picker
            v-model="legalDisclaimers.termsAndConditionsDate"
            value-type="String"
            value-format="yyyy-LL-dd"
            format="yyyy-LL-dd"
            :time-picker="false"
            :auto-close="true"
            min-date="2020-01-01"
            :max-date="today"
            :disabled="!updateInformations" />
          <br/>
          <label>Terms and Conditions Text:</label>
          <block-edit v-if="legalDisclaimers.privacyPolicyText!==null" v-model="legalDisclaimers.termsAndConditionsText" placeholder="" :editable="updateInformations" />
          <br/>
          <br/>
          <h4>Privacy Policy</h4>
          <mnb-input v-model="legalDisclaimers.privacyPolicyHeader" label="Privacy Policy Header" :disabled="!updateInformations" :maxlength="100"/>
          <br/>
          <label>Privacy Policy Updated:</label>
          <date-time-picker
            v-model="legalDisclaimers.privacyPolicyDate"
            value-type="String"
            value-format="yyyy-LL-dd"
            format="yyyy-LL-dd"
            :time-picker="false"
            :auto-close="true"
            min-date="2020-01-01"
            :max-date="today"
            :disabled="!updateInformations" />
          <br/>
          <label>Privacy Policy Text:</label>
          <block-edit v-if="legalDisclaimers.privacyPolicyText!==null" v-model="legalDisclaimers.privacyPolicyText" placeholder="" :editable="updateInformations" />
          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button v-if="$hasPerm('update-system-information')" class="main" @click="updateInformations = true">Update</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button v-if="$hasPerm('reset-system-information')" class="second">Reset Fields</button>
            </div>
          </div>
        </basic-form>
      </div>
  </div>
</template>

<script>
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbUploadInput from '../../components/ui/forms/UploadInput'
  import MnbTextarea from '../../components/ui/forms/Textarea'

  import mixinAutoResize from "../../components/ui/forms/autoresize.js"
  import inputMixin from "../../components/ui/forms/input-mixin.js"
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    mixins: [mixinAutoResize],

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      MnbUploadInput,
//       MnbTextarea,
      DateTimePicker,
    },

    data () {
      return {
        legalDisclaimers: {},
        updateInformations: false,
        today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      }
    },

    computed: {

    },

    watch: {

    },

    async created () {
      const legalDisclaimerses = await this.$store.dispatch('system/load')
      this.legalDisclaimers =  legalDisclaimerses[0]
    },

    methods: {
      async save () {
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.legalDisclaimers })
          this.$toast.success('Information saved successfully.')
          this.updateInformations = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    }
  }
</script>

<style scoped>

</style>
