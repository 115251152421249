<template>
  <div>
    <div class="content-form">
      <h3 class="subtitle">BANK & BENEFICIARY INFORMATION</h3>
      <div class="form-row form-split routing-number-validate">
        <!--<form-field-text v-model="form.bank_name" label="Bank Name" :validator="v.bank_name" required />-->
        <form-field-text :class="showSwiftBtn && (form.bank_name == null || form.bank_name == '') ? 'error' : ''" v-model="form.swift" label="Bank SWIFT Code" :validator="v.swift" required />
        <span class="error-message error-code" v-if="showSwiftBtn && (form.bank_name == null || form.bank_name == '')">Not validated</span>
        <button :disabled="!showSwiftBtn" class="btn btn-success show-btn" @click="validateSwift()"><i class="el-icon-search" /> Validate</button>
      </div>
      <p class="bank-name"><template v-if="bankSwiftInfo">{{ bankSwiftInfo['bankName'] }},</template> <template v-if="bankSwiftAddress">{{ bankSwiftAddress['line1'] }},  {{ bankSwiftAddress['line2'] }}</template>, <template v-if="bankSwiftAddress">{{ bankSwiftAddress['city'] }}, {{ bankSwiftAddress['state'] }}, {{ bankSwiftAddress['postalCode'] }}, {{ bankSwiftAddress['country'] }}</template>&nbsp;</p>
      <div class="form-row form-split">
        <form-field-select v-model="form.account_type" label="Account Type" :validator="v.account_type" :options="accountOptions" required />
        <form-field-text v-model="form.account_name" label="Account Name" :validator="v.account_name" required />
      </div>
      <div class="form-row form-fullw">
        <div>
          <form-field-text v-model="form.account_number" label="Account Number / IBAN" :validator="v.account_number" required />
          <span class="account-number-alert">Numbers and letters are accepted for Account Number / IBAN</span>
        </div>
      </div>
      <br/>
      <br/>
      <div class="use_intermediary_bank_div">
        <h3 class="subtitle">INTERMEDIARY BANK INFORMATION</h3>
        <div class="center-checkbox">
          <input v-model="form.use_intermediary_bank" type="checkbox" id="use_intermediary_bank" disabled>
          <label for="use_intermediary_bank">Use Intermediary Bank <span class="error-message">*Required</span></label>
        </div>
      </div>
      <template v-if="form.use_intermediary_bank">
        <div class="form-row form-split">
          <form-field-select v-model="form.intermediaryBankIdentifier" label="Intermediary Bank Identifier" :validator="v.intermediaryBankIdentifier" :options="intermediaryBankIdentifierOptions" required />
        </div>
        <template v-if="form.intermediaryBankIdentifier == 'routing'">
          <div class="form-row form-split routing-number-validate">
            <!-- <form-field-text v-model="form.intermediary_bank_name" label="Intermediary Bank Name" :validator="v.intermediary_bank_name" required />-->
            <form-field-text v-model="form.intermediary_bank_routing_number" label="Intermediary Bank Routing Number" :validator="v.intermediary_bank_routing_number" required />
            <button :disabled="!showBtn" class="btn btn-success show-btn" @click="validateRoutingNumber()"><i class="el-icon-search" /> Validate</button>
          </div>
          <p class="bank-name"><template v-if="bankInfo">{{ bankInfo.name }}, {{bankInfo.phone}}, {{ bankInfo.street }}, {{ bankInfo.city }}, {{ bankInfo.state}}, {{ bankInfo.zip }}</template></p>
          <p class="error-message" v-if="!bankInfo">No Address Provided</p>
        </template>
        <template v-else-if="form.intermediaryBankIdentifier == 'swift'">
          <div class="form-row form-split routing-number-validate">
            <!--<form-field-text v-model="form.bank_name" label="Bank Name" :validator="v.bank_name" required />-->
            <form-field-text v-model="form.intermediary_bank_swift_code" label="Intermediary Bank SWIFT Code" :validator="v.intermediary_bank_swift_code" required />
            <button :disabled="!showIntSwiftBtn" class="btn btn-success show-btn" @click="validateIntSwift()"><i class="el-icon-search" /> Validate</button>
          </div>
          <p class="bank-name"><template v-if="bankIntSwiftInfo">{{ bankIntSwiftInfo['bankName'] }},</template> <template v-if="bankIntSwiftAddress">{{ bankIntSwiftAddress['line1'] }},  {{ bankIntSwiftAddress['line2'] }}, {{ bankIntSwiftAddress['city'] }}, {{ bankIntSwiftAddress['state'] }}, {{ bankIntSwiftAddress['postalCode'] }}, {{ bankIntSwiftAddress['country'] }}</template></p>
        </template>
        <div class="form-row form-fullw">
          <form-field-text v-model="form.intermediary_bank_reference" label="Intermediary Bank Reference" :validator="v.intermediary_bank_reference" required />
        </div>
        <!--<h3 class="subtitle">INTERMEDIARY BANK ADDRESS</h3>
        <address-form v-model="form.intermediary_address" :v="v.intermediary_address" />-->
      </template>

      <h3 class="subtitle">BENEFICIARY ADDRESS</h3>
      <div class="form-row form-split">
        <!--<form-field-text v-model="form.iban" label="IBAN" :validator="v.iban" required />-->
      </div>
      <address-dom-form v-model="form" :v="v" :transactionCountries="transactionCountries" />
    </div>
    <popup :open.sync="showConfirm">
      <div class="popup-header">
        <h3>Intermediary Bank Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form bank-info">
          <p v-if="bankError">Error searching Banks. {{ bankErrorMsg }}</p>
          <p v-if="notFound">Intermediary Bank not found. Please check information and try again.</p>
          <template v-if="bankInfo">
            <div class="form-row form-split">
              <label>
                <b>Routing Number: </b>
              </label>
              <label>
                {{ bankInfo.routingNumber }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Name: </b>
              </label>
              <label>
                {{ bankInfo.name }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address: </b>
              </label>
              <label>
                {{ bankInfo.street }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address 2: </b>
              </label>
              <label v-if="bankAddress">
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>City: </b>
              </label>
              <label>
                {{ bankInfo.city }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>State/Province: </b>
              </label>
              <label>
                {{ bankInfo.state }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Postal/Zip: </b>
              </label>
              <label>
                {{ bankInfo.zip }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Country: </b>
              </label>
              <label>
              
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Phone: </b>
              </label>
              <label>
                {{ bankInfo.phone }}
              </label>
            </div>
          </template>
        </div>
        <div class="buttons">
          <template v-if="notFound || bankError">
            <button class="second" @click="showConfirm = false">Close</button>
          </template>
          <template v-else>
            <button class="main" @click="confirmIntermediary()">Confirm</button>
            <button class="second" @click="showConfirm = false">Cancel</button>
          </template>
        </div>
      </div>
    </popup>
    <popup :open.sync="showSwiftConfirm">
      <div class="popup-header">
        <h3>Bank Swift Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form bank-info">
          <p v-if="bankError">Error searching Banks. {{ bankErrorMsg }}</p>
          <p v-if="notFound">Intermediary Bank not found. Please check information and try again.</p>
          <template v-if="bankSwiftInfo">
            <div class="form-row form-split">
              <label>
                <b>Swift Code: </b>
              </label>
              <label>
                {{ bankSwiftInfo["routingNumber"] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Name: </b>
              </label>
              <label>
                {{ bankSwiftInfo["bankName"] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address: </b>
              </label>
              <label>
                {{ bankSwiftAddress['line1'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address 2: </b>
              </label>
              <label>
                {{ bankSwiftAddress['line2'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>City: </b>
              </label>
              <label>
                {{ bankSwiftAddress['city'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>State/Province: </b>
              </label>
              <label>
                {{ bankSwiftAddress['state'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Postal/Zip: </b>
              </label>
              <label>
                {{ bankSwiftAddress['postalCode'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Country: </b>
              </label>
              <label>
                {{ bankSwiftAddress['country'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Phone: </b>
              </label>
              <label>

              </label>
            </div>
          </template>
        </div>
        <div class="buttons">
          <template v-if="notFound || bankError">
            <button class="second" @click="showSwiftConfirm = false">Close</button>
          </template>
          <template v-else>
            <button class="main" @click="confirmSwiftIntermediary()">Confirm</button>
            <button class="second" @click="showSwiftConfirm = false">Cancel</button>
          </template>
        </div>
      </div>
    </popup>
    <popup :open.sync="showIntSwiftConfirm">
      <div class="popup-header">
        <h3>Bank Swift Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form bank-info">
          <p v-if="bankError">Error searching Banks. {{ bankErrorMsg }}</p>
          <p v-if="notFound">Intermediary Bank not found. Please check information and try again.</p>
          <template v-if="bankIntSwiftInfo">
            <div class="form-row form-split">
              <label>
                <b>Swift Code: </b>
              </label>
              <label>
                {{ bankIntSwiftInfo["routingNumber"] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Name: </b>
              </label>
              <label>
                {{ bankIntSwiftInfo["bankName"] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['line1'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Address 2: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['line2'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>City: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['city'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>State/Province: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['state'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Postal/Zip: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['postalCode'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Country: </b>
              </label>
              <label>
                {{ bankIntSwiftAddress['country'] }}
              </label>
            </div>
            <div class="form-row form-split">
              <label>
                <b>Phone: </b>
              </label>
              <label>

              </label>
            </div>
          </template>
        </div>
        <div class="buttons">
          <template v-if="notFound || bankError">
            <button class="second" @click="showIntSwiftConfirm = false">Close</button>
          </template>
          <template v-else>
            <button class="main" @click="confirmIntSwiftIntermediary()">Confirm</button>
            <button class="second" @click="showIntSwiftConfirm = false">Cancel</button>
          </template>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import AddressForm from './Address'
import AddressDomForm from './AddressDom'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: false
    },

    transactionCountries: {
      type: Array,
      required: false
    },
  },

  components: {
    AddressForm,
    AddressDomForm,
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal Checking'
        },
        {
          id: 'business',
          text: 'Business Checking'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ],

      intermediaryBankIdentifierOptions: [
        {
          id: 'routing',
          text: 'Intermediary Bank Routing Number'
        },
        {
          id: 'swift',
          text: 'Intermediary Bank SWIFT Code'
        }
      ],

      showBtn: false,
      showSwiftBtn: false,
      showIntSwiftBtn: false,

      bankError: false,
      notFound: false,
      bankInfo: null,
      bankErrorMsg: null,
      bankAddress: null,
      showConfirm: false,

      bankSwiftInfo: null,
      bankSwiftAddress: null,
      showSwiftConfirm: false,

      bankIntSwiftInfo: null,
      bankIntSwiftAddress: null,
      showIntSwiftConfirm: false
    }
  },

  watch: {
    'form.intermediary_bank_routing_number' () {
      this.v.intermediary_bank_routing_number.$touch()
      if(!this.v.intermediary_bank_routing_number.$invalid) {
        this.showBtn = true
      }
    },

    'form.swift' () {
      this.v.swift.$touch()
      if(!this.v.swift.$invalid) {
        this.showSwiftBtn = true
      }
    },

    'form.intermediary_bank_swift_code' () {
      this.v.intermediary_bank_swift_code.$touch()
      if(!this.v.intermediary_bank_swift_code.$invalid) {
        this.showIntSwiftBtn = true
      }
    },

    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    },
  },

  methods: {
    async validateRoutingNumber() {
      const loader = this.$loading.show()
      let payload;
      payload = {
        type: 'bank_routing_number',
        value: this.form.intermediary_bank_routing_number,
        deposit_type: this.form.deposit_type,
      }
      const response = (await api.action("Transactions", "new", "Bank", payload))
      if(response.action.error){
        this.bankError = true
        this.bankErrorMsg = response.action.errors
        this.notFound = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      } else if(response.action.notFound){
        this.notFound = true
        this.bankError = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      }else{
        this.bankInfo = response.action.data
        this.bankAddress = response.action.address
        this.bankError = false
        this.notFound = false
        this.showConfirm = true
        loader.hide()
      }
    },

    async validateSwift() {
      const loader = this.$loading.show()
      let payload;
      payload = {
        type: 'swift',
        value: this.form.swift,
      }
      const response = (await api.action("Transactions", "new", "BankSwift", payload))
      if(response.action.error){
        this.bankError = true
        this.bankErrorMsg = response.action.errors
        this.notFound = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      } else if(response.action.notFound){
        this.notFound = true
        this.bankError = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      }else{
        this.bankSwiftInfo = response.action.data
        this.bankSwiftAddress = response.action.address
        this.bankError = false
        this.notFound = false
        this.showSwiftConfirm = true
        loader.hide()
      }
    },

    async validateIntSwift() {
      const loader = this.$loading.show()
      let payload;
      payload = {
        type: 'swift',
        value: this.form.intermediary_bank_swift_code,
      }
      const response = (await api.action("Transactions", "new", "BankSwift", payload))
      if(response.action.error){
        this.bankError = true
        this.bankErrorMsg = response.action.errors
        this.notFound = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      } else if(response.action.notFound){
        this.notFound = true
        this.bankError = false
        this.bankInfo = null
        this.bankAddress = null
        this.showConfirm = true
        loader.hide()
      }else{
        this.bankIntSwiftInfo = response.action.data
        this.bankIntSwiftAddress = response.action.address
        this.bankError = false
        this.notFound = false
        this.showIntSwiftConfirm = true
        loader.hide()
      }
    },

    confirmIntermediary() {
      this.form.intermediary_address.country = this.bankInfo.country?  this.bankInfo.country : null
      this.form.intermediary_address.state = this.bankInfo.state
      this.form.intermediary_address.address = this.bankInfo.street
      this.form.intermediary_address.city = this.bankInfo.city
      this.form.intermediary_address.postal = this.bankInfo.zip
      this.form.intermediary_bank_name = this.bankInfo.name
      this.form.intermediary_address.disabled = false
      this.showConfirm = false
    },

    confirmSwiftIntermediary() {
      this.form.bank_name = this.bankSwiftInfo['bankName']
      this.form.bank_country = this.bankSwiftAddress['country']
      this.form.bank_address = this.bankSwiftAddress['line1']
      this.form.bank_city = this.bankSwiftAddress['city']
      this.form.bank_state = this.bankSwiftAddress['state']
      this.form.bank_postal = this.bankSwiftAddress['postalCode']
      this.showSwiftConfirm = false
    },

    confirmIntSwiftIntermediary() {
      this.form.intermediary_address.country = this.bankIntSwiftAddress['country']
      this.form.intermediary_address.state = this.bankIntSwiftAddress['state']
      this.form.intermediary_address.address = this.bankIntSwiftAddress['line1']
      this.form.intermediary_address.city = this.bankIntSwiftAddress['city']
      this.form.intermediary_address.postal = this.bankIntSwiftAddress['postalCode']
      this.form.intermediary_bank_name = this.bankIntSwiftInfo['bankName']
      this.form.intermediary_address.disabled = false
      this.showIntSwiftConfirm = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .routing-number-validate {
    position: relative;

    .show-btn {
      position: absolute;
      left: 50%;
      width: 100px;
      height: 30px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .bank-info {
    label {
      width: 50%;
    }
  }

  .account-number-alert {
    margin-top: -15px !important;
    padding: 0 !important;
    color: #1f2d4e;
  }

  .use_intermediary_bank_div {
    display: flex;
    justify-content: space-between;
  }

  .center-checkbox {
    width: 50%;
  }
  .bank-name {
    margin-top: 0;
    font-size: 10px;
  }

  .error-code {
    position: absolute;
    top: 53px;
  }
</style>
