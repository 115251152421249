<template>
  <div class="cp-box">
      <section>
        <div class="main-header">
          <h2 class="inner-headers">Permissions</h2>
        </div>
      </section>
      <permissions v-model="user.perms" :disabled="disabled" :employee="employee" :noButtons="noButtons" @editPerms="enableDisablePerms" classes="roleHeadline"/>
    </div>
</template>

<script>
import permissions from '../../../form-fields/permissions.vue'

export default {
  components: { permissions },

  props: {
    employee: {
      type: Object,
      default: null
    },
    noButtons: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      user: { perms: [] },
      disabled: true
    }
  },

  mounted() {
    this.user=JSON.parse(JSON.stringify(this.employee.user));
    this.$set(this.user, "perms", this.user.perms || []);
    this.$nextTick(()=>this.show=true);
  },

  methods: {
    enableDisablePerms () {
      this.disabled = !this.disabled
    }
  }

}
</script>

