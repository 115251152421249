<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>Cancel Card</h3>
    </div>
      <div class="scrollable">
        <template>
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
            </div>
            <div class="text-center">
              <h3 class="subtitle">This will cancel card:</h3>
            </div>
            <p class="text-center address-line">*{{ account.cards[account.cards.length-1].cardLast4 }}</p>
          </div>
        </template>
        <div class="buttons">
          <button class="main" @click="add()">Submit</button>
          <button class="second" @click="open = false">Cancel</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
  </popup>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    },

    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      form: {
        account: null,
        cardType: null,
        cardLimit: null,
        cardLimitInterval: null,
        cardBin: null,
        shipTo: null,
      },
      system: null,

      open: true,
      submitError: false,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    accountOptions () {
      let accounts = []
      accounts.push({
        id: this.account.id,
        text: `${this.account.accountNumber}-${this.account.regName}`,
      })

      return accounts
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false
      const loader = this.$loading.show()
      let payload;

      const response = (await this.$store.dispatch('cards/action', {
        id: this.account.cards[this.account.cards.length-1].id,
        action: 'CancelCard',
        payload: {
          account: this.form.account,
        }
      }))
      if(response.action.error) {
        this.$toast.error('Error: ' + response.action.errorMsg)
      }else{
        this.$store.dispatch('registrations/get', this.registration.id)
        this.open = false
        this.$toast.success('Card successfully canceled.')
      }
      loader.hide()
    },
  },

  async created () {
    this.form.account = this.account.id
    this.system = JSON.parse(JSON.stringify(window.appData.system))
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        account: { required },
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}

.address-line {
  margin: 0;
}
</style>