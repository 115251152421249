<template>
  <div>
    <div class="cp-box">
      <div class="cof-field mobinl">
        <label>&nbsp;</label>
        <button v-if="canEdit" class="main" @click="addNewDocument()">Add New</button>
      </div>
      <hr>
      <!--
      <section>
        <div class="main-header">
          <h2 class="inner-headers">Profile Documents</h2>
        </div>
      </section> -->

      <section class="content-filters">
        <div v-if="addDocument" class="cof-row" style="margin-bottom: 70px;">
          <mnb-select v-model="addDocumentForm.description" label="Description" :validator="$v.addDocumentForm.description" :options="businessPersonalDescriptionOptions" />
          <mnb-input label="Description" v-model="descriptionOther" :validator="$v.descriptionOther"/>
          <mnb-select v-model="addDocumentForm.type" label="Type" :validator="$v.addDocumentForm.type" :options="businessPersonalTypeOptions" />
          <mnb-input label="Type" v-model="typeOther" :validator="$v.typeOther" />
          <mnb-input label="Number" v-model="addDocumentForm.number" :validator="$v.addDocumentForm.number" />
          <form-field-upload v-model="addDocumentForm.file" :value="addDocumentForm.file" :validator="$v.addDocumentForm.file" label="Document" :classes="{'add-document':true}" btnClasses="add-document-btn" style="width: 255px;" />
          
          
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>&nbsp;</label>
            <button @click="saveDocument()" class="main" style="min-width: 100px; margin-left: 20px">Submit</button>
          </div>
          <div class="cof-field mobinl" style="margin-left: 20px;">
            <label>&nbsp;</label>
            <button class="second">Reset Fields</button>
          </div>    
        </div>
      </section>

      <section  class="content-tlist" style="margin: 30px 0;">
        <table class="content-table">           
          <tr>
            <th>Description</th>              
            <th>Type</th>
            <th>Number</th>              
            <th>Filename</th>
            <th v-if="canEdit">Options</th>                          
          </tr>
          <tr v-for="(document, i) in reorderedDocuments" :key="document.id">
            <td><span>Description</span>
              <mnb-input v-if="isEdit(i) && document.deletable && !newCopy" v-model="editingForms[i].description" :validator="$v.editingForms[i].description" :classes="{ error: $v.editingForms[i].description.$invalid }"/>
              <template v-else>
                {{ document.description}}
              </template>
            </td>               
            <td><span>Type</span>
              <mnb-input v-if="isEdit(i) && document.deletable && !newCopy" v-model="editingForms[i].type" :validator="$v.editingForms[i].type" :classes="{ error: $v.editingForms[i].type.$invalid }"/>
              <template v-else>
                {{ document.type }}
              </template>
            </td>
            <td><span>Number</span>
              <mnb-input v-if="isEdit(i)" v-model="editingForms[i].number" :validator="$v.editingForms[i].number" :classes="{ error: $v.editingForms[i].number.$invalid && $v.editingForms[i].number.$dirty}"/>
              <template v-else>
                {{ document.number }}
              </template>
            </td>              
            <td><span>Filename</span>
              <form-field-upload v-if="isEdit(i)" v-model="editingForms[i].file" label="" :validator="$v.editingForms[i].file" :classes="{error: $v.editingForms[i].file.$invalid && $v.editingForms[i].number.$dirty&& $v.editingForms[i].number.$dirty, 'add-document': true}" btnClasses="add-document-btn-upload" style="width: 173px;" />
              <template v-else>
                <template v-if="document.file">
                  <a :href="`/download/RegDocument/${document.id}`" target="_blank">
                    {{ document.file }}
                  </a>
                </template>
              </template>
            </td>
            <td v-if="canEdit"><span>Options</span>
              <template v-if="isEdit(i)">
                <button @click="saveUpdateDocument(i)" class="document-handle save">Save</button>
                <button @click="cancelEdit(i)" class="document-handle cancel">Cancel</button>
              </template>
              <template v-else>
                <button v-if="$hasPerm('update-profile')" @click="editDocument(i)" class="document-handle update">Update</button>
                <button v-if="$hasPerm('remove-documents')" @click="deleteDocument(document.id, document.deletable)" class="document-handle remove">Remove</button>
                <button v-if="!document.deletable && document.file" @click="copy(i)" class="document-handle save">Copy</button>
              </template>
            </td>
          </tr>
          <tr v-if="!documents.length">
            <td class="empty-table-text" colspan="5">There are no Documents</td>
          </tr>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import FormFieldUpload from '../../components/FormFieldUpload'

import { required } from 'vuelidate/lib/validators'
import helperMixin from '../../components/common/helper-mixin'

export default {
  name: 'ProfileDocuments',

  mixins: [helperMixin],

  components: {
    MnbInput,
    FormFieldUpload,
    MnbSelect
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      addDocument: false,
      addDocumentForm: {
        description: null,
        type: null,
        number: null,
        file: null
      },
      descriptionOther: null,
      typeOther: null,
      editing: [],
      editingForms: {},
      isCopy: false,
      documentChanged: false,
      newCopy: false
    }
  },

  watch: {
    'addDocumentForm.file' (value) {
      if (this.isCopy) {
        this.documentChanged = true
      }
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    },

    canEdit () {
      return !['Denied', 'Archived'].includes(this.registration.status)
    },

    documents () {
      return (this.$store.state.reg_documents.data || []).filter(doc => parseInt(doc.regId) === parseInt(this.id))
    },

    reorderedDocuments () {
      const reordered = []
      this.documents.filter(doc => doc.description.includes('Terms and Conditions')).forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Business Incorporation').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Business Address Confirmation').forEach(el => {
        reordered.push(el)
      })

      this.documents.filter(doc => doc.description === 'Contact 1 Passport').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 1 Government Issued ID').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 1 Address Confirmation').forEach(el => {
        reordered.push(el)
      })
      
      this.documents.filter(doc => doc.description === 'Contact 2 Passport').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 2 Government Issued ID').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 2 Address Confirmation').forEach(el => {
        reordered.push(el)
      })

      this.documents.filter(doc => doc.description === 'Contact 3 Passport').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 3 Government Issued ID').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 3 Address Confirmation').forEach(el => {
        reordered.push(el)
      })

      this.documents.filter(doc => doc.description === 'Contact 4 Passport').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 4 Government Issued ID').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 4 Address Confirmation').forEach(el => {
        reordered.push(el)
      })

      this.documents.filter(doc => doc.description === 'Contact 5 Passport').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 5 Government Issued ID').forEach(el => {
        reordered.push(el)
      })
      this.documents.filter(doc => doc.description === 'Contact 5 Address Confirmation').forEach(el => {
        reordered.push(el)
      })

      this.documents.filter(el => !reordered.includes(el)).forEach(el => {
        reordered.push(el)
      })
      
      return reordered
    },

    businessPersonalDescriptionOptions () {
      let options = []
      if(this.registration.type === "Business") {
        options.push(
          { 
            id: "Other",
            text: "Other"
          },
        )
      } 
      if (this.registration.type === "Personal") {
        options.push(
          { 
            id: "Other",
            text: "Other"
          },
        )
      }

      return options
    },

    businessPersonalTypeOptions () {

      const options = [
        { 
          id: "Other",
          text: "Other"
        }
      ]

      return options
    },
  },

  created() {
    this.$store.dispatch('reg_documents/load', { params: { regId: this.id }})
  },

  methods: {
    addNewDocument () {
      this.addDocument = true
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    async saveDocument () {
      const documentType = this.typeOther ? this.typeOther : this.addDocumentForm.type
      const name = `${this.registration.name}-${documentType}`
      this.$v.$touch()
      if (!this.$v.addDocumentForm.$invalid && !this.$v.descriptionOther.$invalid && !this.$v.typeOther.$invalid) {
        try {
          const loader = this.$loading.show()
          const documentDescription = this.descriptionOther ? this.descriptionOther : this.addDocumentForm.description
          await this.documentPromise(this.id, true, documentDescription, documentType, this.addDocumentForm.number, this.addDocumentForm.file, name)
          await this.setLastUpdatedAt()
          this.$toast.success('Document successfully added.')
          Object.keys(this.addDocumentForm).map(item => this.addDocumentForm[item] = null)
          this.descriptionOther = null
          this.typeOther = null
          this.isCopy = false
          this.documentChanged = false
          this.$v.$reset()
          loader.hide()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async saveUpdateDocument (i) {
      let name 
      if (this.registration.type === 'Business') {
        if (this.reorderedDocuments[i].description === 'Business Incorporation') name = `${this.registration.name}-Business Inc Documents`
        else if (this.reorderedDocuments[i].description === 'Business Address Confirmation') name = `${this.registration.name}-Business Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Contact 1 Passport') name = `${this.registration.data.contacts[0].firstName} ${this.registration.data.contacts[0].lastName}-Passport-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 1 Government Issued ID') name = `${this.registration.data.contacts[0].firstName} ${this.registration.data.contacts[0].lastName}-${this.reorderedDocuments[i].type}-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 1 Address Confirmation') name = `${this.registration.data.contacts[0].firstName} ${this.registration.data.contacts[0].lastName}-Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Contact 2 Passport') name = `${this.registration.data.contacts[1].firstName} ${this.registration.data.contacts[1].lastName}-Passport-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 2 Government Issued ID') name = `${this.registration.data.contacts[1].firstName} ${this.registration.data.contacts[1].lastName}-${this.reorderedDocuments[i].type}-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 2 Address Confirmation') name = `${this.registration.data.contacts[1].firstName} ${this.registration.data.contacts[1].lastName}-Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Contact 3 Passport') name = `${this.registration.data.contacts[2].firstName} ${this.registration.data.contacts[2].lastName}-Passport-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 3 Government Issued ID') name = `${this.registration.data.contacts[2].firstName} ${this.registration.data.contacts[2].lastName}-${this.reorderedDocuments[i].type}-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 3 Address Confirmation') name = `${this.registration.data.contacts[2].firstName} ${this.registration.data.contacts[2].lastName}-Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Contact 4 Passport') name = `${this.registration.data.contacts[3].firstName} ${this.registration.data.contacts[3].lastName}-Passport-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 4 Government Issued ID') name = `${this.registration.data.contacts[3].firstName} ${this.registration.data.contacts[3].lastName}-${this.reorderedDocuments[i].type}-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 4 Address Confirmation') name = `${this.registration.data.contacts[3].firstName} ${this.registration.data.contacts[3].lastName}-Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Contact 5 Passport') name = `${this.registration.data.contacts[4].firstName} ${this.registration.data.contacts[4].lastName}-Passport-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 5 Government Issued ID') name = `${this.registration.data.contacts[4].firstName} ${this.registration.data.contacts[4].lastName}-${this.reorderedDocuments[i].type}-${this.editingForms[i].number}`
        else if (this.reorderedDocuments[i].description === 'Contact 5 Address Confirmation') name = `${this.registration.data.contacts[4].firstName} ${this.registration.data.contacts[4].lastName}-Address Confirm`

        else if (this.reorderedDocuments[i].description === 'Provider Agrement') name = `${this.registration.mainAccount.profileNumber} ${this.registration.mainAccount.accountNumber}-final account agrement`
        else if (this.reorderedDocuments[i].description.includes('Terms and Conditions')) name = `${this.registration.regId}-TermsAndConditions`
        else name = `${this.registration.name}-${this.editingForms[i].type}`
      }
      if (this.registration.type === 'Personal') {
        if (this.editingForms[i].type === 'Address') name = `${this.registration.name}-Address Confirm`
        else if (this.editingForms[i].type === 'Passport') name = `${this.registration.name}-Passport-${this.editingForms[i].number}`
        else if (this.editingForms[i].type === 'Drivers License') name = `${this.registration.name}-Drivers Licence-${this.editingForms[i].number}`

        else if (this.reorderedDocuments[i].description === 'Provider Agrement') name = `${this.registration.mainAccount.profileNumber} ${this.registration.mainAccount.accountNumber}-final account agrement`
        else if (this.reorderedDocuments[i].description.includes('Terms and Conditions')) name = `${this.registration.regId}-TermsAndConditions`
        else name = `${this.registration.name}-${this.editingForms[i].type}`
      }
      // if (this.newCopy) {
      //   name = this.reorderedDocuments[i].file
      // }

      if (this.editingForms[i]) {
        this.$v.editingForms[i].$touch()
        if (!this.$v.editingForms[i].$invalid) {
          const loader = this.$loading.show()
          try {
            await this.documentUpdatePromise(this.reorderedDocuments[i].id, this.editingForms[i].description, this.editingForms[i].type, this.editingForms[i].number, this.editingForms[i].file, name)
            await this.setLastUpdatedAt()
            this.$toast.success('Document successfully updated.')
            this.cancelEdit(i)
            this.newCopy = false
          } catch (e) {
            this.$toast.error(window.errorMessage)
          }
          loader.hide()
        }
      }
    },

    async documentPromise (regId, deletable, description, type, number, file, filename) {
      const document = await this.$store.dispatch('reg_documents/create', {
        regId,
        deletable,
        type,
        description,
        number,
        file: this.isCopy ? file.name : null,
        isCopy: this.isCopy,
        createdAt: 'CURRENT_TIMESTAMP'
      })
      if(file && (!this.isCopy || this.documentChanged)) await this.$store.dispatch('reg_documents/upload', { 
        id: document.id, 
        file: this.changeFileName(file, filename),
        params: {
          virusscan: true
        } })
    },

    async documentUpdatePromise (documentId, description, type, number, file, filename) {
      const updateDocument = await this.$store.dispatch('reg_documents/update', { id: documentId, payload: {
        type,
        description,
        number
      }})
      if(file) await this.$store.dispatch('reg_documents/upload', { 
        id: documentId, 
        file: this.changeFileName(file, filename),
        params: {
          virusscan: true
        } })
    },

    async deleteDocument(id, deletable) {
      if(confirm(`Are you sure to delete document?`)) {
        if (deletable) {
          await this.$store.dispatch('reg_documents/delete', id)
        } else {
          await this.$store.dispatch('reg_documents/update', { id: id, payload: {
            number: null,
            file: null
          }})
        }
        await this.setLastUpdatedAt()
        this.$toast.success('Document deleted successfully.')
      }
    },

    setLastUpdatedAt () {
      return Promise.all([
        this.$store.dispatch('registrations/update', { id: this.registration.id, payload: { updatedAt: 'CURRENT_TIMESTAMP' } }),
        this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: this.registration.id,
          notes: 'Documents updated',
          createdAt: 'CURRENT_TIMESTAMP'
        })
      ])
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      this.$delete(this.editingForms, i)
      this.newCopy = false
    },

    editDocument(index) {
      this.editing.push(index)
      this.$set(this.editingForms, index, {
        description: this.reorderedDocuments[index].description,
        type: this.reorderedDocuments[index].type,
        number: this.reorderedDocuments[index].number,
      })
    },

    async copy(i) {
      const loader = this.$loading.show()
      this.addDocumentForm.description = 'Other'
      this.addDocumentForm.type = 'Other'
      this.addDocumentForm.number = this.reorderedDocuments[i]?.number
      this.descriptionOther = this.reorderedDocuments[i]?.file.slice(0, this.reorderedDocuments[i].file.indexOf('.'))
      this.typeOther = this.reorderedDocuments[i]?.file.slice(0, this.reorderedDocuments[i].file.indexOf('.'))

      if (this.reorderedDocuments[i]) {
        this.addDocumentForm.file = new File(["foo"], this.reorderedDocuments[i]?.file, {
          type: "text/plain",
        })
      }
      this.isCopy = true
      await this.saveDocument()
      if (this.reorderedDocuments[i].deletable) {
        await this.$store.dispatch('reg_documents/delete', this.reorderedDocuments[i].id)
      } else {
        await this.$store.dispatch('reg_documents/update', { id: this.reorderedDocuments[i].id, payload: {
          number: null,
          file: null
        }})
      }
      await this.setLastUpdatedAt()
      this.$toast.success('Document deleted successfully.')
      loader.hide()
    }
  },

  validations () {
    const rules = {
      addDocumentForm: {
        description: { required },
        type: { required },
        number: { required },
        file: { required },
      },
      descriptionOther: { required },
      typeOther: { required },
      editingForms: {}
    }

    if (this.editingForms) {
      for (const index in this.editingForms) {
        rules.editingForms[index] = { 
          number: { required },
          file: { required },
          description: { required },
          type: { required }
        }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .mobinl {
    margin-bottom: 20px;
  }
  .document-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white; 
    border: none;
    outline: none; 

    &.update {
      background-color: #ffa700; 
    }
    &.remove {
      background-color: #d62b2b; 
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
  .content-filters {
    margin-top: 80px; 
  }
</style>
