<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>Settings</h3>
    </div>
      <div v-if="feeSchedule" class="">
        <table class="content-table">
          <tr>
            <th style="width: 15%;"></th>
            <th style="width: 15%;">Fee Name</th>
            <th style="width: 10%;">Fee</th>
            <th style="width: 10%;">Deposit</th>
            <th style="width: 10%;">Withdrawal</th>
          </tr>
          <tr>
            <td></td>
            <td>
              <span>Fee Name</span>
              Volume Fee
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                {{ feeSchedule.volumeFee.fee | formattedTwoDecimal }} %
              </template>
              <template v-else>
                <input
                  v-model="feeSchedule.volumeFee.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.volumeFee.fee"
                />%
              </template>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <span>Fee Name</span>
              Monthly Access Fee
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.monthlyAccessFee.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.monthlyAccessFee.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.monthlyAccessFee.fee"
                />
              </template>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
             
            </td>
            <td>
              <span>Fee Name</span>
              Dormant Accounts
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.dormantAccounts.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.dormantAccounts.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.dormantAccounts.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr v-if="provider.ach == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.ach" />
              <mnb-switch v-else :value="profileSettings.ach" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              ACH
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.ach.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.ach.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.ach.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.ach.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.ach.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.ach.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.acct == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.acc_to_acc" />
              <mnb-switch v-else :value="profileSettings.acc_to_acc" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Account Transfer
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.accountTransfer.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.accountTransfer.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.accountTransfer.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.checks == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.checks" />
              <mnb-switch v-else :value="profileSettings.checks" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Checks
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.checks.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.checks.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.checks.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.checks.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.checks.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.checks.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.domWires == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.domWires" />
              <mnb-switch v-else :value="profileSettings.domWires" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Domestic Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.domesticWire.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.domesticWire.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.domesticWire.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.domesticWire.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.domesticWire.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.domesticWire.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.intlWires == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.intlWires" />
              <mnb-switch v-else :value="profileSettings.intlWires" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              International Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.internationalWire.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.internationalWire.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.internationalWire.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.internationalWire.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.internationalWire.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.internationalWire.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.exchange == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.exchange" />
              <mnb-switch v-else :value="profileSettings.exchange" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Exchange
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.exchange.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.exchange.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.exchange.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr v-if="provider.assets == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.assets" />
              <mnb-switch v-else :value="profileSettings.assets" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Assets
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.fee"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.per_deposit | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.per_deposit"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.per_deposit"
                />
              </template>
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.assets.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.assets.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.assets.per_withdrawal"
                />
              </template>
            </td>
          </tr>
          <tr v-if="provider.debitCards == 1">
            <td>
              <mnb-switch v-if="updateFlag" v-model="profileSettings.debitCards" />
              <mnb-switch v-else :value="profileSettings.debitCards" disabled />
            </td>
            <td>
              <span>Fee Name</span>
              Debit Card
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.debitCard.fee | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.debitCard.fee"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.debitCard.fee"
                />
              </template>
            </td>
            <td>
              
            </td>
            <td>
              <template v-if="!updateFlag">
                $ {{ feeSchedule.debitCard.per_withdrawal | formattedTwoDecimal }}
              </template>
              <template v-else>
                $ <input
                  v-model="feeSchedule.debitCard.per_withdrawal"
                  type="number"
                  class="fee-input"
                  :validator="$v.feeSchedule.debitCard.per_withdrawal"
                />
              </template>
            </td>
          </tr>
        </table>
        <div class="buttons">
          <template v-if="$hasPerm('update-fee-Schedule')">
            <button v-if="!updateFlag" class="main" @click="updateFlag = true">Update</button>
            <button v-else class="main" @click="save()">Save</button>
          </template>
          <button class="second" @click="open = false">Close</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
  </popup>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'
import MnbSwitch from '../../components/ui/forms/Switch'


export default {
  components: {
    MnbSwitch,
  },

  props: {
    modalType: {
      type: String,
      required: true
    },

    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    },

    provider: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      updateFlag: false,
      open: true,
      submitError: false,
    }
  },

  computed: {
    feeSchedule () {
      return this.account.feeSchedule
    },

    profileSettings () {
      return this.account?.profileSettings || null
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    async save () {
      this.$v.feeSchedule.$touch()
      if (this.$v.feeSchedule.$invalid) {
        this.submitError = true
        return
      }
      this.submitError = false
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/update', {
          id: this.account.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            feeSchedule: this.feeSchedule,
            profileSettings: this.profileSettings
          }
        })
        await this.$store.dispatch('registrations/get', this.registration.id)
        this.updateFlag = false
        this.open = false
        this.$toast.success('Fee Schedule successfully updated.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
    
  },

  async created () {
  },

  validations () {
    let rules = {
      feeSchedule: {
        volumeFee : { 
          fee: { required }
        },
        monthlyAccessFee : { 
          fee: { required }
        },
        domesticWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        internationalWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        ach : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        accountTransfer : { 
          per_withdrawal: { required }
        },
        checks : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        exchange : { 
          fee: { required },
        },
        assets : { 
          fee: { required },
          per_deposit: { required },
          per_withdrawal: { required }
        },
        dormantAccounts : { 
          fee: { required },
        },
        debitCard : { 
          fee: { required },
          per_withdrawal: { required }
        },
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.fee-input {
  max-width: 50px;
}
.fee-input::-webkit-inner-spin-button,
.fee-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>