<template>
  <popup :open.sync="open">
    <h2>Create New Employee</h2>
      <div class="pf-row split">
        <div class="pf-field">
          <form-field-select v-model="item.role" :validator="$v.item.role" label="Role" :options="roleOptions" required />
        </div>
      </div>
      <div class="pf-row split">
        <div class="pf-field">
          <form-field-text v-model="item.firstName" label="First Name"  :validator="$v.item.firstName" :maxlength="25" required  />
        </div>
        <div class="pf-field">
          <form-field-text v-model="item.lastName" label="Last Name"  :validator="$v.item.lastName" :maxlength="25" required  />
        </div>
      </div>
      <div class="pf-row split">
        <div class="pf-field">
          <form-field-text :validator="$v.item.email" label="Email" required v-model="item.email" :maxlength="50"/>
        </div>
        <div class="pf-field">
          <form-field-text :validator="$v.item.phone" label="Phone" :maxlength="20" required v-model="item.phone" />
        </div>
      </div>
      <div class="pf-row split error" v-if="duplicateEmail">
        Email address already exists!
      </div>

      <label style="margin-bottom: 10px; display: block;">Permissions</label>
      <permissions v-model="item.perms" :role="item.role" :noButtons="true"/>

      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="open=false">Cancel</a>
        <button class="pop-btn" @click="showConfirm()">Create</button>
      </div>
  </popup>
</template>
<script>
  import { mustBeTrue, alpha, alphaNum } from '../../lib/validators'
  import { required, numeric, email, url, requiredIf, maxLength, between } from 'vuelidate/lib/validators'

  export default {
    data: ()=>({
      open: false,
      confirmOpen: false,
      user: null,
      roles: [],
      item: {
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        perms: []
      },
      duplicateEmail: false
    }),
    methods: {
      showUser(user) {
        this.user=user;
        this.open=true;
      },

      async loadRoles() {
        this.roles=(await api.list("Role", {"group_id": 1})).items;
      },

      async showConfirm() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        window.employeeConfirmPopup.show();
      },

      async createEmployee() {
        let payload = {
          role: this.item.role,
          firstName: this.item.firstName,
          lastName: this.item.lastName,
          email: this.item.email,
          phone: this.item.phone,
          perms: JSON.stringify(this.item.perms)
        }
        let response = (await api.action("Employees", "new", "Create", payload))
        if(response.action.duplicateEmail){
          this.duplicateEmail = true;
        }else{
          this.duplicateEmail = false;
          this.open = false;
          this.item = {
            role: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            perms: []
          };
          window.employeeList.loadEmployees();
          window.employeeCodePopup.show(response.item.user.activationCode);
        }
      }
    },
    mounted() {
      this.loadRoles();
      window.employeePopup=this;
    },

    computed: {
      roleOptions () {
        return this.roles.reduce((acc, state) => {
          if(state.id !=1) {
            acc[state.id] = state.name
          }
          return acc
        }, {})
      }
    },

    validations () {
      const rules = {
        item: {},
      }

      rules.item = {
        firstName: { required, alpha },
        lastName: { required, alpha },
        phone: { required, numeric },
        email: { required, email },
        role: { required},
      }

      return rules
    }
  }
</script>
<style lang="scss" scoped>
  .error {
    color: #d23131;
  }
</style>
