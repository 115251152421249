<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Boarding Compliance</h2>
      <div class="status">
        Status
        <status-icon :color="registration.boardingApproval ? 'green' : null" />
      </div>
    </div>
    <table v-if="registration.type == 'Personal'" class="content-table" style="min-width: unset;">
      <tr>
        <td colspan="3">
          <div class="flex">
            <label>Scan Type:</label>&nbsp;&nbsp;
            <mnb-select v-model="form.scanType" :options="providerDisabledOptions" />&nbsp;&nbsp;
            <button class="main" @click="scan()" :disabled="!form.scanType">Scan</button>
          </div>
        </td>
      </tr>
      <tr>
        <th>Scan</th>
        <th>Date/Time</th>
        <th>Scan Type</th>
        <th>Scan Result</th>
        <th>File</th>
        <th>Options</th>
      </tr>
      <tr v-for="scan in scans" :key="scan.id">
        <td><span>Scan</span>{{ scan.scanId }}</td>
        <td><span>Date/Time</span>{{ scan.createdAt | date('MM/D/YYYY') }}</td>
        <td><span>Scan Type</span>
          {{ scan.scanType }}
        </td>
        <td><span>Scan Result</span>
          <strong :class="statusColorClasses[scan.scanResult]">{{ scan.scanResult }}</strong>
        </td>
        <td><span>File</span>
          <a class="link" target="_blank" :href="`files/scanReports/${scan.id}/${scan.file}`">{{ scan.file }}</a>
        </td>
        <td><span>Options</span>
          <button v-if="scan.scanResult === 'Pending'" class="btn btn-secondary" @click="update(scan.id)">Update</button>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="date-time">
          <p class="date">Date / Time:</p>
          <template v-if="!registration.boardingApproval">N/A</template>
          <template v-else>{{ registration.createdAt | date('LL') }}<br>{{ registration.createdAt | date('LT') }}</template>
        </td>
        <td v-if="registration.status !== 'Complete'" colspan="3" style="text-align: right;"><span>Options</span>
          <template v-if="$hasPerm('boarding-approval')">
            <template v-if="!registration.boardingApproval">
              <button class="btn btn-secondary" @click="approve()">Skip</button>
              <button :class="{main: true}" disabled>Approve</button>
              <button :class="{second: true}" disabled>Denied</button>
            </template>
            <template v-else>
              <button :class="{second: true}" @click="reset()">Reset</button>
            </template>
          </template>
        </td>
      </tr>
    </table>
    <table v-else class="content-table" style="min-width: unset;">
      <tr>
        <td colspan="3">
          <div class="flex">
            <label>Scan Type:</label>&nbsp;&nbsp;
            <mnb-select v-model="form.scanType" :options="providerOptions" />&nbsp;&nbsp;
            <button class="main" @click="scan()" :disabled="!form.scanType">Scan</button>
          </div>
        </td>
      </tr>
      <tr>
        <th>Scan</th>
        <th>Date/Time</th>
        <th>Scan Type</th>
        <th>Scan Result</th>
        <th>File</th>
        <th>Options</th>
      </tr>
      <tr v-for="scan in scans" :key="scan.id">
        <td><span>Scan</span>{{ scan.scanId }}</td>
        <td><span>Date/Time</span>{{ scan.createdAt | date('MM/D/YYYY') }}</td>
        <td><span>Scan Type</span>
          {{ scan.scanType }}
        </td>
        <td><span>Scan Result</span>
          <strong :class="statusColorClasses[scan.scanResult]">{{ scan.scanResult }}</strong>
        </td>
        <td><span>File</span>
          <a class="link" target="_blank" :href="`files/scanReports/${scan.id}/${scan.file}`">{{ scan.file }}</a>
        </td>
        <td><span>Options</span>
          <button v-if="scan.scanResult === 'Pending'" class="btn btn-secondary" @click="update(scan.id)">Update</button>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="date-time">
          <p class="date">Date / Time:</p>
          <template v-if="!registration.boardingApproval">N/A</template>
          <template v-else>{{ registration.createdAt | date('LL') }}<br>{{ registration.createdAt | date('LT') }}</template>
        </td>
        <td v-if="registration.status !== 'Complete'" colspan="3" style="text-align: right;"><span>Options</span>
          <template v-if="$hasPerm('boarding-approval')">
            <template v-if="scans[0]">
              <template v-if="registration.status == 'In Process' && registration.boardingApproval"  >
                <button :class="{second: true}" @click="reset()" :disabled="scans[0].scanResult != 'Complete'">Reset</button>
              </template>
              <template v-else-if="registration.status == 'Denied'">
                <button :class="{second: true}" @click="reset()">Reset</button>
              </template>
              <template v-else>
                <button :class="{main: true}" @click="approve()" :disabled="scans[0].scanResult != 'Complete'">Approve</button>
                <button :class="{second: true}" @click="denied()" :disabled="scans[0].scanResult != 'Complete'">Denied</button>
              </template>
            </template>
            <template v-else>
              <button :class="{main: true}" disabled>Approve</button>
              <button :class="{second: true}" disabled>Denied</button>
            </template>
          </template>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
  import StatusIcon from './StatusIcon'
  import MnbSelect from '../../../components/ui/forms/Select'

  export default {
    props: {
      registration: {
        type: Object,
        required: true
      },
      step: {}
    },

    components: {
      MnbSelect,
      StatusIcon
    },

    data () {
      return {
        form: {
          scanType: null
        },
        checked: false
      }
    },

    computed: {
      scans () {
        let scans = this.$store.state.scan_reports.data || []
        return scans
      },

      statusColorClasses () {
        return {
          Pending: 'c2',
          Error: 'c3',
          Complete: 'c1',
        }
      },

      providers () {
        return this.$store.state.providersList.data || []
      },

      selectedProvider() {
        return this.providers.find(item => item.id == this.submitForm.scanType)
      },

      providerOptions () {
        return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Compliance').map(provider => ({
          id: provider.id,
          text: provider.provider_name
        }))
      },

      providerDisabledOptions () {
        return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Compliance').map(provider => ({
          id: provider.id,
          text: provider.provider_name,
          disabled: true
        }))
      },
    },

    async created () {
      await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
      await this.refreshScans();
    },

    methods: {
      async refreshScans () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        params.regId = this.registration.id

        await this.$store.dispatch('scan_reports/load', { params })
        this.loading=false;
        loader.hide()
      },

      async scan() {
        const loader = this.$loading.show()
        try {
          const action = (await this.$store.dispatch('scan_reports/action', {
            id: 'new',
            action: 'KycSiteScan',
            payload: {
              provider: this.form.scanType,
              regId: this.registration.id
            }
          })).action
          this.refreshScans();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
          this.open = false
        }
      },

      async update (id) {
        const loader = this.$loading.show()
        try {
          const action = (await this.$store.dispatch('scan_reports/action', {
            id: id,
            action: 'UpdateScan',
            payload: {

            }
          })).action
          this.refreshScans();
        } catch (e) {
          //this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async approve () {
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('registrations/update', {
            id: this.registration.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              boardingApproval: true
            }
          })
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          this.$emit("update:step", this.step+1)
          loader.hide()
        }
      },

      async denied () {
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('registrations/update', {
            id: this.registration.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              status: 'Denied',
              boardingApproval: false
            }
          })
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async reset () {
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('registrations/update', {
            id: this.registration.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              status: 'In Process',
              boardingApproval: false
            }
          })
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    padding: 15px 34px;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    .status {
      font-size: 16px;
    }
  }
  .disabled {
    background-color: #f6f8f9;
    color: #616f7b;
    border: 1px solid #616f7b;
  }
  .date-time {
    padding: 5px 0 5px 34px;
  }
  .date {
    color: #000;
  }
  .flex {
    align-items: center;
  }
</style>
