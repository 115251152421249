<template>
  <div>
    <checklist-steps :registration="registration" />
    
  </div>
</template>

<script>
import Documents from './checklist/Documents'
import ComplianceScan from './checklist/ComplianceScan'
import ProfileSettings from './checklist/ProfileSettings'
import ServiceProviders from './checklist/ServiceProviders'
import TermsAndConditions from './checklist/TermsAndConditions'
import AccountConfirmation from './checklist/AccountConfirmation'
import ProfileAccountCreation from './checklist/ProfileAccountCreation'
import ChecklistSteps from './checklist/ChecklistSteps'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  components: { 
    Documents,
    ComplianceScan,
    ProfileSettings,
    ServiceProviders,
    TermsAndConditions,
    AccountConfirmation,
    ProfileAccountCreation,
    ChecklistSteps,
  }
}
</script>
