<template>
    <div v-if="user" class="users-profile">
      <section>
        <div>
          <h2 class="inner-headers">My Profile</h2>
        </div>
      </section>
      <section class="client-properties active">
        <div class="cp-box">
          <ul class="ctabs" style="margin-bottom:50px">
            <li :class="{ active: active === 1 }" @click="active = 1"><a>My Info</a></li>
            <li :class="{ active: active === 3 }" @click="active = 3"><a>My Settings</a></li>
            <li :class="{ active: active === 4 }" @click="active = 4"><a>My Access</a></li>
            <li :class="{ active: active === 5 }" @click="active = 5"><a>Change Password</a></li>
          </ul>
          
          <div v-if="active === 1">
            <ul class="sub-tabs">
              <li :class="{ active: subActive === 'profile' }" @click="subActive = 'profile'"><a>Profile</a></li>
              <li :class="{ active: subActive === 'Contacts' }" @click="subActive = 'Contacts'"><a>Contacts</a></li>
            </ul>
            <div v-if="subActive === 'profile'">
              <registration-data-info
                ref="contacts"
                :registration="registration" 
                hideBasics
                hideContacts
                :showEdit="false"/>
            </div>
            <div v-if="subActive === 'Contacts'">
              <contacts :registration="registration" :showEditAdd="false" />
            </div>
          </div>
  
          <div v-if="active === 3">
            <ul class="sub-tabs">
              <li :class="{ active: settingsActive === 'settings' }" @click="settingsActive = 'settings'"><a>Settings</a></li>
              <li :class="{ active: settingsActive === 'fee' }" @click="settingsActive = 'fee'"><a>Schedule</a></li>
            </ul>
            <div v-if="settingsActive === 'settings'">
              <settings-setup :registration="registration" hideVerificationPermissionsReview :noDateUpdate="false" />
            </div>
            <div v-if="settingsActive === 'fee'">
              <fees-schedule :registration="registration" :noUpdate="false"/>
            </div>
          </div>
  
          <div v-if="active === 4">
            <ul class="sub-tabs">
              <li :class="{ active: accessActive === 'users' }" @click="accessActive = 'users'"><a>Users</a></li>
              <li :class="{ active: accessActive === 'userLog' }" @click="accessActive = 'userLog'"><a>User Log</a></li>
            </ul>
            <div v-if="accessActive === 'users'">
              <div>
                <button :disabled="!selected" class="btn btn-block" @click="block()">Block</button>
                <button class="btn btn-primary" @click="openAddUser()">Add Sub-User</button>
                <button
                  :disabled="!selected || selected.id === registration.user.id"
                  class="btn btn-primary"
                  @click="remove()">
                  Remove Sub-User
                </button>
  
                <section  class="content-tlist" style="margin: 30px 0;">
                  <table class="content-table">
                    <tr>
                      <th></th>
                      <th>Username</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Activation Date</th>
                    </tr>
                    <tr v-for="(user, i) in users" :key="i">
                      <td><span></span>
                        <input type="radio" @change="onCbxClicked(user)" name="access-user">
                      </td>
                      <td><span>Username</span>{{ user.username }}</td>
                      <td><span>First Name</span>{{ user.firstName }}</td>
                      <td><span>Last Name</span>{{ user.lastName }}</td>
                      <td><span>Activation Date</span>{{ user.activationDate | date('MM/D/YYYY hh:mm A') }}</td>
                    </tr>
                  </table>
                </section>
  
                <popup :open.sync="addModal">
                  <template v-if="addModal">
                    <h4 class="popup-title">Add Sub-User</h4>
                    <div class="scrollable">
                      <div class="content-form">
                        <div class="form-row form-split">
                          <form-field-text v-model="form.firstName" :maxlength="25" :validator="$v.form.firstName" label="First Name" required />
                          <form-field-text v-model="form.lastName" :maxlength="25" :validator="$v.form.lastName" label="Last Name" required />
                        </div>
                        <div class="form-row form-split">            
                          <form-field-text v-model="form.email" :validator="$v.form.email" label="Email Address" required />
                          <form-field-text v-model="form.phone" :maxlength="15" :validator="$v.form.phone" label="Phone Number" required />
                        </div>
                      </div>
                    </div>
                    <div class="buttons">
                      <button class="main" @click="add()">Add</button>
                      <button class="second" @click="addModal = false">Cancel</button>
                    </div>
                  </template>
                </popup>
              </div>
            </div>
            <div v-if="accessActive === 'userLog'">
              <ul
                v-if="lastLogin"
                class="cp-fields justify-content-none">
                <li>
                  <span>Last Login</span>
                  <div class="val">
                    {{ lastLogin.recorded | date }}
                  </div>
                </li>
                <li>
                  <span>Data Transfer</span>
                  <div class="val">
                    {{ lastLogin.transferred | fileSize }}
                  </div>
                </li>
                <li>
                  <span>IP Address</span>
                  <div class="val">
                    {{ lastLogin.ipAddress }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
  
          <change-password v-if="active === 5" />
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { alphaNum } from '../../lib/validators'
  import { required, numeric, email } from 'vuelidate/lib/validators'
  
  import ChangePassword from './ChangePassword'
  import helper from '../../components/common/helper-mixin'
  import RegistrationDataInfo from '../partners/RegistrationDataInfo'
  import Contacts from '../partners/ProfileInfoContacts'
  import SettingsSetup from '../partners/SettingsSetup'
  
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbSwitch from '../../components/ui/forms/Switch'
  
  export default {
    name: 'UsersProfile',
  
    mixins: [helper, vueMixins.helpers],
  
    components: {
      Contacts,
      MnbInput,
      MnbSwitch,
      MnbSelect,
      SettingsSetup,
      ChangePassword,
      RegistrationDataInfo
    },
  
    data () {
      return {
        active: null,
        subActive: 'profile',
        settingsActive: 'settings',
        accessActive: 'users',
  
        form: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null
        },
  
        selected: null,
        addModal: false,
  
        systemMaintenanceStatus: window.maintenance,
        featureStatus: true,
        mainAccount: null,
        modalActivationType: null,
        modalPinData: null,
        modalCardData: null,
  
        featureFundsStatus: null,
        featureAssetsStatus: null,
        featureTradeStatus: null,
        featureAdvancedAccountRoutingStatus: null,
        featureCardServicesStatus: null,
  
        currencyStatus : {
          AUD : false,
          CAD : false,
          EUR : false,
          GBP : false,
          JPY : false,
        },
  
        updateSettings: false,
  
        yesNoOptions: [
          {
            id: true,
            text: 'Yes'
          },
          {
            id: false,
            text: 'No'
          },
        ],
  
        permsNew: window.appData.currentUser?.role?.perms?.includes('new-account') || window.appData.currentUser?.perms?.includes('new-account'),
      }
    },
  
    computed: {
      user () {
        return this.$store.getters['users/get'](this.currentUser.id)
      },
  
      currentUser () {
        return window.appData.currentUser
      },
  
      lastLogin () {
        return this.$store.getters['users_logins/get'](this.user?.lastLogin) || null
      },
  
      registration () {
        return this.$store.getters['partners/get'](this.currentUser.parId)
      },
  
      users () {
        if(this.registration?.user?.id) {
          return this.$store.getters['users/list'].filter(user => parseInt(user.id) === parseInt(this.registration.user.id) || parseInt(user.subuserOf) === parseInt(this.registration.user.id))
        }
      },
  
      provider () {
        return this.providers.find(provider => provider.id === this.account.serviceProviders?.id)
      },
  
      providers () {
        return this.$store.state.providersList.data || []
      },
    },
  
    watch: {
      
    },
  
    async created () {
      const loader = this.$loading.show()
      await this.getFeatureStatus();
      if(this.currentUser.parId) {
        await this.$store.dispatch('partners/get', this.currentUser.parId)
      }
      await this.$store.dispatch('users/get', this.currentUser.id)
      if (this.user?.lastLogin) await this.$store.dispatch('users_logins/get', this.user.lastLogin)
  
      const active = parseInt(this.getUrl('active'))
      if(active) {
        if ([1, 2, 3, 4, 5].includes(active)) {
          this.active = active
        }
      }else{
        this.active = 1
      }
  
      if(this.registration.user?.id) {
        this.$store.dispatch('users/get', this.registration.user.id)
        this.$store.dispatch('users/load', { params: { subuserOf: this.registration.user.id } })
      }
      
      await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
      loader.hide()
    },
  
    methods: {
      async remove () {
        if (!this.selected || this.selected.id === this.registration.user.id) return
        if (!confirm('Are you sure you want to remove selected Sub-User?')) return
        
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('users/delete', this.selected.id)
          this.$toast.success('Sub-User removed successfully.')
          this.selected = null
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },
  
      getStatus (status) {
        const names = {
          Complete: 'Pending',
          Active: 'Open',
          pendingActivation: 'Pending Activation',
          active: 'Active',
          inactive: 'Inactive',
          canceled: 'Canceled',
        }
        return names[status] || status
      },
  
      openAddUser () {
        this.addModal = true
        this.form = {
          firstName: null,
          lastName: null,
          email: null,
          phone: null
        }
        this.$v.$reset()
      },
  
      async add () {
        this.$v.$touch()
        if (this.$v.$invalid) return null
  
          const loader = this.$loading.show()
        try {
          const response = await this.$store.dispatch('registrations/action', {
            id: this.registration.id,
            action: 'CreateSubUser',
            payload: {
              ...this.form,
              regId: this.currentUser.regId
            }
          })
          this.$store.commit('users/add', response.action)
          this.$store.commit('users/setSingle', response.action)
          this.$toast.success('Sub-User added successfully.')
          this.addModal = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },
  
      async block () {
        if (!this.selected) return
        if (!confirm('Are you sure you want to block selected users?')) return null
  
        const loader = this.$loading.show()
        try {
          if (parseInt(this.selected.id) !== this.registration.user.id && this.selected.active === 'Yes') {
            await this.$store.dispatch('users/action', { id: this.selected.id, action: 'Block' })
            this.$toast.success('Selected users are now Blocked.')
          }
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },
  
      onCbxClicked (user) {
        this.selected = user
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Digital Assets']
        this.featureFundsStatus = window.systemFeatures['Multi-Currency']
        this.featureAssetsStatus = window.systemFeatures['Digital Assets']
        this.featureTradeStatus = window.systemFeatures['Trade Desk']
        this.featureAdvancedAccountRoutingStatus = window.systemFeatures['Advanced Account Routing']
        this.featureCardServicesStatus = window.systemFeatures['Card Services']
      },
      
    },
  
    validations: {
      form: {
        firstName: { required, alphaNum },
        lastName: { required, alphaNum },
        phone: { required, numeric },
        email: { required, email },
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .title {
    margin: 10px 0;
  }
  .space-bottom {
    margin-bottom: 20px;
  }
  .balance {
    color: #1f2d4e;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    display: block;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #67c23a;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #67c23a;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  </style>
  