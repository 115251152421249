<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers">Profile Detail View - Employee</h2>
      </div>
    </section>

    <section class="content-tabs">
      <div class="content-tabs-container">
        <div class="cs-tri">
          <div>Profile: <strong v-if="employee">{{ employee.employeeId }}</strong></div>
          <div>Name: <strong v-if="employee">{{ employee.firstName }} {{ employee.lastName }}</strong></div>
          <div>Profile Status: <strong v-if="employee">{{ employee.status }}</strong></div>
        </div>
        <ul class="ctabs first-row">
          <li
            v-for="tab in tabs[1]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
        <ul class="ctabs second-row">
          <li
            v-for="tab in tabs[2]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </section>

    <section class="client-properties active">
      <info v-if="activeTab === 'Employee Info'" :employee="employee" />
      <notes v-if="activeTab === 'Notes'" :employee="employee" />
      <emails v-if="activeTab === 'Emails'" :employee="employee" />
      <access v-if="activeTab === 'Access'" :employee="employee" />
      <documents v-if="activeTab === 'Documents'" :employee="employee" />
      <permissions v-if="activeTab === 'Permissions'" :employee="employee" />
    </section>
  </div>
</template>

<script>
import Info from './Info'
import Notes from './Notes'
import Access from './Access'
import Documents from './Documents'
import Emails from './Emails'
import Permissions from './Permissions'

export default {
  name: 'Employee',

  components: {
    Info,
    Notes,
    Access, 
    Documents,
    Emails,
    Permissions
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data: ()=>({
    activeTab: 'Employee Info'
  }),

  computed: {
    tabs () {
      return {
        1: ['Employee Info', 'Documents', 'Notes', 'Emails'],
        2: ['Access', 'Permissions', 'User Log']
      }
    },

    employee () {
      return this.$store.getters['employees/get'](this.id)
    }
  },

  created () {
    this.$store.dispatch('employees/get', this.id)
    this.$store.dispatch('employeenotes/load', { params: { 'employeeId': this.id } })
  }
}
</script>