<template>
  <div>
    <template v-if="$hasPerm('partners-manage')">
      <button v-if="!editStatus && showEditAdd" class="btn btn-success" @click="edit()">Edit</button>
      <button v-if="editStatus && showEditAdd" class="btn btn-success" @click="save()">Save</button>
      <button v-if="editStatus && showEditAdd" class="btn btn-default" @click="editStatus=false">Cancel</button>
      <button class="btn btn-primary" @click="openAddContact()">Add</button>
    </template>

    
    <registration-data-info
      ref="contacts"
      :registration="registration"
      hide-basics
      hide-info
      show-remove-contact-button />
    
  <popup :open.sync="addModal" :width="1250">
      <template v-if="addModal">
        <h4>Add Contact</h4>
        <div class="">
          <div class="content-form">
            <ul class="cp-fields" style="margin-top: 10px;">
              <li>
                <span class="form-label">First Name <span v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.firstName" type="text"/>
                <div v-if="$v.addContactForm.firstName.$dirty && $v.addContactForm.firstName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Last Name <span v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid" class="error-message">&nbsp; *</span></span>
                <input v-model="addContactForm.lastName" type="text"/>
                <div v-if="$v.addContactForm.lastName.$dirty && $v.addContactForm.lastName.$invalid"><span class="error-message">This field is required.</span></div>
              </li>
              <li>
                <span class="form-label">Phone <span v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.phone" type="text" />
                  <div v-if="$v.addContactForm.phone.$dirty && $v.addContactForm.phone.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
              <li>
                <span class="form-label">Mobile</span>
                <div>
                  <input v-model="addContactForm.mobile" type="text"/>
                </div>
              </li>
              <li>
                <span class="form-label">Email <span v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid" class="error-message">&nbsp; *</span></span>
                <div>
                  <input v-model="addContactForm.email" type="text"/>
                  <div v-if="$v.addContactForm.email.$dirty && $v.addContactForm.email.$invalid"><span class="error-message">This field is required.</span></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="buttons">
          <button class="main" @click="addContact()">Add</button>
          <button class="second" @click="addModal = false">Cancel</button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import helperMixin from '../../components/common/helper-mixin'
import { alpha, dashNumbers, alphaNum} from '../../lib/validators'
import { required, numeric, email, requiredIf, between } from 'vuelidate/lib/validators'

import RegistrationDataInfo from './RegistrationDataInfo'
import BisinessContact from '../../RegisterForm2BusinessContact'
import VueEnglishdatepicker from 'vue-englishdatepicker'

const contact = {
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
}

export default {
  mixins: [helperMixin],

  components: {
    BisinessContact,
    RegistrationDataInfo,
    VueEnglishdatepicker
  },

  props: {
    registration: {
      type: Object,
      required: true
    },
    showEditAdd: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      form: {},
      editStatus: false,
      addModal: false,
      addContactForm: {},
      editContactForm: {},
    }
  },

  computed: {
    countryOptions () {
      return { ...window.countriesOptions }
    },

    contactCountryOptions () {
      return { ...window.contactCountriesOptions }
    }
  },

  watch: {
    'addContactForm.firstName' (value) {
        this.editNewContact()
    }
  }, 

  methods: {
    async addContact () {
      this.$v.addContactForm.$touch()
      if (this.$v.addContactForm.$invalid) return

      const loader = this.$loading.show()

      const payload = {
        updatedAt: 'CURRENT_TIMESTAMP',
        business: this.editContactForm
      }
      const contact = { ...this.addContactForm }
      
      payload.business.contacts.push(contact)
      try {
        await this.$store.dispatch('partners/update', { id: this.registration.id, payload })
        await this.addNote('Contact added')
        this.$toast.success('Contact added successfully.')
        this.addModal = false
        Object.keys(this.addContactForm).forEach(item => delete this.addContactForm[item])
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    openAddContact () {
      this.$nextTick(() => { this.$v.$reset() })
      this.$set(this, 'addContactForm', { ...contact })
      this.addModal = true
    },

    addNote (notes) {
      return this.$store.dispatch('partner_notes/create', {
        createdBy: window.appData.currentUser.id,
        partnerId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    editNewContact () {
        this.editContactForm = JSON.parse(JSON.stringify(this.registration.business)) 
    },

    edit () {
      this.editStatus = true
      this.$refs.contacts.edit()
    },

    save () {
      this.editStatus = false
      this.$refs.contacts.save()
    },

    stateOptions (country) {
      if (!['CA', 'US'].includes(country)) return null

      return window.states[country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },
  },

  validations: {
    addContactForm: {
      firstName: { required, alphaNum },
      lastName: { required, alphaNum },
      phone: { required, numeric },
      mobile: { numeric },
      email: { required, email },
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
}
.scrollable {
  padding: 20px 0;
}
.error-message {
  color: red !important;
}
.error {
  border: 1px solid red !important;
}
.form-label {
  display: flex !important;
  justify-content: flex-start;
}
.beneficial-owner-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}
.beneficial-owner-percent {
  margin-left: 40px;
}
.beneficial-owner-percent-content {
  background-color: rgb(238, 238, 238);
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
.addContactsInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0 0 0 10px;
  width: 10%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  cursor: #616F7B;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
}
</style>
