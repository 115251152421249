<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Partner Settings</h2>
      <div class="status">
        Status
        <status-icon :color="profileSettings ? 'green' : null" />
      </div>
    </div>
    <table class="content-table">
        <tr>
          <th style="width: 15%;"></th>
          <th style="width: 12%;">Registration Tracking</th>
        </tr>
        <tr class="content-filters">
          <td><span>Verifications</span> Enable</td>
          <td><span>Registration Tracking</span>
            <mnb-switch v-if="!profileSettings" v-model="form.registrationTracking" :validator="$v.form.registrationTracking" />
            <mnb-switch v-else :value="form.registrationTracking == 1" disabled />
          </td>
          <td colspan="2"></td>
        </tr>
        <tr class="space">
          <th style="width: 15%;">Permissions</th>
          <th style="width: 12%;">Create Sub-Users</th>
          <th colspan="2"></th>
        </tr>
        <tr class="content-filters">
          <td><span>Permissions</span> Enable</td>
          <td><span>Create Sub-Users</span>
            <mnb-switch v-if="!profileSettings" v-model="form.create_subusers" :validator="$v.form.create_subusers"/>
            <mnb-switch v-else :value="form.create_subusers == 1" disabled />
          </td>
          <td colspan="2"></td>
        </tr>
        <tr class="space">
          <th style="width: 15%;">Options</th>
          <th style="width: 12%;">Send Welcome Email</th>
          <th style="width: 12%;">Partner Review Days</th>
          <th style="width: 12%;">Profile Language</th>
        </tr>
        <tr class="content-filters">
          <td></td>
          <td><span>Send Welcome/Activation Email</span>
            <mnb-switch v-if="!profileSettings" v-model="form.welcome_email" :validator="$v.form.welcome_email"/>
            <mnb-switch v-else :value="form.welcome_email == 1" disabled />
          </td>
          <td><span>Partner Review Days</span>
            <mnb-select v-if="!profileSettings" v-model="form.review_days" :validator="$v.form.review_days" class="select" :options="reviewOptions" />
            <template v-else>{{ form.review_days }}</template>
          </td>
          <td ><span>Profile Language</span>
            <mnb-select v-if="!profileSettings" v-model="form.language" :validator="$v.form.language" class="select" :options="languageOptions" />
            <template v-else>{{ form.language }}</template>
          </td>
        </tr>
    </table>

    <div class="footer space">
      <span>
        <b>Date / Time:</b><br>
        <template v-if="!profileSettings">N/A</template>
        <template v-else>{{ profileSettings.createdAt | date('LL') }}<br>{{ profileSettings.createdAt | date('LT') }}</template>
      </span>
      <div>
        <button v-if="!profileSettings" class="main" @click="create()">Create</button>
        <button v-else class="second" @click="remove()">Remove</button>
      </div>
    </div>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'
import MnbInput from '../../../components/ui/forms/Input'
import MnbSelect from '../../../components/ui/forms/Select'
import MnbSwitch from '../../../components/ui/forms/Switch'

import { required } from 'vuelidate/lib/validators'
import Templates from '../../transactions/Templates.vue'

const initialForm = {
  registrationTracking: false,
  create_subusers: false,

  welcome_email: false,
  review_days: null,
  language: null,
}

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch,
    StatusIcon
  },

  data () {
    return {
      form: { ...initialForm },

      reviewOptions: [
          {
            id: 30,
            text: 30
          },
          {
            id: 60,
            text: 60
          },
          {
            id: 90,
            text: 90
          },
          {
            id: 180,
            text: 180
          },
          {
            id: 365,
            text: 365
          }
        ],

        languageOptions: [
          {
            id: 'English',
            text: 'English'
          }
        ],
    }
  },

  computed: {
    profileSettings () {
      return this.partner?.settings || null
    },
  },

  async created () {
    if (this.profileSettings) {
      this.form = this.profileSettings
    }
  },

  methods: {
    async create () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const loader = this.$loading.show()
      try {
        
        var current_timestamp = new Date()
        await this.$store.dispatch('partners/update', {
          id: this.partner?.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            settings: {
              ...this.form,
              createdAt: current_timestamp
            }
          }
        })
        
        await this.addNote('Partner Settings created')
        this.$toast.success('Partner Settings successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('partners/update', {
          id: this.partner.id,
          payload: { updatedAt: 'CURRENT_TIMESTAMP', settings: null }
        })
        await this.addNote('Partner Settings removed')
        this.form = { ...initialForm }
        this.$toast.success('Partner Settings successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('partner_notes/create', {
        createdBy: window.appData.currentUser.id,
        partnerId: this.partner.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },
  },

  validations () {
    const rules = {
      form: {
      }
    }

    rules.form.registrationTracking = { required }
    rules.form.create_subusers = { required }
    rules.form.welcome_email = { required }
    rules.form.review_days = { required }
    rules.form.language = { required }
    
    return rules
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}

.checkbox-large {
  width: 17px;
  height: 17px;
}

.money-input {
  display: flex;
  span {
    display: inline-block;
    width: 15px;
    line-height: 29px;
  }
  .input {
    width: 100px;
  }
}
.select {
  width: 115px;
}

.space {
  td, th {
    padding-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 0 34px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.profile-settings {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
</style>
