<template>
  <div class="cp-box">
    <ul class="cp-rows active">
      <li>
        <ul class="cp-fields">
          <li>
            <span>Client Since</span>
            <div class="val">{{ registration.createdAt | date('LL') }}</div>
          </li>
          <li>
            <span>Profile Type</span>
            <div class="val">{{ registration.type }}</div>
          </li>
          <li>
            <span>Profile Number</span>
            <div class="val">
              {{ registration.profileNumber }}
            </div>
          </li>
          <li v-if="registration.partner">
            <span>Partner</span>
            <div class="val">
              {{ registration.partner.partnerNumber }} - {{ registration.partner.business.legal_name }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  
  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  }
}
</script>
