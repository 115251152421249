<template>
  <div class="cp-box">
    <ul class="cp-rows active">
      <li>
        <button class="btn btn-success" @click="showCreate = true">Add New</button>
      </li>
      <li class="boxed-plist">
        <h4>Profile Notes</h4>
        <br>
        <ul class="cp-fields profile-lists">
          <li>
            <span>Date / Time</span>
          </li>
          <li>
            <span>Added By</span>
          </li>
          <li>
            <span>Note</span>
          </li>
        </ul>
        <ul v-for="note in notes" :key="note.id" class="cp-fields profile-lists">
          <li>
            {{ note.createdAt | date }}
          </li>
          <li>
            {{ note.addedBy }}
          </li>
          <li class="note-area">
            {{ note.notes }}
          </li>
        </ul>
      </li>

      <li v-if="showCreate">
        <h4>Add New Note</h4>
        <ul class="cp-fields">
          <textarea rows="5" class="textarea-profile-notes" v-model="text"></textarea>
        </ul>
        <ul class="cp-fields">
          <li>
            <button class="btn btn-success" @click="saveEmployeeNotes()">Save</button>
            <button class="btn btn-warning" @click="cancel()">Cancel</button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      text: null,
      showCreate: false
    }
  },

  computed: {
    id () {
      return this.employee?.id || null
    },

    notes () {
      return (this.$store.state.employeenotes.data || []).filter(note => parseInt(note.employeeId) === parseInt(this.id))
    }
  },

  methods: {
    async saveEmployeeNotes () {
      await this.$store.dispatch('employeenotes/create', {
        employeeId: this.id,
        notes: this.text,
        createdAt: 'CURRENT_TIMESTAMP'
      })
      this.cancel()
    },

    cancel () {
      this.showCreate = false
      this.text = null
    }
  }
}
</script>
