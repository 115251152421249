<template>
  <div>
    <section v-if="mode=='Overview'">
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters v-if="mode=='Overview'" :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div class="accordion" v-if="mode=='Overview'">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date <img src="/assets/img/updown.png" width="13" @click="sort('createdAt')"/></th>
              <!--<th>Type</th>-->
              <th>Profile</th>
              <th style="width: 10%;">Name</th>
              <th>Type</th>
              <th style="width: 10%;">Account</th>
              <th v-if="!partner">Provider</th>
              <th v-if="!partner">Provider Account</th>
              <!--<th>CIP</th>
              <th>AML</th>
              <th>KYC</th>-->
              <th v-if="!partner">Result</th>
              <th>Updated</th>
              <th>Status</th>
              <th v-if="!partner" style="width: 15%;">Options</th>
            </tr>
            <tr v-for="registration in filteredData" :key="registration.id">
              <td><span>Registration Date</span>
                {{ registration.createdAt | date('MM/D/YYYY') }}<br/>
                {{ registration.createdAt | date('hh:mm A') }}
              </td>
              <!--<td><span>Type</span></td>-->
              <td><span>Registration</span>
                <a v-if="!partner" :href="`/system-customers/registrations/${registration.id}`">{{ registration.profileNumber || registration.regId }}</a>
                <template v-else>{{ registration.profileNumber || registration.regId }}</template>
              </td>
              <td>
                <span>Name</span>
                <template v-if="registration.data">
                  <template v-if="registration.type === 'Personal'">{{ registration.data.firstName }} {{ registration.data.lastName }}</template>
                  <template v-else>{{ registration.data.legal_name }}</template>
                </template>
              </td>
              <td><span>Profile Type</span>{{ registration.type }}</td>
              <td><span>Account</span><template v-if="registration.mainAccount">{{ registration.mainAccount.accountNumber }}</template></td>
              <td v-if="!partner"><span>Provider</span><template v-if="registration.serviceProviders && registration.serviceProviders.provider">{{ registration.serviceProviders.provider.provider_name }}</template></td>
              <td v-if="!partner"><span>Provider Account</span>
                <template v-if="registration.accounts[registration.accounts.length-1]">
                  <template v-if="registration.accounts[registration.accounts.length-1].serviceProviders.provider_name == 'Solid' || registration.accounts[registration.accounts.length-1].serviceProviders.provider_name == 'Solid Test'">
                    <template v-if="registration.accounts[registration.accounts.length-1].solidAccount[0]">{{ registration.accounts[registration.accounts.length-1].solidAccount[0].accountNumber }}</template>
                  </template>
                  <template v-else>
                    <template v-if="!registration.accounts[registration.accounts.length-1].complianceScan"></template>
                    <template v-else>{{ registration.accounts[registration.accounts.length-1].complianceScan.accountNumber }}</template>
                  </template>
                </template>
              </td>
              <!--<td><span>CIP</span><template v-if="registration.complianceScan">{{ registration.complianceScan.cipStatus }}</template></td>
              <td><span>AML</span><template v-if="registration.complianceScan">{{ registration.complianceScan.amlStatus }}</template></td>
              <td><span>KYC</span><template v-if="registration.complianceScan">{{ registration.complianceScan.kycStatus }}</template></td>-->
              <td v-if="!partner"><span>Result</span>
                <template v-if="registration.accounts[registration.accounts.length-1]">
                  <template v-if="registration.accounts[registration.accounts.length-1].serviceProviders.provider_name == 'Solid' || registration.accounts[registration.accounts.length-1].serviceProviders.provider_name == 'Solid Test'">
                    <template v-if="registration.accounts[registration.accounts.length-1].solidAccount[0]">{{ statusColorText[registration.accounts[registration.accounts.length-1].solidAccount[0].accountStatus] }}</template>
                  </template>
                  <template v-else>
                    <template v-if="!registration.accounts[registration.accounts.length-1].complianceScan"></template>
                    <template v-else>{{ registration.accounts[registration.accounts.length-1].complianceScan.result }}</template>
                  </template>
                </template>
              </td>
              <td><span>Last Update</span>
                <template v-if="registration.accounts[registration.accounts.length-1]">
                  <template v-if="!registration.accounts[registration.accounts.length-1].complianceScan"></template>
                  <template v-else>
                    {{ registration.accounts[registration.accounts.length-1].complianceScan.createdAt | date('MM/D/YYYY') }}<br/>
                    {{ registration.accounts[registration.accounts.length-1].complianceScan.createdAt | date('hh:mm A') }}
                  </template>
                </template>
              </td>
              <td><span>Status</span><em :class="statusColorClasses[registration.status]">{{ registration.status }}</em></td>
              <td v-if="!partner"><span>Options</span>
                <!--<button v-if="registration.status === 'Archived'" class="action-button cancel" @click="unarchive(registration.id)">Unarchive</button>-->
                <template v-if="registration.status === 'Denied'">
                  <button class="action-button cancel" @click="archive(registration.id)">Archive</button>
                </template>
                <template v-else-if="registration.status !== 'Archived' && oneMonthBefore(registration)">
                  <button class="action-button cancel" @click="archive(registration.id)">Archive</button>
                </template>
                <template v-if="$hasPerm('reject')">
                  <button v-if="registration.status === 'New' || registration.status === 'In Process'" class="action-button remove" @click="reject(registration.id)">Reject</button>
                </template>
              </td>
            </tr>
            <tr v-if="!registrations.length">
              <td colspan="13" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>
    <RegisterForm2 :internal="true" v-else-if="mode=='New'"></RegisterForm2>

    <registrations-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import RegistrationsFilters from './filters/RegistrationsFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'

  export default {
    name: 'CustomerRegistrations',

    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      AppliedFilters,
      RegistrationsFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,

      activeInfo: true,
      mode: "Overview",

      loading: false,
      page: 1,
      perPage: 25,
      pages: [],

      sortName: null,
      sortFlag: true,
    }),

    computed: {
      appliedFilters () {
        const filterNames = {
          profileNumber: 'Profile Number',
          name: 'Name',
          type: 'Profile Type',
          status: 'Status'
        }
        
        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.filters[key]}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },
      customerStatuses () {
        return window.customerStatuses
      },

      totalNumber() {
        return this.registrations.length
      },

      currentUser () {
        return window.appData.currentUser
      },

      partner () {
        return this.$store.getters['partners/get'](this.currentUser.parId)
      },
      
      registrations () {
        let registrations = this.$store.state.registrations.data || []

        if (!this.filters || !Object.values(this.filters).filter(val => val).length) return registrations

        if (this.filters.name) {
          registrations = registrations.filter(reg => {
            const name = reg.type === 'Personal' ? `${reg.data.firstName} ${reg.data.lastName}` : reg.data.legal_name
            return name.toLowerCase().includes(this.filters.name.toLowerCase())
          })
        }

        if (this.filters.fromDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          registrations = registrations.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date >= from
          })
        }

        if (this.filters.toDate) {
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          registrations = registrations.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date <= to
          })
        }

        if (this.filters.uuid) {
          registrations = registrations.filter(reg => parseInt(reg.regId) === parseInt(this.filters.uuid))
        }

        if (this.filters.profileNumber) {
          registrations = registrations.filter(reg => parseInt(reg.profileNumber) === parseInt(this.filters.profileNumber))
        }

        if (this.filters.type) {
          registrations = registrations.filter(reg => reg.type === this.filters.type)
        }

        if (this.filters.currency) {
          registrations = registrations.filter(reg => reg.currency === this.filters.currency)
        }
        return registrations
      },

      statusColorClasses () {
        return {
          New: 'c1',
          Pending: 'c2',
          'In Process': 'c2',
          Denied: 'c4',
          Complete: 'c3',
          Active: 'c1'
        }
      },

      statusColorText () {
        return {
          approved: 'Approved',
          inReview: 'In Review',
          declined: 'Declined',
          active: 'Active',
          submitted: 'Submitted',
        }
      },

      filteredData () {
        return this.paginate(this.registrations);
      }
    },

    async created () {
      if(this.currentUser.parId) {
        await this.$store.dispatch('partners/get', this.currentUser.parId)
      }
      await this.refreshRegistrations()
    },

    methods: {
      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      clearFilters () {
        this.filters = null
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      oneMonthBefore (reg){
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 30)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")

        const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
        if(date >= now) {
          return false
        }else{
          return true
        }
      },

      async setNew (id) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: { status: 'New' }
        })
        await this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: id,
          notes: 'Registration Status Updated: New',
          createdAt: 'CURRENT_TIMESTAMP'
        })
        loader.hide()
      },

      async archive (id, archive = true) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: {
            status: archive ? 'Archived' : 'Denied'
          }
        })
        loader.hide()
      },

      async unarchive (id) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: {
            status: 'Pending'
          }
        })
        loader.hide()
      },

      async reject (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('registrations/update', {
            id,
            payload: {
              status: 'Denied'
            }
          })
          await this.$store.dispatch('profilenotes/create', {
            createdBy: window.appData.currentUser.id,
            registrationId: id,
            notes: 'Registration Rejected',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          await this.refreshRegistrations()
          loader.hide()
        }
      },

      search () {
        this.filters = { ...this.form }
      },

      resetItem() {
        this.filters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async refreshRegistrations () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        params.corporate = ['!=', '1']

        if(this.partner) {
          params.partnerId = this.partner.id
        }
        
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
          params.status=["OR", "New", "Pending", "Denied", "In Process"];
        } else {
          if (this.filters.status)
            params.status=this.filters.status;
        }

        let order = []
        if(this.sortName != null) {
          if(this.sortFlag) {
            order = [this.sortName + ':ASC']
          }else{
            order = [this.sortName + ':DESC']
          }
        }else{
          order = ['ID:ASC']
        }
        await this.$store.dispatch('registrations/load', {order: order, params, skip: ['user', 'contact', 'fees', 'terms', 'confirmation', 'documentsChecklist', 'documents', 'profileSettings', 'regName', 'regCorp', 'regType', 'regAdministrative', 'regReviewDays']}, )
        this.loading=false;
        this.page = 1
        loader.hide()
      },

      sort(sortName) {
        if(this.sortName == sortName){
          this.sortFlag = !this.sortFlag
        }else {
          this.sortName = sortName
          this.sortFlag = true
        }
      },

      setPages () {
        let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },
    },

    watch: {
      appliedFilters () {
        this.refreshRegistrations();
      },

      sortName () {
        this.refreshRegistrations();
      },

      sortFlag () {
        this.refreshRegistrations();
      },

      filteredData () {
        this.setPages();
      },

      page() {
        this.setPages();
      },

      registrations () {
        this.setPages();
      },

      perPage() {
        if(this.page == 1){
          this.setPages();
        }else{
          this.page = 1
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .content-tlist {
    border: none;
  }
</style>
