<template>
<div class="status-page">
  <div class="ss-head">
    <div>
      <h1>System Status</h1>
      <div class="next-schedule" style="">
        <label style="font-weight:bold">Scheduled maintenance or downtime:</label>
        <br/>
        <p style="font-size:14px">{{ scheduled || "no downtime scheduled" }}</p>
      </div>
    </div>
  </div>
  <div class="status-list">
    <ul>
      <li v-for="(item, index) in items" :class="{opened: opened[index]}">
        <div v-on:click.prevent="toggleOpened(index)">
          <a class="toggle" href="">
            <!--{{ opened[index]?'-':'+' }}-->
            {{ item.title }}
          </a>
        </div>
        <ul v-if="opened[index]">
          <li v-for="(subitem, si) in item.items">
            <div>{{ subitem.name }}</div>
            <div>{{ subitem.message }}</div>
            <div class="color" :class="subitem.color"></div>
            <div class="err" v-if="subitem.downtime">{{ subitem.downtime }}</div>
            <div v-else>Operational</div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    scheduled: null,
    opened: [],
  }},
  methods: {
    toggleOpened(index) {
      this.$set(this.opened, index, !this.opened[index]);
    },
    async loadItems() {
      var statusData=appData.status;
      this.items=statusData.items;
      this.scheduled=statusData.scheduled;
      this.opened=this.items.map(a=>false);
    },
  },
   mounted() {
    this.loadItems();
   },
}
</script>
