<template>
  <header id="header">
    <ul class="quick-action">
      <li>
        <el-popover
          ref="popover"
          placement="bottom"
          width="500"
          trigger="click">
          <div class="messages">
            <system-message
              v-for="message in latestMessages"
              :key="message.id"
              :message="message" />

                    
            <el-empty
              v-if="!latestMessages.length"
              :image-size="50"
              description="There are no new messages." />

            <div
              class="see-all">
              <a
                href="/system-messages"
                class="cursor-pointer">
                View all
              </a>
            </div>
          </div>

          <template #reference>
            <span>
              <el-badge
                v-if="unread"
                :value="unread">
                <i class="el-icon-chat-line-square" />
              </el-badge>
              <i
                v-else
                class="el-icon-chat-line-square" />
            </span>
          </template>
        </el-popover>
      </li>
      <li>
        <el-tooltip effect="dark" content="Notifications" placement="bottom">
          <i class="el-icon-bell" />
        </el-tooltip>
      </li>
      <li>
        <a href="/support">
          <el-tooltip effect="dark" content="Support" placement="bottom">
            <i class="el-icon-help" />
          </el-tooltip>
        </a>
      </li>
    </ul>
  </header>
</template>

<script>
import SystemMessage from '../system-messages/SystemMessage'

export default {
  name: 'main-header',

  components: {
    SystemMessage
  },

  data () {
    return {
      type: 'SYSTEM_MESSAGES',
      maxMessages: 5
    }
  },

  computed: {
    unread () {
      return this.messages.filter(message => !message.read).length
    },

    read () {
      const data = this.$store.state.readed.data || []
      return data.filter(item => item.itemType === this.type)
    },

    discarded () {
      const data = this.$store.state.discarded.data || []
      return data.filter(item => item.itemType === this.type)
    },

    messages () {
      const messages = this.$store.state.systemMessage.data || []
      return messages.slice().sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      }).map(message => {
        return {
          ...message,
          read: this.read.some(read => parseInt(read.itemId) === parseInt(message.id)),
          discarded: this.discarded.some(discarded => parseInt(discarded.itemId) === parseInt(message.id)),
        }
      }).filter(message => !message.archived && !message.discarded && !message.read)
    },

    latestMessages () {
      return this.messages.slice(0, this.maxMessages);
    }
  },

  created () {
    this.$store.commit('systemMessage/set', window.systemMessage)
    this.$store.commit('readed/set', window.readed)
    this.$store.commit('discarded/set', window.discarded)
  }
}
</script>

<style lang="scss" scoped>
  .see-all {
    text-align: center;
    margin-top: 10px;
    color: #13ce67;
  }
</style>
