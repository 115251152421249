<template>
  <div>
    <template v-if="open">
      <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

      <section class="content-filters">
        <div class="cof-box">
          <div class="cof-form">
            <div class="cof-row">
              <div class="cof-field">
                <label>Select Account</label>
                <mnb-select v-model="mainAccount" :options="accountOptions"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div v-if="perms && mainAccount" class="accordion">
          <section  class="content-tlist">
            <table class="content-table">
              <tr>
                <th v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">
                  Prime Linked
                </th>
                <th v-else-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">
                  Solid Linked
                </th>
                <th v-else>
                  Linked
                </th>
                <th v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">
                  Prime Fees
                </th>
                <th v-else-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">
                  Solid Fees
                </th>
                <th v-else>
                  Fees
                </th>
                <th>Name</th>
                <th>Account</th>
                <th>Type</th>
                <th>Currency</th>
                <th v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">Reference Code</th>
                <th v-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">Routing Number</th>
                <th>Status</th>
                <th>Available</th>
                <th>Balance</th>
                <th>Account Date</th>
                <th>Options</th>
              </tr>
              <tr>
                <td v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">
                  <span>Linked</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.linked" @change="checkLinked(account.id)">
                </td>
                <td v-else-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">
                  <span>Linked</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.solidLinked" @change="checkSolidLinked(account.id)">
                </td>
                <td v-else>
                  <span>Linked</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.linked" @change="checkLinked(account.id)">
                </td>
                <td v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">
                  <span>Fees</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.fees" @change="checkFees(account.id)">
                </td>
                <td v-else-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">
                  <span>Fees</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.solidFees" @change="checkSolidFees(account.id)">
                </td>
                <td v-else>
                  <span>Fees</span>
                  <input type="checkbox" class="fee-checkbox" v-model="account.fees" @change="checkFees(account.id)">
                </td>
                <td>
                  <span>Name</span>
                  {{ account.accountName }}
                </td>
                <td><span>Account ID</span>
                  {{ account.accountNumber }}
                </td>
                <td><span>Account Type</span>{{ typeText[account.accountType] }}</td>
                <td><span>Currency</span>{{ account.currency }}</td>
                <td><span>Reference Code</span>
                  <template v-if="account.serviceProviders.provider_name == 'Prime Trust Test' || account.serviceProviders.provider_name == 'Prime Trust'">{{ account.QPaymentReference }}</template>
                  <template v-if="account.serviceProviders.provider_name == 'Solid Test' || account.serviceProviders.provider_name == 'Solid'">{{ account.routingNumber }}</template>
                </td>
                <td><span>Account Status</span><em :class="statusColorClasses[getStatus(account.accountStatus)]">
                  {{ getStatus(account.accountStatus) }}</em></td>
                <td><span>Available</span>{{ account.systemAvailableBalance | balance }}</td>
                <td><span>Balance</span>{{ account.systemTotalBalance | balance }}</td>
                <td><span>Account Date</span>{{ account.createdAt | date('MM/D/YYYY') }}</td>
                <td><span>Options</span>
                  <template v-if="systemMaintenanceStatus">
                    <button v-if="account.accountStatus == 'Active'" class="action-button" @click="generateQReference(account)">Q Reference</button>
                    <button v-if="account.accountStatus == 'Active'" class="action-button save">Account Name</button>
                  </template>
                </td>
              </tr>
              <template v-if="!featureFundsStatus">
                <tr>
                  <td colspan="12">
                    This feature is not enabled. Please contact system administration in order to have it enabled. Feature - Multi-Currency
                  </td>
                </tr>
              </template>
              <template v-if="account.serviceProviders.multiCurrency == 1">
                <template v-if="getCurrencyName('AUD') != null">
                  <template v-if="!featureFundsStatus">
                    <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td><span>Account ID</span>
                        </td>
                        <td><span>Account Type</span></td>
                        <td><span>Currency</span>AUD</td>
                        <td><span>Reference Code</span></td>
                        <td><span>Account Status</span>
                          <template v-if="!featureFundsStatus">
                            Disabled
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                            {{ getCurrencyStatus(account.fundsCurrencies, 'AUD') ? 'Enabled' : 'Disabled' }}
                          </template>
                        </td>
                        <td><span>Available</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                            {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsAvailableBalance(account, 'AUD')? getSystemFundsAvailableBalance(account, 'AUD') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')">
                            {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsAvailableBalance(account, 'AUD')? getSystemFundsAvailableBalance(account, 'AUD') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Balance</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                            {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsTotalBalance(account, 'AUD')? getSystemFundsTotalBalance(account, 'AUD') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')">
                            {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsTotalBalance(account, 'AUD')? getSystemFundsTotalBalance(account, 'AUD') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Account Date</span></td>
                        <td><span>Options</span>
                          <template v-if="featureFundsStatus">
                            <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') == null">
                              <button class="btn btn-success" :disabled="!getCurrencyName('AUD')" @click="activateCurrency(account.id, 'AUD')" >Activate</button>
                            </template>
                            <template v-else>
                              <button v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'AUD')">Disable</button>
                              <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'AUD')">Enable</button>
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span>Name</span>
                      </td>
                      <td><span>Account ID</span>
                      </td>
                      <td><span>Account Type</span></td>
                      <td><span>Currency</span>AUD</td>
                      <td><span>Reference Code</span></td>
                      <td><span>Account Status</span>
                        <template v-if="!featureFundsStatus">
                          Disabled
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                          {{ getCurrencyStatus(account.fundsCurrencies, 'AUD') ? 'Enabled' : 'Disabled' }}
                        </template>
                      </td>
                      <td><span>Available</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                          {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsAvailableBalance(account, 'AUD')? getSystemFundsAvailableBalance(account, 'AUD') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')">
                          {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsAvailableBalance(account, 'AUD')? getSystemFundsAvailableBalance(account, 'AUD') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Balance</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') != null">
                          {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsTotalBalance(account, 'AUD')? getSystemFundsTotalBalance(account, 'AUD') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')">
                          {{ getFundsCurrencySymbol(account, 'AUD') }} {{ getSystemFundsTotalBalance(account, 'AUD')? getSystemFundsTotalBalance(account, 'AUD') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Account Date</span></td>
                      <td><span>Options</span>
                        <template v-if="featureFundsStatus">
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD') == null">
                            <button class="btn btn-success" :disabled="!getCurrencyName('AUD')" @click="activateCurrency(account.id, 'AUD')" >Activate</button>
                          </template>
                          <template v-else>
                            <button v-if="getCurrencyStatus(account.fundsCurrencies, 'AUD')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'AUD')">Disable</button>
                            <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'AUD')">Enable</button>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-if="getCurrencyName('CAD') != null">
                  <template v-if="!featureFundsStatus">
                    <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td><span>Account ID</span>
                        </td>
                        <td><span>Account Type</span></td>
                        <td><span>Currency</span>CAD</td>
                        <td><span>Reference Code</span></td>
                        <td><span>Account Status</span>
                          <template v-if="!featureFundsStatus">
                            Disabled
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                            {{ getCurrencyStatus(account.fundsCurrencies, 'CAD') ? 'Enabled' : 'Disabled' }}
                          </template>
                        </td>
                        <td><span>Available</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                            {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsAvailableBalance(account, 'CAD')? getSystemFundsAvailableBalance(account, 'CAD') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')">
                            {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsAvailableBalance(account, 'CAD')? getSystemFundsAvailableBalance(account, 'CAD') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Balance</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                            {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsTotalBalance(account, 'CAD')? getSystemFundsTotalBalance(account, 'CAD') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')">
                            {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsTotalBalance(account, 'CAD')? getSystemFundsTotalBalance(account, 'CAD') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Account Date</span></td>
                        <td><span>Options</span>
                          <template v-if="featureFundsStatus">
                            <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') == null">
                              <button class="btn btn-success" :disabled="!getCurrencyName('CAD')" @click="activateCurrency(account.id, 'AUD')" >Activate</button>
                            </template>
                            <template v-else>
                              <button v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'CAD')">Disable</button>
                              <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'CAD')">Enable</button>
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span>Name</span>
                      </td>
                      <td><span>Account ID</span>
                      </td>
                      <td><span>Account Type</span></td>
                      <td><span>Currency</span>CAD</td>
                      <td><span>Reference Code</span></td>
                      <td><span>Account Status</span>
                        <template v-if="!featureFundsStatus">
                          Disabled
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                          {{ getCurrencyStatus(account.fundsCurrencies, 'CAD') ? 'Enabled' : 'Disabled' }}
                        </template>
                      </td>
                      <td><span>Available</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                          {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsAvailableBalance(account, 'CAD')? getSystemFundsAvailableBalance(account, 'CAD') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')">
                          {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsAvailableBalance(account, 'CAD')? getSystemFundsAvailableBalance(account, 'CAD') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Balance</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') != null">
                          {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsTotalBalance(account, 'CAD')? getSystemFundsTotalBalance(account, 'CAD') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')">
                          {{ getFundsCurrencySymbol(account, 'CAD') }} {{ getSystemFundsTotalBalance(account, 'CAD')? getSystemFundsTotalBalance(account, 'CAD') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Account Date</span></td>
                      <td><span>Options</span>
                        <template v-if="featureFundsStatus">
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD') == null">
                            <button class="btn btn-success" :disabled="!getCurrencyName('CAD')" @click="activateCurrency(account.id, 'AUD')" >Activate</button>
                          </template>
                          <template v-else>
                            <button v-if="getCurrencyStatus(account.fundsCurrencies, 'CAD')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'CAD')">Disable</button>
                            <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'CAD')">Enable</button>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-if="getCurrencyName('EUR') != null">
                  <template v-if="!featureFundsStatus">
                    <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td><span>Account ID</span>
                        </td>
                        <td><span>Account Type</span></td>
                        <td><span>Currency</span>EUR</td>
                        <td><span>Reference Code</span></td>
                        <td><span>Account Status</span>
                          <template v-if="!featureFundsStatus">
                            Disabled
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                            {{ getCurrencyStatus(account.fundsCurrencies, 'EUR') ? 'Enabled' : 'Disabled' }}
                          </template>
                        </td>
                        <td><span>Available</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                            {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsAvailableBalance(account, 'EUR')? getSystemFundsAvailableBalance(account, 'EUR') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')">
                            {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsAvailableBalance(account, 'EUR')? getSystemFundsAvailableBalance(account, 'EUR') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Balance</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                            {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsTotalBalance(account, 'EUR')? getSystemFundsTotalBalance(account, 'EUR') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')">
                            {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsTotalBalance(account, 'EUR')? getSystemFundsTotalBalance(account, 'EUR') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Account Date</span></td>
                        <td><span>Options</span>
                          <template v-if="featureFundsStatus">
                            <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') == null">
                              <button class="btn btn-success" :disabled="!getCurrencyName('EUR')" @click="activateCurrency(account.id, 'EUR')" >Activate</button>
                            </template>
                            <template v-else>
                              <button v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'EUR')">Disable</button>
                              <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'EUR')">Enable</button>
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span>Name</span>
                      </td>
                      <td><span>Account ID</span>
                      </td>
                      <td><span>Account Type</span></td>
                      <td><span>Currency</span>EUR</td>
                      <td><span>Reference Code</span></td>
                      <td><span>Account Status</span>
                        <template v-if="!featureFundsStatus">
                          Disabled
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                          {{ getCurrencyStatus(account.fundsCurrencies, 'EUR') ? 'Enabled' : 'Disabled' }}
                        </template>
                      </td>
                      <td><span>Available</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                          {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsAvailableBalance(account, 'EUR')? getSystemFundsAvailableBalance(account, 'EUR') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')">
                          {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsAvailableBalance(account, 'EUR')? getSystemFundsAvailableBalance(account, 'EUR') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Balance</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') != null">
                          {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsTotalBalance(account, 'EUR')? getSystemFundsTotalBalance(account, 'EUR') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')">
                          {{ getFundsCurrencySymbol(account, 'EUR') }} {{ getSystemFundsTotalBalance(account, 'EUR')? getSystemFundsTotalBalance(account, 'EUR') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Account Date</span></td>
                      <td><span>Options</span>
                        <template v-if="featureFundsStatus">
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR') == null">
                            <button class="btn btn-success" :disabled="!getCurrencyName('EUR')" @click="activateCurrency(account.id, 'EUR')" >Activate</button>
                          </template>
                          <template v-else>
                            <button v-if="getCurrencyStatus(account.fundsCurrencies, 'EUR')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'EUR')">Disable</button>
                            <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'EUR')">Enable</button>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-if="getCurrencyName('GBP') != null">
                  <template v-if="!featureFundsStatus">
                    <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td><span>Account ID</span>
                        </td>
                        <td><span>Account Type</span></td>
                        <td><span>Currency</span>
                          GBP
                        </td>
                        <td><span>Reference Code</span></td>
                        <td><span>Account Status</span>
                          <template v-if="!featureFundsStatus">
                            Disabled
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                            {{ getCurrencyStatus(account.fundsCurrencies, 'GBP') ? 'Enabled' : 'Disabled' }}
                          </template>
                        </td>
                        <td><span>Available</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                            {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsAvailableBalance(account, 'GBP')? getSystemFundsAvailableBalance(account, 'GBP') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')">
                            {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsAvailableBalance(account, 'GBP')? getSystemFundsAvailableBalance(account, 'GBP') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Balance</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                            {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsTotalBalance(account, 'GBP')? getSystemFundsTotalBalance(account, 'GBP') : '' | balance_without_symbol }}
                          </template>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')">
                            {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsTotalBalance(account, 'GBP')? getSystemFundsTotalBalance(account, 'GBP') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Account Date</span></td>
                        <td><span>Options</span>
                          <template v-if="featureFundsStatus">
                            <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') == null">
                              <button class="btn btn-success" :disabled="!getCurrencyName('GBP')" @click="activateCurrency(account.id, 'GBP')" >Activate</button>
                            </template>
                            <template v-else>
                              <button v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'GBP')">Disable</button>
                              <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'GBP')">Enable</button>
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span>Name</span>
                      </td>
                      <td><span>Account ID</span>
                      </td>
                      <td><span>Account Type</span></td>
                      <td><span>Currency</span>
                        GBP
                      </td>
                      <td><span>Reference Code</span></td>
                      <td><span>Account Status</span>
                        <template v-if="!featureFundsStatus">
                          Disabled
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                          {{ getCurrencyStatus(account.fundsCurrencies, 'GBP') ? 'Enabled' : 'Disabled' }}
                        </template>
                      </td>
                      <td><span>Available</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                          {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsAvailableBalance(account, 'GBP')? getSystemFundsAvailableBalance(account, 'GBP') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')">
                          {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsAvailableBalance(account, 'GBP')? getSystemFundsAvailableBalance(account, 'GBP') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Balance</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') != null">
                          {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsTotalBalance(account, 'GBP')? getSystemFundsTotalBalance(account, 'GBP') : '' | balance_without_symbol }}
                        </template>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')">
                          {{ getFundsCurrencySymbol(account, 'GBP') }} {{ getSystemFundsTotalBalance(account, 'GBP')? getSystemFundsTotalBalance(account, 'GBP') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Account Date</span></td>
                      <td><span>Options</span>
                        <template v-if="featureFundsStatus">
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP') == null">
                            <button class="btn btn-success" :disabled="!getCurrencyName('GBP')" @click="activateCurrency(account.id, 'GBP')" >Activate</button>
                          </template>
                          <template v-else>
                            <button v-if="getCurrencyStatus(account.fundsCurrencies, 'GBP')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'GBP')">Disable</button>
                            <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'GBP')">Enable</button>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-if="getCurrencyName('JPY') != null">
                  <template v-if="!featureFundsStatus">
                    <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <span>Name</span>
                        </td>
                        <td><span>Account ID</span>
                        </td>
                        <td><span>Account Type</span></td>
                        <td><span>Currency</span>JPY</td>
                        <td><span>Reference Code</span></td>
                        <td><span>Account Status</span>
                          <template v-if="!featureFundsStatus">
                            Disabled
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                            {{ getCurrencyStatus(account.fundsCurrencies, 'JPY') ? 'Enabled' : 'Disabled' }}
                          </template>
                        </td>
                        <td><span>Available</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                            {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsAvailableBalance(account, 'JPY')? getSystemFundsAvailableBalance(account, 'JPY') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')">
                            {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsAvailableBalance(account, 'JPY')? getSystemFundsAvailableBalance(account, 'JPY') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Balance</span>
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                            {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsTotalBalance(account, 'JPY')? getSystemFundsTotalBalance(account, 'JPY') : '' | balance_without_symbol }}
                          </template>
                          <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')">
                            {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsTotalBalance(account, 'JPY')? getSystemFundsTotalBalance(account, 'JPY') : '' | balance_without_symbol }}
                          </template>
                        </td>
                        <td><span>Account Date</span></td>
                        <td><span>Options</span>
                          <template v-if="featureFundsStatus">
                            <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') == null">
                              <button class="btn btn-success" :disabled="!getCurrencyName('JPY')" @click="activateCurrency(account.id, 'JPY')" >Activate</button>
                            </template>
                            <template v-else>
                              <button v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'JPY')">Disable</button>
                              <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'JPY')">Enable</button>
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <span>Name</span>
                      </td>
                      <td><span>Account ID</span>
                      </td>
                      <td><span>Account Type</span></td>
                      <td><span>Currency</span>JPY</td>
                      <td><span>Reference Code</span></td>
                      <td><span>Account Status</span>
                        <template v-if="!featureFundsStatus">
                          Disabled
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                          {{ getCurrencyStatus(account.fundsCurrencies, 'JPY') ? 'Enabled' : 'Disabled' }}
                        </template>
                      </td>
                      <td><span>Available</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                          {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsAvailableBalance(account, 'JPY')? getSystemFundsAvailableBalance(account, 'JPY') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')">
                          {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsAvailableBalance(account, 'JPY')? getSystemFundsAvailableBalance(account, 'JPY') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Balance</span>
                        <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') != null">
                          {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsTotalBalance(account, 'JPY')? getSystemFundsTotalBalance(account, 'JPY') : '' | balance_without_symbol }}
                        </template>
                        <template v-else-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')">
                          {{ getFundsCurrencySymbol(account, 'JPY') }} {{ getSystemFundsTotalBalance(account, 'JPY')? getSystemFundsTotalBalance(account, 'JPY') : '' | balance_without_symbol }}
                        </template>
                      </td>
                      <td><span>Account Date</span></td>
                      <td><span>Options</span>
                        <template v-if="featureFundsStatus">
                          <template v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY') == null">
                            <button class="btn btn-success" :disabled="!getCurrencyName('JPY')" @click="activateCurrency(account.id, 'JPY')" >Activate</button>
                          </template>
                          <template v-else>
                            <button v-if="getCurrencyStatus(account.fundsCurrencies, 'JPY')" class="btn btn-secondary" @click="disableCurrency(account.fundsCurrencies, 'JPY')">Disable</button>
                            <button v-else class="btn btn-success" @click="enableCurrency(account.fundsCurrencies, 'JPY')">Enable</button>
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
              <template v-if="account.serviceProviders.provider_name == 'Solid' || account.serviceProviders.provider_name == 'Solid Test'"></template>
              <template v-else-if="account.serviceProviders.assets">
                <tr v-if="featureStatus">
                  <td></td>
                  <td></td>
                  <td colspan="10">
                    <b class="assets-label">ASSETS</b>
                  </td>
                </tr>
                <tr v-if="!featureStatus">
                  <td></td>
                  <td>
                    <b class="assets-label">ASSETS</b>
                  </td>
                  <td colspan="9">
                    This feature is not enabled. Please contact system administration in order to have it enabled. Feature - Digital Assets
                  </td>
                </tr>
                <template v-if="featureStatus">
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="1">
                      Currency
                    </td>
                    <td colspan="1">
                      Name
                    </td>
                    <td colspan="1">
                      Tag Required
                    </td>
                    <td colspan="1">
                      Tag
                    </td>
                    <td colspan="2">
                      Wallet Address
                    </td>
                    <td>
                      Available
                    </td>
                    <td colspan="2">
                      Balance
                    </td>
                    <td colspan="1">
                      Options
                    </td>
                  </tr>
                  <tr v-for="item in assetsList">
                    <td></td>
                    <td></td>
                    <td colspan="1"><span>Currency</span>
                      {{ item }}
                    </td>
                    <td colspan="1"><span>Name</span>
                      {{ getCurrencyName(item) }}
                    </td>
                    <td colspan="1"><span>Tag Required</span>
                      {{ getTagRequired(item) }}
                    </td>
                    <td colspan="1"><span>Tag</span>
                      {{ getTag(account, item) }}
                    </td>
                    <td colspan="2"><span>Wallet Address</span>
                      {{ getIncomingAssetWalletAddress(account, item) }}
                    </td>
                    <td><span>Available Balance</span>
                      {{ getAssetsSystemAssetAvailableBalance(account, item)? parseFloat(getAssetsSystemAssetAvailableBalance(account, item)) : ''}}
                    </td>
                    <td colspan="2"><span>Balance</span>
                      {{ getAssetsSystemAssetTotalBalance(account, item)? parseFloat(getAssetsSystemAssetTotalBalance(account, item)) : '' }}
                    </td>
                    <td colspan="1"><span>Wallet Address</span>
                      <button v-if="systemMaintenanceStatus && getIncomingAssetWalletAddress(account, item)" class="action-button save" @click="getWallet(item, account.id)">Update</button>
                      <button v-if="!getIncomingAssetWalletAddress(account, item)" class="action-button save" @click="wallet(item, account.id)">Activate</button>
                    </td>
                  </tr>
                </template>
                <template v-else="featureStatus">
                  <tr>
                    <td></td>
                    <td></td>
                    <td colspan="1">
                      Currency
                    </td>
                    <td colspan="1">
                      Name
                    </td>
                    <td colspan="1">
                      Tag Required
                    </td>
                    <td colspan="1">
                      Tag
                    </td>
                    <td colspan="2">
                      Wallet Address
                    </td>
                    <td>
                      Available
                    </td>
                    <td colspan="2">
                      Balance
                    </td>
                    <td colspan="1">
                      Options
                    </td>
                  </tr>
                  <tr v-for="item in assetsList" v-if="getIncomingAssetWalletAddress(account, item)">
                    <td></td>
                    <td></td>
                    <td colspan="1"><span>Currency</span>
                      {{ item }}
                    </td>
                    <td colspan="1"><span>Name</span>
                      {{ getCurrencyName(item) }}
                    </td>
                    <td colspan="1"><span>Tag Required</span>
                      {{ getTagRequired(item) }}
                    </td>
                    <td colspan="1"><span>Tag</span>
                      {{ getTag(account, item) }}
                    </td>
                    <td colspan="2"><span>Wallet Address</span>
                      {{ getIncomingAssetWalletAddress(account, item) }}
                    </td>
                    <td><span>Available Balance</span>
                      {{ getAssetsSystemAssetAvailableBalance(account, item)? parseFloat(getAssetsSystemAssetAvailableBalance(account, item)) : ''}}
                    </td>
                    <td colspan="2"><span>Balance</span>
                      {{ getAssetsSystemAssetTotalBalance(account, item)? parseFloat(getAssetsSystemAssetTotalBalance(account, item)) : '' }}
                    </td>
                    <td colspan="1"><span>Wallet Address</span>
                      Disabled
                    </td>
                  </tr>
                </template>
              </template>
              <tr><td colspan="12"></td></tr>
            </table>
          </section>
      </div>
    </template>
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Finance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>

    <popup :open.sync="errorOpen" :width="400">
      <div class="scrollable">
        <p>{{ message }}</p>
      </div>
    </popup>

    <corp-account-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import PaymentDetailsModal from './PaymentDetailsModal.vue'
import BankLookupModal from './BankLookupModal.vue'
import MnbSelect from '../../components/ui/forms/Select'
import CorpAccountFilters from './filters/CorpAccountFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'corporate-accounts',

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    PaymentDetailsModal,
    BankLookupModal,
    MnbSelect,
    AppliedFilters,
    CorpAccountFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,

    accounts: null,
    modalType: null,
    modalPaymentType: null,
    modalBankType: null,

    open: true,
    pin: '',
    pinFailed: false,
    systemMaintenanceStatus: window.maintenance,

    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),

    errorOpen: false,
    message: null,
    featureStatus: true,
    featureFundsStatus: true,
    featureAdvanceDepositNoticeStatus: true,
    activeInfo: false,
    mainAccount: null,

    currencyStatus : {
      AUD : false,
      CAD : false,
      EUR : false,
      GBP : false,
      JPY : false,
    },
    perms: window.appData.currentUser?.role?.perms?.includes('corporate-accounts') || window.appData.currentUser?.perms?.includes('corporate-accounts')
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        accountId: 'Account ID',
        name: 'Name',
        type: 'Account Type',
        status: 'Account Status'
      }
      
     
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    appData() {
      return window.appData;
    },
    assetsList () {
      let currencies = window.currencies || []
      let assets = [];
      currencies = currencies.filter(currency => {
        return currency.systemCurrencyType === 'Assets'
      })
      currencies.forEach(currency => {
        assets.push(currency.currencyNickname)
      })
      return assets;
    },

    accountStatuses () {
      return window.accountStatuses
    },

    profileStatuses () {
      return window.profileStatuses
    },

    accountStatusesFiltered () {
      const array = [...window.accountStatuses]
      array.splice(0, 2)
      return array
    },

    sortAccounts () {
      let accounts = this.accounts

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) return accounts

      if (this.filters.name) {
        accounts = accounts.filter(account => {
          return account.accountName.toLowerCase().includes(this.filters.name.toLowerCase())
        })
      }

      if (this.filters.fromDate) {
        const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
        accounts = accounts.filter(account => {
          const date = moment(account.createdAt.split(' ')[0], 'YYYY-MM-DD')
          return date >= from
        })
      }

      if (this.filters.toDate) {
        const to = moment(this.filters.toDate, 'YYYY-MM-DD')
        accounts = accounts.filter(account => {
          const date = moment(account.createdAt.split(' ')[0], 'YYYY-MM-DD')
          return date <= to
        })
      }

      if (this.filters?.status) {
        accounts = accounts.filter(account => account.accountStatus === this.filters.status)
      }

      if (this.filters.accountId) {
        accounts = accounts.filter(account => parseInt(account.accountNumber) === parseInt(this.filters.accountId))
      }

      if (this.filters.type) {
        accounts = accounts.filter(account => account.accountType === this.filters.type)
      }

      return accounts
    },

    statusColorClasses () {
      return {
        Open: 'c1',
        Pending: 'c2',
        Closed: 'c4',
        Dormant: 'c3',
        Active: 'c1'
      }
    },

    typeText () {
      return {
        miscAccount: 'Misc',
        corpAccount: 'Corp',
        feesAccount: 'Fees',
        operAccount: 'Oper',
      }
    },

    totalNumber() {
      return this.sortAccounts? this.sortAccounts.length : 0
    },

    accountOptions () {
      let accounts = []
      if(this.accounts){
        this.accounts.forEach(account => {
          accounts.push({
            id: account.id,
            text: account.accountNumber + '-' + account.accountName + '-' + account.serviceProviders.provider_name,
          })
        })
      }
      return accounts
    },

    account () {
      return this.accounts.find(item => item.id === this.mainAccount)
    },
  },

  async created () {
    const loader = this.$loading.show()
    await this.getFeatureStatus();
    const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
    const registration = registrations[0]
    if(registration){
      this.accounts = registration.accounts
    }
    loader.hide()
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    getFilterValue (key, value) {
      if (key === 'type') return window.corpAccountTypes[value]
      if (key === 'status') return window.corpAccountStatuses[value]
      
      return value
    },

    getCurrencyName (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    getTagRequired (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? ( currencies[0].requiresTag? 'Yes' : 'No' ) : 'No'
    },

    getAssetsSystemAssetAvailableBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetAvailableBalance : null
    },

    getAssetsSystemAssetTotalBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetTotalBalance : null
    },

    getIncomingAssetWalletAddress(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].incomingAssetWalletAddress : null
    },

    getTag(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].tag : null
    },

    openTransModal (type) {
      this.modalType = type
    },

    openPayment () {
      this.modalPaymentType = 'Payment'
    },

    openBank () {
      this.modalBankType = 'Bank'
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    cancel() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckFinancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    async generateQReference(account) {
      const loader = this.$loading.show()
      try {
        await api.action("Account", account.id, "GenerateQPaymentReference")
        const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
        const registration = registrations[0]
        if(registration){
          this.accounts = registration.accounts
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async wallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'WalletCorp',
          payload: {
            currencyNickname: currencyNickname,
          }
        }))
        if(response.action.error) {
          this.errorOpen=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
        const registration = registrations[0]
        if(registration) {
          this.accounts = registration.accounts
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async getWallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'GetWalletCorp',
          payload: {
            currencyNickname: currencyNickname,
          }
        }))
        if(response.action.error) {
          this.errorOpen=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
        const registration = registrations[0]
        if(registration) {
          this.accounts = registration.accounts
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    enableCurrency(currency) {
      const loader = this.$loading.show()
      this.currencyStatus[currency] = true
      loader.hide()
    },

    disableCurrency(currency) {
      const loader = this.$loading.show()
      this.currencyStatus[currency] = false
      loader.hide()
    },

    getCurrencyStatus(fundsCurrencies, currency) {
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currency)
      if(fundsCurrency){
        return fundsCurrency.currencyEnabled
      }else{
        return null
      }
    },

    async activateCurrency(accountId, currency) {
      const loader = this.$loading.show()
      let payload;
      payload = {
        accountId: accountId,
        currency: currency,
      }
      let response = (await api.action("AccountsFundsCurrencies", "new", "Activate", payload)).item
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
      loader.hide()
    },

    async enableCurrency(fundsCurrencies, currency) {
      const loader = this.$loading.show()
      let payload;
      let id = (fundsCurrencies.find(item => item.currency.currencyNickname == currency))?.id

      payload = {
        status: 1,
      }
      let response = (await api.action("AccountsFundsCurrencies", id, "Status", payload)).item
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
      loader.hide()
    },

    async disableCurrency(fundsCurrencies, currency) {
      const loader = this.$loading.show()
      let payload;
      let id = (fundsCurrencies.find(item => item.currency.currencyNickname == currency))?.id

      payload = {
        status: 0,
      }
      let response = (await api.action("AccountsFundsCurrencies", id, "Status", payload)).item
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
      loader.hide()
    },

    getSystemFundsAvailableBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemAvailableBalance : null
    },

    getSystemFundsTotalBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemTotalBalance : null
    },

    getFundsCurrencySymbol(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].currency.symbol : null
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      this.featureAdvanceDepositNoticeStatus = window.systemFeatures['Advance Deposit Notice']
    },

    async checkFees(id){
      const account = (await api.action("Account", id, "CheckFees")).action
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
    },

    async checkLinked(id){
      const account = (await api.action("Account", id, "CheckLinked")).action
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
    },

    async checkSolidFees(id){
      const account = (await api.action("Account", id, "CheckSolidFees")).action
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
    },

    async checkSolidLinked(id){
      const account = (await api.action("Account", id, "CheckSolidLinked")).action
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.accounts = registration.accounts
      }
    },
  }
}
</script>
<style type="scss" scoped>
  .assets-label {
    font-size: 17px;
  }

  .content-tlist {
    border: none;
  }
  .content-filters .cof-box {
    background: none;
  }
</style>
