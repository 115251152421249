<template>
  <div>
    <div class="cp-box">
      <div class="mail-buttons-container">
        <div class="cof-field mobinl">
          <label>&nbsp;</label>
          <button :class="{main: true, disabled: employee.status === 'Active'}" @click="sendMail('employees', 'EmployeeWelcomeEmail')" :disabled="employee.status === 'Active'">Activation</button>
        </div>
      </div>
      <hr>

      <section  class="content-tlist" style="margin: 30px 0;">
        <table class="content-table">           
          <tr>
            <th>Type</th>              
            <th>Date/Time</th>                        
          </tr>
          <tr v-for="email in emails" :key="email.id">
            <td><span>Type</span>{{ email.email_type }}</td>               
            <td><span>Date/Time</span>{{ email.createdAt | date }}</td>         
          </tr>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      default: null
    }
  },

  computed: {
    emails() {
      return this.$store.state.employee_email_log.data
    }
  },

  created() {
    this.load()
  },

  methods: {
    async sendMail (action, mailType) {
      try {
        await this.$store.dispatch(`${action}/action`, { id: this.employee.id, action: mailType })
        await this.load()
        this.$toast.success('Email successfully sent.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    load () {
      this.$store.dispatch('employee_email_log/load', { params: { entity: 'Employees', entityId: this.employee.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mail-buttons-container {
    width: 100%; 
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px; 
  }
  .disabled {
    background-color: #f6f8f9;
    color: #616f7b;
    border: 1px solid #616f7b;
  }
</style>