<template>
  <div v-if="employee" class="cp-box">
    <ul class="cp-rows active">
      <li class="access-btns">
        <div class="mail-buttons-container">
          <button v-if="$hasPerm('access')" class="btn" :class="!isActive ? 'btn-success' : 'btn-secondary'" :disabled="isActive" @click="resetActivationCode()" >Reset Activation Code</button>
          <button v-if="$hasPerm('access')" class="btn btn-success" @click="resetPassword()">Reset Password</button>
          <button v-if="$hasPerm('access')" class="btn btn-block" @click="toggleBlock()">{{ employee.user.active !== 'Blocked' ? 'Block' : 'Unblock' }}</button>
        </div>
      </li>
      <li><h4>Access Information</h4></li>
      <li>
        <ul class="cp-fields justify-content-none">
          <li>
            <span>Username</span>
            <div class="val">
              {{ employee.user.username }}
            </div>
          </li>
          <li>
            <span>First Name</span>
            <div class="val">
              {{ employee.user.firstName }}
            </div>
          </li>
          <li>
            <span>Last Name</span>
            <div class="val">
              {{ employee.user.lastName }}
            </div>
          </li>
        </ul>
      </li>
      <li>
        <ul class="cp-fields justify-content-none">
          <template v-if="!employee.user.activationDate">
            <li>
              <span>Activation Code</span>
              <div class="val">
                {{ employee.user.activationCode }}
              </div>
            </li>
            <li>
              <span>Activation Code Expires</span>
              <div class="val">
                {{ employee.user.activationCodeExpiryDate | date }}
              </div>
            </li>
          </template>
          <li>
            <span>Activation Date</span>
            <div class="val">
              <template v-if="employee.user.activationDate">{{ employee.user.activationDate | date }}</template>
              <template v-else>N/A</template>
            </div>
          </li>
          <li>
            <span>Reset Pass Request</span>
            <div class="val">
              <template v-if="employee.user.resetPasswordRequestedDateTime">{{ employee.user.resetPasswordRequestedDateTime | date }}</template>
              <template v-else>N/A</template>
            </div>
          </li>
          <li>
            <span>Reset Pass Complete</span>
            <div class="val">
              <template v-if="employee.user.resetPasswordCompletedDateTime">{{ employee.user.resetPasswordCompletedDateTime | date }}</template>
              <template v-else>N/A</template>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      default: null
    }
  },

  computed : {
    isActive () {
      return this.employee.user.activationDate
    },
  },

  methods: {
    async toggleBlock () {
      if (confirm(`Are you sure you want to ${isBlocked ? 'un' : ''}block this employee?`)) {
        const loader = this.$loading.show()
        const isBlocked = this.employee.user.active === 'Blocked'
        await this.$store.dispatch('users/action', { id: this.employee.user.id, action: isBlocked ? 'Unblock' : 'Block' })
        await this.$store.dispatch('employees/get', this.employee.id)
        loader.hide()
        this.$toast.success(`User ${isBlocked ? 'unblocked' : 'blocked'} successfully.`)
      }
    },

    async resetActivationCode() {
      if (this.isActive) return
      const loader = this.$loading.show()
      await api.action("Employees", this.employee.id, "ResetActivationCode", );
      await this.$store.dispatch('employees/action', { id: this.employee.id, action: 'ResetActivationCode', payload: { userId: this.employee.user.id } })
      await this.$store.dispatch('employeenotes/load', { params: { 'employeeId': this.employee.id } })
      loader.hide()
    },

    async resetPassword () {
      const loader = this.$loading.show()
      await this.$store.dispatch('users/action', { id: this.employee.user.id, action: 'ResetPassword' })
      loader.hide()
      this.$toast.success('Password Reset successfull.')
    }
  }
}
</script>
<style>

</style>