<template>
  <popup :open.sync="open">
    <div v-if="form.depositType == 'Assets'" class="popup-header">
      <h3>{{ isDeposit ? 'Assets Deposit' : 'Assets Withdrawal' }}</h3>
      <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
    </div>
    <div v-else-if="form.depositType == 'Funds'" class="popup-header">
      <h3>{{ isDeposit ? 'Funds Deposit' : 'Funds Withdrawal' }}</h3>
      <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
    </div>
    <div v-else class="popup-header">
      <h3>{{ isDeposit ? 'Deposit' : 'Withdrawal' }}</h3>
      <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
    </div>
    <template v-if="!nextFlag">
      <div class="scrollable">
        <h1 class="text-center">Choose the {{ isDeposit ? 'Deposit' : 'Withdrawal' }} Type</h1>
        <div class="content-form text-center">
          <div class="form-row form-split depositType">
            <form-field-select v-model="form.depositType" :label="`${isDeposit ? 'Deposit' : 'Withdrawal'} Type`" :validator="$v.form.depositType" :options="depositTypeOptions" required />
          </div>
        </div>
        <div class="buttons">
          <button class="main" @click="moveNextPopup()">Next</button>
          <button class="second" @click="open = false">Cancel</button>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="addModal">
        <div class="scrollable" v-if="form.depositType == 'Funds'">
          <template v-if="isDeposit && system.transaction_deposits">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount" :label="`How will you ${isDeposit ? 'deposit' : 'withdraw'}`" :validator="$v.form.deposit_type" :options="depositOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
                <form-field-text v-model="form.amount" :label="`${isDeposit ? 'Deposit' : 'Withdrawal'} Amount`" :validator="$v.form.amount" required />
              </div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidDepositFee"><span>The Amount must be greater than the Fee.</span></div>
            </div>
            <div class="sum">
              <div>Fee: <b>{{ fee | money }}</b></div>
              <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ total | money }}</b></div>
            </div>

            <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" />

            <ach-form v-if="showACHForm" v-model="form" :v="$v.form" />

            <div v-if="showPayee" class="content-form">
              <div class="form-row form-split">
                <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
              </div>
            </div>

            <template v-if="showAddressForm">
              <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
              <address-form v-model="form" :v="$v.form" />
            </template>

            <div class="content-form">
              <div v-if="showFurtherCreditAccount" class="center-checkbox">
                <input v-model="form.use_further_credit_account" type="checkbox" id="use_further_credit_account">
                <label for="use_further_credit_account">Use Further Credit Account</label>
              </div>

              <div v-if="form.use_further_credit_account" class="form-row form-split">
                <form-field-text v-model="form.futher_credit_acc_name" label="Further Credit Account Name" :validator="$v.form.futher_credit_acc_name" required />
                <form-field-text v-model="form.futher_credit_acc_number" label="Further Credit Account Number" :validator="$v.form.futher_credit_acc_number" required />
              </div>
              <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
                <form-field-text v-model="form.a2a_account_number" label="Account Number" :validator="$v.form.a2a_account_number" required />
                <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
                <div class="error-message" v-if="invalidFundsCurrency"><span>{{ form.currency }} not available.</span></div>
              </div>

              <h3 class="subtitle">EXTRA INFORMATION</h3>
              <div class="form-row form-split">
                <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
                <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
              </div>
            </div>
          </template>
          <template v-else-if="!isDeposit && system.transaction_withdrawals">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount" label="Withdrawal Method" :validator="$v.form.deposit_type" :options="depositOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
                <form-field-text v-model="form.amount" :label="`${isDeposit ? 'Deposit' : 'Withdrawal'} Amount`" :validator="$v.form.amount" required />
              </div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidToProvider"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
            </div>
            <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" :transactionCountries="transactionCountries"/>

            <ach-form v-if="showACHForm" v-model="form" :v="$v.form" />

            <div v-if="showPayee" class="content-form">
              <h3 class="subtitle">PAYEE INFORMATION</h3>
              <div class="form-row form-split">
                <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
              </div>
            </div>
            
            <template v-if="form.deposit_type == 'ach'">
              <h3 class="subtitle">BENEFICIARY ADDRESS</h3>
              <address-dom-form v-model="form" :v="$v.form" :transactionCountries="transactionCountries" />
            </template>
            <template v-if="showAddressForm">
              <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
              <address-form v-if="form.deposit_type !== 'domestic_wire'" v-model="form" :v="$v.form" :usOnly="form.deposit_type === 'domestic_wire'? false : true" :transactionCountries="transactionCountries"/>
              <address-dom-form v-else v-model="form" :v="$v.form" :usOnly="form.deposit_type === 'domestic_wire'? false : true" :transactionCountries="transactionCountries" />
            </template>

            <div class="content-form">
              <h3 v-if="showA2AAccountNumber" class="subtitle">ACCOUNT INFORMATION</h3>
              <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
                <!--<form-field-text v-model="form.a2a_account_number" label="Account Number" :validator="$v.form.a2a_account_number" required />-->
                <form-field-select v-model="form.a2a_account_number" label="Select Linked Account" :validator="$v.form.a2a_account_number" :options="linkedAccountOptions" :option-group="open" required />
                <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
                <div class="error-message" v-if="invalidFundsCurrency"><span>{{ form.currency }} not available.</span></div>
              </div>
              <div class="form-row form-split">
                <h3 class="subtitle">EXTRA INFORMATION</h3>
                <div class="template-check">
                  <input v-model="form.save_as_template" type="checkbox" id="save_as_template">
                  <label for="save_as_template">Save as a Template</label>
                </div>
              </div>
              <div class="form-row form-split template-name">
                <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
                <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
                <form-field-text v-if="form.save_as_template" v-model="form.template_name" :validator="$v.form.template_name" :focus="form.save_as_template" label="Template Name" :maxlength="50" required :disabled="!form.save_as_template" />
                <form-field-text v-else label="Template Name" :maxlength="50" required :disabled="!form.save_as_template" />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="form.reference" label="Reference" :maxlength="60" required :validator="$v.form.reference"/>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="isDeposit" class="disabledText"><b>{{ system.disabled_deposits_text }}</b></div>
            <div v-else class="disabledText"><b>{{ system.disabled_withdrawals_text }}</b></div>
          </template>
          <div class="withdraw-sum">
            <div>Fee: <b>{{ fee | money }}</b></div>
            <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ total | money }}</b></div>
          </div>
          
          <div class="buttons">
            <button v-if="isDeposit && !system.transaction_deposits" class="second" @click="open = false">Close</button>
            <button v-else-if="!isDeposit && !system.transaction_withdrawals" class="second" @click="open = false">Close</button>
            <template v-else>
              <button v-if="isDeposit" class="main" @click="add()">Submit</button>
              <button v-else class="main" @click="withdraw()">Submit</button>
              <button class="second" @click="open = false">Cancel</button>
            </template>
            <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
          </div>
        </div>
        <div class="scrollable" v-else>
          <template v-if="isDeposit && system.transaction_deposits">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.assetsCurrency" label="Assets Type" :validator="$v.form.assetsCurrency" :options="assetsCurrencyOptions" required />
              </div>
              <div class="form-row form-split">
                <div></div>
                <form-field-text v-model="form.assetsAmount" :label="`Units ${isDeposit ? 'Deposit' : 'Withdrawal'} Amount`" :validator="$v.form.assetsAmount" required />
              </div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidDepositFee"><span>The Amount must be greater than the Fee.</span></div>
            </div>
            <div class="sum">
              <div>Fee: <b>{{ assetsFee | money }}</b></div>
              <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ totalAssets | unit_asset_balance }}</b></div>
            </div>
            <br/>
            <br/>
            <div class="content-form">
              <h3 class="subtitle">EXTRA INFORMATION</h3>
              <div class="form-row form-split">
                <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
                <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
              </div>
            </div>
          </template>
          <template v-else-if="!isDeposit && system.transaction_withdrawals">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.assetsCurrency" label="Assets Type" :validator="$v.form.assetsCurrency" :options="assetsCurrencyOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-select v-model="form.sendingTo" label="Sending to" :validator="$v.form.sendingTo" :options="sendingToOptions" required />
                <form-field-text v-model="form.assetsAmount" :label="`Units ${isDeposit ? 'Deposit' : 'Withdrawal'} Amount`" :validator="$v.form.assetsAmount" required />
              </div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidAssetBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidToProvider"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
            </div>
            <div class="sum">
              <div>Fee: <b>{{ assetsFee | money }}</b></div>
              <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ totalAssets | unit_asset_balance }}</b></div>
            </div>
            <div v-if="form.sendingTo == 'WalletAddress'" class="content-form paddingless">
              <div class="form-row form-split">
                <form-field-text v-model="form.payeeToName" :validator="$v.form.payeeToName" label="Pay to Name" />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="form.payeeToWalletAddress" :validator="$v.form.payeeToWalletAddress" label="Pay to Wallet Address" required />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="form.tag" :validator="$v.form.tag" label="Destination Tag" required />
              </div>
            </div>

            <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" />

            <ach-form v-if="showACHForm" v-model="form" :v="$v.form" />

            <div v-if="showPayee" class="content-form">
              <div class="form-row form-split">
                <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
              </div>
            </div>

            <template v-if="showAddressForm">
              <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
              <address-form v-model="form" :v="$v.form" />
            </template>

            <div class="content-form paddingless">
              <div v-if="showFurtherCreditAccount" class="center-checkbox">
                <input v-model="form.use_further_credit_account" type="checkbox" id="use_further_credit_account">
                <label for="use_further_credit_account">Use Further Credit Account</label>
              </div>

              <div v-if="form.use_further_credit_account" class="form-row form-split">
                <form-field-text v-model="form.futher_credit_acc_name" label="Further Credit Account Name" :validator="$v.form.futher_credit_acc_name" required />
                <form-field-text v-model="form.futher_credit_acc_number" label="Further Credit Account Number" :validator="$v.form.futher_credit_acc_number" required />
              </div>
              <div v-if="form.sendingTo == 'AccountTransfer'" class="form-row form-split acc-to-acc">
                <!--<form-field-text v-model="form.a2a_account_number" label="Account Number" :validator="$v.form.a2a_account_number" required />-->
                <form-field-select v-model="form.a2a_account_number" label="Select Linked Account" :validator="$v.form.a2a_account_number" :options="linkedAccountOptions" :option-group="open" required />
                <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
                <div class="error-message" v-if="invalidFundsCurrency"><span>{{ form.currency }} not available.</span></div>
                <div class="error-message" v-if="errorWallet"><span>This account does not have an activated wallet for the asset type chosen. Please check information and try again.</span></div>
              </div>
              <div class="form-row form-split">
                <h3 class="subtitle">EXTRA INFORMATION</h3>
                <div class="template-check">
                  <input v-model="form.save_as_template" type="checkbox" id="save_as_template">
                  <label for="save_as_template">Save as a Template</label>
                </div>
              </div>
              <div class="form-row form-split template-name">
                <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
                <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
                <form-field-text v-if="form.save_as_template" v-model="form.template_name" :validator="$v.form.template_name" :focus="form.save_as_template" label="Template Name" :maxlength="50" required :disabled="!form.save_as_template" />
                <form-field-text v-else label="Template Name" :maxlength="50" required :disabled="!form.save_as_template" />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="form.reference" label="Reference" :maxlength="60" required :validator="$v.form.reference"/>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="isDeposit" class="disabledText"><b>{{ system.disabled_deposits_text }}</b></div>
            <div v-else class="disabledText"><b>{{ system.disabled_withdrawals_text }}</b></div>
          </template>
          <div class="buttons">
            <button v-if="isDeposit && !system.transaction_deposits" class="second" @click="open = false">Close</button>
            <button v-else-if="!isDeposit && !system.transaction_withdrawals" class="second" @click="open = false">Close</button>
            <template v-else>
              <button v-if="isDeposit" class="main" @click="depositAssets()">Submit</button>
              <button v-else class="main" @click="withdrawAssets()">Submit</button>
              <button class="second" @click="open = false">Cancel</button>
            </template>
            <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
          </div>
        </div>
      </template>

      <template v-if="addConfirmModal">
        <div class="scrollable" v-if="isDeposit">
          <div class="content-form" v-if="form.depositType == 'Funds'">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <br>
            <div v-if="this.form.deposit_type != 'ach'" class="form-row">
              <p v-if="transaction" class="transaction-status">Reference: {{ transaction.transactionReferenceCode }}, {{ selectedAccount.name }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ depositOptionsText }} Information</p>
              <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details you will need to complete your payment process.</p>
              <template v-if="this.form.deposit_type == 'checks'">
                <p>No Address Provided</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'ach'">
                <p>ACH Deposit Added Successfully</p>
              </template>
              <template v-else>
                <p v-if="transaction && transaction.instructions"><b>Depository Bank Name</b>: {{ transaction.instructions['depository-bank-name'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Bank Address</b>: {{ transaction.instructions['bank-address'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Bank Phone</b>: {{ transaction.instructions['bank-phone'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Credit To</b>: {{ transaction.instructions['credit-to'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Address</b>: {{ transaction.instructions['beneficiary-address'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Routing Number</b>: {{ transaction.instructions['routing-number'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Account Number</b>: {{ transaction.instructions['account-number'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>SWIFT Code</b>: {{ transaction.instructions['swift-code'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Reference</b>: {{ transaction.transactionReferenceCode }}, {{ selectedAccount.name }}</p>
              </template>
              <p v-if="this.form.deposit_type == 'domestic_wire'" class="margin-10"><b>Note</b>: Your domestic wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
              <p v-else-if="this.form.deposit_type == 'international_wires'" class="margin-10"><b>Note</b>: Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
              <p v-else class="margin-10"><b>Note</b>: Please follow any specific funding instructions to ensure the funds are received quickly.  If you have any doubts or questions please verify your instructions with your account representative.</p>
            </div>
          </div>
          <div v-else class="content-form">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <br>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">Information</p>
              <p v-if="transaction">{{ getCurrencyName(form.assetsCurrency) }} Wallet Address</p>
              <p v-if="transaction">{{ transaction.contributionIncomingWalletAddress }}</p>
              <p class="margin-10"><b>Note</b>: Send only {{ getCurrencyName(form.assetsCurrency) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
            </div>
          </div>
        </div>
        <div class="scrollable" v-else>
          <div class="content-form" v-if="form.depositType == 'Funds'">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details submitted for your withdrawal.</p>
              <template v-if="this.form.deposit_type == 'international_wires'">
                <p v-if="transaction"><b>Bank Name</b>: {{ this.form.bank_name }}</p>
                <p v-if="transaction"><b>Account Name</b>: {{ this.form.account_name }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ this.form.account_number }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
                <p v-if="transaction"><b>Bank Swift Code</b>: {{ this.form.swift }}</p>
                <p v-if="transaction"><b>Intermediary Bank Name</b>: {{ this.form.intermediary_bank_name }}</p>
                <p v-if="transaction"><b>Intermediary Bank Reference</b>: {{ this.form.intermediary_bank_reference }}</p>
                <p v-if="transaction"><b>Intermediary Bank Routing Number</b>: {{ this.form.intermediary_bank_routing_number }}</p>
                <p v-if="transaction"><b>Intermediary Bank SWIFT Code</b>: {{ this.form.intermediary_bank_swift_code }}</p>
                <p v-if="transaction"><b>INTERMEDIARY BANK ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.intermediary_address.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.intermediary_address.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.intermediary_address.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.intermediary_address.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.intermediary_address.postal }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>IBAN</b>: {{ this.form.iban }}</p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'checks'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Payee</b>: {{ this.form.payee }}</p>
                <br/>
                <p v-if="transaction"><b>PAYEE ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'ach'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Name of Bank Account</b>: {{ this.form.bank_name }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ this.form.routing_number }}</p>
                <p v-if="transaction"><b>Bank Account Number</b>: {{ this.form.account_number }}</p>
                <p v-if="transaction"><b>Bank Account Type</b>: {{ this.form.bank_account_type }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'domestic_wire'">
                <p v-if="transaction"><b>Account Name</b>: {{ transaction.accountName }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ transaction.routingNumber }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ transaction.accountNumber }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ transaction.accountType }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else>
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Account Number</b>:  ****{{ this.form.a2a_account_number.substr(4, 3) }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <p class="margin-10"><b>Note</b>: If you have any doubts or questions please verify your instructions with your account representative. </p>
            </div>
          </div>
          <div class="content-form" v-else>
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="transaction"><b>Amount</b>: {{ transaction.totalAssetAmount | assets_balance }}</p>
              <p v-if="transaction"><b>Account Number</b>: ****{{ transaction.toAccountId? transaction.toAccountId.substr(4, 3) : '' }}</p>
              <p v-if="transaction">{{ transaction.outgoingWalletAddress }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              <p class="margin-10"><b>Note</b>: Send only {{ getCurrencyName(form.assetsCurrency) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="open = false">Close</button>
        </div>
      </template>
    </template>
  </popup>
</template>

<script>
import { monbiAccountNumber, assetsAmount, alphaNum, routingNumber, swift, alphaNumNoSpace, bankRoutingNumber } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import AchForm from './forms/ACH'
import AddressForm from './forms/Address'
import AddressDomForm from './forms/AddressDom'
import InternationalWireForm from './forms/InternationalWire'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
    AchForm,
    AddressForm,
    AddressDomForm,
    InternationalWireForm
  },

  data () {
    return {
      form: {
        account: null,
        currency: 'USD',
        deposit_type: null,
        amount: null,

        bank_name: null,
        account_type: null,
        routing_number: null,
        account_number: null,
        bank_account_type: null,

        a2a_account_number: null,

        payee: null,

        country: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        postal: null,

        bank_country: null,
        bank_address: null,
        bank_city: null,
        bank_state: null,
        bank_postal: null,

        account_name: null,
        swift: null,
        iban: null,
        use_intermediary_bank: true,
        intermediary_bank_name: null,
        intermediary_bank_reference: null,
        intermediary_bank_routing_number: null,
        intermediaryBankIdentifier: null,
        intermediary_bank_swift_code: null,
        intermediary_address: {
          country: null,
          address: null,
          city: null,
          state: null,
          postal: null,
          disabled: true
        },

        use_further_credit_account: false,
        futher_credit_acc_name: null,
        futher_credit_acc_number: null,

        reference: null,
        memo: null,

        save_as_template: false,
        template_name: null,
        depositType: null,
        assetsCurrency: null,
        assetsAmount: null,
        payeeToWalletAddress: null,
        tag: null,
        payeeToName: null,
        sendingTo: null,
        contributionIncomingWalletAddress: null,
      },

      transaction: null,
      open: true,
      addModal: true,
      addConfirmModal: false,
      invalidAccountNumber: false,
      invalidFundsCurrency: false,
      errorWallet: false,
      invalidBalance: false,
      invalidAssetBalance: false,
      invalidDepositFee: false,
      invalidToProvider: false,
      fee: 0,
      legacyFee: 0,
      feePercent: 0,
      assetsFee: 0,
      linkedAccounts: null,

      submitError: false,

      nextFlag: false,
      featureStatus: window.systemFeatures['Digital Assets'],
      featureFundsStatus: window.systemFeatures['Funds'],
      featureAssetsStatus: window.systemFeatures['Assets'],
      toRegistrations: null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    showInternationalWireForm () {
      return !this.isDeposit && this.form.deposit_type === 'international_wires'
    },

    showFurtherCreditAccount () {
      return !this.isDeposit && ['domestic_wire', 'international_wires'].includes(this.form.deposit_type)
    },

    showPayee () {
      return !this.isDeposit && this.form.deposit_type === 'checks'
    },

    showAddressForm () {
      return !this.isDeposit && ['domestic_wire', 'checks'].includes(this.form.deposit_type)
    },

    showA2AAccountNumber () {
      return !this.isDeposit && this.form.deposit_type === 'acc_to_acc'
    },

    showACHForm () {
      return this.form.deposit_type === 'ach' || (!this.isDeposit && this.form.deposit_type === 'domestic_wire')
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.form.account))
      return account
    },

    limit () {
      const keys = {
        ach: 'acc_to_acc_limit',
        checks: 'checks_limit',
        domestic_wire: 'wires_limit',
        international_wires: 'wires_limit',
        acc_to_acc: 'acc_to_acc_limit',
      }

      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings[keys[this.form.deposit_type]]) : null
    },

    assetLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings['assets_limit']) : null
    },

    withdrawalLimit () {
      if(this.form.currency == 'USD') {
        return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
      } else {
        if(this.selectedAccount){
            if (this.selectedAccount.fundsCurrencies) {
              let fundCurrency = this.selectedAccount.fundsCurrencies.find(item => item.currency.currencyNickname == this.form.currency)
              if(fundCurrency){
                return parseFloat(fundCurrency.systemAvailableBalance)
              }
            }
          }
      }
    },

    withdrawalAssetLimit () {
      return this.getSystemAssetAvailableBalance()
    },

    depositOptionsText () {
      if(this.form.deposit_type == "checks")
        return "Check Deposit"
      if(this.form.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.form.deposit_type == "international_wires")
        return "International Wire"
      if(this.form.deposit_type == "ach")
        return "ACH"
      return
    },

    withdrawOptionsText () {
      if(this.form.deposit_type == "checks")
        return "Check"
      if(this.form.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.form.deposit_type == "international_wires")
        return "International Wire"
      if(this.form.deposit_type == "ach")
        return "ACH"
      return
    },

    depositOptions () {
      const options = [
        {
          id: 'ach',
          text: 'ACH',
          if: this.selectedAccount?.profileSettings?.ach
        },
        {
          id: 'checks',
          text: 'Check',
          if: this.selectedAccount?.profileSettings?.checks
        },
        {
          id: 'domestic_wire',
          text: 'Domestic Wire',
          if: this.selectedAccount?.profileSettings?.domWires
        },
        {
          id: 'international_wires',
          text: 'International Wire',
          if: this.selectedAccount?.profileSettings?.intlWires
        }
      ]
      if (!this.isDeposit) options.push({
        id: 'acc_to_acc',
        text: 'Account Transfer',
        if: this.selectedAccount?.profileSettings?.acc_to_acc
      })
      return options.filter(opt => opt.if)
    },

    depositTypeOptions () {
      let options = [];
      if(this.featureFundsStatus) {
        options.push(
          {
            id: 'Funds',
            text: 'Funds',
          },
        )
      }
      if(this.featureStatus) {
        if(this.featureAssetsStatus) {
          options.push(
            {
              id: 'Assets',
              text: 'Assets',
              disabled: !this.featureStatus
            }
          )
        }
      }
      return options
    },

    sendingToOptions () {
      const options = [
        {
          id: 'WalletAddress',
          text: 'Wallet Address',
        },
        {
          id: 'AccountTransfer',
          text: 'Account Transfer',
        }
      ]
      return options
    },

    currencyOptions () {
      let currencies = window.currencies || []
      let options = []
      options.push({
        id: 'USD',
        text: 'USD',
      })
      currencies.forEach(currency => {
        if(currency.systemCurrencyType == 'Funds') {
          if(this.selectedAccount){
              if (this.selectedAccount.fundsCurrencies) {
                let fundCurrency = this.selectedAccount.fundsCurrencies.find(item => item.currencyId == currency.id)
                if(fundCurrency){
                  if(fundCurrency.currencyEnabled) {
                    options.push({
                      id: currency.currencyNickname,
                      text: currency.currencyNickname,
                    })
                  }
                }
              }
          }
        }
      })
      return options
    },

    assetsCurrencyOptions () {
      let currencies = window.currencies || []
      let options = []
      let disabled = true
      currencies.forEach(currency => {
        if(this.selectedAccount) {
          if(this.selectedAccount){
            if(this.selectedAccount.assetsWallets){
              let wallets
              wallets = this.selectedAccount.assetsWallets.filter(wallet => {
                return wallet.currencyId === currency.id
              })
              if(wallets[0] && wallets[0].incomingAssetWalletAddress) {
                if(currency.systemCurrencyType == 'Assets') {
                  options.push({
                    id: currency.id,
                    text: currency.currencyNickname,
                  })
                }
              }else{
              }
            }
          }
        }
      })
      return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    isDeposit () {
      return this.modalType === 'deposit'
    },

    total () {
      if(this.form.amount) {
        if (this.isDeposit)
          return (parseFloat(this.form.amount).toFixed(2)) || 0
        else
          return parseFloat(parseFloat(this.form.amount) + parseFloat(this.fee)).toFixed(2) || 0
      }else{
        if (this.isDeposit)
          return 0
        else
          return parseFloat(this.fee).toFixed(2) || 0
      }
    },

    totalAssets () {
      return (parseFloat(this.form.assetsAmount)) || 0
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []
      if(this.form.depositType == 'Funds') {
        registrations.forEach(reg => {
          if (reg.accounts?.length) {
            reg.accounts.forEach(account => {
              if(this.isDeposit){
                if(account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                  accounts.push({
                    id: account.id,
                    regId: reg.id,
                    status: account.accountStatus,
                    text: `${account.accountNumber}-${reg.name}`,
                    accountNumber: account.accountNumber,
                    provider: account.provider,
                    profileSettings: account.profileSettings,
                    fundsCurrencies: account.fundsCurrencies,
                    assetsWallets: account.assetsWallets,
                    name: account.accountName,
                    systemAvailableBalance: account.systemAvailableBalance,
                    disabled: true,
                    serviceProviders: account.serviceProviders,
                  })
                }else{
                  accounts.push({
                    id: account.id,
                    regId: reg.id,
                    status: account.accountStatus,
                    text: `${account.accountNumber}-${reg.name}`,
                    accountNumber: account.accountNumber,
                    profileSettings: account.profileSettings,
                    fundsCurrencies: account.fundsCurrencies,
                    assetsWallets: account.assetsWallets,
                    name: account.accountName,
                    systemAvailableBalance: account.systemAvailableBalance,
                    provider: account.provider,
                    serviceProviders: account.serviceProviders,
                  })
                }
              }else{
                if(account.accountStatus == 'Restricted' || account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                  accounts.push({
                    id: account.id,
                    regId: reg.id,
                    status: account.accountStatus,
                    text: `${account.accountNumber}-${reg.name}`,
                    accountNumber: account.accountNumber,
                    provider: account.provider,
                    serviceProviders: account.serviceProviders,
                    profileSettings: account.profileSettings,
                    fundsCurrencies: account.fundsCurrencies,
                    name: account.accountName,
                    assetsWallets: account.assetsWallets,
                    systemAvailableBalance: account.systemAvailableBalance,
                    disabled: true
                  })
                }else{
                  accounts.push({
                    id: account.id,
                    regId: reg.id,
                    status: account.accountStatus,
                    text: `${account.accountNumber}-${reg.name}`,
                    accountNumber: account.accountNumber,
                    profileSettings: account.profileSettings,
                    fundsCurrencies: account.fundsCurrencies,
                    assetsWallets: account.assetsWallets,
                    systemAvailableBalance: account.systemAvailableBalance,
                    name: account.accountName,
                    provider: account.provider,
                    serviceProviders: account.serviceProviders,
                  })
                }
              }

            })
          }
        })
      }else{
        registrations.forEach(reg => {
          if (reg.accounts?.length) {
            reg.accounts.forEach(account => {
              if(account.profileSettings && account.profileSettings.assets) {
                if(this.isDeposit){
                  if(account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                    accounts.push({
                      id: account.id,
                      regId: reg.id,
                      status: account.accountStatus,
                      text: `${account.accountNumber}-${reg.name}`,
                      accountNumber: account.accountNumber,
                      provider: account.provider,
                      profileSettings: account.profileSettings,
                      fundsCurrencies: account.fundsCurrencies,
                      assetsWallets: account.assetsWallets,
                      systemAvailableBalance: account.systemAvailableBalance,
                      name: account.accountName,
                      disabled: true
                    })
                  }else{
                    accounts.push({
                      id: account.id,
                      regId: reg.id,
                      status: account.accountStatus,
                      text: `${account.accountNumber}-${reg.name}`,
                      accountNumber: account.accountNumber,
                      profileSettings: account.profileSettings,
                      fundsCurrencies: account.fundsCurrencies,
                      assetsWallets: account.assetsWallets,
                      systemAvailableBalance: account.systemAvailableBalance,
                      name: account.accountName,
                      provider: account.provider
                    })
                  }
                }else{
                  if(account.accountStatus == 'Restricted' || account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                    accounts.push({
                      id: account.id,
                      regId: reg.id,
                      status: account.accountStatus,
                      text: `${account.accountNumber}-${reg.name}`,
                      accountNumber: account.accountNumber,
                      profileSettings: account.profileSettings,
                      fundsCurrencies: account.fundsCurrencies,
                      assetsWallets: account.assetsWallets,
                      systemAvailableBalance: account.systemAvailableBalance,
                      name: account.accountName,
                      provider: account.provider,
                      disabled: true
                    })
                  }else{
                    accounts.push({
                      id: account.id,
                      regId: reg.id,
                      status: account.accountStatus,
                      text: `${account.accountNumber}-${reg.name}`,
                      accountNumber: account.accountNumber,
                      profileSettings: account.profileSettings,
                      fundsCurrencies: account.fundsCurrencies,
                      assetsWallets: account.assetsWallets,
                      systemAvailableBalance: account.systemAvailableBalance,
                      name: account.accountName,
                      provider: account.provider,
                    })
                  }
                }
              }
            })
          }
        })
      }

      if (this.isCustomer) {
        accounts = accounts.filter(account => ['Open'].includes(account.status))
      } else {
        const categories = {}
        const availableStatuses = ['Open', 'Restricted', 'Suspended', 'Dormant', 'Hold']
        availableStatuses.forEach(status => {
          const accs = accounts.filter(account => account.status === status)
          if (accs.length) categories[status] = accs
        })
        return categories
      }

      return accounts
    },

    linkedAccountOptions () {
      let accounts = []

      this.linkedAccounts.forEach(account => {
        if(this.selectedAccount.provider == account.provider) {
          accounts.push({
            id: account.accountNumber,
            text: account.text,
          })
        }
      })
      const categories = {}
      let corpAccounts = []
      if(this.toRegistrations.length > 0){
        let toAccounts = this.toRegistrations[0].accounts
        corpAccounts = toAccounts.filter(acc => (acc.linked == true || acc.solidLinked == true) && acc.provider == this.selectedAccount.provider ).map(acc => ({
          id: acc.accountNumber,
          text: ` ****${acc.accountNumber.substr(-3)}-${acc.accountName}`,
        }))
      }
      categories[''] = accounts
      categories['Corporate Accounts'] = corpAccounts
      return categories
    },

    transactionCountries() {
      return this.selectedAccount?.serviceProviders?.transactionCountries
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    scheduleFee () {
      const data = this.$store.state.fee_schedule.data || []
      if(data.length > 0) {
        return data[0].volumeFee
      }else{
        return null
      }
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.form.deposit_type = null
      this.invalidToProvider = false
      this.getAssetsFee()
      this.getLinkedAccounts()
    },

    'form.amount' () {
      this.$v.form.amount.$touch()
      
      if(this.isDeposit){
        if (this.form.amount > parseFloat(this.fee)) {
          this.invalidDepositFee = false
          return
        } else {
          this.invalidDepositFee = true
          return
        }
      }else{
        if(this.form.deposit_type != 'acc_to_acc') {
          if(this.form.amount) {
            this.fee = parseFloat(parseFloat(this.legacyFee) + parseFloat(this.form.amount) * this.feePercent).toFixed(2)
          }else{
            this.fee = parseFloat(this.legacyFee).toFixed(2)
          }
        }else{
          this.fee = parseFloat(this.legacyFee).toFixed(2)
        }
      }

      if (parseFloat(this.withdrawalLimit) < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      } else {
        this.invalidBalance = false
        return
      }
    },

    'form.assetsAmount' () {
      this.$v.form.assetsAmount.$touch()
      if (parseFloat(this.withdrawalAssetLimit) < parseFloat(this.totalAssets)) {
        this.invalidAssetBalance = true
        return
      } else {
        this.invalidAssetBalance = false
        return
      }
    },

    'form.routing_number' () {
      this.$v.form.routing_number.$touch()
    },

    async 'form.a2a_account_number' () {
      let accounts = this.linkedAccounts
      let toAccount = accounts.find(item => item.accountNumber === this.form.a2a_account_number)
      if(!toAccount) {
        this.fee = 0
      }

//      this.$v.form.a2a_account_number.$touch()
//      let payload = {
//        toAccountId: this.form.a2a_account_number,
//        currency: this.form.currency
//      }
//      const response = (await api.action("Transactions", "new", "CheckAccount", payload))
//      if(response.action.error){
//        this.invalidAccountNumber = true
//      }else{
//        this.invalidAccountNumber = false
//      }
//      if(response.action.errorFunds) {
//        this.invalidFundsCurrency = true
//      }else{
//        this.invalidFundsCurrency = false
//      }
    },

    'form.account_number' () {
      this.$v.form.account_number.$touch()
    },

    async 'form.deposit_type' () {
      await this.getFee()
      this.invalidToProvider = false
      if(!this.isDeposit){
        if(this.form.deposit_type != 'acc_to_acc') {
          if(this.form.amount) {
            this.fee = parseFloat(this.legacyFee) + parseFloat(this.form.amount) * this.feePercent
          }else{
            this.fee = parseFloat(this.legacyFee)
          }
        }else{
          this.fee = parseFloat(this.legacyFee)
        }
      }
    },

    'form.save_as_template' () {
      if(this.form.save_as_template) {
        this.$nextTick(() => this.$el.querySelectorAll('.template-name input')[1].focus())
        this.$nextTick(() => this.$el.querySelectorAll('.template-name input')[1].select())
      }
    }
  },

  methods: {
    moveNextPopup () {
      if(this.form.depositType == 'Funds' || this.form.depositType == 'Assets') {
        this.nextFlag = true
      }
    },

    getIncomingAssetWalletAddress(currencyId) {
      if(this.form.assetsCurrency) {
        let wallets
        wallets = this.selectedAccount.assetsWallets.filter(wallet => {
          return wallet.currencyId === currencyId
        })
        return wallets[0]? wallets[0].incomingAssetWalletAddress : null
      }
      return null
    },

    getCurrencyName (currencyId) {
      if(this.form.assetsCurrency) {
        let currencies = window.currencies || []
        currencies = currencies.filter(currency => {
          return currency.id === currencyId
        })
        return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
      }
      return null
    },

    getSystemAssetAvailableBalance() {
      if(this.form.assetsCurrency) {
        let wallets
        wallets = this.selectedAccount.assetsWallets.filter(wallet => {
          return wallet.currencyId === this.form.assetsCurrency
        })
        return wallets[0]? wallets[0].systemAssetAvailableBalance : null
      }

    },

    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        if (this.limit && this.limit < this.form.amount) this.$toast.error(`Amount Entered Exceeds Limit`)
        return
      }

      if (this.form.amount <= parseFloat(this.fee)) {
        this.invalidDepositFee = true
        return
      }
      this.submitError = false
      const loader = this.$loading.show()
      let payload;
      if (this.form.deposit_type == 'ach') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          routingNumber: this.form.routing_number,
          accountNumber: this.form.account_number,
          bankAccountType: this.form.bank_account_type,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }else{
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }
      this.transaction = (await api.action("Transactions", "new", "Deposit", payload)).item
      this.$toast.success('Transaction successfully created.')
      loader.hide()
      this.addModal = false
      this.addConfirmModal = true
    },

    async depositAssets () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false
      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        amount: this.form.assetsAmount,
        totalAmount: this.totalAssets,
        transactionFee: this.assetsFee,
        transactionType: 'Asset',
        memo: this.form.memo,
        completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        currencyId: this.form.assetsCurrency
      }
      this.transaction = (await api.action("Transactions", "new", "DepositAssets", payload)).item
      this.$toast.success('Transaction successfully created.')
      loader.hide()
      this.addModal = false
      this.addConfirmModal = true
    },

    async withdraw () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      if (this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      }

      if (this.invalidAccountNumber) {
        return
      }

      if (this.invalidFundsCurrency) {
        return
      }

      this.submitError = false

      if(this.form.deposit_type == 'acc_to_acc') {
        let accounts = this.accountOptions
        if (!this.isCustomer) {
          accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
        }
        const fromProvider = (accounts.find(item => item.id === this.form.account))?.provider
        const toProvider = (accounts.find(item => item.accountNumber === this.form.a2a_account_number))?.provider
        if(toProvider){
          if(fromProvider != toProvider) {
            this.invalidToProvider = true
            return
          } else {
            this.invalidToProvider = false
          }
        }
      }

      const loader = this.$loading.show()
      let payload;
      if (this.form.deposit_type == 'international_wires') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          swift: this.form.swift,
          accountNumber: this.form.account_number,
          useIntermediaryBank: this.form.use_intermediary_bank,
          intermediaryBankName: this.form.intermediary_bank_name,
          intermediaryBankReference: this.form.intermediary_bank_reference,
          intermediaryBankIdentifier: this.form.intermediaryBankIdentifier,
          intermediaryBankRoutingNumber: this.form.intermediary_bank_routing_number,
          intermediaryBankSwiftCode: this.form.intermediary_bank_swift_code,
          intermediaryAddress:this.form.intermediary_address,
          bank_country: this.form.bank_country,
          bank_address: this.form.bank_address,
          bank_city: this.form.bank_city,
          bank_state: this.form.bank_state,
          bank_postal: this.form.bank_postal? this.form.bank_postal : '00000',
          iban:this.form.iban,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          address2: this.form.address2,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference == ''? null : this.form.reference,
          memo: this.form.memo,
          useFurtherCreditAccount: this.form.use_further_credit_account,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      }else if(this.form.deposit_type == 'checks') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          payee: this.form.payee,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference == ''? null : this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      }else if(this.form.deposit_type == 'ach') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          routingNumber: this.form.routing_number,
          bankName: this.form.bank_name,
          accountName: this.form.account_name,
          accountType: this.form.account_type,
          bankAccountType: 'checking',
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          address2: this.form.address2,
          city: this.form.city,
          postal: this.form.postal,
          bank_country: this.form.bank_country,
          bank_address: this.form.bank_address,
          bank_city: this.form.bank_city,
          bank_state: this.form.bank_state,
          bank_postal: this.form.bank_postal,
          accountNumber: this.form.account_number,
          reference: this.form.reference == ''? null : this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else if(this.form.deposit_type == 'domestic_wire') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          accountType: this.form.account_type,
          routingNumber: this.form.routing_number,
          accountNumber: this.form.account_number,
          bankName: this.form.bank_name,
          bankAccountType: 'checking',
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          address2: this.form.address2,
          city: this.form.city,
          postal: this.form.postal,
          bank_country: this.form.bank_country,
          bank_address: this.form.bank_address,
          bank_city: this.form.bank_city,
          bank_state: this.form.bank_state,
          bank_postal: this.form.bank_postal,
          reference: this.form.reference == ''? null : this.form.reference,
          useFurtherCreditAccount: this.form.use_further_credit_account,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          accountName: this.form.account_name,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          toAccountId: this.form.a2a_account_number,
          reference: this.form.reference == ''? null : this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      }
      const response = (await api.action("Transactions", "new", "Withdraw", payload))
      if(response.action.error){
        this.invalidAccountNumber = true
        loader.hide()
      } else{
        this.transaction = response.item
        this.$toast.success('Transaction successfully created.')
        loader.hide()
        this.addModal = false
        this.addConfirmModal = true
        this.invalidAccountNumber = false
        this.invalidFundsCurrency = false
      }
    },

    async withdrawAssets () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false
      const loader = this.$loading.show()
      if(this.form.sendingTo == 'WalletAddress'){
        let payload;
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.assetsAmount,
          totalAmount: this.totalAssets,
          transactionFee: this.assetsFee,
          transactionType: 'Asset',
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyId: this.form.assetsCurrency,
          label: this.form.payeeToName,
          outgoingWalletAddress: this.form.payeeToWalletAddress,
          tag: this.form.tag,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          sendingTo: this.form.sendingTo,
          reference: this.form.reference,
        }
        this.transaction = (await api.action("Transactions", "new", "WithdrawAssets", payload)).item
        this.$toast.success('Transaction successfully created.')
        loader.hide()
        this.addModal = false
        this.addConfirmModal = true
      }else{
        let payload;
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.assetsAmount,
          totalAmount: this.totalAssets,
          transactionFee: this.assetsFee,
          transactionType: 'Asset',
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyId: this.form.assetsCurrency,
          toAccountId: this.form.a2a_account_number,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          sendingTo: this.form.sendingTo,
          reference: this.form.reference,
        }
        const response = (await api.action("Transactions", "new", "WithdrawAccountTransferAssets", payload))
        if(response.action.errorWallet){
          this.errorWallet = true
          loader.hide()
        } else{
          this.transaction = response.item
          this.$toast.success('Transaction successfully created.')
          loader.hide()
          this.addModal = false
          this.addConfirmModal = true
          this.errorWallet = false
        }
      }
    },

    async getFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: this.form.deposit_type,
        entryType: this.isDeposit ?'Deposit' : 'Withdrawal'
      }
      let action = (await api.action("Transactions", "new", "GetFee", payload)).action
      this.legacyFee = action.fee
      this.fee = action.fee
      this.feePercent = action.feePercent
    },

    async getAssetsFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: 'Assets',
        entryType: this.isDeposit ?'Deposit' : 'Withdrawal'
      }
      this.assetsFee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
    },

    async getTemplateId () {
      const templateId = (await api.action("Transactions", "new", "GetTemplateID")).action.id
      this.form.template_name = 'Template - ' + templateId
    },

    async getLinkedAccounts () {
      const payload = {
        accountId: this.form.account,
      }
      this.linkedAccounts = (await api.action("LinkedAccount", "new", "GetLinkedAccounts", payload)).action.item
    },
  },

  async created () {
    this.getTemplateId()
    await this.$store.dispatch('fee_schedule/load')
    this.toRegistrations = (await api.list('Registration',{'corporate' : 1}, {}, {}, {}, ['user', 'contact', 'data', 'fees', 'terms', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents'])).items
  },

  validations () {
    let rules = {}
    if(this.form.depositType == 'Funds') {
      rules = {
        form: {
          amount: { required, decimal },
          account: { required },
          currency: { required },
          deposit_type: { required },
        }
      }

      if (this.limit) {
        rules.form.amount.limit = maxValue(this.limit)
      }
      if(!this.isDeposit){
        rules.form.amount.minLimit = minValue(this.fee)
        rules.form.reference = { minLength: minLength(3), maxLength: maxLength(60) }
      }

      if (this.showA2AAccountNumber) {
        rules.form.a2a_account_number = { required }
      }


      if (this.showPayee) {
        rules.form.payee = { required, alphaNum }
      }
      if (this.showAddressForm || this.showInternationalWireForm) {
        rules.form.country = { required }
        rules.form.city = { required }
        rules.form.state = { required }
        rules.form.postal = { required, alphaNum }
      }

      if (this.showAddressForm) {
        rules.form.address = { required, maxLength: maxLength(35) }
        rules.form.address2 = {  maxLength: maxLength(35) }
      }

      if (this.showInternationalWireForm) {
        rules.form.address = { required, maxLength: maxLength(60) }
        rules.form.address2 = {  maxLength: maxLength(60) }
      }

      if (this.form.use_further_credit_account) {
        rules.form.futher_credit_acc_name = { required }
        rules.form.futher_credit_acc_number = { required }
      }

      if (this.showACHForm) {
        rules.form.account_name = { required }
        rules.form.account_type = { required }
        rules.form.routing_number = { required, minLength: minLength(9), maxLength: maxLength(9) }
        rules.form.account_number = { required, minLength: minLength(4), maxLength: maxLength(17), numeric }
        if(this.form.deposit_type === 'domestic_wire') {
          rules.form.bank_name = { required }
        }
      }

      if (this.showInternationalWireForm) {
        rules.form.account_name = { required }
        rules.form.account_type = { required }
        rules.form.intermediaryBankIdentifier = { required }
        rules.form.bank_name = { required }
        rules.form.account_number = { required, minLength: minLength(4), maxLength: maxLength(34), alphaNumNoSpace }
        rules.form.swift = { required, swift }

        if (this.form.use_intermediary_bank) {
          //rules.form.intermediary_bank_name = { required }
          if(this.form.intermediaryBankIdentifier == 'routing') {
            rules.form.intermediary_bank_routing_number = { required, minLength: minLength(9), maxLength: maxLength(9) }
          }else{
            rules.form.intermediary_bank_swift_code = { required, swift }
          }
          rules.form.intermediary_address = {
            country: { required },
            city: { required },
            state: { required },
            postal: { required, alphaNum },
            address: { required }
          }
        }
      }

      if (this.form.save_as_template) {
        rules.form.template_name = { required }
      }
    }else{
      rules = {
        form: {
          account: { required },
          assetsAmount: { required, assetsAmount },
          assetsCurrency: { required}
        }
      }

      if (this.assetLimit) {
        rules.form.assetsAmount.limit = maxValue(this.assetLimit)
      }

      if(!this.isDeposit){
        if (this.form.sendingTo == 'AccountTransfer') {
          rules.form.a2a_account_number = { required }
        }else{
          rules.form.payeeToWalletAddress = { required }
        }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}

.withdraw-sum {
  font-size: 15px;
  line-height: 1.6;
}
</style>
