<template>
  <popup :open.sync="codeOpen" class="confirm-popup">
    <h2>New Employee Created</h2>
    <form class="pop-form">
      <div class="pf-row split">
        <h5>New Employee successfully created.</h5>
      </div>
      <div class="pf-row split">
        <h5>Activation Code is: {{ activationCode }}</h5>
      </div>
      <div class="pf-row buttons">
        <button class="pop-btn" @click.prevent="codeOpen = false">OK</button>
      </div>
    </form>
  </popup>
</template>
<script>
export default {
  data: ()=>({
    codeOpen: false,
    activationCode: ''
  }),
  methods: {
    show(activationCode) {
      this.codeOpen=true;
      this.activationCode = activationCode;
    },
  },
  mounted() {
    window.employeeCodePopup=this;
  },
}
</script>
