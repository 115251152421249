import { render, staticRenderFns } from "./IssueReportsManage.vue?vue&type=template&id=ed4efb84&scoped=true&"
import script from "./IssueReportsManage.vue?vue&type=script&lang=js&"
export * from "./IssueReportsManage.vue?vue&type=script&lang=js&"
import style0 from "./IssueReportsManage.vue?vue&type=style&index=0&id=ed4efb84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed4efb84",
  null
  
)

export default component.exports