<template>
  <div>
    <div class="cof-field mobinl" style="margin-top: 30px;">
      <label>&nbsp;</label>
    </div>
    <section class="content-filters">
      <div class="">
        <div class="cof-row">
          <div class="cof-field">
            <div class="cof-multi flex">
              <select v-model="statsType">
                <option></option>
                <option value="profile">Profile Stats</option>
                <option value="account">Account Stats</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table v-if="statsType == 'profile'" class="content-table">
        <tr>
          <th>Partner</th>
          <th>Submitted</th>
          <th>Denied</th>
          <th>New</th>
          <th>Approved</th>
          <th>Activation</th>
          <th>Active</th>
          <th>Review</th>
          <th>Restricted</th>
          <th>Hold</th>
          <th>Dormant</th>
          <th>Closed</th>
        </tr>
        <tr v-if="partner">
          <td><span>Partner</span>
            <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
          </td>
          <td><span>Submitted</span>
            {{ partner.stats.submitted }} (100%)
          </td>
          <td><span>Denied</span>
            {{ partner.stats.denied }} ({{ partner.stats.deniedPercent }}%)
          </td>
          <td><span>New</span>
            {{ partner.stats.new }} ({{ partner.stats.newPercent }}%)
          </td>
          <td><span>Approved</span>
            {{ partner.stats.approved }} ({{ partner.stats.approvedPercent }}%)
          </td>
          <td><span>activation</span>
            {{ partner.stats.activation }}
          </td>
          <td><span>active</span>
            {{ partner.stats.active }}
          </td>
          <td><span>review</span>
            {{ partner.stats.review }}
          </td>
          <td><span>restricted</span>
            {{ partner.stats.restricted }}
          </td>
          <td><span>hold</span>
            {{ partner.stats.hold }}
          </td>
          <td><span>dormant</span>
            {{ partner.stats.dormant }}
          </td>
          <td><span>closed</span>
            {{ partner.stats.closed }}
          </td>
        </tr>
      </table>
      <table v-if="statsType == 'account'" class="content-table">
        <tr>
          <th>Partner</th>
          <th>Accounts</th>
          <th>New</th>
          <th>Pending</th>
          <th>Open</th>
          <th>Restricted</th>
          <th>Hold</th>
          <th>Dormant</th>
          <th>Closed</th>
        </tr>
        <tr v-if="partner">
          <td><span>Partner</span>
            <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
          </td>
          <td><span>Accounts</span>
            {{ partner.accountStats.submitted }} (100%)
          </td>
          <td><span>New</span>
            {{ partner.accountStats.new }} ({{ partner.accountStats.newPercent }}%)
          </td>
          <td><span>Pending</span>
            {{ partner.accountStats.pending }} ({{ partner.accountStats.pendingPercent }}%)
          </td>
          <td><span>Open</span>
            {{ partner.accountStats.open }} ({{ partner.accountStats.openPercent }}%)
          </td>
          <td><span>restricted</span>
            {{ partner.accountStats.restricted }} ({{ partner.accountStats.restrictedPercent }}%)
          </td>
          <td><span>hold</span>
            {{ partner.accountStats.hold }} ({{ partner.accountStats.holdPercent }}%)
          </td>
          <td><span>dormant</span>
            {{ partner.accountStats.dormant }} ({{ partner.accountStats.dormantPercent }}%)
          </td>
          <td><span>closed</span>
            {{ partner.accountStats.closed }} ({{ partner.accountStats.closedPercent }}%)
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  components: {
    DateTimePicker
  },

  data () {
    return {
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      appliedFilters: null,
      statsType: "profile"
    }
  },

  computed: {
   
  },

  async created () {
  },

  methods: {
    search() {
      this.appliedFilters = {...this.searchForm}
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
    },
  }
}
</script>