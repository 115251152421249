<template>
  <div style="display: flex; justify-content: space-around;">
    <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
      <div class="popup-header">
        <h3>{{ isDeposit ? 'Deposit' : 'Create New Template' }}</h3>
        <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
      </div>
      <template v-if="!form.depositType">
        <div class="scrollable">
          <div class="content-form content-form-modern">
            <div class="form-row form-split justify-content-center">
              <form-field-select v-model="form.depositType" label="Template Type" :validator="$v.form.depositType" :options="depositTypeOptions" required />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="addModal">
          <div class="scrollable" v-if="form.depositType == 'Funds'">
            <template>
              <div class="content-form content-form-modern">
                <div class="form-row form-split">
                  <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                  
                </div>
                <div class="form-row form-split">
                  <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount" :label="`Withdrawal Method`" :validator="$v.form.deposit_type" :options="depositOptions" required />
                  <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
                </div>
              </div>
              <div class="invalid-balance">
                <div class="error-message" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
              </div>
              <div class="invalid-balance">
                <div class="error-message" v-if="invalidToProvider"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
              </div>
              <div class="sum">
                <div>Fee: <b>{{ fee | money }}</b></div>
              </div>

              <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" :transactionCountries="transactionCountries"/>

              <ach-form v-if="showACHForm" v-model="form" :v="$v.form"/>

              <div v-if="showPayee" class="content-form content-form-modern">
                <h3 class="subtitle">PAYEE INFORMATION</h3>
                <div class="form-row form-split">
                  <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
                </div>
              </div>

              <template v-if="showAddressForm">
                <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
                <address-form v-if="form.deposit_type !== 'domestic_wire'" v-model="form" :usOnly="form.deposit_type === 'domestic_wire'? false : true" :v="$v.form" :transactionCountries="transactionCountries" />
                <address-dom-form v-else v-model="form" :usOnly="form.deposit_type === 'domestic_wire'? false : true" :v="$v.form" :transactionCountries="transactionCountries" />
              </template>

              <div class="content-form content-form-modern">
                <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
                  <form-field-select v-model="form.a2a_account_number" label="Select Linked Account" :validator="$v.form.a2a_account_number" :options="linkedAccountOptions" required />
                  <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
                </div>
                <div class="form-row form-split">
                  <h3 class="subtitle">EXTRA INFORMATION</h3>
                </div>
                <div class="form-row form-split template-name">
                  <form-field-text v-model="form.memo" label="Memo" :maxlength="50" required />
                  <form-field-text v-model="form.template_name" :validator="$v.form.template_name" label="Template Name" :maxlength="50" required />
                </div>
                <div class="form-row form-max">
                  <form-field-text v-model="form.reference" label="Reference" :maxlength="60" required :validator="$v.form.reference" />
                </div>
              </div>
            </template>
            <div class="buttons">
              <button class="main" @click="withdraw()">Save</button>
              <button class="second" @click="open = false">Cancel</button>
            </div>
          </div>
          <div class="scrollable" v-else>
            <template>
              <div class="content-form">
                <div class="form-row form-split">
                  <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                  <form-field-select v-model="form.assetsCurrency" label="Assets Type" :validator="$v.form.assetsCurrency" :options="assetsCurrencyOptions" required />
                </div>
                <div class="form-row form-split">
                  <form-field-select v-model="form.sendingTo" label="Sending to" :validator="$v.form.sendingTo" :options="sendingToOptions" required />
                </div>
              </div>
              <div class="invalid-balance">
                <div class="error-message" v-if="invalidToProvider"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
              </div>
              <div class="sum">
                <div>Fee: <b>{{ assetsFee | money }}</b></div>
              </div>
              <div v-if="form.sendingTo == 'WalletAddress'" class="content-form paddingless">
                <div class="form-row form-split">
                  <form-field-text v-model="form.payeeToName" :validator="$v.form.payeeToName" label="Pay to Name" />
                </div>
                <div class="form-row form-max">
                  <form-field-text v-model="form.payeeToWalletAddress" :validator="$v.form.payeeToWalletAddress" label="Pay to Wallet Address" required />
                </div>
                <div class="form-row form-max">
                  <form-field-text v-model="form.tag" :validator="$v.form.tag" label="Destination Tag" required />
                </div>
              </div>
              <div class="content-form paddingless">
                <div v-if="form.sendingTo == 'AccountTransfer'" class="form-row form-split acc-to-acc">
                  <form-field-select v-model="form.a2a_account_number" label="Select Linked Account" :validator="$v.form.a2a_account_number" :options="linkedAccountOptions" required />
                  <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
                </div>
                <div class="form-row form-split">
                  <h3 class="subtitle">EXTRA INFORMATION</h3>
                </div>
                <div class="form-row form-split template-name">
                  <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
                  <form-field-text v-model="form.template_name" :validator="$v.form.template_name" label="Template Name" :maxlength="50" required />
                </div>
              </div>
            </template>
            <div class="buttons">
              <button class="main" @click="withdrawAssets()">Save</button>
              <button class="second" @click="open = false">Cancel</button>
            </div>
          </div>
        </template>
      </template>
    </div>
    </div>
</template>

<script>
  import { monbiAccountNumber, alphaNum, routingNumber, swift, alphaNumNoSpace } from '../../lib/validators'
  import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

  import AchForm from './panels/ACH'
  import AddressForm from './panels/Address'
  import AddressDomForm from './panels/AddressDom'
  import InternationalWireForm from './panels/InternationalWire'

  export default {
    props: {
      modalType: {
        type: String,
        required: true
      }
    },

    components: {
      AchForm,
      AddressForm,
      AddressDomForm,
      InternationalWireForm
    },

    data () {
      return {
        form: {
          account: null,
          currency: 'USD',
          deposit_type: null,
          amount: null,

          bank_name: null,
          account_type: null,
          routing_number: null,
          account_number: null,
          bank_account_type: null,

          a2a_account_number: null,

          payee: null,

          country: null,
          address: null,
          address2: null,
          city: null,
          state: null,
          postal: null,

          account_name: null,
          swift: null,
          iban: null,
          use_intermediary_bank: true,
          intermediary_bank_name: null,
          intermediary_bank_reference: null,
          intermediary_bank_routing_number: null,
          intermediaryBankIdentifier: null,
          intermediary_bank_swift_code: null,
          intermediary_address: {
            country: null,
            address: null,
            city: null,
            state: null,
            postal: null
          },

          use_further_credit_account: false,
          futher_credit_acc_name: null,
          futher_credit_acc_number: null,

          reference: null,
          memo: null,

          save_as_template: false,
          template_name: null,
          depositType: null,
          assetsCurrency: null,
          payeeToWalletAddress: null,
          tag: null,
          payeeToName: null,
          sendingTo: null,
        },

        transaction: null,
        open: true,
        addModal: true,
        addConfirmModal: false,
        invalidAccountNumber: false,
        invalidBalance: false,
        invalidDepositFee: false,
        invalidToProvider: false,
        fee: 0,
        nextFlag: false,
        assetsFee: 0,
        linkedAccounts: null,
        featureStatus: window.systemFeatures['Digital Assets'],
        featureFundsStatus: window.systemFeatures['Funds'],
        featureAssetsStatus: window.systemFeatures['Assets'],
      }
    },

    computed: {
      currentUser () {
        return window.appData.currentUser
      },

      isCustomer () {
        return this.currentUser.isCustomer
      },

      reg () {
        return this.$store.getters['registrations/get'](this.currentUser.regId)
      },

      showInternationalWireForm () {
        return !this.isDeposit && this.form.deposit_type === 'international_wires'
      },

      showFurtherCreditAccount () {
        return !this.isDeposit && ['domestic_wire', 'international_wires'].includes(this.form.deposit_type)
      },

      showPayee () {
        return !this.isDeposit && this.form.deposit_type === 'checks'
      },

      showAddressForm () {
        return !this.isDeposit && ['domestic_wire', 'checks'].includes(this.form.deposit_type)
      },

      showA2AAccountNumber () {
        return !this.isDeposit && this.form.deposit_type === 'acc_to_acc'
      },

      showACHForm () {
        return this.form.deposit_type === 'ach' || (!this.isDeposit && this.form.deposit_type === 'domestic_wire')
      },

      selectedAccount () {
        let accounts = this.accountOptions
        if (!this.isCustomer) {
          accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
        }
        const account = (accounts.find(item => item.id === this.form.account))
        return account
      },

      limit () {
        const keys = {
          ach: 'acc_to_acc_limit',
          checks: 'checks_limit',
          domestic_wire: 'wires_limit',
          international_wires: 'wires_limit',
          acc_to_acc: 'acc_to_acc_limit',
        }

        return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings[keys[this.form.deposit_type]]) : null
      },

      withdrawalLimit () {
        return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.mainAccount.systemAvailableBalance) : null
      },

      depositOptionsText () {
        if(this.form.deposit_type == "checks")
          return "Check Deposit"
        if(this.form.deposit_type == "domestic_wire")
          return "Domestic Wire"
        if(this.form.deposit_type == "international_wires")
          return "International Wire"
        if(this.form.deposit_type == "ach")
          return "ACH"
        return
      },

      withdrawOptionsText () {
        if(this.form.deposit_type == "checks")
          return "Check"
        if(this.form.deposit_type == "domestic_wire")
          return "Domestic Wire"
        if(this.form.deposit_type == "international_wires")
          return "International Wire"
        if(this.form.deposit_type == "ach")
          return "ACH"
        return
      },

      depositOptions () {
        const options = [
          {
            id: 'ach',
            text: 'ACH',
            if: this.selectedAccount?.profileSettings?.ach
      },
        {
          id: 'checks',
            text: 'Check',
          if: this.selectedAccount?.profileSettings?.checks
        },
        {
          id: 'domestic_wire',
            text: 'Domestic Wire',
          if: this.selectedAccount?.profileSettings?.domWires
        },
        {
          id: 'international_wires',
            text: 'International Wire',
          if: this.selectedAccount?.profileSettings?.intlWires
        }
      ]
        if (!this.isDeposit) options.push({
          id: 'acc_to_acc',
          text: 'Account Transfer',
          if: this.selectedAccount?.profileSettings?.acc_to_acc
      })
        return options.filter(opt => opt.if)
      },

      now () {
        return moment().subtract(window.serverDiff, 's')
      },

      isDeposit () {
        return this.modalType === 'deposit'
      },

      total () {
        if (this.isDeposit)
          return (parseFloat(this.form.amount)) || 0
        else
          return (parseFloat(this.form.amount) + parseFloat(this.fee)) || 0
      },

      accountOptions () {
        let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
        let accounts = []
        if(this.form.depositType == 'Funds') {
          registrations.forEach(reg => {
            if (reg.accounts ?.length){
              reg.accounts.forEach(account => {
                if(account.serviceProviders?.status != 'Disabled') {
                  if (this.isDeposit) {
                    if (account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                      accounts.push({
                        id: account.id,
                        regId: reg.id,
                        status: account.accountStatus,
                        text: `${account.accountNumber}-${reg.name}`,
                        accountNumber: account.accountNumber,
                        provider: account.provider,
                        profileSettings: account.profileSettings,
                        fundsCurrencies: account.fundsCurrencies,
                        assetsWallets: account.assetsWallets,
                        name: account.accountName,
                        systemAvailableBalance: account.systemAvailableBalance,
                        disabled: true,
                        serviceProviders: account.serviceProviders,
                      })
                    } else {
                      accounts.push({
                        id: account.id,
                        regId: reg.id,
                        status: account.accountStatus,
                        text: `${account.accountNumber}-${reg.name}`,
                        accountNumber: account.accountNumber,
                        provider: account.provider,
                        profileSettings: account.profileSettings,
                        fundsCurrencies: account.fundsCurrencies,
                        assetsWallets: account.assetsWallets,
                        name: account.accountName,
                        systemAvailableBalance: account.systemAvailableBalance,
                        serviceProviders: account.serviceProviders,
                      })
                    }
                  } else {
                    if (account.accountStatus == 'Restricted' || account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                      accounts.push({
                        id: account.id,
                        regId: reg.id,
                        status: account.accountStatus,
                        text: `${account.accountNumber}-${reg.name}`,
                        accountNumber: account.accountNumber,
                        provider: account.provider,
                        profileSettings: account.profileSettings,
                        fundsCurrencies: account.fundsCurrencies,
                        assetsWallets: account.assetsWallets,
                        name: account.accountName,
                        systemAvailableBalance: account.systemAvailableBalance,
                        disabled: true
                      })
                    } else {
                      accounts.push({
                        id: account.id,
                        regId: reg.id,
                        status: account.accountStatus,
                        text: `${account.accountNumber}-${reg.name}`,
                        accountNumber: account.accountNumber,
                        provider: account.provider,
                        profileSettings: account.profileSettings,
                        fundsCurrencies: account.fundsCurrencies,
                        assetsWallets: account.assetsWallets,
                        name: account.accountName,
                        systemAvailableBalance: account.systemAvailableBalance,
                        serviceProviders: account.serviceProviders,
                      })
                    }
                  }
                }
              })
            }
          })
        }else{
          registrations.forEach(reg => {
            if(reg.profileSettings) {
              if (reg.profileSettings.assets) {
                if (reg.accounts ?.
                length
              )
                {
                  reg.accounts.forEach(account => {
                    if(account.serviceProviders?.status != 'Disabled') {
                      if (this.isDeposit) {
                        if (account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                          accounts.push({
                            id: account.id,
                            regId: reg.id,
                            status: account.accountStatus,
                            text: `${account.accountNumber}-${reg.name}`,
                            accountNumber: account.accountNumber,
                            provider: account.provider,
                            profileSettings: account.profileSettings,
                            fundsCurrencies: account.fundsCurrencies,
                            assetsWallets: account.assetsWallets,
                            name: account.accountName,
                            systemAvailableBalance: account.systemAvailableBalance,
                            disabled: true
                          })
                        } else {
                          accounts.push({
                            id: account.id,
                            regId: reg.id,
                            status: account.accountStatus,
                            text: `${account.accountNumber}-${reg.name}`,
                            accountNumber: account.accountNumber,
                            provider: account.provider,
                            profileSettings: account.profileSettings,
                            fundsCurrencies: account.fundsCurrencies,
                            assetsWallets: account.assetsWallets,
                            name: account.accountName,
                            systemAvailableBalance: account.systemAvailableBalance,
                          })
                        }
                      } else {
                        if (account.accountStatus == 'Restricted' || account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                          accounts.push({
                            id: account.id,
                            regId: reg.id,
                            status: account.accountStatus,
                            text: `${account.accountNumber}-${reg.name}`,
                            accountNumber: account.accountNumber,
                            provider: account.provider,
                            profileSettings: account.profileSettings,
                            fundsCurrencies: account.fundsCurrencies,
                            assetsWallets: account.assetsWallets,
                            name: account.accountName,
                            systemAvailableBalance: account.systemAvailableBalance,
                            disabled: true
                          })
                        } else {
                          accounts.push({
                            id: account.id,
                            regId: reg.id,
                            status: account.accountStatus,
                            text: `${account.accountNumber}-${reg.name}`,
                            accountNumber: account.accountNumber,
                            provider: account.provider,
                            profileSettings: account.profileSettings,
                            fundsCurrencies: account.fundsCurrencies,
                            assetsWallets: account.assetsWallets,
                            name: account.accountName,
                            systemAvailableBalance: account.systemAvailableBalance,
                          })
                        }
                      }
                    }
                  })
                }
              }
            }
          })
        }

        if (this.isCustomer) {
          accounts = accounts.filter(account => ['Open', 'Active'].includes(account.status))
        } else {
          const categories = {}
          const availableStatuses = ['Open', 'Active', 'Restricted', 'Suspended', 'Dormant', 'Hold']
          availableStatuses.forEach(status => {
            const accs = accounts.filter(account => account.status === status)
            if (accs.length) categories[status] = accs
          })
          return categories
        }

        return accounts
      },

      depositTypeOptions () {
        let options = [];
        if(this.featureFundsStatus) {
          options.push(
            {
              id: 'Funds',
              text: 'Funds',
            },
          )
        }
        if(this.featureStatus) {
          if(this.featureAssetsStatus) {
            options.push(
              {
                id: 'Assets',
                text: 'Assets',
                disabled: !this.featureStatus
              }
            )
          }
        }
        return options
      },

      sendingToOptions () {
        const options = [
          {
            id: 'WalletAddress',
            text: 'Wallet Address',
          },
          {
            id: 'AccountTransfer',
            text: 'Account Transfer',
          }
        ]
        return options
      },

      currencyOptions () {
        let currencies = window.currencies || []
        let options = []
        options.push({
          id: 'USD',
          text: 'USD',
        })
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Funds') {
            if(this.selectedAccount){
              if(this.selectedAccount.accounts) {
                if (this.selectedAccount.accounts[0].fundsCurrencies) {
                  let fundCurrency = this.selectedAccount.accounts[0].fundsCurrencies.find(item => item.currencyId == currency.id)
                  if(fundCurrency){
                    if(fundCurrency.currencyEnabled) {
                      options.push({
                        id: currency.currencyNickname,
                        text: currency.currencyNickname,
                      })
                    }
                  }
                }
              }
            }
          }
        })
        return options
      },

      assetsCurrencyOptions () {
        let currencies = window.currencies || []
        let options = []
        let disabled = true
        currencies.forEach(currency => {
          if(this.selectedAccount) {
            if(this.selectedAccount.accounts){
              if(this.selectedAccount.accounts[0].assetsWallets){
                let wallets
                wallets = this.selectedAccount.accounts[0].assetsWallets.filter(wallet => {
                  return wallet.currencyId === currency.id
                })
                if(wallets[0] && wallets[0].incomingAssetWalletAddress) {
                  disabled = false
                }else{
                  disabled = true
                }
              }
            }
          }
          if(currency.systemCurrencyType == 'Assets') {
            options.push({
              id: currency.id,
              text: currency.currencyNickname,
              disabled: disabled
            })
          }
        })
        return options
      },

      linkedAccountOptions () {
        let accounts = []
        this.linkedAccounts.forEach(account => {
          accounts.push({
            id: account.accountNumber,
            text: account.text,
          })
        })

        return accounts
      },

      transactionCountries() {
        return this.selectedAccount?.serviceProviders?.transactionCountries
      },
      
      system () {
        return this.$store.getters['system/get'](1)
      }
    },

    watch: {
      open (open) {
        if (!open) this.$emit('close')
      },

      'form.account' () {
        this.form.deposit_type = null
        this.invalidToProvider = false
        this.getAssetsFee()
        this.getLinkedAccounts()
      },

      'form.amount' () {
        this.$v.form.amount.$touch()
        if(this.isDeposit){
          if (this.form.amount > parseFloat(this.fee)) {
            this.invalidDepositFee = false
            return
          } else {
            this.invalidDepositFee = true
            return
          }
        }

        if (this.withdrawalLimit < parseFloat(this.total)) {
          this.invalidBalance = true
          return
        } else {
          this.invalidBalance = false
          return
        }


      },

      'form.routing_number' () {
        this.$v.form.routing_number.$touch()
      },

      async 'form.a2a_account_number' () {
        this.$v.form.a2a_account_number.$touch()
        let payload = {
          toAccountId: this.form.a2a_account_number,
        }
        const response = (await api.action("Transactions", "new", "CheckAccount", payload))
        if(response.action.error){
          this.invalidAccountNumber = true
        }else{
          this.invalidAccountNumber = false
        }
      },

      'form.account_number' () {
        this.$v.form.account_number.$touch()
      },

      'form.deposit_type' () {
        this.getFee()
        this.invalidToProvider = false
      },
    },

    methods: {
      moveNextPopup () {
        if(this.form.depositType == 'Funds' || this.form.depositType == 'Assets') {
          this.nextFlag = true
        }
      },

      async add () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          if (this.limit && this.limit < this.form.amount) this.$toast.error(`Amount Entered Exceeds Limit`)
          return
        }

        if (this.form.amount <= parseFloat(this.fee)) {
          this.invalidDepositFee = true
          return
        }

        const loader = this.$loading.show()
        let payload;
        if (this.form.deposit_type == 'ach') {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            bankName: this.form.bank_name,
            accountType: this.form.account_type,
            routingNumber: this.form.routing_number,
            accountNumber: this.form.account_number,
            bankAccountType: this.form.bank_account_type,
            reference: this.form.reference,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName
          }
        }else{
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            reference: this.form.reference,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName
          }
        }
        this.transaction = (await api.action("Transactions", "new", "Deposit", payload)).item
        this.$toast.success('Transaction successfully created.')
        loader.hide()
        this.addModal = false
        this.addConfirmModal = true
      },

      async withdraw () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          return
        }

        if (this.invalidAccountNumber) {
          return
        }
        if(this.form.deposit_type == 'acc_to_acc') {
          let accounts = this.accountOptions
          if (!this.isCustomer) {
            accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
          }
          const fromProvider = (accounts.find(item => item.id === this.form.account))?.provider
          const toProvider = (accounts.find(item => item.accountNumber === this.form.a2a_account_number))?.provider
          if(toProvider){
            if(fromProvider != toProvider) {
              this.invalidToProvider = true
              return
            } else {
              this.invalidToProvider = false
            }
          }
        }

        const loader = this.$loading.show()
        let payload;
        if (this.form.deposit_type == 'international_wires') {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            accountName: this.form.account_name,
            bankName: this.form.bank_name,
            accountType: this.form.account_type,
            swift: this.form.swift,
            accountNumber: this.form.account_number,
            useIntermediaryBank: this.form.use_intermediary_bank,
            intermediaryBankName: this.form.intermediary_bank_name,
            intermediaryBankReference: this.form.intermediary_bank_reference,
            intermediaryBankIdentifier: this.form.intermediaryBankIdentifier,
            intermediaryBankRoutingNumber: this.form.intermediary_bank_routing_number,
            intermediaryBankSwiftCode: this.form.intermediary_bank_swift_code,
            intermediaryAddress:this.form.intermediary_address,
            bank_country: this.form.bank_country,
            bank_address: this.form.bank_address,
            bank_city: this.form.bank_city,
            bank_state: this.form.bank_state,
            bank_postal: this.form.bank_postal,
            iban:this.form.iban,
            country: this.form.country,
            state: this.form.state,
            address: this.form.address,
            address2: this.form.address2,
            city: this.form.city,
            postal: this.form.postal,
            reference: this.form.reference,
            memo: this.form.memo,
            useFurtherCreditAccount: this.form.use_further_credit_account,
            futherCreditAccName: this.form.futher_credit_acc_name,
            futherCreditAccNumber: this.form.futher_credit_acc_number,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            templateName: this.form.template_name,
            depositType: this.form.depositType
          }
        }else if(this.form.deposit_type == 'checks') {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            payee: this.form.payee,
            country: this.form.country,
            state: this.form.state,
            address: this.form.address,
            city: this.form.city,
            postal: this.form.postal,
            reference: this.form.reference,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            templateName: this.form.template_name,
            depositType: this.form.depositType
          }
        }else if(this.form.deposit_type == 'ach') {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            routingNumber: this.form.routing_number,
            bankName: this.form.bank_name,
            accountName: this.form.account_name,
            accountType: this.form.account_type,
            bankAccountType: 'checking',
            accountNumber: this.form.account_number,
            reference: this.form.reference,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            templateName: this.form.template_name,
            depositType: this.form.depositType
          }
        } else if(this.form.deposit_type == 'domestic_wire') {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            accountName: this.form.account_name,
            accountType: this.form.account_type,
            routingNumber: this.form.routing_number,
            accountNumber: this.form.account_number,
            bankName: this.form.bank_name,
            bankAccountType: 'checking',
            country: this.form.country,
            state: this.form.state,
            address: this.form.address,
            address2: this.form.address2,
            city: this.form.city,
            postal: this.form.postal,
            reference: this.form.reference,
            useFurtherCreditAccount: this.form.use_further_credit_account,
            futherCreditAccName: this.form.futher_credit_acc_name,
            futherCreditAccNumber: this.form.futher_credit_acc_number,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            templateName: this.form.template_name,
            depositType: this.form.depositType
          }
        } else {
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.amount,
            totalAmount: this.total,
            transactionFee: this.fee,
            transactionType: this.form.deposit_type,
            toAccountId: this.form.a2a_account_number,
            reference: this.form.reference,
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            templateName: this.form.template_name,
            depositType: this.form.depositType
          }
        }
        const response = (await api.action("Transactions", "new", "AddTemplate", payload))
        if(response.action.error){
          this.invalidAccountNumber = true
          loader.hide()
        } else{
          this.transaction = response.item
          this.$toast.success('Transaction successfully created.')
          loader.hide()
          this.open = false
          this.addModal = false
          this.invalidAccountNumber = false
          this.$parent.refreshTemplates()
        }
      },

      async withdrawAssets () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          return
        }

        const loader = this.$loading.show()
        if(this.form.sendingTo == 'WalletAddress'){
          let payload;
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.assetsAmount,
            totalAmount: this.totalAssets,
            transactionFee: this.assetsFee,
            transactionType: 'Asset',
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyId: this.form.assetsCurrency,
            label: this.form.payeeToName,
            outgoingWalletAddress: this.form.payeeToWalletAddress,
            tag: this.form.tag,
            saveAsTemplate: this.form.save_as_template,
            templateName: this.form.template_name,
            sendingTo: this.form.sendingTo,
            depositType: this.form.depositType
          }
          this.transaction = (await api.action("Transactions", "new", "AddTemplate", payload)).item
          this.$toast.success('Template successfully created.')
          loader.hide()
          this.addModal = false
          this.open = false
          this.$parent.refreshTemplates()
        }else{
          let payload;
          payload = {
            profileId: this.form.account,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.form.assetsAmount,
            totalAmount: this.totalAssets,
            transactionFee: this.assetsFee,
            transactionType: 'Asset',
            memo: this.form.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyId: this.form.assetsCurrency,
            toAccountId: this.form.a2a_account_number,
            saveAsTemplate: this.form.save_as_template,
            templateName: this.form.template_name,
            sendingTo: this.form.sendingTo,
            depositType: this.form.depositType
          }
          const response = (await api.action("Transactions", "new", "AddTemplate", payload))
          if(response.action.errorWallet){
            loader.hide()
          } else{
            this.transaction = response.item
            this.$toast.success('Template successfully created.')
            loader.hide()
            this.addModal = false
            this.open = false
            this.$parent.refreshTemplates()
          }
        }
      },

      async getFee () {
        const payload = {
          profileId: this.form.account,
          transactionType: this.form.deposit_type,
          entryType: this.isDeposit ?'Deposit' : 'Withdrawal'
        }
        this.fee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
      },

      async getTemplateId () {
        const templateId = (await api.action("Transactions", "new", "GetTemplateID")).action.id
        this.form.template_name = 'Template - ' + templateId
      },

      async getAssetsFee () {
        const payload = {
          profileId: this.form.account,
          transactionType: 'Assets',
          entryType: this.isDeposit ?'Deposit' : 'Withdrawal'
        }
        this.assetsFee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
      },

      async getLinkedAccounts () {
        const payload = {
          accountId: this.form.account,
        }
        this.linkedAccounts = (await api.action("LinkedAccount", "new", "GetLinkedAccounts", payload)).action.item
      },
    },

    async created () {
      await this.getTemplateId()
    },

    validations () {
      let rules = {}
      if(this.form.depositType == 'Funds') {
        rules = {
          form: {
            account: { required },
            currency: { required },
            deposit_type: { required }
          }
        }
        rules.form.reference = { minLength: minLength(3), maxLength: maxLength(60) }
        if (this.limit) {
          rules.form.amount.limit = maxValue(this.limit)
        }

        if (this.showA2AAccountNumber) {
          rules.form.a2a_account_number = { required, numeric, minLength: minLength(7), monbiAccountNumber }
        }

        if (this.showPayee) {
          rules.form.payee = { required, alphaNum }
        }
        if (this.showAddressForm || this.showInternationalWireForm) {
          rules.form.country = { required }
          rules.form.city = { required }
          rules.form.state = { required }
          rules.form.postal = { required, alphaNum }
        }

        if (this.showAddressForm) {
          rules.form.address = { required, maxLength: maxLength(35) }
          rules.form.address2 = {  maxLength: maxLength(35) }
        }

        if (this.showInternationalWireForm) {
          rules.form.address = { required, maxLength: maxLength(60) }
          rules.form.address2 = {  maxLength: maxLength(60) }
        }

        if (this.form.use_further_credit_account) {
          rules.form.futher_credit_acc_name = { required }
          rules.form.futher_credit_acc_number = { required }
        }

        if (this.showACHForm) {
          rules.form.account_type = { required }
          rules.form.routing_number = { required, numeric, minLength: minLength(9), maxLength: maxLength(9), routingNumber }
          rules.form.account_number = { required, numeric, minLength: minLength(4), maxLength: maxLength(17) }
        }

        if (this.showInternationalWireForm) {
          rules.form.account_name = { required }
          rules.form.account_type = { required }
          rules.form.bank_name = { required }
          rules.form.account_number = { required, alphaNum, minLength: minLength(4), maxLength: maxLength(34), alphaNumNoSpace }
          rules.form.swift = { required, swift }

          if (this.form.use_intermediary_bank) {
            if(this.form.intermediaryBankIdentifier == 'routing') {
              rules.form.intermediary_bank_routing_number = { required, minLength: minLength(9), maxLength: maxLength(9) }
            }else{
              rules.form.intermediary_bank_swift_code = { required, swift }
            }

            rules.form.intermediary_bank_name = { required }
            rules.form.intermediary_address = {
              country: { required },
              city: { required },
              postal: { required, alphaNum },
              address: { required }
            }
          }
        }

        rules.form.template_name = { required }
      }else{
        rules = {
          form: {
            account: { required },
            assetsCurrency: { required},
            sendingTo: { required}
          }
        }

        if(!this.isDeposit){
          if (this.form.sendingTo == 'AccountTransfer') {
            rules.form.a2a_account_number = { required }
          }else{
            rules.form.payeeToWalletAddress = { required }
          }
        }
      }

      return rules
    }
  }
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 30px;
  }
  .template-check {
    display: flex;
    align-items: center;

    input {
      display: inline-block !important;
      width: 20px !important;
      height: 20px !important;
    }

    label {
      display: inline !important;
      position: unset !important;

      &:before {
        position: unset !important;
      }
    }
  }

  input::selection {
    background: #0000ff;
    color: #fff;
  }

  .information p {
    margin: 5px;
  }

  .form-max {
    div {
      width: 100%;
    }
  }

  .content-tlist {
    background: none; 
  }

  .form-four >div {
    width: calc(25% - 18px);
    @media screen and (max-width: 760px) {
      width: 100%;
    }
  }
</style>