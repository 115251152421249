<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Terms & Conditions</h2>
      <div class="status">
        Status
        <status-icon :color="terms ? 'green' : null" />
      </div>
    </div>
    <table class="content-table" style="min-width: unset;">           
      <tr>
        <th>Date/Time</th>
        <th>IP Address</th>
        <th>Terms</th>
        <th>Privacy</th>
        <!--<th>Another</th>-->
        <th>File</th>
        <th v-if="registration.status !== 'Complete' && !mainAccount">Options</th>                       
      </tr>
      <tr>
        <td><span>Date/Time</span>
          <template v-if="!terms">N/A</template>
          <template v-else>{{ terms.createdAt | date('LL') }} {{ terms.createdAt | date('LT') }}</template>
        </td>         
        <td><span>IP Address</span>
          <template v-if="!terms">N/A</template>
          <template v-else>{{ terms.ip }}</template>
        </td>         
        <td><span>Terms</span>
          <template v-if="!terms">N/A</template>
          <template v-else>{{ terms.terms }}</template>
        </td>         
        <td><span>Privacy</span>
          <template v-if="!terms">N/A</template>
          <template v-else>{{ terms.privacy }}</template>
        </td>         
        <!--<td><span>Another</span>-->
          <!--<template v-if="!terms">N/A</template>-->
          <!--<template v-else>{{ terms.another }}</template>-->
        <!--</td>         -->
        <td><span>File</span>
          <template v-if="!terms">N/A</template>
          <template v-else>
            <a :href="`/download/RegDocument/${terms.file.id}`" target="_blank">
              {{ terms.file.file }}
            </a>
          </template>
        </td>         
        <td v-if="registration.status !== 'Complete' && !mainAccount">
          <span>Options</span>
          <button v-if="!terms" class="main" @click="create()">Create</button>
          <button v-else class="second" @click="remove()">Remove</button>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  components: { StatusIcon },
  
  computed: {
    terms () {
      return this.registration?.terms || null
    },

    mainAccount () {
      return this.registration.mainAccount || null
    }
  },

  methods: {
    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('reg_documents/delete', this.terms.file.id)
        await this.$store.dispatch('reg_terms/delete', this.terms.id)
        await this.$store.dispatch('registrations/update', { id: this.registration.id, payload: { terms: null, updatedAt: 'CURRENT_TIMESTAMP' } })
        await this.addNote('Terms and Conditions removed')
        this.$toast.success('Terms and Conditions successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async create () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('registrations/action', { id: this.registration.id, action: 'CreateTerms' })
        await this.$store.dispatch('registrations/update', { id: this.registration.id, payload: { updatedAt: 'CURRENT_TIMESTAMP' } })
        await this.addNote('Terms and Conditions created')
        this.$toast.success('Terms and Conditions successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}
</style>
