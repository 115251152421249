<template>
  <div>
    <template v-if="!open">
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>
      <section>
        <button v-if="$hasPerm('corporate-new-account') && permsNew" class="btn btn-success" @click="openModal()"><i class="el-icon-plus" /> New Account</button>
        <!--<button v-if="$hasPerm('corporate-deposit')" class="btn btn-success" @click="openTransModal('deposit')">+ Deposit</button>-->
        <!--<button v-if="$hasPerm('corporate-withdrawal')" class="btn btn-success" @click="openTransModal('withdraw')">- Withdrawal</button>-->
      </section>

      <add-deposit-withdrawal-modal
        v-if="modalType"
        :modal-type="modalType"
        @close="modalType = null" />

      <popup :open.sync="newAccount">
        <div class="popup-header">
          <h3>New Corporate Account</h3>
        </div>
        <template v-if="!successCorpAccount">
          <div class="scrollable">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select v-model="form.provider" :validator="$v.form.provider" label="Provider" :options="providersOptions" required />
                <form-field-text v-model="form.accountName" :validator="$v.form.accountName" label="Account Name" required :disabled="!form.provider" />
              </div>
              <div class="form-row form-split">
                <form-field-select v-model="form.accountType" :validator="$v.form.accountType" label="Account Type" :options="accountOptions" required :disabled="!form.provider"/>
                <form-field-select v-model="form.currency" :validator="$v.form.currency" label="Currency" :options="currencyOptions" required :disabled="!form.provider"/>
              </div>
            </div>
            <div class="acc-number" v-if="accountNumber">
              Account Number - {{accountNumber}}
            </div>
          </div>

          <div class="buttons">
            <button v-if="accountNumber" class="main" @click="confirmAccount()">Confirm</button>
            <button v-else class="main" @click="addNewAccount()">Submit</button>
            <button class="second" @click="closeModal()">Cancel</button>
          </div>
        </template>
        <template v-else>
          <div class="scrollable">
            <div class="acc-number">
             <template v-if="errorDate">
               Error - {{errorDate}}
               <p>{{ errorMessage }}</p>
               <p v-for="message in messages" style="text-align: center;"><b>{{ message }} is missing.</b></p>
               <p v-for="providerMessage in providerMessages" style="text-align: center;"><b>{{ providerMessage }}</b></p>
             </template>
              <template v-else>
                Success.
              </template>
            </div>
          </div>
          <div class="buttons">
            <button class="second" @click="closeModal()">Close</button>
          </div>
        </template>
      </popup>

      <div class="content-filters accordion">
        <div v-if="perms" :class="{'cof-box' : true, 'cof-box-active' : activeInfo}">
          <h4 class="accordion-headline" @click="activeInfo = !activeInfo">Corporate Information</h4>
          <h3 class="total-number" @click="activeInfo = !activeInfo">Click to expand</h3>
          <h4 style="margin-top: 16px;">Corporate Business Information</h4>
          <div class="content-form">
            <div class="form-row form-corporate">
              <form-field-text v-model="form.legal_name" :validator="$v.form.legal_name" label="Business Legal Name" :maxlength="50" required :disabled="updateIndentification"/>
              <form-field-text v-model="form.dba_name" :validator="$v.form.dba_name" label="Business DBA Name" :maxlength="50" required :disabled="updateIndentification"/>
              <form-field-date v-model="form.incorporation_date" :dirty="$v.form.$dirty" label="Business Incorporation Date" required :disabled="updateIndentification"/>
              <form-field-text v-model="form.tax_id" :validator="$v.form.tax_id" label="Business Tax ID" :maxlength="20" required :disabled="updateIndentification"/>
            </div>
            <div class="form-row form-corporate">
              <form-field-select v-model="form.formation_country" :validator="$v.form.formation_country" label="Country of Formation" :options="countryOptions" required :disabled="updateIndentification"/>
              <form-field-select v-if="stateOptions" v-model="form.formation_state" :validator="$v.form.formation_state" label="State/Province of Formation" :options="stateOptions" :disabled="updateIndentification" required />
              <form-field-text v-else v-model="form.formation_state" :validator="$v.form.formation_state" label="State/Province of Formation" :disabled="updateIndentification" required />
              <form-field-text v-model="form.address" :validator="$v.form.address" label="Business Address" :maxlength="50" required :disabled="updateIndentification"/>
              <form-field-text v-model="form.address2" label="Business Address 2" :maxlength="50" :disabled="updateIndentification"/>
            </div>
            <div class="form-row form-corporate">
              <form-field-text v-model="form.city" :validator="$v.form.city" label="Business City" required :disabled="updateIndentification"/>
              <form-field-select v-if="stateOptions" v-model="form.state" :validator="$v.form.state" label="Business State/Province" :options="stateOptions" :disabled="updateIndentification" required />
              <form-field-text v-else v-model="form.state" :validator="$v.form.state" label="Business State/Province" :disabled="updateIndentification" required />
              <form-field-text v-model="form.zip" :validator="$v.form.zip" label="Business ZIP / Postal Code" required :disabled="updateIndentification"/>
              <form-field-text v-model="form.phone" :validator="$v.form.phone" label="Business Phone" :maxlength="15" required :disabled="updateIndentification"/>
            </div>
            <div class="form-row form-corporate" style="justify-content: flex-start;">
              <form-field-text v-model="form.email" :validator="$v.form.email" label="Business Email" required :disabled="updateIndentification" style="margin-right: 7px;"/>
              <form-field-text v-model="form.website" :validator="$v.form.website" label="Business Website" required :disabled="updateIndentification" style="margin-right: 7px;"/>
              <form-field-select v-model="form.business_type" :validator="$v.form.business_type" label="Business Type" :options="businessTypequestions[0].options" :key="businessTypequestions[0].key" required :disabled="updateIndentification"/>
            </div>
          </div>

          <div v-for="(contact, index) in form.contacts" :key="index">
            <h4 style="margin-top: 16px;">Corporate Contact <template v-if="index"> {{ index + 1 }}</template> Information</h4>
            <corporate-contact v-model="form.contacts[index]" :v="$v.form.contacts.$each[index]" :initialLoad="initialLoad" :disabled="updateIndentification" :showSin="showSin"/>
          </div>
          <div class="content-form">
            <div class="form-row form-split">
              <div>
                <a v-if="form.contacts && form.contacts.length < 4" @click="addContact()">+ Add Another Contact</a>
              </div>
              <a v-if="form.contacts && form.contacts.length > 1" @click="removeContact()">- Remove Contact</a>
            </div>
          </div>

          <h4 style="margin-top: 16px;">Corporate Business Description</h4>
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select
                v-for="question in questions"
                :key="question.key"
                v-model="form.questions[question.key]"
                :label="question.question"
                :validator="$v.form.questions[question.key]"
                :options="question.options"
                required :disabled="updateIndentification" />
            </div>
          </div>
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-textarea v-model="form.description" label="Business Description" :rows="3" :maxlength="250" :disabled="updateIndentification"/>
            </div>
          </div>

          <h4 style="margin-top: 16px;">Corporate Documents</h4>
          <div class="content-form">
            <div class="form-row form-split">
              <div>
                <form-field-upload v-model="form.doc_incorporation" :validator="$v.form.doc_incorporation" label="Business Incorporation Documents" :disabled="updateIndentification" required />
                <a v-if="parsedDocuments['business_incorporation']" :href="`/download/RegDocument/${parsedDocuments['business_incorporation'].id}`" target="_blank" class="uploaded-document">
                  {{ parsedDocuments['business_incorporation'].file }}
                </a>
              </div>
              <div>
                <form-field-upload v-model="form.doc_address_confirmation" :validator="$v.form.doc_address_confirmation" label="Business Address Confirmation" :disabled="updateIndentification" required />
                <a v-if="parsedDocuments['business_address']" :href="`/download/RegDocument/${parsedDocuments['business_address'].id}`" target="_blank" class="uploaded-document">
                  {{ parsedDocuments['business_address'].file }}
                </a>
              </div>
            </div>
            <div v-for="(contact, index) in form.contacts" :key="'docs_' + index">
              <div class="form-row form-split">
                <div>
                  <form-field-upload v-model="form.contacts[index].doc_passport" :validator="$v.form.contacts.$each[index].doc_passport" :label="`Contact ${index + 1} Passport`" :disabled="updateIndentification" required />
                  <a v-if="parsedDocuments[`contact_passport-${index + 1}`]" :href="`/download/RegDocument/${parsedDocuments[`contact_passport-${index + 1}`].id}`" target="_blank" class="uploaded-document">
                    {{ parsedDocuments[`contact_passport-${index + 1}`].file }}
                  </a>
                </div>
                <div>
                  <form-field-upload v-model="form.contacts[index].doc_id_type" :validator="$v.form.contacts.$each[index].doc_id_type" :label="`Contact ${index + 1} ${form.contacts[index].id_type || 'Government Issued ID Type'}`" :disabled="updateIndentification" required />
                  <a v-if="parsedDocuments[`contact_government_issued_id-${index + 1}`]" :href="`/download/RegDocument/${parsedDocuments[`contact_government_issued_id-${index + 1}`].id}`" target="_blank" class="uploaded-document">
                    {{ parsedDocuments[`contact_government_issued_id-${index + 1}`].file }}
                  </a>
                </div>
              </div>
              <div class="form-row form-split">
                <div>
                  <form-field-upload v-model="form.contacts[index].doc_address_confirmation" :validator="$v.form.contacts.$each[index].doc_address_confirmation" :label="`Contact ${index + 1} Address Confirmation`" :disabled="updateIndentification" required />
                  <a v-if="parsedDocuments[`contact_address-${index + 1}`]" :href="`/download/RegDocument/${parsedDocuments[`contact_address-${index + 1}`].id}`" target="_blank" class="uploaded-document">
                    {{ parsedDocuments[`contact_address-${index + 1}`].file }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="cof-row" style="margin-bottom: 16px;">
            <template v-if="registrationId">
              <div v-if="updateIndentification" class="cof-field">
                <label>&nbsp;</label>
                <button v-if="$hasPerm('corporate-update-information')" class="action-button update" @click="update()">Update</button>
              </div>
              <div v-else class="cof-field">
                <label>&nbsp;</label>
                <button class="action-button save" @click="submit()">Submit</button>
              </div>
            </template>
            <template v-else>
              <div v-if="updateIndentification" class="cof-field">
                <label>&nbsp;</label>
                <button class="action-button save" @click="create()">Create</button>
              </div>
              <div v-else class="cof-field">
                <label>&nbsp;</label>
                <button class="action-button save" @click="save()">Save</button>
              </div>
            </template>
          </div>
        </div>
        <div v-if="permsNew" :class="{'cof-box' : true, 'cof-box-active' : activeAccounts}" >
          <h4 class="accordion-headline" @click="activeAccounts = !activeAccounts">Corporate Accounts</h4>
          <h3 class="total-number" @click="activeAccounts = !activeAccounts">Click to expand</h3>
          <section class="content-tlist">
            <table class="content-table">
              <tr>
                <th>Date Added</th>
                <th>Name</th>
                <th>Type</th>
                <th>Number</th>
                <th>Status</th>
                <th>Currency</th>
                <th>Provider</th>
                <th>Provider Account</th>
                <th>Status</th>
                <th>Available</th>
                <th>Balance</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <template v-for="item in sortAccounts">
                <tbody :key="item.id">
                  <tr>
                    <td><span>Date Added</span>{{item.createdAt | date('MM/D/YYYY ')}}</td>
                    <td><span>Account Name</span>{{item.accountName}}</td>
                    <td><span>Account Type</span>{{accountTypeText[item.accountType]}}</td>
                    <td><span>Account Number</span>{{item.accountNumber}}</td>
                    <td><span>Account Status</span>{{item.accountStatus}}</td>
                    <td><span>Currency</span>{{item.currency}}</td>
                    <td><span>Provider</span>{{item.serviceProviders.provider_name}}</td>
                    <td><span>Provider Account</span>{{item.providerAccountNumber}}</td>
                    <td><span>Status</span>
                      {{item.result}}
                    </td>
                    <td><span>Available</span>{{item.systemAvailableBalance | balance}}</td>
                    <td><span>Balance</span>{{item.systemTotalBalance | balance}}</td>
                    <td style="">
                      <span>Options</span>
                      <template v-if="item.accountStatus == 'Active'">
                        <button v-if="systemMaintenanceStatus" @click="updateCorpAccount(item.id)" class="action-button update">
                          Update
                        </button>  
                      </template>
                      <template v-else-if="item.serviceProviders.provider_name == 'Solid' || item.serviceProviders.provider_name == 'Solid Test'">
                        <template v-if="item.solidAccount[0]">
                         <!-- <button class="main" @click="confirmCorpAccount(item.id)">
                            Confirm
                          </button>-->
                        </template>
                        <template v-else>
                          <button v-if="item && item.kycStatus == 'approved' && item.kybStatus == 'approved'" @click="setAccount(item.id, item.provider)" class="action-button save">
                            Set Account
                          </button>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="item.result == 'Opened' && item.cipStatus == 'APP' && item.amlStatus == 'APP' && item.kycStatus == 'APP'">
                          <!--<button @click="confirmCorpAccount(item.id)" class="main">
                            Confirm
                          </button>-->
                        </template>
                        <template v-else>
                          <button v-if="systemMaintenanceStatus" @click="updateCorpAccount(item.id)" class="action-button update">
                            Update 
                          </button> 
                          <button @click="resubmit(item.id)" class="action-button save">
                            Resubmit
                          </button>
                          <template v-if="systemMaintenanceStatus">
                            <button v-if="!noforce" class="action-button" @click="forceCorpAccount(item.id)">Force</button>
                          </template>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">Account Agreement</td>
                    <td colspan="6">Final Account Agreement</td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <a :href="`/download/Account/${item.id}`" target="_blank">
                        {{ item.file }}
                      </a>
                    </td>
                    <td colspan="6">
                      <template v-if="registration">
                        <template
                          v-for="document in registration.documents.filter(document => document.type=='Account Agreement' && document.file.indexOf(item.accountNumber) >= 0)">
                          <a :key="document.id" :href="`/download/RegDocument/${document.id}`" target="_blank">
                            {{ document.file }}
                          </a>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="13">Compliance</td>
                  </tr>
                  <tr>
                    <td colspan="2">Name</td>
                    <td colspan="1">AML</td>
                    <td colspan="1">CIP</td>
                    <td colspan="1">KYC</td>
                    <!--<td colspan="1">KYC Documents</td>
                    <td colspan="5">Review</td>-->
                    <td colspan="7">Messages</td>
                  </tr>
                  <template v-if="item.serviceProviders.connectionType == 'Manual'">
                    <template v-if="item">
                      <tr>
                        <td colspan="2">
                          {{ registration.data.legal_name }}
                        </td>
                        <td>
                          <em :class="statusColorClasses[item.amlStatus]">{{ statusColorText[item.amlStatus] }}</em>  
                        </td>
                        <td>
                          <em :class="statusColorClasses[item.cipStatus]">{{ statusColorText[item.cipStatus] }}</em>
                        </td>
                        <td>
                          <em :class="statusColorClasses[item.kycStatus]">{{ statusColorText[item.kycStatus] }}</em>
                        </td>
                        <td colspan="7">
                          
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else-if="item.serviceProviders.provider_name == 'Solid' || item.serviceProviders.provider_name == 'Solid Test'">
                    <template v-if="item">
                      <tr>
                        <td colspan="2">
                          {{ registration.data.legal_name }}
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                          <em :class="statusColorClasses[item.kybStatus]">{{ statusColorText[item.kybStatus] }}</em>
                        </td>
                        <td colspan="7">
                          <button class="action-button cancel" @click="viewSolidBusinessMessage(item)">View</button>
                        </td>
                      </tr>
                      <tr v-for="contact in registration.data.contacts">
                        <td colspan="2">
                          {{ contact.firstName }} {{ contact.lastName }}
                        </td>
                        <td colspan="1">

                        </td>
                        <td colspan="1">

                        </td>
                        <td colspan="1">
                          <em :class="statusColorClasses[item.kycStatus]">{{ statusColorText[item.kycStatus] }}</em>
                        </td>
                        <td colspan="7">
                          <button class="action-button cancel" @click="viewSolidContactMessage(contact.firstName + ' ' + contact.lastName, item)">View</button>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else-if="item.response">
                    <tr v-for="include in item.response.included" v-if="include.type == 'contacts'">
                      <td colspan="2">{{ include.attributes.name }}</td>
                      <td colspan="1">
                        <template v-if="include.attributes['aml-cleared']"><em class="c1">Passed</em></template>
                        <template v-else><em class="c2">Pending</em></template>
                      </td>
                      <td colspan="1">
                        <template v-if="include.attributes['cip-cleared']"><em class="c1">Passed</em></template>
                        <template v-else><em class="c2">Pending</em></template>
                      </td>
                      <td colspan="1">
                        <template v-if="include.attributes['identity-confirmed']"><em class="c1">Passed</em></template>
                        <template v-else><em class="c2">Pending</em></template>
                      </td>
                      <!--<td colspan="1">
                        <template v-if="include.attributes['kyc-documents-verified']"><em class="c1">Passed</em></template>
                        <template v-else><em class="c2">Pending</em></template>
                      </td>
                      <td colspan="5">
                        <template v-if="include.attributes['ready-for-review']"><em class="c1">Passed</em></template>
                        <template v-else><em class="c2">Pending</em></template>
                      </td>-->
                      <td colspan="7">
                        <button class="action-button cancel" @click="viewMessage(include.id, include.attributes.name, item)">View</button>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="12"></td>
                  </tr>
                </tbody>
              </template>
            </table>
          </section>
        </div>
      </div>
    </template>
    <popup :open.sync="errorAccount" :width="400">
      <div class="popup-header">
        <h3>Error</h3>
      </div>
      <div class="scrollable">
        Error - {{errorDate}}
        <p>{{ errorMessage }}</p>
        <p v-for="message in messages" style="text-align: center;"><b>{{ message }} is missing.</b></p>
        <p v-for="providerMessage in providerMessages" style="text-align: center;"><b>{{ providerMessage }}</b></p>
      </div>
    </popup>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Admin PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" :class="{open: openForce}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Admin PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pinForce"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailedForce">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPinForce()">Cancel</a>
            <button class="pop-btn" @click="checkPinForce()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="messageOpen" v-if="contactItem">
      <h2>Messages</h2>
      <div v-if="contactItem.serviceProviders.provider_name == 'Solid' || contactItem.serviceProviders.provider_name == 'Solid Test'" class="pf-row split comliance-header">
        <template v-if="business">
          <p v-if="contactItem" class="subtitle">{{ statusColorText[contactItem.kybStatus] }}</p>
          <p v-if="contactItem" class="subtitle">{{ registration.data.legal_name }}</p>
          <p v-if="contactItem" class="subtitle">{{ contactItem.solidAccount? contactItem.solidAccount.accountNumber : ''}}</p>
          <p v-if="contactItem" class="subtitle">{{ contactItem.createdAt }}</p>
        </template>
        <template v-else>
          <p v-if="contactItem" class="subtitle">{{ statusColorText[contactItem.kycStatus] }}</p>
          <p v-if="contactItem" class="subtitle">{{ contactName }}</p>
          <p v-if="contactItem" class="subtitle">{{ contactItem.solidAccount? contactItem.solidAccount.accountNumber : ''}}</p>
          <p v-if="contactItem" class="subtitle">{{ contactItem.createdAt }}</p>
        </template>
      </div>
      <div v-else class="pf-row split comliance-header">
        <p v-if="contactItem" class="subtitle">{{ contactItem.result }}</p>
        <p v-if="contactItem" class="subtitle">{{ contactName }}</p>
        <p v-if="contactItem" class="subtitle">{{ contactItem.accountNumber }}</p>
        <p v-if="contactItem" class="subtitle">{{ contactItem.createdAt }}</p>
      </div>
      <h3 class="subtitle">Review Details</h3>
      <div class="pf-row split">
        <template v-if="contactItem">
          <p v-if="contactId && contactItem.reviewDetail">{{contactItem.reviewDetail[contactId]}}</p>
        </template>
      </div>
      <div v-if="contactItem.serviceProviders.provider_name == 'Solid' || contactItem.serviceProviders.provider_name == 'Solid Test'" class="pf-row split">
        <div class="pf-field">
          <h3 class="subtitle">Review Code</h3>
          <template v-if="contactItem">
            <p>{{contactItem.reviewCode}}</p>
          </template>
        </div>
        <div class="pf-field">
          <h3 class="subtitle">Review Message</h3>
          <template v-if="contactItem">
            <p>{{contactItem.reviewMessage}}</p>
          </template>
        </div>
      </div>
      <div class="pf-row split">
        <div class="pf-field">
          <h3 class="subtitle">AML Message</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.amlMessage">{{contactItem.amlMessage[contactId]}}</p>
          </template>
        </div>
        <div class="pf-field">
          <h3 class="subtitle">AML Message Details</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.amlMessageDetail">{{contactItem.amlMessageDetail[contactId]}}</p>
          </template>
        </div>
      </div>
      <div class="pf-row split">
        <div class="pf-field">
          <h3 class="subtitle">CIP Message</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.cipMessage">{{contactItem.cipMessage[contactId]}}</p>
          </template>
        </div>
        <div class="pf-field">
          <h3 class="subtitle">CIP Message Details</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.cipMessageDetail">{{contactItem.cipMessageDetail[contactId]}}</p>
          </template>
        </div>
      </div>
      <div class="pf-row split">
        <div class="pf-field">
          <h3 class="subtitle">KYC Message</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.kycMessage">{{contactItem.kycMessage[contactId]}}</p>
          </template>
        </div>
        <div class="pf-field">
          <h3 class="subtitle">KYC Message Details</h3>
          <template v-if="contactItem">
            <p v-if="contactId && contactItem.kycMessageDetail">{{contactItem.kycMessageDetail[contactId]}}</p>
          </template>
        </div>
      </div>
      <div v-if="business" class="pf-row split">
        <div class="pf-field">
          <h3 class="subtitle">KYB Message</h3>
          <template v-if="contactItem">

          </template>
        </div>
        <div class="pf-field">
          <h3 class="subtitle">KYB Message Details</h3>
          <template v-if="contactItem">
            <p>{{contactItem.kycResults}}</p>
          </template>
        </div>
      </div>
      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="messageOpen=false">Close</a>
      </div>
    </popup>
  </div>
</template>

<script>
import BasicForm from '../../components/ui/forms/Form'
import FormFieldText from '../../components/FormFieldText'
import FormFieldDate from '../../components/FormFieldDate'
import FormFieldSelect from '../../components/FormFieldSelect'
import CorporateContact from './SystemCorporateContact'

import helperMixin from '../../components/common/helper-mixin'

import { mustBeTrue, alpha, alphaNum } from '../../lib/validators'
import { required, numeric, email, url, requiredIf, maxLength, between } from 'vuelidate/lib/validators'

const contact = {
  type: null,
  country: null,
  city: null,
  state: null,
  zip: null,
  address: null,
  address2: null,
  sex: null,
  sin: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
  passport: null,
  id_type: null,
  id_number: null,
  ownership_percentage: null,
  doc_passport: null,
  doc_id_type: null,
  doc_address_confirmation: null
}

export default {
  mixins: [helperMixin],

  components: {
    BasicForm,
    FormFieldText,
    FormFieldDate,
    FormFieldSelect,
    CorporateContact
  },

  data () {
    return {
      activeInfo: false,
      activeAccounts: false,
      activeTransactions: false,

      updateIndentification: true,

      newAccount: false,

      currencyOptions: window.currencyOptions,

      accountOptions: [
        {
          id: 'corpAccount',
          text: 'Corporate'
        },
        {
          id: 'operAccount',
          text: 'Operating'
        },
        {
          id: 'feesAccount',
          text: 'Fees'
        },
        {
          id: 'miscAccount',
          text: 'Miscellaneous'
        }
      ],
      form: {
        legal_name: null,
        dba_name: null,
        incorporation_date: null,
        description: null,
        tax_id: null,
        formation_country: null,
        formation_state: null,
        address: null,
        address2: null,
        zip: null,
        city: null,
        state: null,
        phone: null,
        email: null,
        website: 'https://',
        business_type: null,
        contacts: [{ ...contact }],
        doc_incorporation: null,
        doc_address_confirmation: null,
        accountName: null,
        accountType: null,
        currency: null,
        provider: null,
        questions: {
          purpose_of_account: null,
          association_with_other_accounts: null,
          source_of_assets_and_income: null,
          intended_use_of_account: null,
          anticipated_types_of_assets: null,
          anticipated_monthly_cash_volume: null,
          anticipated_trading_patterns: null,
          anticipated_monthly_transactions_incoming: null,
          anticipated_monthly_transactions_outgoing: null,
        },
      },

      initialLoad: false,
      documents: [],

      pin: '',
      pinFailed: false,
      open: true,

      pinForce: '',
      pinFailedForce: false,
      openForce: false,

      registrationId: null,
      accountNumber: null,
      accounts: null,
      registration: null,
      successCorpAccount: false,
      errorMessage: null,
      errorDate: null,
      modalType: null,
      errorAccount: false,
      messages: null,
      providerMessages: null,

      forceId:null,
      noforce: appData.customEnv.noforce,

      showSin: false,

      contactId: null,
      contactItem: null,
      contactName: null,
      messageOpen: false,
      systemMaintenanceStatus: window.maintenance,
      perms: window.appData.currentUser?.role?.perms?.includes('corporate-update-information') || window.appData.currentUser?.perms?.includes('corporate-update-information'),
      permsNew: window.appData.currentUser?.role?.perms?.includes('corporate-new-account') || window.appData.currentUser?.perms?.includes('corporate-new-account'),
      business: false,
    }

  },

  computed: {
    parsedDocuments () {
      return this.documents.reduce((acc, item) => {
        const key = item.docAdditional ? `${item.docFor}-${item.docAdditional}` : item.docFor
        acc[key] = item
        return acc
      }, {})
    },

    sortAccounts () {
      const data = this.accounts || []
      return data.sort((a, b) => {
        if (a.createdAt > b.createdAt) return 1
        if (a.createdAt < b.createdAt) return -1
        return 0
      })
    },

    contactTypeOptions () {
      return {
        'Beneficial Owner': 'Beneficial Owner',
        'Authorized Person': 'Authorized Person'
      }
    },

    countryOptions () {
      return window.countriesOptions
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.formation_country)) return null

      return window.states[this.form.formation_country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providersOptions () {
      return [
        ...this.providers.filter(provider => provider && provider.status === 'Active' || provider.status === 'Testing').map(provider => ({
          id: provider.id,
          text: provider.provider_name
        }))
      ]      
    },

    serviceProviderName () {
      if(this.form.provider){
        var serviceProvider = this.providers.find(provider => provider.id === this.form.provider)
        return serviceProvider.provider_name
      }else{
        return null;
      }
    },

    selectedProvider () {
      if(this.form.provider){
        var serviceProvider = this.providers.find(provider => provider.id === this.form.provider)
        return serviceProvider
      }else{
        return null;
      }
    },

    ownershipError () {
      if (this.accountType !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.type === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    accountTypeText () {
      return {
        corpAccount: 'Corp',
        operAccount: 'Oper',
        feesAccount: 'Fees',
        miscAccount: 'Misc',
      }
    },

    groupedQuestions () {
      const list = []

      let group = []
      this.questions.forEach(question => {
        group.push(question)
        if (group.length === 2) {
          list.push(group)
          group = []
        }
      })

      if (group.length) {
        list.push(group)
      }

      return list
    },

    businessTypequestions () {
      const questions = [
        {
          key: 'business_type',
          question: 'Business Type',
          options: [
            'Sole Proprietor',
            'Single Member LLC',
            'LLC',
            'General Partnership',
            'Corporation',
            'Publicly Traded Corporation',
            'Association',
            'Non Profit',
            'Government Organization',
            'Revocable Trust',
            'Irrevocable Trust',
            'Estate',
          ]
        }
      ]

      return questions.map(question => ({
        key: question.key,
        question: question.question,
        options: question.options.reduce((acc, option) => {
          acc[option] = option
          return acc
        }, {})
      }))
    },

    questions () {
      return window.questions
    },

    statusColorClasses () {
      return {
        APP: 'c1',
        approved: 'c1',
        inReview: 'c2',
        declined: 'c3',
        submitted: 'c2',
      }
    },

    statusColorText () {
      return {
        APP: 'APP',
        approved: 'Approved',
        inReview: 'In Review',
        declined: 'Declined',
        active: 'Active',
        submitted: 'Submitted',
      }
    },
  },

  async created () {
    const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
    const registration = registrations[0]
    if (registration) {
      this.registrationId = registration.id
      this.registration = registration
      registration.data.doc_address_confirmation = null
      registration.data.doc_incorporation = null
      registration.data.contacts.forEach((contact, index) => {
        contact.doc_passport = null
        contact.doc_address_confirmation = null
        contact.doc_id_type = null
      })
      registration.data.questions = registration.data.questions || {
        purpose_of_account: null,
        association_with_other_accounts: null,
        source_of_assets_and_income: null,
        intended_use_of_account: null,
        anticipated_types_of_assets: null,
        anticipated_monthly_cash_volume: null,
        anticipated_trading_patterns: null,
        anticipated_monthly_transactions_incoming: null,
        anticipated_monthly_transactions_outgoing: null,
      };
      this.form = registration.data
      this.documents = registration.documents
      this.accounts = registration.accounts
      this.$nextTick(() => {
        this.initialLoad = true
      })
    }

    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  watch: {
    'form.formation_country' (value) {
      if(this.initialLoad) {
        this.form.formation_state = null
        this.form.state = null
      }
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    load () {

    },

    openTransModal (type) {
      this.modalType = type
    },

    update () {
      this.updateIndentification = false
    },

    create () {
      this.updateIndentification = false
    },

    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const payload = {
        data: this.form,
        corporate: 1,
        profileSettingsId: '00001',
        createdAt: 'CURRENT_TIMESTAMP'
      }
      try {
        const response = await this.$store.dispatch('registrations/create', payload)
        this.registrationId = response.id
        await this.uploadDocuments(response.id)
        this.$toast.success('Corporate information sussessfully created.')
        this.updateIndentification = true
      } catch (e) {
        this.$toast.error('There was an unknown error. Please try again later or contact the support.')
      }
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const payload = {
        data: this.form,
      }
      const id = this.registrationId
      try {
        const response = await this.$store.dispatch('registrations/update', {
          id,
          payload
        })
        await this.uploadDocuments(response.id)
        this.$toast.success('Corporate information sussessfully updated.')
        this.updateIndentification = true
        this.showSin = false
      } catch (e) {
        this.$toast.error('There was an unknown error. Please try again later or contact the support.')
      }
    },

    openModal () {
      this.newAccount = true
    },

    closeModal () {
      this.newAccount = false
      this.accountNumber = null
      this.form.accountName = null
      this.form.accountType = null
      this.form.currency = null
      this.form.provider = null
      this.successCorpAccount = false
      this.errorMessage = null
    },

    removeContact () {
      this.form.contacts.splice(this.form.contacts.length - 1, 1)
    },

    addContact () {
      this.form.contacts.push({ ...contact })
    },

    uploadDocuments (id) {
      const name = this.form.legal_name      
      this.documentPromise(id, `${name}-Business Inc Documents`, 'business_incorporation', null, 'Business Incorporation', 'Business Incorporation', this.form.tax_id, this.form.doc_incorporation)
      this.documentPromise(id, `${name}-business Address Confirm`, 'business_address', null, 'Business Address Confirmation', 'Address', 'Address', this.form.doc_address_confirmation)
      this.form.contacts.forEach((contact, index) => {
        const prefix = `Contact ${index + 1} `
        const contactsName = `${contact.firstName} ${contact.lastName}`
        this.documentPromise(id, `${contactsName}-Passport-${contact.passport}`, 'contact_passport', index + 1, prefix + 'Passport', 'Passport', contact.passport, contact.doc_passport)
        this.documentPromise(id, `${contactsName}-${contact.id_type}-${contact.id_number}`, 'contact_government_issued_id', index + 1, prefix + 'Government Issued ID', contact.id_type, contact.id_number, contact.doc_id_type)
        this.documentPromise(id, `${contactsName}-Address Confirm`, 'contact_address', index + 1, prefix + 'Address Confirmation', 'Address', 'Address', contact.doc_address_confirmation)
      })
    },

    async documentPromise (regId, filename, docFor, docAdditional, description, type, number, file) {
      if (file  instanceof File) {
        const doc = this.documents.find(document => document.docFor === docFor && (!docAdditional || (`${docAdditional}` === document.docAdditional)))
        let docId = doc?.id || null
        if (!docId) {
          const document = await this.$store.dispatch('reg_documents/create', {
            regId,
            type,
            docFor,
            docAdditional,
            description,
            number,
            createdAt: 'CURRENT_TIMESTAMP',
            deletable: false
          })
          docId = document.id
        }
        if (file) {
          const doc = await this.$store.dispatch('reg_documents/upload', {
            id: docId,
            file: this.changeFileName(file, filename),
            params: {
              virusscan: true
            }
          })
          const idx = this.documents.findIndex(d => d.docFor === doc.docFor && d.docAdditional === doc.docAdditional)
          if (idx)
            this.documents.splice(idx, 1)
          this.documents.push(doc)
        }
      }
    },

    cancelPin() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    cancelPinForce() {
      this.openForce = false
      this.pinForce = ''
      this.pinFailedForce = false
    },

    async checkPinForce() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pinForce })).action
      if(action.pin) {
        this.pinFailedForce = false
        this.openForce = false
        const loader = this.$loading.show()
        const account = (await api.action("Account", this.forceId, "ForceCorpAccount")).action
        this.load()
        loader.hide()
      }else{
        this.pinFailedForce = true
      }
    },

    async addNewAccount(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      const payload = {
        data: this.form,
        registrationId: this.registrationId,
      }
      const account = (await api.action("Account", "new", "NewCorpAccount", payload)).action
      this.accountNumber = account.accountNumber
    },

    async confirmAccount(){
      this.$v.$touch()
      if (this.$v.$invalid) return
      const loader = this.$loading.show()
      const payload = {
        data: this.form,
        registrationId: this.registrationId,
      }
      let action
      if(this.selectedProvider.connectionType == 'Manual') {
        action= (await api.action("Account", "new", "ConfirmCorpAccountManual", payload)).action
      }else if(this.serviceProviderName == 'Solid Test' || this.serviceProviderName == 'Solid') {
        action= (await api.action("Account", "new", "ConfirmCorpAccountSolid", payload)).action
      }else{
        action = (await api.action("Account", "new", "ConfirmCorpAccount", payload)).action
      }

      if(action.error){
        this.errorMessage = action.errorMessage
        this.accountNumber = null
        this.errorDate = action.errorDate
        this.messages = action.message
        this.providerMessages = action.providerMessage
      }
      this.successCorpAccount = true
      this.load()
      loader.hide()
    },

    async setAccount (id, provider) {
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('accounts/action', {
            id: id,
            action: 'SetAccount',
            payload: {
              provider: provider,
          registrationId: this.registration.id
      }
      })).action

        if(action.error){
          this.errorMessage = action.errorMessage
          this.accountNumber = null
          this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
          //this.$toast.success('Compliance scan successfully ran.')
        }
        this.load()
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async resubmit (id) {
      const loader = this.$loading.show()
      try {
        const action = (await api.action("Account", id, "ResubmitAccount")).action
        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage
          this.errorDate = action.errorDate
        }else{
          this.errorAccount = false
        }
        this.load()
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async updateCorpAccount(id){
      this.$v.$touch()
      if (this.$v.$invalid) return
      const loader = this.$loading.show()
      const account = (await api.action("Account", id, "UpdateCorpAccount")).action
      this.load()
      loader.hide()
    },

    async forceCorpAccount(id){
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.openForce = true
      this.forceId = id
    },

    async confirmCorpAccount(id){
      this.$v.$touch()
      if (this.$v.$invalid) return
      const loader = this.$loading.show()
      const account = (await api.action("Account", id, "ConfirmFinalAccount")).action
      this.load()
      loader.hide()
    },

    async checkFees(id){
      const account = (await api.action("Account", id, "CheckFees")).action
      this.load()
    },

    async load(){
      const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
      const registration = registrations[0]
      if(registration){
        this.registration = registration
        this.accounts = registration.accounts
        this.$nextTick(() => {
          this.initialLoad = true
        })
      }
    },

    viewMessage(contactId, contactName, contactItem) {
      this.contactId = contactId
      this.contactName = contactName
      this.contactItem = contactItem
      this.messageOpen = true
    },

    viewSolidBusinessMessage(contactItem, ) {
      this.messageOpen = true
      this.business = true
      this.contactItem = contactItem
    },

    viewSolidContactMessage(contactName, contactItem) {
      this.contactName = contactName
      this.business = false
      this.messageOpen = true
      this.contactItem = contactItem
    },
  },

  validations () {
    const rules = {
      form: {
        legal_name: {required, alphaNum},
        dba_name: {required, alphaNum},
        incorporation_date: {required},
        tax_id: {required, alphaNum},
        formation_country: {required},
        formation_state: {required},
        address: {required},
        city: {required},
        state: {required},
        phone: {required, numeric},
        email: {required, email},
        website: {required, url},
        business_type: {required},
        zip: {required, alphaNum},
        contacts: {
          $each: {
            type: {required},
            country: {required},
            city: {required},
            state: {required},
            zip: {required, alphaNum},
            address: {required},
            sex: {required},
            sin: {required},
            dob: {required},
            firstName: {required, alphaNum},
            lastName: {required, alphaNum},
            phone: {required, numeric},
            mobile: {numeric},
            email: {required, email},
            passport: {required, alphaNum},
            id_type: {required},
            id_number: {required, alphaNum},
            ownership_percentage: {
              required: requiredIf(nested => nested.type === 'Beneficial Owner'),
              numeric,
              between: between(1, 100)
            },
          }
        },
        questions: {
          business_industry: { required },
        }
      }
    }
    if(this.newAccount){
      rules.form.accountName = { required }
      rules.form.accountType = { required }
      rules.form.currency = { required }
      rules.form.provider = { required }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .accordion-headline {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px; 
    width: 100%;
    height: 19px;
    padding: 16px 0;
    cursor: pointer;
  }
  .cof-box {
    position: relative;
    height: 51px;  
    overflow: hidden;
    padding: 0 16px; 
  }
  .cof-box::after {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    left: unset;
    width: 13px;
    height: 1px;
    transition: .25s ease-out;
    background-color: #0480c6;
    transform: rotate(90deg);
  }
  .cof-box::before {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    width: 13px;
    height: 1px;
    transition: .25s ease-out;
    background-color: #0480c6;
  }
  .cof-box-active {
    height: auto;
  }
  .cof-box-active::before {
    transform: rotate(180deg);
  }
  .cof-box-active::after {
    transform: rotate(180deg);
  }
  .content-tlist {
    margin: 0 0 16px;
  }
  .acc-number {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
//   .popup-header {
//   display: flex;
//   justify-content: space-between;
//   padding-right: 50px;
//   padding-bottom: 12px;
//   margin-bottom: 24px;
//   border-bottom: 1px solid #99aabb;

//   h3 {
//     color: #1f2d4e;
//     font-size: 20px;
//     font-weight: bold;
//   }
// }
.fee-checkbox {
  -webkit-appearance: checkbox;
}
.uploaded-document {
  display: block;
  margin-top: -12px;
}
</style>
