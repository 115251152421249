<template>
  <div>
    <template>
      <template v-if="perms">
        <div class="content-filters">
          <basic-form>
            <div class="cof-row dates">
              <div class="cof-field">
                <label>Start Date</label>
                <div class="cof-multi flex">
                  <date-time-picker
                    v-model="form.fromDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    min-date="2021-09-01">
                  </date-time-picker>
                </div>
              </div>
              <div class="cof-field">
                <label>End Date</label>
                <div class="cof-multi flex">
                  <date-time-picker
                    v-model="form.toDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    min-date="2021-09-01"
                    :max-date="date2"
                  >
                  </date-time-picker>
                </div>
                <div class="error-message" v-if="toDateError"><span>Invalid End Date.</span></div>
              </div>
            </div>
            <div class="cof-row buttons">
              <div class="cof-field">
                <label>&nbsp;</label>
                <button class="second" @click="refreshTransactions()" :disabled="toDateError || (form.fromDate == null) || (form.toDate == null)">Generate</button>
              </div>
              <div class="cof-field">
                <label>&nbsp;</label>
                <button class="second" @click="reset()">Reset</button>
              </div>
            </div>
            <div class="cof-row run">
              <div class="cof-field">
                <label>&nbsp;</label>
                <a v-if="isRun" :href="`/export_fees_report/${form.fromDate}/${form.toDate}`" target="_blank" class="btn main">Export</a>
              </div>
            </div>
          </basic-form>
        </div>
        <section class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date</th>
              <th>Account</th>
              <th>Transaction</th>
              <th>Entry</th>
              <th>Amount</th>
              <th>Reference</th>
            </tr>
            <tr v-for="(item, i) in filteredData" :key="i">
              <td><span>Date</span>{{ item.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
              <td><span>Account</span><a :href="`/system-customers/registrations/${item.regId}?tab=Accounts`">{{ item.accountId }}</a></td>
              <td><span>Account</span>{{ item.transactionId }}</td>
              <td><span>Entry</span>{{ item.entryType }}</td>
              <td><span>Amount</span>
                <template v-if="item.currencyType == 'Assets'">{{ parseFloat(item.assetAmount) }}</template>
                <template v-else>{{ item.currencySymbol }} {{ item.amount | balance_without_symbol }}</template>
              </td>
              <td><span>Reference</span>{{ item.reference }}</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </template>
    </template>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker'
  import BasicForm from '../../components/ui/forms/Form'
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    name: 'fees-report',

    components: {
      VueEnglishdatepicker,
      BasicForm,
      DateTimePicker
    },

    data() {
      return {
        form: {
          fromDate: null,
          toDate: null,
        },
        results: null,
        total: null,
        isRun: false,

        toDateError: false,

        page: 1,
        perPage: 25,
        pages: [],

        loading: false,
        perms: window.appData.currentUser?.role?.perms?.includes('fees-detail-report') || window.appData.currentUser?.perms?.includes('fees-detail-report')
      }
    },

    computed: {
      statusColorClasses() {
        return {
          Success: 'c1',
          Failed: 'c2',
        }
      },

      statusAccountColorClasses () {
        return {
          Open: 'c1',
          Restricted: 'c2',
          Pending: 'c2',
          Closed: 'c4',
          Dormant: 'c3',
          Hold: 'c2'
        }
      },

      transactions () {
        let data =  this.$store.state.transactions.data || []
        this.totalNumber = this.$store.state.transactions.count || 0
        return data
      },

      filteredData () {
        return this.transactions;
      },

      date2 () {
        let today = new Date()
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        return yesterdayDate
      }
    },

    watch: {
      'form.toDate'() {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        const to = moment(this.form.toDate, 'YYYY-MM-DD')
        if (to > now)
          this.toDateError = true
        else
          this.toDateError = false
      },

      transactions () {
        this.setPages();
      },

      page() {
        this.refreshTransactions();
      },

      perPage() {
        if(this.page == 1){
          this.refreshTransactions();
        }else{
          this.page = 1
        }
      }
    },

    methods: {
      setPages () {
        let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      async paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },

      async refreshTransactions() {
        if (this.toDateError) return
        const loader = this.$loading.show()
        let params = {}
        params.createdAtAfter=[">=", this.form.fromDate+" 00:00:00", "createdAt"];
        params.createdAtBefore=["<=", this.form.toDate+" 23:59:59", "createdAt"];
        params.isFee=1;
        try {
          await this.$store.dispatch('transactions/load', { order: ['entryType:DESC'], params, limit: this.perPage, start: (this.page-1) * this.perPage })
          this.isRun = true
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      reset() {
        this.results = null
        this.total = null
        this.isRun = false
        this.form.fromDate = null
        this.form.toDate = null
        this.refreshTransactions()
      },

      cancel() {
        window.history.back()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .dates {
    justify-content: center;

    .cof-field {
      margin: 0 50px;
    }
  }

  .buttons {
    justify-content: center;

    .cof-field {
      margin: 0 50px;
    }
  }

  .btn.main {
    background: #67C23A;
    color: white;
    padding: 0 16px;
    font-size: 12px;
    font-weight: bold;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }

  .content-tlist {
    border: none;
  }
  .content-filters .cof-box {
    background: none;
  }
</style>