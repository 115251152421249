<template>
  <section v-if="user && !user.isPartner">

    <template v-if="featureAdvanceDepositNoticeStatus == null"></template>
    <template v-else>
      <template v-if="$hasPerm('new-transaction')">
        <button v-if="showDeposit && featureAdvanceDepositNoticeStatus" class="btn btn-success" @click="open('deposit')"><i class="el-icon-plus" /> Deposit</button>
        <button v-if="featureRemoteDeposit" class="btn btn-success" @click="openRemoteDeposit()"><i class="el-icon-plus" /> Remote Deposit</button>
        <button v-if="showWithdrawal && featureWithdrawal" class="btn btn-success" @click="open('withdraw')"><i class="el-icon-minus" /> Withdrawal</button>
        <button class="btn btn-secondary" @click="openAccountLink()"><i class="el-icon-link" /> Account Link</button>
        <button class="btn btn-secondary" @click="openPayment()"><i class="el-icon-document" /> Deposit Details</button>
        <button class="btn btn-secondary" @click="openBank()"><i class="el-icon-office-building" /> Bank Look Up</button>
      </template>
    </template>
    <add-deposit-withdrawal-panel
      v-if="modalType"
      :modal-type="modalType"
      @close="modalType = null" />
    <payment-details-panel
      v-if="modalPaymentType"
      :modal-payment-type="modalPaymentType"
      @close="modalPaymentType = null" />
    <account-link-panel
      v-if="modalAccountLinkType"
      :modal-account-link-type="modalAccountLinkType"
      @close="modalAccountLinkType = null" />
    <bank-lookup-panel
      v-if="modalBankType"
      :modal-bank-type="modalBankType"
      @close="modalBankType = null" />
    <add-remote-deposit-modal
      v-if="modalDepositType"
      :modal-type="modalDepositType"
      @close="modalDepositType = null" />
  </section>
</template>

<script>
import AddDepositWithdrawalModal from './AddDepositWithdrwalModal.vue'
import AddDepositWithdrawalPanel from './AddDepositWithdrawalPanel.vue'
import AddRemoteDepositModal from './AddRemoteDepositModal.vue'
import PaymentDetailsModal from './PaymentDetailsModal.vue'
import BankLookupModal from './BankLookupModal.vue'
import AccountLink from './AccountLink.vue'
import AccountLinkPanel from './AccountLinkPanel.vue'
import PaymentDetailsPanel from './PaymentDetailsPanel.vue'
import BankLookupPanel from './BankLookupPanel.vue'


export default {
  name: 'Reporting',

  components: {
    AddRemoteDepositModal,
    AddDepositWithdrawalPanel,
    AddDepositWithdrawalModal,
    PaymentDetailsModal,
    BankLookupModal,
    AccountLinkPanel,
    PaymentDetailsPanel,
    BankLookupPanel,
  },

  data () {
    return {
      modalType: null,
      modalDepositType: null,
      modalPaymentType: null,
      modalAccountLinkType: null,
      modalBankType: null,
      loading: true,
      featureAdvanceDepositNoticeStatus: window.systemFeatures['Advanced Deposit Notice'],
      featureRemoteDeposit: window.systemFeatures['Remote Deposit'],
      featureWithdrawal: window.systemFeatures['Withdrawal'],
      user: appData.currentUser,
    }
  },

  computed: {
    appData() {
      return window.appData;
    },

    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    showDeposit () {
      return (this.isCustomer ? ['Active', 'Restricted'].includes(this.reg?.status) : true)
    },

    showWithdrawal () {
      return (this.isCustomer ? ['Active', 'Activation'].includes(this.reg?.status) : true)
    },

    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  async created () {
    this.$store.commit('system/setSingle', window.appData.system)
    try {
      if (this.isCustomer) await this.$store.dispatch('registrations/get', this.currentUser.regId)
      else await this.$store.dispatch('registrations/load', {params: { corporate: ['!=', 1] }, skip: ['user', 'contact', 'data', 'fees', 'terms', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'profileNumber', 'partnerId', 'currency', 'matched', 'verified', 'boardingApproval', 'createdAt', 'updatedAt', 'approvalDate', 'browser', 'browser_lang', 'os', 'device', 'documentId', 'serviceProvidersId', 'termId', 'confirmId', 'createdBy', 'partner', 'result', 'file', 'cipStatus', 'amlStatus', 'agreementId', 'kycStatus', 'confirm', 'exception', 'providerAccountNumber', 'providerContingentHold', 'providerNonContingentHold', 'providerPendingTransfer', 'linked', 'solidLinked', 'response', 'providerCreatedAt', 'solidAccount', 'cipMessage', 'cipMessageDetail', 'amlMessage', 'amlMessageDetail', 'kycMessage', 'kycMessageDetail', 'reviewDetail', 'QPaymentReference', 'statusUpdatedAt', 'accountKey', 'contactFundsTransferReferencesId', 'personId', 'businessId', 'kybStatus', 'idvStatus', 'phoneVerified', 'emailVerified', 'kycResults', 'kybResults', 'idvResults', 'documentResults', 'reviewCode', 'reviewMessage', 'metadata', 'programID', 'idv', 'disclosureStatus', 'members', 'membersKYCResults', 'membersIDVResults', 'membersDocumentResults', 'cards', 'owedFee', 'owedFeeLastUpdate', 'agreementResponse', 'webhookId', 'webhookResponse',  'partnerId', 'chargeOff', 'chargeOffBalance', 'accountPlan', 'feeSchedule', 'feePlan', 'mainAccount', 'setupComplete', 'complianceReview', 'regName', 'currencyName', 'regCorp', 'regType', 'regAdministrative', 'regReviewDays', 'assetsWallets', 'fundsCurrencies' ]})
    } catch {} finally {
      this.loading = false
    }
  },

  methods: {    
    open (type) {
      this.modalType = type
      this.modalDepositType = null,
      this.modalBankType = null,
      this.modalAccountLinkType = null,
      this.modalPaymentType = null
    },

    openPayment () {
      this.modalType = null,
      this.modalDepositType = null,
      this.modalBankType = null,
      this.modalAccountLinkType = null,
      this.modalPaymentType = 'Payment'
    },

    openBank () {
      this.modalType = null,
      this.modalDepositType = null,
      this.modalPaymentType = null,
      this.modalAccountLinkType = null,
      this.modalBankType = 'Bank'
    },

    openAccountLink () {
      this.modalType = null,
      this.modalDepositType = null,
      this.modalPaymentType = null,
      this.modalBankType = null,
      this.modalAccountLinkType = 'AccountLink'
    },

    openRemoteDeposit () {
      this.modalDepositType = 'modalDepositType'
    },
  }
}
</script>
