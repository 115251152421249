<template>
  <div>
    <section>
      <div style="padding: 24px 30px 10px 10px;">
        <!-- <button class="btn btn-secondary" @click="activeInfo = 'transactions'">Transactions</button> -->
        <button class="btn btn-secondary" @click="activeInfo = 'statements'"> Statements</button>
        <button class="btn btn-secondary" @click="activeInfo = 'accounts'"> Accounts</button>
        <button class="btn btn-secondary" @click="getLedgerBalance()">Ledger Balance</button>
      </div>

      <popup :open.sync="openLedger">
        <div class="popup-header">
          <h3>Ledger Balance</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
           <h3 style="font-size: 20px; font-weight: bold;">Argense</h3>
           <h3 style="font-size: 24px; font-weight: bold;" class="text-center">
            $ {{ this.ledgerBalance }}
          </h3>
          </div>
          <div class="buttons text-center">
            <button class="second" @click="openLedger = false">Close</button>
          </div>
        </div>
      </popup>

      <corporate-accounts v-if="activeInfo == 'accounts'"/>
      <corp-transactions v-if="activeInfo == 'transactions'"/>
      <corporate-statements v-if="activeInfo == 'statements'"/>
    </section>
  </div>
</template>

<script>
import CorporateAccounts from './CorporateAccounts.vue'
import CorporateStatements from './CorporateStatements.vue'
import CorpTransactions from './CorpTransactions.vue'

export default {
  name: 'corp-accounts',

  components: {
    CorporateAccounts,
    CorpTransactions,
    CorporateStatements
  },

  data: () => ({
    activeInfo: null,
    ledgerBalance: null,
    openLedger: false,
  }),

  computed: {
  },

  async created () {
   
  },

  methods: {
    async getLedgerBalance() {
      this.bankInfo = null
      this.bankSwiftInfo = null
      const loader = this.$loading.show()
      let payload;
      const response = (await api.action("Transactions", "new", "LedgerBalance", payload))
      if(response.action.error){
        this.$toast.error('Error: ' + response.action.errors)
        loader.hide()
      }else{
        this.openLedger = true
        this.ledgerBalance = response.action.data
        loader.hide()
      }
    }
  }
}
</script>
<style type="scss" scoped>

</style>
