<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>{{ isDeposit ? 'Deposit' : 'Withdrawal' }}</h3>
      <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
    </div>
    <template v-if="addModal">
      <div class="scrollable">
        <template v-if="isDeposit && system.transaction_deposits">
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
              <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount" :label="`How will you ${isDeposit ? 'deposit' : 'withdraw'}`" :validator="$v.form.deposit_type" :options="depositOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
              <form-field-text v-model="form.amount" :label="`${isDeposit ? 'Deposit' : 'Withdrawal'} amount`" :validator="$v.form.amount" required />
            </div>
          </div>
          <div class="sum">
            <div>Fee: <b>{{ fee | money }}</b></div>
            <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ total | money }}</b></div>
          </div>

          <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" />

          <ach-form v-if="showACHForm" v-model="form" :v="$v.form" :hide-bank-account-type="form.deposit_type === 'domestic_wire'" />

          <div v-if="showPayee" class="content-form">
            <div class="form-row form-split">
              <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
            </div>
          </div>

          <template v-if="showAddressForm">
            <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
            <address-form v-model="form" :v="$v.form" />
          </template>

          <div class="content-form">
            <div v-if="showFurtherCreditAccount" class="center-checkbox">
              <input v-model="form.use_further_credit_account" type="checkbox" id="use_further_credit_account">
              <label for="use_further_credit_account">Use Further Credit Account</label>
            </div>

            <div v-if="form.use_further_credit_account" class="form-row form-split">
              <form-field-text v-model="form.futher_credit_acc_name" label="Further Credit Account Name" :validator="$v.form.futher_credit_acc_name" required />
              <form-field-text v-model="form.futher_credit_acc_number" label="Further Credit Account Number" :validator="$v.form.futher_credit_acc_number" required />
            </div>
            <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
              <form-field-text v-model="form.a2a_account_number" label="Account Number" :validator="$v.form.a2a_account_number" required />
              <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
            </div>

            <h3 class="subtitle">EXTRA INFORMATION</h3>
            <div class="form-row form-split">
              <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
              <form-field-text v-model="form.memo" label="Memo" :maxlength="50" required />
            </div>
          </div>
        </template>
        <template v-else-if="!isDeposit && system.transaction_withdrawals">
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
              <form-field-select v-model="form.deposit_type" :disabled="!selectedAccount" :label="`How will you ${isDeposit ? 'deposit' : 'withdraw'}`" :validator="$v.form.deposit_type" :options="depositOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
              <form-field-text v-model="form.amount" :label="`${isDeposit ? 'Deposit' : 'Withdrawal'} amount`" :validator="$v.form.amount" required />
            </div>
          </div>
          <div class="invalid-balance">
            <div class="error-message" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
          </div>
          <div class="sum">
            <div>Total {{ isDeposit ? 'deposit' : 'withdrawal' }}: <b>{{ total | money }}</b></div>
          </div>

          <international-wire-form v-if="showInternationalWireForm" v-model="form" :v="$v.form" />

          <ach-form v-if="showACHForm" v-model="form" :v="$v.form" :hide-bank-account-type="form.deposit_type === 'domestic_wire'" />

          <div v-if="showPayee" class="content-form">
            <div class="form-row form-split">
              <form-field-text v-model="form.payee" :validator="$v.form.payee" label="Payee" required />
            </div>
          </div>

          <template v-if="showAddressForm">
            <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
            <address-form v-model="form" :v="$v.form" />
          </template>

          <div class="content-form">
            <div v-if="showFurtherCreditAccount" class="center-checkbox">
              <input v-model="form.use_further_credit_account" type="checkbox" id="use_further_credit_account">
              <label for="use_further_credit_account">Use Further Credit Account</label>
            </div>

            <div v-if="form.use_further_credit_account" class="form-row form-split">
              <form-field-text v-model="form.futher_credit_acc_name" label="Further Credit Account Name" :validator="$v.form.futher_credit_acc_name" required />
              <form-field-text v-model="form.futher_credit_acc_number" label="Further Credit Account Number" :validator="$v.form.futher_credit_acc_number" required />
            </div>
            <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
              <form-field-text v-model="form.a2a_account_number" label="Account Number" :validator="$v.form.a2a_account_number" required />
              <div class="error-message" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
            </div>

            <h3 class="subtitle">EXTRA INFORMATION</h3>
            <div class="form-row form-split">
              <!--<form-field-text v-model="form.reference" label="Reference" :maxlength="50" required />-->
              <form-field-text v-model="form.memo" label="Memo" :maxlength="50" required />
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="isDeposit" class="disabledText"><b>{{ system.disabled_deposits_text }}</b></div>
          <div v-else class="disabledText"><b>{{ system.disabled_withdrawals_text }}</b></div>
        </template>

        <div class="buttons">
          <button v-if="isDeposit && !system.transaction_deposits" class="second" @click="open = false">Close</button>
          <button v-else-if="!isDeposit && !system.transaction_withdrawals" class="second" @click="open = false">Close</button>
          <template v-else>
            <button v-if="isDeposit" class="main" @click="add()">Submit</button>
            <button v-else class="main" @click="withdraw()">Submit</button>
            <button class="second" @click="open = false">Cancel</button>
          </template>
        </div>
      </div>
    </template>

    <template v-if="addConfirmModal">
      <div class="scrollable" v-if="isDeposit">
        <div class="content-form">
          <div class="form-row">
            <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
          </div>
          <div v-if="this.form.deposit_type != 'ach'" class="form-row">
            <p v-if="transaction" class="transaction-status">Reference: {{ transaction.qPaymentReference }}</p>
          </div>
          <div class="information">
            <p v-if="transaction" class="transaction-status margin-10">{{ depositOptionsText }} Information</p>
            <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details you will need to complete your payment process.</p>
            <template v-if="this.form.deposit_type == 'checks'">
              <p>No Address Provided</p>
            </template>
            <template v-else-if="this.form.deposit_type == 'ach'">
              <p>ACH Deposit Added Successfully</p>
            </template>
            <template v-else>
              <p v-if="transaction && transaction.instructions"><b>Depository Bank Name</b>: {{ transaction.instructions['depository-bank-name'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Bank Address</b>: {{ transaction.instructions['bank-address'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Bank Phone</b>: {{ transaction.instructions['bank-phone'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Credit To</b>: {{ transaction.instructions['credit-to'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Address</b>: {{ transaction.instructions['beneficiary-address'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Routing Number</b>: {{ transaction.instructions['routing-number'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>Account Number</b>: {{ transaction.instructions['account-number'] }}</p>
              <p v-if="transaction && transaction.instructions"><b>SWIFT Code</b>: {{ transaction.instructions['swift-code'] }}</p>
            </template>
            <p v-if="this.form.deposit_type == 'domestic_wire'" class="margin-10"><b>Note</b>: Your domestic wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
            <p v-else-if="this.form.deposit_type == 'international_wires'" class="margin-10"><b>Note</b>: Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
            <p v-else class="margin-10"><b>Note</b>: Please follow any specific funding instructions to ensure the funds are received quickly.  If you have any doubts or questions please verify your instructions with your account representative.</p>
          </div>
        </div>
      </div>
      <div class="scrollable" v-else>
        <div class="content-form">
          <div class="form-row">
            <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
          </div>
          <div class="information">
            <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
            <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details submitted for your withdrawal.</p>
            <template v-if="this.form.deposit_type == 'international_wires'">
              <p v-if="transaction"><b>Bank Name</b>: {{ this.form.bank_name }}</p>
              <p v-if="transaction"><b>Bank Account Name</b>: {{ this.form.account_name }}</p>
              <p v-if="transaction"><b>Bank Account Number</b>: {{ this.form.account_number }}</p>
              <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
              <p v-if="transaction"><b>Swift</b>: {{ this.form.swift }}</p>
              <p v-if="transaction"><b>Intermediary Bank Name</b>: {{ this.form.intermediary_bank_name }}</p>
              <p v-if="transaction"><b>Intermediary Bank Reference</b>: {{ this.form.intermediary_bank_reference }}</p>
              <p v-if="transaction"><b>Intermediary Bank Address</b></p>
              <p v-if="transaction"><b>Country</b>: {{ this.form.intermediary_address.country }}</p>
              <p v-if="transaction"><b>Province/State</b>: {{ this.form.intermediary_address.state }}</p>
              <p v-if="transaction"><b>Address</b>: {{ this.form.intermediary_address.address }}</p>
              <p v-if="transaction"><b>City</b>: {{ this.form.intermediary_address.city }}</p>
              <p v-if="transaction"><b>Postal</b>: {{ this.form.intermediary_address.postal }}</p>
              <p v-if="transaction"><b>IBAN</b>: {{ this.form.iban }}</p>
              <p v-if="transaction"><b>Beneficiary Address</b></p>
              <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
              <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
              <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
              <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
              <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
              <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
              <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
            </template>
            <template v-else-if="this.form.deposit_type == 'checks'">
              <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
              <p v-if="transaction"><b>Payee</b>: {{ this.form.payee }}</p>
              <p v-if="transaction"><b>Payee Address</b></p>
              <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
              <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
              <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
              <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
              <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
            </template>
            <template v-else-if="this.form.deposit_type == 'ach'">
              <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
              <p v-if="transaction"><b>Name of Bank Account</b>: {{ this.form.bank_name }}</p>
              <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
              <p v-if="transaction"><b>Routing Number</b>: {{ this.form.routing_number }}</p>
              <p v-if="transaction"><b>Bank Account Number</b>: {{ this.form.account_number }}</p>
              <p v-if="transaction"><b>Bank Account Type</b>: {{ this.form.bank_account_type }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
            </template>
            <template v-else-if="this.form.deposit_type == 'domestic_wire'">
              <p v-if="transaction"><b>Name of Bank Account</b>: {{ transaction.bankName }}</p>
              <p v-if="transaction"><b>Routing Number</b>: {{ transaction.routingNumber }}</p>
              <p v-if="transaction"><b>Bank Account Number</b>: {{ transaction.accountNumber }}</p>
              <p v-if="transaction"><b>Account Type</b>: {{ transaction.accountType }}</p>
              <p v-if="transaction"><b>Beneficiary Address</b></p>
              <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
              <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
              <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
              <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
              <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
              <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
              <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
            </template>
            <template v-else>
              <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
              <p v-if="transaction"><b>Account Number</b>: {{ this.form.a2a_account_number }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
            </template>
            <p class="margin-10"><b>Note</b>: If you have any doubts or questions please verify your instructions with your account representative. </p>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="second" @click="open = false">Close</button>
      </div>
    </template>
  </popup>
</template>

<script>
import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import AchForm from './forms/ACH'
import AddressForm from './forms/Address'
import InternationalWireForm from './forms/InternationalWire'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
    AchForm,
    AddressForm,
    InternationalWireForm
  },

  data () {
    return {
      form: {
        account: null,
        currency: 'USD',
        deposit_type: null,
        amount: null,

        bank_name: null,
        account_type: null,
        routing_number: null,
        account_number: null,
        bank_account_type: null,

        a2a_account_number: null,

        payee: null,

        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,

        account_name: null,
        swift: null,
        iban: null,
        use_intermediary_bank: true,
        intermediary_bank_name: null,
        intermediary_bank_reference: null,
        intermediary_address: {
          country: null,
          address: null,
          city: null,
          state: null,
          postal: null
        },

        use_further_credit_account: false,
        futher_credit_acc_name: null,
        futher_credit_acc_number: null,

        reference: null,
        memo: null
      },

      currencyOptions: window.currencyOptions,

      transaction: null,
      open: true,
      addModal: true,
      addConfirmModal: false,
      invalidAccountNumber: false,
      invalidBalance: false,
      fee: 0,
      regId: null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    showInternationalWireForm () {
      return !this.isDeposit && this.form.deposit_type === 'international_wires'
    },

    showFurtherCreditAccount () {
      return !this.isDeposit && ['domestic_wire', 'international_wires'].includes(this.form.deposit_type)
    },

    showPayee () {
      return !this.isDeposit && this.form.deposit_type === 'checks'
    },

    showAddressForm () {
      return !this.isDeposit && ['domestic_wire', 'checks'].includes(this.form.deposit_type)
    },

    showA2AAccountNumber () {
      return !this.isDeposit && this.form.deposit_type === 'acc_to_acc'
    },

    showACHForm () {
      return this.form.deposit_type === 'ach' || (!this.isDeposit && this.form.deposit_type === 'domestic_wire')
    },

    selectedAccount () {
      return this.$store.getters['registrations/get'](this.regId)
    },

    limit () {
      const keys = {
        ach: 'acc_to_acc_limit',
        checks: 'checks_limit',
        domestic_wire: 'wires_limit',
        international_wires: 'wires_limit',
        acc_to_acc: 'acc_to_acc_limit',
      }
      
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings[keys[this.form.deposit_type]]) : null
    },

    withdrawalLimit () {
      return this.selectedAccount?.systemAvailableBalance ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
    },

    depositOptionsText () {
      if(this.form.deposit_type == "checks")
        return "Check Deposit"
      if(this.form.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.form.deposit_type == "international_wires")
        return "International Wire"
      if(this.form.deposit_type == "ach")
        return "ACH"
      return
    },

    withdrawOptionsText () {
      if(this.form.deposit_type == "checks")
        return "Check"
      if(this.form.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.form.deposit_type == "international_wires")
        return "International Wire"
      if(this.form.deposit_type == "ach")
        return "ACH"
      return
    },

    depositOptions () {
      const options = [
        {
          id: 'ach',
          text: 'eCheck/ACH',
        },
        {
          id: 'checks',
          text: 'Check',
        },
        {
          id: 'domestic_wire',
          text: 'Domestic Wire',
        },
        {
          id: 'international_wires',
          text: 'International Wire',
        }
      ]
      if (!this.isDeposit) options.push({
        id: 'acc_to_acc',
        text: 'Account to Account',
      })
      return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    isDeposit () {
      return this.modalType === 'deposit'
    },

    total () {
      if (this.isDeposit)
        return (parseFloat(this.form.amount)) || 0
      else
        return (parseFloat(this.form.amount) + parseFloat(this.fee)) || 0
    },

    accountOptions () {
      let registrations = this.$store.state.registrations.data || []
      if(registrations.length > 0){
        this.regId = registrations[0].id
        let accounts = registrations[0].accounts
        return accounts.filter(acc => ['Active'].includes(acc.accountStatus)).map(acc => ({
          id: acc.id,
          text: `${acc.profileNumber}-${acc.accountNumber}-${acc.accountName}`
        }))
      }
    },

    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.form.deposit_type = null
    },

    'form.amount' () {
      this.$v.form.amount.$touch()
      if (this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      } else {
        this.invalidBalance = false
        return
      }
    },

    'form.routing_number' () {
      this.$v.form.routing_number.$touch()
    },

    async 'form.a2a_account_number' () {
      this.$v.form.a2a_account_number.$touch()
      let payload = {
        toAccountId: this.form.a2a_account_number,
      }
      const response = (await api.action("Transactions", "new", "CheckAccount", payload))
      if(response.action.error){
        this.invalidAccountNumber = true
      }else{
        this.invalidAccountNumber = false
      }
    },

    'form.account_number' () {
      this.$v.form.account_number.$touch()
    },

    'form.deposit_type' () {
      this.getFee()
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
  },

  methods: {
    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        if (this.limit && this.limit < this.form.amount) this.$toast.error(`Amount Entered Exceeds Limit`)
        return
      }
      const loader = this.$loading.show()
      let payload;
      if (this.form.deposit_type == 'ach') {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          routingNumber: this.form.routing_number,
          accountNumber: this.form.account_number,
          bankAccountType: this.form.bank_account_type,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }else{
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }
      this.transaction = (await api.action("Transactions", "new", "Deposit", payload)).item
      this.$toast.success('Transaction successfully created.')
      loader.hide()
      this.addModal = false
      this.addConfirmModal = true
    },

    async withdraw () {
      this.$v.$touch()
      if (this.$v.form.$invalid) return
      if (this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      }

      if (this.invalidAccountNumber) {
        return
      }

      const loader = this.$loading.show()
      let payload;
      if (this.form.deposit_type == 'international_wires') {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          swift: this.form.swift,
          accountNumber: this.form.account_number,
          useIntermediaryBank: this.form.use_intermediary_bank,
          intermediaryBankName: this.form.intermediary_bank_name,
          intermediaryBankReference: this.form.intermediary_bank_reference,
          intermediaryAddress:this.form.intermediary_address,
          iban:this.form.iban,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference,
          memo: this.form.memo,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }else if(this.form.deposit_type == 'checks') {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          payee: this.form.payee,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }else if(this.form.deposit_type == 'ach') {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          routingNumber: this.form.routing_number,
          bankName: this.form.bank_name,
          accountName: this.form.account_name,
          accountType: this.form.account_type,
          bankAccountType: 'checking',
          accountNumber: this.form.account_number,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      } else if(this.form.deposit_type == 'domestic_wire') {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          routingNumber: this.form.routing_number,
          accountNumber: this.form.account_number,
          bankAccountType: 'checking',
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      } else {
        payload = {
          corp: true,
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          accountName: this.form.account_name,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          toAccountId: this.form.a2a_account_number,
          reference: this.form.reference,
          memo: this.form.memo,
          completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          currencyName: this.form.currency
        }
      }
      const response = (await api.action("Transactions", "new", "Withdraw", payload))
      if(response.action.error){
        this.invalidAccountNumber = true
        loader.hide()
      } else{
        this.transaction = response.item
        this.$toast.success('Transaction successfully created.')
        loader.hide()
        this.addModal = false
        this.addConfirmModal = true
        this.invalidAccountNumber = false
      }
    },

    async getFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: this.form.deposit_type,
        entryType: this.isDeposit ?'Deposit' : 'Withdrawal'
      }
      this.fee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
    }
  },

  validations () {
    const rules = {
      form: {
        amount: { required, decimal },
        account: { required },
        currency: { required },
        deposit_type: { required }
      }
    }

    if (this.limit) {
      rules.form.amount.limit = maxValue(this.limit)
    }

    rules.form.amount.minLimit = minValue(this.fee)

    if (this.showA2AAccountNumber) {
      rules.form.a2a_account_number = { required, numeric, minLength: minLength(7), monbiAccountNumber }
    }

    if (this.showPayee) {
      rules.form.payee = { required, alphaNum }
    }
    if (this.showAddressForm || this.showInternationalWireForm) {
      rules.form.country = { required }
      rules.form.city = { required }
      rules.form.state = { required }
      rules.form.postal = { required, alphaNum }
      rules.form.address = { required }
    }

    if (this.form.use_further_credit_account) {
      rules.form.futher_credit_acc_name = { required }
      rules.form.futher_credit_acc_number = { required }
    }

    if (this.showACHForm) {
      rules.form.account_name = { required }
      rules.form.account_type = { required }
      rules.form.routing_number = { required, numeric, minLength: minLength(9), maxLength: maxLength(9), routingNumber }
      rules.form.account_number = { required, numeric, minLength: minLength(4), maxLength: maxLength(17) }
    }

    if (this.showInternationalWireForm) {
      rules.form.account_name = { required }
      rules.form.account_type = { required }
      rules.form.bank_name = { required }
      rules.form.account_number = { required, numeric, minLength: minLength(4), maxLength: maxLength(17) }
      rules.form.swift = { required, swift }
      rules.form.iban = { required }

      if (this.form.use_intermediary_bank) {
        rules.form.intermediary_bank_name = { required }
        rules.form.intermediary_bank_reference = { required }
        rules.form.intermediary_address = {
          country: { required },
          city: { required },
          state: { required },
          postal: { required, alphaNum },
          address: { required }
        }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
// .sum {
//   text-align: right;
//   font-size: 15px;
//   line-height: 1.6;
//   b {
//     margin-left: 5px;
//   }
// }
// .popup-header {
//   display: flex;
//   justify-content: space-between;
//   padding-right: 50px;
//   padding-bottom: 12px;
//   margin-bottom: 24px;
//   border-bottom: 1px solid #99aabb;
//   h3 {
//     color: #1f2d4e;
//     font-size: 20px;
//     font-weight: bold;
//   }
// }

.information p {
  margin: 5px;
}

// .transaction-status {
//   font-size: 20px;
//   font-weight: bold;
//   margin-left: 0 !important;
// }

// .margin-10 {
//   margin: 10px 0 !important;
// }

// .center-checkbox {
//   margin-bottom: 15px;
//   text-align: center;
//   input {
//     width: 18px;
//     height: 18px;
//     vertical-align: middle;
//     margin-right: 5px;
//   }
// }
// .subtitle {
//   color: #1f2d4e;
//   font-size: 16px;
//   font-weight: bold;
//   margin-bottom: 15px;
// }
// .disabledText {
//   text-align: center;
//   font-size: 18px;
//   margin: 60px 0;
// }

// .error-message {
//   color: red;
// }
</style>