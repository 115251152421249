<template>
<div>
  <section>
    <div>
      <h2 class="inner-headers"></h2>
    </div>
    <button v-if="$hasPerm('add-employees')" class="btn btn-success" @click.prevent="openEmployee"><i class="el-icon-plus" /> New Employee</button>
  </section>
  <div v-if="perms" class="accordion">
    <div :class="{'cof-box-active' : activeInfo}">
      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Setup Date</th>
            <th>Emp ID</th>
            <th>Role</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
          <tr v-for="employee in filteredData" :key="employee.id">
            <td><span>Date</span>{{ employee.createdAt | date('MM/D/YYYY') }}</td>
            <td><span>Emp ID</span>
              <a v-if="$hasPerm('manage-employees')" :href="`/settings/employees/${employee.id}`">{{ employee.employeeId }}</a>
              <p v-else style="color: #00c2ac;">{{ employee.employeeId }}</p>
            </td>
            <td><span>Role</span>{{ employee.role.name }}</td>
            <td><span>First Name</span>{{ employee.firstName }}</td>
            <td><span>Last Name</span>{{ employee.lastName }}</td>
            <td><span>Email</span>{{ employee.email }}</td>
            <td><span>Status</span>
              <em :class="statusColors[employee.status]">{{ employee.status }}</em>
            </td>
            <td><span>Options</span>
              <button v-if="$hasPerm('access') && employee.user" class="action-button" @click="toggleBlock(employee)">{{ employee.user.active !== 'Blocked' ? 'Block' : 'Unblock' }}</button>
              <button v-if="$hasPerm('manage-employees') && employee.user" class="action-button remove" @click="remove(employee)">Remove</button>
            </td>
          </tr>
        </table>
      </section>
      <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Employees',

  data: ()=>({
    open: false,
    user: null,
    employees: [],
    activeInfo: true,

    statusColors: {
      Active: 'c1',
      Pending: 'c2',
      Disabled: 'c3',
      Blocked: 'c3'
    },
    perms: window.appData.currentUser?.role?.perms?.includes('manage-employees') || window.appData.currentUser?.perms?.includes('manage-employees'),

    loading: false,
    page: 1,
    perPage: 25,
    pages: [],
  }),

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    totalNumber() {
      return this.employees.length
    },

    filteredData () {
      return this.paginate(this.employees);
    }
  },

  methods: {
    async toggleBlock (employee) {
      const isBlocked = employee.user.active === 'Blocked'
      if (confirm(`Are you sure you want to ${isBlocked ? 'un' : ''}block this employee?`)) {
        const loader = this.$loading.show()
        await this.$store.dispatch('users/action', { id: employee.user.id, action: isBlocked ? 'Unblock' : 'Block' })
        await this.loadEmployees()
        loader.hide()
        this.$toast.success(`User ${isBlocked ? 'unblocked' : 'blocked'} successfully.`)
      }
    },

    async remove (employee) {
      if (confirm(`Are you sure you want to remove this employee?`)) {
        const loader = this.$loading.show()
        await this.$store.dispatch('users/delete', employee.user.id)
        await this.$store.dispatch('employees/delete', employee.id)
        const response = (await api.action("EmployeeEmailLog", "new", "RemoveLog", {entityId: employee.id}))
        await this.loadEmployees()
        loader.hide()
        this.$toast.success(`Employee deleted successfully.`)
      }
    },

    openEmployee() {
      window.employeePopup.showUser({});
    },

    async loadEmployees() {
      this.employees=(await api.list("Employees")).items;
      this.page = 1
    },

    disableLink(event) {
      this.btnDisabled = true; // mutate data and let vue disable the element
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },
  },

  watch: {
    filteredData () {
      this.setPages();
    },

    page() {
      this.setPages();
    },

    employees () {
      this.setPages();
    },

    perPage() {
      if(this.page == 1){
        this.setPages();
      }else{
        this.page = 1
      }
    }
  },

  mounted() {
    this.loadEmployees();
    window.employeePopup=this;
    window.employeeList=this;
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>
