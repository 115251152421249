<template>
  <div>
    <div class="loading-counter" v-if="loadingCount">
      {{ loadingProgress+1 }} / {{ loadingCount }}
    </div>
    <popup :open.sync="open">
      <div class="popup-header">
        <h3>Account Reconciliation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row form-split">
            <form-field-select v-model="form.provider" label="Select Provider" :validator="$v.form.provider" :options="providerOptions" required />
          </div>
          <div class="form-row form-split">
            <form-field-select v-model="form.currencyType" label="Select Currency Type" :validator="$v.form.currencyType" :options="currencyTypeOptions" required />
            <form-field-select v-model="form.currency" label="Select Currency" :validator="$v.form.currency" :options="currencyOptions" required />
          </div>
        </div>
        <div class="buttons text-center">
          <button class="main" @click="run()" :disabled="this.$v.form.$invalid">Start</button>
          <button class="second" @click="open = false">Close</button>
        </div>
      </div>
    </popup>
    <popup :open.sync="error" :width="400">
      <div class="popup-header">
        <h3>Error</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row">
            <p>{{errors}}</p>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="second" @click="error = false">Close</button>
      </div>
    </popup>
  </div>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      open: true,
      form: {
        currencyType: null,
        currency: null,
        provider: null,
      },
      error: false,
      errors: null,
      featureStatus: null,
      loadingCount: null,
      loadingProgress: null,
    }
  },

  computed: {
    currencyTypeOptions () {
      const options = [
        {
          id: 'Funds',
          text: 'Funds',
        },
        {
          id: 'Assets',
          text: 'Assets',
        }
      ]
      return options
    },

    currencyOptions () {
      let currencies = window.currencies || []
      let options = []
      if(this.form.currencyType == 'Funds') {
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Funds') {
            if(currency.currencyNickname == 'USD') {
              options.push({
                id: currency.id,
                text: `${currency.currencyNickname}-${currency.name}`,
              })
            }else{
              if(this.selectedProvider.multiCurrency == 1) {
                options.push({
                  id: currency.id,
                  text: `${currency.currencyNickname}-${currency.name}`,
                })
              }
            }
          }
        })
      }

      if(this.form.currencyType == 'Assets') {
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Assets') {
            options.push({
              id: currency.id,
              text: `${currency.currencyNickname}-${currency.name}`,
            })
          }
        })
      }

      return options
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.kyc && provider.providerType == 'Accounts').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    selectedProvider () {
      return this.providers.find(provider => provider.id == this.form.provider)
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    async run() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }

      this.$parent.currencyId = this.form.currency
      this.$parent.currencyType = this.form.currencyType
      const loader = this.$loading.show()
      try {
        let payload = {
          currency: this.form.currency,
          currencyType: this.form.currencyType,
          provider: this.form.provider,
        }
        let action
        if(this.form.currencyType == 'Funds') {
          this.loadingProgress=0
          var accounts=(await api.action("Account", "new", "startProviderCash", payload)).action.accounts;
          this.loadingCount = accounts.length
          for (var accountId of accounts) {
            action = (await api.action("Account", accountId.id, "startProviderCashAccount", payload)).action
            this.loadingProgress++
          }
        }else{
          this.loadingProgress=0
          var accounts=(await api.action("Account", "new", "StartProviderCashAssets", payload)).action.accounts;
          this.loadingCount = accounts.length
          for (var accountId of accounts) {
            action = (await api.action("Account", accountId.id, "StartProviderCashAssetsAccount", payload)).action
            this.loadingProgress++
          }
        }
        if (action.error) {
          this.error = true
          this.errors = action.errors
        } else {
          this.error = false
        }
        await this.$store.dispatch('accounts/load', { params: { provider: this.form.provider} }, {order: ['accountNumber:DESC']})
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        this.open = false
        loader.hide()
        this.loadingCount=null;
        this.loadingProgress=null;
      }
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Account Reconciliation']
    },
  },

  async created() {
    await this.getFeatureStatus();
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  validations () {
    const rules = {
      form: {
        currency: { required },
        currencyType: { required },
        provider: { required },
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 30px;
  }
  .loading-counter {
    z-index: 10005;
    position: fixed;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>