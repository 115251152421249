<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers">Compliance > Scans</h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <button v-if="$hasPerm('new-scan')" class="btn btn-success" @click="open = 'Open'">New Scan</button>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />


    <div class="accordion">
      <div :class="{'cof-box' : true, 'cof-box-active' : activeInfo}">
        <h4 class="accordion-headline" @click="activeInfo = !activeInfo">Scans</h4>
        <h3 class="total-number" @click="activeInfo = !activeInfo">({{ totalNumber }}) Click to expand</h3>
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Scan</th>
              <th>Date/Time</th>
              <th>Profile</th>
              <th>Name</th>
              <th>Scan Type</th>
              <th>Scan Result</th>
              <th>File</th>
              <th>Options</th>
            </tr>
            <tr v-for="scan in scans" :key="scan.id">
              <td><span>Scan</span>{{ scan.scanId }}</td>
              <td><span>Date/Time</span>{{ scan.createdAt | date('MM/D/YYYY') }}</td>
              <td>
                <span>Profile</span>
                <a :href="`/system-customers/registrations/${scan.regId}`">{{ scan.profile }}</a>
              </td>
              <td>
                <span>Name</span>
                {{ scan.name }}
              </td>
              <td><span>Scan Type</span>
                {{ scan.scanType }}
              </td>
              <td><span>Scan Result</span>
                <strong :class="statusColorClasses[scan.scanResult]">{{ scan.scanResult }}</strong>
              </td>
              <td><span>File</span>
                <a class="link" target="_blank" :href="`files/scanReports/${scan.id}/${scan.file}`">{{ scan.file }}</a>
              </td>
              <td><span>Options</span>
                <button v-if="scan.scanResult === 'Pending'" class="btn btn-secondary" @click="update(scan.id)">Update</button>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </div>
    <scans-modal v-if="open" :modal-type="open" @close="open = null" />
    <scan-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import ScanFilters from './filters/ScanFilters'
import AppliedFilters from '../../components/common/AppliedFilters'
import ScansModal from "./ScansModal.vue";

export default {
  name: 'Scans',

  components: {
    ScansModal,
    ScanFilters,
    AppliedFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,

    activeInfo: false,
    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),

    sortName: null,
    sortFlag: true,
    open: null
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        number: 'Scan Number',
        profile: 'Profile',
        name: 'Name',
        scanType: 'Scan Type',
        scanResult: 'Scan Result',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.filters[key]}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    accountStatuses () {
      return window.accountStatuses
    },

    profileStatuses () {
      return window.profileStatuses
    },

    accountStatusesFiltered () {
      const array = [...window.accountStatuses]
      array.splice(0, 2)
      return array
    },

    totalNumber() {
      return this.scans.length
    },

    scans () {
      let scans = this.$store.state.scan_reports.data || []
      if (!this.appliedFilters) {

      }
      if (this.appliedFilters) {
        if (this.appliedFilters.number) {
          scans = scans.filter(scan => {
            return scan.scanId.includes(this.appliedFilters.number)
          })
        }

        if (this.appliedFilters.name) {
          scans = scans.filter(scan => {
            return scan.name.toLowerCase().includes(this.appliedFilters.name.toLowerCase())
          })
        }

        if (this.appliedFilters ?.scanResult) {
          scans = scans.filter(scan=> scan.scanResult === this.appliedFilters.scanResult)
        }

        if (this.appliedFilters.profile) {
          scans = scans.filter(scan => parseInt(scan.profile) === parseInt(this.appliedFilters.profile))
        }

        if (this.appliedFilters.scanType) {
          scans = scans.filter(scan => scan.scanType === this.appliedFilters.scanType)
        }
      }

      if(this.sortName != null) {
        let aName, bName
        if(this.sortName == 'embossingPerson'){
          if(this.sortFlag) {
            cards.sort((a, b) => {
              if (a.embossingPerson > b.embossingPerson) return 1
              if (a.embossingPerson < b.embossingPerson) return -1
              return 0
            })
          }else{
            cards.sort((a, b) => {
              if (a.embossingPerson > b.embossingPerson) return -1
              if (a.embossingPerson < b.embossingPerson) return 1
              return 0
            })
          }
        }
      }
      return scans
    },

    statusColorClasses () {
      return {
        Pending: 'c2',
        Error: 'c3',
        Complete: 'c1',
      }
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []
      if(this.selectedProvider) {
        registrations.forEach(reg => {
          {
            if(this.selectedProvider.provider_name.includes('KYC Reports')){
            if(reg.type == 'Business') {
              accounts.push({
                id: reg.id,
                text: `${reg.profileNumber}-${reg.name}`,
              })
            }
          }else{
            accounts.push({
              id: reg.id,
              text: `${reg.profileNumber}-${reg.name}`,
            })
          }
          }
        })
      }
      return accounts
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Compliance').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    currentUser () {
      return window.appData.currentUser
    },
  },

  async created () {
    await this.refreshScans();
    const loader = this.$loading.show()
    try {
      await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
      if (this.isCustomer) await this.$store.dispatch('registrations/get', this.currentUser.regId)
      else await this.$store.dispatch('registrations/load', {params: { corporate: ['!=', 1] }, skip: ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents']})
    } catch {} finally {
      this.loading = false
      loader.hide();
    }
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'status') return window.profileReviewStatuses[value]

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    getStatus (status) {
      const names = {
        pendingActivation: 'Pending Activation',
        Active: 'Open'
      }
      return names[status] || status
    },

    sortTemplateName(sortName) {
      if(this.sortName == sortName){
        this.sortFlag = !this.sortFlag
      }else {
        this.sortName = sortName
        this.sortFlag = true
      }
    },

    async refreshScans () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (!this.appliedFilters) {

      } else {
        if (this.appliedFilters.fromDate)
          params.createdAt=[">=", this.appliedFilters.fromDate+" 00:00:00", "cardCreatedAt"];
        if (this.appliedFilters.toDate)
          params.createdAt=["<=", this.appliedFilters.toDate+" 23:59:59", "cardCreatedAt"];
      }

      await this.$store.dispatch('scan_reports/load', { params })
      this.loading=false;
      loader.hide()
    },

    async update (id) {
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('scan_reports/action', {
          id: id,
          action: 'UpdateScan',
          payload: {

          }
        })).action
        this.refreshScans();
      } catch (e) {
        //this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    }
  },

  watch: {
    appliedFilters () {
      this.refreshScans();
    },
  },

  validations () {

  }
}
</script>
<style lang="scss" scoped>
  .buttons {
    margin-top: 270px;
  }
</style>
