<template>
  <div>
    <section style="margin:30px;">
      <div class="content-form">
        <div class="form-row form-split">
          <form-field-select label="Select Account Type" :options="{Personal: 'Personal Accounts', Business: 'Business Accounts'}" v-model="type" />
          <form-field-select label="Available Account plans" :options="plans" v-model="plan" />
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Fee Name</th>
          <th style="width: 10%;">Fee</th>
          <th style="width: 10%;">Deposit</th>
          <th style="width: 10%;">Withdrawal</th>
        </tr>
        <template v-if="feeSchedule">
          <tr>
            <td>
              <span>Fee Name</span>
              Volume Fee
            </td>
            <td>
              <span>Fee</span>
              {{ feeSchedule.volumeFee.fee | formattedTwoDecimal }} %
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Monthly Access Fee
            </td>
            <td>
              <span>Fee</span>
              $ {{ feeSchedule.monthlyAccessFee.fee | formattedTwoDecimal }}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Domestic Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ {{ feeSchedule.domesticWire.per_deposit | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.domesticWire.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              International Wire
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ {{ feeSchedule.internationalWire.per_deposit | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.internationalWire.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              ACH
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ {{ feeSchedule.ach.per_deposit | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.ach.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Account Transfer
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              
            </td>
            <td>
              $ {{ feeSchedule.accountTransfer.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Checks
            </td>
            <td>
              <span>Fee</span>
              
            </td>
            <td>
              $ {{ feeSchedule.checks.per_deposit | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.checks.per_withdrawal | formattedTwoDecimal }} 
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Exchange
            </td>
            <td>
              <span>Fee</span>
              $ {{ feeSchedule.exchange.fee | formattedTwoDecimal }}
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Assets
            </td>
            <td>
              <span>Fee</span>
              $ {{ feeSchedule.assets.fee | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.assets.per_deposit | formattedTwoDecimal }}
            </td>
            <td>
              $ {{ feeSchedule.assets.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Dormant Accounts
            </td>
            <td>
              <span>Fee</span>
              $ {{ feeSchedule.dormantAccounts.fee | formattedTwoDecimal }}
            </td>
            <td>
              
            </td>
            <td>
             
            </td>
          </tr>
          <tr>
            <td>
              <span>Fee Name</span>
              Debit Card
            </td>
            <td>
              <span>Fee</span>
              $ {{ feeSchedule.debitCard.fee | formattedTwoDecimal }}
            </td>
            <td>
              
            </td>
            <td>
              $ {{ feeSchedule.debitCard.per_withdrawal | formattedTwoDecimal }}
            </td>
          </tr>
        </template>
      </table>
    </section>
  </div>
</template>

<script>

export default {
  name: 'page-fee',

  components: {
   
  },

  data() {
    return {
      plan: null,
      type: null,
    }
  },

  computed: {
    plans () {
      let data = this.$store.state.fee_plan.data || []
      if(this.type) {
        return data.filter(item => item.accountTypes?.includes(this.type)).map(acc => ({
          id: acc.id,
          text: acc.name
        }))
      }else{
        return []
      }
    },

    feeSchedule () {
      let data = this.$store.state.fee_plan.data || []
      return data.find(item => item.id == this.plan)?.feeSchedule
    }
  },

  async created () {
    await this.$store.dispatch('fee_plan/load')
  },

  methods: {

  },

  watch: {
    type () {
      if(this.type == '') {
        this.plan = null
      }
    }
  },

  validations () {

  }
}
</script>

<style lang="scss" scoped>
  .header-flex {
    margin: 30px;
    justify-content: space-between;
  }
</style>