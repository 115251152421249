<template>
<div class="amount-currency">
  <input type="text" :value="amount" v-on:input="$emit('update:amount', $event)"  />
  <dyn-select :value="currency" v-on:input="$emit('update:currency', $event)" :options="currencies" />
</div>
</template>

<script>
export default {
  props: ["amount", "currency"],
  mixins: [vueMixins.helpers],
  methods: {
  },
  mounted() {
    if (this.currency==null)
      this.$emit('update:currency', "124");
  },
}
</script>
