<template>
  <div>
    <template v-if="open">
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>

      <div class="content-filters">
        <basic-form>
          <div class="cof-row">
            <div class="split">
              <div class="split">
                <mnb-input v-model="activatedThemeName" label="Active Theme" disabled />
              </div>
            </div>
            <div class="split">
              <div class="split">
                <mnb-select v-model="themeOption" :options="themeOptions" label="Theme" />
              </div>
            </div>
          </div>
          <div class="cof-row">
            <div class="split">
              <div class="split">
                <mnb-input v-model="form.themeName" label="Theme Name" :disabled="!updateInformations" :validator="$v.form.themeName"/>
              </div>
            </div>
          </div>

          <h4 class="pt-2 text-center">Theme Settings</h4>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Main Color:</label>
              <input type="color" class="split" v-model="form.mainColor" :disabled="!updateInformations" :validator="$v.form.mainColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Background Gradient Left:</label>
              <input type="color" class="split" v-model="form.loginBackgroundGradientLeft" label="" :disabled="!updateInformations" :validator="$v.form.loginBackgroundGradientLeft"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Background Gradient Right:</label>
              <input type="color" class="split" v-model="form.loginBackgroundGradientRight":disabled="!updateInformations" :validator="$v.form.loginBackgroundGradientRight"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Tagline Gradient Left:</label>
              <input type="color" class="split" v-model="form.loginTaglineGradientLeft" :disabled="!updateInformations" :validator="$v.form.loginTaglineGradientLeft"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Tagline Gradient Right:</label>
              <input type="color" class="split" v-model="form.loginTaglineGradientRight" :disabled="!updateInformations" :validator="$v.form.loginTaglineGradientRight"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Footer Text Color:</label>
              <input type="color" class="split" v-model="form.loginFooterTextColor" label="" :disabled="!updateInformations" :validator="$v.form.loginFooterTextColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Footer Text Link Color:</label>
              <input type="color" class="split" v-model="form.loginFooterTextLinkColor" :disabled="!updateInformations" :validator="$v.form.loginFooterTextLinkColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Button Gradient Left:</label>
              <input type="color" class="split" v-model="form.loginButtonGradientLeft" :disabled="!updateInformations" :validator="$v.form.loginButtonGradientLeft"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Login Button Gradient Right:</label>
              <input type="color" class="split" v-model="form.loginButtonGradientRight" :disabled="!updateInformations" :validator="$v.form.loginButtonGradientRight"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Modal Header Color:</label>
              <input type="color" class="split" v-model="form.modalHeaderColor" :disabled="!updateInformations" :validator="$v.form.modalHeaderColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Subnav Underline Color:</label>
              <input type="color" class="split" v-model="form.subnavUnderlineColor" :disabled="!updateInformations" :validator="$v.form.subnavUnderlineColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Page Headers Color:</label>
              <input type="color" class="split" v-model="form.pageHeadersColor" :disabled="!updateInformations" :validator="$v.form.subnavUnderlineColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Transaction Popup Headers Color:</label>
              <input type="color" class="split" v-model="form.transactionPopupHeadersColor" :disabled="!updateInformations" :validator="$v.form.subnavUnderlineColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">PIN popup Main Color:</label>
              <input type="color" class="split" v-model="form.pinPopupMainColor" :disabled="!updateInformations" :validator="$v.form.subnavUnderlineColor"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Popup Shade Background Color - RGB:</label>
              <input class="split" v-model="form.popupShadeBackgroundColorRGB" :disabled="!updateInformations" :validator="$v.form.popupShadeBackgroundColorRGB"/>
            </div>
          </div>
          <div class="cof-row">
            <div class="split flex">
              <label class="split">Theme Notes:</label>
              <textarea class="split"
              v-model="form.themeNotes"
              @input="mixin_autoResize_resize"
              :classes="{ 'full': true, 'noResize': true }"
              full
              :disabled="!updateInformations"></textarea>
            </div>
          </div>

          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Save</button>
            </div>
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="cancel()">Cancel</button>
            </div>
            <div v-if="!updateInformations && themeOption" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="updateInformations = true">Update</button>
            </div>
          </div>
          <div class="text-center">
            <div v-if="themeOption != 'new' && themeOption != null && activatedThemeId != themeOption" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="activate()">Activate</button>
            </div>
          </div>
        </basic-form>
      </div>
    </template>
  </div>
</template>

<script>
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MnbUploadInput from '../../components/ui/forms/UploadInput'
import MnbTextarea from '../../components/ui/forms/Textarea'
import mixinAutoResize from "../../components/ui/forms/autoresize.js"
import inputMixin from "../../components/ui/forms/input-mixin.js"
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  mixins: [mixinAutoResize],

  components: {
    MnbInput,
    BasicForm,
    MnbSelect,
    MnbUploadInput,
    MnbTextarea,
  },

  data () {
    return {
      form: {
        themeName: null,
        mainColor: null,
        loginBackgroundGradientLeft: null,
        loginBackgroundGradientRight: null,
        loginTaglineGradientLeft: null,
        loginTaglineGradientRight: null,
        loginFooterTextColor: null,
        loginFooterTextLinkColor: null,
        loginButtonGradientLeft: null,
        loginButtonGradientRight: null,
        popupShadeBackgroundColorRGB: null,
        modalHeaderColor: null,
        subnavUnderlineColor: null,
        pageHeadersColor: null,
        transactionPopupHeadersColor: null,
        pinPopupMainColor: null,
        themeNotes: null,
      },

      themeOption: null,
      updateInformations: false,

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],

      enableDisableOptions: [
        {
          id: true,
          text: 'Enable'
        },
        {
          id: false,
          text: 'Disable'
        },
      ],
      open: true,
    }
  },

  computed: {
    themeOptions () {
      const options = [
        {
          id: 'new',
          text: 'New',
        }
      ]
      this.themes.forEach(theme => {
        options.push({
          id: theme.id,
          text: theme.themeName,
        })
      })
      return options
    },

    themes() {
      const data = this.$store.state.themes.data || []
      return data
    },

    selectedTheme() {
      const theme = (this.themes.find(item => item.id === this.themeOption))
      return theme
    },

    activatedThemeName() {
      const theme = (this.themes.find(item => item.activated === true))
      if(theme)
        return theme.themeName
      else
        return ''
    },

    activatedThemeId() {
      const theme = (this.themes.find(item => item.activated === true))
      if(theme)
        return theme.id
      else
        return ''
    }
  },

  watch: {
    'themeOption' (value) {
      if(this.themeOption == 'new' || this.themeOption == null) {
        this.form.themeName = null
        this.form.mainColor = null
        this.form.loginBackgroundGradientLeft = null
        this.form.loginBackgroundGradientRight = null
        this.form.loginTaglineGradientLeft = null
        this.form.loginTaglineGradientRight = null
        this.form.loginFooterTextColor = null
        this.form.loginFooterTextLinkColor = null
        this.form.loginButtonGradientLeft = null
        this.form.loginButtonGradientRight = null
        this.form.popupShadeBackgroundColorRGB = null
        this.form.modalHeaderColor = null
        this.form.subnavUnderlineColor = null
        this.form.pageHeadersColor = null
        this.form.transactionPopupHeadersColor = null
        this.form.pinPopupMainColor = null
        this.form.themeNotes = null
        this.updateInformations = true
      }else{
        this.form.themeName = this.selectedTheme.themeName
        this.form.mainColor = this.selectedTheme.mainColor
        this.form.loginBackgroundGradientLeft = this.selectedTheme.loginBackgroundGradientLeft
        this.form.loginBackgroundGradientRight = this.selectedTheme.loginBackgroundGradientRight
        this.form.loginTaglineGradientLeft = this.selectedTheme.loginTaglineGradientLeft
        this.form.loginTaglineGradientRight = this.selectedTheme.loginTaglineGradientRight
        this.form.loginFooterTextColor = this.selectedTheme.loginFooterTextColor
        this.form.loginFooterTextLinkColor = this.selectedTheme.loginFooterTextLinkColor
        this.form.loginButtonGradientLeft = this.selectedTheme.loginButtonGradientLeft
        this.form.loginButtonGradientRight = this.selectedTheme.loginButtonGradientRight
        this.form.popupShadeBackgroundColorRGB = this.selectedTheme.popupShadeBackgroundColorRGB
        this.form.modalHeaderColor = this.selectedTheme.modalHeaderColor
        this.form.subnavUnderlineColor = this.selectedTheme.subnavUnderlineColor
        this.form.pageHeadersColor = this.selectedTheme.pageHeadersColor
        this.form.transactionPopupHeadersColor = this.selectedTheme.transactionPopupHeadersColor
        this.form.pinPopupMainColor = this.selectedTheme.pinPopupMainColor
        this.form.themeNotes = this.selectedTheme.themeNotes
        this.updateInformations = false
      }

    }
  },

  async created () {
    await this.$store.dispatch('themes/load')
  },

  methods: {
    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if (action.pin) {
        this.pinFailed = false
        this.open = false
      } else {
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    async save () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      if(confirm(` Are you sure?`)) {
          if (this.themeOption == 'new') {
            const payload = {
              themeName: this.form.themeName,
              mainColor: this.form.mainColor,
              loginBackgroundGradientLeft: this.form.loginBackgroundGradientLeft,
              loginBackgroundGradientRight: this.form.loginBackgroundGradientRight,
              loginTaglineGradientLeft: this.form.loginTaglineGradientLeft,
              loginTaglineGradientRight: this.form.loginTaglineGradientRight,
              loginFooterTextColor: this.form.loginFooterTextColor,
              loginFooterTextLinkColor: this.form.loginFooterTextLinkColor,
              loginButtonGradientLeft: this.form.loginButtonGradientLeft,
              loginButtonGradientRight: this.form.loginButtonGradientRight,
              popupShadeBackgroundColorRGB: this.form.popupShadeBackgroundColorRGB,
              modalHeaderColor: this.form.modalHeaderColor,
              subnavUnderlineColor: this.form.subnavUnderlineColor,
              pageHeadersColor: this.form.pageHeadersColor,
              transactionPopupHeadersColor: this.form.transactionPopupHeadersColor,
              pinPopupMainColor: this.form.pinPopupMainColor,
              themeNotes: this.form.themeNotes,
            }

            await this.$store.dispatch('themes/create', payload)
            await this.$store.dispatch('themes/load')
            this.$toast.success('Theme successfully created.')
            this.themeOption = null
            this.updateInformations = false
          } else {
            const payload = {
              id: this.themeOption,
              payload: {
                themeName: this.form.themeName,
                mainColor: this.form.mainColor,
                loginBackgroundGradientLeft: this.form.loginBackgroundGradientLeft,
                loginBackgroundGradientRight: this.form.loginBackgroundGradientRight,
                loginTaglineGradientLeft: this.form.loginTaglineGradientLeft,
                loginTaglineGradientRight: this.form.loginTaglineGradientRight,
                loginFooterTextColor: this.form.loginFooterTextColor,
                loginFooterTextLinkColor: this.form.loginFooterTextLinkColor,
                loginButtonGradientLeft: this.form.loginButtonGradientLeft,
                loginButtonGradientRight: this.form.loginButtonGradientRight,
                popupShadeBackgroundColorRGB: this.form.popupShadeBackgroundColorRGB,
                modalHeaderColor: this.form.modalHeaderColor,
                subnavUnderlineColor: this.form.subnavUnderlineColor,
                pageHeadersColor: this.form.pageHeadersColor,
                transactionPopupHeadersColor: this.form.transactionPopupHeadersColor,
                pinPopupMainColor: this.form.pinPopupMainColor,
                themeNotes: this.form.themeNotes,
              }
            }

            let action = (await this.$store.dispatch('themes/update', payload))
            if(action.activated) {
              await api.action("Themes", this.themeOption, "Activate")
            }
            await this.$store.dispatch('themes/load')
            this.$toast.success('Theme successfully updated.')
            this.updateInformations = false
          }
      }
    },

    async update () {
      try {

      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async activate () {
      try {
        await api.action("Themes", this.themeOption, "Activate")
        await this.$store.dispatch('themes/load')
        this.$toast.success('Theme successfully activated.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    cancel () {
      this.updateInformations = false
    }
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        themeName: { required },
        mainColor: { required },
        loginBackgroundGradientLeft: { required },
        loginBackgroundGradientRight: { required },
        loginTaglineGradientLeft: { required },
        loginTaglineGradientRight: { required },
        loginFooterTextColor: { required },
        loginFooterTextLinkColor: { required },
        loginButtonGradientLeft: { required },
        loginButtonGradientRight: { required },
        popupShadeBackgroundColorRGB: { required },
        modalHeaderColor: { required },
        subnavUnderlineColor: { required },
        transactionPopupHeadersColor: { required },
        pinPopupMainColor: { required },
        pageHeadersColor: { required },
      }
    }

    return rules
  }
}
</script>

<style scoped>
  .split {
    width: 50%;
  }
  .flex {
    align-items: center;
  }
</style>