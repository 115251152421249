<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>Remote Deposit</h3>
    </div>
    <template>
      <template v-if="addModal">
        <div class="scrollable">
          <template v-if="system.transaction_deposits">
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-text v-model="form.amount" label="Amount" :validator="$v.form.amount" required />
                <div class="sum">
                  <div>Fee: <b>{{ fee | money }}</b></div>
                  <div>Total Deposit<b>{{ total | money }}</b></div>
                </div>
              </div>
            </div>
            <div class="invalid-balance">
              <div class="error-message" v-if="invalidDepositFee"><span>The Amount must be greater than the Fee.</span></div>
            </div>

            <div class="content-form" style="padding-bottom: 0;">
              <h3 class="subtitle">SENDER INFORMATION</h3>
              <div class="form-row form-fullw">
                <form-field-text v-model="form.nameSender" label="Name Of Sender" :validator="$v.form.nameSender" required :maxlength="75" />
              </div>
            </div>
            <remote-address-form v-model="form" :v="$v.form" style="padding-top: 0;"/>
            <div class="content-form">
              <h3 class="subtitle">CHECK IMAGES</h3>
              <div class="form-row form-split">
                <form-field-upload v-model="form.frontImage" label="Front Image" :validator="$v.form.frontImage" required />
                <form-field-upload v-model="form.backImage" label="Back Image" :validator="$v.form.backImage" required />
              </div>
              <div :class="{ 'error-message': submitFrontError || submitBackError, 'file-text': true }">
                <p>Only file types JPG, JPEG, PNG</p>
                <p>Maximum file size = 10MB</p>
              </div>
            </div>
            <div class="content-form">
              <h3 class="subtitle">EXTRA INFORMATION</h3>
              <div class="form-row form-fullw">
                <form-field-text v-model="form.memo" label="Memo" :maxlength="150" required />
                <form-field-text v-model="form.reference" label="Reference" :maxlength="150" required />
              </div>
            </div>
            <div class="buttons">
              <template>
                <button class="main" @click="add()">Submit</button>
                <button class="second" @click="open = false">Cancel</button>
              </template>
              <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
            </div>
          </template>
          <template v-else>
            <div class="disabledText"><b>{{ system.disabled_deposits_text }}</b></div>
            <div class="buttons">
              <button class="second" @click="open = false">Close</button>
            </div>
          </template>
        </div>
      </template>

      <template v-if="addConfirmModal">
        <div class="scrollable" v-if="isDeposit">
          <div class="content-form" v-if="form.depositType == 'Funds'">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <br>
            <div v-if="this.form.deposit_type != 'ach'" class="form-row">
              <p v-if="transaction" class="transaction-status">Reference: {{ transaction.transactionReferenceCode }}, {{ selectedAccount.name }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ depositOptionsText }} Information</p>
              <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details you will need to complete your payment process.</p>
              <template v-if="this.form.deposit_type == 'checks'">
                <p>No Address Provided</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'ach'">
                <p>ACH Deposit Added Successfully</p>
              </template>
              <template v-else>
                <p v-if="transaction && transaction.instructions"><b>Depository Bank Name</b>: {{ transaction.instructions['depository-bank-name'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Bank Address</b>: {{ transaction.instructions['bank-address'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Bank Phone</b>: {{ transaction.instructions['bank-phone'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Credit To</b>: {{ transaction.instructions['credit-to'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Address</b>: {{ transaction.instructions['beneficiary-address'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Routing Number</b>: {{ transaction.instructions['routing-number'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Account Number</b>: {{ transaction.instructions['account-number'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>SWIFT Code</b>: {{ transaction.instructions['swift-code'] }}</p>
                <p v-if="transaction && transaction.instructions"><b>Reference</b>: {{ transaction.transactionReferenceCode }}, {{ selectedAccount.name }}</p>
              </template>
              <p v-if="this.form.deposit_type == 'domestic_wire'" class="margin-10"><b>Note</b>: Your domestic wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
              <p v-else-if="this.form.deposit_type == 'international_wires'" class="margin-10"><b>Note</b>: Your international wire instructions might be different from the above. If you have any doubts or questions please verify your instructions with your account representative. Please follow any specific funding instructions to ensure the funds are received quickly.</p>
              <p v-else class="margin-10"><b>Note</b>: Please follow any specific funding instructions to ensure the funds are received quickly.  If you have any doubts or questions please verify your instructions with your account representative.</p>
            </div>
          </div>
          <div v-else class="content-form">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <br>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">Information</p>
              <p v-if="transaction">{{ getCurrencyName(form.assetsCurrency) }} Wallet Address</p>
              <p v-if="transaction">{{ transaction.contributionIncomingWalletAddress }}</p>
              <p class="margin-10"><b>Note</b>: Send only {{ getCurrencyName(form.assetsCurrency) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
            </div>
          </div>
        </div>
        <div class="scrollable" v-else>
          <div class="content-form" v-if="form.depositType == 'Funds'">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="this.form.deposit_type == 'domestic_wire' || this.form.deposit_type == 'international_wires'" class="margin-10">Please see below for the details submitted for your withdrawal.</p>
              <template v-if="this.form.deposit_type == 'international_wires'">
                <p v-if="transaction"><b>Bank Name</b>: {{ this.form.bank_name }}</p>
                <p v-if="transaction"><b>Account Name</b>: {{ this.form.account_name }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ this.form.account_number }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
                <p v-if="transaction"><b>Bank Swift Code</b>: {{ this.form.swift }}</p>
                <p v-if="transaction"><b>Intermediary Bank Name</b>: {{ this.form.intermediary_bank_name }}</p>
                <p v-if="transaction"><b>Intermediary Bank Reference</b>: {{ this.form.intermediary_bank_reference }}</p>
                <p v-if="transaction"><b>Intermediary Bank Routing Number</b>: {{ this.form.intermediary_bank_routing_number }}</p>
                <p v-if="transaction"><b>Intermediary Bank SWIFT Code</b>: {{ this.form.intermediary_bank_swift_code }}</p>
                <p v-if="transaction"><b>INTERMEDIARY BANK ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.intermediary_address.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.intermediary_address.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.intermediary_address.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.intermediary_address.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.intermediary_address.postal }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>IBAN</b>: {{ this.form.iban }}</p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'checks'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Payee</b>: {{ this.form.payee }}</p>
                <br/>
                <p v-if="transaction"><b>PAYEE ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'ach'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Name of Bank Account</b>: {{ this.form.bank_name }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.form.account_type }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ this.form.routing_number }}</p>
                <p v-if="transaction"><b>Bank Account Number</b>: {{ this.form.account_number }}</p>
                <p v-if="transaction"><b>Bank Account Type</b>: {{ this.form.bank_account_type }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else-if="this.form.deposit_type == 'domestic_wire'">
                <p v-if="transaction"><b>Account Name</b>: {{ transaction.accountName }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ transaction.routingNumber }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ transaction.accountNumber }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ transaction.accountType }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.form.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.form.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.form.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.form.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.form.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.form.futher_credit_acc_name }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.form.futher_credit_acc_number }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <template v-else>
                <p v-if="transaction"><b>Amount</b>: ${{ this.form.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Account Number</b>:  ****{{ this.form.a2a_account_number.substr(4, 3) }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.form.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.form.memo }}</p>
              </template>
              <p class="margin-10"><b>Note</b>: If you have any doubts or questions please verify your instructions with your account representative. </p>
            </div>
          </div>
          <div class="content-form" v-else>
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="transaction"><b>Amount</b>: {{ transaction.totalAssetAmount | assets_balance }}</p>
              <p v-if="transaction"><b>Account Number</b>: ****{{ transaction.toAccountId? transaction.toAccountId.substr(4, 3) : '' }}</p>
              <p v-if="transaction">{{ transaction.outgoingWalletAddress }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              <p class="margin-10"><b>Note</b>: Send only {{ getCurrencyName(form.assetsCurrency) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="open = false">Close</button>
        </div>
      </template>
    </template>
  </popup>
</template>

<script>
import { monbiAccountNumber, assetsAmount, alphaNum, routingNumber, swift } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import AchForm from './forms/ACH'
import RemoteAddressForm from './forms/RemoteAddress'
import AddressForm from './forms/Address'
import InternationalWireForm from './forms/InternationalWire'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
    AchForm,
    AddressForm,
    RemoteAddressForm,
    InternationalWireForm
  },

  data () {
    return {
      form: {
        account: null,
        currency: 'USD',
        amount: null,
        nameSender: null,
        memo: null,
        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,
      },

      transaction: null,
      open: true,
      addModal: true,
      addConfirmModal: false,
      invalidAccountNumber: false,
      invalidFundsCurrency: false,
      errorWallet: false,
      invalidBalance: false,
      invalidAssetBalance: false,
      invalidDepositFee: false,
      invalidToProvider: false,
      fee: 0,
      assetsFee: 0,

      submitError: false,
      submitFrontError: false,
      submitBackError: false,

      featureStatus: true,
      toRegistrations: null,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const regId = (accounts.find(item => item.id === this.form.account))?.regId
      return this.$store.getters['registrations/get'](regId)
    },

    limit () {
      const keys = {
        ach: 'acc_to_acc_limit',
        checks: 'checks_limit',
        domestic_wire: 'wires_limit',
        international_wires: 'wires_limit',
        acc_to_acc: 'acc_to_acc_limit',
      }

      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings[keys[this.form.deposit_type]]) : null
    },

    depositOptionsText () {
      if(this.form.deposit_type == "checks")
        return "Check Deposit"
      if(this.form.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.form.deposit_type == "international_wires")
        return "International Wire"
      if(this.form.deposit_type == "ach")
        return "ACH"
      return
    },

    depositOptions () {
      const options = [
        {
          id: 'ach',
          text: 'eCheck/ACH',
          if: this.selectedAccount?.profileSettings?.ach
        },
        {
          id: 'checks',
          text: 'Check',
          if: this.selectedAccount?.profileSettings?.checks
        },
        {
          id: 'domestic_wire',
          text: 'Domestic Wire',
          if: this.selectedAccount?.profileSettings?.domWires
        },
        {
          id: 'international_wires',
          text: 'International Wire',
          if: this.selectedAccount?.profileSettings?.intlWires
        }
      ]
      if (!this.isDeposit) options.push({
        id: 'acc_to_acc',
        text: 'Account Transfer',
        if: this.selectedAccount?.profileSettings?.acc_to_acc
      })
      return options.filter(opt => opt.if)
    },

    depositTypeOptions () {
      let options = [
        {
          id: 'Funds',
          text: 'Funds',
        },
      ]
      if(this.featureStatus) {
        options.push(
          {
            id: 'Assets',
            text: 'Assets',
            disabled: !this.featureStatus
          }
        )
      }
      return options
    },

    currencyOptions () {
      let currencies = window.currencies || []
      let options = []
      options.push({
        id: 'USD',
        text: 'USD',
      })
      currencies.forEach(currency => {
        if(currency.systemCurrencyType == 'Funds') {
          if(this.selectedAccount){
            if(this.selectedAccount.accounts) {
              if (this.selectedAccount.accounts[0].fundsCurrencies) {
                let fundCurrency = this.selectedAccount.accounts[0].fundsCurrencies.find(item => item.currencyId == currency.id)
                if(fundCurrency){
                  if(fundCurrency.currencyEnabled) {
                    options.push({
                      id: currency.currencyNickname,
                      text: currency.currencyNickname,
                    })
                  }
                }
              }
            }
          }
        }
      })
      return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    isDeposit () {
      return this.modalType === 'deposit'
    },

    total () {
        return (parseFloat(this.form.amount) - parseFloat(this.fee)) || 0
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []
      registrations.forEach(reg => {
        if (reg.accounts?.length) {
          reg.accounts.forEach(account => {
            if(account.profileSettings?.remoteDeposit) {
              if(account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                accounts.push({
                  id: account.id,
                  regId: reg.id,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${reg.name}`,
                  accountNumber: account.accountNumber,
                  provider: account.provider,
                  disabled: true
                })
              }else{
                accounts.push({
                  id: account.id,
                  regId: reg.id,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${reg.name}`,
                  accountNumber: account.accountNumber,
                  provider: account.provider
                })
              }
            }
          })
        }
      })

      if (this.isCustomer) {
        accounts = accounts.filter(account => ['Open'].includes(account.status))
      } else {
        const categories = {}
        const availableStatuses = ['Open', 'Restricted', 'Suspended', 'Dormant', 'Hold']
        availableStatuses.forEach(status => {
          const accs = accounts.filter(account => account.status === status)
          if (accs.length) categories[status] = accs
        })
        return categories
      }

      return accounts
    },


    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.invalidToProvider = false
      this.getFee()
    },

    'form.amount' () {
      this.$v.form.amount.$touch()
      if(this.isDeposit){
        if (this.form.amount > parseFloat(this.fee)) {
          this.invalidDepositFee = false
          return
        } else {
          this.invalidDepositFee = true
          return
        }
      }

      if (parseFloat(this.withdrawalLimit) < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      } else {
        this.invalidBalance = false
        return
      }
    },

    'form.account_number' () {
      this.$v.form.account_number.$touch()
    },

    'form.deposit_type' () {

      this.invalidToProvider = false
    },

    'form.save_as_template' () {
      if(this.form.save_as_template) {
        this.$nextTick(() => this.$el.querySelectorAll('.template-name input')[1].focus())
        this.$nextTick(() => this.$el.querySelectorAll('.template-name input')[1].select())
      }
    }
  },

  methods: {

    getCurrencyName (currencyId) {
      if(this.form.assetsCurrency) {
        let currencies = window.currencies || []
        currencies = currencies.filter(currency => {
          return currency.id === currencyId
        })
        return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
      }
      return null
    },

    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      var ext = this.getExtension(this.form.frontImage.name);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          this.submitError = false
          this.submitFrontError = false
          break;
        default:
          this.submitError = true
          this.submitFrontError = true
          return
      }

      var extBack = this.getExtension(this.form.backImage.name);
      switch (extBack.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
          this.submitError = false
          this.submitBackError = false
          break;
        default:
          this.submitError = true
          this.submitBackError = true
          return
      }

      this.submitError = false
      const loader = this.$loading.show()
      let payload;

      payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        amount: this.form.amount,
        reference: this.form.reference,
        nameSender: this.form.nameSender,
        memo: this.form.memo,
        completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        currencyName: this.form.currency,
        country: this.form.country,
        state: this.form.state,
        address: this.form.address,
        city: this.form.city,
        postal: this.form.postal,
      }

      this.transaction = (await api.action("Transactions", "new", "RemoteDeposit", payload)).item

      await this.uploadImage();

      payload = {
        id: this.transaction.id,
        profileId: this.form.account,
      }
      if(this.transaction.status != 'Review') {
        (await api.action("Transactions", this.transaction.id, "RemoteDepositSolidApi", payload)).item
      }

      this.$toast.success('Transaction successfully created.')
      loader.hide()
      this.open = false
      //this.addModal = false
      //this.addConfirmModal = true
    },

    uploadImage () {
      return new Promise((resolve, reject) => {
        var url="/Transactions/new/RemoteDepositUpload";
        var formData = new FormData();
        formData.append("frontImage", this.form.frontImage);
        formData.append("backImage", this.form.backImage);
        formData.append("id", this.transaction.id);
        formData.append("returnObj", true);
        $.ajax({
          url: window.appData.apiRoot + url,
          data: formData,
          contentType: false,
          dataType: "json",
          processData: false,
          error: (jqXHR, status, error) => {
            reject(error);
          },
          type: "POST",
          success: (data) => {
            resolve(data);
          },
        });
      })
    },

    getExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    },

    async getFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: 'checks',
        entryType: 'Deposit'
      }
      this.fee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
    },

    async getTemplateId () {
      const templateId = (await api.action("Transactions", "new", "GetTemplateID")).action.id
      this.form.template_name = 'Template - ' + templateId
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
    },
  },

  async created () {
    this.getFeatureStatus();
    this.getTemplateId()
    this.toRegistrations = (await api.list('Registration',{'corporate' : 1}, {}, {}, {}, ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents'])).items
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        amount: { required, decimal },
        account: { required },
        nameSender: { required },
        currency: { required },
      }
    }

    rules.form.country = { required }
    rules.form.city = { required }
    rules.form.state = { required }
    rules.form.postal = { required, alphaNum }
    rules.form.address = { required }
    rules.form.frontImage = { required }
    rules.form.backImage = { required }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}
.file-text {
  margin-top:-30px;
}

.file-text p{
  margin-top:0;
  font-size: 12px;
}
</style>