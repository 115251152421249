<template>
  <div>
    <div v-if="loading && !hasNumber">
      <div style="padding: 24px 30px 10px 10px;">
        <input v-model="providerId" placeholder="Provider ID" />
        <button class="btn btn-success" @click.prevent="loadAccounts">
          Select provider
        </button>
      </div>
    </div>
    <div v-else-if="loading">Loading...</div>
    <section v-if="!loading">
      <div style="padding: 24px 30px 10px 10px;" v-if="closed">
        All accounts listed below are closed
      </div>
      <div style="padding: 24px 30px 10px 10px;">
        <button class="btn btn-success" @click.prevent="closeAll">
          Close all shown accounts
          {{ progress?'('+progress+'/'+accounts.length+')':'' }}
        </button>
      </div>

      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Account</th>
            <th>Type</th>
            <th>Status</th>
            <th>Available</th>
            <th>Balance</th>
          </tr>
          <tr v-for="account in accounts" :key="account.id">
            <td><span>Account Date</span>{{ account.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
            <td>
              <span>Name</span>
              {{ account.regName }}
            </td>
            <td><span>Account</span>
              <a>{{ account.accountNumber }}</a>
            </td>
            <td><span>Account Type</span>{{ account.regType }}</td>
            <td><span>Account Status</span><em>
              {{ account.accountStatus }}</em></td>
            <td><span>Available</span>{{ account.systemAvailableBalance | balance }}</td>
            <td><span>Balance</span>{{ account.systemTotalBalance | balance }}</td>
          </tr>
        </table>
      </section>

    </section>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    accounts: [],
    loading: true,
    hasNumber: false,
    progress: 0,
    closed: false,
    providerId: "",
  }),
  methods: {
    async loadAccounts() {
      if (!(this.providerId*1))
        return false;
      this.hasNumber=true;
      this.accounts=(await api.list("Account", {provider: this.providerId, accountStatus: ["!=", "Closed"]})).items.filter(item=>{
        if (item.accountStatus=='Closed') return false;
        return true;
      });
      this.loading=false;
    },
    async closeAll() {
      if (confirm("Are you sure?")) {
        for(var i in this.accounts) {
          var account=this.accounts[i];
          this.progress=i*1+1;
          await api.update("Account", account.id, {accountStatus: "Closed"});
        }
        this.progress=0;
        this.closed=true;
      }
    },
  },
  mounted() {
  },
}
</script>
