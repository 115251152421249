<template>
  <div>
    <button v-if="$hasPerm('new-scan')" class="btn btn-success" @click="open = true">New Scan</button>

    <section  class="content-tlist" style="margin: 20px 0;">
      <table class="content-table">
        <tr>
          <th>Scan</th>
          <th>Date/Time</th>
          <th>Profile</th>
          <th>Name</th>
          <th>Scan Type</th>
          <th>Scan Result</th>
          <th>File</th>
          <th>Options</th>
        </tr>
        <tr v-for="scan in scans" :key="scan.id">
          <td><span>Scan</span>{{ scan.scanId }}</td>
          <td><span>Date/Time</span>{{ scan.createdAt | date('MM/D/YYYY') }}</td>
          <td>
            <span>Profile</span>
            <a :href="`/system-customers/registrations/${scan.regId}`">{{ scan.profile }}</a>
          </td>
          <td>
            <span>Name</span>
            {{ scan.name }}
          </td>
          <td><span>Scan Type</span>
            {{ scan.scanType }}
          </td>
          <td><span>Scan Result</span>
            <strong :class="statusColorClasses[scan.scanResult]">{{ scan.scanResult }}</strong>
          </td>
          <td><span>File</span>
            <a class="link" target="_blank" :href="`files/scanReports/${scan.id}/${scan.file}`">{{ scan.file }}</a>
          </td>
          <td><span>Options</span>
            <button v-if="scan.scanResult === 'Pending'" class="btn btn-secondary" @click="update(scan.id)">Update</button>
          </td>
        </tr>
      </table>
    </section>

    <popup :open.sync="open">
      <div class="popup-header">
        <h3>New Scan</h3>
      </div>
      <template>
        <div class="scrollable">
          <template>
            <div class="content-form">
              <div class="form-row form-split">
                <form-field-select v-model="submitForm.scanType" label="Scan Type" :validator="$v.submitForm.scanType" :options="providerOptions" required />
                <form-field-select v-model="submitForm.profile" label="Select Profile" :validator="$v.submitForm.profile" :options="accountOptions" required disabled/>
              </div>
              <div class="form-row form-fullw justify-content-center">
                <p class="text-center">Notes for Compliance Scans</p>
              </div>
              <br/>
              <div class="form-row form-fullw">
                <p>1 - KYC Reports are enabled for Business Profiles only</p>
              </div>
            </div>
          </template>
          <div class="buttons">
            <template>
              <button class="main" @click="submit()" :disabled="$v.submitForm.$invalid">Submit</button>
              <button class="second" @click="open = false">Close</button>
            </template>
          </div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
  import { monbiAccountNumber, assetsAmount, alphaNum, routingNumber, swift } from '../../lib/validators'
  import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'
export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    submitForm: {
      profile: null,
      scanType: null
    },

    open: false
  }),

  computed: {
    scans () {
      let scans = this.$store.state.scan_reports.data || []
      return scans
    },

    statusColorClasses () {
      return {
        Pending: 'c2',
        Error: 'c3',
        Complete: 'c1',
      }
    },

    accountOptions () {
      let reg = this.registration
      let accounts = []
      accounts.push({
        id: reg.id,
        text: `${reg.profileNumber}-${reg.name}`,
      })

      return accounts
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Compliance').map(provider => ({
        id: provider.id,
        text: provider.provider_name,
        disabled: this.registration.type == 'Personal'
      }))
    },
  },

  async created () {
    this.submitForm.profile = this.registration.id
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    await this.refreshScans();
  },

  methods: {
    async refreshScans () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.regId = this.registration.id

      await this.$store.dispatch('scan_reports/load', { params })
      this.loading=false;
      loader.hide()
    },

    async submit () {
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('scan_reports/action', {
          id: 'new',
          action: 'KycSiteScan',
          payload: {
            provider: this.submitForm.scanType,
            regId: this.submitForm.profile
          }
        })).action
        this.refreshScans();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
        this.open = false
      }
    },

    async update (id) {
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('scan_reports/action', {
          id: id,
          action: 'UpdateScan',
          payload: {

          }
        })).action
        this.refreshScans();
      } catch (e) {
        //this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    }
  },

  validations () {
    let rules = {}
    rules = {
      submitForm: {
        profile: { required },
        scanType: { required },
      }
    }

    return rules
  }
}
</script>
<style lang="scss" scoped>
  .buttons {
    margin-top: 270px;
  }
</style>