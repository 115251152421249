<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers">Partner Detail View</h2>
      </div>
    </section>

    <section v-if="partner" class="content-tabs">
      <div class="content-tabs-container">
        <div class="cs-tri">
          <div>Partner Number: <strong>{{ partner.partnerNumber }}</strong></div>
          <div>Name: <strong>{{ partner.business.legal_name}}</strong></div>
          <div>Partner Status: <strong>{{ status }}</strong></div>
          <div>Last Update: <strong>{{ partner.updatedAt | date }}</strong></div>
        </div>
        <ul class="ctabs first-row">
          <li
            v-for="tab in tabs[1]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
        <ul class="ctabs second-row">
          <li
            v-for="tab in tabs[2]"
            :key="tab"
            :class="{ active: activeTab === tab }">
            <a @click="activeTab = tab">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </section>

    <section class="client-properties active">
      <general-info v-if="activeTab === 'General'" :id="id" />
      <profile-info v-if="activeTab === 'Partner Info'" :id="id" />
      <profile-documents v-if="activeTab === 'Documents'" :id="id"/>
      <notes v-if="activeTab === 'Notes'" :id="id" />
      <emails v-if="activeTab === 'Emails'" :id="id" />
      <access v-if="activeTab === 'Access'" :id="id" />
      <accounts v-if="activeTab === 'Reports'" :id="id" />
      <compliance v-if="activeTab === 'Compliance'" :id="id" />
      <services v-if="activeTab === 'Services'" :id="id" />
      <settings v-if="activeTab === 'Settings'" :id="id" />
      <tools v-if="activeTab === 'Tools'" :id="id" />
    </section>
  </div>
</template>

<script>
import helperMixin from '../../components/common/helper-mixin'

import Notes from './Notes'
import Emails from './Emails'
import Access from './Access'
import Accounts from './Accounts'
import Services from './Services'
import Settings from './Settings'
import Compliance from './Compliance'
import GeneralInfo from './GeneralInfo'
import ProfileInfo from './ProfileInfo'
import ProfileDocuments from './ProfileDocuments'
import Tools from './Tools.vue'

export default {
  name: 'PartnerRegistration',

  mixins: [helperMixin],

  components: {
    Notes,
    Access,
    Emails,
    Services,
    Accounts,
    Settings,
    Compliance,
    GeneralInfo,
    ProfileInfo,
    ProfileDocuments,
    Tools
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    },
  },

  data () {
    return {
      activeTab: 'General'
    }
  },

  computed: {
    status () {
      let names = {}
      return names[this.partner?.status] || this.partner?.status
    },

    tabs () {
        return {
          1: ['General', 'Partner Info', 'Documents', 'Notes', 'Emails'],
          2: ['Access', 'Compliance', 'Reports', 'Tools', 'Settings']
        }
    },

    partner () {
      return this.$store.getters['partners/get'](this.id)
    }
  },

  async created () {
    const loader = this.$loading.show()
    await this.$store.dispatch('partners/get', this.id)
    await this.$store.dispatch('partner_notes/load', { params: { partnerId: this.id } })
    loader.hide()
  }
}
</script>
