<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <button class="btn btn-success" @click="open = 'Open'" style="margin-top:-65px"><i class="el-icon-files" /> New File Transfer</button>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />


    <div class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>File</th>
              <th>Date/Time</th>
              <th>Profile</th>
              <th>Description</th>
              <th>File</th>
              <th>Options</th>
            </tr>
            <tr v-for="file in files" :key="file.id">
              <td><span>File</span>{{ file.fileNumber }}</td>
              <td><span>Date/Time</span>{{ file.createdAt | date('MM/D/YYYY') }}</td>
              <td>
                <span>Profile</span>
                <a v-if="!isCustomer" :href="`/system-customers/registrations/${file.profile}`">{{ file.profileNumber }}</a>
                <template v-else>{{ file.profileNumber }}</template>
              </td>
              <td>
                <span>Description</span>
                {{ file.description }}
              </td>
              <td><span>File</span>
                <a class="link" target="_blank" :href="`files/fileTransfer/${file.id}/${file.file}`">{{ file.file }}</a>
              </td>
              <td><span>Options</span>
                <button v-if="!isCustomer" class="action-button remove" @click="remove(file.id)">Remove</button>
              </td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>
    <file-transfer-modal v-if="open" :modal-type="open" @close="open = null" />
    <file-transfer-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import FileTransferFilters from './filters/FileTransferFilters.vue'
import AppliedFilters from '../../components/common/AppliedFilters'
import FileTransferModal from "./FileTransferModal.vue";

export default {
  name: 'FileTransfer',

  components: {
    FileTransferModal,
    FileTransferFilters,
    AppliedFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,

    activeInfo: true,
    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),

    sortName: null,
    sortFlag: true,
    open: null,

    loading: false,
    page: 1,
    perPage: 25,
    pages: [],
    totalNumber: 0,
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        number: 'File Number',
        profile: 'Profile',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.filters[key]}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    files () {
      let files = this.$store.state.file_transfer.data || []
      this.totalNumber = this.$store.state.file_transfer.count || 0
      return files
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    currentUser () {
      return window.appData.currentUser
    },
  },

  async created () {
    await this.refreshFileTransfers();
    const loader = this.$loading.show()
    try {
      if (this.isCustomer) await this.$store.dispatch('registrations/get', this.currentUser.regId)
      else await this.$store.dispatch('registrations/load', {params: { corporate: ['!=', 1] }, skip: ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents']})
    } catch {} finally {
      this.loading = false
      loader.hide();
    }
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'status') return window.profileReviewStatuses[value]

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    async refreshFileTransfers () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if(this.isCustomer) {
        params.profile = this.currentUser.regId
      }
      if (!this.filters) {

      } else {
        if (this.filters.fromDate)
          params.createdAt=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAt=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.number) {
          params.fileNumber = ['like', '%' + this.filters.number + '%'];
        }
      }

      await this.$store.dispatch('file_transfer/load', { params, limit: this.perPage, start: (this.page-1) * this.perPage })
      this.loading=false;
      loader.hide()
    },

    async remove (id) {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('file_transfer/delete', id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },

  watch: {
    appliedFilters () {
      this.refreshFileTransfers();
    },

    page() {
      this.refreshFileTransfers();
    },

    files () {
      this.setPages();
    },

    perPage() {
      if(this.page == 1){
        this.refreshFileTransfers();
      }else{
        this.page = 1
      }
    }
  },

  validations () {

  }
}
</script>
<style lang="scss" scoped>
  .buttons {
    margin-top: 270px;
  }

  .content-tlist {
    border: none;
  }

</style>
