<template>
  <div class="mnb-field">
    <label>{{ label }}</label>
    <copyinput
      ref="input"
      v-model="data"
      :type="type"
      class="mnb-input"
      :required="isRequired"
      :disabled="disabled"
      :maxlength="maxlength || null"
      :class="{ error: hasError }"
      @focus="focused = true"
      @blur="focused = false"
      @input="$emit('input', $event.target.value)"
      @change="$emit('input', $event.target.value)"/>
  </div>
</template>

<script>
import inputMixin from './input-mixin'

export default {
  props: ["type", "maxlength"],
  mixins: [inputMixin],
  mounted() {
  },
}
</script>
<style lang="scss" scoped>
  input:disabled {
    opacity: 0.7 !important;
    border-color: rgba(118, 118, 118, 0.3);
  }
</style>
