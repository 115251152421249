<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>

    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Status</th>
          <th>Feature</th>
          <th>Feature Details</th>
        </tr>
        <tr v-for="(feature, i) in features" :key="i" :class="feature.secondary? 'secondary' : ''">
          <td>
            <div class="switch">
              <i :class="feature.status == 'Enabled' ? 'green' : 'grey'" />
            </div>
          </td>
          <td>{{ feature.feature }}</td>
          <td>{{ feature.featureDetails }}</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import Popup from '../popup'
  import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
  import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

  export default {
    name: 'system-feature',

    components: {
      BasicForm,
      MnbInput,
      MnbSelect,
      Popup
    },

    data () {
      return {
        featureStatus: true,
        featureStatusMultiCurrency: true,
        featureStatusDebitCard: true,
      }
    },

    computed: {
      features () {
        return this.$store.state.systemFeatures.data
      },
    },

    async created () {
      await this.getFeatureStatus();
      this.$store.dispatch('systemFeatures/load')
    },

    methods: {
      async enable (id) {
        const loader = this.$loading.show()
        let payload;
        payload = {
          enabled: 1,
        }
        const response = (await api.action("SystemFeatures", id, "Update", payload))
        this.$store.dispatch('systemFeatures/load')
        await this.getFeatureStatus();
        loader.hide()
      },

      async disable (id) {
        const loader = this.$loading.show()
        let payload;
        payload = {
          enabled: 0,
        }
        const response = (await api.action("SystemFeatures", id, "Update", payload))
        this.$store.dispatch('systemFeatures/load')
        await this.getFeatureStatus();
        loader.hide()
      },

      async disableDigitalAssets (id) {
        const loader = this.$loading.show()
        let payload;
        payload = {
          enabled: 0,
        }
        const response = (await api.action("SystemFeatures", id, "Update", payload))
        let tradeDesk = this.features.find(feature => feature.feature == 'Trade Desk')?.id
        if(tradeDesk)
            (await api.action("SystemFeatures", tradeDesk, "Update", payload))
        this.$store.dispatch('systemFeatures/load')
        await this.getFeatureStatus();
        loader.hide()
      },

      async disableMultiCurrency (id) {
        const loader = this.$loading.show()
        let payload;
        payload = {
          enabled: 0,
        }
        const response = (await api.action("SystemFeatures", id, "Update", payload))
        let tradeDesk = this.features.find(feature => feature.feature == 'Exchange Desk')?.id
        if(tradeDesk)
          (await api.action("SystemFeatures", tradeDesk, "Update", payload))
        this.$store.dispatch('systemFeatures/load')
        await this.getFeatureStatus();
        loader.hide()
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Digital Assets']
        this.featureStatusMultiCurrency = window.systemFeatures['Multi-Currency']
        this.featureStatusDebitCard = window.systemFeatures['Card Services']
      },
    },

    validations () {
    }
  }
</script>

<style lang="scss" scoped>
  .switch {
    display: inline-block;
    margin-right: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 999px;
    line-height: 20px;
    padding: 3px 3px 3px 0px;
    box-sizing: border-box;
    font-size: 13px;
    color: #666;
    float: left;
    min-width: 28px;
    text-align: right;
    background: #fff;
  }

  .switch i {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 999px;
    margin-left: 3px;
  }

  .switch i.green {
    background-color: #67C23A;
  }

  .switch i.grey {
    background-color: #666;
  }

  .secondary {
    background: #000;

    td {
      color: #fff;
      background: #000 !important;
    }
  }
  .content-tlist {
    border: none;
  }
</style>
