<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <template></template>
    <template>
      <template>
        <div class="content-tlist" style="padding: 30px;">
          <basic-form>
            <label>Maintenance Message</label>
            <textarea rows="10" class="fullw" v-model="systemStatus.maintenanceMessage":disabled="!updateInformations"></textarea>
            <br/>
            <br/>
            <h4>Section 1 </h4>
            <mnb-input v-model="systemStatus.section1Name" label="Category" :maxlength="150" :disabled="!updateInformations" />
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section1Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem1(i)" class="button-handle cancel">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList1" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem1(i)" class="button-handle cancel">Remove</button>
                </td>
              </tr>
            </table>
          </div>

            <basic-form>
              <button class="main" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem1()">+ Add Item</button>
            </basic-form>
          <basic-form>
            <br/>
            <br/>
            <h4>Section 2 </h4>
            <mnb-input v-model="systemStatus.section2Name" label="Category" :maxlength="150" :disabled="!updateInformations"/>
          </basic-form>
          <div class="content-tlist">
            <table class="content-table">
              <tr>
                <th style="width: 20%;">Name</th>
                <th style="width: 20%;">Item Message</th>
                <th style="width: 20%;">Color</th>
                <th style="width: 20%;">Downtime</th>
                <th style="width: 20%;">Options</th>
              </tr>
              <tr v-for="(item, i) in systemStatus.section2Items">
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.name" />
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <input type="text" class="split" v-model="item.message" />
                  </template>
                  <template v-else>
                    {{ item.message }}
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.color">
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                      <option value="black">Black</option>
                    </select>
                  </template>
                  <template v-else>
                    <div class="color" :class="item.color"></div>
                  </template>
                </td>
                <td>
                  <template v-if="updateInformations">
                    <select v-model="item.downtime">
                      <option value="Operational">Operational</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Down">Down</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </template>
                  <template v-else>
                    {{ item.downtime }}
                  </template>
                </td>
                <td>
                  <button v-if="updateInformations" @click="removeStatusItem2(i)" class="button-handle cancel">Remove</button>
                </td>
              </tr>
              <tr v-for="(item, i) in itemList2" :key="i">
                <td>
                  <input type="text" class="split" v-model="item.name" />
                </td>
                <td>
                  <input type="text" class="split" v-model="item.message" />
                </td>
                <td>
                  <select v-model="item.color">
                    <option value="green">Green</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="black">Black</option>
                  </select>
                </td>
                <td>
                  <select v-model="item.downtime">
                    <option value="Operational">Operational</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Down">Down</option>
                    <option value="Offline">Offline</option>
                  </select>
                </td>
                <td>
                  <button @click="removeItem2(i)" class="button-handle cancel">Remove</button>
                </td>
              </tr>
            </table>
          </div>
          <basic-form>
            <button class="main" style="margin-left: 30px;" :disabled="!updateInformations" @click="addItem2()">+ Add Item</button>
          </basic-form>
          <br/>
          <br/>
          <br/>
          <br/>
          <basic-form style="text-align: center;">
            <template v-if="updateInformations">
              <button class="main" @click="save()">Submit</button>
              <button class="second" @click="cancel()">Cancel</button>
            </template>
            <template v-else>
              <button class="main" @click="updateInformations = true">Update</button>
            </template>
          </basic-form>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { required, maxLength } from 'vuelidate/lib/validators'
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbTextarea from '../../components/ui/forms/Textarea'

  import mixinAutoResize from "../../components/ui/forms/autoresize.js"
  import inputMixin from "../../components/ui/forms/input-mixin.js"
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    mixins: [mixinAutoResize],

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      DateTimePicker,
    },

    data () {
      return {
        form: {
          maintenanceMessage: null,
          section1Name: null,
          section1Items: null,
          section2Name: null,
          section2Items: null,
        },
        updateInformations: false,
        today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
        itemList1: [],
        itemList2: []
      }
    },

    computed: {
      systemStatus () {
         return this.$store.getters['systemStatus/get'](1) || this.form
      },

      statusColorClasses () {
        return {
          Sent: 'c1',
          Resent: 'c2',
          Archived: 'c2',
          Error: 'c3'
        }
      },
    },

    watch: {

    },

    async created () {
      await this.$store.dispatch('systemStatus/load')
    },

    methods: {
      addItem1() {
        this.itemList1.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      addItem2() {
        this.itemList2.push(
          {
            name: null,
            message: null,
            color: null,
            downtime: null
          }
        )
      },

      removeItem1(i) {
        this.itemList1.splice(i,1)
      },

      removeItem2(i) {
        this.itemList2.splice(i,1)
      },

      removeStatusItem1(i) {
        this.systemStatus.section1Items.splice(i,1)
      },

      removeStatusItem2(i) {
        this.systemStatus.section2Items.splice(i,1)
      },

      cancel() {
        this.itemList1 = []
        this.itemList2 = []
        this.updateInformations = false
      },

      async save () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const loader = this.$loading.show()
          try {
            this.systemStatus.updatedAt = 'CURRENT_TIMESTAMP'
            if(this.systemStatus.section1Items == null)
              this.systemStatus.section1Items = []
            for(var i = 0; i < this.itemList1.length; i++){
              this.systemStatus.section1Items.push(
                this.itemList1[i]
              )
            }

            if(this.systemStatus.section2Items == null)
              this.systemStatus.section2Items = []
            for(var i = 0; i < this.itemList2.length; i++){
              this.systemStatus.section2Items.push(
                this.itemList2[i]
              )
            }

            await this.$store.dispatch('systemStatus/update', { id: 1, payload: this.systemStatus })
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
            this.itemList1 = []
            this.itemList2 = []
            this.updateInformations = false
         }
        }
      },
    },

    validations() {
      const rules = {
        form: {

        }
      }
      return rules
    }
  }
</script>

<style lang="scss" scoped>
  .color {
    height: 22px;
    border-radius: 4px;
    width: 50px;
  &.green {
     background: #7EEECE;
   }
  &.yellow {
     background: #fbecbe;
   }
  &.red {
     background: #f9a0a0;
   }
  &.black {
     background: #ccd5dd;
   }
  }
</style>
