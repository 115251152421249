<template>
  <div>
    <section class="" style="margin-left: 30px;">
      <div class="flex-between">
        <div>&nbsp;</div>
        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>
    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Statement</th>              
          <th>Statement Date</th>
          <th>Filename</th>                     
          <th>Options</th>                     
        </tr>
        <tr v-for="statement in statements" :key="statement.id">
          <td><span>Statement</span>
            {{ statement.number }}
          </td>               
          <td><span>Statement Date</span>
            {{ statement.createdAt | date('LL') }}
          </td>
          <td><span>Filename</span>
            <template v-if="statement.file">
              <a :href="`/download/PartnerStatement/${statement.id}`" target="_blank">
                {{ statement.file }}
              </a>
            </template>
          </td>                           
          <td><span>Options</span>
           <a :href="`/download/PartnerStatement/${statement.id}`" target="_blank">
              <button class="main">View</button>
           </a>
          </td>                           
        </tr>
      </table>
    </section>
    <partner-stats-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>
<script>

import DateTimePicker from 'vue-vanilla-datetime-picker';
import PartnerStatsFilters from './filters/PartnerStatsFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  components: {
    DateTimePicker,
    AppliedFilters,
    PartnerStatsFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    appliedFilters () {
        const filterNames = {
          
        }
        
        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.filters[key]}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },

    statements() {
      let data =  this.$store.state.partner_statement.data || []
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        data = data.filter(item => {
          return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= moment("00:00:00", "HH:mm:ss")
        })
      }else{
        if (this.filters.fromDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= from
          })
        }

        if (this.filters.toDate) {
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') <= to
          })
        }
      }
      return data
    }
  },

  async created () {
    if(this.currentUser.parId) {
      await this.$store.dispatch('partner_statement/load', {order: ['createdAt:DESC'], params: {partnerId: this.currentUser.parId}})
    }
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    search () {
      this.filters = { ...this.form }
    },

    resetItem() {
      this.filters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },
  },

  watch: {
  },
}
</script>
