<template>
  <div>
    <div class="cp-box">
      <template v-if="canEdit">
        <div class="mail-buttons-container">
          <div class="cof-field mobinl" v-if="registration.status == 'New'">
            <label>&nbsp;</label>
            <button class="main" @click="sendMail(id, 'registrations', 'RegistrationEmail')">Registration</button>
          </div>
          <div class="cof-field mobinl" v-if="registration.status == 'Complete' || registration.status == 'Activation'">
            <label>&nbsp;</label>
            <button class="main" @click="sendMail(registration.user.id, 'users', 'CustomerWelcomeEmail')">Activation</button>
          </div>
        </div>
        <hr>
      </template>
      <section class="content-filters">
        <div class="">
          <div class="cof-row">
            <div class="cof-field">
              <label>Date Search</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="searchForm.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="searchForm.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  :max-date="today" >
                </date-time-picker>
                &nbsp;
                <button class="main" @click="search()">Search</button>
                &nbsp;
                <button class="second" @click="resetItem()">Reset Filters</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section  class="content-tlist" style="margin: 30px 0;">
        <table class="content-table">           
          <tr>
            <th>Date/Time</th>
            <th>Type</th>
          </tr>
          <tr v-for="email in filterEmails" :key="email.id">
            <td><span>Date/Time</span>{{ email.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
            <td><span>Type</span>{{ email.email_type }}</td>
          </tr>
          <tr v-if="!emails.length">
            <td class="empty-table-text" colspan="2">There are no emails</td>
          </tr>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
import DateTimePicker from 'vue-vanilla-datetime-picker';
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  components: {
    DateTimePicker
  },

  data () {
    return {
      emails: [],
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      appliedFilters: null,
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    },

    canEdit () {
      return !['Denied', 'Archived'].includes(this.registration.status)
    },

    filterEmails () {
      let data = this.emails || []
      if (!this.appliedFilters) {
        data = data.filter(item => {
          return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= moment("00:00:00", "HH:mm:ss")
        })
      }else{
        if (this.appliedFilters.fromDate) {
          const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= from
          })
        }

        if (this.appliedFilters.toDate) {
          const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') <= to
          })
        }
      }

      return data
    }
  },

  created() {
    this.load()
  },

  methods: {
    search() {
      this.appliedFilters = {...this.searchForm}
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
    },

    async sendMail (id, action, mailType) {
      try {
        const loader = this.$loading.show()
        await this.$store.dispatch(`${action}/action`, { id, action: mailType })
        await this.load()
        loader.hide()
        this.$toast.success('Email successfully sent.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async load () {
      const e1 = await this.$store.dispatch('email_logs/load', { params: { entity: 'Registration', entityId: this.id } })
      const e2 = this.registration.user ? await this.$store.dispatch('email_logs/load', { params: { entity: 'User', entityId: this.registration.user.id } }) : []
      this.emails = e1.concat(e2)
    }
  }
}
</script>

<style lang="scss" scoped>
  .mail-buttons-container {
    width: 100%; 
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px; 
  }
</style>