<template>
  <div>
    <section class="" style="margin-left: 30px;">
      <h3 class="monthly-head">Commission Management</h3>
      <div class="content-filters">
            <basic-form class="form-flex">
              <div class="cof-row dates">
                <div class="cof-field">
                  <label>Start Date</label>
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="form.fromDate"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      :max-date="date2"
                      >
                    </date-time-picker>
                  </div>
                  <div class="error-message" v-if="$v.form.fromDate.$dirty && $v.form.fromDate.$invalid"><span>Please enter start date.</span></div>
                  <div class="error-message" v-if="!$v.form.fromDate.$invalid && !$v.form.toDate.$invalid && !validDate"><span>Start date must be earlier than end date.</span></div>
                </div>
                <div class="cof-field">
                  <label>End Date</label>
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="form.toDate"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      :max-date="date2"
                    >
                    </date-time-picker>
                  </div>
                  <div class="error-message" v-if="toDateError"><span>Invalid End Date.</span></div>
                  <div class="error-message" v-if="$v.form.toDate.$dirty && $v.form.toDate.$invalid"><span>Please enter end date.</span></div>
                </div>
              </div>
              <div class="cof-row buttons">
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button class="second" @click="refreshPartners()" :disabled="toDateError || (form.fromDate == null) || (form.toDate == null)">Generate</button>
                </div>
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button class="second" @click="reset()">Reset</button>
                </div>
              </div>
              <div class="cof-row run">
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button class="main" v-if="isRun" @click="run()">Run</button>
                </div>
              </div>
            </basic-form>
          </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 20%">PartnerID</th>
          <th style="width: 20%">Name</th>
          <th style="width: 20%">Status</th>
          <th style="width: 20%">Total Commission</th>
          <th style="width: 20%">Options</th>
        </tr>
      </table>
      <div class="accordion">        
        <template v-for="partner in partners">
        <div :class="{'cof-customer-box' : true, 'cof-box-active' : activeInfo == partner.id}">
          <table class="content-table">
            <tr>
              <td style="width: 20%"><span>PartnerID</span>
                <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
              </td>
              <td style="width: 20%"><span>Name</span>
                {{ partner.business.legal_name }}
              </td>
              <td style="width: 20%"><span>Status</span>
                <em :class="statusColorClasses[partner.status]">{{ partner.status }}</em>
              </td>
              <td style="width: 20%"><span>Total Commission</span>
                <b>$ {{ partner.total | formattedTwoDecimal }}</b>
              </td>
              <td style="width: 20%"><span>Options</span>
                <a class="link" v-if="!(activeInfo == partner.id)" @click.prevent="activeInfo = partner.id">Details</a>
                <a class="link" v-else @click.prevent="activeInfo = null">Hide</a>
              </td>
            </tr>
            <tr class="detail-row">
              <td colspan="5"><p style="text-align:center"><b>Partner Commission Detail</b></p></td>              
            </tr>
            <tr class="detail-row">
              <td><b>Fee Name</b></td>
              <td><b>Fee</b></td>
              <td><b>Deposit</b></td>
              <td><b>Withdrawal</b></td>
              <td><b>Total</b></td>
            </tr>
            <tr class="detail-row">
              <td>Volume Fee</td>
              <td>$ {{ partner.volumeFee | formattedTwoDecimal }}</td>
              <td></td>
              <td></td>
              <td>$ {{ partner.volumeFee | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Monthly Access Fee</td>
              <td>({{ partner.accountCount }}) $ {{ partner.monthlyAccessFee | formattedTwoDecimal }}</td>
              <td></td>
              <td></td>
              <td>$ {{ partner.monthlyAccessFee | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>ACH</td>
              <td></td>
              <td>({{ partner.achDepositCount }}) $ {{ partner.achDeposit | formattedTwoDecimal }}</td>
              <td>({{ partner.achWithdrawalCount }}) $ {{ partner.achWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.achTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Check</td>
              <td></td>
              <td>({{ partner.checkDepositCount }}) $ {{ partner.checkDeposit | formattedTwoDecimal }}</td>
              <td>({{ partner.checkWithdrawalCount }}) $ {{ partner.checkWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.checkTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Domestic Wire</td>
              <td></td>
              <td>({{ partner.domesticDepositCount }}) $ {{ partner.domesticDeposit | formattedTwoDecimal }}</td>
              <td>({{ partner.domesticWithdrawalCount }}) $ {{ partner.domesticWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.domesticTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>International Wire</td>
              <td></td>
              <td>({{ partner.internationalDepositCount }}) $ {{ partner.internationalDeposit | formattedTwoDecimal }}</td>
              <td>({{ partner.internationalWithdrawalCount }}) $ {{ partner.internationalWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.internationalTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Account Transfer</td>
              <td></td>
              <td></td>
              <td>({{ partner.accountWithdrawalCount }}) $ {{ partner.accountWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.accountTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Assets</td>
              <td>({{ partner.assetsFeeCount }}) $ {{ partner.assetsFee | formattedTwoDecimal }}</td>
              <td>({{ partner.assetsDepositCount }}) $ {{ partner.assetsDeposit | formattedTwoDecimal }}</td>
              <td>({{ partner.assetsWithdrawalCount }}) $ {{ partner.assetsWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.assetsTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td>Debit Card</td>
              <td>({{ partner.debitFeeCount }}) $ {{ partner.debitFee | formattedTwoDecimal }}</td>
              <td></td>
              <td>({{ partner.debitWithdrawalCount }}) $ {{ partner.debitWithdrawal | formattedTwoDecimal }}</td>
              <td>$ {{ partner.debitTotal | formattedTwoDecimal }}</td>
            </tr>
            <tr class="detail-row">
              <td></td>
              <td></td>
              <td></td>
              <td><b>Total Commission</b></td>
              <td><b>$ {{ partner.total | formattedTwoDecimal }}</b></td>
            </tr>          
        </table>
      </div>
    </template>   
  </div>
    </section>
  </div>
</template>

<script>
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import { decimal, required } from 'vuelidate/lib/validators'
  import BasicForm from '../../components/ui/forms/Form'

  export default {
    name: 'Partners',

    components: {
      DateTimePicker,
      BasicForm,
    },

    data: () => ({
      results: null,
      form: {
        fromDate: null,
        toDate: null,
        provide: null,
      },
      activeInfo: false,
      toDateError: false,
      isRun: false,
    }),

    computed: {
      validDate () {
        return moment(this.form.fromDate) <= moment(this.form.toDate)
      },
      
      partners() {
        const data = this.results || []
        return data.sort((a, b) => {
          if (a.total > b.total) return -1
          if (a.total < b.total) return 1
          return 0
        })
      },

      statusColorClasses () {
        return {
          New: 'c1',
          Review: 'c2',
          Pending: 'c2',
          Restricted: 'c2',
          'In Process': 'c2',
          Denied: 'c4',
          Closed: 'c4',
          Archived: 'c3',
          Complete: 'c3',
          Active: 'c1'
        }
      },

      date2 () {
        let today = new Date()
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        return yesterdayDate
      },
    },

    async created () {
    },

    methods: {
      async refreshPartners () {
        if(this.validDate) {
          const loader = this.$loading.show()
          this.loading=true;

          const payload = {
            fromDate: this.form.fromDate,
            toDate: this.form.toDate,
          }
          
          const partners = (await api.action("Partners", "new", "GetCommissions", payload)).action
          this.results = partners.partners
          this.isRun = true
          this.loading=false;
          loader.hide()
        }
      },

      async run() {
        const loader = this.$loading.show()
        const payload = {
          fromDate: this.form.fromDate,
          toDate: this.form.toDate,
        }
        try {
          const partners = (await api.action("Partners", "new", "RunCommissions", payload)).action
          this.reset()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      reset() {
        this.results = null
        $('.fromDate').val('')
        $('.toDate').val('')
        this.form.fromDate = null
        this.form.toDate = null
        this.isRun = false
      },
    },

    watch: {
      
    },

    validations: {
      form: {
        fromDate: { required },
        toDate: { required }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .dates {
    justify-content: space-between;
    margin-bottom: 20px; 
  }
  .buttons {
    justify-content: space-between;
  }

  .accordion .cof-customer-box {
    position: relative;
    height: 51px;
    overflow: hidden;
    background: #f8f9fb;
    border-radius: 16px;
  }

  .accordion .cof-box-active {
    height: auto;
  }

  .detail-row td{
    background: #e7e8ea !important;
  }
</style>