<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers">&nbsp;</h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>
    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section  class="content-tlist">
        <table class="content-table">
          <tr>
            <th v-for="(item, i) in header">{{ item }}</th>
            <th>Options</th>
          </tr>
          <tr v-for="transaction in transactions" :key="transaction.id">
            <td v-for="(item, i) in transaction" :key="i">
              <template v-if="i == 'status'">
                <form-field-select v-if="isEdit(transaction.id)" v-model="itemstatus" label="" :options="statusOptions" required />
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else-if="i == 'currencyName'">
                <template v-if="isEdit(transaction.id)">
                  <form-field-select v-if="transaction.currencyType == 'Funds'" v-model="itemcurrencyName" label="" :options="fundsCurrencyOptions" required />
                  <form-field-select v-else v-model="itemcurrencyName" label="" :options="assetsCurrencyOptions" required />
                </template>
                <template v-else>
                  {{ item }}
                </template>
              </template>
              <template v-else>{{ item }}</template>
            </td>
            <td><span>Options</span>
              <template v-if="isEdit(transaction.id)">
                <button @click="saveUpdate(transaction)" class="action-button save">Save</button>
                <button @click="cancelEdit(transaction.id)" class="action-button cancel">Cancel</button>
              </template>
              <template v-else-if="!editStatus">
                <button @click="edit(transaction)" class="action-button update">Edit</button>
              </template>
            </td>
          </tr>
        </table>
      </section>
    <section class="pagination-section">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>

    <transactions-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import TransactionsFilters from './filters/TransactionsFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'

  export default {
    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      TransactionsFilters,
      AppliedFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,
      activeInfo: false,
      page: 1,
      perPage: 25,
      pages: [],
      totalNumber: 0,
      editing: [],
      itemstatus: null,
      itemcurrencyName: null,
      editStatus: false
    }),

    computed: {
      appliedFilters () {
        const filterNames = {
          profileId: 'Profile ID',
          name: 'Name',
          type: 'Profile Type',
          status: 'Status'
        }

        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },

      transactions () {
        let transactions = this.$store.state.transactions.data || []
        this.totalNumber = this.$store.state.transactions.count || 0
        return transactions
      },

      header () {
        let header = []
        if(this.transactions.length > 0) {
          header = Object.keys(this.transactions[0])
        }
        return header
      },

      statusColorClasses () {
        return {
          Active: 'c1',
          Restricted: 'c2',
          Complete: 'c2',
          Activation: 'c2',
          Suspended: 'c2',
          Dormant: 'c3',
          Closed: 'c1'
        }
      },


      fundsCurrencyOptions() {
        let options = []
        let currencies = window.currencies || []
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Funds') {
            options.push({
              id: currency.currencyNickname,
              text: currency.currencyNickname,
            })
          }
        })
        return options
      },

      assetsCurrencyOptions() {
        let options = []
        let currencies = window.currencies || []
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Assets') {
            options.push({
              id: currency.currencyNickname,
              text: currency.currencyNickname,
            })
          }
        })
        return options
      },

      statusOptions () {
        let options = [
          {
            id: 'Review',
            text: 'Review',
          },
          {
            id: 'Pending',
            text: 'Pending',
          },
          {
            id: 'On Hold',
            text: 'On Hold',
          },
          {
            id: 'Complete',
            text: 'Complete',
          },
          {
            id: 'Cancelled',
            text: 'Cancelled',
          },
          {
            id: 'Declined',
            text: 'Declined',
          },
          {
            id: 'Refund',
            text: 'Refund',
          },
          {
            id: 'Reversed',
            text: 'Reversed',
          },
          {
            id: 'Error',
            text: 'Error',
          },
        ]
        return options
      },
    },

    async created () {
      await this.refreshTransactions();
    },

    methods: {
      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(transaction) {
        this.editStatus = true
        this.itemstatus = transaction.status
        this.itemcurrencyName = transaction.currencyName
        this.editing.push(transaction.id)
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
        this.editStatus = false
      },

      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      getFilterValue (key, value) {
        if (key === 'status') return window.customerProfileStatuses[value]

        return value
      },

      clearFilters () {
        this.filters = null
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      async refreshTransactions () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {

        } else {
          if (this.filters.fromDate)
            params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
          if (this.filters.toDate)
            params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
          if (this.filters.depositType) {
            params.transactionType = this.filters.depositType
          }
          if (this.filters.entryType) {
            params.entryType = this.filters.entryType
          }
          if (this.filters.profileId) {
            params.profileId = this.filters.profileId
          }
          if (this.filters.transactionId) {
            params.transactionId = this.filters.transactionId
          }
          if (this.filters.accountId) {
            params.accountId = this.filters.accountId
          }
          if (this.filters.amount) {
            params.amount = this.filters.amount
          }
          if (this.filters.status) {
            params.status = this.filters.status
          }
          if (this.filters.currencyType) {
            params.currencyType = this.filters.currencyType
          }
          if (this.filters.currencyName) {
            params.currencyName = this.filters.currencyName
          }
        }

        await this.$store.dispatch('transactions/load', { order: ['id:DESC'], params, limit: this.perPage, start: (this.page-1) * this.perPage, skip: ['notes', 'regId', 'regName', 'profileNumber', 'walletAddress', 'currencySymbol', 'QPaymentReference', 'toAccountName'] })
        this.loading=false;
        loader.hide()
      },

      setPages () {
        let numberOfPages = 0;
        numberOfPages = Math.ceil(this.totalNumber / this.perPage);

        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      async paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },

      async saveUpdate (transaction) {
        try {
          const payload = {
            id: transaction.id,
            payload: {
              status: this.itemstatus,
              currencyName: this.itemcurrencyName,
            }
          }

          await this.$store.dispatch('transactions/update', payload)
          this.refreshTransactions()
          this.$toast.success('Transaction successfully updated.')
          this.cancelEdit(transaction.id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

    },

    watch: {
      transactions () {
        this.setPages();
      },

      appliedFilters () {
        this.refreshTransactions();
      },

      page() {
        this.refreshTransactions();
      },

      perPage() {
        if(this.page == 1){
          this.refreshTransactions();
        }else{
          this.page = 1
        }
      }
    },
  }
</script>
<style scoped>
  th {
    padding: 5px;
  }

  .content-tlist {
    overflow: scroll;
    max-width: 1500px;
    max-height: 100vh;
  }

  textarea {
    width: 100%;
  }
</style>
