<template>
  <div>
    <template>
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>

      <div class="content-filters">
        <basic-form>
          <div class="cof-row">
              <mnb-input v-model="email" label="Email" />
              <mnb-input v-model="phone" label="Phone" />
              <mnb-input v-model="legal_name" label="Legal Name" />
              <mnb-input v-model="dba_name" label="DBA Name" />
              <mnb-input v-model="sin" label="SSN" />
              <mnb-input v-model="tax_id" label="Tax ID" />
              <div class="text-center">
                <label>&nbsp;</label>
                <button class="action-button save" @click="doCheck()">Check</button>
              </div>
          </div>
        </basic-form>
      </div>
      
    <section  class="content-tlist">
      <table class="content-table">            
        <tr>                
          <th style="width: 20%">Profile ID</th>
          <th style="width: 20%">Registration ID</th>
        </tr>
        <tr v-for="(item, i) in registrations" :key="i">
          <template>
            <td><a :href="'/system-customers/registrations/'+item.id">{{ item.profileNumber || item.regId }}</a></td>
            <td><a :href="'/system-customers/registrations/'+item.id">{{ item.regId }}</a></td>
          </template>
        </tr>
      </table>
    </section>
    </template>
  </div>
</template>

<script>
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MnbUploadInput from '../../components/ui/forms/UploadInput'
import MnbTextarea from '../../components/ui/forms/Textarea'
import mixinAutoResize from "../../components/ui/forms/autoresize.js"
import inputMixin from "../../components/ui/forms/input-mixin.js"
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  mixins: [mixinAutoResize],

  components: {
    MnbInput,
    BasicForm,
    MnbSelect,
    MnbUploadInput,
    MnbTextarea,
  },

  data () {
    return {
      email: "",
      phone: "",
      legal_name: "",
      dba_name: "",
      sin: "",
      tax_id: "",
      registrations: [],
    }
  },

  computed: {
  },

  watch: {
  },

  async created () {
  },

  methods: {
    async doCheck() {
      const loader = this.$loading.show()
      try {
        this.registrations = (await api.request("POST", "/check-duplicates", {
          email: this.email,
          phone: this.phone,
          mobile: this.phone,
          legal_name: this.legal_name,
          dba_name: this.dba_name,
          tax_id: this.tax_id,
          sin: this.sin,
        })).duplicates || [];
      } catch(e) { }
      loader.hide()
    },

  },

  validations () {
    let rules = {}
    return rules
  }
}
</script>

<style scoped>
  .split {
    width: 20%;
  }
  .flex {
    align-items: center;
  }
  .content-tlist {
    border: none;
  }
</style>
