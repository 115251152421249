<template>
<div class="checklist-steps">
  <div class="step-form">
    <div class="step-new-head">
      <h3 style="color: #000;">{{ registration.accounts.length>1?'Account Boarding':'Profile Boarding' }}</h3>
      <template v-if="registration.status == 'New'">
        <div class="step-nav">
          <button class="main" v-on:click.prevent="startBoarding">Start Boarding</button>
        </div>
      </template>
      <template v-else>
        <div v-if="featureBoardingComplianceStatus" class="step-nav">
          <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
          <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
          <a href="" class="next" :class="{disabled: !canNext}" v-if="step<5" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
          <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==5" v-on:click.prevent="finish">Finish</a>
        </div>
        <div v-else class="step-nav">
          <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
          <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
          <a href="" class="next" :class="{disabled: !canNext}" v-if="step<5" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
          <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==5" v-on:click.prevent="finish">Finish</a>
        </div>
      </template>
    </div>
    <template v-if="registration.status != 'New'">
      <div class="step-new-split">
        <ul v-if="featureBoardingComplianceStatus" class="step-tabs st7">
          <li :class="{past: isDone[1], active: step==1}" v-on:click="isDone[1] && (step=1)">
            Documents
          </li>
          <li :class="{past: isDone[2], active: step==2}" v-on:click="isDone[2] && (step=2)">
            Settings
          </li>
          <li :class="{past: isDone[3], active: step==3}" v-on:click="isDone[3] && (step=3)">
            Plan
          </li>
          <li :class="{past: isDone[4], active: step==4}" v-on:click="isDone[4] && (step=4)">
            Boarding Compliance
          </li>
          <li :class="{past: isDone[5], active: step==5}" v-on:click="isDone[5] && (step=5)">
            Finalize
          </li>
        </ul>
        <ul v-else class="step-tabs st7">
          <li :class="{past: isDone[1], active: step==1}" v-on:click="isDone[1] && (step=1)">
            Documents
          </li>
          <li :class="{past: isDone[2], active: step==2}" v-on:click="isDone[2] && (step=2)">
            Settings
          </li>
          <li :class="{past: isDone[3], active: step==3}" v-on:click="isDone[3] && (step=3)">
            Plan
          </li>
          <li :class="{past: isDone[4], active: step==4}" v-on:click="isDone[4] && (step=4)">
            Finalize
          </li>
        </ul>
        <ul v-if="featureBoardingComplianceStatus" class="tab-content">
          <li v-if="step==0" class="active">
            <terms-and-conditions :registration="registration" />        
          </li>
          <li v-else-if="step==1" class="active">
            <documents :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==2" class="active">
            <profile-account-creation :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==3" class="active">
            <account-plan :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==4" class="active">
            <boarding-compliance :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==5" class="active">
            <compliance-scan :registration="registration" />
          </li>
        </ul>
        <ul v-else class="tab-content">
          <li v-if="step==0" class="active">
            <terms-and-conditions :registration="registration" />
          </li>
          <li v-else-if="step==1" class="active">
            <documents :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==2" class="active">
            <profile-account-creation :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==3" class="active">
            <account-plan :registration="registration" :step.sync="step"/>
          </li>
          <li v-else-if="step==4" class="active">
            <compliance-scan :registration="registration" />
          </li>
        </ul>
      </div>
      <div v-if="featureBoardingComplianceStatus" class="step-nav">
        <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
        <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
        <a href="" class="next" :class="{disabled: !canNext}" v-if="step<5" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
        <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==5" v-on:click.prevent="finish">Finish</a>
      </div>
      <div v-else class="step-nav">
        <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
        <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
        <a href="" class="next" :class="{disabled: !canNext}" v-if="step<4" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
        <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==4" v-on:click.prevent="finish">Finish</a>
      </div>
    </template>
  </div>
</div>
</template>
<script>
import Documents from './Documents'
import ComplianceScan from './ComplianceScan'
import ProfileSettings from './ProfileSettings'
import AccountPlan from './AccountPlan'
import ServiceProviders from './ServiceProviders'
import TermsAndConditions from './TermsAndConditions'
import AccountConfirmation from './AccountConfirmation'
import ProfileAccountCreation from './ProfileAccountCreation'
import BoardingCompliance from './BoardingCompliance.vue'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },
  data: ()=>({
    step: 1,
    featureBoardingComplianceStatus: window.systemFeatures['Boarding Compliance']
  }),
  computed: {
    isDone() {
      if(this.featureBoardingComplianceStatus)
        return [
          !!this.registration?.terms,
          !!this.$store.getters['registrations/get'](this.registration.id).documentsChecklist,
          !!this.registration?.accounts[this.registration.accounts.length-1]?.profileSettings,
          !!this.registration?.accounts[this.registration.accounts.length-1]?.accountPlan,
          this.registration?.boardingApproval,
          this.registration?.accounts[this.registration.accounts.length-1]?.complianceScan?.confirm === 'Pass',
        ];
      else
        return [
        !!this.registration?.terms,
        !!this.$store.getters['registrations/get'](this.registration.id).documentsChecklist,
        !!this.registration?.accounts[this.registration.accounts.length-1]?.profileSettings,
        !!this.registration?.accounts[this.registration.accounts.length-1]?.accountPlan,
        this.registration?.accounts[this.registration.accounts.length-1]?.complianceScan?.confirm === 'Pass',
    ];
    },
    canNext() {
      return this.isDone[this.step];
    },
  },
  methods: {
    prevStep() {
      this.step--;
    },
    nextStep() {
      if (!this.canNext)
        return;
      this.step++;
    },
    finish() {
      // TODO
    },
    cancel() {
      history.go(-1);
    },

    async startBoarding() {
      const loader = this.$loading.show()
      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: { status: 'In Process' }
      })
      this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes: 'Profile boarding started',
        createdAt: 'CURRENT_TIMESTAMP'
      })
      await this.$store.dispatch('registrations/get', this.registration.id)
      loader.hide()
    }
  },
  components: { 
    Documents,
    ComplianceScan,
    ProfileSettings,
    ServiceProviders,
    TermsAndConditions,
    AccountConfirmation,
    ProfileAccountCreation,
    BoardingCompliance,
    AccountPlan,
  }
};
</script>
<style lang="scss" scoped>
  .tab-content {
    .content-table {
      min-width: unset;
    }
  }
</style>