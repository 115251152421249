<template>
  <div>
    <section class="" style="margin-left: 30px;">
      <div class="flex-between">
        <select v-model="statsType">
          <option></option>
          <option value="profile">Profile Stats</option>
          <option value="account">Account Stats</option>
        </select>
        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>
    
    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section  class="content-tlist">
      <table v-if="statsType == 'profile'" class="content-table">
        <tr>
          <th>Partner</th>
          <th>Submitted</th>
          <th>Denied</th>
          <th>New</th>
          <th>Approved</th>
          <th>Activation</th>
          <th>Active</th>
          <th>Review</th>
          <th>Restricted</th>
          <th>Hold</th>
          <th>Dormant</th>
          <th>Closed</th>
        </tr>
        <tr v-if="partner">
          <td><span>Partner</span>
            <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
          </td>
          <td><span>Submitted</span>
            {{ partner.stats.submitted }} (100%)
          </td>
          <td><span>Denied</span>
            {{ partner.stats.denied }} ({{ partner.stats.deniedPercent }}%)
          </td>
          <td><span>New</span>
            {{ partner.stats.new }} ({{ partner.stats.newPercent }}%)
          </td>
          <td><span>Approved</span>
            {{ partner.stats.approved }} ({{ partner.stats.approvedPercent }}%)
          </td>
          <td><span>activation</span>
            {{ partner.stats.activation }}
          </td>
          <td><span>active</span>
            {{ partner.stats.active }}
          </td>
          <td><span>review</span>
            {{ partner.stats.review }}
          </td>
          <td><span>restricted</span>
            {{ partner.stats.restricted }}
          </td>
          <td><span>hold</span>
            {{ partner.stats.hold }}
          </td>
          <td><span>dormant</span>
            {{ partner.stats.dormant }}
          </td>
          <td><span>closed</span>
            {{ partner.stats.closed }}
          </td>
        </tr>
      </table>
      <table v-if="statsType == 'account'" class="content-table">
        <tr>
          <th>Partner</th>
          <th>Accounts</th>
          <th>New</th>
          <th>Pending</th>
          <th>Open</th>
          <th>Restricted</th>
          <th>Hold</th>
          <th>Dormant</th>
          <th>Closed</th>
        </tr>
        <tr v-if="partner">
          <td><span>Partner</span>
            <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
          </td>
          <td><span>Accounts</span>
            {{ partner.accountStats.submitted }} (100%)
          </td>
          <td><span>New</span>
            {{ partner.accountStats.new }} ({{ partner.accountStats.newPercent }}%)
          </td>
          <td><span>Pending</span>
            {{ partner.accountStats.pending }} ({{ partner.accountStats.pendingPercent }}%)
          </td>
          <td><span>Open</span>
            {{ partner.accountStats.open }} ({{ partner.accountStats.openPercent }}%)
          </td>
          <td><span>restricted</span>
            {{ partner.accountStats.restricted }} ({{ partner.accountStats.restrictedPercent }}%)
          </td>
          <td><span>hold</span>
            {{ partner.accountStats.hold }} ({{ partner.accountStats.holdPercent }}%)
          </td>
          <td><span>dormant</span>
            {{ partner.accountStats.dormant }} ({{ partner.accountStats.dormantPercent }}%)
          </td>
          <td><span>closed</span>
            {{ partner.accountStats.closed }} ({{ partner.accountStats.closedPercent }}%)
          </td>
        </tr>
      </table>
    </section>
    <partner-stats-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import PartnerStatsFilters from './filters/PartnerStatsFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'

  export default {
    name: 'Partners',

    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      AppliedFilters,
      PartnerStatsFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,

      statsType: "profile",
      partner: null
    }),

    computed: {
      currentUser () {
        return window.appData.currentUser
      },

      appliedFilters () {
        const filterNames = {
          partnerNumber: 'Partner Number',
          name: 'Name',
          type: 'Profile Type',
          status: 'Status'
        }
        
        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.filters[key]}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },
    },

    async created () {
      await this.refreshPartners()
    },

    methods: {
      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      clearFilters () {
        this.filters = null
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      search () {
        this.filters = { ...this.form }
      },

      resetItem() {
        this.filters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async refreshPartners () {
        const loader = this.$loading.show()
        this.loading=true;

        let payload = {}
        if(this.filters) {
          if(this.filters.fromDate) {
            payload.fromDate = this.filters.fromDate
          }
          if(this.filters.toDate) {
            payload.toDate = this.filters.toDate
          }
        }
        
        const partners = (await api.action("Partners", this.currentUser.parId, "GetStat", payload)).action
        this.partner = partners.partner
        this.loading=false;
        loader.hide()
      }
    },

    watch: {
      appliedFilters () {
        this.refreshPartners();
      },
    },
  }
</script>
