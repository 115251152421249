<template>
<select v-model="actualValue" v-on:input="$emit('input', $event.target.value)">
  <option v-for="opt in options" :value="opt.value" :selected="opt.value==value">{{ opt.label }}</option>
</select>
</template>

<script>
export default {
  props: ["value", "options"],
  data: ()=>({
    actualValue: null,
  }),
  watch: {
    value() {
      this.actualValue=this.value;
    },
    actualValue() {
      this.$emit("input", this.actualValue);
    },
  },
}
</script>
