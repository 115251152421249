<template>
  <section class="form-box reg-form">
    <div v-show="false">{{ stepDummy }}</div>
    <h1 class="logo">
      <img :src="base + system.logo" :alt="`${system.logo} Logo`" />
    </h1>
    <h2><span>{{ system.name }}</span></h2>
    <h3 style="margin-bottom: 10px;">{{ $t('register.title') }}</h3>

    <template v-if="system.registration_form">
      <h4 class="app-page-text">{{ system.app_page_text }}</h4>

      <template v-if="!submitted">
        <div class="content-form" v-show="step==0 && !hasAccount">
          <h4>{{ $t('register.subtitle_q') }}</span></h4>
          <div class="form-row" :class="{'form-split': hasAccount===null, 'form-fullw': hasAccount!==null}">
            <form-field-select label="Language" :required="true" :options="{en: 'English', es: 'Español'}" v-model="$i18n.locale" no-null v-if="hasAccount===null" />
            <form-field-select :label="$t('register.have_acc_label')" :required="true" :options="{Yes: $t('register.have_acc_yes'), No: $t('register.have_acc_no')}" v-model="hasAccount" />
          </div>
        </div>
        <div class="content-form" v-show="hasAccount=='Yes' && step==0">
          <p>{{ $t('register.yes_description') }}</p>
          <div class="form-row form-center">
            <div class="">
            <button><a href="/login" class="login-link">{{ $t('register.login_button') }}</a></button>
            </div>
          </div>
        </div>
        <div class="content-form" v-show="hasAccount=='No' && step==0">
          <h4>{{ $t('register.acc_info') }} <span>*</span></h4>
          <div class="form-row form-split">
            <form-field-select :label="$t('register.acc_type_selection')" :required="true" :options="businessPersonalOptions" v-model="accountType" />
            <form-field-select :label="$t('register.acc_curr_selection')" :required="true" :options="{USD: 'USD'}" v-model="accountCurrency" />
          </div>
        </div>
        <div class="content-stepnav" v-show="step==0 && hasAccount!='Yes'">
          <button class="next" :disabled="!accountType || !accountCurrency" v-on:click.prevent="nextStep()">Next</button>
        </div>
        
        <template v-if="accountCurrency && accountType && form && step>=1">
          <div class="content-form">
            <personal-form
              v-if="isPersonal"
              v-model="form"
              :v="$v.form"
              :step="step"  />

            <business-form
              v-if="!isPersonal"
              v-model="form"
              :v="$v.form"
              @add-contact="addContact()"
              @remove-contact="removeContact()"
              :step="step" />

            <template v-if="isLastStep">
            <div class="form-row form-fullw">
              <div class="txtc">
                <p>{{ $t('register.agree_terms') }}</p>
              </div>
            </div>

            <div class="form-row form-fullw">
              <div class="autoh">
                <ul class="cmarks">
                  <li>
                    <label class="check" :class="{ error: errors.terms }">
                      <input v-model="terms" type="checkbox" />
                      <span></span>
                      <a @click.prevent="termsModal = true">{{ $t('register.terms') }}</a>
                    </label>
                  </li>
                  <li>
                    <label class="check" :class="{ error: errors.privacy_policy }">
                      <input v-model="privacy_policy" type="checkbox" />
                      <span></span>
                      <a @click.prevent="privacyModal = true">{{ $t('register.privacy') }}</a>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-row form-center">
              <div class="">
                <button :disabled="saving" @click="submit()">{{ $t('register.submit_reg') }}</button>
              </div>
            </div>
            </template>
          </div>
          <div class="content-stepnav">
            <button class="prev" v-on:click.prevent="step--">Back</button>
            <button class="next" :class="{disabled: checkInvalid()}" @click="nextStep()" v-if="!isLastStep">Next</button>
          </div>
        </template>
      </template>

      <div v-else class="submitted-note">
        <div v-if="success" class="success">
          Registration Successfully Submitted!
        </div>
        <div v-else class="denied">
          <span>{{ $t('register.unable_1') }} <b>{{ system.support_email }}</b> {{ $t('register.unable_2') }} <b>{{ system.support_phone }}</b> {{ $t('register.unable_3') }}.</span>
          <br><br>{{ $t('register.unable_4') }}, 
          <br><br><b>{{ system.dba_name }}</b>
        </div>
      </div>
    </template>

    <h4 v-else class="app-page-text">{{ system.disabled_form_text }}</h4>

    <popup :open.sync="termsModal">
      <div class="privacy-popup scrollable" v-on:scroll.passive="handleScroll">
        <terms-popup></terms-popup>
      </div>
      <div class="buttons" v-if="submitClicked">
        <button :class="reachTerms? 'main': 'second'" @click="acceptTerms()">{{ $t('register.accept_button') }}</button>
        <button class="second" @click="termsModal = false">{{ $t('register.cancel_button') }}</button>
        <template v-if="showTermsMessage">{{ $t('register.must_complete_terms') }}</template>
      </div>
    </popup>
    <popup :open.sync="privacyModal" >
      <div class="privacy-popup scrollable">
        <privacy-popup></privacy-popup>
      </div>
    </popup>
  </section>
</template>

<script>
import helperMixin from './components/common/helper-mixin'
import { mustBeTrue, dashNumbers, alphaNum, alphaVersion, phone } from './lib/validators'
import { required, numeric, email, url, requiredIf, between, minLength } from 'vuelidate/lib/validators'

import UAParser from 'ua-parser-js'
import Popup from './monbi/popup'
import BusinessForm from './RegisterForm2Business'
import PersonalForm from './RegisterForm2Personal'
import PrivacyPopup from './PrivacyPopup.vue'
import TermsPopup from './TermsPopup.vue'

const contact = {
  type: null,
  country: null,
  city: null,
  state: null,
  zip: null,
  address: null,
  address2: null,
  sex: null,
  dob: null,
  firstName: null,
  lastName: null,
  phone: null,
  mobile: null,
  email: null,
  sin: null,
  id_types: [
    {
      id_type: null,
      id_number: null,
      document: null
    }
  ],
  id_number: null,
  ownership_percentage: null,
  doc_address_confirmation: null
}

const forms = {
  Personal: {
    sex: null,
    dob: null,
    firstName: null,
    lastName: null,
    phone: null,
    mobile: null,
    email: null,
    sin: null,
    id_types: [
      {
        id_type: null,
        id_number: null,
        document: null
      }
    ],
    id_number: null,
    country: null,
    city: null,
    address: null,
    address2: null,
    state: null,
    zip: null,
    submit_later: false,
    doc_address_confirmation: null
  },
  Business: {
    legal_name: null,
    dba_name: null,
    incorporation_date: null,
    description: null,
    tax_id: null,
    formation_country: null,
    address: null,
    address2: null,
    zip: null,
    city: null,
    state: null,
    phone: null,
    email: null,
    website: '',
    contacts: [{ ...contact }],
    questions: {
      purpose_of_account: null,
      association_with_other_accounts: null,
      source_of_assets_and_income: null,
      intended_use_of_account: null,
      anticipated_types_of_assets: null,
      anticipated_monthly_cash_volume: null,
      anticipated_trading_patterns: null,
      anticipated_monthly_transactions_incoming: null,
      anticipated_monthly_transactions_outgoing: null,
    },
    doc_incorporation: null,
    doc_address_confirmation: null
  }
}

export default {
  mixins: [helperMixin],

  components: {
    Popup,
    BusinessForm,
    PersonalForm,
    PrivacyPopup,
    TermsPopup
  },

  data: ()=>({
    step: 0,
    stepDummy: 0,
    form: null,

    hasAccount: null,
    accountType: null,
    accountCurrency: 'USD',

    terms: false,
    privacy_policy: false,

    base: '/files/system/',

    submitClicked: false,

    termsModal: false,
    privacyModal: false,
    submitted: false,
    success: false,
    saving: false,
    reachTerms: false,
    showTermsMessage: false
  }),

  computed: {
    errors () {
      const keys = ['terms', 'privacy_policy']
      return keys.reduce((acc, key) => {
        acc[key] = this.$v[key].$dirty && this.$v[key].$invalid && !this.$v[key].$pending
        return acc
      }, {})
    },

    isPersonal () {
      return this.accountType == 'Personal'
    },
    
    isLastStep () {
      return (this.isPersonal && this.step===4) ||
             (!this.isPersonal && this.step===6);
    },
    
    system () {
      return this.$store.getters['system/get'](1)
    },

    ownershipError () {
      if (this.accountType !== 'Business') return false
      const contacts = this.form.contacts.filter(contact => contact.type === 'Beneficial Owner')
      return contacts.length ? contacts.reduce((acc, contact) => acc + parseInt(contact.ownership_percentage), 0) !== 100 : false
    },

    noOwner () {
      if (this.accountType !== 'Business') return false
      return this.form.contacts.filter(contact => contact.type === 'Beneficial Owner').length==0;
    },

    businessPersonalOptions () {
      let options = []
      if(this.system.business_registrations) {
        options.push({ 
          id: "Business",
          text: this.$t("register.acc_type_bussines")
        })
      } 
      if (this.system.personal_registrations) {
        options.push({ 
          id: "Personal",
          text: this.$t("register.acc_type_personal")
        })
      }

      return options
    }
  },

  watch: {
    termsModal (value) {
      if (!value) this.submitClicked = false
    },

    accountType (accountType) {
      if (accountType) {
        this.form = JSON.parse(JSON.stringify(forms[accountType]))
      }
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
  },

  methods: {
    checkInvalid (component) {
      component=component || this;
      var invalid=false;
      for(var i=0;i<component.$children.length;i++) {
        var child=component.$children[i];
        if (child._props && child._props.validator) {
          if (child._props.validator.$invalid)
            invalid=true;
        } else {
          if (this.checkInvalid(child))
            invalid=true;
        }
      }
      return invalid;
    },
  
    acceptTerms () {
      if (this.reachTerms) {
        this.terms = true
        if (this.submitClicked) {
          this.save()
          this.submitClicked = false
        }
        this.termsModal = false
      } else {
        this.showTermsMessage = true
      }
    },
    
    nextStep() {
      if (this.checkInvalid()) {
        this.$toast.error('You need to fill all required fields. We marked them with red color so that you know what fields you have missed.')
        this.$v.$touch();
      } else {
        this.$v.$reset();
        this.step++;
        setTimeout(()=>this.stepDummy++, 30);
      }
    },

    async submit () { 
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$toast.error('You need to fill all required fields. We marked them with red color so that you know what fields you have missed.')
        return
      }
      if (this.ownershipError) {
        this.$toast.error('Ownership Percentage of all Beneficial Owners needs to be a total of 100%')
        return
      }
      if (this.noOwner) {
        this.$toast.error('At least one Beneficial Owner must be selected')
        return
      }

      this.submitClicked = true
      this.termsModal = true
    },

    async save (status) {
      if (this.saving) return
      this.termsModal = false
      this.terms = true
      this.saving = true
      const parser = (new UAParser()).getResult()
      const payload = {
        uuid: 'CUSTOM_REGISTRATION_ID',
        type: this.accountType,
        currency: this.accountCurrency,
        data: this.form,
        createdAt: 'CURRENT_TIMESTAMP',
        browser: `${parser.browser.name} ${parser.browser.major}`,
        browser_lang: window.navigator.userLanguage || window.navigator.language,
        device: parser.device.model || parser.device.type ? `${parser.device.model} ${parser.device.type}` : null,
        os: `${parser.os.name} ${parser.os.version}`
      }
      const loader = this.$loading.show()
      try {
        const response = await api.request('POST', '/register', { obj: payload })

        await this.uploadDocuments(response.id, response.token)

        this.submitted = true
        if (response.status === 'New') {
          this.success = true
        }
      } catch (e) {
        this.$toast.error('There was an unknown error. Please try again later or contact the support.')
      }finally {
        loader.hide()
      }
    },

    uploadDocuments (id, token) {
      const name = this.isPersonal ? `${this.form.firstName}-${this.form.lastName}` : this.form.legal_name
      if (this.isPersonal) {
        const done = []
        this.form.id_types.forEach(type => {
          done.push(type.id_type)
          this.documentPromise(id, token, `${name}-${type.id_type}-${type.id_number}`, 'government_issued_id', type.id_type, 'Government Issued ID', type.id_type, type.id_number, type.document)
        })
        // Create Drivers License and Passport documents even if they are not selected.
        const idTypes = ['Drivers License', 'Passport'].filter(type => !done.includes(type))
        idTypes.forEach(type => this.documentPromise(id, token, `${name}-${type}`, 'government_issued_id', type, 'Government Issued ID', type, null, null))
        this.documentPromise(id, token, `${name}-Address Confirm`, 'address', null, 'Address Confirmation', 'Address', 'Address', this.form.doc_address_confirmation)
      } else {
        this.documentPromise(id, token, `${name}-Business Inc Documents`, 'business_incorporation', null, 'Business Incorporation', 'Business Incorporation', this.form.tax_id, this.form.doc_incorporation)
        this.documentPromise(id, token, `${name}-business Address Confirm`, 'business_address', null, 'Business Address Confirmation', 'Address', 'Address', this.form.doc_address_confirmation)
        this.form.contacts.forEach((contact, index) => {
          const prefix = `Contact ${index + 1} `
          const contactsName = `${contact.firstName} ${contact.lastName}`

          const done = []
          contact.id_types.forEach(type => {
            done.push(type.id_type)
            this.documentPromise(id, token, `${contactsName}-${type.id_type}-${type.id_number}`, 'contact_government_issued_id', index + 1, prefix + 'Government Issued ID', type.id_type, type.id_number, type.document)
          })
          // Create Drivers License and Passport documents even if they are not selected.
          const idTypes = ['Drivers License', 'Passport'].filter(type => !done.includes(type))
          idTypes.forEach(type => this.documentPromise(id, token, `${contactsName}-${type}`, 'contact_government_issued_id', index + 1, prefix + 'Government Issued ID', type, null, null))

          this.documentPromise(id, token, `${contactsName}-Address Confirm`, 'contact_address', index + 1, prefix + 'Address Confirmation', 'Address', 'Address', contact.doc_address_confirmation)
        })
      }
    },

    async documentPromise (regId, token, filename, docFor, docAdditional, description, type, number, file) {
      var data={
        regId,
        type,
        docFor,
        docAdditional,
        description,
        number,
        createdAt: 'CURRENT_TIMESTAMP',
        deletable: false
      };
      await api.registerUpload(file?this.changeFileName(file, filename):null, {id: regId, token, obj: data});
    },

    removeContact () {
      this.form.contacts.splice(this.form.contacts.length - 1, 1)
    },

    addContact () {
      this.form.contacts.push(JSON.parse(JSON.stringify(contact)))
    },

    handleScroll: function(e) {
      if ((e.target.scrollHeight-700) <= e.target.scrollTop) {
        this.reachTerms = true
      }
    }
  },

  validations () {
    const rules = {
      form: {},
      terms: { mustBeTrue },
      privacy_policy: { mustBeTrue },
    }

    if (this.isPersonal) {
      rules.form = {
        sex: { required },
        dob: { required },
        firstName: { required, alphaNum },
        lastName: { required, alphaNum },
        phone: { required, phone },
        mobile: { phone },
        email: { required, email },
        sin: { required, numeric, minLength: minLength(4) },
        country: { required,  },
        city: { required, alpha },
        address: { required, addressValidation },
        address2: { addressValidation },
        state: { required },
        zip: { required, alphaVersionNoSpace },
        id_types: {
          $each: {
            id_type: { required },
            id_number: { required, alphaNumNoSpace },
            document: { required: requiredIf(() => !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
          }
        }
      }
    } else {
      rules.form = {
        legal_name: { required, alphaNum },
        dba_name: { alphaNum },
        incorporation_date: { required },
        tax_id: { required, numeric },
        formation_country: { required },
        business_type: { required },
        address: { required, addressValidation },
        address2: { required, addressValidation },
        city: { required, alpha },
        state: { required, alphaNumNoSpace },
        phone: { required, dashNumbers, phone },
        description: { required },
        email: { required, email },
        website: { webSiteUrl },
        zip: { required, alphaVersionNoSpace },
        doc_incorporation: { required: requiredIf(() => !this.form || !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
        doc_address_confirmation: { required: requiredIf(() => !this.form || !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
        contacts: {
          $each: {
            type: { required },
            country: { required },
            city: { required, alpha },
            state: { required },
            zip: { required, alphaVersionNoSpace },
            address: { required, addressValidation },
            address2: { addressValidation },
            sex: { required },
            dob: { required },
            firstName: { required, alphaNum },
            lastName: { required, alphaNum },
            phone: { required, phone },
            mobile: { phone },
            email: { required, email },
            sin: { required, numeric, minLength: minLength(4) },
            id_types: {
              $each: {
                id_type: { required },
                id_number: { required, alphaNumNoSpace },
                document: { required: requiredIf(() => !this.form.submit_later), fileSizeValidator: fileSizeValidator(maxFileSize) },
              }
            },
            doc_address_confirmation: { required, fileSizeValidator: fileSizeValidator(maxFileSize) },
            ownership_percentage: { required: requiredIf(nested => nested.type === 'Beneficial Owner'), numeric, between: between(1, 100) },
          }
        },
        questions: {
          business_industry: { required },
        }
      }

    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .app-page-text {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .content-form .form-row div .check {
    position: unset;
    pointer-events: unset;
  }
  .buttons {
    margin-top: 20px;
  }
  
  .login-link {
    text-decoration: none;
    color: #fff;
  }

  .submitted-note {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #000;
    text-align: center;
    .success {
      font-size: 25px;
      color: #00B100;
    }
    .denied {
      text-align: left;
      font-size: 16px;
      span {
        display: block;
        font-size: 20px;
        color: #b10000;
      }

    }
  }
</style>
