<template>
  <div>
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>

      <div class="content-filters">
        <basic-form>
          <div class="cof-row">
            <mnb-input v-model="form.accountNumberPrefix" :maxlength="4" label="Account Number Prefix" :disabled="!updateInformations"/>
            <mnb-select v-model="dummy" label="Provider prefixes" :options="providerPrefixes"  />
            <mnb-input v-model="form.masterBusinessAccountPrefix" :maxlength="4" label="Master Business Account Prefix" :disabled="!updateInformations"/>
            <mnb-input v-model="form.personalAccountPrefix" :maxlength="2" label="Personal Account Prefix" :disabled="!updateInformations"/>
            <mnb-input v-model="form.businessAccountPrefix" :maxlength="2" label="Business Account Prefix" :disabled="!updateInformations"/>
            <mnb-select v-model="form.accountNumberLength" :options="accountNumberLengthOptions" label="Account Number Length" :disabled="!updateInformations"/>
          </div>

          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="updateInformations = true">Update</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="clear()">Clear</button>
            </div>
          </div>
        </basic-form>
      </div>

      <div class="content-filters">
        <basic-form>
          <h3>Session Management</h3>
          <div class="cof-row">
            <mnb-select v-model="formSession.sessionTimeout" :options="sessionLengthOptions" label="Session Timeout (Minutes)" :disabled="!updateInformationsSession"/>
          </div>

          <div class="cof-row">
            <div v-if="updateInformationsSession" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="saveSession()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="updateInformationsSession = true">Update</button>
            </div>
          </div>
        </basic-form>
      </div>

      <div class="content-filters">
      <basic-form>
        <h3>Activation Code Timeout</h3>
        <div class="cof-row">
          <mnb-select v-model="formSession.activationCodeTimeout" :options="activationLengthOptions" label="Activation Code Timeout (Hours)" :disabled="!updateInformationsActivation"/>
        </div>

        <div class="cof-row">
          <div v-if="updateInformationsActivation" class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="saveActivationCodeTimeout()">Submit</button>
          </div>
          <div v-else class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="updateInformationsActivation = true">Update</button>
          </div>
        </div>
      </basic-form>
    </div>
    <div class="content-filters">
      <basic-form>
        <h3>Monthly Limit (USD)</h3>
        <div class="cof-row">
          <mnb-input v-model="formSession.montlyLimitUsd" label="Transaction Monthly Limit (USD)" :disabled="!updateLimits"/>
        </div>
        <h3>Daily Limit (USD)</h3>
        <div class="cof-row">
          <mnb-input v-model="formSession.dailyLimitUsd" label="Transaction Daily Limit (USD)" :disabled="!updateLimits"/>
        </div>

        <div class="cof-row">
          <div v-if="updateLimits" class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="saveLimits()">Submit</button>
          </div>
          <div v-else class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="updateLimits = true">Update</button>
          </div>
        </div>
      </basic-form>
    </div>
    <div class="content-filters">
      <basic-form>
        <h3>Registration Countries</h3>
        <div class="cof-row">
          <div>
            <label>Formation Country</label>
            <multi-select-dropdown :options="countries" v-model="formSession.formationCountry" :isMulti="true" :disabled="!updateCountry" />
          </div>
          <div style="margin-left: 10px;">
            <label>Contacts Country</label>
            <multi-select-dropdown :options="countries" v-model="formSession.contactsCountry" :isMulti="true" :disabled="!updateCountry" />
          </div>
        </div>
        <div class="cof-row">
          <div v-if="updateCountry" class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="saveCountry()">Submit</button>
          </div>
          <div v-else class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="updateCountry = true">Update</button>
          </div>
        </div>
      </basic-form>
    </div>
  </div>
</template>

<script>
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbUploadInput from '../../components/ui/forms/UploadInput'
  import MultiSelectDropdown from '../../components/MultiselectDropdown'

  export default {
    name: 'account-number-management',

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      MultiSelectDropdown,
      MnbUploadInput
    },

    data () {
      return {
        form: {

        },
        formSession: {

        },
        dummy: null,

        updateInformations: false,
        updateInformationsSession: false,
        updateInformationsActivation: false,
        updateLimits: false,
        updateCountry: false,

        accountNumberLengthOptions: [
          {
            id: 6,
            text: '6'
          },          
          {
            id: 8,
            text: '8'
          },         
          {
            id: 10,
            text: '10'
          },
        ],

        sessionLengthOptions: [
          {
            id: 5,
            text: '5'
          },
          {
            id: 10,
            text: '10'
          },
          {
            id: 15,
            text: '15'
          },
          {
            id: 30,
            text: '30'
          },
          {
            id: 45,
            text: '45'
          },
          {
            id: 60,
            text: '60'
          },
        ],

        activationLengthOptions: [
          {
            id: 1,
            text: '1 Hour'
          },
          {
            id: 2,
            text: '2 Hours'
          },
          {
            id: 6,
            text: '6 Hours'
          },
          {
            id: 12,
            text: '12 Hours'
          },
          {
            id: 24,
            text: '24 Hours'
          }
        ],

        providerPrefixes: [],
      }
    },

    computed: {
      countries() {
        let countries = window.fullCountries
        return countries.map(a=>({label: `${a.id} - ${a.text}`, value: a.id}));
      },
    },

    watch: {

    },

    async created () {
      this.loadProviderIds();
      this.form = (await api.get('AccountNumberManagement', 1)).item
      this.formSession = (await api.get('System', 1)).item
    },

    methods: {
      async loadProviderIds() {
        var result = (await api.list("ProvidersList", {providerType: "Accounts", status: "Active"})).items;
        this.providerPrefixes=result.map(a=>({
          id: a.id,
          text: (a.id%100<10?"0":"")+(a.id%100)+" - "+a.provider_name,
        }));
      },
      async save () {
        try {
          await this.$store.dispatch('accountNumberManagement/update', { id: 1, payload: this.form })
          this.$toast.success('Account Number Management Information saved successfully.')
          this.updateInformations = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

      clear () {
        if(this.updateInformations) {
          Object.keys(this.form).forEach(key => this.form[key] = null)
        }
      },

      async saveSession () {
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.formSession })
          this.$toast.success('Session Timeout saved successfully.')
          this.updateInformationsSession = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

      async saveActivationCodeTimeout () {
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.formSession })
          this.$toast.success('Activation Code Timeout saved successfully.')
          this.updateInformationsActivation = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

      async saveLimits () {
        if (this.formSession.dailyLimitUsd*1 && this.formSession.montlyLimitUsd*1 && this.formSession.dailyLimitUsd>this.formSession.montlyLimitUsd) {
          return this.$toast.error('Monthly limit cannot be less than the daily limit.')
        }
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.formSession })
          this.$toast.success('Transaction Limits saved successfully.')
          this.updateLimits = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },

      async saveCountry () {
        try {
          await this.$store.dispatch('system/update', { id: 1, payload: this.formSession })
          this.$toast.success('Registration Countries saved successfully.')
          this.updateCountry = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      },
    }
  }
</script>
