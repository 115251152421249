<template>
  <div style="display: flex; justify-content: space-around;">
    <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
      <div class="popup-header">
        <h3>Bank Look Up</h3>
      </div>
      <template v-if="addModal">
        <div class="scrollable">
          <div class="content-form content-form-modern">
            <div class="form-row form-split">
              <div class="form-row form-split">
                <form-field-select v-model="form.account" label="Search Using" :validator="$v.form.account" :options="accountOptions" style="width: 50%;" required />
                <form-field-text v-if="form.account" v-model="form.number" :disabled="!form.account"  :label="`Enter the ${form.account == 'bank_routing_number' ? 'Routing Number' : 'SWIFT Code'}`" :validator="$v.form.number" required />
              </div>
              <div class="form-row form-split">
              </div>
            </div>
            <div v-if="form.account == 'bank_routing_number'" class="form-row form-split">
              <div class="form-row form-split">
                <form-field-select v-model="form.type" label="Service Type" :validator="$v.form.type" :options="typeOptions" required />
              </div>
            </div>
          </div>
          <div class="content-form bank-info content-form-modern">
            <p v-if="bankError">Error searching Banks. {{ bankErrorMsg }}</p>
            <p v-if="notFound">Bank not found. Please check information and try again.</p>
            <template v-if="bankInfo">
              <h3 class="subtitle">Bank Details</h3>
              <div class="form-row form-split">
                <label>
                  <b>Routing Number: </b>
                </label>
                <label>
                  {{ bankInfo.routingNumber }}
                </label>
              </div>
              <div class="form-row form-split">
                <label>
                  <b>Name: </b>
                </label>
                <label>
                  {{ bankInfo.name }}
                </label>
              </div>
              <div v-if="bankInfo.street" class="form-row form-split">
                <label>
                  <b>Address: </b>
                </label>
                <label>
                  {{ bankInfo.street }}
                </label>
              </div>
              <div v-if="bankInfo.city" class="form-row form-split">
                <label>
                  <b>City: </b>
                </label>
                <label>
                  {{ bankInfo.city }}
                </label>
              </div>
              <div v-if="bankInfo.state" class="form-row form-split">
                <label>
                  <b>State/Province: </b>
                </label>
                <label>
                  {{ bankInfo.state }}
                </label>
              </div>
              <div v-if="bankInfo.zip" class="form-row form-split">
                <label>
                  <b>Postal/Zip: </b>
                </label>
                <label>
                  {{ bankInfo.zip }}
                </label>
              </div>
              <div v-if="bankInfo.country" class="form-row form-split">
                <label>
                  <b>Country: </b>
                </label>
                <label>
                
                </label>
              </div>
              <div v-if="bankInfo.phone" class="form-row form-split">
                <label>
                  <b>Phone: </b>
                </label>
                <label>
                  {{ bankInfo.phone }}
                </label>
              </div>
            </template>
            <template v-if="bankSwiftInfo">
              <h3 class="subtitle">Bank Details</h3>
              <div class="form-row form-split">
                <label>
                  <b>Swift Code: </b>
                </label>
                <label>
                  {{ form.number }}
                </label>
              </div>
              <div class="form-row form-split">
                <label>
                  <b>Name: </b>
                </label>
                <label>
                  {{ bankSwiftAddress["name"] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['address']" class="form-row form-split">
                <label>
                  <b>Address: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['address'] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['city']" class="form-row form-split">
                <label>
                  <b>City: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['city'] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['state']" class="form-row form-split">
                <label>
                  <b>State/Province: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['state'] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['zip']" class="form-row form-split">
                <label>
                  <b>Postal/Zip: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['zip'] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['country']" class="form-row form-split">
                <label>
                  <b>Country: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['country'] }}
                </label>
              </div>
              <div v-if="bankSwiftAddress['phone']" class="form-row form-split">
                <label>
                  <b>Phone: </b>
                </label>
                <label>
                  {{ bankSwiftAddress['phone'] }}
                </label>
              </div>
            </template>
          </div>
          <div class="buttons">
            <button class="main" @click="bank()" :disabled="this.$v.form.$invalid">Submit</button>
            <button class="second" @click="open = false">Close</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import AchForm from './forms/ACH'
import AddressForm from './forms/Address'
import InternationalWireForm from './forms/InternationalWire'

export default {
  props: {
    modalBankType: {
      type: String,
      required: true
    }
  },

  components: {
    AchForm,
    AddressForm,
    InternationalWireForm
  },

  data () {
    return {
      form: {
        account: null,
        number: null,
        type: null,
      },

      currencyOptions: window.currencyOptions,

      open: true,
      addModal: true,
      bankError: false,
      notFound: false,
      bankInfo: null,
      bankErrorMsg: null,
      bankAddress: null,

      bankSwiftInfo: null,
      bankSwiftAddress: null,
    }
  },

  computed: {
    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    isDeposit () {
      return this.modalType === 'deposit'
    },

    accountOptions () {
      let accounts = [
        {
          id: 'bank_routing_number',
          text: 'Routing Number',
        },
        {
          id: 'bank_swift_code',
          text: 'SWIFT Code',
        }
      ]

      return accounts
    },

    typeOptions () {
      let accounts = [
        {
          id: 'wire',
          text: 'Wire',
        },
        {
          id: 'ach',
          text: 'ACH',
        }
      ]

      return accounts
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {
      this.form.number = null
      this.bankInfo = null
      this.bankSwiftInfo = null
    },

    'form.number' () {
      this.$v.form.number.$touch()
    },
  },

  methods: {
    async bank() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.bankInfo = null
      this.bankSwiftInfo = null
      const loader = this.$loading.show()
      if(this.form.account == 'bank_routing_number') {
        let payload;
        payload = {
          type: 'routingNumber',
          routingNumber: this.form.number,
          payType: this.form.type
        }
        const response = (await api.action("Transactions", "new", "Bank", payload))
        if(response.action.error){
          this.bankError = true
          this.bankErrorMsg = response.action.errors
          this.notFound = false
          this.bankInfo = null
          this.bankAddress = null
          loader.hide()
        } else if(response.action.notFound){
          this.notFound = true
          this.bankError = false
          this.bankInfo = null
          this.bankAddress = null
          loader.hide()
        }else{
          this.bankInfo = response.action.data
          this.bankAddress = response.action.address
          this.bankError = false
          this.notFound = false
          loader.hide()
        }
      }else{
        let payload;
        payload = {
          type: 'swiftCode',
          swiftCode: this.form.number,
        }
        const response = (await api.action("Transactions", "new", "BankSwift", payload))
        if(response.action.error){
          this.bankError = true
          this.bankErrorMsg = response.action.errors
          this.notFound = false
          this.bankInfo = null
          this.bankAddress = null
          loader.hide()
        } else if(response.action.notFound){
          this.notFound = true
          this.bankError = false
          this.bankInfo = null
          this.bankAddress = null
          loader.hide()
        }else{
          this.bankSwiftInfo = response.action.data.swift_data
          this.bankSwiftAddress = response.action.data.bank_data
          this.bankError = false
          this.notFound = false
          loader.hide()
        }
      }
    }
  },

  async created () {
  },

  validations () {
    const rules = {
      form: {
        account: { required },
        number: { required }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 30px;
  }
  .template-check {
    display: flex;
    align-items: center;

    input {
      display: inline-block !important;
      width: 20px !important;
      height: 20px !important;
    }

    label {
      display: inline !important;
      position: unset !important;

      &:before {
        position: unset !important;
      }
    }
  }

  input::selection {
    background: #0000ff;
    color: #fff;
  }

  .information p {
    margin: 5px;
  }

  .payment-reference {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bank-info {
    label {
      width: 30%;
    }
  }

  .bank-info {
    .form-row {
      justify-content: start;
    }
  }

  .content-tlist {
    background: none; 
  }
  
  .content-table {
    min-width: unset;
  }
</style>