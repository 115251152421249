<template>
  <popup open>
    <div class="popup-header">
      <h3>Trade</h3>
      <h3 v-if="trade">{{ trade.tradeNumber }}</h3>
      <h3 v-if="trade">Status: {{ letterUpper(getStatus(trade.quoteStatus)) }}</h3>
    </div>
    <template>
      <div class="scrollable">
        <div class="information">
          <template v-if="trade">
            <div>
              <p><b>Account</b>: ****{{ trade.accountId.substr(4, 3) }}</p>
              <p><b>Created</b>: {{ trade.createdAt | date('MM/D/YYYY hh:mm A')  }}</p>
            </div>
            <div>
              <p><b>Profile</b>: {{ trade.profileId }}</p>
              <p><b>Completed By</b>: {{ trade.completedBy }}</p>
            </div>
          </template>
        </div>
        <div class="popup-header details">
          <h3>Details</h3>
        </div>
        <template v-if="trade">
          <div class="information">
            <div>
              <p><b>Quote</b>: {{ trade.quoteNumber }}</p>
              <p><b>Trade Type</b>: {{ trade.quoteType }}</p>
              <p><b>Base Amount</b>: {{ trade.baseAmount | balance }}</p>
              <p><b>Total Amount</b>: {{ trade.totalAmount | balance }}</p>
              <p><b>Unit Count</b>: {{ parseFloat(trade.unitCount) }}</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p><b>Completed On</b>: <template v-if="trade.trade.providerSettledAt">{{ trade.trade.providerSettledAt | date('MM/D/YYYY hh:mm A') }}</template></p>
            </div>
            <div>
              <p><b>Trade</b>: {{ trade.tradeNumber }}</p>
              <p><b>Asset</b>: {{ trade.assetName }}</p>
              <p><b>Broker Fee</b>: {{ Math.abs(trade.feeAmount) | balance }}</p>
              <p><b>Price Per Unit</b>: {{ parseFloat(trade.pricePerUnit) }}</p>
              <p><b>Trade Fee</b>: {{ Math.abs(trade.tradeFee) | balance }}</p>
            </div>
          </div>
        </template>
      </div>
      <div class="popup-header notes">
        <h3>Options</h3>
      </div>
      <div class="buttons">
        <div></div>
        <button class="main" @click="print(trade.id)">Print</button>
        <button class="second" @click="closeTradeDetailModal()">Close</button>
      </div>
    </template>
  </popup>
</template>

<script>
export default {
  props: {
    trade: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.buttons {
  margin-top: 30px;
}
.buttons {
  display: flex;
  justify-content: space-between;

  .optionBtns .main {
    margin-left: 15px; 
  }
}
.information {
  display: flex;
  justify-content: space-between;

  div {
    width: 50%; 
  }
}
</style>