<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <profile-reviews-table :data="reviews" />
      </div>
    </div>
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Compliance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>

    <profile-review-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import ProfileReviewFilters from './filters/ProfileReviewFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'Profile-Reviews',

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    AppliedFilters,
    ProfileReviewFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,

      open: true,
      pin: '',
      pinFailed: false,
      activeInfo: true,
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        profile: 'Profile',
        name: 'Name',
        type: 'Profile Type',
        status: 'Status'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    totalNumber() {
      return this.reviews.length
    },

    reviews () {
      let data = this.$store.getters['registrations/list'] || []
      if(!this.filters) {
        data = data.filter(item => item.status == 'Review')
      }
      if (this.filters) {

        if (this.filters.fromDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          data = data.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date >= from
          })
        }

        if (this.filters.toDate) {
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          data = data.filter(reg => {
            const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date <= to
          })
        }

        if (this.filters.name) {
          data = data.filter(item => item.name.toLowerCase().includes(this.filters.name.toLowerCase()))
        }

        if (this.filters.type) {
          data = data.filter(item => item.type == this.filters.type )
        }

        if (this.filters.profile) {
          data = data.filter(item => item.profileNumber.includes(this.filters.profile))
        }

        if (this.filters.currency) {
          data = data.filter(item => item.currency.includes(this.filters.currency))
        }

        if (this.filters.status) {
          data = data.filter(item => item.status == this.filters.status )
        }
      }
      return data;

    },

    now () {
      return moment().format('YYYY-MM-DD')
    }
  },

  created () {
    this.$store.dispatch('registrations/load', {
      params: {
        status: 'Review',
      }
    })
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'status') return window.profileReviewStatuses[value]

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
     },
    cancel() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCompliancePin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>