<template>
  <div>
    <section  class="content-tlist" style="margin: 30px 0;">
      <h2>Schedule</h2>
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Fee Name</th>
          <th style="width: 10%;">Fee</th>
          <th style="width: 10%;">Deposit</th>
          <th style="width: 10%;">Withdrawal</th>
          <th style="width: 13%;">Description</th>
        </tr>
        <tr v-for="(item, i) in fees" :key="i">
          <template v-if="i==0">
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!isEdit"> {{ item.fee | formattedTwoDecimal }} </template>
              <input
                v-else
                v-model="item.fee"
                type = "number"
                class="fee-input"
              />%
            </td>
            <td></td>
            <td></td>
            <td>
              <span>Description</span>
              <template v-if="!isEdit"> {{ item.description }}</template>
              <input  
                type="text"
                v-else
                v-model="item.description"
                class="description"
              />
            </td>
          </template>
          <template v-else-if="i != 'createdAt'">
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!noneEditable(item.name, 'fee')">
                $<template v-if="!isEdit"> {{ item.fee | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.fee"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
            <td>
              <span>Per Deposit</span>
              <template v-if="!noneEditable(item.name, 'deposit')">
                $<template v-if="!isEdit"> {{ item.per_deposit | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.per_deposit"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
            <td>
              <span>Per Withdrawal</span>
              <template v-if="!noneEditable(item.name, 'withdrawal')">
                $<template v-if="!isEdit"> {{ item.per_withdrawal | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.per_withdrawal"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
            <td>
              <span>Description</span>
              <template v-if="!isEdit"> {{ item.description }}</template>
              <input 
                type="text"
                v-else
                v-model="item.description"
                class="description"
              />
            </td>
          </template>
        </tr>
      </table>
    </section>
    <div v-if="noUpdate">
      <template v-if="$hasPerm('partners-update')">
        <template v-if="isEdit">
          <button @click="saveUpdate()" class="button-handle save">Save</button>
          <button @click="isEdit = false" class="button-handle cancel">Cancel</button>
        </template>
        <template v-else>
          <button v-if="featureStatus" @click="isEdit = true" class=" second">Update</button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FeeSchedule',

  props: {
    registration: {
      type: Object,
      required: true
    },
    noUpdate: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      isEdit: false,
      featureStatus: true
    }
  },

  computed: {
    fees () {
      let data = []
      data = this.registration.schedule || this.$store.state.fee_schedule.data || []
      return data
    }
  },

  async created () {
    await this.getFeatureStatus();
    await this.$store.dispatch('fee_schedule/load')
  },

  methods: {
    noneEditable (name, column) {
      const noneeditable = {
        fee: ['Domestic Wire Transfer', 'International Wire Transfer', 'ACH Transfer', 'Account to Account Transfer', 'Checks'],
        deposit: ['Volume Fee', 'Monthly Access Fee', 'Account to Account Transfer', 'Exchange Transactions', 'Dormant Accounts', 'Debit Card'],
        withdrawal: ['Volume Fee', 'Monthly Access Fee', 'Exchange Transactions', 'Dormant Accounts' ]
      }
      return noneeditable[column].includes(name)
    },

    async saveUpdate (i) {
      try {
        const payload = {
          updatedAt: 'CURRENT_TIMESTAMP',
            schedule: {
              ...this.fees,
            }
        }
        
        await this.$store.dispatch('partners/update', {
          id: this.registration.id,
          payload
        })
        await this.$store.dispatch('partner_notes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: this.registration.id,
          notes: 'Fee Schedule updated',
          createdAt: 'CURRENT_TIMESTAMP'
        })
        this.$toast.success('Fee Schedule successfully updated.')
        this.isEdit = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Customized Fees']
    },
  }

}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 20px; 
    color: #0075be; 
    padding: 10px;
  }
  .fee-input {
    max-width: 50px;
  }
  .fee-input::-webkit-inner-spin-button,
  .fee-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .description {
    max-width: 100%;
  }
  .button-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white;
    border: none;
    outline: none;
    margin-left: 30px;
    margin-bottom: 30px; 

    &.update {
      background-color: #ffa700;
    }
    &.remove {
      background-color: #d62b2b;
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
</style>