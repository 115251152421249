<template>
<div class="popup" :class="{open: expired}">
    <div class="pop-inner">
      <div class="pop-box session-timeout">
        <a class="close" href=""></a>
        <p>You have been logged out due to inactivity. Please click below to login again.</p>
        <a class="button" href="">Login</a>
      </div>
    </div>
</div>
</template>


<script>
export default {
  data: ()=>({
    expired: false,
  }),
  methods: {
    async checkExpired() {
      var timeout=(appData.sessionTimeout || 10)*60*1000;
      var lastActive=window.localStorage.getItem("monbiLastActive");
      var now=(new Date()).getTime();
      if (now-lastActive>timeout) {
        await api.logout();
        this.expired=true;
      }
    },
    markActive() {
      window.localStorage.setItem("monbiLastActive", (new Date()).getTime());
    },
  },
  mounted() {
    setInterval(()=>this.checkExpired(), 10000);
    this.markActive();
    document.addEventListener("mousedown", ()=>this.markActive(), true);
    document.addEventListener("click", ()=>this.markActive(), true);
    document.addEventListener("touchstart", ()=>this.markActive(), true);
  },
}
</script>

