<template>
  <section class="support-page">
    <div class="support-split">
      <div class="s-main">
        <h2 class="inner-headers">Support</h2>
      </div>
      <aside class="s-side">
        <h3>Support Contact Informaton</h3>
        <ul>
          <li class="ticket" v-if="!customEnv.nosupptickets">
            <div class="title">Support Ticket</div>
            <div class="content"><a href="">Click Here</a> to open a new support ticket</div>
          </li>
          <li class="chat" v-if="!customEnv.nosupptickets">
            <div class="title">Live Chat</div>
            <div class="content"><a href="">Click Here</a> to chat with one of our representatives</div>
          </li>
          <li class="email">
            <div class="title">Email</div>
            <div class="content">Contact us at <a :href="`mailto:${system.support_email}`">{{ system.support_email }}</a></div>
          </li>
          <li class="phone">
            <div class="title">Phone</div>
            <div class="content">Contact us at <a :href="`tel:${system.support_phone}`">{{ system.support_phone }}</a></div>
          </li>
        </ul>
      </aside>
    </div>
  </section>
</template>

<script>
export default {
  data: ()=>({
    customEnv: appData.customEnv,
  }),
  created () {
    this.$store.commit('system/setSingle', window.appData.system)
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  }
}
</script>
