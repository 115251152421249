<template>
  <form>
    <div class="content-form">
      <div class="form-row form-fullw">
        <form-field-text v-model="form.current" type="password" :validator="$v.form.current" label="Current Password" required  />
      </div>
      <div class="form-row form-fullw">
        <form-field-text v-model="form.password" type="password" :validator="$v.form.password" label="New Password" required  />
      </div>
      <div class="form-row form-fullw">
        <form-field-text v-model="form.confirmPassword" type="password" :validator="$v.form.confirmPassword" label="Confirm New Password" required  />
      </div>
      
      <div class="form-row form-center">
        <div>
          <button type="button" @click="submit()">Confirm</button>
        </div>
      </div>

      <a class="cancel" @click="reset()">Cancel</a>
    </div>
  </form>
</template>

<script>
import helper from '../../components/common/helper-mixin'
import { mustBeTrue, passwordValidation } from '../../lib/validators'
import { required, sameAs, not } from 'vuelidate/lib/validators'

export default {
  mixins: [helper],

  data: ()=>({
    form: {
      current: null,
      password: null,
      confirmPassword: null
    }
  }),

  computed: {
    user () {
      return window.appData.currentUser
    }
  },

  methods: {
    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const response = await this.$store.dispatch('users/action', {
        id: this.user.id,
        action: 'ChangePassword',
        payload: this.form
      })
      if (response?.action?.error) {
        this.$toast.error(response.action.error)
        return
      }
      this.$toast.success('Password changed successfully.')
      this.reset()
    },

    reset () {
      this.form = {
        current: null,
        password: null,
        confirmPassword: null
      }
      this.$v.$reset()
    }
  },

  validations: {
    form: {
      current: { required },
      password: { required, passwordValidation, notSame: not(sameAs('current')) },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    }
  }
}
</script>

<style lang="scss" scoped>
.content-form {
  width: 400px;
  margin: 0 auto;
  .cancel {
    display: block; 
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
}
</style>