<template>
  <div>
    <template v-if="open">
      <template v-if="perms">
        <section>
          <div style="padding: 24px 30px 10px 10px; display: flex;">
            <div class="content-filters">
              <basic-form>
                <div class="cof-row">
                  <div class="cof-field">
                  <mnb-select v-model="form.provider" :options="providerOptions" label="" :disabled="!!form.fromDate || !!form.toDate"/>
                  </div>
                </div>
              </basic-form>
            </div>
            <button v-if="form.provider && activeInfoReversals" class="btn btn-secondary" @click="activeInfo = !activeInfo , activeInfoReversals = false"><i class="el-icon-document-add" /> Generate Fees</button>
            <button v-if="form.provider && activeInfo" class="btn btn-secondary" @click="activeInfoReversals = !activeInfoReversals, activeInfo = false"><i class="el-icon-receiving" /> Owed Fees</button>
          </div>
        </section>
        <template v-if="form.provider">
          <div class="accordion">
            <div v-if="activeInfo" :class="{'cof-box-active' : activeInfo}">
              <div class="content-filters">
                <template v-if="form.provider">
                  <div class="cof-row dates">
                    <div class="cof-field">
                      <label>Start Date</label>
                      <div class="cof-multi flex">
                        <date-time-picker
                          v-model="form.fromDate"
                          value-type="String"
                          value-format="yyyy-LL-dd"
                          format="yyyy-LL-dd"
                          :time-picker="false"
                          :auto-close="true"
                          :max-date="date2"
                          :disabled="!form.provider"
                        >
                        </date-time-picker>
                      </div>
                    </div>
                    <div class="cof-field">
                      <label>End Date</label>
                      <div class="cof-multi flex">
                        <date-time-picker
                          v-model="form.toDate"
                          value-type="String"
                          value-format="yyyy-LL-dd"
                          format="yyyy-LL-dd"
                          :time-picker="false"
                          :auto-close="true"
                          :max-date="date2"
                          :disabled="!form.provider"
                        >
                        </date-time-picker>
                      </div>
                      <div class="error-message" v-if="toDateError"><span>Invalid End Date.</span></div>
                    </div>
                  </div>
                  <div class="cof-row buttons">
                    <div v-if="$hasPerm('generate-fees')" class="cof-field">
                      <label>&nbsp;</label>
                      <button class="second" @click="generate()" :disabled="!form.provider || toDateError || (form.fromDate == null) || (form.toDate == null)">Generate</button>
                    </div>
                    <div class="cof-field">
                      <label>&nbsp;</label>
                      <button :disabled="!form.provider" class="second" @click="reset()">Reset</button>
                    </div>
                  </div>
                  <div class="cof-row run">
                    <div class="cof-field">
                      <label>&nbsp;</label>
                      <button class="action-button save" v-if="isRun" @click="run()">Run</button>
                    </div>
                  </div>
                </template>
                <div class="result" v-if="isResult">
                  <h3 class="subtitle">Result:</h3>
                  <table class="result-table">
                    <tr>
                      <td >Collected:</td>
                      <td style="width: 25%;">{{ total.totalCollected }}</td>
                      <td>{{total.totalCollectedFee | balance}}</td>
                    </tr>
                    <tr>
                      <td>Not Collected:</td>
                      <td style="width: 25%;">{{ total.totalNotCollected }}</td>
                      <td>{{total.totalNotCollectedFee | balance}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <section class="content-tlist">
                <table class="content-table">
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Volume</th>
                    <th>Volume Fee</th>
                    <th>Volume Charge</th>
                    <th>Transaction Fees</th>
                    <th>Monthly Fee</th>
                    <th>Dormant Fee</th>
                    <th>Owed Fee</th>
                    <th>Total Fees</th>
                    <th>Available Balance</th>
                    <th>Result</th>
                    <th>Options</th>
                  </tr>
                  <tr v-if="item.accountStatus != 'Pending'" v-for="(item, i) in results" :key="i"
                      :class="item.totalFee >= item.systemAvailableBalance?'highlight-yellow':''">
                    <td><span>Account</span>
                      <a :href="`/system-customers/registrations/${item.regId}`">{{ item.accountNumber }}</a>
                    </td>
                    <td><span>Account</span>{{item.regName}}</td>
                    <td><span>Account</span><em :class="statusAccountColorClasses[item.accountStatus]">{{item.accountStatus}}</em></td>
                    <td><span>Transaction Volume</span>{{item.volume | balance}}</td>
                    <td><span>Discount Fee</span>{{item.discount | formattedTwoDecimal}}%</td>
                    <td><span>Discount Charged</span>{{item.discountCharged | balance}}</td>
                    <td><span>Transaction Fees</span>{{item.transactionFee | balance}}</td>
                    <td><span>Monthly Fee</span>{{item.monthlyFee | balance}}</td>
                    <td><span>Dormant Fee</span><template v-if="item.accountStatus == 'Dormant'">{{item.dormantAccountsFee | balance}}</template></td>
                    <td><span>Owed Fees</span>{{item.owedFee | balance}}</td>
                    <td><span>Total Fees</span>{{item.totalFee | balance}}</td>
                    <td><span>Available Balance</span>{{item.systemAvailableBalance | balance}}</td>
                    <td><span>Result</span><em :class="statusColorClasses[item.resultMonthly]">{{item.resultMonthly}}</em></td>
                    <td><span>Options</span>
                      <button class="action-button save" v-if="item.resultMonthly == 'Failed'" @click="runOne(item.id, i)">Run</button>
                    </td>
                  </tr>
                  <tr v-if="total">
                    <td>Totals</td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span>{{total.totalVolume | balance}}</td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span>{{total.totalDiscountCharged | balance}}</td>
                    <td><span>Totals</span>{{total.totalTransactionFee | balance}}</td>
                    <td><span>Totals</span>{{total.totalMonthlyFee | balance}}</td>
                    <td><span>Totals</span>{{total.totalDormantFee | balance}}</td>
                    <td><span>Totals</span>{{total.totalOwedFee | balance}}</td>
                    <td><span>Totals</span>{{total.totalTotalFee | balance}}</td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span><template v-if="total.totalRealTotalFee">{{total.totalRealTotalFee | balance}}</template></td>
                    <td><span>Totals</span></td>
                  </tr>
                </table>
              </section>
            </div>
            <div v-if="activeInfoReversals" :class="{'cof-box-active' : activeInfoReversals}">
              <section class="content-filters" style="padding: 30px;">
                <div>
                  <a href="" class="tsm-handle">Filters</a>
                  <h4>Filter</h4>
                  <div class="cof-form">
                    <a class="cof-close"></a>
                    <h6>Registrations Search</h6>
                    <div class="cof-row">
                      <div class="cof-field">
                        <label>Account ID</label>
                        <input type="text" v-model="searchForm.accountId" />
                      </div>
                      <div class="cof-field">
                        <label>Name</label>
                        <input type="text" v-model="searchForm.name"/>
                      </div>
                      <div class="cof-field">
                        <label>Status</label>
                        <select v-model="searchForm.status">
                          <option :value="null"></option>
                          <option value="New">NEW</option>
                          <option value="Pending">PENDING</option>
                          <option value="Open">OPEN</option>
                          <option value="Restricted">RESTRICTED</option>
                          <option value="Hold">HOLD</option>
                          <option value="Dormant">DORMANT</option>
                          <option value="Closed">CLOSED</option>
                        </select>
                      </div>
                      <div class="cof-field mobinl">
                        <label>&nbsp;</label>
                        <button class="main" @click="search()">Search</button>
                      </div>
                      <div class="cof-field mobinl">
                        <label>&nbsp;</label>
                        <button class="second" @click="resetItem()">Reset Filters</button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="content-tlist">
                <table class="content-table">
                  <tr>
                    <th>Account</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Owed Fee <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('owedFee')"/></th>
                    <th>Available Balance <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('availableBalance')"/></th>
                    <th>Result</th>
                    <th>Options</th>
                  </tr>
                  <tr v-if="item.accountStatus != 'Pending' && item.owedFee > 0" v-for="(item, i) in owedResults" :key="i"
                  >
                    <td><span>Account</span>
                      <a :href="`/system-customers/registrations/${item.regId}`">{{ item.accountNumber }}</a>
                    </td>
                    <td><span>Account</span>{{item.regName}}</td>
                    <td><span>Account</span><em :class="statusAccountColorClasses[item.accountStatus]">{{item.accountStatus}}</em></td>
                    <td><span>Owed Fees</span>{{item.owedFee | balance}}</td>
                    <td><span>Available Balance</span>{{item.systemAvailableBalance | balance}}</td>
                    <td><span>Result</span><em :class="statusColorClasses[item.resultMonthly]">{{item.resultMonthly}}</em></td>
                    <td><span>Options</span>
                      <template v-if="item.systemAvailableBalance != 0" >
                        <button class="action-button save" v-if="$hasPerm('generate-fees') &&  parseFloat(item.owedFee) <= parseFloat(item.systemAvailableBalance)" @click="fullPayment(item)">Full Payment</button>
                        <button class="action-button save" v-if="$hasPerm('generate-fees') &&  parseFloat(item.owedFee) > parseFloat(item.systemAvailableBalance)" @click="partialPayment(item)">Partial Payment</button>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="total">
                    <td>Totals</td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span>{{owedTotal.totalOwedFee | balance}}</td>
                    <td><span>Totals</span></td>
                    <td><span>Totals</span><template v-if="owedTotal.totalRealTotalFee">{{owedTotal.totalRealTotalFee | balance}}</template></td>
                    <td><span>Totals</span></td>
                  </tr>
                </table>
              </section>
            </div>
          </div>
        </template>
      </template>
    </template>
    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Finance PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker'
  import BasicForm from '../../components/ui/forms/Form'
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import MnbSelect from '../../components/ui/forms/Select'

  export default {
    name: 'monthly-fees',

    components: {
      VueEnglishdatepicker,
      BasicForm,
      DateTimePicker,
      MnbSelect,
    },

    data() {
      return {
        form: {
          fromDate: null,
          toDate: null,
          provider: null,
        },

        searchForm: {
          accountId: null,
          name: null,
          status: null,
        },

        results: null,
        resultsOwed: null,
        total: null,
        owedTotal: null,
        isRun: false,
        isResult: false,
        viewOwed: false,
        appliedFilters: null,

        toDateError: false,
        sortName: null,
        sortFlag: true,

        activeInfo: true,
        activeInfoReversals: false,

        open: true,
        pin: '',
        pinFailed: false,
        perms: window.appData.currentUser?.role?.perms?.includes('generate-fees') || window.appData.currentUser?.perms?.includes('generate-fees')
      }
    },

    computed: {
      statusColorClasses() {
        return {
          Success: 'c1',
          Failed: 'c2',
        }
      },

      statusAccountColorClasses () {
        return {
          Open: 'c1',
          Restricted: 'c2',
          Pending: 'c2',
          Closed: 'c4',
          Dormant: 'c3',
          Hold: 'c2'
        }
      },

      date2 () {
        let today = new Date()
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        return yesterdayDate
      },

      providers () {
        return this.$store.state.providersList.data || []
      },

      providerOptions () {
        return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.kyc && provider.providerType == 'Accounts').map(provider => ({
          id: provider.id,
          text: provider.provider_name
        }))
      },

      owedResults() {
        let results = this.resultsOwed;
        if (this.appliedFilters) {
          if (this.appliedFilters.accountId) {
            results = results.filter(account => {
              return account.accountNumber.includes(this.appliedFilters.accountId)
            })
          }
          if (this.appliedFilters.name) {
            results = results.filter(account => {
              return account.regName.toLowerCase().includes(this.appliedFilters.name.toLowerCase())
            })
          }
          if (this.appliedFilters.status) {
            results = results.filter(account => account.accountStatus === this.appliedFilters.status)
          }
        }
        if(this.sortName != null) {
          if (this.sortName == 'owedFee') {
            if(this.sortFlag) {
              this.resultsOwed.sort((a, b) => {
                if (Number(a.owedFee) > Number(b.owedFee)) return 1
                if (Number(a.owedFee) < Number(b.owedFee)) return -1
                return 0
              })
            }else{
              this.resultsOwed.sort((a, b) => {
                if (Number(a.owedFee) > Number(b.owedFee)) return -1
                if (Number(a.owedFee) < Number(b.owedFee)) return 1
                return 0
              })
            }
          }

          if (this.sortName == 'availableBalance') {
            if(this.sortFlag) {
              this.resultsOwed.sort((a, b) => {
                if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return 1
                if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return -1
                return 0
              })
            }else{
              this.resultsOwed.sort((a, b) => {
                if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return -1
                if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return 1
                return 0
              })
            }
          }
        }
        return results
      }
    },

    watch: {
      'form.toDate'() {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        const to = moment(this.form.toDate, 'YYYY-MM-DD')
        if (to > now)
          this.toDateError = true
        else
          this.toDateError = false
      },

      'sortName'() {
        this.refreshResults()
      },

      'sortFlag'() {
        this.refreshResults()
      },

      appliedFilters () {
        this.refreshResults();
      },

      'form.provider'() {
        this.viewOwedRun()
      }
    },

    async created() {
      await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
      await this.viewOwedRun()
    },

    methods: {
      async generate() {
        if (this.toDateError) return
        const loader = this.$loading.show()
        const payload = {
          fromDate: this.form.fromDate,
          toDate: this.form.toDate,
          provider: this.form.provider,
        }
        try {
          const fees = (await api.action("Transactions", "new", "GenerateMonthlyFee", payload)).action
          this.results = fees.accounts
          this.total = fees.total
          this.isRun = true
          this.viewOwed = false
          this.isResult = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async viewOwedRun() {
        if (this.toDateError) return
        const loader = this.$loading.show()
        const payload = {
          fromDate: this.form.fromDate,
          toDate: this.form.toDate,
          provider: this.form.provider,
        }
        try {
          const fees = (await api.action("Transactions", "new", "ViewOwedFee", payload)).action
          this.resultsOwed = fees.accounts
          this.owedTotal = fees.total
          this.viewOwed = true
          this.isResult = false
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async fullPayment(item) {
        if(confirm(`Confirm Payment?`)) {
          const loader = this.$loading.show()
          let payload = {
            accountId: item.id,
            provider: this.form.provider,
          }
          try {
            (await api.action("Transactions", "new", "FullPayment", payload))
            payload = {
              fromDate: this.form.fromDate,
              toDate: this.form.toDate,
              provider: this.form.provider,
            }
            const fees = (await api.action("Transactions", "new", "ViewOwedFee", payload)).action
            this.resultsOwed = fees.accounts
            this.owedTotal = fees.total
            this.viewOwed = true
            this.isResult = false
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async partialPayment(item) {
        if(confirm(`Confirm Payment?`)) {
          const loader = this.$loading.show()
          let payload = {
            accountId: item.id,
            provider: this.form.provider,
          }
          try {
            (await api.action("Transactions", "new", "PartialPayment", payload))
            payload = {
              fromDate: this.form.fromDate,
              toDate: this.form.toDate,
              provider: this.form.provider,
            }
            const fees = (await api.action("Transactions", "new", "ViewOwedFee", payload)).action
            this.resultsOwed = fees.accounts
            this.owedTotal = fees.total
            this.viewOwed = true
            this.isResult = false
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async run() {
        const loader = this.$loading.show()
        const payload = {
          fromDate: this.form.fromDate,
          toDate: this.form.toDate,
          provider: this.form.provider,
        }
        try {
          const fees = (await api.action("Transactions", "new", "RunMonthlyFee", payload)).action
          this.results = fees.accounts
          this.total = fees.total
          this.isRun = false
          this.isResult = true
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async runOne(id, i) {
        const loader = this.$loading.show()
        const payload = {
          accountId: id
        }
        try {
          const fees = (await api.action("Transactions", "new", "RunOneMonthlyFee", payload)).action
          this.results[i].resultMonthly = fees.status
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      reset() {
        this.results = null
        this.total = null
        this.isRun = false
        $('.fromDate').val('')
        $('.toDate').val('')
        this.form.fromDate = null
        this.form.toDate = null
        this.form.provider = ''
      },

      cancel() {
        window.history.back()
      },

      async checkPin() {
        const action = (await api.action("Pin", "new", "CheckFinancePin", { pin: this.pin })).action
        if(action.pin) {
          this.pinFailed = false
          this.open = false
        }else{
          this.pinFailed = true
        }
      },

      pinInput: function(e) {
        if (e.keyCode === 13) {
          this.checkPin()
        }
      },

      sortTemplateName(sortName) {
        if(this.sortName == sortName){
          this.sortFlag = !this.sortFlag
        }else {
          this.sortName = sortName
          this.sortFlag = true
        }
      },

      refreshResults() {
        if(this.sortName != null) {
          if (this.sortName == 'owedFee') {
            if(this.sortFlag) {
              this.resultsOwed.sort((a, b) => {
                if (Number(a.owedFee) > Number(b.owedFee)) return 1
                if (Number(a.owedFee) < Number(b.owedFee)) return -1
                return 0
              })
            }else{
              this.resultsOwed.sort((a, b) => {
                if (Number(a.owedFee) > Number(b.owedFee)) return -1
                if (Number(a.owedFee) < Number(b.owedFee)) return 1
                return 0
              })
            }
          }

          if (this.sortName == 'availableBalance') {
            if(this.sortFlag) {
              this.resultsOwed.sort((a, b) => {
                if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return 1
                if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return -1
                return 0
              })
            }else{
              this.resultsOwed.sort((a, b) => {
                if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return -1
                if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return 1
                return 0
              })
            }
          }
        }
      },

      search() {
        this.appliedFilters = { ...this.searchForm }
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .dates {
    justify-content: center;

    .cof-field {
      margin: 0 50px;
    }
  }

  .buttons {
    justify-content: center;

    .cof-field {
      margin: 0 50px;
    }
  }

  .highlight-yellow td{
    background: #ffdb96 !important;
  }

  .result {
    position: absolute;
    top: 33px;
    right: 130px;
  }

  .result-table {
    width: 300px;
    color: #616f7b;
    font-weight: 400;

    td {
      padding: 10px 0;
    }
  }

  .content-filters {
    position: relative;
  }

  .view-owed-btn {
    position: absolute;
    top: 100px;
    left: 50px;
  }

  .trans-cof-box {
    width: 50%;
    position: relative;
    height: 51px;
    overflow: hidden;
    padding: 0 16px;
    margin: 15px 30px;
    background: #f8f9fb;
    border-radius: 16px;

    .accordion-headline {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 18px;
      width: 100%;
      height: 19px;
      padding: 16px 0;
      cursor: pointer;
    }

    .total-number {
      position: absolute;
      top: 14px;
      right: 50%;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .trans-sub-cof-box {
    position: relative;
    height: 0px;
    overflow: hidden;
    padding: 0 16px;
    margin: 0px 30px;
    background: #f8f9fb;
    border-radius: 16px;
  }
  .trans-cof-box::after {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    left: unset;
    width: 13px;
    height: 1px;
    transition: .25s ease-out;
    background-color: #0480c6;
    transform: rotate(90deg);
  }
  .trans-cof-box::before {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    width: 13px;
    height: 1px;
    transition: .25s ease-out;
    background-color: #0480c6;
  }
  .trans-cof-box-active::before {
    transform: rotate(180deg);
  }
  .trans-cof-box-active::after {
    transform: rotate(180deg);
  }
  .accordion .cof-box-active {
    height: auto;
    min-height: 520px;
  }
  .content-tlist {
    border: none;
  }
  .content-filters .cof-box {
    padding: 0;
    margin: 0px 20px;
    background: none;
  }
</style>