<template>
  <div class="static-page privacy-popup">
    <h2><strong>{{ legal.privacyPolicyHeader }}</strong></h2>
    <div v-html="legal.privacyPolicyText"></div>
    

    <h3>Last updated</h3>
    <p>{{ legal.privacyPolicyDate }}</p>
  </div>
</template>

<script>

export default {
  data: ()=>({
    legal: window.legalDisclaimers || {},
  }),
}
</script>
<style lang="scss" scoped>
  .privacy-popup {
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup h2{
    border-bottom: none;
  }

  .privacy-popup p {
    margin-bottom: 18px;
    line-height: 22px;
    color: #21364c;
  }

  .privacy-popup a {
    color: #00f;
    text-decoration: underline;
  }
</style>
