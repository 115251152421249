<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="cof-field sysint-page">
            <label>Provider Type</label>
            <mnb-select v-model="form.providerType" :validator="$v.form.providerType" :options="providerTypeOptions" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.provider_name" :validator="$v.form.provider_name" label="Provider Name" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.label" label="Label" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.version" :validator="$v.form.version" label="Version" />
          </div>
          <div class="cof-field sysint-page">
            <label>Environment</label>
            <mnb-select v-model="form.environment" :validator="$v.form.environment" :options="environmentOptions" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.username" label="Username" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.password" label="Password" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.code" label="Key" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.solutionId" label="Solution ID" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.ledgerId" label="Ledger ID" />
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field sysint-page">
            <mnb-input class="url" v-model="form.url" label="URL" :validator="$v.form.url" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input class="url" v-model="form.clientId" label="Client ID" :validator="$v.form.clientId" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input class="url" v-model="form.clientSecret" label="Client Secret" :validator="$v.form.clientSecret" />
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field sysint-page">
            <label>KYC/AML</label>
            <mnb-select v-model="form.kyc" :validator="$v.form.kyc" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>ACH</label>
            <mnb-select v-model="form.ach" :validator="$v.form.ach" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Account Transfer</label>
            <mnb-select v-model="form.acct" :validator="$v.form.acct" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Checks</label>
            <mnb-select v-model="form.checks" :validator="$v.form.checks" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Remote Deposit</label>
            <mnb-select v-model="form.remoteDeposit" :validator="$v.form.remoteDeposit" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Domestic Wires</label>
            <mnb-select v-model="form.domWires" :validator="$v.form.domWires" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>International Wires</label>
            <mnb-select v-model="form.intlWires" :validator="$v.form.intlWires" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Assets</label>
            <mnb-select v-model="form.assets" :validator="$v.form.assets" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Multi-Currency</label>
            <mnb-select v-model="form.multiCurrency" :validator="$v.form.multiCurrency" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Debit Cards</label>
            <mnb-select v-model="form.debitCards" :validator="$v.form.debitCards" :options="yesNoOptions" />
          </div>
          <div class="cof-field sysint-page">
            <label>Transaction Countries</label>
            <multi-select-dropdown :options="countries" v-model="form.transactionCountries" :isMulti="true" />
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field sysint-page">
            <mnb-input class="url" v-model="form.notes" label="Notes" :validator="$v.form.notes" />
          </div>
          <div class="cof-field sysint-page">
            <label>Connection Type</label>
            <mnb-select :options="connectionTypeOptions" v-model="form.connectionType" />
          </div>
        </div>
        <div class="cof-row">                          
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Provider</button>
          </div> 
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetFields()">Reset Fields</button>
          </div>               
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">            
        <tr>
          <th>Key Auto Update</th>
          <th>Provider Name</th>
          <th>Version</th>
          <th>Environment</th>
          <th>Date</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Options</th>                          
        </tr>
        <tr v-for="(provider, i) in providers" :key="i">
          <td class="updateAt"><span>Upd</span>
            <input type="checkbox" class="fee-checkbox" v-model="provider.upd" @change="updUpdate(i)"/>
            <br/>
            {{ provider.updatedAt | date('YYYY-MM-D') }}<br/>
            {{ provider.updatedAt | date('hh:mm:ss') }}
          </td>
          <td><span>Provider Name</span><a @click="detail(provider.id)">{{ provider.provider_name }}</a></td>
          <td><span>Version</span>{{ provider.version }}</td>       
          <td><span>Version</span>{{ provider.environment }}</td>
          <td><span>Date</span>{{ provider.createdAt | date('LL')}}</td>
          <td>
            <span>Status</span>
            <template v-if="!isEdit(i)"><em
              :class="{ c1: provider.status == 'Active', c2: provider.status == 'Review', c3: provider.status === 'Testing'}" >
              {{ formatStatus(provider.status) }}
            </em></template>
            <select v-else v-model="provider.status">
              <option value="Review">Review</option>
              <option value="Testing">Testing</option>
              <option value="Active">Active</option>
              <option value="Disabled">Disabled</option>
              <option value="Archive">Archive</option>
            </select>
          </td>
          <td>
            <span>Notes</span>
            <template v-if="!isEdit(i)">{{ provider.notes }}</template>
            <mnb-input v-else v-model="provider.notes" />
          </td>
          <td>
            <span>Options</span>
            <template v-if="isEdit(i)">
              <button @click="saveUpdate(i)" class="action-button save">Save</button>
              <button @click="cancelEdit(i)" class="action-button cancel">Cancel</button>
            </template>
            <template v-else>
              <button @click="edit(i)" class="action-button update">Update</button>
              <button v-if="provider.status == 'Archive'" @click="deleteProvider(i)" class="action-button cancel">Remove</button>
            </template>
          </td>
        </tr>
      </table>
    </section>

    <popup :open.sync="open">
      <h2>Update Status</h2>
      <div class="pf-field status-options">
        <select v-model="status">
          <option value="Review">Review</option>
          <option value="Testing">Testing</option>
          <option value="Active">Active</option>
          <option value="Disabled">Disabled</option>
          <option value="Archive">Archive</option>
        </select>
      </div>
      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="open=false">Cancel</a>
        <button class="pop-btn" @click.prevent="updateStatus()">Confirm</button>
      </div>
    </popup>

    <popup :open.sync="openPopup">
      <div class="popup-header" v-if="providerPopup">
        <h3>Provider Details</h3>
        <h4>{{ providerPopup.createdAt }}</h4>
      </div>
      <div class="scrollable" v-if="providerPopup">
        <div class="content-form">
          <h3 class="subtitle">Provider Information</h3>
          <div class="form-row form-split">
            <form-field-select v-model="providerPopup.providerType" label="Provider Type" :options="providerTypeOptions" required :disabled="!updateProvider" />
            <form-field-select v-model="providerPopup.status" label="Status" :options="statusOptions" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-split">
            <form-field-text v-model="providerPopup.provider_name" label="Provider Name" required :disabled="!updateProvider" />
            <form-field-text v-model="providerPopup.label" label="Label"  :disabled="!updateProvider" />
          </div>
          <div class="form-row form-split">
            <form-field-text v-model="providerPopup.version" label="Version" required :disabled="!updateProvider" />
            <form-field-select v-model="providerPopup.environment" label="Environment"  :disabled="!updateProvider" :options="environmentOptions" />
          </div>
          <div class="form-row form-split">
            <form-field-text v-model="providerPopup.username" label="Username" required :disabled="!updateProvider" />
            <form-field-text v-model="providerPopup.password" label="Password" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.url" label="URL" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.code" label="Key" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.solutionId" label="Solution ID" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.ledgerId" label="Ledger ID" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.clientId" label="Client ID" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.clientSecret" label="Client Secret" required :disabled="!updateProvider" />
          </div>
        </div>
        <div class="content-form">
          <h3 class="subtitle">Provider Services</h3>
          <div class="services-split">
            <div>
              <p>KYC/AML</p>
              <mnb-switch v-model="providerPopup.kyc" :disabled="!updateProvider" />
            </div>
            <div>
              <p>ACH</p>
              <mnb-switch v-model="providerPopup.ach" :disabled="!updateProvider"/>
            </div>
          </div>
          <div class="services-split">
            <div>
              <p>Account Transfer</p>
              <mnb-switch v-model="providerPopup.acct" :disabled="!updateProvider"/>
            </div>
            <div>
              <p>Checks</p>
              <mnb-switch v-model="providerPopup.checks" :disabled="!updateProvider"/>
            </div>
          </div>
          <div class="services-split">
            <div>
              <p>Domestic Wires</p>
              <mnb-switch v-model="providerPopup.domWires" :disabled="!updateProvider"/>
            </div>
            <div>
              <p>International Wires</p>
              <mnb-switch v-model="providerPopup.intlWires" :disabled="!updateProvider"/>
            </div>
          </div>
          <div class="services-split">
            <div>
              <p>Assets</p>
              <mnb-switch v-model="providerPopup.assets" :disabled="!updateProvider"/>
            </div>
            <div>
              <p>Multi-Currency</p>
              <mnb-switch v-model="providerPopup.multiCurrency" :disabled="!updateProvider"/>
            </div>
          </div>
          <div class="services-split">
            <div>
              <p>Debit Cards</p>
              <mnb-switch v-model="providerPopup.debitCards" :disabled="!updateProvider"/>
            </div>
            <div>
              <p>Remote Deposit</p>
              <mnb-switch v-model="providerPopup.remoteDeposit" :disabled="!updateProvider"/>
            </div>
          </div>
        </div>
        <div class="">
            <div>
              <p>Transaction Countries</p>
              <multi-select-dropdown :options="countries" v-model="providerPopup.transactionCountries" :isMulti="true" :disabled="!updateProvider"/>
            </div>
        </div>
        <div class="content-form">
          <h3 class="subtitle">Provider Notes</h3>
          <div class="form-row form-max">
            <form-field-text v-model="providerPopup.notes" label="Notes" required :disabled="!updateProvider" />
          </div>
          <div class="form-row form-max">
            <form-field-select v-model="providerPopup.connectionType" label="Connection Type"  :disabled="!updateProvider" :options="connectionTypeOptions" />
          </div>
        </div>
        <div class="buttons">
          <button v-if="updateProvider" class="main" @click="saveProvider()">Save</button>
          <button v-else class="main" @click="updateProvider = true">Update</button>
          <button class="second" @click="openPopup = false">Cancel</button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MultiSelectDropdown from '../../components/MultiselectDropdown'
import Popup from '../popup'
import MnbSwitch from '../../components/ui/forms/Switch'

export default {
  name: 'providers-list',

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    MultiSelectDropdown,
    MnbSwitch,
    Popup
  },

  data () {
    return {
      open: false, 

      form: {
        provider_name: null,
        label: null,
        version: null,
        kyc: null,
        domWires: null,
        intlWires: null,
        ach: null,
        acct: null,
        checks: null,
        remoteDeposit: null,
        exchange: null,
        multiCurrency: null,
        debitCards: null,
        assets: null,
        status: null,
        username: null,
        password: null,
        code: null,
        notes: null,
        createdAt: null,
        providerType: null,
        environment: null,
        solutionId: null,
        ledgerId: null,
        url: null,
        clientId: null,
        clientSecret: null,
        transactionCountries: null,
        connectionType: null,
      },

      methods: null,
      status: 'Review',
      providerId: '',

      yesNoOptions: [
        {
          id: "1",
          text: 'Yes'
        },
        {
          id: "0",
          text: 'No'
        },
      ],

      providerTypeOptions: [
        {
          id: "Accounts",
          text: 'Accounts'
        },
        {
          id: "Compliance",
          text: 'Compliance'
        },
      ],

      environmentOptions: [
        {
          id: "Live",
          text: 'Live'
        },
        {
          id: "Sandbox",
          text: 'Sandbox'
        },
      ],

      connectionTypeOptions: [
        {
          id: "API",
          text: 'API'
        },
        {
          id: "Manual",
          text: 'Manual'
        },
      ],

      editing: [],

      openPopup: false,
      updateProvider: false,
      providerPopup: null
    }
  },

  computed: {
    countries() {
      let countries = window.fullCountries
      return countries.map(a=>({label: `${a.id} - ${a.text}`, value: a.id}));
    },

    providers () {
      return this.$store.state.providersList.data
    },

    statusOptions () {
      const options = [
        {
          id: 'Review',
          text: 'Review',
        },
        {
          id: 'Testing',
          text: 'Testing',
        },
        {
          id: 'Active',
          text: 'Active',
        },
        {
          id: 'Disabled',
          text: 'Disabled',
        },
        {
          id: 'Archive',
          text: 'Archive',
        }
      ]
      return options
    },

    yesNoPopOptions () {
      const options = [
        {
          id: "1",
          text: 'Yes',
        },
        {
          id: "0",
          text: 'No',
        }
      ]
      return options
    },
  },

  created () {
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  methods: {

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = {
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id,
            provider_name: this.form.provider_name,
            label: this.form.label,
            providerType: this.form.providerType,
            version: this.form.version,
            kyc: this.form.kyc,
            domWires: this.form.domWires,
            intlWires: this.form.intlWires,
            ach: this.form.ach,
            acct: this.form.acct,
            checks: this.form.checks,
            remoteDeposit: this.form.remoteDeposit,
            exchange: this.form.exchange,
            multiCurrency: this.form.multiCurrency,
            debitCards: this.form.debitCards,
            assets: this.form.assets,
            notes: this.form.notes,
            status: this.status,
            username: this.form.username,
            password: this.form.password,
            code: this.form.code,
            environment: this.form.environment,
            solutionId: this.form.solutionId,
            ledgerId: this.form.ledgerId,
            url: this.form.url,
            clientId: this.form.clientId,
            clientSecret: this.form.clientSecret,
            transactionCountries: this.form.transactionCountries,
            connectionType: this.form.connectionType,
          }
          
          this.$store.dispatch('providersList/create', payload)
          Object.keys(this.form).map(item => this.form[item] = null)
          this.$toast.success('Provider successfully created')
          this.$v.$reset()
        } catch {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    changeStatus(id) {
      this.open = true
      this.providerId = id
      const providerChange = this.providers.find(provider => provider.id === id)
      this.form.provider_name = providerChange.provider_name,
      this.form.version = providerChange.version,
      this.form.kyc =  providerChange.kyc,
      this.form.domWires =  providerChange.domWires,
      this.form.intlWires =  providerChange.intlWires,
      this.form.ach =  providerChange.ach,
      this.form.acct =  providerChange.acct,
      this.form.checks =  providerChange.checks,
      this.form.remoteDeposit =  providerChange.remoteDeposit,
      this.form.multiCurrency =  providerChange.multiCurrency,
      this.form.debitCards =  providerChange.debitCards,
      this.form.assets =  providerChange.assets,
      this.form.notes =  providerChange.notes
    },

    async updateStatus () {
      try {
        const payload = {
          provider_name: this.form.provider_name,
          version: this.form.version,
          kyc: this.form.kyc,
          domWires: this.form.domWires,
          intlWires: this.form.intlWires,
          ach: this.form.ach,
          acct: this.form.acct,
          checks: this.form.checks,
          remoteDeposit: this.form.remoteDeposit,
          multiCurrency: this.form.multiCurrency,
          debitCards: this.form.debitCards,
          assets: this.form.assets,
          notes: this.form.notes,
          status: this.status
        }
          this.$store.dispatch('providersList/update', { id: this.providerId, payload })
          this.$toast.success('Status successfully updated')
          this.open = false
        } catch {
          this.$toast.error(window.errorMessage)
        }
    },

    resetFields() {
      Object.keys(this.form).map(item => this.form[item] = null)
    },

    formatStatus(status) {
      return status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    edit(index) {
      this.editing.push(index)
    },

    detail(index) {
      this.providerId = index
      this.updateProvider = false
      this.providerPopup = this.providers.find(provider => provider.id === index)
      this.openPopup = true
    },

    async saveUpdate (i) {
      try {
        const payload = {
          id: this.providers[i].id,
          payload: {
            kyc: this.providers[i].kyc,
            domWires: this.providers[i].domWires,
            intlWires: this.providers[i].intlWires,
            ach: this.providers[i].ach,
            providerType: this.providers[i].providerType,
            acct: this.providers[i].acct,
            checks: this.providers[i].checks,
            remoteDeposit: this.providers[i].remoteDeposit,
            multiCurrency: this.providers[i].multiCurrency,
            debitCards: this.providers[i].debitCards,
            assets: this.providers[i].assets,
            notes: this.providers[i].notes,
            status: this.providers[i].status,
            username: this.providers[i].username,
            password: this.providers[i].password,
            code: this.providers[i].code,
            upd: this.providers[i].upd,
            url: this.providers[i].url,
            clientId: this.providers[i].clientId,
            clientSecret: this.providers[i].clientSecret,
            transactionCountries: this.providers[i].transactionCountries,
            connectionType: this.providers[i].connectionType
          }
        }

        await this.$store.dispatch('providersList/update', payload)
        this.$toast.success('Provider successfully updated.')
        this.cancelEdit(i)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async saveProvider () {
      const loader = this.$loading.show()
      try {
        const payload = {
          id: this.providerId,
          payload: {
            provider_name: this.providerPopup.provider_name,
            providerType: this.providerPopup.providerType,
            label: this.providerPopup.label,
            kyc: this.providerPopup.kyc,
            domWires: this.providerPopup.domWires,
            intlWires: this.providerPopup.intlWires,
            ach: this.providerPopup.ach,
            acct: this.providerPopup.acct,
            checks: this.providerPopup.checks,
            remoteDeposit: this.providerPopup.remoteDeposit,
            multiCurrency: this.providerPopup.multiCurrency,
            debitCards: this.providerPopup.debitCards,
            assets: this.providerPopup.assets,
            notes: this.providerPopup.notes,
            status: this.providerPopup.status,
            username: this.providerPopup.username,
            password: this.providerPopup.password,
            code: this.providerPopup.code,
            environment: this.providerPopup.environment,
            solutionId: this.providerPopup.solutionId,
            ledgerId: this.providerPopup.ledgerId,
            url: this.providerPopup.url,
            clientId: this.providerPopup.clientId,
            clientSecret: this.providerPopup.clientSecret,
            transactionCountries: this.providerPopup.transactionCountries,
            connectionType: this.providerPopup.connectionType,
            achEnable: this.achEnable,
            acctEnable: this.acctEnable,
            checksEnable: this.checksEnable,
            domWiresEnable: this.domWiresEnable,
            intlWiresEnable: this.intlWiresEnable,
            assetsEnable: this.assetsEnable,
            multiCurrencyEnable: this.multiCurrencyEnable,
            debitCardsEnable: this.debitCardsEnable,
            remoteDepositEnable: this.remoteDepositEnable,
          }
        }
        await this.$store.dispatch('providersList/update', payload)

        await api.action("ProvidersList", payload.id, "UpdateServices", payload.payload)

        this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
        this.$toast.success('Provider successfully updated.')
        this.updateProvider = false
        this.openPopup = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async deleteProvider (i) {
      try {
        await this.$store.dispatch('providersList/delete', this.providers[i].id)
        this.$toast.success('Provider successfully deleted.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async updUpdate (i) {
      try {
        const payload = {
          id: this.providers[i].id,
          payload: {
            upd: this.providers[i].upd
          }
        }

        await this.$store.dispatch('providersList/update', payload)
        this.$toast.success('Provider successfully updated.')
        this.cancelEdit(i)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
    },

    changeAch() {
      if(this.providerPopup.ach) {
        if(confirm('Enable this service for all accounts?')) {
          this.achEnable = true
        }
      }
    },

    changeAcct() {
      if(this.providerPopup.acct) {
        if(confirm('Enable this service for all accounts?')) {
          this.acctEnable = true
        }
      }
    },

    changeChecks() {
      if(this.providerPopup.checks) {
        if(confirm('Enable this service for all accounts?')) {
          this.checksEnable = true
        }
      }
    },

    changeDomWires() {
      if(this.providerPopup.domWires) {
        if(confirm('Enable this service for all accounts?')) {
          this.domWiresEnable = true
        }
      }
    },

    changeIntlWires() {
      if(this.providerPopup.intlWires) {
        if(confirm('Enable this service for all accounts?')) {
          this.intlWiresEnable = true
        }
      }
    },

    changeAssets() {
      if(this.providerPopup.assets) {
        if(confirm('Enable this service for all accounts?')) {
          this.assetsEnable = true
        }
      }
    },

    changeMultiCurrency() {
      if(this.providerPopup.multiCurrency) {
        if(confirm('Enable this service for all accounts?')) {
          this.multiCurrencyEnable = true
        }
      }
    },

    changeDebitCards() {
      if(this.providerPopup.debitCards) {
        if(confirm('Enable this service for all accounts?')) {
          this.debitCardsEnable = true
        }
      }
    },

    changeRemoteDeposit() {
      if(this.providerPopup.remoteDeposit) {
        if(confirm('Enable this service for all accounts?')) {
          this.remoteDepositEnable = true
        }
      }
    },
  },

  watch: {
    'providerPopup.ach' () {
      if(this.updateProvider) {
        this.changeAch()
      }
    },

    'providerPopup.acct' () {
      if(this.updateProvider) {
        this.changeAcct()
      }
    },

    'providerPopup.checks' () {
      if(this.updateProvider) {
        this.changeChecks()
      }
    },

    'providerPopup.domWires' () {
      if(this.updateProvider) {
        this.changeDomWires()
      }
    },

    'providerPopup.intlWires' () {
      if(this.updateProvider) {
        this.changeIntlWires()
      }
    },

    'providerPopup.assets' () {
      if(this.updateProvider) {
        this.changeAssets()
      }
    },

    'providerPopup.multiCurrency' () {
      if(this.updateProvider) {
        this.changeMultiCurrency()
      }
    },

    'providerPopup.debitCards' () {
      if(this.updateProvider) {
        this.changeDebitCards()
      }
    },

    'providerPopup.remoteDeposit' () {
      if(this.updateProvider) {
        this.changeRemoteDeposit()
      }
    },
  },

  validations () {
    const rules = {
      form: {
        provider_name: { required },
        providerType: { required },
        version: { required },
        kyc: { required },
        domWires: { required },
        intlWires: { required },
        ach: { required },
        acct: { required },
        checks: { required },
        remoteDeposit: { required },
        multiCurrency: { required },
        debitCards: { required },
        assets: { required },
        notes: { required }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px; 
  }
  .errors {
    color: #ff0000 !important;
  }
  // .status-options {
  //   margin-bottom: 30px; 
  // }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
  .updateAt {
    font-size: 10px;
    width: 155px;
  }
  .form-max {
    div {
      width: 100%;
    }
  }
  .url {
    width: 500px;
  }
  .services-split{
    display: flex;
    margin: 10px 0px;
  }
  .services-split div {
    display: flex;
    width: calc(50% - 100px);
    justify-content: space-between;
    margin-right: 100px;
  }
</style>
