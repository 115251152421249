<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>Update Card</h3>
    </div>
      <div class="scrollable">
        <template>
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
              <form-field-select v-model="form.cardStatus" label="Select Card Status" :validator="$v.form.cardStatus" :options="cardTypeOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-text v-model="form.cardLimit" label="Enter Card Limit" :validator="$v.form.cardLimit" required />
              <form-field-select v-model="form.cardLimitInterval" label="Card Limit Interval" :validator="$v.form.cardLimitInterval" :options="cardLimitIntervalOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="form.allowedCategories" label="Select Allowed Categories" :validator="$v.form.allowedCategories"  required />
              <form-field-select v-model="form.blockedCategories" label="Select Blocked Categories" :validator="$v.form.blockedCategories"  required />
            </div>
          </div>
        </template>
        <div class="buttons">
          <button class="main" @click="add()">Submit</button>
          <button class="second" @click="open = false">Cancel</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
  </popup>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    },

    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      form: {
        account: null,
        cardStatus: null,
        cardLimit: null,
        cardLimitInterval: null,
      },
      system: null,

      open: true,
      submitError: false,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    cardTypeOptions () {
      const options = [
        {
          id: 'active',
          text: 'Active',
          disabled: this.account.cards[this.account.cards.length-1].cardStatus == 'active'? true : false
        },
        {
          id: 'inactive',
          text: 'Inactive',
          disabled: this.account.cards[this.account.cards.length-1].cardStatus == 'inactive'? true : false
        }
      ]
      return options
    },

    cardBinOptions () {
      const options = [
        {
          id: 'debit',
          text: 'Debit',
        },
        {
          id: 'credit',
          text: 'Credit',
          disabled:true
        }
      ]
      return options
    },

    cardLimitIntervalOptions () {
      const options = [
        {
          id: 'daily',
          text: 'Daily',
        },
        {
          id: 'weekly',
          text: 'Weekly',
        },
        {
          id: 'monthly',
          text: 'Monthly',
        },
        {
          id: 'yearly',
          text: 'Yearly',
        },
        {
          id: 'allTime',
          text: 'All Time',
        },
        {
          id: 'perTransaction',
          text: 'Per Transaction',
        }
      ]
      return options
    },

    shipToOptions () {
      const options = [
        {
          id: 'Account Address',
          text: 'Account Address',
        },
        {
          id: 'Head Office',
          text: 'Head Office',
        }
      ]
      return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    accountOptions () {
      let accounts = []
      accounts.push({
        id: this.account.id,
        text: `${this.account.accountNumber}-${this.account.regName}`,
      })

      return accounts
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {

    },
  },

  methods: {
    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false
      const loader = this.$loading.show()
      let payload;

      const response = (await this.$store.dispatch('cards/action', {
        id: this.account.cards[this.account.cards.length-1].id,
        action: 'UpdateCard',
        payload: {
          account: this.form.account,
          cardStatus: this.form.cardStatus,
          cardLimit: this.form.cardLimit,
          cardLimitInterval: this.form.cardLimitInterval,
        }
      }))
      if(response.action.error) {
        this.$toast.error('Error: ' + response.action.errorMsg)
      }else{
        this.$store.dispatch('registrations/get', this.registration.id)
        this.open = false
        this.$toast.success('Card successfully updated.')
      }
      loader.hide()
    },
  },

  async created () {
    this.form.account = this.account.id
    this.system = JSON.parse(JSON.stringify(window.appData.system))
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        account: { required },
        cardStatus: { required },
      }
    }

    if(this.form.cardLimit) {
      rules.form.cardLimit = { decimal }
      rules.form.cardLimitInterval = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}

.address-line {
  margin: 0;
}
</style>