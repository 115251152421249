<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Account Settings</h2>
      <div class="status">
        Status
        <status-icon :color="profileSettings ? 'green' : null" />
      </div>
    </div>
    <table class="content-table" style="min-width: unset;">
      <tr>
        <th style="width: 15%;">Select Account</th>
        <th style="width: 12%;">ACH</th>
        <th style="width: 12%;">Account Transfers</th>
        <th style="width: 12%;">Checks</th>
        <th style="width: 12%;">Remote Deposit</th>
        <th style="width: 12%;">Domestic Wires</th>
        <th style="width: 12%;">International Wires</th>
        <th style="width: 12%;">Assets</th>
      </tr>
      <tr class="content-filters">
        <td><span>Services</span>
          <mnb-select v-if="!profileSettings" v-model="form.account" class="select" :options="accountOptions" :validator="$v.form.account" />
          <template v-else>{{ mainAccount.accountNumber }}</template>
        </td>
        <td><span>ACH</span>
          <template v-if="!profileSettings">
            <mnb-switch  v-if="provider.ach == 1" v-model="form.ach" :validator="$v.form.ach" :disabled="customEnv.noach || !form.account" />
            <mnb-switch  v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.ach == 1" disabled />
<!--           <template v-else>{{ form.ach | yesNo }}</template> -->
        </td>
        <td><span>Account to Account</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.acct == 1" v-model="form.acc_to_acc" :validator="$v.form.acc_to_acc" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled/>
          </template>
          <mnb-switch v-else :value="form.acc_to_acc == 1" disabled />
<!--           <template v-else>{{ form.acc_to_acc | yesNo }}</template> -->
        </td>
        <td><span>Checks</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.checks == 1" v-model="form.checks" :validator="$v.form.checks" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.checks == 1" disabled />
<!--           <template v-else>{{ form.checks | yesNo }}</template> -->
        </td>
        <td><span>Remote Deposit</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.remoteDeposit == 1" v-model="form.remoteDeposit" :validator="$v.form.remoteDeposit" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.remoteDeposit == 1" disabled />
<!--           <template v-else>{{ form.remoteDeposit | yesNo }}</template> -->
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.domWires == 1" v-model="form.domWires" :validator="$v.form.domWires" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.domWires == 1" disabled />
<!--           <template v-else>{{ form.domWires | yesNo }}</template> -->
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.intlWires == 1" v-model="form.intlWires" :validator="$v.form.intlWires" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.intlWires == 1" disabled />
<!--           <template v-else>{{ form.intlWires | yesNo }}</template> -->
        </td>
        <td><span>Assets</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="featureAssetsStatus && provider.assets == 1" v-model="form.assets" :validator="$v.form.assets" :disabled="!form.account" />
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.assets == 1" disabled />
          <!--           <template v-else>{{ form.assets | yesNo }}</template> -->
        </td>
      </tr>
      <tr class="content-filters">
        <td><span>Services</span> Transaction Limits</td>
        <td><span>ACH</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.ach_limit" class="input" label="Limit" no-style :disabled="customEnv.noach || provider.ach != 1 || !form.account" /></div>
          <template v-else>$ {{ form.ach_limit }}</template>
        </td>
        <td><span>Account to Account</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.acc_to_acc_limit" class="input" label="Limit" no-style :disabled="provider.acct != 1 || !form.account" /></div>
          <template v-else>$ {{ form.acc_to_acc_limit }}</template>
        </td>
        <td><span>Checks</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.checks_limit" class="input" label="Limit" no-style :disabled="provider.checks != 1 || !form.account" /></div>
          <template v-else>$ {{ form.checks_limit }}</template>
        </td>
        <td><span>Remote deposit</span>
<!--          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.checks_limit" class="input" label="Limit" no-style :disabled="provider.remoteDeposit != 1 || !form.account" /></div>
          <template v-else>$ {{ form.checks_limit }}</template>-->
        </td>
        <td><span>Wire Transfer</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.domWires_limit" class="input" label="Limit" no-style :disabled="provider.domWires != 1 || !form.account" /></div>
          <template v-else>$ {{ form.domWires_limit }}</template>
        </td>
        <td><span>Wire Transfer</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.intlWires_limit" class="input" label="Limit" no-style :disabled="provider.intlWires != 1 || !form.account" /></div>
          <template v-else>$ {{ form.intlWires_limit }}</template>
        </td>
        <td><span>Assets</span>
          <template v-if="!featureAssetsStatus">
            <div v-if="!profileSettings" class="money-input"><span></span> <mnb-input v-if="!profileSettings" v-model="form.assets_limit" class="input" label="Limit" no-style :disabled="true" /></div>
            <template v-else>{{ form.assets_limit }}</template>
          </template>
          <template v-else>
            <div v-if="!profileSettings" class="money-input"><span></span> <mnb-input v-if="!profileSettings" v-model="form.assets_limit" class="input" label="Limit" no-style :disabled="provider.assets != 1 || !form.account" /></div>
            <template v-else>{{ form.assets_limit }}</template>
          </template>
        </td>
      </tr>
    </table>
    <table class="content-table">
      <tr>
        <th style="width: 15%;"></th>
        <th style="width: 12%;">Multi-Currency</th>
        <th style="width: 12%;">Debit Cards</th>
        <th colspan="5"></th>
      </tr>
      <tr>
        <td>Enable</td>
        <td><span>Multi-currency</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="featureFundsStatus && provider.multiCurrency == 1" v-model="form.multiCurrency" :validator="$v.form.multiCurrency" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.multiCurrency == 1" disabled />
<!--           <template v-else>{{ form.multiCurrency | yesNo }}</template> -->
        </td>
        <td><span>Debit Cards</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="provider.debitCards == 1" v-model="form.debitCards" :validator="$v.form.debitCards" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.debitCards == 1" disabled />
<!--           <template v-else>{{ form.debitCards | yesNo }}</template> -->
        </td>
        <td colspan="5"></td>
      </tr>
      <tr class="content-filters">
        <td>Transaction Limits</td>

        <td><span>Multi Currency</span>
          <template v-if="!featureFundsStatus">
            <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.multi_currency_limit" class="input" label="Limit" no-style :disabled="true" /></div>
            <template v-else>$ {{ form.multi_currency_limit }}</template>
          </template>
          <template v-else>
            <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.multi_currency_limit" class="input" label="Limit" no-style :disabled="provider.multiCurrency != 1 || !form.account" /></div>
            <template v-else>$ {{ form.multi_currency_limit }}</template>
          </template>
        </td>
        <td><span>Debit Cards</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.debit_cards_limit" class="input" label="Limit" no-style :disabled="provider.debitCards != 1 || !form.account" /></div>
          <template v-else>$ {{ form.debit_cards_limit }}</template>
        </td>
        <td colspan="5"></td>
      </tr>
    </table>
    <table class="content-table">
      <tr>
        <th style="width: 15%;">System Services</th>
        <th style="width: 12%;">Exchange Desk</th>
        <th style="width: 12%;">Trade Desk</th>
      </tr>
      <tr class="content-filters">
        <td><span>Services</span> Enable</td>
        <td><span>Exchange</span>
          <mnb-switch v-if="!profileSettings" :value="false" disabled />
          <mnb-switch v-else :value="form.exchange == 1" disabled />
<!--           <template v-else>{{ form.exchange | yesNo }}</template> -->
        </td>
        <td><span>Trade Desk</span>
          <template v-if="!profileSettings">
            <mnb-switch v-if="form.assets" v-model="form.trade_desk" :validator="$v.form.trade_desk" :disabled="!form.account"/>
            <mnb-switch v-else :value="false" disabled />
          </template>
          <mnb-switch v-else :value="form.trade_desk == 1" disabled />
<!--           <template v-else>{{ form.trade_desk | yesNo }}</template> -->
        </td>
        <td colspan="7">
        </td>
      </tr>
      <tr class="content-filters">
        <td><span>Services</span> Transaction Limits</td>
        <td><span>Exchange</span>
          <div v-if="!profileSettings" class="money-input"><span>$</span> <mnb-input v-if="!profileSettings" v-model="form.exchange_limit" class="input" label="Limit" no-style :disabled="!form.multiCurrency || !form.account" /></div>
          <template v-else>$ {{ form.exchange_limit }}</template>
        </td>
        <td><span>Trade Desk</span>
          <div v-if="!profileSettings" class="money-input"><span></span> <mnb-input v-if="!profileSettings" v-model="form.trade_desk_limit" class="input" label="Limit" no-style :disabled="!form.assets || !form.account" /></div>
          <template v-else>{{ form.trade_desk_limit }}</template>
        </td>
        <td colspan="7">
        </td>
      </tr>
      <template v-if="this.registration.accounts.length == 1">
        <tr class="space">
          <td colspan="11">
            <h2 class="profile-settings">Profile Settings</h2>
          </td>
        </tr>
        <tr>
          <th style="width: 15%;">Verifications</th>
          <th style="width: 12%;">Administrative</th>
          <th style="width: 12%;">Remote Deposit Capture</th>
          <th style="width: 12%;" v-if="!isPersonal">Double Verification</th>
        </tr>
        <tr class="content-filters">
          <td><span>Verifications</span> Enable</td>
          <td><span>Administrative</span>
            <mnb-switch v-if="!profileSettings" v-model="form.administrative" :validator="$v.form.administrative" :disabled="!form.account"/>
            <mnb-switch v-else :value="form.administrative == 1" disabled />
<!--             <template v-else>{{ form.administrative | yesNo }}</template> -->
          </td>
          <td>
            <mnb-switch v-if="!profileSettings" v-model="form.remoteDepositCapture" :validator="$v.form.remoteDepositCapture" :disabled="!form.account"/>
            <mnb-switch v-else :value="form.remoteDepositCapture == 1" disabled />
<!--             <template v-else>{{ form.remoteDepositCapture | yesNo }}</template> -->
          </td>
          <td v-if="!isPersonal"><span>Double Verification</span>
            <mnb-switch v-if="!profileSettings" v-model="form.double_verification" :validator="$v.form.double_verification" :disabled="!isPersonal || !form.account" />
            <mnb-switch v-else :value="form.double_verification == 1" disabled />
<!--             <template v-else>{{ form.double_verification | yesNo }}</template> -->
          </td>
          <td colspan="8">
          </td>
        </tr>
        <tr class="space">
          <th style="width: 15%;">Permissions</th>
          <th style="width: 12%;">Create Sub-Users</th>
          <th style="width: 12%;"v-if="isMasterBusiness">Create Sub-Profiles</th>
        </tr>
        <tr class="content-filters">
          <td><span>Permissions</span> Enable</td>
          <td><span>Create Sub-Users</span>
            <mnb-switch v-if="!profileSettings" v-model="form.create_subusers" :validator="$v.form.create_subusers" :disabled="!form.account"/>
            <mnb-switch v-else :value="form.create_subusers == 1" disabled />
<!--             <template v-else>{{ form.create_subusers | yesNo }}</template> -->
          </td>
          <td v-if="isMasterBusiness"><span>Create Sub-Profiles</span>
            <mnb-switch v-if="!profileSettings" :value="form.create_subprofiles" :validator="$v.form.create_subprofiles" disabled />
            <mnb-switch v-else :value="form.create_subprofiles == 1" disabled />
<!--             <template v-else>{{ form.create_subprofiles | yesNo }}</template> -->
          </td>
          <td colspan="7">
          </td>
        </tr>
        <tr class="space">
          <th style="width: 15%;">Options</th>
          <th style="width: 12%;">Send Welcome Email</th>
          <th style="width: 12%;">Profile Review Days</th>
          <th style="width: 12%;">Profile Language</th>
        </tr>
        <tr class="content-filters">
          <td></td>
          <td><span>Send Welcome/Activation Email</span>
            <mnb-switch v-if="!profileSettings" v-model="form.welcome_email" :validator="$v.form.welcome_email" :disabled="!form.account"/>
            <mnb-switch v-else :value="form.welcome_email == 1" disabled />
<!--             <template v-else>{{ form.welcome_email | yesNo }}</template> -->
          </td>
          <td><span>Profile Review Days</span>
            <mnb-select v-if="!profileSettings" v-model="form.review_days" :validator="$v.form.review_days" class="select" :options="reviewOptions" :disabled="!form.account"/>
            <template v-else>{{ form.review_days }}</template>
          </td>
          <td ><span>Profile Language</span>
            <mnb-select v-if="!profileSettings" v-model="form.language" :validator="$v.form.language" class="select" :options="languageOptions" :disabled="!form.account"/>
            <template v-else>{{ form.language }}</template>
          </td>
          <td colspan="7">
          </td>
        </tr>
      </template>
    </table>

    <div class="footer space">
      <span>
        <b>Date / Time:</b><br>
        <template v-if="!profileSettings">N/A</template>
        <template v-else>{{ profileSettings.createdAt | date('LL') }}<br>{{ profileSettings.createdAt | date('LT') }}</template>
      </span>
      <div v-if="registration.status !== 'Complete'">
        <button v-if="!profileSettings" class="main" @click="create()" :disabled="!form.account">Create</button>
        <button v-else-if="registration.status != 'Active'" class="second" @click="remove()" :disabled="!form.account">Remove</button>
      </div>
    </div>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'
import MnbInput from '../../../components/ui/forms/Input'
import MnbSelect from '../../../components/ui/forms/Select'
import MnbSwitch from '../../../components/ui/forms/Switch'

import { required } from 'vuelidate/lib/validators'
import Templates from '../../transactions/Templates.vue'

const initialForm = {
  domWires: false,
  intlWires: false,
  ach: false,
  checks: false,
  exchange: false,
  acc_to_acc: false,
  assets: false,
  trade_desk: false,
  multiCurrency: false,
  remoteDeposit: false,
  debitCards: false,
  domWires_limit: null,
  intlWires_limit: null,
  ach_limit: null,
  checks_limit: null,
  exchange_limit: null,
  acc_to_acc_limit: null,
  assets_limit: null,
  trade_desk_limit: null,
  multi_currency_limit: null,
  debit_cards_limit: null,

  administrative: false,
  remoteDepositCapture: false,
  double_verification: false,

  create_subusers: false,
  create_subprofiles: false,

  welcome_email: false,
  review_days: null,
  language: null,

  account: null
}

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch,
    StatusIcon
  },

  data () {
    return {
      form: { ...initialForm },

      reviewOptions: [
          {
            id: 30,
            text: 30
          },
          {
            id: 60,
            text: 60
          },
          {
            id: 90,
            text: 90
          },
          {
            id: 180,
            text: 180
          },
          {
            id: 365,
            text: 365
          }
        ],

        languageOptions: [
          {
            id: 'English',
            text: 'English'
          }
        ],
        customEnv: appData.customEnv,
        featureFundsStatus: null,
        featureAssetsStatus: null,
    }
  },

  computed: {
    isPersonal () {
      return this.registration?.type === 'Personal'
    },

    isMasterBusiness () {
      return this.registration?.type === 'Master Business'
    },

    profileSettings () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.profileSettings || null
    },

    mainAccount () {
      return this.registration.accounts[this.registration.accounts.length-1] || null
    },

    provider () {
      return this.providers.find(provider => provider.id === this.registration.accounts[this.registration.accounts.length-1].serviceProviders.id)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    accountOptions () {
      return [
        {
          id: this.registration.accounts[this.registration.accounts.length-1]?.id,
          text: this.registration.accounts[this.registration.accounts.length-1]?.accountNumber
        }
      ]
    }
  },

  async created () {
    await this.getFeatureStatus();
    if (this.profileSettings) {
      this.form = this.profileSettings
    } else if (!this.isPersonal) {
      this.form.double_verification = false
    }
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  methods: {
    async create () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const loader = this.$loading.show()
      try {

        await this.$store.dispatch('accounts/update', {
          id: this.registration?.accounts[this.registration.accounts.length-1]?.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            profileSettings: {
              ...this.form,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        await this.$store.dispatch('registrations/get', this.registration.id)
        await this.addNote('Profile Settings created')
        this.$toast.success('Profile Settings successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('reg_profile_settings/delete', this.profileSettings.id)
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: { updatedAt: 'CURRENT_TIMESTAMP', profileSettings: null }
        })
        await this.addNote('Profile Settings removed')
        this.form = { ...initialForm }
        this.$toast.success('Profile Settings successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async getFeatureStatus () {
      this.featureAssetsStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
    },
  },

  validations () {
    const rules = {
      form: {
        domWires: { required },
        intlWires: { required },
        ach: { required },
        checks: { required },
        exchange: { required },
        acc_to_acc: { required },
        assets: { required },
        trade_desk: { required },
        multiCurrency: { required },
        debitCards: { required },
        trade_desk: { required },
        account: { required },
      }
    }

    if(this.registration.accounts.length == 1){
      rules.form.administrative = { required }
      rules.form.remoteDepositCapture = { required }
      rules.form.create_subusers = { required }
      rules.form.welcome_email = { required }
      rules.form.review_days = { required }
      rules.form.language = { required }
      rules.form.account = { required }
    }

    if (!this.isPersonal) {
      rules.form.double_verification = { required }
      rules.form.create_subprofiles = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}

.checkbox-large {
  width: 17px;
  height: 17px;
}

.money-input {
  display: flex;
  span {
    display: inline-block;
    width: 15px;
    line-height: 29px;
  }
  .input {
    width: 100px;
  }
}
.select {
  width: 115px;
}

.space {
  td, th {
    padding-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 0 34px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.profile-settings {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
</style>
