<template>
  <div>
    <div v-if="loading">Loading...</div>
    <section v-if="!loading">
      <div style="padding: 24px 30px 10px 10px;" v-if="closed">
        All profiles listed below are closed
      </div>
      <div style="padding: 24px 30px 10px 10px;">
        <button class="btn btn-success" @click.prevent="closeAll">
          Close all shown profiles
          {{ progress?'('+progress+'/'+registrations.length+')':'' }}
        </button>
      </div>

      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Date</th>
            <th>Profile</th>
            <th>Name</th>
            <th>Profile Type</th>
            <th>Status</th>
          </tr>
            <tr v-for="registration in registrations" :key="registration.id">
              <td><span>Registration Date</span>
                {{ registration.createdAt | date('MM/D/YYYY') }}<br/>
                {{ registration.createdAt | date('hh:mm A') }}
              </td>
              <td><span>Profile</span>
                <a>{{ registration.profileNumber || registration.regId }}</a>
              </td>
              <td>
                <span>Name</span>
                <template v-if="registration.data">
                  <template v-if="registration.type === 'Personal'">{{ registration.data.firstName }} {{ registration.data.lastName }}</template>
                  <template v-else>{{ registration.data.legal_name }}</template>
                </template>
              </td>
              <td><span>Profile Type</span>{{ registration.type }}</td>
              <td><span>Status</span><em>{{ registration.status }}</em></td>
            </tr>

        </table>
      </section>

    </section>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    registrations: [],
    loading: true,
    progress: 0,
    closed: false,
  }),
  methods: {
    async loadRegistrations() {
      this.registrations=(await api.list("Registration", {status: ["IN ('Review', 'Restricted', 'Hold', 'Dormant', 'Activation', 'Complete')"]})).items;
      this.loading=false;
    },
    async closeAll() {
      if (confirm("Are you sure?")) {
        for(var i in this.registrations) {
          var registration=this.registrations[i];
          this.progress=i*1+1;
          await api.update("Registration", registration.id, {status: "Closed"});
        }
        this.progress=0;
        this.closed=true;
      }
    },
  },
  mounted() {
    this.loadRegistrations();
  },
}
</script>
