<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <template v-if="featureStatus ==null"></template>
    <template v-else>
      <template v-if="!featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - MailCast</h3>
      </template>
      <template v-else>
        <div class="content-filters" v-if="perms">
          <basic-form>
            <label>Mail Type</label>
            <mnb-select v-model="form.mailType" :options="mailTypeOptions" :validator="$v.form.mailType" />
            <br/>
            <label>Recipients</label>
            <mnb-select v-model="form.recipients" :options="recipientsOption" :validator="$v.form.recipients" />
            <br/>
            <mnb-input v-model="form.emailSubject" label="Email Subject" :validator="$v.form.emailSubject" :maxlength="150"/>
            <br/>
            <label v-if="form.mailType=='Text'">Email Text</label>
            <div v-if="form.mailType=='Text'" :style="$v.form.emailText.$invalid? `border: 1px solid red;` : `` " key="mail-text-field">
              <block-edit v-model="form.emailText" placeholder="" :editable="true" :validator="$v.form.emailText" />
            </div>
            <span v-if="form.mailType=='Text' && $v.form.emailText.$invalid" class="errors">
              <span class="help-block text-danger">This field is required.</span>
            </span>
            <label v-if="form.mailType=='Image'">
              Photo:
            </label>
            <div v-if="form.mailType=='Image'" class="mailcast-photo-field" key="photo-field">
              <form-field-upload label="" v-model="form.photo" :validator="$v.form.photo" />
            </div>
            <span v-if="form.mailType=='Image'" class="">
              <span class="help-block">(Recommended size: width 935px height any)</span>
            </span>
            <div v-if="form.mailType=='Image' && form.photo">
              <img :src="formBlob" style="max-width: 935px; display: block; margin: 20px auto">
            </div>
            <br/>
            <br/>
            <div class="cof-row">
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="action-button save" @click="send()">Send</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="action-button cancel" @click="reset()">Cancel</button>
              </div>
            </div>
          </basic-form>
        </div>
        <section class="content-filters">
          <div class="cof-box">
            <div class="cof-row">
              <div class="cof-field">
                <label>Date Search</label>
                <div class="cof-multi flex">
                  <date-time-picker
                    v-model="searchForm.fromDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    :max-date="today" >
                  </date-time-picker>
                  <span>To</span>
                  <date-time-picker
                    v-model="searchForm.toDate"
                    value-type="String"
                    value-format="yyyy-LL-dd"
                    format="yyyy-LL-dd"
                    :time-picker="false"
                    :auto-close="true"
                    :max-date="today" >
                  </date-time-picker>
                  &nbsp;
                  <button class="main" @click="search()">Search</button>
                  &nbsp;
                  <button class="second" @click="resetItem()">Reset Filters</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th style="width: 5%;">ID</th>
              <th style="width: 10%;">Date</th>
              <th style="width: 20%;">Sender</th>
              <th style="width: 15%;">Recipients</th>
              <th style="width: 35%;">Email Subject</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
            <tr v-for="(item, i) in mailcasts" :key="i">
              <td><span>Id</span><a @click="openModal(item)">{{ item.id }}</a></td>
              <td><span>Date</span>
                {{ item.createdAt | date('MMMM D, YYYY') }}
                <br/>
                {{ item.createdAt | date('hh:mm A') }}
              </td>
              <td>
                <span>Sender</span>
                {{ item.sender }}
              </td>
              <td>
                <span>Recipients</span>
                {{ item.recipients }}
              </td>
              <td>
                <span>Email Subject</span>
                {{ item.emailSubject }}
              </td>
              <td>
                <span>Status</span>
                <div><em :class="statusColorClasses[item.messageStatus]">{{ item.messageStatus }} {{ item.messageStatus=='Sending'?mailcastProgress(item):"" }}</em></div>
              </td>
              <td>
                <span>Options</span>
              </td>
            </tr>
          </table>
        </section>
        <popup :open.sync="addModal">
          <div class="popup-header">
            <h3>MailCast Message Detail</h3>
            <h3 v-if="selectedItem">{{ selectedItem.id }}</h3>
            <h3 v-if="selectedItem">Status: {{ selectedItem.messageStatus }}</h3>
          </div>
          <template>
            <div class="scrollable">
              <div class="content-form">
                <div class="form-row form-three border-line" v-if="selectedItem">
                  <h3>{{ selectedItem.createdAt | date('MMMM D, YYYY hh:mm A')}}</h3>
                  <h3>{{ selectedItem.sender }}</h3>
                  <h3>{{ selectedItem.recipients }}</h3>
                </div>
                <br/>
                <div class="border-line" v-if="selectedItem">
                  <p><b>Email Subject</b></p>
                  <br/>
                  <p v-if="selectedItem">{{ selectedItem.emailSubject }}</p>
                  <br>
                </div>
                <br/>
                <div class="border-line" v-if="selectedItem">
                  <p><b>Email Message</b></p>
                  <br/>
                  <div class="plain-content" v-html="selectedItem.emailText"> </div>
                  <br/>
                </div>
              </div>
            </div>
            <div class="buttons text-right">
              <button class="second" @click="closeModal()">Close</button>
            </div>
          </template>
        </popup>
        <section class="popup" :class="{open: confirmModal}">
          <div class="pop-inner" :style="{ maxWidth: `400px` }">
            <div class="pop-box">
              <h2>MailCast Confirmation</h2>
              <div class="pf-row">
                <div class="pf-field">
                  Are you sure that you want to send the email {{ form.emailSubject }} to {{ form.recipients }}?
                </div>
              </div>
              <div class="pf-row buttons">
                <a href="" class="pop-btn" @click.prevent="confirmModal = false">Cancel</a>
                <button class="pop-btn" @click="save()">Confirm</button>
              </div>
            </div>
          </div>
        </section>
      </template>
      <div class="loading-counter" v-if="loadingCount">
        {{ loadingProgress+1 }} / {{ loadingCount }}
      </div>
    </template>
  </div>
</template>

<script>
  import { required, maxLength } from 'vuelidate/lib/validators'
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import MnbUploadInput from '../../components/ui/forms/UploadInput'
  import MnbTextarea from '../../components/ui/forms/Textarea'
  import FormFieldUpload from '../../components/FormFieldUpload'

  import mixinAutoResize from "../../components/ui/forms/autoresize.js"
  import inputMixin from "../../components/ui/forms/input-mixin.js"
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    mixins: [mixinAutoResize],

    components: {
      MnbInput,
      BasicForm,
      MnbSelect,
      MnbUploadInput,
      DateTimePicker,
      FormFieldUpload,
    },

    data () {
      return {
        form: {
          mailType: null,
          recipients: null,
          emailSubject: null,
          emailText: null,
          photo: null,
        },
        formBlob: null,
        updateInformations: false,
        today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
        featureStatus: null,
        addModal: false,
        confirmModal: false,
        selectedItem: null,

        recipientsOption : [
          {
            'id': 'All',
            'text': 'All',
            'disabled': true,
          },
          {
            'id': 'Employees Only',
            'text': 'Employees Only'
          },
          {
            'id': 'Customers Only',
            'text': 'Profiles Only'
          },
          {
            'id': 'Partners Only',
            'text': 'Partners Only'
          },
          {
            'id': 'Dormant Accounts',
            'text': 'Dormant Accounts'
          },
        ],
        mailTypeOptions: [
          { 'id': 'Text', 'text': 'Text' },
          { 'id': 'Image', 'text': 'Image' },
        ],
        loadingCount: 0,
        loadingProgress: 0,
        searchForm: {
          fromDate: null,
          toDate: null,
        },
        appliedFilters: null,
        perms: window.appData.currentUser?.role?.perms?.includes('mailcast') || window.appData.currentUser?.perms?.includes('mailcast')
    }
    },

    computed: {
      mailcasts () {
        let data = this.$store.state.mailcast.data || []
        if (!this.appliedFilters) {
          data = data.filter(item => {
            return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= moment("00:00:00", "HH:mm:ss")
          })
        }else{
          if (this.appliedFilters.fromDate) {
            const from = moment(this.appliedFilters.fromDate, 'YYYY-MM-DD')
            data = data.filter(item => {
              return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') >= from
            })
          }

          if (this.appliedFilters.toDate) {
            const to = moment(this.appliedFilters.toDate, 'YYYY-MM-DD')
            data = data.filter(item => {
              return moment(item.createdAt.split(' ')[0], 'YYYY-MM-DD') <= to
            })
          }
        }
        return data
      },

      statusColorClasses () {
        return {
          Sent: 'c1',
          Resent: 'c2',
          Archived: 'c2',
          Error: 'c3'
        }
      },
    },

    watch: {
      "form.photo": function() {
        console.log("Photo change", this.form.photo);
        if (!this.form.photo || !this.form.photo.size)
          this.formBlob = null;
        var reader = new FileReader();
        reader.onload = () => {
          this.formBlob=reader.result;
        }
        reader.readAsDataURL(this.form.photo);
      },
    },

    async created () {
      await this.$store.dispatch('mailcast/load', { order: ['createdAt:DESC'] })
      await this.getFeatureStatus();
    },

    methods: {
      mailcastProgress(item) {
        var recipients=item.recipientList || [];
        if (!recipients.length)
          return "";
        return "("+recipients.filter(a=>a.sent).length+"/"+recipients.length+")";
      },

      search() {
        this.appliedFilters = {...this.searchForm}
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.searchForm).forEach(key => this.searchForm[key] = null)
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['MailCast']
      },

      send() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.confirmModal = true
        }
      },

      async save () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const loader = this.$loading.show()
          try {
            this.confirmModal = false
            if (this.form.mailType == 'Text') {
              const payload = {
                recipients: this.form.recipients,
                emailSubject: this.form.emailSubject,
                emailText: this.form.emailText,
                sender: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
              }

              var actionResult=(await api.action("Mailcast", "new", "Send", payload));
            } else if (this.form.mailType == 'Image') {
              const payload = {
                recipients: this.form.recipients,
                emailSubject: this.form.emailSubject,
                emailText: null,
                sender: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
              }

              var actionResult=(await api.action("Mailcast", "new", "Send", payload));
              await api.upload("Mailcast", actionResult.item.id, this.form.photo);
            }
            await this.$store.dispatch('mailcast/load', { order: ['createdAt:DESC'] })
            this.$toast.success('MailCast successfully created.')
            loader.hide()
            return;
            if (actionResult.action && actionResult.action.recipientCount) {
              this.loadingCount=actionResult.action.recipientCount;
              await new Promise((resolve)=>{
                for(let i=0;i<this.loadingCount;i++) {
                  setTimeout(async ()=>{
                    this.loadingProgress=i;
                    await api.action("Mailcast", actionResult.item.id, "sendOne", {index: i});
                    if (i==this.loadingCount-1) {
                      resolve();
                    }
                  }, 100+i*500);
                }
              });
            }
            this.loadingProgress=this.loadingCount=0;
            await this.$store.dispatch('mailcast/load', { order: ['createdAt:DESC'] })
            this.$toast.success('MailCast successfully sent.')
            this.form.recipients = null
            this.form.emailSubject = null
            this.form.emailText = ''
            this.$v.$reset()
            
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      reset() {
        this.form.recipients = null
        this.form.emailSubject = null
        this.form.emailText = ''
      },

      openModal (item) {
        this.addModal = true
        this.selectedItem = item
      },

      closeModal () {
        this.addModal = false
        this.selectedItem = null
      },

    },

    validations() {
      const rules = this.form.mailType == 'Image' ? {
        form: {
          mailType: { required },
          photo: { required },
          recipients: { required },
          emailSubject: { required, maxLength: maxLength(150) },
        }
      } : {
        form: {
          mailType: { required },
          recipients: { required },
          emailSubject: { required, maxLength: maxLength(150) },
          emailText: { required, maxLength: maxLength(3000) },
        }
      }
      return rules
    }
  }
</script>

<style scoped>
.border-line {
  border-bottom: 1px solid #b9b9b9;
  padding-bottom: 10px;
}

a:hover {
  cursor: pointer;
}
.loading-counter {
  z-index: 10005;
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-tlist {
  border: none;
}
.content-filters .cof-box {
  background: none;
}
</style>
