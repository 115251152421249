<template>
<div>
<div class="popup" :class="{open: show}">
  <div class="pop-inner">
    <div class="pop-box">
      <a class="close" @click="$parent.currentRole=null"></a>
      <h2 class="modal-title">Edit Role</h2>
        <form>
          <div class="pf-field">
            <label>Role name</label>
            <input type="text" class="" v-model="currentRole.name" />
          </div>
          <div class="pf-field">
            <label>Group</label>
            <dyn-select class="" v-model="currentRole.group" :options="roleGroups" />
          </div>
          <div class="pf-field">
            <label>Parent role</label>
            <dyn-select class="" v-model="currentRole.parent" :options="rolesList" />
          </div>
          <div class="pf-field">
            <label>Permissions</label>
          </div>
          <div class="pf-row">
            <permissions v-model="currentRole.perms" />
          </div>
        </form>
      <div class="pf-row buttons">
        <a class="pop-btn" @click.prevent="$parent.currentRole=null">Close</a>
        <button type="button" class="pop-btn" @click.prevent="saveRole()">Save</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  props: ["role", "roles"],
  data: ()=>({
    currentRole: {perms: []},
    roleGroups: [],
    show: false,
  }),
  computed: {
    rolesList() {
      var result=this.roles.map((a)=>({
        value: a.id,
        label: a.name,
      }));
      result.unshift({
        value: null,
        label: "(no parent)",
      });
      return result;
    },
  },
  methods: {
    async loadRoleGroups() {
      this.roleGroups=(await api.list("RoleGroup")).items.map((a)=>({
        value: a.id,
        label: a.name,
      }));
    },
    async saveRole() {
      var role=JSON.parse(JSON.stringify(this.currentRole));
      if (role.id)
        await api.update("Role", role.id, role);
      else
        await api.create("Role", role);
      this.$parent.currentRole=null;
      this.$parent.loadRoles();
    },
  },
  mounted() {
    this.currentRole=JSON.parse(JSON.stringify(this.role));
    this.currentRole.group=this.currentRole.group && this.currentRole.group.id;
    this.loadRoleGroups();
    this.$nextTick(()=>this.show=true);
  },
}
</script>
