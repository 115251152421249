<template>
  <div v-if="loading">
    <section class="content-filters" style="margin: 0 0 30px 0;">
      <div class="flex-between">
        <h2 class="inner-headers">Customer Accounts</h2>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Date</th>
          <th>Account</th>
          <th>Currency</th>
          <th>Type</th>
          <th>Name</th>
          <th>Status</th>
        </tr>
        <tr v-for="account in accounts" :key="account.id">
          <td><span>Account Date</span>{{ account.createdAt | date('MM/D/YYYY') }}</td>
          <td><span>Account ID</span>
            <a :href="`/system-customers/registrations/${account.regId}`">{{ account.accountNumber }}</a>
          </td>
          <td><span>Currency</span>{{ account.currency }}</td>
          <td><span>Account Type</span>{{ account.regType }}</td>
          <td>
            <span>Name</span>
            {{ account.regName }}
          </td>
          <td><span>Account Status</span><em v-if="account" :class="statusColorClasses[getStatus(account.accountStatus)]">
            {{ getStatus(account.accountStatus) }}</em></td>
        </tr>
        <tr v-if="!accounts.length">
          <td colspan="6" class="no-data">No data</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>

export default {
  components: {
   
  },

  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    loading: false,
  }),

  computed: {
    statusColorClasses () {
      return {
        Open: 'c1',
        Restricted: 'c2',
        Pending: 'c2',
        Closed: 'c4',
        Dormant: 'c3',
        Hold: 'c2'
      }
    },

    accounts () {
      let accounts = this.$store.state.accounts.data || []
      return accounts
    },
  },

  watch: {
   
  },

  async created () {
    const loader = this.$loading.show()
    await this.refreshAccounts();
    loader.hide()
    this.loading = true
  },

  methods: {
    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    async refreshAccounts () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.regId=["!=", 1];

      if(this.partner) {
        params.partnerId = this.partner.id
      }
      await this.$store.dispatch('accounts/load', { order: ['createdAt:DESC'], params, skip: ['primeAccount', 'solidAccount', 'serviceProviders', 'assetsWallets', 'fundsCurrencies', 'complianceScan', 'profileSettings', 'cards', 'documents', 'primeAccount', 'currencyName', 'regCorp', 'regAdministrative', 'regReviewDays'] })
      this.loading=false;
      loader.hide()
    },
  },

  validations () {
    
  }
}
</script>

<style lang="scss" scoped>
  .content-table .title{
    color: #99aabb;
    font-weight: bold;
  }
</style>
