<template>
  <popup :open.sync="confirmOpen" class="confirm-popup">
    <h2>Confirm</h2>
    <form class="pop-form">
      <div class="pf-row split">
        <h5>Are you sure you want to create a New Employee?</h5>
      </div>
      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="confirmOpen=false">Cancel</a>
        <button class="pop-btn" @click.prevent="createEmployee">OK</button>
      </div>
    </form>
  </popup>
</template>
<script>
export default {
  data: ()=>({
    confirmOpen: false,
  }),
  methods: {
    show() {
      this.confirmOpen=true;
    },

    async createEmployee() {
      this.confirmOpen = false;
      window.employeePopup.createEmployee();
    }

  },
  mounted() {
    window.employeeConfirmPopup=this;
  },
}
</script>
