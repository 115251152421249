<template>
  <div>
    <template v-if="open">
      <section>
        <div class="flex-between">
          <div style="margin-top:15px">
          <button class="btn btn-success" @click="openTransModal('deposit')" v-if="featureAdvanceDepositNoticeStatus && $hasPerm('corporate-deposit')"><i class="el-icon-plus" /> Deposit</button>
          <button v-if="featureRemoteDeposit" class="btn btn-success" @click="openRemoteDeposit()"><i class="el-icon-plus" /> Remote Deposit</button>
          <!-- <button class="btn btn-success" @click="openTransModal('withdraw')" v-if="$hasPerm('corporate-withdrawal')"><i class="el-icon-minus" /> Withdrawal</button> -->
          <!-- <button class="btn btn-secondary" @click="openPayment()" v-if="$hasPerm('corporate-deposit')"><i class="el-icon-document" /> Deposit Details</button> -->
          </div>
          <div style="padding: 24px 30px 10px 10px;">
            <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
          </div>
        </div>
      </section>

      <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

      <section>
        
      </section>
      <add-deposit-withdrawal-panel
        v-if="modalType"
        :modal-type="modalType"
        @close="modalType = null" />
      <payment-details-panel
        v-if="modalPaymentType"
        :modal-payment-type="modalPaymentType"
        @close="modalPaymentType = null" />
      <bank-lookup-panel
        v-if="modalBankType"
        :modal-bank-type="modalBankType"
        @close="modalBankType = null" />
      <add-remote-deposit-modal
        v-if="modalDepositType"
        :modal-type="modalDepositType"
        @close="modalDepositType = null" />

      <section v-if="$hasPerm('update-transaction')">
        <div class="text-center" v-if="currentUser.role.group.id==1">
          <button v-if="systemMaintenanceStatus" class="main forcebtt" @click="updateTrasactions()">Update Transactions</button>
        </div>
      </section>
      <div class="accordion" v-if="!modalType && !modalPaymentType && !modalBankType" >
        <div :class="{'cof-box-active' : activeInfo}">
          <section  class="content-tlist" v-if="!loading">
            <el-table class="content-table" :data="filteredData" @row-click="transactionsTableClick" ref="transactionsTable">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="popup-header" v-for="selectedTransaction in [[props.row]]">
                    <h3>Transaction</h3>
                    <h3 v-if="selectedTransaction">{{ selectedTransaction[0].transactionId }}</h3>
                    <h3 v-if="selectedTransaction">Status: {{ selectedTransaction[0].status }}</h3>
                  </div>
                  <template v-for="selectedTransaction in [[props.row]]">
                    <div class="scrollable">
                      <div class="information">
                        <template v-if="selectedTransaction">
                          <div>
                            <p><b>Account</b>: {{ selectedTransaction[0].accountId }}</p>
                            <p><b>Created</b>: {{ selectedTransaction[0].createdAt }}</p>
                            <p><b>Entry Type</b>: {{ selectedTransaction[0].entryType }}</p>
                            <p><b>Currency Type</b>: {{ selectedTransaction[0].currencyType }}</p>
                            <p><b>Transaction Amount</b>:
                              <template v-if="selectedTransaction[0].currencyType == 'Assets'">{{ selectedTransaction[0].assetAmount ? parseFloat(selectedTransaction[0].assetAmount) : '' }}</template>
                              <template v-else>{{ selectedTransaction[0].amount | balance }}</template>
                            </p>
                          </div>
                          <div>
                            <p><b>Profile</b>: {{ selectedTransaction[0].profileId }}</p>
                            <p><b>Completed By</b>: {{ selectedTransaction[0].completedBy }}</p>
                            <p><b>Transaction Type</b>: {{ transactionTypeText[selectedTransaction[0].transactionType] }}</p>
                            <p><b>Currency Name</b>:{{ selectedTransaction[0].currencyName }} - {{ getCurrencyName(selectedTransaction[0].currencyName) }}</p>
                            <p><b>Transaction Fee</b>: ${{ selectedTransaction[0].transactionFee | formattedTwoDecimal }}</p>
                          </div>
                        </template>
                      </div>
                      <div class="popup-header details">
                        <h3>Details</h3>
                      </div>
                      <template v-if="selectedTransaction">
                        <template v-if="selectedTransaction[0].entryType == 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>{{ getCurrencyName(selectedTransaction[0].currencyName) }} Deposit Wallet Address</b>:</p>
                          <p>{{ selectedTransaction[0].contributionIncomingWalletAddress }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <template v-if="selectedTransaction[0].entryType != 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>Pay to</b>: {{ selectedTransaction[0].label }}</p>
                          <p><b>Pay to Wallet Address</b>: </p>
                          <p>{{ selectedTransaction[0].outgoingWalletAddress }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <div class="information">
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payer</b>: <template v-if="selectedTransaction[0].remoteDepositPayor">{{ selectedTransaction[0].remoteDepositPayor.nameOfSender }}</template></p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].remoteDepositPayor">
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.address }}</p>
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.city }}, {{ selectedTransaction[0].remoteDepositPayor.state }}, {{ selectedTransaction[0].remoteDepositPayor.country }}, {{ selectedTransaction[0].remoteDepositPayor.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Check Front</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageFront}`" target="_blank">{{ selectedTransaction[0].checkImageFront }}</a></p>
                                <p><b>Check Bank</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageBack}`" target="_blank">{{ selectedTransaction[0].checkImageBack }}</a></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                <br/>
                                <p><b>Note</b>: Send only {{ getCurrencyName(selectedTransaction[0].currencyName) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: Account Transfer</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'System'">
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'international_wires'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Swift</b>: {{ selectedTransaction[0].swift }}</p>
                                <br/>
                                <p><b>INTERMEDIARY BANK</b></p>
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].intermediaryBankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].intermediaryBankRoutingNumber }}</p>
                                <p><b>SWIFT</b>: {{ selectedTransaction[0].intermediaryBankSwiftCode }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].intermediaryBankReference }}</p>
                                <p><b>Address</b>:</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.address }}</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.city }}, {{ selectedTransaction[0].intermediaryAddress.state }}, {{ selectedTransaction[0].intermediaryAddress.country }}, {{ selectedTransaction[0].intermediaryAddress.postal }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <!--<p><b>IBAN</b>: {{ selectedTransaction[0].iban }}</p>-->
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p> {{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Further Credit Account Name</b>: {{ selectedTransaction[0].futherCreditAccName }}</p>
                                <p><b>Further Credit Account Number</b>: {{ selectedTransaction[0].futherCreditAccNumber }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payee</b>: {{ selectedTransaction[0].payee }}</p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'ach'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'domestic_wire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing Number</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Further Credit Account Name</b>: {{ selectedTransaction[0].futherCreditAccName }}</p>
                                <p><b>Further Credit Account Number</b>: {{ selectedTransaction[0].futherCreditAccNumber }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: ****{{ selectedTransaction[0].toAccountId? selectedTransaction[0].toAccountId.substr(4, 3) : '' }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Trade'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'System'">
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <p><b>Account Number</b>: ****{{ selectedTransaction[0].toAccountId? selectedTransaction[0].toAccountId.substr(4, 3) : '' }}
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                            </template>
                          </div>
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'ach' || selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'acc_to_acc' || selectedTransaction[0].transactionType == 'Trade' || selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'Trade'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                      <!-- Place for details -->
                      <div class="popup-header notes">
                        <h3>Notes</h3>
                        <img src="/assets/img/green30.png" v-if="!isCustomer && !addNote" class="main plus-img" @click="addNote = true">
                        <button v-if="addNote" class="action-button save add-note-button" @click="saveNote()">Save</button>
                      </div>
                      <div class="content-form" v-if="addNote">
                        <div class="form-row form-max">
                          <form-field-text v-model="note" />
                        </div>
                      </div>
                      <table class="content-table">
                        <tr>
                          <th style="padding-left: 0;">Date/Time</th>
                          <th>Added By</th>
                          <th>Note</th>
                        </tr>
                        <template v-if="selectedTransaction">
                          <tr v-for="(item, i) in selectedTransaction[0].notes" :key="item.id">
                            <td style="padding-left: 0;"><span>Date/Time</span>{{ item.noteTime | date }}</td>
                            <td><span>Added By</span>{{ item.addedBy }}</td>
                            <td><span>Note</span>{{ item.note }}</td>
                          </tr>
                        </template>
                      </table>
                    </div>
                    <div class="popup-header notes">
                      <h3>Options</h3>
                    </div>
                    <div class="buttons">
                      <div class="optionBtns" v-if="selectedTransaction">
                        <template v-if="$hasPerm('approve-transaction')">
                          <button v-if="!isCustomer && selectedTransaction[0].status === 'Review'" class="approve" @click="approvePopupTransaction(selectedTransaction[0].id)">Approve</button>
                        </template>
                        <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                      </div>
                      <button class="main" @click="print(selectedTransaction[0].id)">Print</button>
                    </div>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="Date" min-width="7">
                <template slot-scope="props">
                  <p>{{ props.row.createdAt | date('MM/D/YYYY') }}<br/>
                  {{ props.row.createdAt | date('hh:mm A') }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Account" min-width="10">
                <template slot-scope="props">
                  {{ props.row.accountId }}
                </template>
              </el-table-column>
              <el-table-column label="Number" min-width="7">
                <template slot-scope="props">
                  {{ props.row.transactionId }}
                </template>
              </el-table-column>
              <el-table-column label="Entry" min-width="6">
                <template slot-scope="props">
                  {{ props.row.entryType == 'Deposit'? 'DEP' : 'W/D' }}
                </template>
              </el-table-column>
              <el-table-column label="Type" min-width="8">
                <template slot-scope="props">
                  {{ transactionTypeText[props.row.transactionType] }}
                </template>
              </el-table-column>
        <!--      <el-table-column
                label="Currency" min-width="6"
                prop="currencyName">
              </el-table-column> -->
              <el-table-column label="Amount" min-width="12">
                <template slot-scope="props">
                  <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.assetAmount) }}</template>
                  <template v-else>{{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</template>
                </template>
              </el-table-column>
              <el-table-column label="Balance" min-width="12">
                <template slot-scope="props">
                  <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.availableAssetsBalance) }}</template>
                  <template v-else>{{ props.row.currencySymbol }} {{ props.row.availableFundsBalance | balance_without_symbol }}</template>
                </template>
              </el-table-column>
              <el-table-column label="Status" min-width="7">
                <template slot-scope="props">
                  <em :class="statusColorClasses[props.row.status]">{{ props.row.status }}</em>
                </template>
              </el-table-column>
              <el-table-column
                label="Reference" min-width="17"
                prop="reference">
              </el-table-column>
              <el-table-column label="Options" min-width="14">
                <template slot-scope="props">
                  <template v-if="$hasPerm('approve-transaction')">
                    <button v-if="!isCustomer && props.row.status === 'Review'" class="action-button save" @click="approveTransaction(props.row.id)">Approve</button>
                  </template>
                  <template v-if="props.row.transactionType == 'Asset'">
                    <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelAssetTransaction(props.row.id)">Cancel</button>
                  </template>
                  <template v-else>
                    <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                  </template>
                  <template v-if="props.row.status === 'Pending' && props.row.disbursementAuthorizationsStatus === 'pending'">
                    <button v-if="!isCustomer" class="action-button cancel" @click="authRetry(props.row.id)">Auth</button>
                    <button v-else class="action-button cancel" disabled>Auth</button>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </section>
          <section class="pagination-section" v-if="!loading">
        <div>
          Per page
          <select v-model="perPage" @change="setPages()">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
            </li>
            <li class="page-item">
              <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
            </li>
            <li class="page-item">
              <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
            </li>
          </ul>
        </nav>
      </section>
        </div>
      </div>
      <popup :open.sync="addConfirmModal" :width="400">
        <div class="popup-header">
          <h3>Confirmation</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row">
              <p v-if="confirmSuccess" class="transaction-status">Transaction Cancelled</p>
              <p v-else class="transaction-status">Error: Unable to Cancel Transaction</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="addConfirmModal = false">Close</button>
        </div>
      </popup>
      <popup :open.sync="addApproveModal" :width="400">
        <div class="popup-header">
          <h3>Confirmation</h3>
        </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row">
              <p v-if="approveSuccess" class="transaction-status">Transaction Approved</p>
              <p v-else class="transaction-status">Error: Unable to Approve Transaction</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="addApproveModal = false">Close</button>
        </div>
      </popup>
    </template>

    <section class="popup" :class="{open: !open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Corporate Entry PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="errorOpen" :width="400">
      <div class="scrollable">
        <p>{{ message }}</p>
      </div>
    </popup>

    <corp-transaction-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import MnbSelect from '../../components/ui/forms/Select'
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import PaymentDetailsModal from './PaymentDetailsModal.vue'
import BankLookupModal from './BankLookupModal.vue'
import AddRemoteDepositModal from './AddRemoteDepositModal.vue'
import AppliedFilters from '../../components/common/AppliedFilters'
import CorpTransactionFilters from './filters/CorpTransactionFilters'
import AddDepositWithdrawalPanel from './AddDepositWithdrawalPanel.vue'
import PaymentDetailsPanel from './PaymentDetailsPanel.vue';
import BankLookupPanel from './BankLookupPanel.vue';

export default {
  name: 'corp-transactions',

  components: {
    MnbSelect,
    VueEnglishdatepicker,
    DateTimePicker,
    PaymentDetailsModal,
    BankLookupModal,
    AppliedFilters,
    PaymentDetailsModal,
    CorpTransactionFilters,
    AddDepositWithdrawalPanel,
    PaymentDetailsPanel,
    BankLookupPanel
  },

  data: () => ({
    openedFilters: false,
    filters: null,
    page: 1,
    perPage: 25,
    pages: [],
    totalNumber: 0,

    accounts: null,
    modalType: null,
    modalDepositType: null,
    modalPaymentType: null,
    modalBankType: null,

    open: true,
    pin: '',
    pinFailed: false,

    errorOpen: false,
    message: null,
    featureStatus: true,
    featureFundsStatus: true,
    featureAdvanceDepositNoticeStatus: true,
    featureRemoteDeposit: null,

    currencyStatus : {
      AUD : false,
      CAD : false,
      EUR : false,
      GBP : false,
      JPY : false,
    },

    addConfirmModal: false,
    confirmSuccess: true,
    addApproveModal: false,
    approveSuccess: true,

    selectedTransaction: null,
    addNote: false,
    note: null,
    loading: false,
    systemMaintenanceStatus: window.maintenance,
    activeInfo: true,
    today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        entryType: 'Entry Type',
        depositType: 'Transaction Type',
        transactionId: 'Transaction ID',
        accountId: 'Account Number',
        amount: 'Amount',
        status: 'Status',
        currencyType: 'Currency Type',
        currencyName: 'Currency',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    appData() {
      return window.appData;
    },

    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    currencies () {
      let data = window.currencies || []
      return data
    },

    transactions () {
      let data =  this.$store.state.transactions.data || []
      this.totalNumber = this.$store.state.transactions.count || 0
      return data
    },

    filteredData () {
      return this.transactions;
    },

    transactionTypeText(){
      return {
        checks: 'Check',
        ach: 'ACH',
        domestic_wire: 'Domestic Wire',
        domesticWire: 'Domestic Wire',
        international_wires: 'International Wire',
        internationalWire: 'International Wire',
        acc_to_acc: 'Account Transfer',
        Asset: 'Asset',
        Trade: 'Trade',
        System: 'System'
      }
    },

    statusColorClasses () {
      return {
        New: 'c1',
        Pending: 'c2',
        Review: 'c2',
        'On Hold': 'c2',
        Denied: 'c4',
        Complete: 'c1',
        Reversed: 'c3',
        Cancelled: 'c3',
        Error: 'c3'
      }
    },
  },

  async created () {
    await this.getFeatureStatus();
    await this.refreshTransactions();
    const registrations = await this.$store.dispatch('registrations/load', { params: { corporate: 1 } })
    const registration = registrations[0]
    if(registration){
      this.accounts = registration.accounts
    }
    this.$store.commit('system/setSingle', window.appData.system)
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'currencyName') return this.getCurrencyName(value)
      if (key === 'amount') return window.formatMoney(value)

      if (key === 'entryType') return window.entryTypes[value]
      if (key === 'currencyType') return window.currencyTypes[value]
      if (key === 'status') return window.transactionStatuses[value]

      if (key === 'depositType') return window.transactionTypes[value]

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    getCurrencyName (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    openTransModal (type) {
      this.modalDepositType = null
      this.modalBankType = null
      this.modalPaymentType = null
      this.modalType = type
    },

    openPayment () {
      this.modalType = null
      this.modalDepositType = null
      this.modalBankType = null
      this.modalPaymentType = 'Payment'
    },

    openBank () {
      this.modalType = null
      this.modalDepositType = null
      this.modalPaymentType = null
      this.modalBankType = 'Bank'
    },

    openRemoteDeposit () {
      this.modalDepositType = 'modalDepositType'
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    cancel() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckCorporateEntryPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      this.featureAdvanceDepositNoticeStatus = window.systemFeatures['Advanced Deposit Notice']
      this.featureRemoteDeposit = window.systemFeatures['Remote Deposit']
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async cancelTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Cancel")).action
          if(status.error)
            this.confirmSuccess = false
          this.addConfirmModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async cancelAssetTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "CancelAsset")).action
          if(status.error)
            this.confirmSuccess = false
          this.addConfirmModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async authRetry(id) {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", id, "AuthRetry")).action
        await this.refreshTransactions();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async cancelPopupTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Cancel")).action
          if (status.error)
            this.confirmSuccess = false
          await this.refreshTransactions();
          this.selectedTransaction = this.transactions.filter(item => item.id === id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async approveTransaction(id) {
      if(confirm(`Are you sure you want to Approve this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Approve")).action
          if (status.error)
            this.approveSuccess = false
          this.addApproveModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async approvePopupTransaction(id) {
      if(confirm(`Are you sure you want to Approve this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Approve")).action
          if (status.error)
            this.approveSuccess = false
          await this.refreshTransactions();
          this.selectedTransaction = this.transactions.filter(item => item.id === id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async updateTrasactions() {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", "new", "UpdateTransactions")).action
        await this.refreshTransactions();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async saveNote () {
      const payload = {
        transactionId: this.selectedTransaction[0].id,
        addedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        note: this.note,
        noteTime: 'CURRENT_TIMESTAMP'
      }

      await this.$store.dispatch('transactionNotes/create', payload)
      this.addNote = false
      this.note = null
      await this.refreshTransactions();
      this.selectedTransaction = this.transactions.filter(item => item.id === this.selectedTransaction[0].id)
    },

    async refreshTransactions () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (this.isCustomer) {
        const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
        if (reg?.mainAccount?.accountNumber)
        params.accountId=reg.mainAccount.accountNumber;
      }
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.accountId = ['like', '923%'];
        params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        params.accountId = ['like', '923%'];
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.depositType) {
          params.transactionType = this.filters.depositType
        }
        if (this.filters.entryType) {
          params.entryType = this.filters.entryType
        }
        if (this.filters.profileId) {
          params.profileId = this.filters.profileId
        }
        if (this.filters.transactionId) {
          params.transactionId = this.filters.transactionId
        }
        if (this.filters.accountId) {
          params.accountId = this.filters.accountId
        }
        if (this.filters.amount) {
          params.amount = this.filters.amount
        }
        if (this.filters.status) {
          params.status = this.filters.status
        }
        if (this.filters.currencyType) {
          params.currencyType = this.filters.currencyType
        }
        if (this.filters.currencyName) {
          params.currencyName = this.filters.currencyName
        }
      }

      await this.$store.dispatch('transactions/load', { order: ['transactionId:DESC'], skip: ['regName', 'profileNumber', 'walletAddress', 'qPaymentReference', 'toAccountName'],  params, limit: this.perPage, start: (this.page-1) * this.perPage })
      this.loading=false;
      loader.hide()
    },

    async print (id) {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", id, "Print")).action
        window.open("/files/transactions/"+id+"/"+id+"-transaction.pdf");
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
    transactionsTableClick(row) {
      this.$refs.transactionsTable.toggleRowExpansion(row);
    },
  },

  watch: {
    transactions () {
      this.setPages();
    },
    appliedFilters () {
      this.refreshTransactions();
    },

    page() {
      this.refreshTransactions();
    },

    perPage() {
      if(this.page == 1){
        this.refreshTransactions();
      }else{
        this.page = 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .assets-label {
    font-size: 17px;
  }
  .table {
    width: 100%;
    tr {
      td {
        padding: 5px;
      }
    }
  }
  .buttons {
    margin-top: 30px;
  }

  .information {
    display: flex;
    justify-content: space-between;

    div {
      width: 50%;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .optionBtns .main {
      margin-left: 15px;
    }
  }
  .form-max {
    div {
      width: 100%;
    }
  }
  button.cancel {
    background: #666;
    color: white;
    min-width: 120px;
  }
  button.approve {
    background: #67c23a;
    color: white;
    min-width: 120px;
  }
  .btn-approve {
    background: #67c23a ;
    color: white;
  }

  .search-item {
    input {
      max-width: 110px;
    }
  }
  .image-link {
    color: #51aeff;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .content-tlist {
    border: none;
  }

  .add-note-button {
  min-width: 50px !important;
}
</style>
