<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Profile Account Confirmation</h2>
      <div class="status">
        Status
        <status-icon :color="accountConfirmation ? 'green' : null" />
      </div>
    </div>

    <table class="content-table" style="min-width: unset;">         
      <tr>
        <th>Date/Time</th>
        <th v-if="registration.type=='Business' && featureMasterAccounts"></th>
        <th v-if="registration.type=='Master Business' && featureMasterAccounts"></th>
        <th>Profile Type</th>
        <th>Profile Number</th>
        <th>Account Type</th>
        <th>Account Number</th>
        <th>Currency</th>
        <th v-if="registration.status !== 'Complete' && !mainAccount">Options</th>                       
      </tr>
      <tr>
        <td><span>Date/Time</span>
          <template v-if="!accountConfirmation">N/A</template>
          <template v-else>{{ accountConfirmation.createdAt | date('LL') }} {{ accountConfirmation.createdAt | date('LT') }}</template>
        </td>         
        <td v-if="registration.type=='Business' && featureMasterAccounts">
          <button class="second" v-on:click.prevent="setAsMaster">Master Account</button>
        </td>
        <td v-if="registration.type=='Master Business' && featureMasterAccounts">
          <button class="second" v-on:click.prevent="setAsMaster">Remove Master Account</button>
        </td>
        <td><span>Profile Type</span>
          {{ registration.type }}
        </td>
        <td><span>Profile Number</span>
          {{ mainAccount? mainAccount.profileNumber : '' }}
        </td>
        <td><span>Account Type</span>
          {{ registration.type }}
        </td>
        <td><span>Account Number</span>
          {{ mainAccount? mainAccount.accountNumber : '' }}
        </td>
        <td><span>Currency</span>
          <mnb-select v-model="form.currency" :options="currencyOptions" :disabled="!!accountConfirmation"/>
        </td>        
        <td v-if="registration.status !== 'Complete' && !mainAccount"><span>Options</span>
          <button v-if="!accountConfirmation" class="main" @click="save()">Confirm</button>
          <button v-else class="second" @click="reset()">Reset</button>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'
import MnbSelect from '../../../components/ui/forms/Select'

export default {
  components: {
    MnbSelect,
    StatusIcon
  },

  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data () {
    return {  
      form: {
        currency: null,
      },

      currencyOptions:  [
        {
          id: 'USD',
          text: 'USD'
        }
      ],
      customEnv: appData.customEnv,
      featureMasterAccounts: true,
    }
  },

  computed: {
    accountConfirmation () {
      return this.$store.getters['registrations/get'](this.registration.id).confirmation
    },

    mainAccount () {
      return this.registration.mainAccount || null
    }
  },

  async created () {
    this.form.currency = this.accountConfirmation?.currency || this.registration.currency
    await this.getFeatureStatus();
  },

  methods: {
    async save () {
      const loader = this.$loading.show()
      try {
        const payload = {
          currency: this.form.currency,
          createdAt: 'CURRENT_TIMESTAMP'
        }
        const confirmation = await this.$store.dispatch('account_confirmation/create', payload)
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            status: 'Pending',
            confirmation: confirmation.id
          }
        })
        await this.addNote('Profile Account confirmed')
        this.$toast.success('Profile account confirmed successfully.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async reset () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('account_confirmation/delete', this.accountConfirmation.id)
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            confirmation: null
          }
        })
        await this.addNote('Profile Account Confirmation reset')
        this.form.currency = this.registration.currency
        this.$toast.success('Profile account confirmed successfully.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },
    
    async setAsMaster() {
      var newType="Master Business";
      if (this.registration.type=="Master Business")
        newType="Business";
      this.registration.type=newType;
      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: {
          updatedAt: 'CURRENT_TIMESTAMP',
          type: newType,
        }
      })
    },

    async getFeatureStatus () {
      this.featureMasterAccounts = window.systemFeatures['Master Accounts']
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}
</style>
