<template>
  <div class="cp-box" v-if="registration">
    <ul class="sub-tabs">
      <!-- <li v-if="showActive" :class="{ active: activeTab === 'info' }" @click="activeTab = 'info'"><a>{{ registration.type }}</a></li> -->
      <template v-if="$hasPerm('status-update') && registration.status === 'Denied'">
        <li><a class="set-profile" @click="setNew()">New</a></li>
      </template>
      <template v-if="$hasPerm('setup-checklist') && showActive">
        <li v-if="showSetupChecklist" :class="{ active: activeTab === 'setup_checklist' }" @click="activeTab = 'setup_checklist'"><a v-if="registration.accounts.length > 1">Boarding</a><a v-else>Profile</a></li>
      </template>
      <template v-if="$hasPerm('set-profile')">
        <li v-if="showSetupButton"><a class="set-profile" @click="setProfile()">Set Profile</a></li>
      </template>
    </ul>
    <hr v-if="$hasPerm('setup-checklist')">
    <registration-data-info
      v-if="activeTab === 'info'"
      :registration="registration"
      :show-edit="['New', 'In Process', 'Denied', 'Archived'].includes(this.registration.status) && showSetupChecklist" />
    <setup-checklist v-if="activeTab === 'setup_checklist'" :registration="registration" />
    <setup-history v-if="activeTab === 'setup_history'" :registration="registration" />
  </div>
</template>

<script>
import SetupChecklist from './SetupChecklist'
import VueEnglishdatepicker from 'vue-englishdatepicker'
import RegistrationDataInfo from './RegistrationDataInfo'
import helperMixin from '../../components/common/helper-mixin'

export default {
  mixins: [helperMixin],

  components: {
    SetupChecklist,
    VueEnglishdatepicker,
    RegistrationDataInfo
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'setup_checklist'
    }
  },

  computed: {
    showSetupButton () {
      return this.registration?.setupComplete && ['New', 'Pending'].includes(this.registration?.status)
    },

    showActive (){
      //return !['Active'].includes(this.registration.status)
      return true
    },

    showSetupChecklist () {
      return !['Denied', 'Archived'].includes(this.registration.status)
    },

    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },

  async created () {
    this.$store.commit('system/setSingle', window.appData.system)
    // const tab = this.getUrl('tab')
    // if(tab) this.activeTab = tab
  },

  methods: {
    async setProfile () {
      if (confirm('Do you want to Set up this Profile?')) {
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('registrations/action', {
            id: this.registration.id,
            action: 'SetProfile'
          })
          await this.$store.dispatch('profilenotes/load', { params: { registrationId: this.id } })
          this.$toast.success('Profile is set.')
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async setNew () {
      const loader = this.$loading.show()
      await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
        payload: { status: 'New' }
      })
      await this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes: 'Registration Status Updated: New',
        createdAt: 'CURRENT_TIMESTAMP'
      })
      loader.hide()
    },
  }
}
</script>

<style lang="scss" scoped>
.set-profile {
  cursor: pointer;
}
</style>