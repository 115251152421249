<template>
  <div class="cp-box" v-if="partner">
    <ul class="sub-tabs">
      <li :class="{ active: activeTab === 'tracking' }" @click="activeTab = 'tracking'"><a>Tracking</a></li>
    </ul>
    <hr>
    <tools-tracking v-if="activeTab === 'tracking'" :partner="partner" />
  </div>
</template>

<script>
import ToolsTracking from './ToolsTracking'

export default {
  components: {
    ToolsTracking
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'tracking'
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    }
  }
}
</script>
