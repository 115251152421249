<template>
  <div>
    <template>
      <section>
        <div style="padding: 24px 30px 10px 10px;">
          <button class="btn btn-secondary" @click="activeInfo = 'fees'"> Fees Detail</button>
          <button class="btn btn-secondary" @click="activeInfo = 'transaction'"> Transaction Detail</button>
        </div>

        <fees-report v-if="activeInfo == 'fees'"/>
        <transaction-report v-if="activeInfo == 'transaction'"/>
      </section>
    </template>
  </div>
</template>

<script>
  import FeesReport from './FeesReport.vue';
  import TransactionReport from './TransactionReport.vue';

  export default {
    name: 'detail-reports',

    components: {
        FeesReport,
        TransactionReport
    },

    data() {
      return {
        activeInfo: null,
      }
    },

    computed: {
     
    },

    watch: {
      
    },

    methods: {
      
    }
  }
</script>

<style lang="scss" scoped>
</style>