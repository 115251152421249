<template>
  <div>
    <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>
      <template v-if="featureStatus !=null && !featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Statements Generating</h3>
      </template>
      <template v-else-if="featureStatus !=null && featureStatus">
        <template v-if="perms">
          <div class="content-filters" v-if="statementTasks.length && false">
            <basic-form class="form-flex" :classes="{'form-resized': true}">
              <div class="cof-row message">
                <label>There are {{ statementTasks.length }} statements ready to be generated</label>
              </div>
              <div class="cof-row buttons">
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button v-if="$hasPerm('generate-statements')" class="second" @click="generateLeft()">Generate Them</button>
                </div>
              </div>
            </basic-form>
          </div>

          <div class="content-filters">
            <basic-form class="form-flex" :classes="{'form-resized': true}">
              <h4 class="monthly-head-date-range">Statement Date Range</h4>
              <div class="cof-row dates">
                <div class="cof-field">
                  <label>Start Date</label>
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="form.start"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true">
                    </date-time-picker>
                    <!--<vue-englishdatepicker v-model="form.start" :validator="$v.form.start" classValue="fromDate" />-->
                  </div>
                  <div class="error-message" v-if="$v.form.start.$dirty && $v.form.start.$invalid"><span>Please enter start date.</span></div>
                  <div class="error-message" v-if="$v.form.start.$dirty && !$v.form.start.$invalid && $v.form.end.$dirty && !$v.form.end.$invalid && !validDate"><span>Start date must be earlier than end date.</span></div>
                </div>
                <div class="cof-field">
                  <label>End Date</label>
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="form.end"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      :max-date="date2"
                    >
                    </date-time-picker>
                    <!--<vue-englishdatepicker v-model="form.end" :validator="$v.form.end" classValue="toDate" />-->
                  </div>
                  <div class="error-message" v-if="toDateError"><span>Invalid End Date.</span></div>
                  <div class="error-message" v-if="$v.form.end.$dirty && $v.form.end.$invalid"><span>Please enter end date.</span></div>
                </div>
              </div>
              <div class="cof-row message">
                <mnb-textarea
                  v-model="form.message"
                  label="Statement Message"
                  @input="mixin_autoResize_resize"
                  :classes="{ 'statement-message': true}" full/>
              </div>
              <div class="cof-row buttons">
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button v-if="$hasPerm('generate-statements')" class="second" @click="generate()" :disabled="toDateError || (form.start == null) || (form.end == null)">Schedule</button>
                </div>
                <div class="cof-field">
                  <label>&nbsp;</label>
                  <button class="second" @click="reset()">Reset</button>
                </div>
              </div>
            </basic-form>
          </div>
        </template>

        <h4 class="statement-history">History</h4>
        <section class="content-filters">
          <div class="cof-box">
            <a href="" class="tsm-handle">Filters</a>
            <div class="cof-form">
              <a class="cof-close"></a>
              <h6>Transaction Search</h6>
              <div class="cof-row">
                <div class="cof-field">
                  <label>Date Range</label>
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="searchForm.fromDate"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      min-date="2020-01-01"
                      :max-date="today" >
                    </date-time-picker>
                    <span>To</span>
                    <date-time-picker
                      v-model="searchForm.toDate"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      min-date="2020-01-01"
                      :max-date="today" >
                    </date-time-picker>
                  </div>
                </div>
              </div>
              <div class="cof-row">
                <div class="cof-field mobinl">
                  <label>&nbsp;</label>
                  <button class="main" @click="search()">Search</button>
                </div>
                <div class="cof-field mobinl">
                  <label>&nbsp;</label>
                  <button class="second" @click="resetItem()">Reset Filters</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Scheduled Date</th>
              <th>Statement Date</th>
              <th>By</th>
              <th>Start</th>
              <th>End</th>
              <th>Created</th>
              <th>Result</th>
            </tr>
            <template v-for="statement in statements">
            <tr :key="'schedule'+statement.id" v-if="statement.startDate">
              <td><span>Scheduled</span>{{ statement.createdAt | date('MM/D/YYYY')}}</td>
              <td><span>Date</span><template v-if="statement.status!='Pending'">{{ statement.completeOn | date('MM/D/YYYY')}}</template></td>
              <td><span>By</span>{{ statement.createdBy && `${statement.createdBy.firstName} ${statement.createdBy.lastName}`}}</td>
              <td><span>Start Date</span>{{ statement.startDate | date('MM/D/YYYY')}}</td>
              <td><span>End Date</span>{{ statement.endDate | date('MM/D/YYYY')}}</td>
              <td><span>Statements Created</span>
                <a v-if="statement.doneCount*1<statement.totalCount*1" href="" v-on:click.prevent="showMissing(statement.id)">
                  {{ statement.doneCount }} of {{ statement.totalCount }}
                </a>
                <template v-else>
                  {{ statement.totalCount }}
                </template>
              </td>
              <td><span>Result</span>{{ statement.status=='Complete'?'Success':statement.status }}</td>
            </tr>
            <tr v-else :key="statement.id">

              <td><span>Date</span>{{ statement.createdAt | date('MM/D/YYYY')}}</td>
              <td><span>Date</span>{{ statement.createdAt | date('MM/D/YYYY')}}</td>
              <td><span>By</span>{{ `${statement.createdBy.firstName} ${statement.createdBy.lastName}`}}</td>
              <td><span>Start Date</span>{{ statement.start | date('MM/D/YYYY')}}</td>
              <td><span>End Date</span>{{ statement.end | date('MM/D/YYYY')}}</td>
              <td><span>Statements Created</span>{{ statement.numberOfStatements }}</td>
              <td><span>Result</span>{{ statement.result }}</td>
            </tr>
            </template>
          </table>
        </section>
        <section class="pagination-section">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="6">6</option>
              <option value="12">12</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </template>
    <div class="loading-counter" v-if="loadingCount">
      {{ loadingProgress+1 }} / {{ loadingCount }}
    </div>
  <popup :open.sync="detailsOpen">
    <div class="popup-header">
      <h3>Pending statements</h3>
    </div>
    <div class="scrollable">
      <div class="content-form">
        <ul>
          <li v-for="task of detailsList">
            <span>Number: {{ task.stdata.data.account.accountNumber }}</span>
            <span>From: {{ task.stdata.data.start | date('MM/D/YYYY') }}</span>
            <span>To: {{ task.stdata.data.end | date('MM/D/YYYY') }}</span>
          </li>
        </ul>
      </div>
    </div>
  </popup>
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker'
import BasicForm from '../../components/ui/forms/Form'
import MnbTextarea from '../../components/ui/forms/Textarea'

import mixinAutoResize from "../../components/ui/forms/autoresize.js"

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'accounting-statements',

  components: {
    VueEnglishdatepicker,
    BasicForm,
    MnbTextarea,
    DateTimePicker
  },

  mixins: [mixinAutoResize],

  data () {
    return {
      form: {
        start: null,
        end: null,
        message: null
      },
      searchForm: {
        fromDate: null,
        toDate: null,
      },
      statementTasks: [],
      toDateError: false,
      featureStatus: null,
      loadingCount: null,
      loadingProgress: null,
      perms: window.appData.currentUser?.role?.perms?.includes('generate-statements') || window.appData.currentUser?.perms?.includes('generate-statements'),

      page: 1,
      perPage: 6,
      pages: [],
      totalNumber: 0,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      appliedFilters: null,
      detailsOpen: false,
      detailsList: [],
    }
  },

  computed: {
    validDate () {
      return moment(this.form.start) <= moment(this.form.end)
    },

    statements() {
      let data = [...(this.$store.state.statements_schedule.data || []),
                  ...(this.$store.state.statements.data || [])];
      this.totalNumber = this.$store.state.statements_schedule.count || 0
      return data
    },

    date2 () {
      let today = new Date()
      let yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      var dd = String(yesterday.getDate()).padStart(2, '0');
      var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = yesterday.getFullYear();
      var yesterdayDate = yyyy + '-' + mm + '-' + dd;
      return yesterdayDate
    }
  },

  watch: {
    'form.end' () {
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      var dd = String(yesterday.getDate()).padStart(2, '0');
      var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = yesterday.getFullYear();
      var yesterdayDate = yyyy + '-' + mm + '-' + dd;
      const now = moment(yesterdayDate, "YYYY-MM-DD")
      const to = moment(this.form.end, 'YYYY-MM-DD')
      if (to > now)
        this.toDateError = true
      else
        this.toDateError = false
    },

    statements () {
      this.setPages();
    },

    page() {
      this.refreshStatements();
    },

    perPage() {
      if(this.page == 1){
        this.refreshStatements();
      }else{
        this.page = 1
      }
    },

    appliedFilters () {
      this.refreshStatements();
    },
  },

  async created () {
    this.loadStatementTasks();
    await this.getFeatureStatus();
    await this.refreshStatements();
  },

  methods: {
    async showMissing(id) {
      this.detailsOpen=true;
      this.detailsList=[];
      this.detailsList=(await api.list("StatementTask", {done: "null", schedule: id})).items;
    },
  
    search () {
      this.appliedFilters = { ...this.searchForm }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.searchForm).forEach(key => this.form[key] = null)
    },

    async loadStatementTasks() {
      this.statementTasks=(await api.list("StatementTask", {done: "null"})).items;
    },
    async generateLeft() {
      this.loadingCount=this.statementTasks.length;
      this.loadingProgress=0;
      const loader = this.$loading.show()
      try {
        for (var stmt of this.statementTasks) {
          await api.request("POST", "/generate-statement/"+stmt.id);
          this.loadingProgress++;
        }
      } catch(e) {
      } finally {
        loader.hide()
        this.loadingCount=null;
        this.loadingProgress=null;
      }
      this.statementTasks=[];
      this.loadStatementTasks();
    },
    async generate() {
      if (!this.$v.$invalid && this.validDate && !this.toDateError) {
        const loader = this.$loading.show()
        try {
          const response = await api.request('POST', '/generate-statement-schedule', this.form)
          this.reset()
          this.$toast.success('Statements scheduled for '+response.item.completeOn+".");
        } catch (e) {
          console.log(e);
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },
    async generateOld() {
      this.$v.$touch()
      if (!this.$v.$invalid && this.validDate && !this.toDateError) {
        const loader = this.$loading.show()
        try {
          const response = await api.request('POST', '/generate-statement', this.form)
//           this.$store.commit('statements/set', response)
          this.reset()
//           this.$toast.success('Statements ready to be generated.')
          this.loadingCount=response.ids.length;
          this.loadingProgress=0;
          for(var id of response.ids) {
            await api.request("POST", "/generate-statement/"+id);
            this.loadingProgress++;
          }
          this.$toast.success('Statements generated.')
        } catch (e) {
          console.log(e);
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
          this.loadingCount=null;
          this.loadingProgress=null;
        }
        
      }
    },

    reset () {
      this.form.start = null
      this.form.end = null
      this.form.message = null
      this.toDateError = false
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Statements Generating']
    },

    async refreshStatements () {
      const loader = this.$loading.show()
      let params = {}
      if (!this.appliedFilters) {
        params.completeOn=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.appliedFilters.fromDate)
          params.createdAtAfter = [">=", this.appliedFilters.fromDate + " 00:00:00", "completeOn"];
        if (this.appliedFilters.toDate)
          params.createdAtBefore = ["<=", this.appliedFilters.toDate + " 23:59:59", "completeOn"];
      }
      let params2 = {}
      params2.showinlog = 1
      if (!this.appliedFilters) {
        params2.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.appliedFilters.fromDate)
          params2.createdAtAfter = [">=", this.appliedFilters.fromDate + " 00:00:00", "createdAt"];
        if (this.appliedFilters.toDate)
          params2.createdAtBefore = ["<=", this.appliedFilters.toDate + " 23:59:59", "createdAt"];
      }
      await this.$store.dispatch('statements_schedule/load', {params, order: ['id:DESC'], limit: this.perPage, start: (this.page-1) * this.perPage })
      await this.$store.dispatch('statements/load', {params: params2, order: ['id:DESC'], limit: this.perPage, start: (this.page-1) * this.perPage })
      loader.hide()
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },
  },

  validations: {
    form: {
      start: { required },
      end: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dates {
    justify-content: space-between;
    margin-bottom: 20px; 
  }
  .buttons {
    justify-content: space-between;
  }
  .message {
    justify-content: space-between;
  }

  .loading-counter {
    z-index: 10005;
    position: fixed;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-filters .cof-box {
    background: none;
  }

  .content-tlist {
    border: none;
  }
</style>
