<template>
  <div class="content-form">
    <div class="form-row form-split">
      <form-field-text v-model="form.account_name" label="Name on Account" :validator="v.account_name" required />
      <form-field-select v-model="form.account_type" label="Account Type" :validator="v.account_type" :options="accountOptions" required />
    </div>
    <div class="form-row form-three">
      <form-field-text v-model="form.bank_name" label="Bank Name" :validator="v.bank_name" required />
      <form-field-text v-model="form.account_number" label="Bank Account Number" :validator="v.account_number" required />
      <form-field-text v-model="form.swift" label="SWIFT Code" :validator="v.swift" required />
    </div>
    <div class="center-checkbox">
      <input v-model="form.use_intermediary_bank" type="checkbox" id="use_intermediary_bank" disabled>
      <label for="use_intermediary_bank">Use Intermediary Bank <span class="error-message">*Required</span></label>
    </div>
    <template v-if="form.use_intermediary_bank">
      <div class="form-row form-split">
        <form-field-text v-model="form.intermediary_bank_name" label="Intermediary Bank Name" :validator="v.intermediary_bank_name" required />
        <form-field-text v-model="form.intermediary_bank_reference" label="Intermediary Bank Reference" :validator="v.intermediary_bank_reference" required />
      </div>
      <h3 class="subtitle">INTERMEDIARY BANK ADDRESS</h3>
      <address-form v-model="form.intermediary_address" :v="v.intermediary_address" />
    </template>
    
    <h3 class="subtitle">BENEFICIARY ADDRESS</h3>
    <div class="form-row form-split">
      <form-field-text v-model="form.iban" label="IBAN" :validator="v.iban" required />
    </div>
    <address-form v-model="form" :v="v" />
  </div>
</template>

<script>
import AddressForm from './Address'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AddressForm
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal'
        },
        {
          id: 'business',
          text: 'Business'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ]
    }
  },

  watch: {
    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
// .center-checkbox {
//   margin-bottom: 15px;
//   text-align: center;
//   input {
//     width: 18px;
//     height: 18px;
//     vertical-align: middle;
//     margin-right: 5px;
//   }
// }
// .subtitle {
//   color: #1f2d4e;
//   font-size: 16px;
//   font-weight: bold;
//   margin-bottom: 15px;
// }
</style>
