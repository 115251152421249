<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>View Card Data</h3>
    </div>
    <div class="scrollable">
      <vgv-display-container class="card-data-container" :class="{'card-data-business': isBusiness}" :orgId="pinData.orgId" :vaultId="pinData.vaultId" :environment="pinData.environment" :cardToken="pinData.pinToken" :cardId="pinData.id">
        <vgv-display fieldName="cardNumber" :css="fieldStyleNum" class="card-number" @shown="shownCount++" v-show="shownCount==4" />
        <label class="card-exp-label" v-show="shownCount==4">Valid thru</label>
        <vgv-display fieldName="expiryMonth" :css="fieldStyle" class="card-exp-m" @shown="shownCount++" v-show="shownCount==4" />
        <label class="card-exp-label-ex" v-show="shownCount==4">/</label>
        <vgv-display fieldName="expiryYear" :css="fieldStyle" class="card-exp-y" @shown="shownCount++" v-show="shownCount==4" />
        <label class="card-exp-label-cvv" v-show="shownCount==4">CVV</label>
        <vgv-display fieldName="cvv" :css="fieldStyle" class="card-cvv" @shown="shownCount++" v-show="shownCount==4" />
        <div class="cardholder" v-show="shownCount==4">
          <div>{{ cardholderName }}</div>
          <div v-if="cardholderBusiness">{{ cardholderBusiness }}</div>
        </div>
      </vgv-display-container>
      <div class="buttons">
        <button class="second" @click="open = false">Close</button>
      </div>
    </div>
  </popup>
</template>

<script>
import VgvDisplayContainer from '../../components/vgv/VgvDisplayContainer'
import VgvDisplay from '../../components/vgv/VgvDisplay'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    },
    
    pinData: {
      type: Object,
      required: true
    },
  },
  
  components: {
    VgvDisplay,
    VgvDisplayContainer
  },

  data () {
    return {
      open: true,
      submitError: null,
      fieldStyle: {
        'font-size': '15px',
        'color': 'black',
//         'font-family': '"Lato", "Arial", "Helvetica", sans-serif',
        'font-family': '"Lato", "Arial", "Helvetica", sans-serif',
      },
      fieldStyleNum: {
        'font-size': '23px',
        'font-weight': 'normal',
        'color': 'black',
        'font-family': '"Lato", "Arial", "Helvetica", sans-serif',
      },
      shownCount: 0,
    }
  },

  computed: {
    isBusiness() {
      return this.account.regType=='Business';
    },
    cardholderName() {
      if (!this.account || !this.account.cards || !this.account.cards.length)
        return "";
      var card=this.account.cards[this.account.cards.length-1];
      if (card && !card.embossingPerson) {
        try {
          var cardholder=JSON.parse(card.cardholder);
          return cardholder.name;
        } catch(e) {
        }
      }
      return (card && card.embossingPerson) || "";
    },
    cardholderBusiness() {
      if (!this.account || !this.account.cards || !this.account.cards.length)
        return "";
      var card=this.account.cards[this.account.cards.length-1];
      return (card && card.embossingBusiness) || "";
    },
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },


  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
    shownCount () {
      if (this.shownCount==4)
        this.cardLoading.hide();
    }
  },

  methods: {
    async setPin () {
      const loader = this.$loading.show()
      var result=await this.$refs.vgvForm.submit();
      loader.hide();
      if (result.message) {
        this.submitError=result.sysMessage || result.message;
        if (!result.clientError)
          this.$emit('refresh');
      } else {
        this.$emit('close');
        this.$toast.success('Card pin successfully updated.')
      }
    },
  },

  async created () {
    this.system = JSON.parse(JSON.stringify(window.appData.system))
    this.cardLoading = this.$loading.show()
  },

}
</script>

<style lang="scss">

</style>
