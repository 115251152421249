<template>
  <div>
    <checklist-steps :partner="partner" />
    
  </div>
</template>

<script>
import ChecklistSteps from './checklist/ChecklistSteps'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  components: { 
    ChecklistSteps,
  }
}
</script>
