<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <h4>API Logs Search</h4>
        <div class="cof-form">
          <a class="cof-close"></a>
          <h6>Templates Search</h6>
          <div class="cof-row">
            <div class="cof-field sysint-page">
              <label>Date Added</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.fromDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
                <span>To</span>
                <date-time-picker
                  v-model="form.toDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  min-date="2020-01-01"
                  :max-date="today" >
                </date-time-picker>
              </div>
            </div>
            <div class="cof-field sysint-page">
              <mnb-select v-model="form.provider" :options="providerOptions" label="Provider" />
            </div>
            <div class="cof-field sysint-page">
              <form-field-select v-model="form.failed" label="Status" :options="statusOptions" />
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="search()">Search</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="resetItem()">Reset Filters</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Date</th>
          <th style="width: 15%;">Provider</th>
          <th style="width: 15%;">Status</th>
          <th style="width: 15%;">Method</th>
          <th style="width: 10%;">URL</th>
          <th style="width: 10%;">Options</th>
        </tr>
        <tr v-for="(item, i) in filteredData" :key="i">
          <td><span>Date</span>{{ item.created }}</td>
          <td><span>Provider</span>{{ providerForId(item.providerId).provider_name || '' }}</td>
          <td><span>Status</span>{{ item.status }}</td>
          <td><span>Method</span>{{ item.method }}</td>
          <td><span>URL</span>{{ item.url }}</td>
          <td>
            <span>Options</span>
            <button class="action-button" @click="view(item)">View</button>
          </td>
        </tr>
      </table>
    </section>
    <section class="pagination-section" v-if="!loading">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>
    <popup :open.sync="detailModal">
      <div class="popup-header">
        <h3>API Log</h3>
      </div>
      <div class="scrollable">
        <div class="log-body" v-if="selectedItem">
          <div class="head-details">
            <div>
              <label>URL:</label>
              <span>{{ selectedItem.url }}</span>
            </div>
            <div>
              <label>Response Code:</label>
              <span>{{ selectedItem.status }}</span>
            </div>
            <div>
              <label>Created date:</label>
              <span>{{ selectedItem.created }}</span>
            </div>
          </div>
          <div class="request-data">
            <label>Request:</label>
            <json-viewer :value="selectedItem.request" />
          </div>
          <div class="response-data">
            <label>Response:</label>
            <json-viewer :value="selectedItem.response" />
          </div>
        </div>
        
      </div>
      
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import Popup from '../popup'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'api-logs',

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    Popup,
    DateTimePicker,
    JsonViewer
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        provider: null,
        failed: null,
        retries: null,
      },

      updateSettings: false,
      appliedFilters: null,
      loading: false,
      changeRetryDelay: false,
      changeMaximumRetries: false,
      
      detailModal: false,
      selectedItem: null,

      page: 1,
      perPage: 25,
      pages: [],

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],
      statusOptions: [
        {id: '200', text: '200 OK'},
        {id: '401', text: '401 Unauthorized'},
        {id: '403', text: '403 Forbidden'},
        {id: '404', text: '404 Not Found'},
        {id: '500', text: '500 Internal Server Error'},
        {id: '502', text: '502 Bad Gateway'},
        {id: '504', text: '504 Gateway Timeout'},
      ],

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    logs () {
      let data =  this.$store.state.apilog.data || []

      if (this.appliedFilters) {
        if (this.appliedFilters.provider) {
          data = data.filter(item => item.providerId == this.appliedFilters.provider )
        }

        if (this.appliedFilters.failed) {
          data = data.filter(item => item.status.toString() == this.appliedFilters.failed)
        }

        if (this.appliedFilters.retries) {
          data = data.filter(item => item.failures == this.appliedFilters.retries )
        }
      }

      return data
    },

    filteredData () {
      return this.paginate(this.logs);
    },

    system () {
      return this.$store.getters['system/get'](window.appData.system.id)
    },

    providerOptions () {
      let data =  this.$store.state.providersList.data || []
      data = data.filter(item => (item.status == 'Active') || (item.status =='Testing'))
      let options = []
      data.forEach(provider => {
        options.push({
          id: provider.id,
          text: `${provider.provider_name}`,
        })
      })
      return options
    },

  },

  async created () {
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    await this.$store.commit('system/setSingle', window.appData.system)
    await this.refreshHooks();
  },

  methods: {
    view (item) {
      this.detailModal=true;
      this.selectedItem=item;
    },
    
    providerForId (id) {
      let data =  this.$store.state.providersList.data || []
      return data.find(item=>item.id==id) || {}
    },
    
    search () {
      this.appliedFilters = { ...this.form }
    },

    resetItem() {
      this.appliedFilters = null
      Object.keys(this.form).forEach(key => this.form[key] = null)
    },

    updateHookSettings () {
      this.updateSettings = true
    },

    setPages () {
      let numberOfPages = Math.ceil(this.logs.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async saveHookSettings() {
      const loader = this.$loading.show()
      this.loading=true;
      try {
        if(this.changeRetryDelay)
          this.system.retryDelayDate = 'CURRENT_TIMESTAMP'
        if(this.changeMaximumRetries)
          this.system.maximumRetriesDate = 'CURRENT_TIMESTAMP'
        await this.$store.dispatch('system/update', { id: 1, payload: this.system })
        await this.$store.dispatch('system/get', this.system.id)
        this.updateSettings = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
      this.loading=false;
      loader.hide()
    },

    async refreshHooks () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}

      if (!this.appliedFilters) {
        params.created=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.appliedFilters.fromDate)
          params.createdAfter=[">=", this.appliedFilters.fromDate+" 00:00:00", "created"];
        if (this.appliedFilters.toDate)
          params.createdBefore=["<=", this.appliedFilters.toDate+" 23:59:59", "created"];
      }
      await this.$store.dispatch('apilog/load', { order: ['ID:DESC'], params })
      this.loading=false;
      loader.hide()
    },

  },

  watch: {
    logs () {
      this.setPages();
    },

    appliedFilters () {
      this.refreshHooks();
    },
  },

  validations () {

  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px; 
  }
  .errors {
    color: #ff0000 !important;
  }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
</style>
