<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <div class="mnb-field">
      <label>Date Range</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          class="position-bottom-right"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
      </div>
    </div>

    <div class="mnb-field">
      <label>Scan Number</label>
      <input class="mnb-input" type="text" v-model="form.number"/>
    </div>

    <div class="mnb-field">
      <label>Profile</label>
      <input class="mnb-input" type="text" v-model="form.profile"/>
    </div>

    <div class="mnb-field">
      <label>Name</label>
      <input class="mnb-input" type="text" v-model="form.name"/>
    </div>

    <div class="mnb-field">
      <label>Scan Type</label>
      <select v-model="form.scanType" class="mnb-input">
        <option></option>
        <option
          v-for="option in typeOptions"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </select>
    </div>
    
    <div class="mnb-field">
      <label>Scan Result</label>
      <select v-model="form.scanResult" class="mnb-input">
        <option></option>
        <option
          v-for="option in resultOptions"
          :key="option"
          :value="option">
          {{ option }}
        </option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        profile: null,
        name: null,
        scanType: null,
        scanResult: null,
        number: null,
      },

      resultOptions: ['Not Started', 'Pending', 'Completed', 'Error'],
      typeOptions: ['KYC Report'],

      profileReviewStatuses: window.profileReviewStatuses,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
