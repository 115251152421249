<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <div class="mnb-field">
      <label>Date Range</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          class="position-bottom-right"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" />
      </div>
    </div>

    <div class="mnb-field">
      <label>Profile Number</label>
      <input class="mnb-input" type="text" v-model="form.profileNumber"/>
    </div>

    <div class="mnb-field">
      <label>Name</label>
      <input class="mnb-input" type="text" v-model="form.name"/>
    </div>

    <div class="mnb-field">
      <label>Profile Type</label>
      <select v-model="form.type" class="mnb-input">
        <option></option>
        <option value="Business">Business</option>
        <option value="Personal">Personal</option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Status</label>
      <select v-model="form.status" class="mnb-input">
        <option :value="null"></option>
        <option v-for="status in customerStatuses" :key="status" :value="status">{{ status | uppercase }}</option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        profileNumber: null,
        name: null,
        type: null,
        status: null,
      },

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  computed: {
    customerStatuses () {
      return window.customerStatuses
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
