<template>
  <div>
    <section>
      <div class="pin-entry">
        <div class="box">
          <div class="pf-row">
            <div class="pf-field">
              <h3>Access Denied</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

  export default {
    name: 'system-access-denied',
  }
</script>
