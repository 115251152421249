import jQuery from 'jquery';
var $=window.$=jQuery;

import moment from 'moment';
window.moment=moment;

import Vue from 'vue';
window.Vue=Vue;

import './lib/jquery-ui.min';
import './lib/api';
import './lib/validation';
import './lib/globals'
import './lib/filters'

var VueComponents = {};

var vueComponentList = [
  require.context(".", false, /.+\.vue$/),
  require.context("./form-fields", false, /.+\.vue$/),
  require.context("./usermgmt", false, /.+\.vue$/),
  require.context("./monbi", false, /.+\.vue$/),
  require.context("./components", false, /.+\.vue$/),
  require.context("./monbi/layout", false, /.+\.vue$/),
  require.context("./monbi/system-messages", false, /.+\.vue$/),
  require.context("./monbi/compliance", false, /.+\.vue$/),
  require.context("./monbi/transactions", false, /.+\.vue$/),
  require.context("./monbi/accounting", false, /.+\.vue$/),
  require.context("./monbi/system", false, /.+\.vue$/),
  require.context("./monbi/control-center", false, /.+\.vue$/),
  require.context("./monbi/support", false, /.+\.vue$/),
  require.context("./monbi/profile", false, /.+\.vue$/),
  require.context("./monbi/customers", false, /.+\.vue$/),
  require.context("./monbi/partners", false, /.+\.vue$/),
  require.context("./monbi/settings", false, /.+\.vue$/),
  require.context("./monbi/settings/employees", false, /.+\.vue$/),
  require.context("./monbi/tools", false, /.+\.vue$/),
];

for(let vueComponentListItem of vueComponentList)
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    VueComponents[actualKey]=actualComponent;
    Vue.component(actualKey, actualComponent);
  }

import './lib/common';



$(function($) {
  $(".mob-handle").click(function(e) {
    e.preventDefault();
    $(".mainside").toggleClass("open");
  });
//   $(".pop-show, .pop-box > .close").click(function(e) {
//     e.preventDefault();
//     $(".popup").toggleClass("open");
//   });
  $(".s-main ul li .title, .support-page .s-main > ul > li span").click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass("open");
  });
  $(".tsm-handle").click(function(e) {
    e.preventDefault();
    $(".cof-form").toggleClass("open");
  });
  $(".cof-close").click(function(e) {
    e.preventDefault();
    $(".cof-form").toggleClass("open");
  });
  $(".ddcontrol").click(function(e) {
    e.preventDefault();
    $(".uscontrol .dropdown").toggleClass("open");
  });
  $(".mainside nav > ul > li a").click(function(e) {
    if (!$(this).parent().hasClass("open") && $(this).next("ul").length) {
      e.preventDefault();
      $(this).parent().toggleClass("open");
    }
  });
  $(".content-form input").each(function() {
    var field=$(this);
    var parent=field.parent();
    var hasData=false;
    var focused=false;
    var update=function() {
      if (hasData || focused)
        parent.addClass("filled");
      else 
        parent.removeClass("filled");
    }
    field.on("change", function() {
      hasData=!!field.val();
      update();
    }).on("focus", function() {
      focused=true;
      update();
    }).on("blur", function() {
      focused=false;
      update();
    });
    
  });


  $('.textarea-profile-notes').on('keyup', function (e){
    $(this).css('height', 'auto' );
    $(this).height( this.scrollHeight );
  });

  var subtabs=$(".client-properties .sub-tabs>li>a");
  var subtabParents=$(".client-properties .sub-tabs>li");
  var subtabContents=$(".client-properties .cp-rows");
  subtabs.click(function(e) {
    e.preventDefault();
    subtabParents.removeClass("active").eq(subtabs.index(this)).addClass("active");
    subtabContents.removeClass("active").eq(subtabs.index(this)).addClass("active");
  });

  $('.mainside .hamburger').on('click', function (e) {
    document.cookie = `sidebar_collapsed=${$('.mainside').hasClass('collapsed') ? 'false' : 'true'}`;
    $('.mainside').toggleClass('collapsed');
  });
});
