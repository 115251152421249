import Vue from 'vue'

export default entity => {
  const state = {
    count: 0,
    data: null,
    singles: {}
  }
  
  const getters = {
    get: state => id => state.singles[id],
    list: state => Object.values(state.singles)
  }

  const actions = {
    async load ({ commit }, { params = {}, order = [], limit = null, start = null, skip = [] } = { params: {}, order: [], limit: null, start: null, skip: [] }) {
      let response = (await api.list(entity, params, order, limit, start, skip))
      const data = response.items
      const count = response.count
      commit('set', data)
      commit('setCount', count)
      data.forEach(item => commit('setSingle', item))
      return data
    },

    async get ({ commit }, id) {
      const data = (await api.get(entity, id)).item
      commit('setSingle', data)
      return data
    },

    async create ({ commit }, payload) {
      const data = (await api.create(entity, payload, true)).item
      commit('add', data)
      commit('setSingle', data)
      return data
    },
  
    async update ({ commit }, { id, payload }) {
      const data = (await api.update(entity, id, payload, true)).item
      commit('update', data)
      commit('setSingle', data)
      return data
    },
  
    async delete ({ commit }, id) {
      await api.delete(entity, id)
      return commit('delete', id)
    },
  
    async upload ({ commit }, { id, file, params = null }) {
      const data = (await api.upload(entity, id, file, params)).item
      commit('update', data)
      commit('setSingle', data)
      return data
    },

    async action ({ commit }, { id, action, payload = null }) {
      const data = await api.action(entity, id, action, payload)
      commit('update', data.item)
      commit('setSingle', data.item)
      return data
    },
  }
  
  const mutations = {
    add (state, data) {
      if (state.data) {
        state.data.push(data)
      } else {
        state.data = [data]
      }
    },

    set (state, data) {
      Vue.set(state, 'data', data)
    },

    setCount (state, count) {
      state.count = count
    },

    update (state, data) {
      if (state.data) {
        const idx = state.data.findIndex(item => item.id == data.id)
        Vue.set(state.data, idx, data)
      }
    },

    setSingle (state, data) {
      Vue.set(state.singles, data.id, data)
    },

    delete (state, id) {
      Vue.delete(state.singles, id)

      if (state.data) {
        const idx = state.data.findIndex(item => item.id === id)
        if (idx >= 0) state.data.splice(idx, 1)
      }
    }
  }

  return {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  }
}
