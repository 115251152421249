<template>
  <div class="cp-box">
    <ul class="sub-tabs" style="margin-bottom: 12px">
      <li :class="{ active: activeTab === 'statements' }" @click="activeTab = 'statements'"><a>Stats</a></li>
      <li :class="{ active: activeTab === 'profiles' }" @click="activeTab = 'profiles'"><a>Profiles</a></li>
      <li :class="{ active: activeTab === 'accounts' }" @click="activeTab = 'accounts'"><a>Accounts</a></li>
      <li :class="{ active: activeTab === 'commission' }" @click="activeTab = 'commission'"><a>Commission</a></li>
    </ul>
    <hr>

    <accounts-setup v-if="activeTab === 'accounts'" :partner="partner"/>
    <profiles-setup v-if="activeTab === 'profiles'" :partner="partner"/>
    <account-statements v-if="activeTab === 'statements'" :partner="partner"/>
    <partner-commission v-if="activeTab === 'commission'" :partner="partner" />
  </div>
</template>

<script>
import AccountsSetup from './AccountsSetup'
import ProfilesSetup from './ProfilesSetup'
import AccountStatements from './AccountStatements'
import PartnerCommission from './PartnerCommission'

export default {

  components: {
    AccountsSetup,
    AccountStatements,
    PartnerCommission,
    ProfilesSetup
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'statements',
      featureStatus: null,
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    }
  },

  async created() {
    await this.getFeatureStatus();
  },

  methods: {
    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Dispute Manager']
    },
  }
}
</script>