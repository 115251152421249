<template>
  <div v-if="user" class="users-profile">
    <section>
      <div>
        <h2 class="inner-headers">My Profile</h2>
      </div>
    </section>
    <section class="client-properties active">
      <div class="cp-box">
        <ul class="ctabs" style="margin-bottom:50px">
          <li :class="{ active: active === 1 }" @click="active = 1"><a>My Info</a></li>
          <li :class="{ active: active === 2 }" @click="active = 2"><a>My Accounts</a></li>
          <li :class="{ active: active === 3 }" @click="active = 3"><a>My Settings</a></li>
          <li :class="{ active: active === 4 }" @click="active = 4"><a>My Access</a></li>
          <li :class="{ active: active === 5 }" @click="active = 5"><a>Change Password</a></li>
        </ul>
        
        <div v-if="active === 1">
          <ul class="sub-tabs">
            <li :class="{ active: subActive === 'profile' }" @click="subActive = 'profile'"><a>Profile</a></li>
            <li :class="{ active: subActive === 'Contacts' }" @click="subActive = 'Contacts'"><a>Contacts</a></li>
          </ul>
          <div v-if="subActive === 'profile'">
            <registration-data-info
              v-if="registration.type === 'Business'"
              ref="contacts"
              :registration="registration" 
              hideContacts
              :showEdit="false"/>
            <registration-data-info
              v-else
              ref="contacts"
              :registration="registration"
              hideContacts
              :showEdit="false"/>
          </div>
          <div v-if="subActive === 'Contacts'">
            <contacts :registration="registration" :showEditAdd="false" />
          </div>
        </div>

        <div v-if="active === 2">
          <ul class="sub-tabs">
            <li :class="{ active: accoutsActive === 'accounts' }" @click="accoutsActive = 'accounts'"><a>Accounts</a></li>
          </ul>
          <div v-if="accoutsActive === 'accounts'">
            <div>
              <section class="content-filters" style="margin: 0 0 30px 0;">
                <div class="cof-form">
                  <div class="cof-row">
                    <div class="cof-field">
                      <label>Select Account</label>
                      <mnb-select v-model="mainAccount" :options="accountOptions"/>
                    </div>
                  </div>
                </div>
              </section>
              <template v-if="mainAccount">
                <template>
                  <div style="display: flex; column-gap: 50px;" class="content-table-split">
                    <div style="width: 30%;">
                      <h2 class="balance">Details</h2>
                      <section  class="content-tlist" style="margin: 30px 0;">
                        <table class="content-table">
                          <tr class="content-filters">
                            <td class="title">Number</td>
                            <td>{{ account.accountNumber }}</td>
                          </tr>
                          <tr>
                            <td class="title">Label</td>
                            <td>{{ account.label }}</td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title">Status</td>
                            <td>
                              <em :class="statusColorClasses[getStatus(account.accountStatus)]">
                                {{ getStatus(account.accountStatus) }}
                              </em>
                            </td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title">Key</td>
                            <td>
                              {{ account.accountKey }}
                            </td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title">Plan</td>
                            <td>
                              {{ account.accountPlan? account.feePlan.name : '' }}
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Settings</td>
                            <td>
                              <a class="link" @click="modalFeeScheduleType = 'Fee'">View</a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title" colspan="2" style="color: black;">Options</td>
                          </tr>
                          <tr class="content-filters">
                            <td colspan="2"></td>
                          </tr>
                        </table>
                        <div class="footer space">
                          <div>
                            <template>
                              <button class="second" style="visibility: hidden;">&nbsp;</button>
                            </template>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div style="width: 30%;">
                      <h2 class="balance">Balances</h2>
                      <section  class="content-tlist" style="margin: 30px 0;">
                        <table class="content-table">
                          <tr class="content-filters">
                            <td class="title">Currency</td>
                            <td>USD</td>
                          </tr>
                          <tr>
                            <td class="title">Balance</td>
                            <td>{{ account.systemTotalBalance | balance }}</td>
                          </tr>
                          <tr>
                            <td class="title">Available</td>
                            <td>{{ account.systemAvailableBalance | balance }}</td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                          </tr>
                          <tr>
                            <td colspan="2"></td>
                          </tr>
                          <tr class="content-filters">
                            <td colspan="2"></td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title" colspan="2" style="color: black;">Options</td>
                          </tr>
                          <tr class="content-filters">
                            <td colspan="2">
                            </td>
                          </tr>
                        </table>
                        <div class="footer space">
                          <div>
                            <template>
                              <button class="second" style="visibility: hidden;">&nbsp;</button>
                            </template>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div v-if="account.profileSettings && account.profileSettings.debitCards" style="width: 30%;">
                      <h2 class="balance">Cards</h2>
                      <section  class="content-tlist" style="margin: 30px 0;">
                        <table class="content-table">
                          <tr>
                            <td class="title">Type</td>
                            <td>
                              <template v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus && account.cards[account.cards.length-1].cardType">
                              {{ account.cards[account.cards.length-1].cardType.charAt(0).toUpperCase() }}{{ account.cards[account.cards.length-1].cardType.slice(1) }}
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Label</td>
                            <td>
                              <template v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus">
                              {{ account.cards[account.cards.length-1].label }}
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Status</td>
                            <td>
                              <em v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus" :class="statusColorClasses[account.cards[account.cards.length-1].cardStatus]">
                                {{ account.cards[account.cards.length-1].cardStatus }}</em>
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Number</td>
                            <td>
                              <template v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus">
                                {{ account.cards[account.cards.length-1].cardLast4.substr(0,6) }}******{{ account.cards[account.cards.length-1].cardLast4.substr(-4) }}
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Expiry</td>
                            <td>
                              <template v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus">
                                {{ account.cards[account.cards.length-1].expiryMonth }} / {{ account.cards[account.cards.length-1].expiryYear }}
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td class="title">Settings</td>
                            <td>
                              <a style="cursor: pointer;" v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus" @click="openCard = true">Settings</a>
                              &nbsp;&nbsp;&nbsp;
                              <template v-if="account.cards.length > 0 && account.cards[account.cards.length-1].cardStatus">
                                <a style="cursor: pointer;" v-if="account.cards[account.cards.length-1].cardStatus == 'pendingActivation' && account.cards[account.cards.length-1].cardType != 'virtual'" @click="openDelivery = true">Delivery</a>
                              </template>
                            </td>
                          </tr>
                          <tr class="content-filters">
                            <td colspan="2"></td>
                          </tr>
                          <tr class="content-filters">
                            <td class="title" colspan="2" style="color: black;">Options</td>
                          </tr>
                          <tr class="content-filters">
                            <td colspan="2">
                              <button class="main" @click="openViewCard = true">View Card</button>
                            </td>
                          </tr>
                        </table>
                        <div class="footer space">
                          <div>
                            <template>
                              <button class="second" style="visibility: hidden;">&nbsp;</button>
                            </template>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>

        <div v-if="active === 3">
          <ul class="sub-tabs">
            <li :class="{ active: settingsActive === 'settings' }" @click="settingsActive = 'settings'"><a>Settings</a></li>
          </ul>
          <div v-if="settingsActive === 'settings'">
            <settings-setup :registration="registration" :noDateUpdate="false" :hideVerificationPermissionsReview="false"/>
          </div>
          <div v-if="settingsActive === 'fee'">
            <fees-schedule :registration="registration" :noUpdate="false"/>
          </div>
        </div>

        <div v-if="active === 4">
          <ul class="sub-tabs">
            <li :class="{ active: accessActive === 'users' }" @click="accessActive = 'users'"><a>Users</a></li>
            <li :class="{ active: accessActive === 'userLog' }" @click="accessActive = 'userLog'"><a>User Log</a></li>
          </ul>
          <div v-if="accessActive === 'users'">
            <div>
              <button :disabled="!selected" class="btn btn-block" @click="block()">Block</button>
              <button v-if="registration.profileSettings.create_subusers" class="btn btn-primary" @click="openAddUser()">Add Sub-User</button>
              <button
                :disabled="!selected || selected.id === registration.user.id"
                class="btn btn-primary"
                @click="remove()">
                Remove Sub-User
              </button>

              <section  class="content-tlist" style="margin: 30px 0;">
                <table class="content-table">
                  <tr>
                    <th></th>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Activation Date</th>
                  </tr>
                  <tr v-for="(user, i) in users" :key="i">
                    <td><span></span>
                      <input type="radio" @change="onCbxClicked(user)" name="access-user">
                    </td>
                    <td><span>Username</span>{{ user.username }}</td>
                    <td><span>First Name</span>{{ user.firstName }}</td>
                    <td><span>Last Name</span>{{ user.lastName }}</td>
                    <td><span>Activation Date</span>{{ user.activationDate | date('MM/D/YYYY hh:mm A') }}</td>
                  </tr>
                </table>
              </section>

              <popup :open.sync="addModal">
                <template v-if="addModal">
                  <h4 class="popup-title">Add Sub-User</h4>
                  <div class="scrollable">
                    <div class="content-form">
                      <div class="form-row form-split">
                        <form-field-text v-model="form.firstName" :maxlength="25" :validator="$v.form.firstName" label="First Name" required />
                        <form-field-text v-model="form.lastName" :maxlength="25" :validator="$v.form.lastName" label="Last Name" required />
                      </div>
                      <div class="form-row form-split">            
                        <form-field-text v-model="form.email" :validator="$v.form.email" label="Email Address" required />
                        <form-field-text v-model="form.phone" :maxlength="15" :validator="$v.form.phone" label="Phone Number" required />
                      </div>
                    </div>
                  </div>
                  <div class="buttons">
                    <button class="main" @click="add()">Add</button>
                    <button class="second" @click="addModal = false">Cancel</button>
                  </div>
                </template>
              </popup>
            </div>
          </div>
          <div v-if="accessActive === 'userLog'">
            <ul
              v-if="lastLogin"
              class="cp-fields justify-content-none">
              <li>
                <span>Last Login</span>
                <div class="val">
                  {{ lastLogin.recorded | date }}
                </div>
              </li>
              <li>
                <span>Data Transfer</span>
                <div class="val">
                  {{ lastLogin.transferred | fileSize }}
                </div>
              </li>
              <li>
                <span>IP Address</span>
                <div class="val">
                  {{ lastLogin.ipAddress }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <change-password v-if="active === 5" />
        <popup :open.sync="openCard">
          <div class="popup-header">
            <h3>Card Settings</h3>
          </div>
          <div v-if="mainAccount && account.cards.length > 0" class="scrollable">
            <div class="content-form">
              <div class="form-row form-three">
                <div>
                  <p><b>ATM Access</b></p>
                  <p v-if="account.cards[account.cards.length-1].atmAccess">Yes</p>
                  <p v-else>No</p>
                </div>
                <div>
                  <p><b>Limit Amount</b></p>
                  <p>
                    <template v-if="account.cards[account.cards.length-1].limitAmount == 0 || account.cards[account.cards.length-1].limitAmount == null">
                      None
                    </template>
                    <template v-else>
                      {{ account.cards[account.cards.length-1].limitAmount }}
                    </template>
                  </p>
                </div>
                <div>
                  <p><b>Limit Interval</b></p>
                  <p>
                    <template v-if="account.cards[account.cards.length-1].limitInterval">
                      {{ account.cards[account.cards.length-1].limitInterval.charAt(0).toUpperCase() }}{{ account.cards[account.cards.length-1].limitInterval.slice(1) }}
                    </template>
                    <template v-else>None</template>
                  </p>
                </div>
              </div>
            </div>
            <div class="content-form">
              <p><b>Allowed Categories</b></p>
              <p>{{ account.cards[account.cards.length-1].allowedCategories }}</p>
            </div>
            <div class="content-form">
              <p><b>Blocked Categories</b></p>
              <p>{{ account.cards[account.cards.length-1].blockedCategories }}</p>
            </div>
            <div class="content-form">
              <p><b>Allowed Merchants</b></p>
              <p>{{ account.cards[account.cards.length-1].allowedMerchants }}</p>
            </div>
            <div class="content-form">
              <p><b>Blocked Merchants</b></p>
              <p>{{ account.cards[account.cards.length-1].blockedMerchants }}</p>
            </div>
            <div class="buttons">
              <button class="second" @click="openCard = false">Close</button>
            </div>
          </div>
        </popup>

        <popup :open.sync="openDelivery">
          <div class="popup-header">
            <h3>Card Delivery</h3>
          </div>
          <div v-if="mainAccount && account.cards.length > 0" class="scrollable">
            <div class="content-form">
              <div class="form-row">
                <div>
                  <p><b>Delivery Status</b></p>
                  <p v-if="account.cards[account.cards.length-1].shipping && account.cards[account.cards.length-1].shipping.deliveryStatus">{{ account.cards[account.cards.length-1].shipping.deliveryStatus.charAt(0).toUpperCase() }}{{ account.cards[account.cards.length-1].shipping.deliveryStatus.slice(1) }}</p>
                </div>
                <div>
                  <p><b>ETA</b></p>
                  <p v-if="account.cards[account.cards.length-1].shipping">
                    {{ account.cards[account.cards.length-1].shipping.eta }}
                  </p>
                </div>
                <div>
                  <p><b>Delivery Partner</b></p>
                  <p v-if="account.cards[account.cards.length-1].shipping">
                    {{ account.cards[account.cards.length-1].shipping.deliveryPartner }}
                  </p>
                </div>
                <div>
                  <p><b>Tracking Number</b></p>
                  <p v-if="account.cards[account.cards.length-1].shipping">
                    {{ account.cards[account.cards.length-1].shipping.trackingNumber }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="account.cards[account.cards.length-1].shipping && account.cards[account.cards.length-1].shipping.shippingAddress" class="content-form">
              <p><b>Shipping Address</b></p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.line1 }}</p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.line2 }}</p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.city }}</p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.state }}</p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.country }}</p>
              <p class="address-line">{{ account.cards[account.cards.length-1].shipping.shippingAddress.postalCode }}</p>
            </div>
            <div class="buttons">
              <button class="second" @click="openDelivery = false">Close</button>
            </div>
          </div>
        </popup>

        <popup :open.sync="openFee">
        <div class="popup-header">
          <h3>Fee Schedule</h3>
        </div>
          <div v-if="feeSchedule" class="">
            <table class="content-table">
              <tr>
                <th style="width: 15%;">Fee Name</th>
                <th style="width: 10%;">Fee</th>
                <th style="width: 10%;">Deposit</th>
                <th style="width: 10%;">Withdrawal</th>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Volume Fee
                </td>
                <td>
                  <span>Fee</span>
                    {{ feeSchedule.volumeFee.fee | formattedTwoDecimal }} %
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Monthly Access Fee
                </td>
                <td>
                  <span>Fee</span>
                    $ {{ feeSchedule.monthlyAccessFee.fee | formattedTwoDecimal }}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Domestic Wire
                </td>
                <td>
                  <span>Fee</span>
                  
                </td>
                <td>
                    $ {{ feeSchedule.domesticWire.per_deposit | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.domesticWire.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  International Wire
                </td>
                <td>
                  <span>Fee</span>
                  
                </td>
                <td>
                    $ {{ feeSchedule.internationalWire.per_deposit | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.internationalWire.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  ACH
                </td>
                <td>
                  <span>Fee</span>
                  
                </td>
                <td>
                    $ {{ feeSchedule.ach.per_deposit | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.ach.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Account Transfer
                </td>
                <td>
                  <span>Fee</span>
                  
                </td>
                <td>
                  
                </td>
                <td>
                    $ {{ feeSchedule.accountTransfer.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Checks
                </td>
                <td>
                  <span>Fee</span>
                  
                </td>
                <td>
                    $ {{ feeSchedule.checks.per_deposit | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.checks.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Exchange
                </td>
                <td>
                  <span>Fee</span>
                    $ {{ feeSchedule.exchange.fee | formattedTwoDecimal }}
                </td>
                <td>
                  
                </td>
                <td>
                
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Assets
                </td>
                <td>
                  <span>Fee</span>
                    $ {{ feeSchedule.assets.fee | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.assets.per_deposit | formattedTwoDecimal }}
                </td>
                <td>
                    $ {{ feeSchedule.assets.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Dormant Accounts
                </td>
                <td>
                  <span>Fee</span>
                    $ {{ feeSchedule.dormantAccounts.fee | formattedTwoDecimal }}
                </td>
                <td>
                  
                </td>
                <td>
                
                </td>
              </tr>
              <tr>
                <td>
                  <span>Fee Name</span>
                  Debit Card
                </td>
                <td>
                  <span>Fee</span>
                    $ {{ feeSchedule.debitCard.fee | formattedTwoDecimal }}
                </td>
                <td>
                  
                </td>
                <td>
                    $ {{ feeSchedule.debitCard.per_withdrawal | formattedTwoDecimal }}
                </td>
              </tr>
            </table>
            <div class="buttons">
              <button class="second" @click="openFee = false">Close</button>
            </div>
          </div>
      </popup>
      </div>
    </section>
    <popup :open.sync="openViewCard" class="view-card">
      <div class="scrollable">
        <div class="card-data-container" v-if="account && account.cards.length > 0">
          <div class="card-number">
          {{ account.cards[account.cards.length-1].cardLast4.substr(0,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(4,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(8,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(12, 4) }}
          </div>
          <div class="card-exp-m">
          {{ account.cards[account.cards.length-1].expiryMonth }}/{{ account.cards[account.cards.length-1].expiryYear.substr(-2) }}
          </div>
          <div class="card-cvv">
          {{ account.cards[account.cards.length-1].cvv }}
          </div>
          <div class="cardholder">
          {{ this.registration.data.firstName }} {{ this.registration.data.lastName }}
          </div>
        </div>
        <div class="buttons text-center">
          <button class="second" @click="openViewCard = false">Close</button>
        </div>
      </div>
    </popup>
    <accounts-card-activation-modal :account="account" :registration="registration" v-if="modalActivationType":modal-type="modalActivationType" @close="modalActivationType = null"/>
    <accounts-card-pin-modal :account="account" :registration="registration" :pinData="modalPinData" v-if="modalPinData && modalPinData.provider=='Solid'" @close="modalPinData = null" @refresh="loadPinModal" />
    <accounts-card-data-modal :account="account" :registration="registration" :pinData="modalCardData" v-if="modalCardData && modalCardData.provider=='Solid'" @close="modalCardData = null" @refresh="loadCardModal" />
    <accounts-fee-schedule-modal :account="account" :registration="registration" v-if="modalFeeScheduleType" :modal-type="modalFeeScheduleType" :provider="provider" @close="modalFeeScheduleType = null"/>
  </div>
</template>

<script>
import { alphaNum } from '../../lib/validators'
import { required, numeric, email } from 'vuelidate/lib/validators'

import ChangePassword from './ChangePassword'
import helper from '../../components/common/helper-mixin'
import RegistrationDataInfo from '../customers/RegistrationDataInfo'
import Contacts from '../customers/ProfileInfoContacts'
import SettingsSetup from '../customers/SettingsSetup'

import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import MnbSwitch from '../../components/ui/forms/Switch'

const initialForm = {
  domWires: null,
  intlWires: null,
  ach: null,
  checks: null,
  remoteDeposit: null,
  exchange: false,
  acc_to_acc: null,
  assets: null,
  trade_desk: null,
  multiCurrency: null,
  debitCards: null,
  domWires_limit: null,
  intlWires_limit: null,
  ach_limit: null,
  checks_limit: null,
  exchange_limit: null,
  acc_to_acc_limit: null,
  assets_limit: null,
  trade_desk_limit: null,
  multi_currency_limit: null,
  debit_cards_limit: null,

  administrative: null,
  double_verification: null,

  create_subusers: null,
  create_subprofiles: null,

  welcome_email: null,
  review_days: null,
  language: null
}

export default {
  name: 'UsersProfile',

  mixins: [helper, vueMixins.helpers],

  components: {
    Contacts,
    MnbInput,
    MnbSwitch,
    MnbSelect,
    SettingsSetup,
    ChangePassword,
    RegistrationDataInfo
  },

  data () {
    return {
      active: null,
      subActive: 'profile',
      accoutsActive: 'accounts',
      settingsActive: 'settings',
      accessActive: 'users',
      openCard: false,
      openDelivery: false,
      openFee: false,
      openViewCard: false,

      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      },

      selected: null,
      addModal: false,

      systemMaintenanceStatus: window.maintenance,
      featureStatus: true,
      mainAccount: null,
      modalActivationType: null,
      modalPinData: null,
      modalCardData: null,
      modalFeeScheduleType: null,

      featureFundsStatus: null,
      featureAssetsStatus: null,
      featureTradeStatus: null,
      featureAdvancedAccountRoutingStatus: null,
      featureCardServicesStatus: null,

      currencyStatus : {
        AUD : false,
        CAD : false,
        EUR : false,
        GBP : false,
        JPY : false,
      },

      initialForm: { ...initialForm },
      updateSettings: false,

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],

      permsNew: window.appData.currentUser?.role?.perms?.includes('new-account') || window.appData.currentUser?.perms?.includes('new-account'),
    }
  },

  computed: {
    user () {
      return this.$store.getters['users/get'](this.currentUser.id)
    },

    currentUser () {
      return window.appData.currentUser
    },

    lastLogin () {
      return this.user?.lastLoginData || null
    },

    registration () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    statusColorClasses () {
      return {
        Open: 'c1',
        Pending: 'c2',
        Closed: 'c4',
        Dormant: 'c3',
        Hold: 'c2',
        Restricted : 'c2',
        active: 'c1',
        pendingActivation: 'c2',
        canceled: 'c3',
        inactive: 'c3',
      }
    },

    users () {
      if(this.registration?.user?.id) {
        return this.$store.getters['users/list'].filter(user => parseInt(user.id) === parseInt(this.registration.user.id) || parseInt(user.subuserOf) === parseInt(this.registration.user.id))
      }
    },

    assetsList () {
      let currencies = window.currencies || []
      let assets = [];
      currencies = currencies.filter(currency => {
        return currency.systemCurrencyType === 'Assets'
      })
      currencies.forEach(currency => {
        assets.push(currency.currencyNickname)
      })
      return assets;
    },

    accountOptions () {
      let accounts = []
      this.registration.accounts.forEach(account => {
        accounts.push({
          id: account.id,
          text: `${account.accountNumber} - ${account.serviceProviders.label}`,
        })
      })
      return accounts
    },

    account () {
      return this.registration.accounts.find(item => item.id === this.mainAccount)
    },

    profileSettings () {
      return this.account?.profileSettings || null
    },

    provider () {
      return this.providers.find(provider => provider.id === this.account.serviceProviders?.id)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    feeSchedule () {
      return this.account?.feeSchedule
    },
  },

  watch: {
    'mainAccount' () {
      this.initialForm = this.profileSettings
    }
  },

  async created () {
    const loader = this.$loading.show()
    await this.getFeatureStatus();
    if(this.currentUser.regId) {
      await this.$store.dispatch('registrations/get', this.currentUser.regId)
    }
    await this.$store.dispatch('users/get', this.currentUser.id)

    const active = parseInt(this.getUrl('active'))
    if(active) {
      if ([1, 2, 3, 4, 5].includes(active)) {
        this.active = active
      }
    }else{
      this.active = 1
    }

    if(this.registration.user?.id) {
      this.$store.dispatch('users/get', this.registration.user.id)
      this.$store.dispatch('users/load', { params: { subuserOf: this.registration.user.id } })
    }
    if (this.profileSettings) {
      this.initialForm = this.profileSettings
    }
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    loader.hide()
  },

  methods: {
    async remove () {
      if (!this.selected || this.selected.id === this.registration.user.id) return
      if (!confirm('Are you sure you want to remove selected Sub-User?')) return
      
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('users/delete', this.selected.id)
        this.$toast.success('Sub-User removed successfully.')
        this.selected = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open',
        pendingActivation: 'Pending Activation',
        active: 'Active',
        inactive: 'Inactive',
        canceled: 'Canceled',
      }
      return names[status] || status
    },

    openAddUser () {
      this.addModal = true
      this.form = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      }
      this.$v.$reset()
    },

    async add () {
      this.$v.$touch()
      if (this.$v.$invalid) return null

        const loader = this.$loading.show()
      try {
        const response = await this.$store.dispatch('registrations/action', {
          id: this.registration.id,
          action: 'CreateSubUser',
          payload: {
            ...this.form,
            regId: this.currentUser.regId
          }
        })
        this.$store.commit('users/add', response.action)
        this.$store.commit('users/setSingle', response.action)
        this.$toast.success('Sub-User added successfully.')
        this.addModal = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async block () {
      if (!this.selected) return
      if (!confirm('Are you sure you want to block selected users?')) return null

      const loader = this.$loading.show()
      try {
        if (parseInt(this.selected.id) !== this.registration.user.id && this.selected.active === 'Yes') {
          await this.$store.dispatch('users/action', { id: this.selected.id, action: 'Block' })
          this.$toast.success('Selected users are now Blocked.')
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    onCbxClicked (user) {
      this.selected = user
    },

    getCurrencyName (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    getTagRequired (currencyNickname) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyNickname
      })
      return currencies[0]? ( currencies[0].requiresTag? 'Yes' : 'No' ) : 'No'
    },

    getAssetsSystemAssetAvailableBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetAvailableBalance : null
    },

    getAssetsSystemAssetTotalBalance(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].systemAssetTotalBalance : null
    },

    getIncomingAssetWalletAddress(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].incomingAssetWalletAddress : null
    },

    getTag(account, currencyNickname) {
      let wallets = account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0]? wallets[0].tag : null
    },

    async wallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'Wallet',
          payload: {
            currencyNickname: currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if(response.action.error) {
          this.open=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async getWallet(currencyNickname, id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'GetWallet',
          payload: {
            currencyNickname: currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if(response.action.error) {
          this.open=true
          this.message = "Error Received - Wallet Address not Set. Please check information and try again - " + response.action.errors
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    getCurrencyStatus(fundsCurrencies, currency) {
      let fundsCurrency = fundsCurrencies.find(item => item.currency.currencyNickname == currency)
      if(fundsCurrency){
        return fundsCurrency.currencyEnabled
      }else{
        return null
      }
    },

    getSystemFundsAvailableBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemAvailableBalance : null
    },

    getSystemFundsTotalBalance(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].systemTotalBalance : null
    },

    getFundsCurrencySymbol(account, currencyNickname) {
      let fundsCurrencies = account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0]? fundsCurrencies[0].currency.symbol : null
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      this.featureAssetsStatus = window.systemFeatures['Digital Assets']
      this.featureTradeStatus = window.systemFeatures['Trade Desk']
      this.featureAdvancedAccountRoutingStatus = window.systemFeatures['Advanced Account Routing']
      this.featureCardServicesStatus = window.systemFeatures['Card Services']
    },

    async accountKey(id) {
      const loader = this.$loading.show()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'AccountKey',
          payload: {

          }
        }))
        if(response.action.error) {

        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
    async loadPinModal() {
      const loader = this.$loading.show()
      try {
        var data=(await api.action("Cards", this.account.cards[this.account.cards.length-1].id, "pinToken")).action;
        this.modalPinData=data;
      } finally {
        loader.hide();
      }
    },
    
    async loadCardModal() {
      const loader = this.$loading.show()
      try {
        var data=(await api.action("Cards", this.account.cards[this.account.cards.length-1].id, "pinToken")).action;
        this.modalCardData=data;
      } finally {
        loader.hide();
      }
    },
    
  },

  validations: {
    form: {
      firstName: { required, alphaNum },
      lastName: { required, alphaNum },
      phone: { required, numeric },
      email: { required, email },
    },

    initialForm: {
      domWires: { required },
      intlWires: { required },
      ach: { required },
      checks: { required },
      exchange: { required },
      acc_to_acc: { required },
      assets: { required },
      trade_desk: { required },
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0;
}
.space-bottom {
  margin-bottom: 20px;
}
.balance {
  color: #1f2d4e;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  display: block;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #67c23a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #67c23a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.content-table .title{
  color: #99aabb;
  font-weight: bold;
}

.content-table td{
  font-weight: bold;
}

.card-number, .card-exp-m, .card-cvv, .cardholder {
  font-weight: bold;
  font-size: 18px;
}
</style>
