<template>
  <div class="">
    <template v-if="open">
      <section>
        <div>
          <h2 class="inner-headers"></h2>
        </div>
      </section>

      <div class="cp-box">
        <ul class="sub-tabs" style="margin-bottom: 12px">
          <li :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'"><a>Registrations/Profiles</a></li>
          <li :class="{ active: activeTab === 'account' }" @click="activeTab = 'account'"><a>Accounts</a></li>
          <li :class="{ active: activeTab === 'transaction' }" @click="activeTab = 'transaction'"><a>Transactions</a></li>
        </ul>

        <db-registrations v-if="activeTab === 'profile'" />
        <db-accounts v-if="activeTab === 'account'" />
        <db-transactions v-if="activeTab === 'transaction'" />

      </div>
    </template>
  </div>
</template>

<script>
import DbRegistrations from './DbRegistrations.vue'
import DbAccounts from './DbAccounts.vue'
import DbTransactions from './DbTransactions.vue'

export default {

  components: {
    DbRegistrations,
    DbAccounts,
    DbTransactions
  },

  data () {
    return {
      activeTab: 'profile',
      open: true,
    }
  },

  computed: {

  },

  async created () {

  },

  methods: {

  },

  validations () {

  }
}
</script>

<style scoped>
  .cp-box {
    padding: 24px 16px 4px;
    margin: 15px 30px 15px;
    border-radius: 0 0 16px 16px;
  }

  .sub-tabs {
    margin-top: -24px;
    padding-bottom: 14px;
    margin-bottom: 10px;
    padding: 12px 16px 0px;
    margin: 12px -16px;
    display: flex;
    box-sizing: border-box;
    padding-right: 40%;
    flex-wrap: wrap;
  }

  .sub-tabs li {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .sub-tabs li.active a {
    background: #1f2d4e;
    background: transparent;
    color: #067AB1;
    text-decoration: underline;
  }

  .sub-tabs li a {
    display: block;
    height: 28px;
    line-height: 28px;
    padding: 0 16px;
    border-radius: 8px;
    cursor: pointer;
  }
</style>