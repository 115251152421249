<template>
  <div v-if="!loading">
    <section v-if="!processFlag">
      <div class="flex-between" style="position: relative;">
        <div style="padding: 24px 30px 10px 10px;">
          <button v-if="$hasPerm('funds_processing')" class="btn btn-success" @click="open('deposit')">+ New Deposit</button>
          <el-popover
            placement="bottom-end"
            width="300"
            popper-class="paddingless-popper"
            trigger="click">
            <table class="content-table smaller">
              <tr>
                <th>Funds</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>Deposit</td>
                <td class="text-center">{{totalFundsDepositNumber}}</td>
                <td class="text-right">${{ totalDepositAmount | balance_without_symbol}}</td>
              </tr>
              <tr>
                <td>Withdrawals</td>
                <td class="text-center">{{totalFundsWithdrawalNumber}}</td>
                <td class="text-right">${{ totalWithdrawalAmount | balance_without_symbol}}</td>
              </tr>
              <tr>
                <th>Assets</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>Deposit</td>
                <td class="text-center">{{totalAssetsDepositNumber}}</td>
                <td class="text-right">{{ totalAssetsDepositAmount }}</td>
              </tr>
              <tr>
                <td>Withdrawals</td>
                <td class="text-center">{{totalAssetsWithdrawalNumber}}</td>
                <td class="text-right">{{ totalAssetsWithdrawalAmount }}</td>
              </tr>
            </table>
            <button slot="reference" class="btn second"><i class="el-icon-tickets" /> Summary</button>
          </el-popover>
        </div>
        <div style="padding: 24px 30px 10px 10px;">
          <template v-if="totalNumber > 0">
            <button v-if="filters" class="btn second" @click="downloadModal = true"><i class="el-icon-download" />Download</button>
            <button v-else class="btn second" @click="downloadModal = true"><i class="el-icon-download" />Download</button>
          </template>
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div v-if="!modalType && !processFlag" class="accordion">
      <div :class="{'trans-sub-cof-box' : true, 'cof-box-active' : activeInfo}">
        <section  class="content-tlist" v-if="!loading">
          <el-table class="content-table" :data="filteredData" @row-click="transactionsTableClick" ref="transactionsTable">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="popup-header" v-for="selectedTransaction in [[props.row]]">
                  <h3>Transaction</h3>
                  <h3 v-if="selectedTransaction">{{ selectedTransaction[0].transactionId }}</h3>
                  <h3 v-if="selectedTransaction">Status: {{ selectedTransaction[0].status }}</h3>
                </div>
                <template v-for="selectedTransaction in [[props.row]]">
                  <div class="scrollable">
                    <div class="information">
                      <template v-if="selectedTransaction">
                        <div>
                          <p><b>Account</b>: ****{{ selectedTransaction[0].accountId.substr(-3) }}</p>
                          <p><b>Created</b>: {{ selectedTransaction[0].createdAt }}</p>
                          <p><b>Entry Type</b>: {{ selectedTransaction[0].entryType }}</p>
                          <p><b>Currency Type</b>: {{ selectedTransaction[0].currencyType }}</p>
                          <p><b>Transaction Amount</b>:
                            <template v-if="selectedTransaction[0].currencyType == 'Assets'">{{ selectedTransaction[0].assetAmount ? parseFloat(selectedTransaction[0].assetAmount) : '' }}</template>
                            <template v-else>{{ selectedTransaction[0].amount | balance }}</template>
                          </p>
                        </div>
                        <div>
                          <p><b>Profile</b>: {{ selectedTransaction[0].profileNumber }}</p>
                          <p><b>Completed By</b>: {{ selectedTransaction[0].completedBy }}</p>
                          <p><b>Transaction Type</b>: {{ transactionTypeText[selectedTransaction[0].transactionType] }}</p>
                          <p><b>Currency Name</b>:{{ selectedTransaction[0].currencyName }} - {{ getCurrencyName(selectedTransaction[0].currencyName) }}</p>
                          <p><b>Transaction Fee</b>: $ <template v-if="selectedTransaction[0].transactionFee">{{ selectedTransaction[0].transactionFee | formattedTwoDecimal }}</template><template v-else>0.00</template></p>
                        </div>
                      </template>
                    </div>
                    <div class="popup-header details">
                      <h3>Details</h3>
                    </div>
                      <template v-if="selectedTransaction">
                        <template v-if="selectedTransaction[0].entryType == 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>{{ getCurrencyName(selectedTransaction[0].currencyName) }} Deposit Wallet Address</b>:</p>
                          <p>{{ selectedTransaction[0].contributionIncomingWalletAddress }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <template v-if="selectedTransaction[0].entryType != 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>Pay to</b>: {{ selectedTransaction[0].label }}</p>
                          <p><b>Pay to Wallet Address</b>: {{ selectedTransaction[0].outgoingWalletAddress }}</p>
                          <p><b>Destination Tag</b>: {{ selectedTransaction[0].tag }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <div class="information">
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payer</b>: <template v-if="selectedTransaction[0].remoteDepositPayor">{{ selectedTransaction[0].remoteDepositPayor.nameOfSender }}</template></p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].remoteDepositPayor">
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.address }}</p>
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.city }}, {{ selectedTransaction[0].remoteDepositPayor.state }}, {{ selectedTransaction[0].remoteDepositPayor.country }}, {{ selectedTransaction[0].remoteDepositPayor.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Check Front</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageFront}`" target="_blank">{{ selectedTransaction[0].checkImageFront }}</a></p>
                                <p><b>Check Bank</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageBack}`" target="_blank">{{ selectedTransaction[0].checkImageBack }}</a></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                <br/>
                                <p><b>Note</b>: Send only {{ getCurrencyName(selectedTransaction[0].currencyName) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <template v-if="selectedTransaction[0].provider == 'Solid' || selectedTransaction[0].provider == 'Solid Test'">
                                  <template v-if="selectedTransaction[0].transactionType == 'ach'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">name: {{ selectedTransaction[0].settlementDetails.name }}, routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else-if="selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }} name: {{ selectedTransaction[0].settlementDetails.name }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, Address Line1: {{ selectedTransaction[0].settlementDetails.line1 }}, Address Line2: {{ selectedTransaction[0].settlementDetails.line2 }}, city: {{ selectedTransaction[0].settlementDetails.city }}, state: {{ selectedTransaction[0].settlementDetails.state }}, country: {{ selectedTransaction[0].settlementDetails.country }}, postal: {{ selectedTransaction[0].settlementDetails.postalCode }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else>
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                </template>
                                <template v-else>
                                  <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                  <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                  <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                  <p>&nbsp;</p>
                                  <p>&nbsp;</p>
                                  <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                  <br/>
                                  <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                  <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'internationalWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Swift</b>: {{ selectedTransaction[0].swift }}</p>
                                <br/>
                                <p><b>INTERMEDIARY BANK</b></p>
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].intermediaryBankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].intermediaryBankRoutingNumber }}</p>
                                <p><b>SWIFT</b>: {{ selectedTransaction[0].intermediaryBankSwiftCode }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].intermediaryBankReference }}</p>
                                <p><b>Address</b>:</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.address }}</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.city }}, {{ selectedTransaction[0].intermediaryAddress.state }}, {{ selectedTransaction[0].intermediaryAddress.country }}, {{ selectedTransaction[0].intermediaryAddress.postal }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <!--<p><b>IBAN</b>: {{ selectedTransaction[0].iban }}</p>-->
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p> {{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payee</b>: {{ selectedTransaction[0].payee }}</p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'ach'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'domesticWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing Number</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Account Name</b>: {{ selectedTransaction[0].toAccountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].toAccountId? getAccountNumber(selectedTransaction[0].toAccountId) : '' }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Trade'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'System'">
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <p><b>Account Number</b>: ****{{ selectedTransaction[0].toAccountId? selectedTransaction[0].toAccountId.substr(4, 3) : '' }}
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                            </template>
                          </div>
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'ach' || selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'acc_to_acc' || selectedTransaction[0].transactionType == 'Trade' || selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'Trade'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    <!-- Place for details -->
                    <div class="popup-header notes">
                      <h3>Notes</h3>
                      <img src="/assets/img/green30.png" v-if="!isCustomer && !addNote" class="main plus-img" @click="addNote = true">
                      <button v-if="addNote" class="action-button save add-note-button" @click="saveNote(selectedTransaction[0])">Save</button>
                    </div>
                    <div class="content-form" v-if="addNote">
                      <div class="form-row form-max">
                        <form-field-text v-model="note" />
                      </div>
                    </div>
                    <table class="content-table">
                      <tr>
                        <th style="padding-left: 0;">Date/Time</th>
                        <th>Added By</th>
                        <th>Note</th>
                      </tr>
                      <template v-if="selectedTransaction">
                        <tr v-for="(item, i) in selectedTransaction[0].notes" :key="item.id">
                          <td style="padding-left: 0;"><span>Date/Time</span>{{ item.noteTime | date }}</td>
                          <td><span>Added By</span>{{ item.addedBy }}</td>
                          <td><span>Note</span>{{ item.note }}</td>
                        </tr>
                      </template>
                    </table>
                  </div>
                  <div class="popup-header notes">
                    <h3>Options</h3>
                  </div>
                  <div class="buttons">
                    <div class="optionBtns" v-if="selectedTransaction">
                      <template v-if="$hasPerm('approve-transaction')">
                        <button v-if="!isCustomer && selectedTransaction[0].status === 'Review'" class="approve" @click="approvePopupTransaction(selectedTransaction[0].id)">Approve</button>
                      </template>
                      <template v-if="!isCustomer">
                        <template v-if="perms">
                          <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                        </template>
                      </template>
                      <template v-else>
                        <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                      </template>
                    </div>
                    <button class="main" @click="print(selectedTransaction[0].id)">Print</button>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Date" min-width="7">
              <template slot-scope="props">
                <p>{{ props.row.createdAt | date('MM/D/YYYY') }}<br/>
                {{ props.row.createdAt | date('hh:mm A') }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Account" min-width="10">
              <template slot-scope="props">
                <a v-if="!isCustomer" :href="`/system-customers/registrations/${props.row.regId}?tab=Accounts`">{{ props.row.accountId }}</a>
                <template v-else>{{ props.row.accountId }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Number" min-width="7">
              <template slot-scope="props">
                {{ props.row.transactionId }}
              </template>
            </el-table-column>
            <el-table-column label="Entry" min-width="6">
              <template slot-scope="props">
                {{ props.row.entryType == 'Deposit'? 'DEP' : 'W/D' }}
              </template>
            </el-table-column>
            <el-table-column label="Type" min-width="8">
              <template slot-scope="props">
                {{ transactionTypeText[props.row.transactionType] }}
              </template>
            </el-table-column>
            <el-table-column
              label="Currency" min-width="6"
              prop="currencyName">
            </el-table-column>
            <el-table-column label="Amount" min-width="10">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.assetAmount) }}</template>
                <template v-else>{{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Balance" min-width="8">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.availableAssetsBalance) }}</template>
                <template v-else>{{ props.row.currencySymbol }} {{ props.row.availableFundsBalance | balance_without_symbol }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Status" min-width="7">
              <template slot-scope="props">
                <em :class="statusColorClasses[props.row.status]">{{ props.row.status }}</em>
              </template>
            </el-table-column>
            <el-table-column
              label="Reference" min-width="17"
              prop="reference">
            </el-table-column>
            <el-table-column label="Options" min-width="14">
              <template slot-scope="props">
                <template v-if="$hasPerm('funds_processing')">
                  <template v-if="props.row.provider == 'Think Green'">
                    <button v-if="props.row.status === 'Pending'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                    <button v-if="props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                  </template>
                  <template v-if="props.row.entryType == 'Withdrawal' && props.row.status == 'Pending'">
                    <button class="action-button save" @click="process(props.row)">Process</button>
                  </template>
                  <template v-if="props.row.transactionType != 'System' && props.row.transactionType != 'acc_to_acc' && props.row.status == 'Complete'">
                    <button class="action-button save" @click="process(props.row)">Reversal</button>
                  </template>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>

    <div v-if="!modalType && processFlag" class="accordion">
      <div class="trans-sub-cof-box cof-box-active">
        <section class="content-tlist">
          <div class="" style="padding: 30px;">
            <div class="" style="display: flex; justify-content: space-around;">
              <div style="width: 70%;">
                <a @click="processFlag = false" style="cursor: pointer;color: #51aeff;
    font-weight: bold;"><< Back</a> 
              </div>
              <div style="width: 25%;">
                <h2 class="detail-header">{{ transactionTypeText[processItem.transactionType] }} | {{ processItem.transactionId }} | {{ processItem.status }}</h2>
                <h2 class="detail-header" style="margin-bottom: 15px;">{{ processItem.accountId }}</h2>
              </div>
            </div>
            <div style="display: flex; justify-content: space-around;">
              <div style="width: 70%;">
                <h2 class="balance">Bank</h2>
                <div class="mnb-form">
                  <div class="mnb-row">
                    <form-field-copy-mnb :disabled="!processItem.bankName" v-model="processItem.bankName" label="Name"/>
                    <form-field-copy-mnb :disabled="!processItem.routingNumber" v-model="processItem.routingNumber" label="Routing Number"/>
                    <form-field-copy-mnb :disabled="!processItem.swift" v-model="processItem.swift" label="Swift Code"/>
                  </div>
                  <div class="mnb-row">
                    <form-field-copy-mnb v-if="processItem.bankAddress" :disabled="!processItem.bankAddress.address" v-model="processItem.bankAddress.address" label="Address"/>
                    <form-field-copy-mnb v-else :disabled="!processItem.bankAddress" label="Address"/>
                    <form-field-copy-mnb :disabled="true" label="Address 2"/>
                  </div>
                  <div class="mnb-row">
                    <form-field-copy-mnb v-if="processItem.bankAddress" :disabled="!processItem.bankAddress.city" v-model="processItem.bankAddress.city" label="City"/>
                    <form-field-copy-mnb v-else :disabled="!processItem.bankAddress" label="City"/>
                    <form-field-copy-mnb v-if="processItem.bankAddress" :disabled="!processItem.bankAddress.state" v-model="processItem.bankAddress.state" label="Province/State"/>
                    <form-field-copy-mnb v-else :disabled="!processItem.bankAddress" label="Province/State"/>
                    <form-field-copy-mnb v-if="processItem.bankAddress" :disabled="!processItem.bankAddress.country" v-model="processItem.bankAddress.country" label="Country"/>
                    <form-field-copy-mnb v-else :disabled="!processItem.bankAddress" label="Country"/>
                    <form-field-copy-mnb v-if="processItem.bankAddress" :disabled="!processItem.bankAddress.postal" v-model="processItem.bankAddress.postal" label="Postal/Zip Code"/>
                    <form-field-copy-mnb v-else :disabled="!processItem.bankAddress" label="Postal/Zip Code"/>
                  </div>
                </div>

                <h2 class="balance">Intermediary Bank</h2>
                <div class="mnb-form">
                  <div class="mnb-row">
                    <form-field-copy-mnb :disabled="!processItem.intermediaryBankName" v-model="processItem.intermediaryBankName" label="Name"/>
                    <form-field-copy-mnb :disabled="!processItem.intermediaryBankRoutingNumber" v-model="processItem.intermediaryBankRoutingNumber" label="Routing Number"/>
                    <form-field-copy-mnb :disabled="!processItem.intermediaryBankSwiftCode" v-model="processItem.intermediaryBankSwiftCode" label="Swift Code"/>
                  </div>
                  <template v-if="processItem.intermediaryAddress">
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.address" v-model="processItem.intermediaryAddress.address" label="Address"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.address2" label="Address 2"/>
                    </div>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.city" v-model="processItem.intermediaryAddress.city" label="City"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.state" v-model="processItem.intermediaryAddress.state" label="Province/State"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.country" v-model="processItem.intermediaryAddress.country" label="Country"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress.postal" v-model="processItem.intermediaryAddress.postal" label="Postal/Zip Code"/>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="Address"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="Address 2"/>
                    </div>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="City"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="Province/State"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="Country"/>
                      <form-field-copy-mnb :disabled="!processItem.intermediaryAddress" label="Postal/Zip Code"/>
                    </div>
                  </template>
                </div>
                <h2 class="balance">Beneficiary</h2>
                <div class="mnb-form">
                  <div class="mnb-row">
                    <form-field-copy-mnb :disabled="!processItem.accountName" v-model="processItem.accountName" label="Account Name"/>
                    <form-field-copy-mnb :disabled="!processItem.accountNumber" v-model="processItem.accountNumber" label="Account Number"/>
                    <form-field-copy-mnb :disabled="!processItem.accountType" v-model="processItem.accountType" label="Account Type"/>
                  </div>
                  <template v-if="processItem.address">
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.address.address" v-model="processItem.address.address" label="Address"/>
                      <form-field-copy-mnb :disabled="!processItem.address.address2" v-model="processItem.address.address2" label="Address 2"/>
                    </div>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.address.city" v-model="processItem.address.city" label="City"/>
                      <form-field-copy-mnb :disabled="!processItem.address.state" v-model="processItem.address.state" label="Province/State"/>
                      <form-field-copy-mnb :disabled="!processItem.address.country" v-model="processItem.address.country" label="Country"/>
                      <form-field-copy-mnb :disabled="!processItem.address.postal" v-model="processItem.address.postal" label="Postal/Zip Code"/>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.address" label="Address"/>
                      <form-field-copy-mnb :disabled="!processItem.address" label="Address 2"/>
                    </div>
                    <div class="mnb-row">
                      <form-field-copy-mnb :disabled="!processItem.address" label="City"/>
                      <form-field-copy-mnb :disabled="!processItem.address" label="Province/State"/>
                      <form-field-copy-mnb :disabled="!processItem.address" label="Country"/>
                      <form-field-copy-mnb :disabled="!processItem.address" label="Postal/Zip Code"/>
                    </div>
                  </template>
                </div>

                <h2 class="balance">Additional Information</h2>
                <div class="mnb-form">
                  <div class="mnb-row">
                    <form-field-copy-mnb v-model="processItem.accountName" label="Reference"/>
                    <form-field-copy-mnb v-model="processItem.accountNumber" label="Memo"/>
                  </div>
                </div>
              </div>
              <div style="width: 25%;">
                <h2 class="balance">Options</h2>
                <template v-if="$hasPerm('funds_processing')">
                  <button v-if="processItem.status == 'Pending'" class="btn btn-success" @click="settleModal = true, form = {}">Settle</button><br/>
                  <button v-if="processItem.status == 'Complete'" class="btn btn-success" @click="reversalModal = true, form = {}">Reversal</button><br/>
                  <button class="btn" @click="cancelProcessTransaction(processItem.id)">Cancel</button>
                </template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <popup :open.sync="addConfirmModal" :width="400">
      <div class="popup-header">
        <h3>Confirmation</h3>
      </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row">
              <p v-if="confirmSuccess" class="transaction-status">Transaction Cancelled</p>
              <p v-else class="transaction-status">Error: Unable to Cancel Transaction</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="addConfirmModal = false">Close</button>
        </div>
    </popup>
    <popup :open.sync="addApproveModal" :width="400">
      <div class="popup-header">
        <h3>Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row">
            <p v-if="approveSuccess" class="transaction-status">Transaction Approved</p>
            <p v-else class="transaction-status">Error: Unable to Approve Transaction</p>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="second" @click="addApproveModal = false">Close</button>
      </div>
    </popup>
    
    <transactions-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />

    <add-deposit-panel v-if="modalType" :modal-type="modalType" @close="modalType = null" />

    <popup v-if="processItem" :open.sync="settleModal">
      <div class="popup-header">
        <h3>Settle Transaction {{ processItem.transactionId }}</h3>
      </div>
      <div class="scrollable">
        <div class="mnb-form">
          <div class="form-row form-fullw">
            <div class="cof-field mnb-field">
              <label>Settled Date</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.settledDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  :max-date="today"
                >
                </date-time-picker>
                <div class="error-message" v-if="$v.form.settledDate.$dirty && $v.form.settledDate.$invalid"><span>Please enter settled date.</span></div>
              </div>
            </div>
          </div>
          <div class="form-row form-fullw mnb-field">
            <form-field-textarea v-model="form.details" label="Settlement Details" :rows="3" :maxlength="750" inputClass="mnb-input" />
          </div>
          <div class="form-row form-fullw mnb-field">
            <form-field-textarea v-model="form.instructions" label="Special Instructions" :rows="3" :maxlength="750" inputClass="mnb-input" />
          </div>
        </div>
        <div class="">
          <button class="main" @click="settleTransaction()">Settle</button>
          <button class="second" @click="settleModal = false">Close</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
    </popup>

    <popup v-if="processItem" :open.sync="reversalModal">
      <div class="popup-header">
        <h3>Reverse Transaction {{ processItem.transactionId }}</h3>
      </div>
      <div class="scrollable">
        <div class="mnb-form">
          <div class="form-row form-fullw">
            <div class="cof-field mnb-field">
              <label>Reversal Date</label>
              <div class="cof-multi flex">
                <date-time-picker
                  v-model="form.settledDate"
                  value-type="String"
                  value-format="yyyy-LL-dd"
                  format="yyyy-LL-dd"
                  :time-picker="false"
                  :auto-close="true"
                  :max-date="today"
                >
                </date-time-picker>
                <div class="error-message" v-if="$v.form.settledDate.$dirty && $v.form.settledDate.$invalid"><span>Please enter settled date.</span></div>
              </div>
            </div>
          </div>
          <div class="form-row form-fullw mnb-field">
            <form-field-textarea v-model="form.details" label="Reversal Details" :validator="$v.form.details" :rows="3" :maxlength="750" inputClass="mnb-input"/>
          </div>
        </div>
        <div class="">
          <button class="main" @click="reversalTransaction()">Reversal</button>
          <button class="second" @click="reversalModal = false">Cancel</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
    </popup>

    <popup :open.sync="downloadModal">
      <div class="popup-header">
        <h3>Download</h3>
      </div>
        <div class="scrollable">
          <h1 class="text-center">Choose the Download Format</h1>
          <div class="content-form text-center">
            <div class="form-row form-split depositType">
              <form-field-select value="Excel" label="File Type" :options="[{id: 'Excel', text: 'Excel'}]" required />
            </div>
          </div>
          <div class="">
            <button class="main" @click="downloadExcel()">Download</button>
            <button class="second" @click="downloadModal = false">Cancel</button>
          </div>
        </div>
    </popup>
  </div>
</template>

<script>
  import AddDepositPanel from './AddDepositPanel.vue'
  import MnbSelect from '../../components/ui/forms/Select'
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import TransactionsFilters from './filters/TransactionsFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'
  import FormFieldCopy from '../../components/FormFieldCopy'
  import FormFieldCopyMnb from '../../components/FormFieldCopyMnb'
  import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

  export default {
    name: 'funds-processing',

    components: {
      AddDepositPanel,
      MnbSelect,
      VueEnglishdatepicker,
      DateTimePicker,
      AppliedFilters,
      FormFieldCopy,
      FormFieldCopyMnb,
      TransactionsFilters
    },

    data() {
      return {
        modalType: null,
        openedFilters: false,
        filters: null,

        page: 1,
        perPage: 25,
        pages: [],
        totalNumber: 0,
        totalFundsNumber: 0,
        totalFundsDepositNumber: 0,
        totalFundsWithdrawalNumber: 0,
        totalAssetsNumber: 0,
        totalAssetsDepositNumber: 0,
        totalAssetsWithdrawalNumber: 0,
        totalAmount: 0,
        totalDepositAmount: 0,
        totalWithdrawalAmount: 0,
        totalAssetsAmount: 0,
        totalAssetsDepositAmount: 0,
        totalAssetsWithdrawalAmount: 0,

        addConfirmModal: false,
        confirmSuccess: true,
        addApproveModal: false,
        approveSuccess: true,
        processFlag: false,
        processItem: null,

        selectedTransaction: null,
        addNote: false,
        note: null,
        loading: false,
        systemMaintenanceStatus: window.maintenance,
        activeInfo: true,
        today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
        perms: window.appData.currentUser?.role?.perms?.includes('cancel-transaction') || window.appData.currentUser?.perms?.includes('cancel-transaction'),
        customEnv: appData.customEnv,

        form: {},
        submitError: false,
        settleModal: false,
        reversalModal: false,
        downloadModal: false,
      }
    },

    computed: {
      appData() {
        return window.appData;
      },

      currentUser () {
        return window.appData.currentUser
      },

      isCustomer () {
        return this.currentUser.isCustomer
      },
      appliedFilters () {
        const filterNames = {
          entryType: 'Entry Type',
          depositType: 'Transaction Type',
          transactionId: 'Transaction ID',
          accountId: 'Account Number',
          amount: 'Amount',
          status: 'Status',
          currencyType: 'Currency Type',
          currencyName: 'Currency',
        }
        
        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },

      reg () {
        return this.$store.getters['registrations/get'](this.currentUser.regId)
      },

      currencies () {
        let data = window.currencies || []
        return data
      },

      transactions () {
        let data =  this.$store.state.transactions.data || []
        this.totalNumber = this.$store.state.transactions.count || 0
        return data
      },

      filteredData () {
        return this.transactions;
      },

      transactionTypeText(){
        return {
          checks: 'Check',
          ach: 'ACH',
          domestic_wire: 'Domestic Wire',
          domesticWire: 'Domestic Wire',
          international_wires: 'International Wire',
          internationalWire: 'International Wire',
          acc_to_acc: 'Account Transfer',
          Asset: 'Asset',
          Trade: 'Trade',
          System: 'System',
          DebitCard: 'Debit Card',
        }
      },

      statusColorClasses () {
        return {
          New: 'c1',
          Pending: 'c2',
          pending: 'c2',
          Review: 'c2',
          'On Hold': 'c2',
          Denied: 'c4',
          Complete: 'c1',
          Reversed: 'c3',
          Cancelled: 'c3',
          Error: 'c3',
          Declined: 'c3'
        }
      },
    },

    watch: {
      transactions () {
        this.setPages();
      },
      appliedFilters () {
        this.refreshTransactions();
      },

      activeInfo() {
        this.setPages();
      },

      page() {
        this.refreshTransactions();
      },

      perPage() {
        if(this.page == 1){
          this.refreshTransactions();
        }else{
          this.page = 1
        }
      }
    },
    
    async created() {
      const loader = this.$loading.show()
      this.loading = true
     
      if (this.isCustomer) await this.$store.dispatch('registrations/get', this.currentUser.regId)

      this.$store.dispatch('accounts/load', {skip: ['user', 'contact', 'data', 'fees', 'terms', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'profileNumber', 'partnerId', 'currency', 'status', 'matched', 'verified', 'boardingApproval', 'createdAt', 'updatedAt', 'approvalDate', 'browser', 'browser_lang', 'os', 'device', 'documentId', 'serviceProvidersId', 'termId', 'confirmId', 'createdBy', 'partner', 'result', 'file', 'cipStatus', 'amlStatus', 'agreementId', 'kycStatus', 'confirm', 'exception', 'providerAccountNumber', 'providerContingentHold', 'providerNonContingentHold', 'providerPendingTransfer', 'linked', 'solidLinked', 'response', 'providerCreatedAt', 'primeAccount', 'solidAccount', 'cipMessage', 'cipMessageDetail', 'amlMessage', 'amlMessageDetail', 'kycMessage', 'kycMessageDetail', 'reviewDetail', 'QPaymentReference', 'statusUpdatedAt', 'accountKey', 'contactFundsTransferReferencesId', 'personId', 'businessId', 'kybStatus', 'idvStatus', 'phoneVerified', 'emailVerified', 'kycResults', 'kybResults', 'idvResults', 'documentResults', 'reviewCode', 'reviewMessage', 'metadata', 'programID', 'idv', 'disclosureStatus', 'members', 'membersKYCResults', 'membersIDVResults', 'membersDocumentResults', 'cards', 'owedFee', 'owedFeeLastUpdate', 'agreementResponse', 'webhookId', 'webhookResponse',  'partnerId', 'chargeOff', 'chargeOffBalance', 'accountPlan', 'feeSchedule', 'feePlan', 'mainAccount', 'setupComplete', 'complianceReview', 'currencyName', 'regType', 'regAdministrative', 'regReviewDays', 'label', 'assetsWallets', 'fundsCurrencies', 'serviceProviders' ]})

      this.refreshTransactions();
      this.loading = false
      loader.hide()
    },

    methods: {
      open (type) {
        this.modalType = type
      },

      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      clearFilters () {
        this.filters = null
      },

      getFilterValue (key, value) {
        if (key === 'currencyName') return this.getCurrencyName(value)
        if (key === 'amount') return window.formatMoney(value)

        if (key === 'entryType') return window.entryTypes[value]
        if (key === 'currencyType') return window.currencyTypes[value]
        if (key === 'status') return window.transactionStatuses[value]

        if (key === 'depositType') return window.transactionTypes[value]

        const dateFilters = ['fromDate', 'toDate']
        if (dateFilters.includes(key)) return moment(value).format('LL')

        return value
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      resetFilters () {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      setPages () {
        let numberOfPages = 0;
        if(this.activeInfo) {
          numberOfPages = Math.ceil(this.totalNumber / this.perPage);
        }

        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      async paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },

      async cancelTransaction(id) {
        if(confirm(`Are you sure you want to Cancel this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "Cancel")).action
            if(status.error)
              this.confirmSuccess = false
            else
              this.confrimSucess = true
            this.addConfirmModal = true
            await this.refreshTransactions();
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async cancelProcessTransaction(id) {
        if(confirm(`Are you sure you want to Cancel this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "Cancel")).action
            if(status.error)
              this.confirmSuccess = false
            else
              this.confrimSucess = true
              this.processFlag = false
              this.filters = null
              this.page = 1
              await this.refreshTransactions();
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async cancelAssetTransaction(id) {
        if(confirm(`Are you sure you want to Cancel this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "CancelAsset")).action
            if(status.error)
              this.confirmSuccess = false
            else
              this.confrimSucess = true
            this.addConfirmModal = true
            await this.refreshTransactions();
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async authRetry(id) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "AuthRetry")).action
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async cancelPopupTransaction(id) {
        if(confirm(`Are you sure you want to Cancel this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "Cancel")).action
            if (status.error)
              this.confirmSuccess = false
            else
              this.confrimSucess = true
            await this.refreshTransactions();
            this.selectedTransaction = this.transactions.filter(item => item.id === id)
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async approveTransaction(id) {
        if(confirm(`Are you sure you want to Approve this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "Approve")).action
            if (status.error)
              this.approveSuccess = false
            this.addApproveModal = true
            await this.refreshTransactions();
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async approveSolidTransaction(item, id) {
        if(confirm(`Are you sure you want to Approve this transaction?`)) {
          const loader = this.$loading.show()
          try {
            let status
            if(item.remoteDepositPayor) {
              status = (await api.action("Transactions", id, "RemoteDepositSolidApi")).action
            }else{
              status = (await api.action("Transactions", id, "Approve")).action
            }
            if (status.error)
              this.approveSuccess = false

            this.addApproveModal = true
            await this.refreshTransactions();
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async approvePopupTransaction(id) {
        if(confirm(`Are you sure you want to Approve this transaction?`)) {
          const loader = this.$loading.show()
          try {
            const status = (await api.action("Transactions", id, "Approve")).action
            if (status.error)
              this.approveSuccess = false
            await this.refreshTransactions();
            this.selectedTransaction = this.transactions.filter(item => item.id === id)
          } catch (e) {
            this.$toast.error(window.errorMessage)
          } finally {
            loader.hide()
          }
        }
      },

      async updateTrasactions() {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", "new", "UpdateTransactions")).action
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async saveNote () {
        const payload = {
          transactionId: this.selectedTransaction[0].id,
          addedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          note: this.note,
          noteTime: 'CURRENT_TIMESTAMP'
        }

        await this.$store.dispatch('transactionNotes/create', payload)
        this.addNote = false
        this.note = null
        await this.refreshTransactions();
        this.selectedTransaction = this.transactions.filter(item => item.id === this.selectedTransaction[0].id)
      },

      async refreshTransactions () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        let orders = {}
        params.provider = 'Think Green'
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
          params.status='Pending';
          orders = ['createdAt:DESC', 'transactionId:DESC'];
        } else {
          if (this.filters.fromDate)
            params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
          if (this.filters.toDate)
            params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
          if (this.filters.depositType) {
            params.transactionType = this.filters.depositType
          }
          if (this.filters.entryType) {
            params.entryType = this.filters.entryType
          }
          if (this.filters.profileId) {
            params.profileId = this.filters.profileId
          }
          if (this.filters.transactionId) {
            params.transactionId = this.filters.transactionId
          }
          if (this.filters.accountId) {
            params.accountId = this.filters.accountId
          }
          if (this.filters.amount) {
            params.amount = this.filters.amount
          }
          if (this.filters.status) {
            params.status = this.filters.status
          }
          if (this.filters.currencyType) {
            params.currencyType = this.filters.currencyType
          }
          if (this.filters.currencyName) {
            params.currencyName = this.filters.currencyName
          }
          orders = ['transactionId:DESC'];
        }
        if (this.isCustomer) {
          const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
          if (reg && reg.accounts.length) {
            params.accountId=['OR']
            reg?.accounts.forEach(account => {
              params.accountId.push(account.accountNumber)
            })
          }
        }
        var items = await this.$store.dispatch('transactions/load', { order: orders, params, limit: this.perPage, start: (this.page-1) * this.perPage })
        this.totalAmount = 0;
        this.totalDepositAmount = 0;
        this.totalWithdrawalAmount = 0;
        this.totalAssetsAmount = 0;
        this.totalAssetsDepositAmount = 0;
        this.totalAssetsWithdrawalAmount = 0;
        this.totalFundsNumber = 0;
        this.totalFundsDepositNumber = 0;
        this.totalFundsWithdrawalNumber = 0;
        this.totalAssetsNumber = 0;
        this.totalAssetsDepositNumber = 0;
        this.totalAssetsWithdrawalNumber = 0;
        for(var i = 0; i < items.length; i++) {
          if(items[i].currencyType == 'Assets') {
            if(items[i].entryType == 'Deposit') { 
              this.totalAssetsDepositNumber++
              if(parseFloat(items[i].assetAmount))
                this.totalAssetsDepositAmount += parseFloat(items[i].assetAmount)
            }else{
              this.totalAssetsWithdrawalNumber++
              if(parseFloat(items[i].assetAmount))
                this.totalAssetsWithdrawalAmount += parseFloat(items[i].assetAmount)
            }
          }else{
            if(items[i].entryType == 'Deposit') {
              this.totalFundsDepositNumber++
              if(parseFloat(items[i].amount))
                this.totalDepositAmount += parseFloat(items[i].amount)
            }else{
              this.totalFundsWithdrawalNumber++
              if(parseFloat(items[i].amount))
                this.totalWithdrawalAmount += parseFloat(items[i].amount)
            }
          }
        }


        this.loading=false;
        loader.hide()
      },

      getCurrencyName (currencyName) {
        let currencies = window.currencies || []
        currencies = currencies.filter(currency => {
          return currency.currencyNickname === currencyName
        })
        return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
      },

      async print (id) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Print")).action
          window.open("/files/transactions/"+id+"/"+id+"-transaction.pdf");
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      getAccountNumber(accountNumber) {
        let number = ''
        for(var i = 0; i < accountNumber.length-3; i++) {
        number = number + '*'
        }
        number = number + accountNumber.substr(-3)
        return number
      },

      process(item) {
        this.form = {}
        this.processItem = item
        this.processFlag = true
      },

      async settleTransaction () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          this.submitError = true
          return
        }
        this.submitError = false
        if(confirm(`Are you sure you want to settle this transaction?`)) {
          const loader = this.$loading.show()
          let payload;
          payload = {
            details: this.form.details,
            instructions: this.form.instructions,
            settledDate: this.form.settledDate,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          }
          this.transaction = (await api.action("Transactions", this.processItem.id, "SettleTransaction", payload)).item
          this.filters = null
          this.page = 1
          this.settleModal = false
          this.processFlag = false
          this.refreshTransactions()
          loader.hide()
        }
      },

      async reversalTransaction () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          this.submitError = true
          return
        }
        this.submitError = false
        if(confirm(`Are you sure you want to reverse this transaction?`)) {
          const loader = this.$loading.show()
          let payload;
          payload = {
            details: this.form.details,
            reversalDate: this.form.settledDate,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
          }
          this.transaction = (await api.action("Transactions", this.processItem.id, "ReversalTransaction", payload)).item
          this.reversalModal = false
          this.processFlag = false
          this.filters = null
          this.page = 1
          this.refreshTransactions()
          loader.hide()
        }
      },

      downloadExcel() {
        if(this.filters){
          window.open(`/export_funds_processing/${this.filters.fromDate}/${this.filters.toDate}/${this.filters.depositType}/${this.filters.entryType}/${this.filters.transactionId}/${this.filters.accountId}/${this.filters.amount}/${this.filters.status}/${this.filters.currencyType}/${this.filters.currencyName}`);
        }else{
          window.open("/export_funds_processing///////////");
        }
        this.downloadModal = false
      },

      transactionsTableClick(row) {
        this.$refs.transactionsTable.toggleRowExpansion(row);
      },
    },

    validations () {
      let rules = {}
        rules = {
          form: {
            settledDate: { required },
          }
        }
      return rules
    }
  }
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  tr {
    td {
      padding: 5px;
    }
  }
}
.buttons {
  margin-top: 30px;
}

.information {
  display: flex;
  justify-content: space-between;

  div {
    width: 50%; 
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  .optionBtns .main {
    margin-left: 15px; 
  }
}
.form-max {
  div {
    width: 100%;
  }
}
button.cancel {
  background: #666;
  color: white;
}
button.approve {
  background: #67c23a;
  color: white;
}
.btn-approve {
  background: #67c23a ;
  color: white;
}

.search-item {
  input {
    max-width: 110px;
  }
}

.image-link {
  color: #51aeff;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.trans-cof-box {
  position: relative;
  height: 51px;
  overflow: hidden;
  padding: 0 16px;
  margin: 15px 30px;
  background: #f8f9fb;
  border-radius: 16px;

  .accordion-headline {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    height: 19px;
    padding: 16px 0;
    cursor: pointer;
  }

  .total-number {
    position: absolute;
    top: 15px;
    right: 40%;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
}

  .trans-sub-cof-box {
    position: relative;
    height: 0px;
    overflow: hidden;
    padding: 0 16px;
    margin: 0px 30px;
    background: #f1f4f7;
    border-radius: 16px;
  }
.trans-cof-box::after {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: 30px;
  left: unset;
  width: 13px;
  height: 1px;
  transition: .25s ease-out;
  background-color: #0480c6;
  transform: rotate(90deg);
}
.trans-cof-box::before {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: 30px;
  width: 13px;
  height: 1px;
  transition: .25s ease-out;
  background-color: #0480c6;
}
.trans-cof-box-active::before {
  transform: rotate(180deg);
}
.trans-cof-box-active::after {
  transform: rotate(180deg);
}
.accordion .cof-box-active {
  height: auto;
}

.summary-table td {
  padding: 3px 20px;
}

.summary-part {
  position: absolute;
  top: 15px;
  right: 41%;
}

.flex-between {
}

.content-tlist {
  border: none;
}

.content-table.smaller {
  tr td, tr th {
    height: 20px;
    padding: 5px 15px;
  }
}

.balance {
  color: #1f2d4e;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.form-four >div {
  width: calc(25% - 18px);
  @media screen and (max-width: 760px) {
    width: 100%;
  }
}

.detail-header {
  color: #1f2d4e;
  font-size: 16px;
  font-weight: 700;
}

.depositType {
  justify-content: center;
}
.add-note-button {
  min-width: 50px !important;
}

.content-tlist {
  background: none; 
}
</style>
