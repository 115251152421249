<template>
  <section class="form-box reg-form login-form">
    <template>
      <h2>{{ !resetsuccess ? 'Reset Password' : 'Reset Password Confirmation' }}</h2>
    </template>

    <template v-if="!resetsuccess">
      <p class="resetPasswordText">Please enter your username below to reset your password</p>
      <form>
        <div class="content-form">
          <div class="form-row form-fullw form-inputfield">
            <form-field-text v-model="form.username" label="User name" :validator="$v.form.username" required  />
          </div>
          <p v-if="notFound" class="resetPasswordText">Username not found. Please check your information and try again. Contact support if you continue to experience issues</p>
          <p v-else-if="notActive" class="resetPasswordText">Your username has not been activated. Please activate and try again. If your activation code has expired, contact support to receive a new activation code.</p>
          <p v-else class="resetPasswordText">If your username is found in our system, you will receive an email with instructions on how to reset your password.</p>
          <div>
            <button @click.prevent="resetPassword()">Reset Password</button>
          </div>
        </div>
        <div class="form-row form-fullw">
          <div class="txtc">
            <a href="/login">Login</a>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <p class="resetPasswordText confirmation">Please check your email for instructions on how to change your password</p>
      <form>
        <div class="form-row form-fullw">
          <div class="txtc">
            <a href="/login">Login</a>
          </div>
        </div>
      </form>
    </template>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {

  data() {
    return {
      form: {
        username: null
      },
      resetsuccess: false,
      notFound: false,
      notActive: false,
    }
  },  

  methods: {
    async resetPassword () {
      if (this.$v.form.$invalid) return
      if (!confirm('Are you sure you want to reset password?')) return null

      const userExists = await api.request('GET', '/user-exists', { username: this.form.username })
      const loader = this.$loading.show()
      try {
        if (userExists.user) {
          await this.$store.dispatch('users/action', {
            id: userExists.user.id,
            action: 'ResetPassword'
          })
          this.$toast.success('Password has been successfully reset.')
          this.resetsuccess = true
          this.notFound = false
          this.notActive = false
        } else if(userExists.notFound){
          this.notFound = true
          this.notActive = false
          this.$toast.error(userExists.error)
        } else if(userExists.notActive) {
          this.notFound = false
          this.notActive = true
        }else{
          this.$toast.error(userExists.error)
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
  },

  validations: {
    form: {
      username: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .resetPasswordText {
    text-align: center;
    margin: 30px 0;
    font-size: 16px;

    &.confirmation {
      margin: 70px 0;
    }
  }
  .form-inputfield {
    margin-top: 50px; 
  }
  .txtc {
    margin: 20px 0;
    text-align: center;

    a {
      color: #13ce67;
    }
  }
</style>