<template>
  <div>
    <popup :open.sync="open">
      <div class="popup-header">
        <h3>Account Analysis</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row form-split justify-content-center">
            <form-field-select v-model="form.currencyType" label="Select Analysis Type" :validator="$v.form.currencyType" :options="currencyTypeOptions" required />
          </div>
        </div>
        <div class="buttons text-center">
          <button class="main" @click="run()" :disabled="this.$v.form.$invalid">Start</button>
          <button class="second" @click="open = false">Close</button>
        </div>
      </div>
    </popup>
    <popup :open.sync="error" :width="400">
      <div class="popup-header">
        <h3>Error</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row">
            <p>{{errors}}</p>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="second" @click="error = false">Close</button>
      </div>
    </popup>
  </div>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      open: true,
      form: {
        currencyType: null,
        currency: null,
      },
      error: false,
      errors: null,
      featureStatus: null,
    }
  },

  computed: {
    currencyTypeOptions () {
      const options = [
        {
          id: 'Charged-Off Accounts',
          text: 'Charged-Off Accounts',
        },
        {
          id: 'Dormant Accounts',
          text: 'Dormant Accounts',
        }
      ]
      return options
    },

    currencyOptions () {
      let currencies = window.currencies || []
      let options = []
      if(this.form.currencyType == 'Funds') {
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Funds') {
            options.push({
              id: currency.id,
              text: `${currency.currencyNickname}-${currency.name}`,
            })
          }
        })
      }

      if(this.form.currencyType == 'Assets') {
        currencies.forEach(currency => {
          if(currency.systemCurrencyType == 'Assets') {
            options.push({
              id: currency.id,
              text: `${currency.currencyNickname}-${currency.name}`,
            })
          }
        })
      }

      return options
    },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    async run() {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        return
      }

      this.$parent.currencyType = this.form.currencyType
      const loader = this.$loading.show()
      try {
        let payload = {
          currencyType: this.form.currencyType,
        }
        let action
        if(this.form.currencyType == 'Dormant Accounts') {
          action = (await api.action("Account", "new", "DormantAccounts", payload)).action
          this.$parent.accounts = action.accounts
          this.$parent.dormantStatus = false
        }else{
          action = (await api.action("Account", "new", "ChargedOffAccounts", payload)).action
          this.$parent.accounts = action.accounts
//          action = (await api.action("Account", "new", "StartProviderCashAssets", payload)).action
        }
        if (action.error) {
          this.error = true
          this.errors = action.errors
        } else {
          this.error = false
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        this.open = false
        loader.hide()
      }
    },

    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Account Reconciliation']
    },
  },

  async created() {
    await this.getFeatureStatus();
  },

  validations () {
    const rules = {
      form: {
        currencyType: { required },
      },
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 30px;
  }
</style>