<template>
  <div v-if="employee" class="cp-box">
    <ul class="cp-rows active">
      <li>
        <h4>General Information</h4>
        <ul class="cp-fields justify-content-none">
          <li>
            <span>Employee ID</span>
            <div class="val">
              {{ employee.employeeId }}
            </div>
          </li>
          <li>
            <span>Role</span>
            <div class="val">
              {{ employee.role.name }}
            </div>
          </li>
          <li>
            <span>First Name</span>
            <div class="val">
              {{ employee.firstName }}
            </div>
          </li>
          <li>
            <span>Last Name</span>
            <div class="val">
              {{ employee.lastName }}
            </div>
          </li>
        </ul>
      </li>
      <li>
        <h4>Contact Information</h4>
        <ul class="cp-fields justify-content-none">
          <li>
            <span>Email</span>
            <div class="val">
              {{ employee.email }}
            </div>
          </li>
          <li>
            <span>Phone</span>
            <div class="val">
              {{ employee.phone }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    employee: {
      type: Object,
      default: null
    }
  }
}
</script>