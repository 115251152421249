<template>
  <div>
    <section v-if="mode=='Overview'">
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <button v-if="mode=='Overview'" class="btn btn-success" @click="mode = 'New'" style="margin-top:-55px"><i class="el-icon-plus" /> New Registration</button>

    <applied-filters v-if="mode=='Overview'" :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />
    
    <div v-if="mode=='Overview'" class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date <img src="/assets/img/updown.png" width="13" @click="sort('createdAt')"/></th>
              <th>Updated</th>
              <th>Number</th>
              <th>Name</th>
              <th>Type</th>
              <th>Status</th>         
              <th>Options</th>         
            </tr>
            <tr v-for="registration in filteredData" :key="registration.id">
              <td><span>Profile Date</span>{{ registration.createdAt | date('MM/D/YYYY') }}</td>
              <td><span>Updated</span><template v-if="registration.updatedAt">{{ registration.updatedAt | date('MM/D/YYYY') }}</template></td>
              <td><span>Profile</span>
                <a v-if="!partner" :href="`/system-customers/${ppage}/${registration.id}`">{{  registration.profileNumber }}</a>
                <template v-else>{{  registration.profileNumber }}</template>
              </td>
              <td>
                <span>Name</span>
                <template v-if="registration.type === 'Personal'">{{ registration.data.firstName }} {{ registration.data.lastName }}</template>
                <template v-else>{{ registration.data.legal_name }}</template>
              </td>
              <td><span>Profile Type</span>{{ registration.type }}</td>
              <td><span>Status</span><em :class="statusColorClasses[registration.status]">
                {{ registration.status === 'Complete' ? 'Activation' : registration.status }}</em></td>    
              <td><span>Options</span>
                <template v-if="registration.status === 'Denied'">
                  <button class="action-button cancel" @click="archive(registration.id)">Archive</button>
                </template>
                <template v-else-if="registration.status !== 'Archived' && oneMonthBefore(registration)">
                  <button class="action-button cancel" @click="archive(registration.id)">Archive</button>
                </template>
                <template v-if="$hasPerm('reject')">
                  <button v-if="registration.status === 'New' || registration.status === 'In Process'" class="action-button remove" @click="reject(registration.id)">Reject</button>
                </template>
              </td>          
            </tr>
            <tr v-if="!registrations.length">
              <td colspan="7" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>

    <RegisterForm2 :internal="true" v-else-if="mode=='New'"></RegisterForm2>

    <profile-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import ProfileFilters from './filters/ProfileFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'CustomerProfiles',

  props: {
    ppage: {
      type: String,
      default: 'registrations'
    }
  },

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    ProfileFilters,
    AppliedFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,
    activeInfo: true,
    mode: "Overview",

    loading: false,
    page: 1,
    perPage: 25,
    pages: [],

    sortName: null,
    sortFlag: true,
  }),

  computed: {
    profileStatuses () {
      return window.profileStatuses
    },
    
    appliedFilters () {
      const filterNames = {
        profileId: 'Profile ID',
        name: 'Name',
        type: 'Profile Type',
        status: 'Status'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    profileStatusesWithoutComplete () {
      const array = ['Complete', 'Active', 'Review', 'Restricted', 'Hold', 'Dormant', 'Closed']
      array.splice(0, 1)
      return array
    },

    totalNumber() {
      return this.registrations.length
    },

    registrations () {
      let registrations = this.$store.state.registrations.data || []
     
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        registrations = registrations.filter(reg => {
          if (!reg.approvalDate) return true
          const date = moment(reg.approvalDate.split(' ')[0], 'YYYY-MM-DD')
          return date >= now
        })
      }
      if (this.filters) {
        if (this.filters.name) {
          registrations = registrations.filter(reg => {
            const name = reg.type === 'Personal' ? `${reg.data.firstName} ${reg.data.lastName}` : reg.data.legal_name
            return name.toLowerCase().includes(this.filters.name.toLowerCase())
          })
        }

        if (this.filters.fromDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          registrations = registrations.filter(reg => {
            if (!reg.approvalDate) return true
            const date = moment(reg.approvalDate.split(' ')[0], 'YYYY-MM-DD')
            return date >= from
          })
        }

        if (this.filters.toDate) {
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          registrations = registrations.filter(reg => {
            if (!reg.approvalDate) return true
            const date = moment(reg.approvalDate.split(' ')[0], 'YYYY-MM-DD')
            return date <= to
          })
        }

        if (this.filters?.status) {
          if(this.filters.status == 'Complete') {
            registrations = registrations.filter(reg => reg.status === 'Complete' || reg.status === 'Activation')
          }else{
            registrations = registrations.filter(reg => reg.status === this.filters.status)
          }
        }

        if (this.filters.profileId) {
          registrations = registrations.filter(reg =>{
            return reg.profileNumber?.includes(this.filters.profileId)
          }
          )
        }

        if (this.filters.type) {
          registrations = registrations.filter(reg => reg.type === this.filters.type)
        }
      }
      return registrations
    },

    statusColorClasses () {
      return {
        Active: 'c1',
        New: 'c1',
        Pending: 'c2',
        Restricted: 'c2',
        Complete: 'c2',
        Activation: 'c2',
        Suspended: 'c2',
        Dormant: 'c3',
        Closed: 'c4',
        'In Process': 'c2',
      }
    },

    currentUser () {
      return window.appData.currentUser
    },

    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    filteredData () {
      return this.paginate(this.registrations);
    }
  },

  async created () {
    if(this.currentUser.parId) {
      await this.$store.dispatch('partners/get', this.currentUser.parId)
    }
    await this.refreshRegistrations();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    getFilterValue (key, value) {
      if (key === 'status') return window.customerProfileStatuses[value]

      return value
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    async refreshRegistrations () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.corporate = ['!=',  1];
      
      if(this.partner) {
        params.partnerId = this.partner.id
      }

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        //params.approvalDate=[">=", yesterdayDate+" 00:00:00"];
        params.status=["OR", "New", "Pending", "Activation", "In Process"];
      } else {
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.status)
            params.status=this.filters.status;
      }

      let order = []
        if(this.sortName != null) {
          if(this.sortFlag) {
            order = [this.sortName + ':ASC']
          }else{
            order = [this.sortName + ':DESC']
          }
        }else{
          order = ['createdAt:DESC']
        }

      await this.$store.dispatch('registrations/load', { order: order, params, skip: ['user', 'contact', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'primeAccount', 'accounts', 'complianceScan', 'mainAccount', 'serviceProviders', 'complianceReview', 'setupComplete', 'profileSettings'] })
      this.loading=false;
      this.page = 1
      loader.hide()
    },

    sort(sortName) {
      if(this.sortName == sortName){
        this.sortFlag = !this.sortFlag
      }else {
        this.sortName = sortName
        this.sortFlag = true
      }
    },

    async archive (id, archive = true) {
      const loader = this.$loading.show()
      await this.$store.dispatch('registrations/update', {
        id,
        payload: {
          status: archive ? 'Archived' : 'Denied'
        }
      })
      loader.hide()
    },

    async reject (id) {
      if(confirm(`Are you sure?`)) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: {
            status: 'Denied'
          }
        })
        await this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: id,
          notes: 'Registration Rejected',
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.refreshRegistrations()
        loader.hide()
      }
    },

    oneMonthBefore (reg){
      const today = new Date()
      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 30)
      var dd = String(yesterday.getDate()).padStart(2, '0');
      var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = yesterday.getFullYear();
      var yesterdayDate = yyyy + '-' + mm + '-' + dd;
      const now = moment(yesterdayDate, "YYYY-MM-DD")

      const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
      if(date >= now) {
        return false
      }else{
        return true
      }
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },
  },

  watch: {
    appliedFilters () {
      this.refreshRegistrations();
    },

    filteredData () {
      this.setPages();
    },

    sortName () {
      this.refreshRegistrations();
    },

    sortFlag () {
      this.refreshRegistrations();
    },

    page() {
      this.setPages();
    },

    registrations () {
      this.setPages();
    },

    perPage() {
      if(this.page == 1){
        this.setPages();
      }else{
        this.page = 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>
