<template>
  <div class="content-form">
    <div class="form-row form-split">
      <form-field-text v-model="form.account_name" label="Name of Bank Account" :validator="v.account_name" required />
      <form-field-select v-model="form.account_type" label="Account Type" :validator="v.account_type" :options="accountOptions" required />
    </div>
    <div class="form-row" :class="hideBankAccountType ? 'form-split' : 'form-three'">
      <form-field-text v-model="form.routing_number" label="Routing Number" :validator="v.routing_number" required />
      <form-field-text v-model="form.account_number" label="Bank Account Number" :validator="v.account_number" required />
      <form-field-select v-if="!hideBankAccountType" v-model="form.bank_account_type" label="Bank Account Type" :validator="v.bank_account_type" :options="bankTypeOptions" required />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: this.value,

      accountOptions: [
        {
          id: 'personal',
          text: 'Personal'
        },
        {
          id: 'business',
          text: 'Business'
        }
      ],

      bankTypeOptions: [
        {
          id: 'checking',
          text: 'Checking'
        },
        {
          id: 'savings',
          text: 'Savings'
        }
      ]
    }
  },

  watch: {
    value: {
      handler () {
        this.form = this.value
      },
      deep: true,
      immediate: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  }
}
</script>
