<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">

    <div class="mnb-field">
      <label>Trade Date</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          class="position-bottom-right"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
      </div>
    </div>

    <div class="mnb-field">
      <label>Account</label>
      <input class="mnb-input" type="text" v-model="form.account"/>
    </div>

    <div class="mnb-field">
      <label>Quote Number</label>
      <input class="mnb-input" type="text" v-model="form.quoteNumber"/>
    </div>

    <div class="mnb-field">
      <label>Trade Number</label>
      <input class="mnb-input" type="text" v-model="form.tradeNumber"/>
    </div>

    <div class="mnb-field">
      <label>Type</label>
      <select v-model="form.quoteType" class="mnb-input">
        <option></option>
        <option value="Buy">Buy</option>
        <option value="Sell">Sell</option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Total Amount</label>
      <input class="mnb-input" type="text" v-model="form.totalAmount"/>
    </div>

    <div class="mnb-field">
      <label>Status</label>
      <select v-model="form.quoteStatus" class="mnb-input">
        <option></option>
        <option
          v-for="(value, key) in tradeDeskStatuses"
          :key="key"
          :value="key">
          {{ value }}
        </option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        account: null,
        quoteNumber: null,
        tradeNumber: null,
        quoteType: null,
        totalAmount: null,
        quoteStatus: null,
      },
      
      tradeDeskStatuses: window.tradeDeskStatuses,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
