<template>
  <popup :open.sync="open">
    <div class="popup-header">
      <h3>New Card Request</h3>
    </div>
      <div class="scrollable">
        <template>
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-select v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" required />
              <form-field-select v-model="form.cardType" label="Select Card Type" :validator="$v.form.cardType" :options="cardTypeOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-text v-model="form.cardLimit" label="Enter Card Limit" :validator="$v.form.cardLimit" required />
              <form-field-select v-model="form.cardLimitInterval" label="Card Limit Interval" :validator="$v.form.cardLimitInterval" :options="cardLimitIntervalOptions" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="form.cardBin" label="Card Bin" :validator="$v.form.cardBin" :options="cardBinOptions" required />
              <form-field-select v-model="form.shipTo" label="Ship To" :validator="$v.form.shipTo" :options="shipToOptions" required :disabled="form.cardType == 'virtual'" />
            </div>
            <div class="form-row form-split">
              <div>
                <h3 class="subtitle">Ship to:</h3>
              </div>
              <div>
                <h3 v-if="balanceError" class="error-message">Insufficient Balance to issue card. Please verify fee and balance</h3>
              </div>
            </div>
            <template v-if="form.shipTo == 'Account Address'">
              <template v-if="registration.type == 'Personal'">
                <p class="address-line">{{ registration.data.address }}</p>
                <p class="address-line">{{ registration.data.address2 }}</p>
                <p class="address-line">{{ registration.data.city }}</p>
                <p class="address-line">{{ registration.data.state }}</p>
                <p class="address-line">{{ registration.data.country }}</p>
                <p class="address-line">{{ registration.data.zip }}</p>
              </template>
              <template v-else>
                <p class="address-line">{{ registration.data.contacts[0].address }}</p>
                <p class="address-line">{{ registration.data.contacts[0].address2 }}</p>
                <p class="address-line">{{ registration.data.contacts[0].city }}</p>
                <p class="address-line">{{ registration.data.contacts[0].state }}</p>
                <p class="address-line">{{ registration.data.contacts[0].country }}</p>
                <p class="address-line">{{ registration.data.contacts[0].zip }}</p>
              </template>
            </template>
            <template v-else-if="form.shipTo == 'Head Office'">
              <p class="address-line">{{ system.address }}</p>
              <p class="address-line"></p>
              <p class="address-line">{{ system.city }}</p>
              <p class="address-line">{{ system.state }}</p>
              <p class="address-line">{{ system.country }}</p>
              <p class="address-line">{{ system.postal }}</p>
            </template>
            <template v-else>
              <p class="address-line">&nbsp;</p>
              <p class="address-line">&nbsp;</p>
              <p class="address-line">&nbsp;</p>
              <p class="address-line">&nbsp;</p>
              <p class="address-line">&nbsp;</p>
              <p class="address-line">&nbsp;</p>
            </template>
          </div>
        </template>
        <div class="buttons">
          <button class="main" @click="add()">Submit</button>
          <button class="second" @click="open = false">Cancel</button>
          <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
        </div>
      </div>
  </popup>
</template>

<script>
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    modalType: {
      type: String,
      required: true
    },

    account: {
      type: Object,
      required: true
    },

    registration: {
      type: Object,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      form: {
        account: null,
        cardType: null,
        cardLimit: null,
        cardLimitInterval: null,
        cardBin: null,
        shipTo: null,
      },
      system: null,

      featureStatusPhysicalCards: false,
      featureStatusVirtualCards: false,
      open: true,
      submitError: false,
      balanceError: false,
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    cardTypeOptions () {
      const options = []
      if(this.featureStatusPhysicalCards) {
        options.push(
          {
            id: 'physical',
            text: 'Physical',
          }
        )
      }
      if(this.featureStatusVirtualCards) {
        options.push(
          {
            id: 'virtual',
            text: 'Virtual',
          }
        )
      }
      return options
    },

    cardBinOptions () {
      const options = [
        {
          id: 'debit',
          text: 'Debit',
        },
        {
          id: 'credit',
          text: 'Credit',
          disabled:true
        }
      ]
      return options
    },

    cardLimitIntervalOptions () {
      const options = [
        {
          id: 'daily',
          text: 'Daily',
        },
        {
          id: 'weekly',
          text: 'Weekly',
        },
        {
          id: 'monthly',
          text: 'Monthly',
        },
        {
          id: 'yearly',
          text: 'Yearly',
        },
        {
          id: 'allTime',
          text: 'All Time',
        },
        {
          id: 'perTransaction',
          text: 'Per Transaction',
        }
      ]
      return options
    },

    shipToOptions () {
      const options = [
        {
          id: 'Account Address',
          text: 'Account Address',
        },
        {
          id: 'Head Office',
          text: 'Head Office',
        }
      ]
      return options
    },

    now () {
      return moment().subtract(window.serverDiff, 's')
    },

    accountOptions () {
      let accounts = []
      accounts.push({
        id: this.account.id,
        text: `${this.account.accountNumber}-${this.account.regName}`,
      })

      return accounts
    },

    debitCardFee () {
      return this.account?.feeSchedule?.debitCard || 0
    }
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },

    'form.account' () {

    },
  },

  methods: {
    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }
      if(parseFloat(this.debitCardFee) > 0) {
        if(parseFloat(this.account.systemAvailableBalance) < parseFloat(this.debitCardFee)) {
          this.balanceError = true
          this.submitError = true
          return
        }
      }

      this.balanceError = false
      this.submitError = false
      const loader = this.$loading.show()
      let payload;

      const response = (await this.$store.dispatch('cards/action', {
        id: 'new',
        action: 'SetCard',
        payload: {
          account: this.form.account,
          cardType: this.form.cardType,
          cardLimit: this.form.cardLimit,
          cardLimitInterval: this.form.cardLimitInterval,
          cardBin: this.form.cardBin,
          shipTo: this.form.shipTo,
        }
      }))
      if(response.action.error) {
        this.$toast.error('Error: ' + response.action.errorMsg)
      }else{
        this.$store.dispatch('registrations/get', this.registration.id)
        this.open = false
        this.$toast.success('Card successfully created.')
      }
      loader.hide()
    },

    async getFeatureStatus () {
      this.featureStatusPhysicalCards = window.systemFeatures['Physical Cards']
      this.featureStatusVirtualCards = window.systemFeatures['Virtual Cards']
    },
  },

  async created () {
    this.form.account = this.account.id
    this.system = JSON.parse(JSON.stringify(window.appData.system))
    await this.getFeatureStatus();
    await this.$store.dispatch('fee_schedule/load')
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        account: { required },
        cardType: { required },
        cardBin: { required },
      }
    }

    if(this.form.cardType == 'physical') {
      rules.form.shipTo = { required }
    }
    if(this.form.cardLimit) {
      rules.form.cardLimit = { decimal }
      rules.form.cardLimitInterval = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}
.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}

.address-line {
  margin: 0;
}
</style>