<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section class="content-filters">
      <basic-form>
        <h4>Webhook Settings</h4>
        <div class="cof-row">
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>Retry Delay</label>
            <select v-model="system.retryDelay" :disabled="!updateSettings" @change="changeRetryDelay = true">
              <option></option>
              <option v-for="item in retriesDelayOptions" :value="item.id">{{item.text}}</option>
            </select>
          </div>
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>Last Update</label>
            <label>{{system.retryDelayDate | date('LL')}}</label>
            <label>{{system.retryDelayDate | date('hh:mm:ss')}}</label>
          </div>
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>Maximun Retries</label>
            <select v-model="system.maximumRetries" :disabled="!updateSettings" @change="changeMaximumRetries = true">
              <option></option>
              <option v-for="item in maximunRetriesOptions" :value="item.id">{{item.text}}</option>
            </select>
          </div>
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>Last Update</label>
            <label>{{system.maximumRetriesDate | date('LL')}}</label>
            <label>{{system.maximumRetriesDate | date('hh:mm:ss')}}</label>
          </div>
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>&nbsp;</label>
            <button v-if="!updateSettings" class="main" @click="updateHookSettings()" style="min-width: 100px; margin-left: 20px">Update</button>
            <button v-else class="main save-connex" @click="saveHookSettings()" style="min-width: 100px; margin-left: 20px; background-color: #67c23a;">Save</button>
          </div>
        </div>
      </basic-form>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Date</th>
          <th style="width: 15%;">Retry Date</th>
          <th style="width: 15%;">Provider</th>
          <th style="width: 10%;">Processed</th>
          <th style="width: 10%;">Failed</th>
          <th style="width: 10%;">Retries</th>
          <th>Webhook</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, i) in filteredData" :key="i">
          <td><span>Date</span>{{ item.createdAt }}</td>
          <td><span>Date</span>{{ item.retriedTime }}</td>
          <td><span>Provider</span>{{ item.provider? item.provider.provider_name : '' }}</td>
          <td><span>Processed</span>{{ item.isProcessed > 0 ? 'Yes': 'No' }}</td>
          <td><span>Failed</span>{{ item.failed ? 'Yes': 'No' }}</td>
          <td><span>Retries</span>{{ item.failures }}</td>
          <td><span>Webhook</span>{{ item.response }}</td>
          <td>
            <span>Options</span>
            <button v-if="item.failed" class="action-button update" @click="retryHook(item.id)">Retry</button>
            <button v-if="item.isProcessed > 0" class="action-button" @click="resetHook(item.id)">Reset</button>
          </td>
        </tr>
      </table>
    </section>
    <section class="pagination-section" v-if="!loading">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>

    <webhook-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import Popup from '../popup'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import WebhookFilters from './filters/WebhookFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'webhook-management',

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    Popup,
    DateTimePicker,
    WebhookFilters,
    AppliedFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,

      updateSettings: false,
      loading: false,
      changeRetryDelay: false,
      changeMaximumRetries: false,

      page: 1,
      perPage: 25,
      pages: [],

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        provider: 'Provider',
        failed: 'Failed',
        retries: 'Retries',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Added: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    hooks () {
      let data =  this.$store.state.webhook.data || []

      if (this.filters) {
        if (this.filters.provider) {
          data = data.filter(item => item.provider?.id == this.filters.provider )
        }

        if (this.filters.failed) {
          data = data.filter(item => item.failed.toString() == this.filters.failed)
        }

        if (this.filters.retries) {
          data = data.filter(item => item.failures == this.filters.retries )
        }
      }

      return data
    },

    filteredData () {
      return this.paginate(this.hooks);
    },

    system () {
      return this.$store.getters['system/get'](window.appData.system.id)
    },

    providerOptions () {
      let data =  this.$store.state.providersList.data || []
      data = data.filter(item => (item.status == 'Active') || (item.status =='Testing'))
      let options = []
      data.forEach(provider => {
        options.push({
          id: provider.id,
          text: `${provider.provider_name}`,
        })
      })
      return options
    },

    retriesOptions () {
      let options = []
      for(var i=1; i <= 20; i++) {
        options.push({
          id: i,
          text: i,
        })
      }
      return options
    },

    retriesDelayOptions () {
      let options = []
      options.push({
        id:1,
        text: "1 Hour"
      })
      for(var i=2; i <= 24; i++) {
        options.push({
          id: i,
          text: i + " Hours",
        })
      }
      return options
    },

    maximunRetriesOptions () {
      let options = []
      for(var i=1; i <= 10; i++) {
        options.push({
          id: i,
          text: i,
        })
      }
      return options
    },
  },

  async created () {
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    await this.$store.commit('system/setSingle', window.appData.system)
    await this.refreshHooks();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'provider') {
        const provider = this.providerOptions.find(item => item.id == value)
        return provider?.text
      }

      if (key === 'failed') return value ? 'Yes' : 'No'

      return value
    },
    
    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    updateHookSettings () {
      this.updateSettings = true
    },

    setPages () {
      let numberOfPages = Math.ceil(this.hooks.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async saveHookSettings() {
      const loader = this.$loading.show()
      this.loading=true;
      try {
        if(this.changeRetryDelay)
          this.system.retryDelayDate = 'CURRENT_TIMESTAMP'
        if(this.changeMaximumRetries)
          this.system.maximumRetriesDate = 'CURRENT_TIMESTAMP'
        await this.$store.dispatch('system/update', { id: 1, payload: this.system })
        await this.$store.dispatch('system/get', this.system.id)
        this.updateSettings = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
      this.loading=false;
      loader.hide()
    },

    async refreshHooks () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
      }
      await this.$store.dispatch('webhook/load', { order: ['ID:DESC'], params })
      this.loading=false;
      loader.hide()
    },

    async retryHook (id) {
      const loader = this.$loading.show()
      this.loading=true;
      const action = (await api.action("Webhooks", id, "Retry")).action
      this.refreshHooks()
      this.loading=false;
      loader.hide()
    },

    async resetHook (id) {
      const loader = this.$loading.show()
      this.loading=true;
      await this.$store.dispatch('webhook/update', { id: id, payload: {'isProcessed' : null} })
      this.refreshHooks()
      this.loading=false;
      loader.hide()
    }
  },

  watch: {
    hooks () {
      this.setPages();
    },

    appliedFilters () {
      this.refreshHooks();
    },
  },

  validations () {

  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px; 
  }
  .errors {
    color: #ff0000 !important;
  }
  // .status-options {
  //   margin-bottom: 30px; 
  // }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
</style>
