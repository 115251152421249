<template>
    <div>
    <div class="cp-box">
      <div class="cof-field mobinl">
        <label>&nbsp;</label>
        <button class="main" @click="addNewDocument()">Add New</button>
      </div>
      <hr>
      <section>
        <div class="main-header">
          <h2 class="inner-headers">Employee Documents</h2>
        </div>
      </section>

      <section  class="content-tlist" style="margin: 30px 0;">
        <table class="content-table">           
          <tr>
            <th>Description</th>              
            <th>Type</th>
            <th>Number</th>              
            <th>Filename</th>
            <th>Options</th>                          
          </tr>
          <tr v-for="(document, i) in documents" :key="document.id">
            <td><span>Description</span>
              <mnb-input v-if="isEdit(i)" v-model="editingForms[i].description" />
              <template v-else>
                {{ document.description}}
              </template>
            </td>               
            <td><span>Type</span>
              <mnb-input v-if="isEdit(i)" v-model="editingForms[i].type" />
              <template v-else>
                {{ document.type }}
              </template>
            </td>
            <td><span>Number</span>
              <mnb-input v-if="isEdit(i)" v-model="editingForms[i].number" />
              <template v-else>
                {{ document.number }}
              </template>
            </td>              
            <td><span>Filename</span>
              <form-field-upload v-if="isEdit(i)" v-model="editingForms[i].file" label="" classes="add-document" btnClasses="add-document-btn-upload" style="width: 173px;" />
              <template v-else>
                <template v-if="document.file">
                  <a :href="`/download/EmployeeDocument/${document.id}`" target="_blank">
                    {{ document.file }}
                  </a>
                </template>
              </template>
            </td>
            <td><span>Options</span>
              <template v-if="isEdit(i)">
                <button @click="saveUpdateDocument(i)" class="document-handle save">Save</button>
                <button @click="cancelEdit(i)" class="document-handle cancel">Cancel</button>
              </template>
              <template v-else>
                <button @click="editDocument(i)" class="document-handle update">Update</button>
                <button @click="deleteDocument(document.id)" class="document-handle remove">Remove</button>
              </template>
            </td>              
          </tr>
        </table>
      </section>

      <section class="content-filters">
        <div v-if="addDocument" class="cof-row" style="margin-bottom: 70px;">
          <mnb-input label="Description" v-model="addDocumentForm.description" :validator="$v.addDocumentForm.description"/>
          <mnb-input label="Type" v-model="addDocumentForm.type" />
          <mnb-input label="Number" v-model="addDocumentForm.number" />
          <form-field-upload v-model="addDocumentForm.file" label="Document" classes="add-document" btnClasses="add-document-btn" style="width: 255px;" />
          
          
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>&nbsp;</label>
            <button @click="saveDocument()" class="main" style="min-width: 100px; margin-left: 20px">Update</button>
          </div>
          <div class="cof-field mobinl" style="margin-left: 20px;">
            <label>&nbsp;</label>
            <button class="second">Reset Fields</button>
          </div>    
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import MnbInput from '../../../components/ui/forms/Input'
import FormFieldUpload from '../../../components/FormFieldUpload'

import { required } from 'vuelidate/lib/validators'

export default {
   name: 'Documents',

  components: {
    MnbInput,
    FormFieldUpload
  },

  props: {
    employee: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      addDocument: false,
      addDocumentForm: {
        description: null,
        type: null,
        number: null,
        file: null
      },
      editing: [],
      editingForms: {}
    }
  },

  computed: {
    documents() {
      return (this.$store.state.employee_documents.data || []).filter(doc => parseInt(doc.employeeId) === parseInt(this.employee.id))
    }
  },

  created() {
    this.$store.dispatch('employee_documents/load', { params: { employeeId: this.employee.id }})
  },

  methods: {
    addNewDocument () {
      this.addDocument = true
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    async saveDocument () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.documentPromise(this.employee.id, this.addDocumentForm.description, this.addDocumentForm.type, this.addDocumentForm.number, this.addDocumentForm.file)
          this.$toast.success('Document successfully added.')
          Object.keys(this.addDocumentForm).map(item => this.addDocumentForm[item] = null)
          this.$v.$reset()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async documentPromise (employeeId, description, type, number, file) {
      const document = await this.$store.dispatch('employee_documents/create', {
        employeeId,
        type,
        description,
        number,
        createdAt: 'CURRENT_TIMESTAMP'
      })
      if(file) await this.$store.dispatch('employee_documents/upload', { id: document.id, file })
    },

    async saveUpdateDocument (i) {
      if (this.editingForms[i]) {
        try {
          await this.documentUpdatePromise(this.documents[i].id, this.editingForms[i].description, this.editingForms[i].type, this.editingForms[i].number, this.editingForms[i].file)

          this.$toast.success('Document successfully updated.')
          this.cancelEdit(i)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async documentUpdatePromise (employeeId, description, type, number, file) {
      const updateDocument = await this.$store.dispatch('employee_documents/update', { id: employeeId, payload: {
        type,
        description,
        number
      }})
      if(file) await this.$store.dispatch('employee_documents/upload', { id: employeeId, file })
    },

    async deleteDocument(id) {
      if(confirm(`Are you sure to delete document?`)) {
        await this.$store.dispatch('employee_documents/delete', id)
        
        this.$toast.success('Document deleted successfully.')
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      delete this.editingForms[index]
    },

    editDocument(index) {
      this.editing.push(index)
      this.editingForms[index] = {
        description: this.documents[index].description,
        type: this.documents[index].type,
        number: this.documents[index].number,
        file: this.documents[index].file
      }
    }
  },

  validations: {
    addDocumentForm: {
      description: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobinl {
    margin-bottom: 20px;
  }
  .document-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white; 
    border: none;
    outline: none; 

    &.update {
      background-color: #ffa700; 
    }
    &.remove {
      background-color: #d62b2b; 
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
  .content-filters {
    margin-top: 80px; 
  }
</style>