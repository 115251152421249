<template>
  <div>
    <div v-if="loading">Loading...</div>
    <section v-if="!loading">
      <div style="padding: 24px 30px 10px 10px;" v-if="closed">
        All users listed below are blocked
      </div>
      <div style="padding: 24px 30px 10px 10px;">
        <button class="btn btn-success" @click.prevent="closeAll">
          Block all users with closed accounts
          {{ progress?'('+progress+'/'+(users.length+subUsers.length)+')':'' }}
        </button>
      </div>

      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Date</th>
            <th>Profile</th>
            <th>Name</th>
            <th>Profile Type</th>
            <th>Status</th>
          </tr>
            <tr v-for="user in users" :key="user.id">
              <td><span>Registration Date</span>
                {{ user.createdAt | date('MM/D/YYYY') }}<br/>
                {{ user.createdAt | date('hh:mm A') }}
              </td>
              <td><span>Profile</span>
                <a>{{ user.profileId || user.regId }}</a>
              </td>
              <td>
                <span>Name</span>
                {{ user.username }}
              </td>
              <td><span>Profile Type</span>{{ user.role?user.role.name:"" }}</td>
              <td><span>Status</span><em>{{ user.active }}</em></td>
            </tr>
            <tr v-for="user in subUsers" :key="user.id">
              <td><span>Registration Date</span>
                {{ user.createdAt | date('MM/D/YYYY') }}<br/>
                {{ user.createdAt | date('hh:mm A') }}
              </td>
              <td><span>Profile</span>
                <a>{{ user.profileId || user.regId }}</a>
              </td>
              <td>
                <span>Name</span>
                {{ user.username }}
              </td>
              <td><span>Profile Type</span>{{ user.role?user.role.name:"" }}</td>
              <td><span>Status</span><em>{{ user.active }}</em></td>
            </tr>

        </table>
      </section>

    </section>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    users: [],
    subUsers: [],
    closedProfiles: [],
    loading: true,
    progress: 0,
    closed: false,
  }),
  methods: {
    async loadRegistrations() {
      this.closedProfiles=(await api.list("Registration", {status: "Closed"}, null, null, null, ['terms', 'serviceProviders', 'confirmation', 'documentsChecklist', 'accounts', 'documents', 'createdBy', 'partner'])).items;
      var allUsers=this.closedProfiles.filter(item=>item.user).map(item=>item.user);
      this.users=allUsers.filter(user=>user.active!='Blocked');
      var parentUsersIds=allUsers.map(user=>"'"+user.id+"'").join(",");
      this.subUsers=(await api.list("User", {subuserOf: ["IN ("+parentUsersIds+")"], active: ["<>", "Blocked"]})).items;

      this.loading=false;
    },
    async closeAll() {
      if (confirm("Are you sure?")) {
        for(var i in this.users) {
          var user=this.users[i];
          this.progress=i*1+1;
          await api.update("User", user.id, {active: "Blocked"});
        }
        for(var i in this.subUsers) {
          var user=this.subUsers[i];
          this.progress=this.users.length+i*1+1;
          await api.update("User", user.id, {active: "Blocked"});
        }
        this.progress=0;
        this.closed=true;
      }
    },
  },
  mounted() {
    this.loadRegistrations();
  },
}
</script>
