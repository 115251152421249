<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date</th>
              <th>Name <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('name')"/></th>
              <th>Account</th>
              <th>Type</th>              
              <th>Status</th>
              <th v-if="!partner">Available <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('systemAvailableBalance')"/></th>
              <th v-if="!partner">Balance <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('systemTotalBalance')"/></th>              
            </tr>
            <tr v-for="account in filteredData" :key="account.id">
              <td><span>Account Date</span>{{ account.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
              <td>
                <span>Name</span>
                {{ account.regName }}
              </td>
              <td><span>Account ID</span>
                <a v-if="!partner" :href="`/system-customers/registrations/${account.regId}`">{{ account.accountNumber }}</a>
                <template v-else>{{ account.accountNumber }}</template>
              </td>
              <td><span>Account Type</span>{{ account.regType }}</td>             
              <td><span>Account Status</span><em v-if="account" :class="statusColorClasses[getStatus(account.accountStatus)]">
                {{ getStatus(account.accountStatus) }}</em></td>
              <td v-if="!partner"><span>Available</span>{{ account.systemAvailableBalance | balance }}</td>
              <td v-if="!partner"><span>Balance</span>{{ account.systemTotalBalance | balance }}</td>              
            </tr>
            <tr v-if="!accounts.length">
              <td colspan="9" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>

    <account-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import AccountFilters from './filters/AccountFilters'
import AppliedFilters from '../../components/common/AppliedFilters'


export default {
  name: 'CustomerAccounts',

  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    AccountFilters,
    AppliedFilters
  },

  data: () => ({
    openedFilters: false,
    filters: null,
    activeInfo: true,

    sortName: null,
    sortFlag: true,

    loading: false,
    page: 1,
    perPage: 25,
    pages: [],
  }),

  computed: {
    appliedFilters () {
      const filterNames = {
        accountId: 'Account ID',
        name: 'Name',
        type: 'Account Type',
        status: 'Account Status'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    accountStatuses () {
      return window.accountStatuses
    },

    profileStatuses () {
      return window.profileStatuses
    },

    accountStatusesFiltered () {
      const array = [...window.accountStatuses]
      array.splice(0, 2)
      return array
    },

    totalNumber() {
      return this.accounts.length
    },

    accounts () {
      let accounts = this.$store.state.accounts.data || []
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        accounts = accounts.filter(account => {
          const date = moment(account.createdAt.split(' ')[0], 'YYYY-MM-DD')
          return date >= now
        })
      }
      if (this.filters) {
        if (this.filters.name) {
          accounts = accounts.filter(account => {
            return account.regName?.toLowerCase().includes(this.filters.name.toLowerCase())
          })
        }

        if (this.filters ?.status) {
          accounts = accounts.filter(account => account.accountStatus === this.filters.status)
        }

        if (this.filters.accountId) {
          accounts = accounts.filter(account => {
            return account.accountNumber?.includes(this.filters.accountId)
          })
        }

        if (this.filters.type) {
          accounts = accounts.filter(account => account.regType === this.filters.type)
        }
      }

      if(this.sortName != null) {
        if(this.sortName == 'systemAvailableBalance'){
          if(this.sortFlag) {
            accounts.sort((a, b) => {
              if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return 1
              if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return -1
              return 0
            })
          }else{
            accounts.sort((a, b) => {
              if (Number(a.systemAvailableBalance) > Number(b.systemAvailableBalance)) return -1
              if (Number(a.systemAvailableBalance) < Number(b.systemAvailableBalance)) return 1
              return 0
            })
          }
        }

        if(this.sortName == 'systemTotalBalance'){
          if(this.sortFlag) {
            accounts.sort((a, b) => {
              if (Number(a.systemTotalBalance) > Number(b.systemTotalBalance)) return 1
              if (Number(a.systemTotalBalance) < Number(b.systemTotalBalance)) return -1
              return 0
            })
          }else{
            accounts.sort((a, b) => {
              if (Number(a.systemTotalBalance) > Number(b.systemTotalBalance)) return -1
              if (Number(a.systemTotalBalance) < Number(b.systemTotalBalance)) return 1
              return 0
            })
          }
        }

        let aName, bName
        if(this.sortName == 'name'){
          if(this.sortFlag) {
            accounts.sort((a, b) => {
              if (a.name > b.name) return 1
              if (a.name < b.name) return -1
              return 0
            })
          }else{
            accounts.sort((a, b) => {
              if (a.name > b.name) return -1
              if (a.name < b.name) return 1
              return 0
            })
          }
        }
      }
      return accounts
    },

    statusColorClasses () {
      return {
        Open: 'c1',
        Restricted: 'c2',
        Pending: 'c2',
        Closed: 'c4',
        Dormant: 'c3',
        Hold: 'c2'
      }
    },

    currentUser () {
      return window.appData.currentUser
    },

    partner () {
      return this.$store.getters['partners/get'](this.currentUser.parId)
    },

    filteredData () {
      return this.paginate(this.accounts);
    }
  },

  async created () {
    if(this.currentUser.parId) {
      await this.$store.dispatch('partners/get', this.currentUser.parId)
    }
    await this.refreshAccounts();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    getFilterValue (key, value) {
      if (key === 'status') return this.getStatus(value)
      return value
    },
    
    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    sortTemplateName(sortName) {
      if(this.sortName == sortName){
        this.sortFlag = !this.sortFlag
      }else {
        this.sortName = sortName
        this.sortFlag = true
      }
    },

    async refreshAccounts () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      params.regId=["!=", 1];

      if(this.partner) {
        params.partnerId = this.partner.id
      }

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 7)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")
        params.createdAt=[">=", yesterdayDate+" 00:00:00"];
        params.accountStatus=["!=", "New"];
      } else {
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
      }

      await this.$store.dispatch('accounts/load', { order: ['createdAt:DESC'], params, skip: ['primeAccount', 'solidAccount', 'serviceProviders', 'assetsWallets', 'fundsCurrencies', 'complianceScan', 'profileSettings', 'cards', 'documents', 'primeAccount', 'currencyName', 'regCorp', 'regAdministrative', 'regReviewDays'] })
      this.loading=false;
      this.page = 1
      loader.hide()
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },
  },

  watch: {
    appliedFilters () {
      this.refreshAccounts();
    },

    filteredData () {
      this.setPages();
    },

    page() {
      this.setPages();
    },

    accounts () {
      this.setPages();
    },

    perPage() {
      if(this.page == 1){
        this.setPages();
      }else{
        this.page = 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>
