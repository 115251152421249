<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>

    <section v-if="perms" class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="full-w">
            <mnb-input
            v-model="form.message"
            label="Message" />
            <span v-if="$v.form.message.$dirty && $v.form.message.$invalid" class="error">This field is required !</span>
          </div>
        </div>
        <div class="cof-row">
          <div class="full-w">
            <label>Message Expiry</label>
            <date-time-picker
              v-model="form.expiresAt"
              value-type="String"
              value-format="yyyy-LL-dd"
              format="yyyy-LL-dd"
              :time-picker="false"
              :auto-close="true"
              :min-date="minDate"
              >
            </date-time-picker>
            <label>Leave blank for no expiry date</label>
          </div>
        </div>
        <div class="cof-row">
          <div v-if="$hasPerm('add-system-message')" class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Message</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetField()">Reset Fields</button>
          </div>
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Date</th>
          <th style="width: 15%;">Expiry</th>
          <th style="width: 15%;">By</th>
          <th style="width: 40%;">Message</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, i) in messages" :key="i">
          <td><span>Date</span>{{ item.createdAt | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Message Expiry</span><template v-if="item.expiresAt">{{ item.expiresAt | date('MM/D/YYYY') }}</template></td>
          <td><span>By</span>{{ item.createdBy? item.createdBy.firstName : '' }} {{ item.createdBy? item.createdBy.lastName : '' }}</td>
          <td style="width: 500px; padding-right: 10px;">
            <span>Messages</span>
            <template v-if="!isEdit(i)"> {{ item.message }}</template>
            <mnb-textarea
              v-else
              :ref="`input_${i}`"
              v-model="editingText[i]"
              :classes="{ 'noUpdate': isEdit(i), 'messageField': true }"
              @input="mixin_autoResize_resize"
              full/>
          </td>
          <td>
            <span>Options</span>
            <template v-if="isEdit(i)">
              <button @click="saveUpdate(i)" class="action-button save">Save</button>
              <button @click="cancelEdit(i)" class="action-button cancel">Cancel</button>
            </template>
            <template v-else>
              <button v-if="$hasPerm('update-system-message')" @click="editMessage(i)" class="action-button save">Update</button>
              <button v-if="$hasPerm('remove-system-message') &&  item.archived" @click="deleteMessage(item.id)" class="action-button remove">Remove</button>
              <button v-else-if="!item.archived" @click="archivedMessage(item.id)" class="action-button cancel">Archive</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbTextarea from '../../components/ui/forms/Textarea'

import mixinAutoResize from "../../components/ui/forms/autoresize.js"
import inputMixin from "../../components/ui/forms/input-mixin.js"
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  name: 'system-messages',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbTextarea,
    DateTimePicker
  },

  data() {
    return {
      form: {
        message: null,
        expiresAt: null
      },
      editing: [],
      editingText: {},
      perms: window.appData.currentUser?.role?.perms?.includes('add-system-message') || window.appData.currentUser?.perms?.includes('add-system-message')
    }
  },

  computed: {
    messages () {
      const data = this.$store.state.systemMessage.data || []
      return data
    },

    minDate() {
      return moment(this.messages[0]?.createdAt, "YYYY-MM-DD HH:mm:ss").add(1,'days').format("YYYY-MM-DD")
    }
  },

  methods: {
    isEdit (index) {
      return this.editing.includes(index)
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = {
            message: this.form.message,
            expiresAt: this.form.expiresAt,
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }

          await this.$store.dispatch('systemMessage/create', payload)
          this.$toast.success('System message successfully created.')
          this.form.message = null
          this.$v.$reset()
          location.replace(location.href);
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    resetField() {
      this.form.message = null
    },

    async deleteMessage(id) {
      if(confirm(`Are you sure to delete System Message?`)) {
        this.$store.dispatch('systemMessage/delete', id)
        this.$toast.success('System Message deleted successfully.')
        location.replace(location.href);
      }
    },

    async saveUpdate (i) {
      if (this.editingText[i]) {
        try {
          const payload = {
            id: this.messages[i].id,
            payload: {
              message: this.editingText[i]
            }
          }

          await this.$store.dispatch('systemMessage/update', payload)
          this.$toast.success('System message successfully updated.')
          location.replace(location.href);
          this.cancelEdit(i)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async archivedMessage (i) {
      try {
        const payload = {
          id: i,
          payload: {
            archived: true
          }
        }

        await this.$store.dispatch('systemMessage/update', payload)
        this.$toast.success('System message successfully archived.')
        location.replace(location.href);
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      delete this.editingText[index]
    },

    editMessage(index) {
      this.editing.push(index)
      this.editingText[index] = this.messages[index].message
      this.$nextTick(() => this.$refs[`input_${index}`][0].$el.getElementsByTagName('textarea')[0].focus())
    }
  },

  validations: {
    form: {
      message: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .second {
    margin-left: 70px;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: #d23131;
  }  
  .noUpdate {
    background-color: transparent;
    border: none;
  }
  .content-tlist {
    border: none;
  }
</style>
