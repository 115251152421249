<template>
  <div>
    <section  class="content-tlist" style="margin: 30px 0;">
      <table class="content-table">           
          <tr>
            <th>Type</th>              
            <th>Link</th>
            <th>Options</th>                          
          </tr>
          <tr>
            <td>Registration Tracking Link</td>
            <td>{{ trackingLink }}</td>
            <td>
              <template v-if="$hasPerm('partners-manage')">
                <button class="main" v-if="!trackingLink" v-on:click.prevent="createCode" :disabled="!partner.settings.registrationTracking">New Link</button>
                <button class="main" v-else v-on:click.prevent="createCode" :disabled="!partner.settings.registrationTracking">Update Link</button>
              </template>
            </td>
          </tr>
        </table>
    </section>
  </div>  
</template>

<script>

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
    }
  },

  computed: {
    trackingLink () {
      return this.partner?.absRefLink;
    },
  },

  created () {
  },

  methods: {
    async createCode () {
      var hasTrackingLink=this.trackingLink;
      if (hasTrackingLink) {
        if (!confirm('Are you sure you want to update the tracking link?')) return
      } else {
        if (!confirm('Are you sure you want to create the tracking link?')) return
      }

      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('partners/action', {
          id: this.partner.id,
          action: 'genRefLink',
          payload: {}
        })
        if (hasTrackingLink) {
          this.$toast.success('Tracking code created')
        } else {
          this.$toast.success('Tracking code created')
        }
        this.selected = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

  },
}
</script>

