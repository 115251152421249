<template>
<div class="vgv-display" ref="container">
  
</div>
</template>
<script>
export default {
  props: ["fieldName", "css"],
  mounted() {
    var headers=this.$parent.vgvHeaders;
    var extraArgs={};
    if (this.fieldName=="cardNumber")
      extraArgs={
        serializers: [this.$parent.vgvShow.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
      };
    if (this.fieldName=="expiryYear")
      extraArgs={
        serializers: [this.$parent.vgvShow.SERIALIZERS.replace('(\\d{2})(\\d{2})', '$2')],
      };

    var iframe=this.$parent.vgvShow.request({
      name: this.fieldName+'Iframe',
      method: 'GET',
      headers,
      path: '/v1/card/'+this.$parent.cardId+'/show',
      jsonPathSelector: this.fieldName,
      ...extraArgs,
    });
    iframe.render(this.$refs.container, this.css || {});
    // TODO fixme - use events in vgs iframe
    setTimeout(()=>this.$emit("shown"), 4000);
  },
}
</script>

<style>
.vgv-display iframe {
  height: 40px;
  width: 100%;
}

</style>
