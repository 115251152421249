<template>
  <div class="cp-box" v-if="registration">
    <ul class="sub-tabs">
      <li :class="{ active: activeTab === 'users' }" @click="activeTab = 'users'"><a>Users</a></li>
      <li :class="{ active: activeTab === 'log' }" @click="activeTab = 'log'"><a>User Log</a></li>
    </ul>
    <hr>
    <access-users v-if="activeTab === 'users'" :registration="registration" />
    <access-user-log v-if="activeTab === 'log'" :registration="registration" />
  </div>
</template>

<script>
import AccessUsers from './AccessUsers'
import AccessUserLog from './AccessUserLog'

export default {
  components: {
    AccessUsers,
    AccessUserLog
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'users'
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  }
}
</script>
