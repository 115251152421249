<template>
<div class="complex-field">
  <input :class="inputClass" type="text" :value="value" readonly :disabled="disabled" v-bind="$attrs" ref="inputField" />
  <a v-if="!disabled" href="" v-on:click.prevent="doCopy" class="copy-icon">
    <svg height="16" viewBox="0 0 48 48" width="16"><path d="M0 0h48v48h-48z" fill="none"/><path d="M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z"/></svg>
  </a>
</div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["value", "disabled", "inputClass"],
  methods: {
    doCopy() {
      this.$refs.inputField.focus()
      this.$refs.inputField.select()

      try {
        if (document.execCommand('copy')) {
          this.$toast.success('Copied to clipboard');
        } else {
          throw new Error("Unsuccessful copying");
        }
      } catch (err) {
        this.$toast.error('Failed to copy to clipboard');
      }
    },
  },
}
</script>
<style>
html .content-form .form-row .complex-field {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: white;
  border: 1px solid #bec9d4;
  line-height: 46px;
  padding: 0 12px;
  font-size: 15px;
  color: #41566c;
  font-weight: bold;
  outline: none;
}
html .content-form .form-row .complex-field input {
  flex-grow: 1;
  padding: 0;
  border: 0px none;
  height: auto;
}
html .content-form .form-row .complex-field a.copy-icon {
  opacity: 0.7;
  transition: .3s;
  display: inline-block;
  height: 16px;
}
html .content-form .form-row .complex-field a.copy-icon svg {
  display: block;
}
html .content-form .form-row .complex-field a.copy-icon:hover {
  opacity: 1;
}
html .client-properties .cp-fields > li .complex-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #99AABB;
  padding: 0 1em 0 1em;
  margin: 0;
  width: 100%;
  height: 28px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  display: block;
  cursor: #616F7B;
  line-height: 26px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
}
html .client-properties .cp-fields > li .complex-field input {
  flex-grow: 1;
  padding: 0;
  border: 0px none;
  height: auto;
  outline: none;
}
html .client-properties .cp-fields > li .complex-field a.copy-icon {
  opacity: 0.7;
  transition: .3s;
  display: inline-block;
  height: 16px;
}
html .client-properties .cp-fields > li .complex-field a.copy-icon svg {
  display: block;
}
html .client-properties .cp-fields > li .complex-field a.copy-icon:hover {
  opacity: 1;
}
</style>
