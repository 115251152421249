<template>
  <div>
    <template v-if="featureStatus == null"></template>
    <template v-else>
      <template v-if="!featureStatus">
        <h3 class="monthly-head">This feature is not enabled. Please contact system administration in order to have it enabled.</h3>
        <h3 class="monthly-head">Feature - Dispute Manager</h3>
      </template>
      <template v-else>
        <button class="btn main" >New Dispute</button>
        <section  class="content-tlist" style="margin: 20px 0;">
          <table class="content-table">
            <tr>
              <th>Date/Time</th>
              <th>By</th>
              <th>Account</th>
              <th>Dispute Number</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Options</th>
            </tr>
          </table>
        </section>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data: ()=>({
    open: false,
    popupStatus: null,
    featureStatus: null,
  }),

  computed: {
    histories () {
      let data =  this.$store.state.account_status_history.data || []
      return data
    },
  },

  async created () {
    await this.getHistories();
    await this.getFeatureStatus();
  },

  methods: {
    async getFeatureStatus () {
      this.featureStatus = window.systemFeatures['Dispute Manager']
    },

    getStatus (status) {
      const names = {
        Complete: 'Pending',
        Active: 'Open'
      }
      return names[status] || status
    },

    async getHistories() {
      let params = {}
      params.accountId = this.registration.mainAccount.id
      await this.$store.dispatch('account_status_history/load', { order: ['createdAt:DESC'], params })
    },

    async updateStatus(status) {
      const loader = this.$loading.show()
      let payload = {
        status: status
      }

      await this.$store.dispatch('accounts/action', {
        id: this.registration.mainAccount.id,
        action: 'SetStatus',
        payload
      })
      this.$store.dispatch('registrations/get', this.registration.id)
      this.getHistories()
      loader.hide()
    },

    openStatus () {
      this.open = true
      this.popupStatus = 'Open'
    },

    openStatusRestricted () {
      this.open = true
      this.popupStatus = 'Restricted'
    },

  }
}
</script>