<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Last Login</th>
          <th>Profile</th>
          <th>Username</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Data Transfer</th>
          <th>IP Address</th>
          <th>Options</th>
        </tr>
        <tr v-for="login in filteredData" :key="login.id">
          <template v-if="login.user">
            <td><span>Last Login</span>
              {{ login.recorded | date('MM/D/YYYY hh:mm A') }}
            </td>
            <td><span>Profile</span>
              <template v-if="login.user.employee">
                <a :href="`/system/employees/${login.user.employee.id}`">{{ login.user.employee.employeeId }}</a>
              </template>
              <template v-else-if="login.user.profileId"><a :href="`/system-customers/profiles/${login.user.regId}`">{{ login.user.profileId }}</a></template>
              <template v-else>/</template>
            </td>
            <td><span>Username</span>{{ login.user.username }}</td>
            <td><span>First Name</span>{{ login.user.firstName }}</td>
            <td><span>Last Name</span>{{ login.user.lastName }}</td>
            <td><span>Data Transfer</span>{{ login.transferred | fileSize }}</td>
            <td><span>IP Address</span>{{ login.ipAddress }}</td>
            <td><span>Options</span><button v-if="perms" class="action-button cancel" @click="toggleBlock(login.user)">{{ login.user.active === 'Blocked' ? 'Unblock' : 'Block' }}</button></td>
          </template>
        </tr>
      </table>
    </section>
    <section class="pagination-section">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>
    <user-log-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import UserLogFilters from './filters/UserLogFilters';
  import AppliedFilters from '../../components/common/AppliedFilters';

  export default {
    name: 'Users',

    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      UserLogFilters,
      AppliedFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,
      page: 1,
      perPage: 20,
      pages: [],
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      perms: window.appData.currentUser?.perms?.includes('access')
    }),

    computed: {
      appliedFilters () {
        const filterNames = {
          uuid: 'Profile ID',
          username: 'Username',
          firstName: 'First Name',
          lastName: 'Last Name',
          ipAddress: 'IP Address'
        }
        
        if (!this.filters) return []
        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.filters[key]}`
        }))
  
        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }
  
        return appliedFilters
      },
      data () {
        let data = this.$store.state.users_logins.data || []

        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
          return data
        }

        if (this.filters.uuid) {
          data = data.filter(item => item.user?.profileId && item.user.profileId.includes(this.filters.uuid))
        }

        if (this.filters.username) {
          data = data.filter(item => item.user?.username.toLowerCase().includes(this.filters.username.toLowerCase()))
        }

        if (this.filters.firstName) {
          data = data.filter(item => item.user?.firstName.toLowerCase().includes(this.filters.firstName.toLowerCase()))
        }

        if (this.filters.lastName) {
          data = data.filter(item => item.user?.lastName.toLowerCase().includes(this.filters.lastName.toLowerCase()))
        }

        if (this.filters.ipAddress) {
          data = data.filter(item => item.ipAddress.toLowerCase().includes(this.filters.ipAddress.toLowerCase()))
        }

        return data
      },

      filteredData () {
        return this.paginate(this.data);
      }
    },

    methods: {
      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate
  
        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },
  
      clearFilters () {
        this.filters = null
      },
  
      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }
  
        this.$delete(this.filters, filter.key)
      },
  
      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      setPages () {
        let numberOfPages = Math.ceil(this.data.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },

      async refreshLogins () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
          params.recorded=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
        } else {
          if (this.filters.fromDate)
            params.recordedAfter=[">=", this.filters.fromDate+" 00:00:00", "recorded"];
          if (this.filters.toDate)
            params.recordedBefore=["<=", this.filters.toDate+" 23:59:59", "recorded"];
        }
        await this.$store.dispatch('users_logins/load', { order: ['ID:DESC'], params })
        this.loading=false;
        loader.hide()
      },

      async toggleBlock (loguser) {
        const toBlock = loguser.active !== 'Blocked'
        if (!confirm(`Are you sure you want to ${toBlock ? 'block' : 'unblock'} selected user?`)) return null

        const loader = this.$loading.show()
        try {
            const user = await this.$store.dispatch('users/action', { id: loguser.id, action: toBlock ? 'Block' : 'Unblock' })
            this.$toast.success(`Selected user is now ${toBlock ? 'blocked' : 'unblocked'}.`)
            loguser = user.item
            this.refreshLogins()
            this.$forceUpdate()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },
    },

    watch: {
      filteredData () {
        this.setPages();
      },

      appliedFilters () {
        this.refreshLogins();
      },
    },

    async created () {
      await this.refreshLogins();

    },
  }
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>