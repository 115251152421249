<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Account Creation</h2>
      <div class="status">
        Status
        <status-icon :color="mainAccount ? 'green' : null" />
      </div>
    </div>
    <template v-if="featureMasterAccounts == null" ></template>
    <template v-else>
      <table class="content-table" style="min-width: unset;">
        <tr>
          <th>Account Provider</th>
<!--           <th>Date/Time</th> -->
          <th v-if="registration.type=='Business' && featureMasterAccounts"></th>
          <th v-if="registration.type=='Master Business' && featureMasterAccounts"></th>
<!--           <th>Profile Type</th> -->  
          <th>Label</th >  
          <th>Type</th>
          <!-- <th>Number</th> -->
          <!-- <th>Currency</th> -->
          <th v-if="showOptions" style="text-align: right;"></th>
        </tr>
        <tr>
          <td><span>Account</span>
            <mnb-select v-model="providerForm.provider" :options="providerOptions" :disabled="!!serviceProviders"/>
          </td>
          <td v-if="registration.type=='Business' && featureMasterAccounts">
            <button class="second" v-on:click.prevent="setAsMaster">Master Account</button>
          </td>
          <td v-if="registration.type=='Master Business' && featureMasterAccounts">
            <button class="second" v-on:click.prevent="setAsMaster">Remove Master Account</button>
          </td>
          <td><span>Account Label</span>
            <template v-if="provider">{{ provider.label }}</template>
          </td>
          <td><span>Account Type</span>
            {{ registration.type }}
          </td>
          <!-- <td><span>Account Number</span>
            <template v-if="!currentAccount">{{ form ? form.accountNumber : 'N/A' }}</template>
            <template v-else>{{ currentAccount.accountNumber }}</template>
          </td> -->
          <!-- <td><span>Currency</span>
            <mnb-select v-model="currencyForm.currency" :options="currencyOptions" :disabled="!!serviceProviders"/>
          </td> -->
          <td v-if="showOptions">
            <!--<span>Options</span>-->
            <!--<button v-if="!mainAccount && !form" :disabled="!isAvailable || $v.providerForm.$invalid" class="main" @click="run()">Run</button>-->
            <!--<button v-if="form && !mainAccount" class="main" @click="create()">Create</button>-->
            <!--<button v-if="mainAccount" class="second" @click="remove()">Reset</button>-->
          </td>
        </tr>
      </table>
      <section v-if="providerForm.provider" class="content-tlist" style="margin: 30px 0;">
        <div class="header">
          <h2>Account Settings</h2>
        </div>
        <table class="content-table" style="min-width: unset;">
          <tr>
            <th style="width: 15%;">Account</th>
            <th style="width: 15%;">Multi<br/>Currency</th>
            <th colspan="6"></th>
          </tr>
          <tr class="content-filters">
            <td><span>Services</span>
              <template v-if="currentAccount">{{ currentAccount.accountNumber }}</template>
            </td>
            <td><span>Multi-currency</span>
              <template v-if="!profileSettings">
                <mnb-switch v-if="featureFundsStatus && provider.multiCurrency == 1" v-model="profileSettingsform.multiCurrency" :validator="$v.profileSettingsform.multiCurrency" />
                <mnb-switch v-else :value="false" disabled />
              </template>
              <mnb-switch v-else :value="profileSettingsform.multiCurrency == 1" disabled />
            </td>
            <td colspan="6"></td>
          </tr>
          <tr class="content-filters">
            <th colspan="8"></th>
          </tr>
        </table>
        <table class="content-table" style="min-width: unset;">
          <template v-if="this.registration.accounts.length <= 1">
            <tr class="space">
              <td colspan="11">
                <h2 class="profile-settings">Profile Settings</h2>
              </td>
            </tr>
            <tr>
              <th style="width: 15%;">Verifications</th>
              <th style="width: 12%;">Administrative</th>
              <th style="width: 12%;">RDC</th>
              <th style="width: 12%;" v-if="!isPersonal">Double Verification</th>
              <th colspan="4"></th>
            </tr>
            <tr class="content-filters">
              <td><span>Verifications</span> Enable</td>
              <td><span>Administrative</span>
                <mnb-switch v-if="!profileSettings" v-model="profileSettingsform.administrative" :validator="$v.profileSettingsform.administrative" />
                <mnb-switch v-else :value="profileSettingsform.administrative == 1" disabled />
              </td>
              <td>
                <mnb-switch v-if="!profileSettings" v-model="profileSettingsform.remoteDepositCapture" :validator="$v.profileSettingsform.remoteDepositCapture" />
                <mnb-switch v-else :value="profileSettingsform.remoteDepositCapture == 1" disabled />
              </td>
              <td v-if="!isPersonal"><span>Double Verification</span>
                <mnb-switch v-if="!profileSettings" v-model="profileSettingsform.double_verification" :validator="$v.profileSettingsform.double_verification" :disabled="!isPersonal || !profileSettingsform.account" />
                <mnb-switch v-else :value="profileSettingsform.double_verification == 1" disabled />
              </td>
              <td colspan="4">
              </td>
            </tr>
            <tr class="space">
              <th style="width: 15%;">Permissions</th>
              <th style="width: 12%;">Create Sub-Users</th>
              <th style="width: 12%;" v-if="isMasterBusiness">Create Sub-Profiles</th>
              <th colspan="5">
              </th>
            </tr>
            <tr class="content-filters">
              <td><span>Permissions</span> Enable</td>
              <td><span>Create Sub-Users</span>
                <mnb-switch v-if="!profileSettings" v-model="profileSettingsform.create_subusers" :validator="$v.profileSettingsform.create_subusers" />
                <mnb-switch v-else :value="profileSettingsform.create_subusers == 1" disabled />
              </td>
              <td v-if="isMasterBusiness"><span>Create Sub-Profiles</span>
                <mnb-switch v-if="!profileSettings" :value="profileSettingsform.create_subprofiles" :validator="$v.profileSettingsform.create_subprofiles" disabled />
                <mnb-switch v-else :value="profileSettingsform.create_subprofiles == 1" disabled />
              </td>
              <td colspan="5">
              </td>
            </tr>
            <tr class="space">
              <th style="width: 15%;">Options</th>
              <th style="width: 12%;">Welcome <br/>Email</th>
              <th style="width: 12%;">Review  <br/>Days</th>
              <th style="width: 12%;">Profile Language</th>
              <th colspan="4">
              </th>
            </tr>
            <tr class="content-filters">
              <td></td>
              <td><span>Send Welcome/Activation Email</span>
                <mnb-switch v-if="!profileSettings" v-model="profileSettingsform.welcome_email" :validator="$v.profileSettingsform.welcome_email" />
                <mnb-switch v-else :value="profileSettingsform.welcome_email == 1" disabled />
              </td>
              <td><span>Profile Review Days</span>
                <mnb-select v-if="!profileSettings" v-model="profileSettingsform.review_days" :validator="$v.profileSettingsform.review_days" class="select" :options="reviewOptions" />
                <template v-else>{{ profileSettingsform.review_days }}</template>
              </td>
              <td ><span>Profile Language</span>
                <mnb-select v-if="!profileSettings" v-model="profileSettingsform.language" :validator="$v.profileSettingsform.language" class="select" :options="languageOptions" />
                <template v-else>{{ profileSettingsform.language }}</template>
              </td>
              <td colspan="4">
              </td>
            </tr>
          </template>
        </table>
      </section>
      <table class="content-table" style="min-width: unset;">
        <tr>
          <td colspan="10"><span>Account</span>
          </td>
          <td v-if="registration.status !== 'Complete'" style="text-align: right;"><span>Options</span>
            <span>Options</span>
            <button v-if="this.registration.accounts.length < 1 && !form" :disabled="!isAvailable || $v.providerForm.$invalid" class="main" @click="run()">Confirm</button>
            <template v-if="this.registration.accounts[this.registration.accounts.length-1] && !form">
              <button v-if="this.registration.accounts[this.registration.accounts.length-1].accountStatus == 'New'" class="main" @click="confirmNewAccount()">Confirm</button>
            </template>
            <button v-else-if="mainAccount && registration.status != 'Active'" class="second" @click="remove()">Reset</button>
          </td>
        </tr>
      </table>
      <div class="footer space">
        <div class="datetime" style="color: inherit">
          <span>Date/Time</span>
          <br/>
          <template v-if="!currentAccount">N/A</template>
          <template v-else>{{ currentAccount.createdAt | date('LL') }} {{ currentAccount.createdAt | date('LT') }}</template>
        </div>
        <br />
      </div>
    </template>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import StatusIcon from './StatusIcon'
import MnbSelect from '../../../components/ui/forms/Select'
import MnbSwitch from '../../../components/ui/forms/Switch'
import MnbInput from '../../../components/ui/forms/Input'

const initialForm = {
  multiCurrency: false,
  multi_currency_limit: null,
  administrative: false,
  remoteDepositCapture: false,
  double_verification: false,

  create_subusers: false,
  create_subprofiles: false,

  welcome_email: false,
  review_days: null,
  language: null,

  account: null
}

export default {
  props: {
    registration: {
      type: Object,
      required: true
    },
    step: {}
  },

  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch,
    StatusIcon,
  },

  data () {
    return {
      profileSettingsform: { ...initialForm },
      form: null,
      currencyForm: {
        currency: 'USD',
      },
      featureMasterAccounts: null,
      currencyOptions:  [
        {
          id: 'USD',
          text: 'USD'
        }
      ],
      providerForm: {
        provider: null
      },

      reviewOptions: [
          {
            id: 30,
            text: 30
          },
          {
            id: 60,
            text: 60
          },
          {
            id: 90,
            text: 90
          },
          {
            id: 180,
            text: 180
          },
          {
            id: 365,
            text: 365
          }
        ],

        languageOptions: [
          {
            id: 'English',
            text: 'English'
          }
        ],
        customEnv: appData.customEnv,
        featureFundsStatus: null,
        featureAssetsStatus: null,
    }
  },

  computed: {
    showOptions () {
      return ['New', 'Pending', 'Denied', 'Archived'].includes(this.registration.status)
    },

    isAvailable () {
      return !!(this.currencyForm.currency)
    },

    mainAccount () {
      return this.registration.mainAccount || null
    },

    currentAccount(){
      return this.registration?.accounts[this.registration.accounts.length-1] || null
    },

    serviceProviders () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.serviceProviders || null
    },

    provider () {
      return this.providers.find(provider => provider.id === this.providerForm.provider)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.providerType == 'Accounts').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    profileSettings () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.profileSettings || null
    },

    isPersonal () {
      return this.registration?.type === 'Personal'
    },

    isMasterBusiness () {
      return this.registration?.type === 'Master Business'
    },
  },

  async created () {
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    this.providerForm.provider =  this.registration?.accounts[this.registration.accounts.length-1]?.serviceProviders?.id
    //this.currencyForm.currency = this.registration.currency
    await this.getFeatureStatus();
    if (this.profileSettings) {
      this.profileSettingsform = this.profileSettings
    } else if (!this.isPersonal) {
      this.profileSettingsform.double_verification = false
    }
  },

  methods: {
    async run () {
      if (!this.isAvailable) return
      // this.$v.profileSettingsform.$touch()
      // if (this.$v.profileSettingsform.$invalid) return
      const loader = this.$loading.show()
      let main
      if(this.registration.accounts.length > 0) {
        main = 0
      }else{
        main = 1
      }
      const response = await this.$store.dispatch('registrations/action', {
        id: this.registration.id,
        action: 'CreateAccount',
        payload: {
          regId: this.registration.id,
          main: main,
          currency: this.currencyForm.currency,
          provider: this.providerForm.provider,
          createdBy: window.appData.currentUser.id,
        }
      })

      await this.$store.dispatch('accounts/update', {
        id: response.item.accounts[response.item.accounts.length-1].id,
        payload: {
          updatedAt: 'CURRENT_TIMESTAMP',
          profileSettings: {
            ...this.profileSettingsform,
            createdAt: 'CURRENT_TIMESTAMP'
          }
        }
      })

      await this.$store.dispatch('registrations/get', this.registration.id)
      this.$toast.success('Profile & Account successfully created.')
      this.$emit("update:step", this.step+1)
      loader.hide()
    },

    async confirmNewAccount () {
      this.$v.profileSettingsform.$touch()
      if (this.$v.profileSettingsform.$invalid) return
      const loader = this.$loading.show()
      await this.$store.dispatch('accounts/update', {
        id: this.registration.accounts[this.registration.accounts.length-1].id,
        payload: {
          accountStatus: 'Pending',
          updatedAt: 'CURRENT_TIMESTAMP',
          profileSettings: {
            ...this.profileSettingsform,
            createdAt: 'CURRENT_TIMESTAMP'
          }
        }
      })
      await this.$store.dispatch('registrations/get', this.registration.id)
      this.$toast.success('Profile & Account successfully updated.')
      loader.hide()
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async create () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/create', {
          ...this.form,
          regId: this.registration.id,
          main: true,
          accountStatus: 'Pending',
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: { updatedAt: 'CURRENT_TIMESTAMP' }
        })
        await this.addNote('Profile & Account created')
        this.$toast.success('Profile & Account Creation successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()

      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: {
          updatedAt: 'CURRENT_TIMESTAMP',
          confirmation: null
        }
      })
      await this.addNote('Profile Account Confirmation reset')
      this.currencyForm.currency = this.registration.currency
      await this.$store.dispatch('accounts/delete', this.mainAccount.id)
      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: { updatedAt: 'CURRENT_TIMESTAMP' }
      })
        await this.addNote('Profile & Account removed')
      this.form = null
      this.$toast.success('Profile & Account Creation reset successfully finished.')

      await this.$store.dispatch('reg_service_providers/delete', this.serviceProviders.id)
      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: {
          updatedAt: 'CURRENT_TIMESTAMP',
          serviceProviders: null
        }
      })
      await this.addNote('Service Providers removed')
      this.providerForm.provider = null
      this.$toast.success('Service Providers successfully removed.')
      loader.hide()
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },

    async setAsMaster() {
      var newType="Master Business";
      if (this.registration.type=="Master Business")
        newType="Business";
      this.registration.type=newType;
      await this.$store.dispatch('registrations/update', {
        id: this.registration.id,
        payload: {
          updatedAt: 'CURRENT_TIMESTAMP',
          type: newType,
        }
      })
    },

    async getFeatureStatus () {
      this.featureMasterAccounts = window.systemFeatures['Master Accounts']
      this.featureAssetsStatus = window.systemFeatures['Digital Assets']
      this.featureFundsStatus = window.systemFeatures['Multi-Currency']
    },

    providersName (id) {
      const provider = this.providers.find(item => parseInt(item.id) === parseInt(id))
      return provider?.provider_name || 'Not Assigned'
    },

    async removeProvider () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('reg_service_providers/delete', this.serviceProviders.id)
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            serviceProviders: null
          }
        })
        await this.addNote('Service Providers removed')
        this.providerForm.provider = null
        this.$toast.success('Service Providers successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async createProvider () {
      if (this.$v.providerForm.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('registrations/update', {
          id: this.registration.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            serviceProviders: {
              ...this.providerForm,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          }
        })
        await this.addNote('Service Providers created')
        this.$toast.success('Service Providers successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },
  },

  validations () {
    const rules = {
      providerForm: {
        provider: { required }
      },
      profileSettingsform: {
        multiCurrency: { required },
      },
    }
    if(this.registration.accounts.length == 0){
      rules.profileSettingsform.administrative = { required }
      rules.profileSettingsform.remoteDepositCapture = { required }
      rules.profileSettingsform.create_subusers = { required }
      rules.profileSettingsform.welcome_email = { required }
      rules.profileSettingsform.review_days = { required }
      rules.profileSettingsform.language = { required }
    }

    if (!this.isPersonal) {
      rules.profileSettingsform.double_verification = { required }
      rules.profileSettingsform.create_subprofiles = { required }
    }

    return rules
  },
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}

.profile-settings {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
</style>
