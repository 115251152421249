<template>
<div>
  <section>
    <div class="flex-between">
      <h2 class="inner-headers">Transaction &gt; Statements</h2>

      <div style="padding: 24px 30px 10px 10px;">
        <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
      </div>
    </div>
  </section>

  <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

  <div class="accordion">
    <div :class="{'cof-box' : true, 'cof-box-active' : activeInfo}">
      <h4 class="accordion-headline" @click="activeInfo = !activeInfo">Statements</h4>
      <h3 class="total-number" @click="activeInfo = !activeInfo">({{ totalNumber }}) Click to expand</h3>
      <section class="content-tlist">
        <table class="content-table">
          <tr>
            <th>Statement Date</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Account</th>
            <th>Account Type</th>
            <th>Profile</th>
            <th>Profile Name</th>
            <th>Number</th>
            <th>Filename</th>
            <th>Options</th>
          </tr>
          <tr v-for="statement in sortStatements" :key="statement.id">
            <td><span>Statement Date</span>
              {{ statement.createdAt | date('MM/D/YYYY') }}
            </td>
            <td><span>Start Date</span>
              {{ statement.start | date('MM/D/YYYY') }}
            </td>
            <td><span>End Date</span>
              {{ statement.end | date('MM/D/YYYY') }}
            </td>
            <td><span>Account</span>
              {{ statement.registration.accounts[0] ? statement.registration.accounts[0].accountNumber : null }}
            </td>
            <td><span>Account Type</span>
              {{ statement.registration.type }}
            </td>
            <td><span>Profile</span>
              {{ statement.registration.profileNumber }}
            </td>
            <td><span>Profile Name</span>
              {{ statement.registration.name }}
            </td>
            <td><span>Number</span>
              {{ statement.number }}
            </td>
            <td><span>Filename</span>
              <template v-if="statement.file">
                <a :href="`/download/Statement/${statement.id}`" target="_blank">
                  {{ statement.file }}
                </a>
              </template>
            </td>
            <td><span>Options</span>
            </td>
          </tr>
        </table>
      </section>
    </div>
  </div>

  <statements-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
</div>
</template>

<script>
import VueEnglishdatepicker from 'vue-englishdatepicker'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import StatementsFilters from './filters/StatementsFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  components: {
    VueEnglishdatepicker,
    DateTimePicker,
    AppliedFilters,
    StatementsFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,

      registration: {},
      dateRangeLimitError: false,
      totalNumber: 0,
      activeInfo: false,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },
  computed: {
    appliedFilters () {
      const filterNames = {
        account: 'Account',
        accountType: 'Account Type',
        profileNumber: 'Profile',
        profileName: 'Profile Name',
        number: 'Statement Number'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.filters[key]}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    statements() {
      this.totalNumber = this.$store.state.statements.count || 0
      return this.$store.state.statements.data || []
    },

    nonCorporateStatements () {
      return this.statements.filter(statement => parseInt(statement.registration.corporate) === 0)
    },

    sortStatements () {
      let statements = this.nonCorporateStatements

      if (!this.filters || !Object.values(this.filters).filter(val => val).length) return statements

      if (this.filters?.account) {
        statements = statements.filter(statement => parseInt(statement.registration.accounts[0].accountNumber) === parseInt(this.filters.account))
      }


      if (this.filters.accountType) {
        statements = statements.filter(statement => statement.registration.type === this.filters.accountType)
      }

      if (this.filters.profileNumber) {
        statements = statements.filter(statement => parseInt(statement.registration.profileNumber) === parseInt(this.filters.profileNumber))
      }

      if (this.filters.profileName) {
        statements = statements.filter(statement => {
          return statement.registration.name.toLowerCase().includes(this.filters.profileName.toLowerCase())
        })
      }

      if (this.filters.number) {
        statements = statements.filter(statement => parseInt(statement.number) === parseInt(this.filters.number))
      }

      return statements
    }
  },

  async created () {
    await this.refreshStatements();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },


    async refreshStatements () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (appData.currentUser.isCustomer)
        params.regId=appData.currentUser.regId;
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
      } else {
        if(this.filters.fromDate && this.filters.toDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          if(to.diff(from, 'days') > 60) {
            this.dateRangeLimitError = true
            this.loading=false;
            loader.hide()
            return
          }else{
            this.dateRangeLimitError = false
          }
        }
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.account)
          params.accountId = this.filters.account;
        if (this.filters.number)
          params.accountType = this.filters.number;
      }

      await this.$store.dispatch('statements/load', { order: ['ID:DESC'], params, skip: ['terms', 'serviceProviders', 'complianceScan', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'account', 'mainAccount', 'user', 'setupComplete', 'createdBy', 'contact', 'terms', 'serviceProviders', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'solidAccount', 'primeAccount'] })
      this.loading=false;
      loader.hide()
    },
  },

  watch: {
    appliedFilters () {
      this.refreshStatements();
    },
  },
}
</script>
