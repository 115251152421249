<template>
  <div>
    <section v-if="!open">
      <div class="pin-entry">
        <div class="box">
          <div class="pf-row">
            <div class="pf-field">
              <h3>Access Denied</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="open">
      <div class="pin-entry">
        <div class="box">
        <h2>Enter System PIN to Continue</h2>
        <div class="pf-row">
          <div class="pf-field">
            <input
              v-model="pin"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type = "number"
              maxlength = "4"
              class="pin-code"
              v-on:keyup="pinInput"
            />
          </div>
        </div>
        <div class="pf-row buttons">
          <a href="" class="pop-btn" @click.prevent="cancel()">Cancel</a>
          <button class="pop-btn" @click="checkPin()">Confirm</button>
        </div>
        </div>
    </div>
    </section>
  </div>
</template>

<script>

  export default {
    name: 'system-pin-check',

    data() {
      return {
        open: true,
        pin: '',
      }
    },

    methods: {

      cancel() {
        this.open = false
      },

      async checkPin() {
        var result = await api.spincheck(this.pin)
        if (result.success) location.href = location.href
        else
          this.open = false
      },

      pinInput: function(e) {
        if (e.keyCode === 13) {
          this.checkPin()
        }
      },

    },

  }
</script>
