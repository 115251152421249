<template>
  <drawer
    v-model="drawer"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">
    <div class="mnb-field">
      <label>Date Range</label>
      <div class="flex-between">
        <date-time-picker
          v-model="form.fromDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
        <span>To</span>
        <date-time-picker
          v-model="form.toDate"
          value-type="String"
          value-format="yyyy-LL-dd"
          format="yyyy-LL-dd"
          class="position-bottom-right"
          :time-picker="false"
          :auto-close="true"
          min-date="2020-01-01"
          :max-date="today" >
        </date-time-picker>
      </div>
    </div>

    <div class="mnb-field">
      <label>Account Number</label>
      <input class="mnb-input" type="text" v-model="form.accountId"/>
    </div>

    <div class="mnb-field">
      <label>Transaction ID</label>
      <input class="mnb-input" type="text" v-model="form.transactionId"/>
    </div>

    <div class="mnb-field">
      <label>Entry Type</label>
      <select v-model="form.entryType" class="mnb-input">
        <option></option>
        <option
          v-for="(type, key) in entryTypes"
          :key="key"
          :value="key">
          {{ type }}
        </option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Transaction Type</label>
      <select v-model="form.depositType" class="mnb-input">
        <option></option>
        <option
          v-for="(type, key) in transactionTypes"
          :key="key"
          :value="key">
          {{ type }}
        </option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Amount</label>
      <input class="mnb-input" type="text" v-model="form.amount"/>
    </div>

    <div class="mnb-field">
      <label>Currency Type</label>
      <select v-model="form.currencyType" class="mnb-input">
        <option></option>
        <option
          v-for="(type, key) in currencyTypes"
          :key="key"
          :value="key">
          {{ type }}
        </option>
      </select>
    </div>

    <div class="mnb-field">
      <label>Currency</label>
      <select v-model="form.currencyName" class="mnb-input">
        <option></option>
        <option v-for="currency in currencies" :key="currency.id">{{ currency.currencyNickname }}</option>
      </select>
    </div>
    
    <div class="mnb-field">
      <label>Status</label>
      <select v-model="form.status" class="mnb-input">
        <option></option>
        <option
          v-for="(status, key) in transactionStatuses"
          :key="key"
          :value="key">
          {{ status }}
        </option>
      </select>
    </div>

    <template #footer>
      <button
        class="button secondary mr-5"
        @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button main"
        type="primary"
        @click="filter()">
        Filter
      </button>
    </template>
  </drawer>
</template>

<script>
import Drawer from '../../../components/Drawer'
import DateTimePicker from 'vue-vanilla-datetime-picker'

export default {
  props: {
    filters: {
      type: Object,
      default: null
    }
  },

  components: {
    Drawer,
    DateTimePicker
  },

  data () {
    return {
      form: {
        fromDate: null,
        toDate: null,
        entryType: null,
        depositType: null,
        transactionId: null,
        accountId: null,
        amount: null,
        status: null,
        currencyType: null,
        currencyName: null,
      },

      currencyTypes: window.currencyTypes,
      entryTypes: window.entryTypes,
      transactionTypes: window.transactionTypes,
      transactionStatuses: window.transactionStatuses,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      drawer: true
    }
  },

  computed: {
    currencies () {
      return window.currencies || []
    }
  },

  created () {
    if (this.filters) {
      this.form = JSON.parse(JSON.stringify(this.filters))
    }
  },

  methods: {
    filter () {
      this.$emit('apply', this.form)
    }
  }
}
</script>
