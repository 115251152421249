<template>
  <div class="cp-box" v-if="partner">
    <ul class="sub-tabs">
      <li :class="{ active: activeTab === 'compliance' }" @click="activeTab = 'compliance'"><a>Partner Review</a></li>
      <li :class="{ active: activeTab === 'account_status' }" @click="activeTab = 'account_status'"><a>Partner Status</a></li>
      <li v-if="featureStatus" :class="{ active: activeTab === 'scans' }" @click="activeTab = 'scans'"><a>Scans</a></li>
    </ul>
    <hr>
    <compliance-settings v-if="activeTab === 'compliance'" :registration="partner" />
    <compliance-scans v-if="activeTab === 'scans'" :registration="partner" />
    <compliance-account-status v-if="activeTab === 'account_status'" :registration="partner" />
  </div>
</template>

<script>
import ComplianceScans from './ComplianceScans'
import ComplianceSettings from './ComplianceSettings'
import ComplianceAccountStatus from './ComplianceAccountStatus'

export default {
  components: {
    ComplianceScans,
    ComplianceSettings,
    ComplianceAccountStatus
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'compliance',
      featureStatus : window.systemFeatures['Ongoing Compliance']
    }
  },

  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    },
  }
}
</script>
