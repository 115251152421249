<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Finalize</h2>
      <div class="status">
        Status
        <status-icon :color="partnerStatus === 'Pass' ? 'green' : null" />
      </div>
    </div>
    <table class="content-table">
      <tr>
        <th style="width: 13%;">Partner Type</th>
        <th style="width: 10%;">Partner Number</th>
        <th style="width: 13%;"></th>
        <th style="width: 10%;"></th>
        <th style="width: 10%;"></th>
        <th style="width: 13%;"></th>
      </tr>
      <tr>
        <td><span>Partner Type</span>
          {{ partner.type }}
        </td>
        <td><span>Partner Number</span>
          {{ partner.partnerNumber }}
        </td>
        <td><span>Date/Time</span>
          
        </td>
        <td><span>Account</span>
          
        </td>
        <td><span>Status</span>
          
        </td>
        <td><span>Result</span>
          
        </td>
      </tr>
    </table>
    <template v-if="!approved && partner.status != 'Denied'">
    <div class="header">
      <h2>Options</h2>
    </div>
    <div class="n-acc-check-opt">
      <div class="n-acc-actions">
        <button v-if="$hasPerm('partners-approve')"  class="main" @click="confirm()">
          Approve
        </button>
          <button v-if="$hasPerm('partners-approve')" class="main" @click="deny(partner.id)">
          Deny
        </button>
      </div>
    </div>
    </template>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import StatusIcon from './StatusIcon'
import MnbSelect from '../../../components/ui/forms/Select'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      partnerStatus: null,
    }
  },

  components: {
    StatusIcon,
    MnbSelect
  },

  computed: {
    approved () {
      return this.partner?.status == 'Pending' || this.partner?.status == 'Active'
    },
  },

  async created () {
    if (this.approved)
      this.partnerStatus='Pass';
  },

  methods: {
    async confirm() {
      if(confirm(`Are you sure?`)) {
        const loader = this.$loading.show()
        var data=await this.$store.dispatch('partners/action', {
          id: this.partner.id,
          action: 'approve',
          payload: {}
        })
        this.$toast.success('Partner successfully approved.')
        this.partnerStatus='Pass';
        loader.hide()
      }
    },
    async deny (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('partners/update', {
            id,
            payload: {
              status: 'Denied',
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('partner_notes/create', {
            createdBy: window.appData.currentUser.id,
            partnerId: id,
            notes: 'Status updated to Denied',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          loader.hide()
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}
.n-acc-actions {
  background: #f6f8f9;
  margin: 0 -30px;
  padding: 10px 30px;
}
.comliance-header {
  border-bottom: 1px solid #99aabb;
}
</style>
