<template>
  <section v-if="showAppMessage" class="form-box reg-form login-form">
    <template v-if="system">
      <h1 class="logo">
        <img :src="base + system.logo" :alt="`${system.logo} Logo`" />
      </h1>
      <h2><span>{{ system.name }}</span></h2>
      <h3>{{ system.login_text }}</h3>

      <div class="expired">
        <b>Password successfully reset.</b>

        <p><b>Please open the app to continue.</b></p>

        <p>You may also contact Support at:</p>

        <p>{{ system.support_email }}<br>{{ system.support_phone }}</p>
        <br /><br /><br /><br />
      </div>

    </template>
  </section>
  <section v-else-if="!resetUserPassword" class="form-box reg-form login-form">
    <template v-if="system">
      <h1 class="logo">
        <img :src="base + system.logo" :alt="`${system.logo} Logo`" />
      </h1>
      <h2><span>{{ system.name }}</span></h2>
      <template v-if="fullMaintenance">
        <h1 class="logo">
          <img class="maintenance-logo" src="/assets/img/tools-construction-svgrepo-com.svg" :alt="`${system.logo} Logo`" />
        </h1>
        <h2><span>SCHEDULED MAINTENANCE</span></h2>
        <br/>
        <p class="text-center">We apologize for any inconvenience this may cause and appreciate your patience.</p>
        <br/>
        <p class="text-center">Need assistance? You can contact support at:</p>
        <br/>
        <p class="text-center">{{ system.support_email}}</p>
        <p class="text-center">{{ system.support_phone}}</p>
      </template>
      <h3 v-else>{{ system.login_text }}</h3>
    </template>

    <template v-if="fullMaintenance">
      <div class="content-form">
        <div class="form-row form-fullw">
          &nbsp;
        </div>
      </div>
    </template>
    <template v-else>
      <form @submit.prevent="login">
        <div class="content-form">
          <div class="error-msg" v-if="error">
            {{ error }}
          </div>
       <!--  <div class="form-row form-fullw">
            <form-field-text label="PIN" />
          </div> -->
          <div class="form-row form-fullw">
            <form-field-text v-model="form.username" label="User name" required class="filled"  />
          </div>
          <div class="form-row form-fullw">
            <form-field-text v-model="form.password" type="password" label="Password" required  class="filled"  />
          </div>
          <div class="form-row form-center">
            <div>
              <button>Login</button>
            </div>
          </div>
          <div class="form-row form-fullw">
            <div class="txtc">
              <p>Forgot password? <a @click.prevent="resetUserPassword = true">Click here</a></p>
            </div>
          </div>
        </div>
      </form>
    </template>

    <popup :open.sync="resetModal" :width="470">
      <template v-if="loggingUser">
        <h2>{{ resetPassword ? 'Reset Password' : 'Account Activation' }}</h2>
        <div class="info">
          <div>ID:
            <template v-if="loggingUser.employee">{{ loggingUser.employee.employeeId }}</template>
            <template v-else-if="loggingUser.registration">{{ loggingUser.registration.mainAccount.profileNumber }}</template>
            <template v-else>N/A</template>
          </div>
          <div>Username: {{ loggingUser.username }}</div>
        </div>
        
        <form>
          <div class="content-form">
            <div class="form-row form-fullw">
              <form-field-text v-model="resetForm.password" type="password" :validator="$v.resetForm.password" label="Set Password" required  />
            </div>
            <div class="form-row form-fullw">
              <form-field-text v-model="resetForm.confirmPassword" type="password" :validator="$v.resetForm.confirmPassword" label="Confirm Password" required  />
            </div>

            <div v-if="!resetPassword" class="agree">
              <span>By activating my account, I agree to the:</span>
              <div>
                <label>
                  <input v-model="resetForm.data1" type="checkbox"> <a @click.prevent="termsModal = true">Terms &amp; Conditions</a>
                </label>
              </div>
              <div>
                <label>
                  <input v-model="resetForm.data2" type="checkbox"> <a @click.prevent="privacyModal = true">Privacy Policy</a>
                </label>
              </div>
            </div>
        
            <div class="form-row form-center">
              <div>
                <button type="button" @click="reset()">{{ resetPassword ? 'Reset' : 'Activate' }}</button>
              </div>
            </div>

            <a v-if="resetPassword" class="cancel" @click="resetModal = false">Cancel</a>
          </div>
        </form>
      </template>
      <template v-else>
        <div class="expired">
          <b>This {{resetPassword ? 'Link' : 'Activation Code' }} is Expired!</b>

          <p><b>Please request a new {{ resetPassword ? 'reset link' : 'activation code' }} and try again.</b></p>

          <p>You may also contact Support at:</p>

          <p>{{ system.support_email }}<br>{{ system.support_phone }}</p>

          <button class="btn" @click="resetModal = false">Close</button>
        </div>
      </template>
    </popup>
    <popup :open.sync="termsModal">
      <div class="privacy-popup scrollable">
        Terms and Conditions
        <terms-popup></terms-popup>
      </div>
    </popup>
    <popup :open.sync="privacyModal" >
      <div class="privacy-popup scrollable">
        Privacy Policy
        <privacy-popup></privacy-popup>
      </div>
    </popup>
  </section>
  <reset-password v-else/>
</template>

<script>
import helper from '../components/common/helper-mixin'
import { mustBeTrue, passwordValidation } from '../lib/validators'
import { required, sameAs } from 'vuelidate/lib/validators'

import resetPassword from './resetPassword'
import PrivacyPopup from '../PrivacyPopup.vue'
import TermsPopup from '../TermsPopup.vue'

export default {
  components: {
    resetPassword,
    PrivacyPopup,
    TermsPopup
  },

  mixins: [helper],

  data: ()=>({
    form: {
      username: null,
      password: null
    },

    resetForm: {
      password: null,
      confirmPassword: null,
      data1: false,
      data2: false,
    },

    focused: {
      password: null,
      confirmPassword: null
    },

    resetPassword: false,

    resetModal: false,
    expired: false,
    loggingUser: null,

    error: null,
    base: '/files/system/',

    activationCode: false,

    resetUserPassword: false,
    termsModal: false,
    privacyModal: false,
    showAppMessage: false,
    fullMaintenance: window.fullMaintenance
  }),

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
    if (this.getUrl('username') && this.getUrl('code')) {
      this.form.username = this.getUrl('username')
      this.form.password = this.getUrl('code')
      this.login()
      this.activationCode = true
    }
  },

  methods: {
    async login() {
      this.error = null
      var result = await api.login(this.form.username, this.form.password)
      if (result.success) location.href = '/'
      else if (result.not_active || result.passwordChange) {
        this.resetPassword = !!result.passwordChange
        this.resetModal = true
        if (!result.expired) {
          this.loggingUser = result.user
        }
      } else if(result.maintenance) {
        this.error = 'System is currently in maintenance. Please try again later.'
      } else {
        if (this.activationCode) {
          this.resetModal = true
          this.resetPassword = false
        }
        this.error = result.token === 'Blocked' ? 'Login Failed. Please Contact Support' : 'Invalid username or password'
      }
    },

    async reset () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (!this.resetPassword && (!this.resetForm.data1 || !this.resetForm.data2)) {
          return this.$toast.error('You need to agree with our Temrs and Conditions, Privacy Policy, User Policy')
        }
        if (!this.resetForm.password) {
          return this.$toast.error('Password field is required.')
        }
        if (this.resetForm.password !== this.resetForm.confirmPassword) {
          return this.$toast.error('Passwords do not match.')
        }
        return this.$toast.error('Password needs to contain small letter, capital letter, number, symbol and at least 6 characters.')
      }

      const response = await api.resetpw(this.form.username, this.form.password, this.resetForm.password)
      if (response.success) {
        if (this.resetPassword) {
          this.close()
          if (window.location.href.indexOf('app=1')!=-1)
            this.showAppMessage=true;
        } else {
          const result = await api.login(this.form.username, this.resetForm.password)
          if (result.success) location.href = "/"
        }
      } else {
        if (response.old_password) {
          this.$toast.error('New password can\'t be the same as the current password.')
        } else {
          this.$toast.error('There was an error. Please try again.')
        }
      }
    },

    close () {
      this.form.password = null
      this.resetForm = {
        password: null,
        confirmPassword: null,
        data1: false,
        data2: false,
        data3: false
      }
      this.resetPassword =  false
      this.resetModal =  false
      this.expired =  false
      this.loggingUser =  null
      this.error =  false
      this.activationCode = false
    }
  },

  validations () {
    const rules = {
      resetForm: {
        password: { required, passwordValidation },
        confirmPassword: { required, sameAsPassword: sameAs('password') },
      }
    }

    if (!this.resetPassword) {
      rules.resetForm.data1 = { mustBeTrue }
      rules.resetForm.data2 = { mustBeTrue }
    }
    
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .cancel {
    display: block; 
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .info {
    text-align: center;
    div {
      margin-bottom: 15px;
      font-weight: bold;
    }
  }

  .agree {
    margin-bottom: 20px;
    span {
      display: block;
      margin-bottom: 10px;
    }
  }

  .expired {
    text-align: center;
    font-size: 18px;
    color: #000;
    p {
      margin: 20px 0;
    }
  }
  
  .error-msg {
    text-align: center;
    display: block;
    margin-bottom: 15px;
    color: red;
    font-size: 16px;
  }

  .maintenance-logo {
    width: 100px !important;
  }
</style>
