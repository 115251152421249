<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Account Plan</h2>
      <div class="status">
        Status
        <status-icon :color="accountPlan ? 'green' : null" />
      </div>
    </div>
    
    <table class="content-table" style="min-width: unset;">
      <tr>
        <th style="width: 15%;">Account Plan</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr class="content-filters">
        <td colspan="2"><span>Plan</span>
          <mnb-select v-if="!accountPlan" v-model="form.accountPlan" class="select" :options="plans" :validator="$v.form.accountPlan" />
          <mnb-select v-else v-model="accountPlan" class="select" :options="plans" :disabled="true" />
        </td>
        <td colspan="5"></td>
      </tr>
      <tr>
        <th></th>
        <th style="width: 15%;">Fee Name</th>
        <th>Fee</th>
        <th>Deposit</th>
        <th>Withdrawal</th>
        <th>Limits</th>
      </tr>
      <template v-if="feeSchedule">
        <tr>
          <td></td>
          <td>
            <span>Fee Name</span>
            Volume Fee
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              {{ feeSchedule.volumeFee.fee | formattedTwoDecimal }} %
            </template>
            <template v-else>
              <input
                v-model="feeSchedule.volumeFee.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.volumeFee.fee"
              /> %
            </template>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span>Fee Name</span>
            Monthly Access Fee
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              $ {{ feeSchedule.monthlyAccessFee.fee | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.monthlyAccessFee.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.monthlyAccessFee.fee"
              />
            </template>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span>Fee Name</span>
            Dormant Accounts
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              $ {{ feeSchedule.dormantAccounts.fee | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.dormantAccounts.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.dormantAccounts.fee"
              />
            </template>
          </td>
          <td>
            
          </td>
          <td>
            
          </td>
          <td></td>
        </tr>
        <tr v-if="provider.ach == 1">
          <td>
            <template v-if="!accountPlan">
              <mnb-switch  v-if="provider.ach == 1" v-model="profileSettingsform.ach" :validator="$v.profileSettingsform.ach" :disabled="customEnv.noach || !profileSettingsform.account" />
              <mnb-switch  v-else :value="false" disabled />
            </template>
            <mnb-switch v-else :value="profileSettingsform.ach == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            ACH
          </td>
          <td>
            <span>Fee</span>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.ach.per_deposit | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.ach.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.ach.per_deposit"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.ach.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.ach.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.ach.per_withdrawal"
              />
            </template>
          </td>
          <td><span>ACH</span>
            <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.ach_limit" class="input" label="Limit" no-style :disabled="customEnv.noach || provider.ach != 1 || !profileSettingsform.ach" /></div>
            <template v-else>$ {{ profileSettingsform.ach_limit }}</template>
          </td>
        </tr>
        <tr v-if="provider.acct == 1">
          <td><span>Account to Account</span>
            <template v-if="!accountPlan">
              <mnb-switch v-if="provider.acct == 1" v-model="profileSettingsform.acc_to_acc" :validator="$v.profileSettingsform.acc_to_acc" />
              <mnb-switch v-else :value="false" disabled/>
            </template>
            <mnb-switch v-else :value="profileSettingsform.acc_to_acc == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            Account Transfers
          </td>
          <td>
            <span>Fee</span>
          </td>
          <td>
            
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.accountTransfer.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.accountTransfer.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.accountTransfer.per_withdrawal"
              />
            </template>
          </td>
          <td><span>Account to Account</span>
            <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.acc_to_acc_limit" class="input" label="Limit" no-style :disabled="provider.acct != 1 || !profileSettingsform.acc_to_acc" /></div>
            <template v-else>$ {{ profileSettingsform.acc_to_acc_limit }}</template>
          </td>
        </tr>
        <tr v-if="provider.checks == 1">
          <td><span>Checks</span>
            <template v-if="!accountPlan">
              <mnb-switch v-if="provider.checks == 1" v-model="profileSettingsform.checks" :validator="$v.profileSettingsform.checks" />
              <mnb-switch v-else :value="false" disabled />
            </template>
            <mnb-switch v-else :value="profileSettingsform.checks == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            Checks
          </td>
          <td>
            <span>Fee</span>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.checks.per_deposit | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.checks.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.checks.per_deposit"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.checks.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.checks.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.checks.per_withdrawal"
              />
            </template>
          </td>
          <td><span>Checks</span>
            <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.checks_limit" class="input" label="Limit" no-style :disabled="provider.checks != 1 || !profileSettingsform.checks" /></div>
            <template v-else>$ {{ profileSettingsform.checks_limit }}</template>
          </td>
        </tr>
        <tr v-if="provider.domWires == 1">
          <td><span>Wire Transfer</span>
            <template v-if="!accountPlan">
              <mnb-switch v-if="provider.domWires == 1" v-model="profileSettingsform.domWires" :validator="$v.profileSettingsform.domWires" />
              <mnb-switch v-else :value="false" disabled />
            </template>
            <mnb-switch v-else :value="profileSettingsform.domWires == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            Domestic Wire
          </td>
          <td>
            <span>Fee</span>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.domesticWire.per_deposit | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.domesticWire.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.domesticWire.per_deposit"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.domesticWire.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.domesticWire.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.domesticWire.per_withdrawal"
              />
            </template>
          </td>
          <td><span>Wire Transfer</span>
            <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.domWires_limit" class="input" label="Limit" no-style :disabled="provider.domWires != 1 || !profileSettingsform.domWires" /></div>
            <template v-else>$ {{ profileSettingsform.domWires_limit }}</template>
          </td>
        </tr>
        <tr v-if="provider.intlWires == 1">
          <td><span>Wire Transfer</span>
            <template v-if="!accountPlan">
              <mnb-switch v-if="provider.intlWires == 1" v-model="profileSettingsform.intlWires" :validator="$v.profileSettingsform.intlWires" />
              <mnb-switch v-else :value="false" disabled />
            </template>
            <mnb-switch v-else :value="profileSettingsform.intlWires == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            International Wire
          </td>
          <td>
            <span>Fee</span>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.internationalWire.per_deposit | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.internationalWire.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.internationalWire.per_deposit"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.internationalWire.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.internationalWire.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.internationalWire.per_withdrawal"
              />
            </template>
          </td>
          <td><span>Wire Transfer</span>
              <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.intlWires_limit" class="input" label="Limit" no-style :disabled="provider.intlWires != 1 || !profileSettingsform.intlWires" /></div>
              <template v-else>$ {{ profileSettingsform.intlWires_limit }}</template>
            </td>
        </tr>
        <tr v-if="provider.debitCards == 1">
          <td><span>Debit Cards</span>
            <template v-if="!accountPlan">
              <mnb-switch v-if="provider.debitCards == 1" v-model="profileSettingsform.debitCards" :validator="$v.profileSettingsform.debitCards" />
              <mnb-switch v-else :value="false" disabled />
            </template>
            <mnb-switch v-else :value="profileSettingsform.debitCards == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            Debit Card
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              $ {{ feeSchedule.debitCard.fee | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.debitCard.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.debitCard.fee"
              />
            </template>
          </td>
          <td>
            
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.debitCard.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.debitCard.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.debitCard.per_withdrawal"
              />
            </template>
          </td>
          <td><span>Debit Cards</span>
            <div v-if="!accountPlan" class="money-input"><span>$</span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.debit_cards_limit" class="input" label="Limit" no-style :disabled="provider.debitCards != 1 || !profileSettingsform.debitCards" /></div>
            <template v-else>$ {{ profileSettingsform.debit_cards_limit }}</template>
          </td>
        </tr>
        <tr>
          <td><span>Trade Desk</span>
            <template v-if="!accountPlan">
              <mnb-switch v-model="profileSettingsform.trade_desk" :validator="$v.profileSettingsform.trade_desk" />
            </template>
            <mnb-switch v-else :value="profileSettingsform.trade_desk == 1" disabled />
          </td>
          <td>
            <span>Fee Name</span>
            Trade Desk
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              $ {{ feeSchedule.exchange.fee | formattedTwoDecimal }} 
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.exchange.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.exchange.fee"
              />
            </template>
          </td>
          <td>
            
          </td>
          <td>
            
          </td>
          <td><span>Trade Desk</span>
            <div v-if="!accountPlan" class="money-input"><span></span> <mnb-input v-if="!accountPlan" v-model="profileSettingsform.trade_desk_limit" class="input" label="Limit" no-style :disabled="!profileSettingsform.trade_desk" /></div>
            <template v-else>{{ profileSettingsform.trade_desk_limit }}</template>
          </td>
        </tr>
        <!-- <tr>
          <td>
            <span>Fee Name</span>
            Assets
          </td>
          <td>
            <span>Fee</span>
            <template v-if="accountPlan">
              $ {{ feeSchedule.assets.fee | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.assets.fee"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.fee"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.assets.per_deposit | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.assets.per_deposit"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.per_deposit"
              />
            </template>
          </td>
          <td>
            <template v-if="accountPlan">
              $ {{ feeSchedule.assets.per_withdrawal | formattedTwoDecimal }}
            </template>
            <template v-else>
              $ <input
                v-model="feeSchedule.assets.per_withdrawal"
                type="number"
                class="fee-input"
                :validator="$v.feeSchedule.assets.per_withdrawal"
              />
            </template>
          </td>
        </tr> -->
      </template>
    </table>

    <div class="footer space">
      <span>
        <b>Date / Time:</b><br>
        <template v-if="!accountPlan">N/A</template>
        <template v-else>{{ registration.updatedAt | date('LL') }}<br>{{ registration.updatedAt | date('LT') }}</template>
      </span>
      <div v-if="registration.status !== 'Complete'">
        <button v-if="!accountPlan" class="main" @click="create()" :disabled="!form.accountPlan || $v.feeSchedule.$invalid">Confirm</button>
        <button v-else-if="registration.status != 'Active'" class="second" @click="remove()">Remove</button>
      </div>
    </div>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'
import MnbSelect from '../../../components/ui/forms/Select'
import MnbSwitch from '../../../components/ui/forms/Switch'
import MnbInput from '../../../components/ui/forms/Input'

import { required } from 'vuelidate/lib/validators'

const initialForm = {
  accountPlan: null,
}

const initialSettingsForm = {
  domWires: false,
  intlWires: false,
  ach: false,
  checks: false,
  acc_to_acc: false,
  assets: false,
  trade_desk: false,
  remoteDeposit: false,
  debitCards: false,
  domWires_limit: null,
  intlWires_limit: null,
  ach_limit: null,
  checks_limit: null,
  acc_to_acc_limit: null,
  assets_limit: null,
  trade_desk_limit: null,
  debit_cards_limit: null,
}

export default {
  props: {
    registration: {
      type: Object,
      required: true
    },
    step: {}
  },

  components: {
    MnbSelect,
    StatusIcon,
    MnbSwitch,
    MnbInput,
  },

  data () {
    return {
      form: { ...initialForm },
      customEnv: appData.customEnv,
      profileSettingsform: { ...initialSettingsForm },
      providerForm: {
        provider: null
      },
    }
  },

  computed: {
    isPersonal () {
      return this.registration?.type === 'Personal'
    },

    accountPlan () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.accountPlan || null
    },

    feeSchedule () {
      let data = this.$store.state.fee_plan.data || []
      return this.registration?.accounts[this.registration.accounts.length-1]?.feeSchedule || data.find(item => item.id == this.form.accountPlan)?.feeSchedule
    },

    plans () {
      let data = this.$store.state.fee_plan.data || []
      return data.map(acc => ({
          id: acc.id,
          text: acc.name,
          disabled: !acc.accountTypes?.includes(this.registration?.type)
        }))
    },

    profileSettings () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.profileSettings || null
    },

    provider () {
      return this.providers.find(provider => provider.id === this.providerForm.provider)
    },

    providers () {
      return this.$store.state.providersList.data || []
    },
  },

  async created () {
    await this.$store.dispatch('fee_plan/load')
    this.providerForm.provider =  this.registration?.accounts[this.registration.accounts.length-1]?.serviceProviders?.id
    if (this.profileSettings) {
      this.profileSettingsform = this.profileSettings
    } else if (!this.isPersonal) {
      this.profileSettingsform.double_verification = false
    }
  },

  methods: {
    async create () {
      this.$v.form.$touch()
      this.$v.feeSchedule.$touch()
      if (this.$v.form.$invalid || this.$v.feeSchedule.$invalid) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/update', {
          id: this.registration?.accounts[this.registration.accounts.length-1]?.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            accountPlan: this.form.accountPlan,
            feeSchedule: this.feeSchedule,
            profileSettings: {
              ...this.profileSettingsform,
            }
          }
        })
        await this.$store.dispatch('registrations/get', this.registration.id)
        await this.addNote('Account Plan created')
        this.$toast.success('Account Plan successfully created.')
        this.$emit("update:step", this.step+1)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('accounts/update', {
          id: this.registration?.accounts[this.registration.accounts.length-1]?.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            accountPlan: null,
            feeSchedule: null
          }
        })
        await this.$store.dispatch('registrations/get', this.registration.id)
        this.form = initialForm
        await this.addNote('Account Plan removed')
        this.$toast.success('Account Plan successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('profilenotes/create', {
        createdBy: window.appData.currentUser.id,
        registrationId: this.registration.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },
  },

  validations () {
    let rules = {
      form: {
        accountPlan : { required },
      },
      profileSettingsform: {
        domWires: { required },
        intlWires: { required },
        ach: { required },
        checks: { required },
        acc_to_acc: { required },
        assets: { required },
        trade_desk: { required },
        debitCards: { required },
        trade_desk: { required },
      },
      feeSchedule: {
        volumeFee : { 
          fee: { required }
        },
        monthlyAccessFee : { 
          fee: { required }
        },
        domesticWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        internationalWire : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        ach : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        accountTransfer : { 
          per_withdrawal: { required }
        },
        checks : { 
          per_deposit: { required },
          per_withdrawal: { required }
        },
        exchange : { 
          fee: { required },
        },
        assets : { 
          fee: { required },
          per_deposit: { required },
          per_withdrawal: { required }
        },
        dormantAccounts : { 
          fee: { required },
        },
        debitCard : { 
          fee: { required },
          per_withdrawal: { required }
        },
      },
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}

.select {
  width: 115px;
}

.space {
  td, th {
    padding-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 0 34px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.fee-input {
  max-width: 50px;
}
.fee-input::-webkit-inner-spin-button,
.fee-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

</style>
