<template>
  <div v-if="user">
    <section>
      <div>
        <h2 class="inner-headers">My Profile</h2>
      </div>
    </section>
    <section class="client-properties active">
      <div class="cp-box">
        <ul class="sub-tabs" style="margin-bottom:50px">
          <li :class="{ active: active === 1 }" @click="active = 1"><a>My Info</a></li>
          <li :class="{ active: active === 2 }" @click="active = 2"><a>User Log</a></li>
          <li :class="{ active: active === 3 }" @click="active = 3"><a style="background:limegreen">Change Password</a></li>
        </ul>
        
        <div v-if="active === 1">
          <ul class="cp-fields justify-content-none">
            <li>
              <span>Active Since</span>
              <div class="val">
                {{ user.activationDate | date }}
              </div>
            </li>
            <li>
              <span>Last Password Change Date</span>
              <div class="val">
                <template v-if="user.lastPasswordChangeDate">{{ user.lastPasswordChangeDate | date }}</template>
                <template v-else>N/A</template>
              </div>
            </li>
            <li>
              <span>Employee ID</span>
              <div class="val">
                {{ user.employee ? user.employee.employeeId : 'N/A' }}
              </div>
            </li>
            <li>
              <span>Role</span>
              <div class="val">
                {{ user.employee ? user.employee.roles.name : 'N/A' }}
              </div>
            </li>
          </ul>
          <h4 class="title">Employee Information</h4>
          <ul class="cp-fields justify-content-none space-bottom">
            <li>
              <span>First Name</span>
              <div class="val">
                {{ user.firstName }}
              </div>
            </li>
            <li>
              <span>Last Name</span>
              <div class="val">
                {{ user.lastName }}
              </div>
            </li>
            <li>
              <span>Email Address</span>
              <div class="val">
                {{ user.email }}
              </div>
            </li>
            <li>
              <span>Phone Number</span>
              <div class="val">
                {{ user.employee ? user.employee.phone : 'N/A' }}
              </div>
            </li>
          </ul>
        </div>

        <div v-if="active === 2">
          <ul
            v-if="lastLogin"
            class="cp-fields justify-content-none">
            <li>
              <span>Last Login</span>
              <div class="val">
                {{ lastLogin.recorded | date }}
              </div>
            </li>
            <li>
              <span>Data Transfer</span>
              <div class="val">
                {{ lastLogin.transferred | fileSize }}
              </div>
            </li>
            <li>
              <span>IP Address</span>
              <div class="val">
                {{ lastLogin.ipAddress }}
              </div>
            </li>
          </ul>
        </div>

        <change-password v-if="active === 3" />
      </div>
    </section>
  </div>
</template>

<script>
import ChangePassword from './ChangePassword'
import helper from '../../components/common/helper-mixin'

export default {
  name: 'EmployeeProfile',

  mixins: [helper],

  components: {
    ChangePassword
  },

  data () {
    return {
      active: 1
    }
  },

  computed: {
    user () {
      return this.$store.getters['users/get'](window.appData.currentUser.id)
    },

    lastLogin () {
      return this.$store.getters['users_logins/get'](this.user?.lastLogin) || null
    }
  },

  async created () {
    await this.$store.dispatch('users/get', window.appData.currentUser.id)
    if (this.user?.lastLogin) await this.$store.dispatch('users_logins/get', this.user.lastLogin)

    const active = parseInt(this.getUrl('active'))
    if ([1, 2, 3].includes(active)) {
      this.active = active
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0;
}
.space-bottom {
  margin-bottom: 20px;
}
</style>
