import { render, staticRenderFns } from "./Emails.vue?vue&type=template&id=7d36dcd0&scoped=true&"
import script from "./Emails.vue?vue&type=script&lang=js&"
export * from "./Emails.vue?vue&type=script&lang=js&"
import style0 from "./Emails.vue?vue&type=style&index=0&id=7d36dcd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36dcd0",
  null
  
)

export default component.exports