<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
    <section class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="cof-field sysint-page">
            <label>Currency Type</label>
            <mnb-select v-model="form.currencyType" :validator="$v.form.currencyType" :options="typeOptions" />
          </div>
          <div class="cof-field sysint-page">
            <mnb-input v-model="form.currencyNickname" :validator="$v.form.currencyNickname" label="Currency Nickname" />
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Currency</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetFields()">Reset</button>
          </div>
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Currency Type</th>
          <th>Currency Nickname</th>
          <th>Currency Name</th>
          <th>Currency Description</th>
          <th>Date Added</th>
          <th>Trade Desk</th>
          <th>Options</th>
        </tr>
        <tr v-for="(currency, i) in currencies" :key="i">
          <td><span></span>
            {{ currency.systemCurrencyType }}
          </td>
          <td><span>Currency Nickname</span>{{ currency.currencyNickname }}</td>
          <td><span>Currency Name</span>{{ currency.name }}</td>
          <td><span>Currency Description</span>{{ currency.description }}</td>
          <td>
            <span>Date Added</span>
            {{ currency.dateAdded | date }}
          </td>
          <td>
            <span>Trade Desk</span>
            <input v-if="currency.systemCurrencyType == 'Assets'" type="checkbox" class="fee-checkbox" v-model="currency.tradeDesk" @change="checkTradeDesk(currency.id, currency)">
          </td>
          <td>
            <span>Options</span>
            <button @click="update(currency.id)" class="action-button update">Update</button>
            <button @click="" class="action-button remove">Remove</button>
          </td>
        </tr>
      </table>
    </section>

    <popup :open.sync="open" :width="400">
      <div class="scrollable">
        <p>{{ message }}</p>
      </div>
    </popup>
    <popup :open.sync="openConfirm" :width="400">
      <div class="popup-header">
        <h3>Confirm New Currency</h3>
      </div>
      <div class="scrollable">
        <table class="content-table">
          <tr>
            <th>Type</th>
            <th>Currency</th>
            <th>Name</th>
          </tr>
          <tr>
            <td>
              {{ form.currencyType }}
            </td>
            <td>
              {{ form.currencyNickname }}
            </td>
            <td>
              {{ currency? currency.name : '' }}
            </td>
          </tr>
        </table>
      </div>
      <br/>
      <div class="buttons">
        <button class="main" @click="confirm()">Confirm</button>
        <button class="second" @click="openConfirm = false">Close</button>
      </div>
    </popup>
  </div>
</template>

<script>
  import BasicForm from '../../components/ui/forms/Form'
  import MnbInput from '../../components/ui/forms/Input'
  import MnbSelect from '../../components/ui/forms/Select'
  import Popup from '../popup'
  import { monbiAccountNumber, alphaNum, routingNumber, swift } from '../../lib/validators'
  import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

  export default {
    name: 'currencies',

    components: {
      BasicForm,
      MnbInput,
      MnbSelect,
      Popup
    },

    data () {
      return {
        open: false,
        openConfirm: false,

        form: {
          currencyType: null,
          currencyNickname: null
        },
        message: null,
        currency: null,
        featureStatus: true,
        featureFundsStatus: true,
      }
    },

    computed: {
      currencies () {
        return this.$store.state.currency.data
      },


      typeOptions () {
        const options = [
          {
            id: "Funds",
            text: 'Funds',
            disabled: !this.featureFundsStatus
          },
          {
            id: "Assets",
            text: 'Assets',
            disabled: !this.featureStatus
          }
        ]
        return options
      },
    },

    async created () {
      this.getFeatureStatus();
      await this.$store.dispatch('currency/load')
    },

    methods: {
      async save () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          return
        }
        const loader = this.$loading.show()
        let payload;
        payload = {
          currencyType: this.form.currencyType,
          currencyNickname: this.form.currencyNickname
        }
        const response = (await api.action("Currency", "new", "Create", payload))
        if(response.action.error) {
          this.open=true
          this.message = "Error adding Currency." + response.action.errors
        }
        if(response.action.noFound) {
          this.open=true
          this.message = "Currency not found. Please check information and try again."
        }
        if(response.action.success) {
          this.currency = response.item
          this.openConfirm=true

//          this.message = "Currency Validated and Added."
//          this.$store.dispatch('currency/load')
        }
        loader.hide()
      },

      async confirm () {
        this.$v.$touch()
        if (this.$v.form.$invalid) {
          return
        }
        const loader = this.$loading.show()
        let payload;
        payload = {
          currencyType: this.form.currencyType,
          currencyNickname: this.form.currencyNickname
        }
        const response = (await api.action("Currency", "new", "Confirm", payload))
        if(response.action.error) {
          this.open=true
          this.openConfirm=false
          this.message = "Error adding Currency." + response.action.errors
        }
        if(response.action.noFound) {
          this.open=true
          this.openConfirm=false
          this.message = "Currency not found. Please check information and try again."
        }
        if(response.action.success) {
          this.open=true
          this.openConfirm=false
          this.message = "Currency Validated and Added."
          this.$store.dispatch('currency/load')
        }
        loader.hide()
      },

      async update (id) {
        const loader = this.$loading.show()

        const response = (await api.action("Currency", id, "Update", {}))
        if(response.action.error) {
          this.open=true
          this.message = "Error adding Currency." + response.action.errors
        }
        if(response.action.noFound) {
          this.open=true
          this.message = "Currency not found. Please check information and try again."
        }
        if(response.action.success) {
          this.open=true
          this.message = "Currency Validated and Added."
        }
        loader.hide()
      },

      async getFeatureStatus () {
        this.featureStatus = window.systemFeatures['Digital Assets']
        this.featureFundsStatus = window.systemFeatures['Multi-Currency']
      },

      async checkTradeDesk(id, currency){
        let payload = {
          currency
        }
        const account = (await api.action("Currency", id, "CheckTradeDesk", { tradeDesk: currency.tradeDesk})).action
      },
    },

    validations () {
      const rules = {
        form: {
          currencyType: { required },
          currencyNickname: { required, alphaNum,  maxLength: maxLength(9) }
        }
      }
      return rules
    }
  }
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px;
  }
  .errors {
    color: #ff0000 !important;
  }
  // .status-options {
  //   margin-bottom: 30px;
  // }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
  .updateAt {
    font-size: 10px;
    width: 155px;
  }
  .form-max {
    div {
      width: 100%;
    }
  }
</style>
