<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Partner Schedule</h2>
      <div class="status">
        Status
        <status-icon :color="schedule ? 'green' : null" />
      </div>
    </div>
    <table class="content-table">
      <tr>
          <th style="width: 15%;">Fee Name</th>
          <th style="width: 10%;">Fee</th>
          <th style="width: 10%;">Deposit</th>
          <th style="width: 10%;">Withdrawal</th>
        </tr>
        <tr v-for="(item, i) in fees" :key="i">
          <template v-if="i==0">
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template v-if="schedule"> {{ item.fee | formattedTwoDecimal }} </template>
              <input
                v-else
                v-model="item.fee"
                type = "number"
                class="fee-input"
              />%
            </td>
            <td></td>
            <td></td>
          </template>
          <template v-else-if="i != 'createdAt'">
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template v-if="!noneEditable(item.name, 'fee')">
                $<template v-if="schedule"> {{ item.fee | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.fee"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
            <td>
              <span>Per Deposit</span>
              <template v-if="!noneEditable(item.name, 'deposit')">
                $<template v-if="schedule"> {{ item.per_deposit | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.per_deposit"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
            <td>
              <span>Per Withdrawal</span>
              <template v-if="!noneEditable(item.name, 'withdrawal')">
                $<template v-if="schedule"> {{ item.per_withdrawal | formattedTwoDecimal }} </template>
                <input
                  v-else
                  v-model="item.per_withdrawal"
                  type = "number"
                  class="fee-input"
                />
              </template>
            </td>
          </template>
        </tr>
    </table>

    <div class="footer space">
      <span>
        <b>Date / Time:</b><br>
        <template v-if="!schedule">N/A</template>
        <template v-else>{{ schedule.createdAt | date('LL') }}<br>{{ schedule.createdAt | date('LT') }}</template>
      </span>
      <div>
        <button v-if="!schedule" class="main" @click="create()">Create</button>
        <button v-else class="second" @click="remove()">Remove</button>
      </div>
    </div>
  </section>
</template>

<script>
import StatusIcon from './StatusIcon'
import MnbInput from '../../../components/ui/forms/Input'
import MnbSelect from '../../../components/ui/forms/Select'
import MnbSwitch from '../../../components/ui/forms/Switch'

import { required } from 'vuelidate/lib/validators'
import Templates from '../../transactions/Templates.vue'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  components: {
    MnbInput,
    MnbSelect,
    MnbSwitch,
    StatusIcon
  },

  data () {
    return {
      isEdit: false,
    }
  },

  computed: {
    schedule () {
      return this.partner?.schedule || null
    },

    fees () {
      let debit = false
      let debitCard
      let data = []
      data = this.partner.schedule || this.$store.state.fee_schedule.data || []

      if(this.partner.schedule) {

      }else{
        data.forEach(item => {
          item.fee = 0
          item.per_withdrawal = 0
          item.per_deposit = 0
        })
      }
      return data
    }
  },

  async created () {
    await this.$store.dispatch('fee_schedule/load')
  },

  methods: {

    noneEditable (name, column) {
      const noneeditable = {
        fee: ['Domestic Wire Transfer', 'International Wire Transfer', 'ACH Transfer', 'Account to Account Transfer', 'Checks'],
        deposit: ['Volume Fee', 'Monthly Access Fee', 'Account to Account Transfer', 'Exchange Transactions', 'Dormant Accounts', 'Debit Card'],
        withdrawal: ['Volume Fee', 'Monthly Access Fee', 'Exchange Transactions', 'Dormant Accounts' ]
      }
      return noneeditable[column].includes(name)
    },

    async create () {
      const loader = this.$loading.show()
      try {
        
        var current_timestamp = new Date()
        await this.$store.dispatch('partners/update', {
          id: this.partner?.id,
          payload: {
            updatedAt: 'CURRENT_TIMESTAMP',
            schedule: {
              ...this.fees,
              createdAt: current_timestamp
            }
          }
        })
        
        await this.addNote('Partner Schedule created')
        this.$toast.success('Partner Schedule successfully created.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async remove () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('partners/update', {
          id: this.partner.id,
          payload: { updatedAt: 'CURRENT_TIMESTAMP', schedule: null }
        })
        await this.addNote('Partner Schedule removed')
        this.$toast.success('Partner Schedule successfully removed.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    addNote (notes) {
      return this.$store.dispatch('partner_notes/create', {
        createdBy: window.appData.currentUser.id,
        partnerId: this.partner.id,
        notes,
        createdAt: 'CURRENT_TIMESTAMP'
      })
    },
  },

  validations () {
   
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}

.checkbox-large {
  width: 17px;
  height: 17px;
}

.money-input {
  display: flex;
  span {
    display: inline-block;
    width: 15px;
    line-height: 29px;
  }
  .input {
    width: 100px;
  }
}
.select {
  width: 115px;
}

.space {
  td, th {
    padding-top: 20px;
  }
}

.footer {
  display: flex;
  padding: 0 34px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.profile-settings {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.fee-input {
    max-width: 50px;
}
.fee-input::-webkit-inner-spin-button,
.fee-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
