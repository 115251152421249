<template>
  <div>
    <section>
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 24px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>
    <template>
      <button class="btn btn-success" @click="openModal('withdraw')" style="margin-top:-60px"><i class="el-icon-plus" /> New Template</button>
    </template>
    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div v-if="!modalType && !open" class="accordion">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist" v-if="!loading">
          <table class="content-table">
            <tr>
              <th style="width: 11%; text-align: center;">Created <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('createdAt')"/></th>
              <th style="width: 11%; text-align: center;">Updated</th>
              <th style="width: 5%; text-align: center;">By</th>
              <th style="width: 6%; text-align: center;">Account <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('account')"/></th>
              <th style="width: 13%; text-align: center;">Template <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('templateName')"/> </th>
              <th style="width: 13%; text-align: center;">Beneficiary</th>
              <th style="width: 7%; text-align: center;">Method <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('method')"/></th>
           <!--   <th style="width: 7%; text-align: center;">Currency <img src="/assets/img/updown.png" width="13" @click="sortTemplateName('currency')"/></th> -->
              <th style="width: 25%; text-align: center;">Options</th>
            </tr>
            <tr v-for="(item, i) in filteredData" :key="i">
              <td style="text-align: center;"><span>Date Created</span>{{ item.createdAt | date('MM/D/YYYY') }}</td>
              <td style="text-align: center;"><span>Last Updated</span>
                <template v-if="item.updatedAt">{{ item.updatedAt | date('MM/D/YYYY') }}</template>
              </td>
              <td style="text-align: center;"><span>By</span>
                {{ item.savedBy }}
              </td>
              <template>
                <td style="text-align: center;" v-if="isCustomer"><span>Account</span>{{ item.account }}</td>
                <td style="text-align: center;" v-else><span>Account</span><a :href="`/system-customers/profiles/${item.regId}`">{{ item.account }}</a></td>
              </template>
              <td style="text-align: center;"><span>Template Name</span><a class="link" @click="openTemplateModal(item)">{{ item.templateName }}</a></td>
              <td style="text-align: center;"><span>Template Name</span>
                <template v-if="item.method == 'Asset'">{{ item.payeeToName }}</template>
                <template v-else-if="item.method == 'checks'">{{ item.payee }}</template>
                <template v-else>{{ item.accountName }}</template>
              </td>
              <td style="text-align: center;"><span>Method</span>{{ transactionTypeText[item.method] }}</td>
          <!--    <td style="text-align: center;"><span>Currency</span>{{ item.currency }}</td> -->
              <td style="text-align: center;">
                <span>Options</span>
                <button @click="openTemplateModal(item)" class="action-button save">Update</button>
                <button @click="newTrans(item)" class="action-button save" :disabled="item.provider.status == 'Disabled' || !system.transaction_withdrawals || item.accountStatus != 'Open' && item.accountStatus != 'Active'">New Trans</button>
                <button @click="removeTemplate(item)" class="action-button remove">Remove</button>
              </td>
            </tr>
            <tr v-if="!templates.length">
              <td colspan="11" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>
    <div v-if="template" style="display: flex; justify-content: space-around;">
      <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
        <div class="popup-header">
          <template v-if="addConfirmModal">
            <h3 v-if="template">{{ template.method != 'Asset' ? 'Funds Withdrawal' : 'Assets Withdrawal' }}</h3>
            <span v-if="transaction">Transaction: {{ transaction.transactionId }}</span>
          </template>
          <template v-else-if="newTransFlag">
            <h3>New Transaction</h3>
            <h3>{{ template.type }}</h3>
            <h3 style="width: 100px;"></h3>
          </template>
          <template v-else>
            <h3>Update Template</h3>
            <h3>{{ template.templateName }}</h3>
            <h3 style="width: 100px;"></h3>
          </template>
        </div>
        <div class="scrollable" v-if="!addConfirmModal">
          <div class="content-form content-form-modern" v-if="template.method != 'Asset'">
            <div class="form-row form-split">
              <form-field-text v-model="templateAccount" label="Account" disabled="true" required />
              <form-field-text v-model="template.currency" label="Currency" disabled="true" required />
            </div>
            <div class="form-row form-split">
              <form-field-text v-model="templateMethod" label="Payment Method" disabled="true" :options="depositOptions" required />
              <form-field-text v-if="newTransFlag" v-model="template.amount" :validator="$v.template.amount" label="Amount"  required />
            </div>
          </div>
          <div class="content-form content-form-modern" v-else>
            <div class="form-row form-split">
              <form-field-text v-model="templateAccount" label="Account" disabled="true" required />
              <form-field-text v-model="template.currency" label="Currency" disabled="true" required />
            </div>
            <div class="form-row form-split">
              <form-field-select v-model="template.sendingTo" label="Sending to" disabled="true" :options="sendingToOptions" required />
              <form-field-text v-if="newTransFlag" v-model="template.assetsAmount" :validator="$v.template.assetsAmount" label="Units Withdrawal Amount"  required />
            </div>
          </div>
          <div class="invalid-balance">
            <div class="error-message" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>
          </div>
          <div class="invalid-balance">
            <div class="error-message" v-if="invalidToProvider"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
          </div>
          <div class="sum" v-if="template.method != 'Asset'">
            <div>Fee: <b>{{ fundsFee | money }}</b></div>
            <div v-if="newTransFlag">Total withdrawal <b>{{ total | money }}</b></div>
          </div>
          <div class="sum" v-else>
            <div>Fee: <b>{{ assetsFee | money }}</b></div>
            <div v-if="newTransFlag">Total withdrawal: <b>{{ totalAssets | unit_asset_balance }}</b></div>
          </div>
          <template v-if="template.method == 'Asset'">
            <div class="content-form content-form-modern">
            <div class="form-row form-split" v-if="template.sendingTo == 'AccountTransfer'">
              <form-field-select v-model="template.toAccountId" label="Select Linked Account" :validator="$v.template.toAccountId" :options="linkedAccountOptions" required :disabled="!updateTemplateFlag"/>
              <div class="error-message" v-if="errorWallet"><span>This account does not have an activated wallet for the asset type chosen. Please check information and try again.</span></div>
            </div>
            <template v-else>
              <div class="form-row form-split">
                <form-field-text v-model="template.payeeToName" label="Pay to Name" :disabled="!updateTemplateFlag" required />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="template.payeeToWalletAddress" label="Pay to Wallet Address" :disabled="!updateTemplateFlag" :validator="$v.template.payeeToWalletAddress" required />
              </div>
              <div class="form-row form-max">
                <form-field-text v-model="template.tag" label="Destination Tag" :disabled="!updateTemplateFlag" :validator="$v.template.tag" required />
              </div>
            </template>
            </div>
          </template>

          <international-wire-form v-if="showInternationalWireForm" v-model="template" :v="$v.template" :disabledField="!updateTemplateFlag" :transactionCountries="transactionCountries" />

          <ach-form v-if="showACHForm" v-model="template" :v="$v.template" :disabledField="!updateTemplateFlag" />

          <div v-if="showPayee" class="content-form content-form-modern">
            <h3 class="subtitle">PAYEE INFORMATION</h3>
            <div class="form-row form-split">
              <form-field-text v-model="template.payee" label="Payee" required :disabled="!updateTemplateFlag" :validator="$v.template.payee"/>
            </div>
          </div>

          <template v-if="showAddressForm">
            <h3 class="subtitle">{{ template.method === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
            <address-form v-if="template.method !== 'domestic_wire'" v-model="template.address" :v="$v.template.address" :disabledField="!updateTemplateFlag" :transactionCountries="transactionCountries" />
            <address-dom-form v-else v-model="template.address" :v="$v.template.address" :disabledField="!updateTemplateFlag" :transactionCountries="transactionCountries" />
          </template>

          <div class="content-form content-form-modern">
            <h3 v-if="showA2AAccountNumber" class="subtitle">ACCOUNT INFORMATION</h3>
            <div v-if="showA2AAccountNumber" class="form-row form-split acc-to-acc">
              <form-field-select v-model="template.toAccountId" label="Select Linked Account" :validator="$v.template.toAccountId" :options="linkedAccountOptions" required  disabled="true"/>
            </div>
            <div class="form-row form-split">
              <h3 class="subtitle">EXTRA INFORMATION</h3>
            </div>
            <div class="form-row form-split template-name">
              <form-field-text v-if="newTransFlag" v-model="template.memo" label="Memo" :maxlength="50" required />
              <form-field-text v-else v-model="template.memo" label="Memo" :maxlength="50" required :disabled="!updateTemplateFlag" />
            </div>
            <div class="form-row form-max">
              <form-field-text v-if="newTransFlag" v-model="template.reference" label="Reference" :maxlength="60" required :validator="$v.template.reference" />
              <form-field-text v-else v-model="template.reference" label="Reference" :maxlength="60" required :disabled="!updateTemplateFlag" :validator="$v.template.reference"/>
            </div>
          </div>
          <div class="">
            <template v-if="newTransFlag">
              <button class="main" @click="withdraw()">Submit</button>
            </template>
            <template v-else>
              <button v-if="!updateTemplateFlag" class="main" @click="updateTemplateFlag = true">Update</button>
              <button v-else class="main" @click="updateTemplate()">Save</button>
            </template>
            <button class="second" @click="open = false">Close</button>
          </div>
        </div>
        <div class="scrollable" v-else>
          <div class="content-form content-form-modern" v-if="template.method != 'Asset'">
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="transaction-information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="this.template.method == 'domestic_wire' || this.template.method == 'international_wires'" class="margin-10">Please see below for the details submitted for your withdrawal.</p>
              <template v-if="this.template.method == 'international_wires'">
                <p v-if="transaction"><b>Bank Name</b>: {{ this.template.bankName }}</p>
                <p v-if="transaction"><b>Account Name</b>: {{ this.template.accountName }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ this.template.accountNumber }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.template.accountType }}</p>
                <p v-if="transaction"><b>Bank Swift Code</b>: {{ this.template.swift }}</p>
                <p v-if="transaction"><b>Intermediary Bank Name</b>: {{ this.template.intermediaryBankName }}</p>
                <p v-if="transaction"><b>Intermediary Bank Reference</b>: {{ this.template.intermediaryBankReference }}</p>
                <p v-if="transaction"><b>Intermediary Bank Routing Number</b>: {{ this.template.intermediaryBankRoutingNumber }}</p>
                <p v-if="transaction"><b>Intermediary Bank SWIFT Code</b>: {{ this.template.intermediaryBankSwiftCode }}</p>
                <p v-if="transaction"><b>INTERMEDIARY BANK ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.template.intermediaryAddress.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.template.intermediaryAddress.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.template.intermediaryAddress.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.template.intermediaryAddress.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.template.intermediaryAddress.postal }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>IBAN</b>: {{ this.template.iban }}</p>
                <p v-if="transaction"><b>Country</b>: {{ this.template.address.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.template.address.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.template.address.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.template.address.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.template.address.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.template.futherCreditAccName }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.template.futherCreditAccNumber }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ this.template.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ this.template.memo }}</p>
              </template>
              <template v-else-if="this.template.method == 'checks'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.template.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Payee</b>: {{ this.template.payee }}</p>
                <br/>
                <p v-if="transaction"><b>PAYEE ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.template.address.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.template.address.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.template.address.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.template.address.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.template.address.postal }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              </template>
              <template v-else-if="this.template.method == 'ach'">
                <p v-if="transaction"><b>Amount</b>: ${{ this.template.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Name of Bank Account</b>: {{ this.template.bankName }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ this.template.accountType }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ this.template.routingNumber }}</p>
                <p v-if="transaction"><b>Bank Account Number</b>: {{ this.template.accountNumber }}</p>
                <p v-if="transaction"><b>Bank Account Type</b>: {{ this.template.bankAccountType }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              </template>
              <template v-else-if="this.template.method == 'domestic_wire'">
                <p v-if="transaction"><b>Account Name</b>: {{ transaction.accountName }}</p>
                <p v-if="transaction"><b>Routing Number</b>: {{ transaction.routingNumber }}</p>
                <p v-if="transaction"><b>Account Number</b>: {{ transaction.accountNumber }}</p>
                <p v-if="transaction"><b>Account Type</b>: {{ transaction.accountType }}</p>
                <br/>
                <p v-if="transaction"><b>BENEFICIARY ADDRESS</b></p>
                <p v-if="transaction"><b>Country</b>: {{ this.template.address.country }}</p>
                <p v-if="transaction"><b>Province/State</b>: {{ this.template.address.state }}</p>
                <p v-if="transaction"><b>Address</b>: {{ this.template.address.address }}</p>
                <p v-if="transaction"><b>City</b>: {{ this.template.address.city }}</p>
                <p v-if="transaction"><b>Postal</b>: {{ this.template.address.postal }}</p>
                <br/>
                <p v-if="transaction"><b>ADDITIONAL INFORMATION</b></p>
                <p v-if="transaction"><b>Further Credit Account Name</b>: {{ this.template.futherCreditAccName }}</p>
                <p v-if="transaction"><b>Further Credit Account Number</b>: {{ this.template.futherCreditAccNumber }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              </template>
              <template v-else>
                <p v-if="transaction"><b>Amount</b>: ${{ this.template.amount | formattedTwoDecimal }}</p>
                <p v-if="transaction"><b>Account Number</b>:  ****{{ this.template.toAccountId.substr(4, 3) }}</p>
                <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
                <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              </template>
              <p class="margin-10"><b>Note</b>: If you have any doubts or questions please verify your instructions with your account representative. </p>
            </div>
          </div>
          <div class="content-form content-form-modern" v-else>
            <div class="form-row">
              <p v-if="transaction" class="transaction-status">Status: {{ transaction.status }}</p>
            </div>
            <div class="transaction-information">
              <p v-if="transaction" class="transaction-status margin-10">{{ withdrawOptionsText }} Withdrawal Information</p>
              <p v-if="transaction"><b>Amount</b>: {{ transaction.totalAssetAmount | assets_balance }}</p>
              <p v-if="transaction"><b>Account Number</b>: ****{{ transaction.toAccountId? transaction.toAccountId.substr(4, 3) : ''}}</p>
              <p v-if="transaction">{{ transaction.outgoingWalletAddress }}</p>
              <p v-if="transaction"><b>Reference</b>: {{ transaction.reference }}</p>
              <p v-if="transaction"><b>Memo</b>: {{ transaction.memo }}</p>
              <p class="margin-10"><b>Note</b>: Send only {{ getCurrencyName(template.currencyId) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
            </div>
          </div>
          <div class="buttons">
            <button class="second" @click="open = false">Close</button>
          </div>
        </div>
      </div>
    </div>
    <add-template-panel
      v-if="modalType"
      :modal-type="modalType"
      @close="modalType = null" />
    <templates-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { monbiAccountNumber, assetsAmount, alphaNum, routingNumber, swift, alphaNumNoSpace} from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import MnbSelect from '../../components/ui/forms/Select'
import VueEnglishdatepicker from 'vue-englishdatepicker';
import AchForm from './templatePanels/ACH'
import AddressForm from './templatePanels/Address'
import AddressDomForm from './templatePanels/AddressDom'
import InternationalWireForm from './templatePanels/InternationalWire'
import DateTimePicker from 'vue-vanilla-datetime-picker';
import AddTemplateModal from './AddTemplateModal.vue'
import AddTemplatePanel from './AddTemplatePanel.vue'
import TemplatesFilters from './filters/TemplatesFilters';
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'Templates',

  components: {
    MnbSelect,
    VueEnglishdatepicker,
    AchForm,
    AddressForm,
    AddressDomForm,
    InternationalWireForm,
    DateTimePicker,
    AddTemplateModal,
    AppliedFilters,
    TemplatesFilters,
    AddTemplatePanel,
    InternationalWireForm
  },

  data () {
    return {
      openedFilters: false,
      filters: null,
      
      loading: false,
      open: false,
      template: false,
      updateTemplateFlag: false,
      currencyOptions: window.currencyOptions,
      invalidToProvider: false,
      newTransFlag: false,
      invalidBalance: false,
      modalType: null,
      assetsFee: 0,
      fundsFee: 0,
      legacyFee: 0,
      feePercent: 0,
      invalidAccountNumber: false,
      errorWallet: false,

      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      customEnv: appData.customEnv,

      page: 1,
      perPage: 25,
      pages: [],
      totalNumber: 0,
      linkedAccounts: null,
      addConfirmModal: false,
      transaction: null,
      activeInfo: true,
      sortName: null,
      sortFlag: true,
      toRegistrations: null,
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        account: 'Account',
        templateName: 'Template Name',
        method: 'Payment Method'
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Added: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    templates () {
      let data =  this.$store.state.templates.data || []
      this.totalNumber = this.$store.state.templates.count || 0
      return data
    },

    filteredData () {
      return this.templates;
    },

    transactionTypeText(){
      return {
        checks: 'Check',
        ach: 'ACH',
        domestic_wire: 'Domestic Wire',
        international_wires: 'International Wire',
        acc_to_acc: 'Account Transfer',
        Asset: 'Asset'
      }
    },

    typeOptions () {
      const options = [
        {
          id: 'Deposit',
          text: 'Deposit',
        },
        {
          id: 'Withdrawal',
          text: 'Withdrawal',
        }
      ]
      return options
    },

    depositOptions () {
      const options = [
        {
          id: 'ach',
          text: 'ACH',
        },
        {
          id: 'checks',
            text: 'Check',
        },
        {
          id: 'domestic_wire',
          text: 'Domestic Wire',
        },
        {
          id: 'international_wires',
          text: 'International Wire',
        },
        {
          id: 'acc_to_acc',
          text: 'Account to Account',
        }
      ]
      return options
    },

    templateMethod () {
      const options = {
        'ach': 'ACH',
        'checks': 'Check',
        'domestic_wire': 'Domestic Wire',
        'international_wires': 'International Wire',
        'acc_to_acc': 'Account to Account',
      }
      return options[this.template.method]
    },

    templateAccount () {
      return `${this.template.account}-${this.template.profileName}`
    },

    total () {
      if(this.template.amount) {
        return parseFloat(parseFloat(this.template.amount) + parseFloat(this.fundsFee)).toFixed(2) || 0
      }else{
        return parseFloat(this.fundsFee).toFixed(2) || 0
      }
    },

    totalAssets () {
      return (parseFloat(this.template.assetsAmount)) || 0
    },

    withdrawalLimit () {
      if(this.template.currency == 'USD') {
        return this.selectedAccount?.systemAvailableBalance
      } else {
        if(this.selectedAccount){
            if (this.selectedAccount.fundsCurrencies) {
              let fundCurrency = this.selectedAccount.fundsCurrencies.find(item => item.currency.currencyNickname == this.template.currency)
              if(fundCurrency){
                return parseFloat(fundCurrency.systemAvailableBalance)
              }
            }
          }
      }
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.template.accountId))
      return account
    },

    accountOptions () {
      let registrations = (this.isCustomer ? [this.reg] : this.$store.state.registrations.data) || []
      let accounts = []

      registrations.forEach(reg => {
        if (reg.accounts?.length) {
          reg.accounts.forEach(account => {
            accounts.push({
              id: account.id,
              regId: reg.id,
              status: account.accountStatus,
              text: `${account.accountNumber}-${reg.name}`,
              accountNumber: account.accountNumber,
              provider: account.provider,
              serviceProviders: account.serviceProviders,
              systemAvailableBalance: account.systemAvailableBalance,
              fundsCurrencies: account.fundsCurrencies,
              assetsWallets: account.assetsWallets,
            })
          })
        }
      })

      if (this.isCustomer) {
        accounts = accounts.filter(account => ['Open'].includes(account.status))
      } else {
        const categories = {}
        const availableStatuses = ['Open', 'Restricted', 'Suspended', 'Dormant']
        availableStatuses.forEach(status => {
          const accs = accounts.filter(account => account.status === status)
          if (accs.length) categories[status] = accs
        })
        return categories
      }

      return accounts
    },

    showInternationalWireForm () {
      return this.template.method === 'international_wires'
    },

    showACHForm () {
      return this.template.method === 'ach' || this.template.method === 'domestic_wire'
    },

    showFurtherCreditAccount () {
      return ['domestic_wire', 'international_wires'].includes(this.template.method)
    },

    showPayee () {
      return this.template.method === 'checks'
    },

    showAddressForm () {
      return ['domestic_wire', 'checks'].includes(this.template.method)
    },

    showA2AAccountNumber () {
      return this.template.method === 'acc_to_acc'
    },

    sendingToOptions () {
      const options = [
        {
          id: 'WalletAddress',
          text: 'Wallet Address',
        },
        {
          id: 'AccountTransfer',
          text: 'Account Transfer',
        }
      ]
      return options
    },

    linkedAccountOptions () {
      let accounts = []
      this.linkedAccounts?.forEach(account => {
        accounts.push({
          id: account.accountNumber,
          text: account.text,
        })
      })

      let corpAccounts = []
      if(this.toRegistrations.length > 0){
        let toAccounts = this.toRegistrations[0].accounts
        corpAccounts = toAccounts.filter(acc => (acc.linked == true || acc.solidLinked == true)).map(acc => ({
          id: acc.accountNumber,
          text: ` ****${acc.accountNumber.substr(-3)}-${acc.accountName}`,
        }))
      }
      corpAccounts.forEach(acc => {
        accounts.push(acc)
      })
      return accounts
    },

    withdrawOptionsText () {
      if(this.template.deposit_type == "checks")
        return "Check"
      if(this.template.deposit_type == "domestic_wire")
        return "Domestic Wire"
      if(this.template.deposit_type == "international_wires")
        return "International Wire"
      if(this.template.deposit_type == "ach")
        return "ACH"
      return
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    transactionCountries() {
      return this.selectedAccount?.serviceProviders?.transactionCountries
    },
  },

  async created () {
    this.$store.commit('system/setSingle', window.appData.system)
    await this.refreshTemplates();
    if (this.isCustomer) await this.$store.dispatch('registrations/get', this.currentUser.regId)
    else await this.$store.dispatch('registrations/load', {params: { corporate: ['!=', 1] }, skip: ['user', 'contact', 'data', 'fees', 'terms', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents', 'profileNumber', 'partnerId', 'currency', 'matched', 'verified', 'boardingApproval', 'createdAt', 'updatedAt', 'approvalDate', 'browser', 'browser_lang', 'os', 'device', 'documentId', 'serviceProvidersId', 'termId', 'confirmId', 'createdBy', 'partner', 'result', 'file', 'cipStatus', 'amlStatus', 'agreementId', 'kycStatus', 'confirm', 'exception', 'providerAccountNumber', 'providerContingentHold', 'providerNonContingentHold', 'providerPendingTransfer', 'linked', 'solidLinked', 'response', 'providerCreatedAt', 'primeAccount', 'solidAccount', 'cipMessage', 'cipMessageDetail', 'amlMessage', 'amlMessageDetail', 'kycMessage', 'kycMessageDetail', 'reviewDetail', 'QPaymentReference', 'statusUpdatedAt', 'accountKey', 'contactFundsTransferReferencesId', 'personId', 'businessId', 'kybStatus', 'idvStatus', 'phoneVerified', 'emailVerified', 'kycResults', 'kybResults', 'idvResults', 'documentResults', 'reviewCode', 'reviewMessage', 'metadata', 'programID', 'idv', 'disclosureStatus', 'members', 'membersKYCResults', 'membersIDVResults', 'membersDocumentResults', 'cards', 'owedFee', 'owedFeeLastUpdate', 'agreementResponse', 'webhookId', 'webhookResponse',  'partnerId', 'chargeOff', 'chargeOffBalance', 'accountPlan', 'feeSchedule', 'feePlan']})
    this.toRegistrations = (await api.list('Registration',{'corporate' : 1}, {}, {}, {}, ['user', 'contact', 'data', 'fees', 'terms', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents'])).items
  },

  methods: {
    getDateText () {
      const from = this.filters?.fromDate
      const to = this.filters?.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    getFilterValue (key, value) {
      if (key === 'method') return window.transactionTypes[value]

      return value
    },

    clearFilters () {
      this.filters = null
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    sortTemplateName(sortName) {
      if(this.sortName == sortName){
        this.sortFlag = !this.sortFlag
      }else {
        this.sortName = sortName
        this.sortFlag = true
      }
    },

    async openTemplateModal (item) {
      let template = (await this.refreshTemplate(item.id));
      this.template = template
      this.updateTemplateFlag = false
      this.newTransFlag = false
      this.addConfirmModal = false
      this.getAssetsFee()
      await this.getLinkedAccounts()
      this.open = true
    },

    async newTrans (item) {
      let template = (await this.refreshTemplate(item.id));
      this.template = template
      this.getAssetsFee()
      this.getFundsFee()
      this.newTransFlag = true
      this.updateTemplateFlag = false
      this.addConfirmModal = false
      await this.getLinkedAccounts()
      this.open = true
    },

    async refreshTemplate(id) {
      const loader = this.$loading.show()
      this.loading=true;
      const template = await this.$store.dispatch('templates/get', id)
      this.loading=false;
      loader.hide()
      return template
    },

    async refreshTemplates () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      if (this.isCustomer) {
        const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
        params.account=['OR']
        reg?.accounts.forEach(account => {
          params.account.push(account.accountNumber)
        })
      }
      if (this.filters && !!Object.values(this.filters).filter(val => val).length) {
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.type) {
          params.type = this.filters.type
        }

        if (this.filters.method) {
          params.method = this.filters.method
        }

        if (this.filters.templateID) {
          params.templateID = this.filters.templateID
        }

        if (this.filters.account) {
          params.account = this.filters.account
        }

        if (this.filters.templateName) {
          params.templateName = ['like', '%' + this.filters.templateName + '%'];
        }
      }

      let order = []
      if(this.sortName != null) {
        if(this.sortFlag) {
          order = [this.sortName + ':ASC']
        }else{
          order = [this.sortName + ':DESC']
        }
      }else{
        order = ['ID:DESC']
      }
      await this.$store.dispatch('templates/load', { order: order, params, limit: this.perPage, start: (this.page-1) * this.perPage })
      this.loading=false;
      loader.hide()
    },

    async updateTemplate () {
      this.$v.$touch()
      if (this.$v.template.$invalid) {
        return
      }
      const loader = this.$loading.show()
      this.loading=true;
      const payload = {
        id: this.template.id,
        payload: {
          account: this.template.account,
          template: this.template.type,
          templateName: this.template.templateName,
          method: this.template.method,
          bankName: this.template.bankName,
          accountType: this.template.accountType,
          hideBankAccountType: this.template.hideBankAccountType,
          routingNumber: this.template.routingNumber,
          accountNumber: this.template.accountNumber,
          bankAccountType: this.template.bankAccountType,
          payee: this.template.payee,
          address: this.template.address,
          useFurtherCreditAccount: this.template.useFurtherCreditAccount,
          futherCreditAccName: this.template.futherCreditAccName,
          futherCreditAccNumber: this.template.futherCreditAccNumber,
          toAccountId: this.template.toAccountId,
          memo: this.template.memo,
          accountName: this.template.accountName,
          useIntermediaryBank: this.template.useIntermediaryBank,
          intermediaryBankName: this.template.intermediaryBankName,
          intermediaryBankReference: this.template.intermediaryBankReference,
          intermediaryBankIdentifier: this.template.intermediaryBankIdentifier,
          intermediaryBankRoutingNumber: this.template.intermediaryBankRoutingNumber,
          intermediaryBankSwiftCode: this.template.intermediaryBankSwiftCode,
          intermediaryAddress: this.template.intermediaryAddress,
          payeeToName: this.template.payeeToName,
          payeeToWalletAddress: this.template.payeeToWalletAddress,
          tag: this.template.tag,
          iban: this.template.iban,
          updatedAt: 'CURRENT_TIMESTAMP',
          reference: this.template.reference
        }
      }

      await this.$store.dispatch('templates/update', payload)
      this.$toast.success('Template successfully updated.')
      this.loading=false;
      this.open = false
      this.template = null
      this.updateTemplateFlag = false
      loader.hide()
    },

    async withdraw () {
      this.$v.$touch()
      if (this.$v.template.$invalid) {
        return
      }

      if (this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      }

      if (this.invalidAccountNumber) {
        return
      }

      if(this.template.method == 'Asset'){
        const loader = this.$loading.show()
        if(this.template.sendingTo == 'WalletAddress'){
          let payload;
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.assetsAmount,
            totalAmount: this.totalAssets,
            transactionFee: this.assetsFee,
            transactionType: 'Asset',
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyId: this.template.currencyId,
            label: this.template.payeeToName,
            outgoingWalletAddress: this.template.payeeToWalletAddress,
            tag: this.template.tag,
          }
          const response = (await api.action("Transactions", "new", "WithdrawAssets", payload))
          this.transaction = response.item
          loader.hide()
          this.newTransFlag = false
          this.updateTemplateFlag = false
          this.addConfirmModal = true
        }else{
          let payload;
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.assetsAmount,
            totalAmount: this.totalAssets,
            transactionFee: this.assetsFee,
            transactionType: 'Asset',
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyId: this.template.currencyId,
            toAccountId: this.template.toAccountId,
          }
          const response = (await api.action("Transactions", "new", "WithdrawAccountTransferAssets", payload))
          if(response.action.errorWallet){
            this.errorWallet = true
            loader.hide()
          } else{
            this.transaction = response.item
            loader.hide()
            this.newTransFlag = false
            this.updateTemplateFlag = false
            this.errorWallet = false
            this.addConfirmModal = true
          }
        }
      }else{
        if (this.withdrawalLimit < parseFloat(this.total)) {
          this.invalidBalance = true
          return
        }

        if(this.template.method == 'acc_to_acc') {
          let accounts = this.accountOptions
          if (!this.isCustomer) {
            accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
          }
          const fromProvider = (accounts.find(item => item.id === this.template.accountId))?.provider
          const toProvider = (accounts.find(item => item.accountNumber === this.template.toAccountId))?.provider
          if(toProvider){
            if(fromProvider != toProvider) {
              this.invalidToProvider = true
              return
            } else {
              this.invalidToProvider = false
            }
          }
        }

        const loader = this.$loading.show()
        let payload;
        if (this.template.method == 'international_wires') {
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.amount,
            totalAmount: this.total,
            transactionFee: this.fundsFee,
            transactionType: this.template.method,
            accountName: this.template.accountName,
            bankName: this.template.bankName,
            accountType: this.template.accountType,
            swift: this.template.swift,
            accountNumber: this.template.accountNumber,
            useIntermediaryBank: this.template.useIntermediaryBank,
            intermediaryBankName: this.template.intermediaryBankName,
            intermediaryBankReference: this.template.intermediaryBankReference,
            intermediaryBankSwiftCode: this.template.intermediaryBankSwiftCode,
            intermediaryBankRoutingNumber: this.template.intermediaryBankRoutingNumber,
            intermediaryAddress:this.template.intermediaryAddress,
            bankAddress:this.template.bankAddress,
            bank_country: this.template.bankAddress?.country,
            bank_address: this.template.bankAddress?.address,
            bank_city: this.template.bankAddress?.city,
            bank_state: this.template.bankAddress?.state,
            bank_postal: this.template.bankAddress?.postal,
            iban:this.template.iban,
            country: this.template.address.country,
            state: this.template.address.state,
            address: this.template.address.address,
            address2: this.template.address.address2,
            city: this.template.address.city,
            postal: this.template.address.postal,
            reference: this.template.reference,
            memo: this.template.memo,
            useFurtherCreditAccount: this.template.useFurtherCreditAccount,
            futherCreditAccName: this.template.futherCreditAccName,
            futherCreditAccNumber: this.template.futherCreditAccNumber,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyName: this.template.currency
          }
        }else if(this.template.method == 'checks') {
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.amount,
            totalAmount: this.total,
            transactionFee: this.fundsFee,
            transactionType: this.template.method,
            payee: this.template.payee,
            country: this.template.address.country,
            state: this.template.address.state,
            address: this.template.address.address,
            city: this.template.address.city,
            postal: this.template.address.postal,
            reference: this.template.reference,
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyName: this.template.currency
          }
        }else if(this.template.method == 'ach') {
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.amount,
            totalAmount: this.total,
            transactionFee: this.fundsFee,
            transactionType: this.template.method,
            routingNumber: this.template.routingNumber,
            bankName: this.template.bankName,
            accountType: this.template.accountType,
            bankAccountType: 'checking',
            accountNumber: this.template.accountNumber,
            accountName: this.template.accountName,
            reference: this.template.reference,
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyName: this.template.currency
          }
        } else if(this.template.method == 'domestic_wire') {
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.amount,
            totalAmount: this.total,
            transactionFee: this.fundsFee,
            transactionType: this.template.method,
            accountName: this.template.accountName,
            accountType: this.template.accountType,
            routingNumber: this.template.routingNumber,
            accountNumber: this.template.accountNumber,
            bankName: this.template.bankName,
            bankAccountType: this.template.bankAccountType,
            country: this.template.address.country,
            state: this.template.address.state,
            address: this.template.address.address,
            address2: this.template.address.address2,
            city: this.template.address.city,
            postal: this.template.address.postal,
            bank_country: this.template.bankAddress?.country,
            bank_address: this.template.bankAddress?.address,
            bank_city: this.template.bankAddress?.city,
            bank_state: this.template.bankAddress?.state,
            bank_postal: this.template.bankAddress?.postal,
            reference: this.template.reference,
            useFurtherCreditAccount: this.template.useFurtherCreditAccount,
            futherCreditAccName: this.template.futherCreditAccName,
            futherCreditAccNumber: this.template.futherCreditAccNumber,
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyName: this.template.currency
          }
        } else {
          payload = {
            profileId: this.template.accountId,
            createdAt: 'CURRENT_TIMESTAMP',
            amount: this.template.amount,
            totalAmount: this.total,
            transactionFee: this.fundsFee,
            transactionType: this.template.method,
            toAccountId: this.template.toAccountId,
            reference: this.template.reference,
            memo: this.template.memo,
            completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
            currencyName: this.template.currency
          }
        }
        const response = (await api.action("Transactions", "new", "Withdraw", payload))
        if(response.action.error){
          this.invalidAccountNumber = true
          loader.hide()
        } else{
          this.transaction = response.item
          loader.hide()
          this.newTransFlag = false
          this.updateTemplateFlag = false
          this.addConfirmModal = true
        }
      }
    },

    async removeTemplate(item) {
      if(confirm(`Are you sure that you want to delete this template? This cannot be reversed.`)) {
        const loader = this.$loading.show()
        try {
          this.$store.dispatch('templates/delete', item.id)
          await this.refreshTemplates();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    openModal (type) {
      this.modalType = type
    },

    async getAssetsFee () {
      const payload = {
        profileId: this.template.accountId,
        transactionType: 'Assets',
        entryType: 'Withdrawal'
      }
      this.assetsFee = (await api.action("Transactions", "new", "GetFee", payload)).action.fee
    },

    async getLinkedAccounts () {
      const payload = {
        accountId: this.template.accountId,
      }
      this.linkedAccounts = (await api.action("LinkedAccount", "new", "GetLinkedAccounts", payload)).action.item
    },

    async getFundsFee () {
      const payload = {
        profileId: this.template.accountId,
        transactionType: this.template.method,
        entryType: 'Withdrawal'
      }
      let action = (await api.action("Transactions", "new", "GetFee", payload)).action
      this.legacyFee = action.fee
      this.fundsFee = action.fee
      this.feePercent = action.feePercent
    },

    setPages () {
      let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    getCurrencyName (currencyId) {
      if(this.template.currencyId) {
        let currencies = window.currencies || []
        currencies = currencies.filter(currency => {
          return currency.id === currencyId
        })
        return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
      }
      return null
    },
  },

  watch: {
    appliedFilters () {
      this.refreshTemplates();
    },

    sortName () {
      this.refreshTemplates();
    },

    sortFlag () {
      this.refreshTemplates();
    },

    templates () {
      this.setPages();
    },

    page() {
      this.refreshTemplates();
    },

    perPage() {
      if(this.page == 1){
        this.refreshTemplates();
      }else{
        this.page = 1
      }
    },

    'template.amount' () {
      if(this.template.deposit_type != 'acc_to_acc') {
          if(this.template.amount) {
            this.fundsFee = parseFloat(parseFloat(this.legacyFee) + parseFloat(this.template.amount) * this.feePercent).toFixed(2)
          }else{
            this.fundsFee = parseFloat(this.legacyFee).toFixed(2)
          }
        }else{
          this.fundsFee = parseFloat(this.legacyFee).toFixed(2)
        }

      if (this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        return
      } else {
        this.invalidBalance = false
        return
      }
    },
  },

  validations () {
    let rules = {
      template: {},
    }
    if(this.template.method != 'Asset'){
      rules.template.reference = { minLength: minLength(3), maxLength: maxLength(60) }
      if(this.newTransFlag) {
        rules.template.amount = { required, decimal }
      }
      if (this.showPayee) {
        rules.template.payee = { required, alphaNum }
      }
      if (this.showAddressForm || this.showInternationalWireForm) {
        rules.template.address = {
          country : {required},
          city : {required},
          state : {required},
          postal : {required, alphaNum},
          address : {required},
        }
      }

      if (this.showAddressForm) { 
        rules.template.address.address = { required, maxLength: maxLength(35) }
        rules.template.address.address2 = { maxLength: maxLength(35) }
      }

      if (this.showInternationalWireForm) {
        rules.template.address.address = { required, maxLength: maxLength(60) }
        rules.template.address.address2 = { maxLength: maxLength(60) }
      }
      
      if (this.showACHForm) {
        rules.template.accountName = { required }
        rules.template.accountType = { required }
        rules.template.routingNumber = { required, minLength: minLength(9), maxLength: maxLength(9) }
        rules.template.accountNumber = { required, numeric, minLength: minLength(4), maxLength: maxLength(17) }
      }

      if (this.showInternationalWireForm) {
        rules.template.accountName = { required }
        rules.template.accountType = { required }
        rules.template.bankName = { required }
        rules.template.intermediaryBankIdentifier = { required }
        rules.template.accountNumber = { required, alphaNumNoSpace, minLength: minLength(4), maxLength: maxLength(34) }
        rules.template.swift = { required, swift }

        if (this.template.useIntermediaryBank) {
          if(this.template.intermediaryBankIdentifier == 'routing') {
            rules.template.intermediaryBankRoutingNumber = { required, minLength: minLength(9), maxLength: maxLength(9) }
          }else{
            rules.template.intermediaryBankSwiftCode = { required, swift }
          }

          rules.template.intermediaryBankName = { required }

          rules.template.intermediaryAddress = {
            country: { required },
            city: { required },
            state: { required },
            postal: { required, alphaNum },
            address: { required }
          }
        }
      }
      if (this.showA2AAccountNumber) {
        rules.template.toAccountId = { required }
      }
    }else{
      if(this.newTransFlag) {
        rules.template.assetsAmount = { required, decimal }
      }
      if(this.updateTemplateFlag) {
        if (this.template.sendingTo == 'WalletAddress') {
          rules.template.payeeToWalletAddress = {required}
        }else{
          rules.template.toAccountId = { required }
        }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  tr {
    td {
      padding: 5px;
    }
  }
}
.buttons {
  margin-top: 30px;
}

.information {
  display: flex;
  justify-content: space-between;

  div {
    width: 50%; 
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  .optionBtns .main {
    margin-left: 15px; 
  }
}
.form-max {
  div {
    width: 100%;
  }
}
button.cancel {
  background: #666;
  color: white;
  min-width: 120px;
}
button.approve {
  background: #67c23a;
  color: white;
  min-width: 120px;
}
.btn-approve {
  background: #67c23a ;
  color: white;
}

.form-max {
  div {
    width: 100%;
  }
}

.transaction-information p {
  margin: 5px;
}

.content-tlist {
  border: none;
  background: none; 
}
</style>
