<template>
<div class="content-form vgv-form">
  <div v-if="!vgvScriptLoaded">
    Loading, please wait...
  </div>
  <slot v-if="vgvScriptLoaded" />
</div>
</template>
<script>
export default {
  props: [
    "vaultId", "environment", "cardToken", "cardId", "orgId",
  ],
  data: function() {
    return {
      vgvScriptLoaded: false,
    };
  },
  methods: {
  
    addVgsShowScript() {
      var vgvScript = document.createElement('script');
      vgvScript.src = 'https://js.verygoodvault.com/vgs-show/1.3/'+this.orgId+'.js';
      vgvScript.type = 'text/javascript';
      vgvScript.onload = ()=>{this.onVgvLoaded()};
      document.head.append(vgvScript);
    },
    onVgvLoaded() {
      this.vgvScriptLoaded=true;
      this.vgvShow=window.VGSShow.create(this.vaultId);
      this.vgvHeaders={'sd-show-token': this.cardToken};
    },
  },
  created() {
    this.addVgsShowScript();
  },
}
</script>
