<template>
  <div>
    <section v-if="mode=='Overview'">
      <div class="flex-between">
        <h2 class="inner-headers"></h2>

        <div style="padding: 0px 30px 10px 10px;">
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <button v-if="mode=='Overview'" class="btn btn-success" @click="mode = 'New'" style="margin-top:-70px"><i class="el-icon-plus" /> New Partner</button>
    
    <applied-filters v-if="mode=='Overview'" :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <div class="accordion" v-if="mode=='Overview'">
      <div :class="{'cof-box-active' : activeInfo}">
        <section  class="content-tlist">
          <table class="content-table">
            <tr>
              <th>Date</th>
              <th>Number</th>
              <th>Type</th>
              <th>Name</th>
              <th>Status</th>
              <th style="width: 15%;">Options</th>
            </tr>
            <tr v-for="partner in filteredData" :key="partner.id">
              <td><span>Date</span>
                {{ partner.createdAt | date('MM/D/YYYY hh:mm A') }}
              </td>
              <td><span>Number</span>
                <a :href="`/system-partners/profiles/${partner.id}`">{{ partner.partnerNumber }}</a>
              </td>
              <td><span>Type</span>
                {{ partner.type }}
              </td>
              <td><span>Name</span>
                {{ partner.business.legal_name }}
              </td>
              <td><span>Status</span>
                <em :class="statusColorClasses[partner.status]">{{ partner.status }}</em>
              </td>
              <td><span>Options</span>
                <button v-if="$hasPerm('partners-approve') && partner.status == 'New'" class="action-button remove" @click="deny(partner.id)">Deny</button>
                <button v-if="$hasPerm('partners-approve') && partner.status == 'Denied'" class="action-button cancel" @click="archive(partner.id)">Archive</button>
              </td>
            </tr>
            <tr v-if="!partners.length">
              <td colspan="6" class="no-data">No data</td>
            </tr>
          </table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>
    <RegisterPartner :internal="true" v-else-if="mode=='New'" />
    <partners-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';
  import PartnersFilters from './filters/PartnersFilters'
  import AppliedFilters from '../../components/common/AppliedFilters'

  export default {
    name: 'Partners',

    components: {
      VueEnglishdatepicker,
      DateTimePicker,
      AppliedFilters,
      PartnersFilters
    },

    data: () => ({
      openedFilters: false,
      filters: null,

      activeInfo: true,
      mode: "Overview",

      loading: false,
      page: 1,
      perPage: 25,
      pages: [],
    }),

    computed: {
      appliedFilters () {
        const filterNames = {
          partnerNumber: 'Partner Number',
          name: 'Name',
          type: 'Profile Type',
          status: 'Status'
        }
        
        if (!this.filters) return []

        let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
        const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
        appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
          key,
          text: `${filterNames[key]}: ${this.filters[key]}`
        }))

        if (hasDateFilters) {
          appliedFilters.push({
            key: 'date',
            text: `Date Range: ${this.getDateText()}`
          })
        }

        return appliedFilters
      },
      customerStatuses () {
        return window.customerStatuses
      },

      totalNumber() {
        return this.partners.length
      },

      partners () {
        let partners = this.$store.state.partners.data || []

        if (!this.filters || !Object.values(this.filters).filter(val => val).length) return partners

        if (this.filters.name) {
          partners = partners.filter(partner => {
            return partner.business.legal_name.toLowerCase().includes(this.filters.name.toLowerCase())
          })
        }

        if (this.filters.fromDate) {
          const from = moment(this.filters.fromDate, 'YYYY-MM-DD')
          partners = partners.filter(partner => {
            const date = moment(partner.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date >= from
          })
        }

        if (this.filters.toDate) {
          const to = moment(this.filters.toDate, 'YYYY-MM-DD')
          partners = partners.filter(partner => {
            const date = moment(partner.createdAt.split(' ')[0], 'YYYY-MM-DD')
            return date <= to
          })
        }

        if (this.filters.uuid) {
          partners = partners.filter(partner => parseInt(partner.uuid) === parseInt(this.filters.uuid))
        }

        if (this.filters.partnerNumber) {
          partners = partners.filter(partner => {
            return partner.partnerNumber.includes(this.filters.partnerNumber)
          })
        }

        if (this.filters.type) {
          partners = partners.filter(partner => partner.type === this.filters.type)
        }

        return partners
      },

      statusColorClasses () {
        return {
          New: 'c1',
          Review: 'c2',
          Pending: 'c2',
          Restricted: 'c2',
          'In Process': 'c2',
          Denied: 'c4',
          Closed: 'c4',
          Archived: 'c3',
          Complete: 'c3',
          Active: 'c1'
        }
      },

      statusColorText () {
        return {
          approved: 'Approved',
          inReview: 'In Review',
          declined: 'Declined',
          active: 'Active',
          submitted: 'Submitted',
        }
      },

      filteredData () {
        return this.paginate(this.partners);
      }
    },

    async created () {
      await this.refreshPartners()
    },

    methods: {
      getDateText () {
        const from = this.filters.fromDate
        const to = this.filters.toDate

        let text = ''
        if (from) text = `From ${moment(from).format('LL')} `
        if (to) text += `To ${moment(to).format('LL')}`
        return text
      },

      clearFilters () {
        this.filters = null
      },

      removeFilter (filter) {
        if (filter.key === 'date') {
          this.$delete(this.filters, 'fromDate')
          this.$delete(this.filters, 'toDate')
          return;
        }

        this.$delete(this.filters, filter.key)
      },

      applyFilters (filters) {
        this.filters = filters
        this.openedFilters = false
      },

      oneMonthBefore (reg){
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 30)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        const now = moment(yesterdayDate, "YYYY-MM-DD")

        const date = moment(reg.createdAt.split(' ')[0], 'YYYY-MM-DD')
        if(date >= now) {
          return false
        }else{
          return true
        }
      },

      async setNew (id) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: { status: 'New' }
        })
        await this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: id,
          notes: 'Registration Status Updated: New',
          createdAt: 'CURRENT_TIMESTAMP'
        })
        loader.hide()
      },

      async deny (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('partners/update', {
            id,
            payload: {
              status: 'Denied',
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('partner_notes/create', {
            createdBy: window.appData.currentUser.id,
            partnerId: id,
            notes: 'Status updated to Denied',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          loader.hide()
        }
        
      },

      async archive (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('partners/update', {
            id,
            payload: {
              status: 'Archived',
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('partner_notes/create', {
            createdBy: window.appData.currentUser.id,
            partnerId: id,
            notes: 'Status updated to Archived',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          loader.hide()
        }
        
      },

      async unarchive (id) {
        const loader = this.$loading.show()
        await this.$store.dispatch('registrations/update', {
          id,
          payload: {
            status: 'Pending'
          }
        })
        loader.hide()
      },

      async reject (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('registrations/update', {
            id,
            payload: {
              status: 'Denied'
            }
          })
          await this.$store.dispatch('profilenotes/create', {
            createdBy: window.appData.currentUser.id,
            registrationId: id,
            notes: 'Registration Rejected',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          await this.refreshRegistrations()
          loader.hide()
        }
      },

      search () {
        this.filters = { ...this.form }
      },

      resetItem() {
        this.filters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async refreshPartners () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
          params.status=["OR", "New", "Pending", "Active"];
        } else {
          if (this.filters.status)
            params.status=this.filters.status;
        }
        await this.$store.dispatch('partners/load', {params}, )
        this.loading=false;
        this.page = 1
        loader.hide()
      },

      setPages () {
        let numberOfPages = Math.ceil(this.totalNumber / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },

      paginate (data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return data.slice(from, to);
      },
    },

    watch: {
      appliedFilters () {
        this.refreshPartners();
      },

      filteredData () {
        this.setPages();
      },

      page() {
        this.setPages();
      },

      partners () {
        this.setPages();
      },

      perPage() {
        if(this.page == 1){
          this.setPages();
        }else{
          this.page = 1
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>