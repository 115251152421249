<template>
  <div>
    <div class="form-row form-split">
      <form-field-text v-model="form.firstName" :maxlength="25" :validator="v.firstName" label="First Name" required />
      <form-field-text v-model="form.lastName" :maxlength="25" :validator="v.lastName" label="Last Name" required />
    </div>
    <div class="form-row form-split">
      <form-field-text v-model="form.phone" :maxlength="20" :validator="v.phone" label="Phone Number" required />
      <form-field-text v-model="form.mobile" :maxlength="20" :validator="v.mobile" label="Mobile Number" required />
    </div>
    <div class="form-row form-fullw" style="position: relative;margin-bottom: 10px;">            
      <form-field-text v-model="form.email" :validator="v.email" label="Email Address" required />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: this.value,
      idTypes: ['Passport', 'Drivers License'],
      ssnModal: false,
      countryModal: false,
      prohibitedCountries: window.prohibitedCountries
    }
  },
  
  computed: {
    contactTypeOptions () {
      return {
        'Beneficial Owner': 'Beneficial Owner',
        'Authorized Person': 'Authorized Person'
      }
    },

    countryOptions () {
      return { ...window.contactCountriesOptions }
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country].reduce((acc, state) => {
        acc[state.id] = state.text
        return acc
      }, {})
    }
  },

  watch: {
    'form.country' (value) {
      this.form.state = null
    },

    value: {
      handler (value) {
        this.form = value
      },
      immediate: true,
      deep: true
    },

    form: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  methods: {
    issueIdTypes (current) {
      const used = this.form.id_types.map(type => type.id_type).filter(id => id)
      return this.idTypes.filter(key => current === key || !used.includes(key)).reduce((acc, key) => {
        acc[key] = key
        return acc
      }, {})
    },

    addIssueType () {
      this.form.id_types.push({
        id_type: null,
        id_number: null,
        document: null
      })
    },

    removeIssueTypeId () {
      this.form.id_types.splice(this.form.id_types.length - 1, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .what-this {
    position: absolute;
    right: 10px;
    top: 54px;
    cursor: pointer;
    font-size: 13px;
  }
</style>