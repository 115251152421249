<template>
<input type="text" :value="value" v-on:input="update" autocomplete="off" :disabled="disabled" />
</template>

<script>
export default {
  inheritAttrs: false,
  props: ["value", "future", "disabled"],
  methods: {
    update($e) {
      this.$emit("input", $e.target.value);
    },
  },
  mounted() {
    var that=this;
    var opts={
      changeYear: true,
      changeMonth: true,
      yearRange: "c-80:+30",
      dateFormat: "yy-mm-dd",
      beforeShow(el, options) {
        var targetEl=options.dpDiv[0];
        if (targetEl) {
          setTimeout(function() {
            var elBounds=el.getBoundingClientRect();
            targetEl.style.top=elBounds.top*.66+window.scrollY+25+"px";
            targetEl.style.left=elBounds.left*.66+window.scrollX+5+"px";
          }, 50);
        }
      },
      onSelect(date, datepicker) {
        that.$emit("input", date);
      }
    };
    if (this.future===false)
      opts.maxDate=0;
    if (this.future===true)
      opts.minDate=0;
    
    for(var attr in this.$attrs)
      opts[attr]=this.$attrs[attr];
    $(this.$el).datepicker(opts);
  },
}

</script>

