<template>
   <div style="display: flex; justify-content: space-around;">
    <div v-show="open" style="width: 70%; border: none; padding: 30px" class="content-tlist">
      <div class="popup-header">
        <h3>New Deposit</h3>
      </div>
      <template v-if="!nextFlag">
        <div class="scrollable">
          <div class="content-form content-form-modern text-center">
            <div class="form-row form-split depositType">
              <form-field-select v-model="form.provider" label="Select Provider" :validator="$v.form.provider" :options="providerOptions" required />
            </div>
          </div>
          <div class="buttons">
            <button class="main" @click="moveNextPopup()">Next</button>
            <button class="second" @click="open = false">Cancel</button>
          </div>
        </div>
      </template>
      <template v-else>
          <div class="scrollable">
            <div class="content-form content-form-modern">
              <div class="form-row form-split">
                <form-field-select-search v-model="form.account" label="Account" :validator="$v.form.account" :options="accountOptions" :option-group="!isCustomer" required />
                <form-field-select v-model="form.depositMethod" :disabled="!selectedAccount" label="Deposit Method" :validator="$v.form.depositMethod" :options="depositOptions" required />
              </div>
              <div class="form-row form-split">
                <form-field-select  disabled v-model="form.currency" label="Currency" :validator="$v.form.currency" :options="currencyOptions" required />
                <form-field-text v-model="form.amount" label="Deposit Amount" :validator="$v.form.amount" required />
              </div>
              <div class="form-row form-fullw">
                <form-field-text v-model="form.senderName" label="Sender Name" :validator="$v.form.senderName" :maxlength="75" required />
              </div>
              <div class="form-row form-fullw">
                <form-field-textarea v-model="form.details" label="Details" :validator="$v.form.details" :rows="3" :maxlength="1000" />
              </div>
              <div class="form-row form-fullw">
                <form-field-text v-model="form.reference" label="Reference" :validator="$v.form.reference" :maxlength="75" required />
              </div>
              <h3 class="subtitle">EXTRA INFORMATION</h3>
              <div class="form-row form-split">
                <form-field-text v-model="form.providerTransactionNumber" label="Provider Transaction Number" :validator="$v.form.providerTransactionNumber"  :maxlength="50" required />
                <div class="cof-field">
                  Provider Created At
                  <div class="cof-multi flex">
                    <date-time-picker
                      v-model="form.providerCreatedAt"
                      value-type="String"
                      value-format="yyyy-LL-dd"
                      format="yyyy-LL-dd"
                      :time-picker="false"
                      :auto-close="true"
                      :max-date="today"
                    >
                    </date-time-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button class="main" @click="add()">Submit</button>
              <button class="second" @click="open = false">Cancel</button>
              <label v-if="submitError" class="error-message">Please check error(s) and try again.</label>
            </div>
          </div>
      </template>
    </div>
  </div>
</template>

<script>
import { monbiAccountNumber, assetsAmount, alphaNum, routingNumber, swift, alphaNumNoSpace, bankRoutingNumber } from '../../lib/validators'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'
import DateTimePicker from 'vue-vanilla-datetime-picker';

export default {
  props: {
    modalType: {
      type: String,
      required: true
    }
  },

  components: {
    DateTimePicker,
  },

  data () {
    return {
      form: {
        provider: null,
        account: null,
        depositMethod: null,
        currency: 'USD',
        amount: null,
        senderName: null,
        details: null,
        providerTransactionNumber: null,
        providerCreatedAt: null,
      },
      open: true,
      nextFlag: false,
      submitError: false,
      corps: [],
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
    }
  },

  computed: {
    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.connectionType == 'Manual' && provider.providerType == 'Accounts').map(provider => ({
        id: provider.id,
        text: provider.provider_name
      }))
    },

    accountOptions () {
      let storeAccounts = this.$store.state.accounts.data || []
      let accounts = []
      let corpAccounts = []
      if (storeAccounts?.length) {
        storeAccounts.forEach(account => {
          if(account.provider == this.form.provider){
            if(account.regCorp != 1) {
              if(account.accountStatus == 'Hold' || account.accountStatus == 'Dormant') {
                accounts.push({
                  id: account.id,
                  regId: account.regId,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${account.regName}`,
                  accountNumber: account.accountNumber,
                  provider: account.provider,
                  profileSettings: account.profileSettings,
                  fundsCurrencies: account.fundsCurrencies,
                  assetsWallets: account.assetsWallets,
                  name: account.accountName,
                  systemAvailableBalance: account.systemAvailableBalance,
                  disabled: true,
                  serviceProviders: account.serviceProviders,
                  corp: false,
                })
              }else{
                accounts.push({
                  id: account.id,
                  regId: account.regId,
                  status: account.accountStatus,
                  text: `${account.accountNumber}-${account.regName}`,
                  accountNumber: account.accountNumber,
                  profileSettings: account.profileSettings,
                  fundsCurrencies: account.fundsCurrencies,
                  assetsWallets: account.assetsWallets,
                  name: account.accountName,
                  systemAvailableBalance: account.systemAvailableBalance,
                  provider: account.provider,
                  serviceProviders: account.serviceProviders,
                  corp: false,
                })
              }
            }else{
              corpAccounts.push({
                id: account.id,
                regId: account.regId,
                status: account.accountStatus,
                text: `${account.accountNumber}-${account.regName}`,
                accountNumber: account.accountNumber,
                provider: account.provider,
                profileSettings: account.profileSettings,
                fundsCurrencies: account.fundsCurrencies,
                assetsWallets: account.assetsWallets,
                name: account.accountName,
                systemAvailableBalance: account.systemAvailableBalance,
                serviceProviders: account.serviceProviders,
                corp: true,
              })
            }
          }
        })
      }

      const categories = {}
      const availableStatuses = ['Open', 'Restricted', 'Suspended', 'Dormant', 'Hold']
      availableStatuses.forEach(status => {
        const accs = accounts.filter(account => account.status === status)
        if (accs.length) categories[status] = accs
      })
      
      categories['Corporate'] = corpAccounts
        return categories
    },

    selectedAccount () {
      let accounts = this.accountOptions
      if (!this.isCustomer) {
        accounts = Object.values(this.accountOptions).reduce((acc, arr) => acc.concat(arr), [])
      }
      const account = (accounts.find(item => item.id === this.form.account))
      return account
    },

    depositOptions () {
      if(!this.selectedAccount) return []
      if(this.selectedAccount.corp) {
        return [
          {
            id: 'ach',
            text: 'ACH',
          },
          {
            id: 'checks',
            text: 'Check',
          },
          {
            id: 'domestic_wire',
            text: 'Domestic Wire',
          },
          {
            id: 'international_wires',
            text: 'International Wire',
          }
        ]
      }else{
        const options = [
          {
            id: 'ach',
            text: 'ACH',
            if: this.selectedAccount?.profileSettings?.ach
          },
          {
            id: 'checks',
            text: 'Check',
            if: this.selectedAccount?.profileSettings?.checks
          },
          {
            id: 'domestic_wire',
            text: 'Domestic Wire',
            if: this.selectedAccount?.profileSettings?.domWires
          },
          {
            id: 'international_wires',
            text: 'International Wire',
            if: this.selectedAccount?.profileSettings?.intlWires
          }
        ]
        return options.filter(opt => opt.if)
      }
      
    },

    currencyOptions () {
      let options = []
      options.push({
        id: 'USD',
        text: 'USD',
      })
      return options
    },

    date2 () {
        let today = new Date()
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        var dd = String(yesterday.getDate()).padStart(2, '0');
        var mm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = yesterday.getFullYear();
        var yesterdayDate = yyyy + '-' + mm + '-' + dd;
        return yesterdayDate
      },
  },

  watch: {
    open (open) {
      if (!open) this.$emit('close')
    },
  },

  methods: {
    moveNextPopup () {
      if(this.form.provider) {
        this.nextFlag = true
      }
    },

    async add () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }
      this.submitError = false
      const loader = this.$loading.show()
      let payload;
      payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        amount: this.form.amount,
        transactionType: this.form.depositMethod,
        reference: this.form.reference,
        senderName: this.form.senderName,
        currencyName: this.form.currency,
        details: this.form.details,
        providerTransactionNumber: this.form.providerTransactionNumber,
        providerCreatedAt: this.form.providerCreatedAt,
        completedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
      }
      this.transaction = (await api.action("Transactions", "new", "DepositManual", payload)).item
      this.$toast.success('Transaction successfully created.')
      loader.hide()
      this.open = false
    },
  },

  async created () {
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  validations () {
    let rules = {}
      rules = {
        form: {
          provider: { required },
          account: { required },
          depositMethod: { required },
          currency: { required },
          amount: { required, decimal },
          senderName: { required },
          reference: { minLength: minLength(3), maxLength: maxLength(60) },
          providerTransactionNumber: { alphaNum },
        }
      }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
}

.dates {
    justify-content: center;

    .cof-field {
      margin: 0 50px;
    }
  }

.template-check {
  display: flex;
  align-items: center;

  input {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

  label {
    display: inline !important;
    position: unset !important;

    &:before {
      position: unset !important;
    }
  }
}

input::selection {
  background: #0000ff;
  color: #fff;
}
.depositType {
  justify-content: center;
}

.information p {
  margin: 5px;
}

.wallet-address {
  font-size: 17px;
}
.form-max {
  div {
    width: 100%;
  }
}
.paddingless {
  padding: 0 !important;
}

.content-tlist {
  background: none; 
}
</style>
