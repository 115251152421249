<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>

    <section  class="content-tlist">
      <table class="content-table">            
        <tr>
          <th>Name</th>
          <th>Version</th>
          <th>Environment</th>
          <th>Type</th>
          <th>Status</th>
          <th>Services</th>
          <th>Notes</th>
        </tr>
        <tr v-for="(provider, i) in providers" :key="i" v-if="provider.status != 'Archived'">
          <td><span>Provider Name</span>{{ provider.provider_name }}</td>
          <td><span>Version</span>{{ provider.version }}</td>       
          <td><span>Version</span>{{ provider.environment }}</td>
          <td><span>Type</span>{{ provider.providerType }}</td>
          <td>
            <span>Status</span>
            <em
              :class="{ c1: provider.status == 'Active', c2: provider.status == 'Review', c3: provider.status === 'Testing'}" 
              >
              {{ formatStatus(provider.status) }}
            </em>
          </td>
          <td>
            <span>Services</span>
            <a @click="detail(provider.id)">View</a>
          </td>
          <td>
            <span>Notes</span>
            <template>{{ provider.notes }}</template>
          </td>
        </tr>
      </table>
    </section>

    <popup :open.sync="openPopup">
      <div class="popup-header">
        <h3>Adapter Services</h3>
      </div>
      <div class="scrollable" v-if="providerPopup">
        <div class="content-form">
          <div class="services-split">
            <div>
              <p><b>KYC/AML</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.kyc" disabled />
              <br/>
              <p><b>Domestic Wires</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.domWires" disabled />
            </div>
            <div>
              <p><b>Account Transfer</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.acct" disabled />
              <br/>
              <p><b>International Wires</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.intlWires" disabled />
            </div>
            <div>
              <p><b>ACH</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.ach" disabled />
              <br/>
              <p><b>Multi-Currency</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.multiCurrency" disabled />
            </div>
            <div>
              <p><b>Checks</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.checks" disabled />
              <br/>
              <p><b>Assets</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.assets" disabled />
            </div>
            <div>
              <p><b>Remote Deposit</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.remoteDeposit" disabled />
              <br/>
              <p><b>Debit Cards</b></p>
              <br/>
              <mnb-switch v-model="providerPopup.debitCards" disabled />
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="openPopup = false">Close</button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import Popup from '../popup'
import MnbSwitch from '../../components/ui/forms/Switch'

export default {
  name: 'system-adapters',

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    MnbSwitch,
    Popup
  },

  data () {
    return {
      open: false, 

      form: {
        provider_name: null,
        version: null,
        kyc: null,
        domWires: null,
        intlWires: null,
        ach: null,
        acct: null,
        checks: null,
        remoteDeposit: null,
        exchange: null,
        multiCurrency: null,
        debitCards: null,
        assets: null,
        status: null,
        username: null,
        password: null,
        code: null,
        notes: null,
        createdAt: null,
        providerType: null
      },

      status: 'Review',
      providerId: '',

      yesNoOptions: [
        {
          id: "1",
          text: 'Yes'
        },
        {
          id: "0",
          text: 'No'
        },
      ],

      providerTypeOptions: [
        {
          id: "Accounts",
          text: 'Accounts'
        },
        {
          id: "Compliance",
          text: 'Compliance'
        },
      ],

      editing: [],

      openPopup: false,
      updateProvider: false,
      providerPopup: null
    }
  },

  computed: {
    providers () {
      return this.$store.state.providersList.data
    },

    statusOptions () {
      const options = [
        {
          id: 'Review',
          text: 'Review',
        },
        {
          id: 'Testing',
          text: 'Testing',
        },
        {
          id: 'Active',
          text: 'Active',
        },
        {
          id: 'Disabled',
          text: 'Disabled',
        },
        {
          id: 'Archive',
          text: 'Archive',
        }
      ]
      return options
    },

    yesNoPopOptions () {
      const options = [
        {
          id: "1",
          text: 'Yes',
        },
        {
          id: "0",
          text: 'No',
        }
      ]
      return options
    },
  },

  created () {
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  methods: {

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = {
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id,
            provider_name: this.form.provider_name,
            providerType: this.form.providerType,
            version: this.form.version,
            kyc: this.form.kyc,
            domWires: this.form.domWires,
            intlWires: this.form.intlWires,
            ach: this.form.ach,
            acct: this.form.acct,
            checks: this.form.checks,
            remoteDeposit: this.form.remoteDeposit,
            exchange: this.form.exchange,
            multiCurrency: this.form.multiCurrency,
            debitCards: this.form.debitCards,
            assets: this.form.assets,
            notes: this.form.notes,
            status: this.status,
            username: this.form.username,
            password: this.form.password,
            code: this.form.code
          }
          
          this.$store.dispatch('providersList/create', payload)
          Object.keys(this.form).map(item => this.form[item] = null)
          this.$toast.success('Provider successfully created')
          this.$v.$reset()
        } catch {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    changeStatus(id) {
      this.open = true
      this.providerId = id
      const providerChange = this.providers.find(provider => provider.id === id)
      this.form.provider_name = providerChange.provider_name,
      this.form.version = providerChange.version,
      this.form.kyc =  providerChange.kyc,
      this.form.domWires =  providerChange.domWires,
      this.form.intlWires =  providerChange.intlWires,
      this.form.ach =  providerChange.ach,
      this.form.acct =  providerChange.acct,
      this.form.checks =  providerChange.checks,
      this.form.remoteDeposit =  providerChange.remoteDeposit,
      this.form.multiCurrency =  providerChange.multiCurrency,
      this.form.debitCards =  providerChange.debitCards,
      this.form.assets =  providerChange.assets,
      this.form.notes =  providerChange.notes
    },

    async updateStatus () {
      try {
        const payload = {
          provider_name: this.form.provider_name,
          version: this.form.version,
          kyc: this.form.kyc,
          domWires: this.form.domWires,
          intlWires: this.form.intlWires,
          ach: this.form.ach,
          acct: this.form.acct,
          checks: this.form.checks,
          remoteDeposit: this.form.remoteDeposit,
          multiCurrency: this.form.multiCurrency,
          debitCards: this.form.debitCards,
          assets: this.form.assets,
          notes: this.form.notes,
          status: this.status
        }
          this.$store.dispatch('providersList/update', { id: this.providerId, payload })
          this.$toast.success('Status successfully updated')
          this.open = false
        } catch {
          this.$toast.error(window.errorMessage)
        }
    },

    resetFields() {
      Object.keys(this.form).map(item => this.form[item] = null)
    },

    formatStatus(status) {
      return status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    edit(index) {
      this.editing.push(index)
    },

    detail(index) {
      this.providerId = index
      this.updateProvider = false
      this.providerPopup = this.providers.find(provider => provider.id === index)
      this.openPopup = true
    },

    async saveUpdate (i) {
      try {
        const payload = {
          id: this.providers[i].id,
          payload: {
            kyc: this.providers[i].kyc,
            domWires: this.providers[i].domWires,
            intlWires: this.providers[i].intlWires,
            ach: this.providers[i].ach,
            providerType: this.providers[i].providerType,
            acct: this.providers[i].acct,
            checks: this.providers[i].checks,
            remoteDeposit: this.providers[i].remoteDeposit,
            multiCurrency: this.providers[i].multiCurrency,
            debitCards: this.providers[i].debitCards,
            assets: this.providers[i].assets,
            notes: this.providers[i].notes,
            status: this.providers[i].status,
            username: this.providers[i].username,
            password: this.providers[i].password,
            code: this.providers[i].code,
            upd: this.providers[i].upd
          }
        }

        await this.$store.dispatch('providersList/update', payload)
        this.$toast.success('Provider successfully updated.')
        this.cancelEdit(i)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async saveProvider () {
      try {
        const payload = {
          id: this.providerId,
          payload: {
            providerType: this.providerPopup.providerType,
            kyc: this.providerPopup.kyc,
            domWires: this.providerPopup.domWires,
            intlWires: this.providerPopup.intlWires,
            ach: this.providerPopup.ach,
            acct: this.providerPopup.acct,
            checks: this.providerPopup.checks,
            remoteDeposit: this.providerPopup.remoteDeposit,
            multiCurrency: this.providerPopup.multiCurrency,
            debitCards: this.providerPopup.debitCards,
            assets: this.providerPopup.assets,
            notes: this.providerPopup.notes,
            status: this.providerPopup.status,
            username: this.providerPopup.username,
            password: this.providerPopup.password,
            code: this.providerPopup.code,
            notes: this.providerPopup.notes,
          }
        }
        await this.$store.dispatch('providersList/update', payload)
        if(this.providerPopup.domWires == 0) {
          await api.action("ProvidersList", payload.id, "UpdateDom", payload.payload)
        }
        if(this.providerPopup.intlWires == 0) {
          await api.action("ProvidersList", payload.id, "UpdateIntl", payload.payload)
        }
        this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
        this.$toast.success('Provider successfully updated.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async deleteProvider (i) {
      try {
        await this.$store.dispatch('providersList/delete', this.providers[i].id)
        this.$toast.success('Provider successfully deleted.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    async updUpdate (i) {
      try {
        const payload = {
          id: this.providers[i].id,
          payload: {
            upd: this.providers[i].upd
          }
        }

        await this.$store.dispatch('providersList/update', payload)
        this.$toast.success('Provider successfully updated.')
        this.cancelEdit(i)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
    },
  },

  validations () {
    const rules = {
      form: {
        provider_name: { required },
        providerType: { required },
        version: { required },
        kyc: { required },
        domWires: { required },
        intlWires: { required },
        ach: { required },
        acct: { required },
        checks: { required },
        remoteDeposit: { required },
        multiCurrency: { required },
        debitCards: { required },
        assets: { required },
        notes: { required }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    margin-right: 5px; 
  }
  .errors {
    color: #ff0000 !important;
  }
  // .status-options {
  //   margin-bottom: 30px; 
  // }
  .key-wrap {
    max-width: 100px;
    word-wrap: break-word;
  }
  .fee-checkbox {
    -webkit-appearance: checkbox;
  }
  .updateAt {
    font-size: 10px;
    width: 155px;
  }
  .form-max {
    div {
      width: 100%;
    }
  }

  p.c1 {
    color: #13ce67;
  }
  p.c3 {
    color: #616f7b;
  }

  .services-split {
    display: flex;
    justify-content: space-between;
  }
</style>
<style lang="scss" scoped>
.content-tlist {
  border: none;
}
</style>