<template>
  <div>
    <button v-if="$hasPerm('partners-approve') && partner && partner.status == 'New'" class="btn btn-primary" @click="deny(partner.id)">Deny</button>
    <button v-if="partner && partner.status == 'Denied'" class="btn btn-primary" @click="archive(partner.id)">Archive</button>
    <ul class="cp-rows active">
      <li>
        <ul class="cp-fields" v-if="partner">
          <li>
            <span>Partner Since</span>
            <div class="val">{{ partner.createdAt | date('LL') }}</div>
          </li>
          <li>
            <span>Partner Type</span>
            <div class="val">{{ partner.type }}</div>
          </li>
          <li>
            <span>Partner Number</span>
            <div class="val">
              {{ partner.partnerNumber }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  
  computed: {
    partner () {
      return this.$store.getters['partners/get'](this.id)
    }
  },

  methods: {
    async deny (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('partners/update', {
            id,
            payload: {
              status: 'Denied',
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('partner_notes/create', {
            createdBy: window.appData.currentUser.id,
            partnerId: id,
            notes: 'Status updated to Denied',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          loader.hide()
        }
    },

    async archive (id) {
        if(confirm(`Are you sure?`)) {
          const loader = this.$loading.show()
          await this.$store.dispatch('partners/update', {
            id,
            payload: {
              status: 'Archived',
              updatedAt: 'CURRENT_TIMESTAMP'
            }
          })
          await this.$store.dispatch('partner_notes/create', {
            createdBy: window.appData.currentUser.id,
            partnerId: id,
            notes: 'Status updated to Archived',
            createdAt: 'CURRENT_TIMESTAMP'
          })
          loader.hide()
        }
    }
  }
}
</script>
