<template>
  <div class="cp-box">
    <ul class="sub-tabs" style="margin-bottom: 12px">
      <li :class="{ active: activeTab === 'services' }" @click="activeTab = 'services'"><a>Services</a></li>
    </ul>
    <hr>

    <services-setup v-if="activeTab === 'services'" :registration="registration"/>
  </div>
</template>

<script>
import ServicesSetup from './ServicesSetup'

export default {

  components: {
    ServicesSetup
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      activeTab: 'services'
    }
  },

  computed: {
    registration () {
      return this.$store.getters['registrations/get'](this.id)
    }
  },
}
</script>
