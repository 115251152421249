<template>
<div class="checklist-steps">
  <div class="step-form">
    <div class="step-new-head">
      <h3 style="color: #000;">Partner Boarding</h3>
      <div class="step-nav">
        <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
        <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
        <a href="" class="next" :class="{disabled: !canNext}" v-if="step<4" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
        <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==4" v-on:click.prevent="finish">Finish</a>
      </div>
    </div>
    <div class="step-new-split">
      <ul class="step-tabs st7">
        <li :class="{past: isDone[1], active: step==1}" v-on:click="isDone[1] && (step=1)">
          Documents
        </li>
        <li :class="{past: isDone[2], active: step==2}" v-on:click="isDone[2] && (step=2)">
          Settings
        </li>
        <li :class="{past: isDone[3], active: step==3}" v-on:click="isDone[3] && (step=3)">
          Schedule
        </li>
        <li :class="{past: isDone[4], active: step==4}" v-on:click="isDone[4] && (step=4)">
          Finalize
        </li>
      </ul>

      <ul class="tab-content">
        <li v-if="step==1" class="active">
          <documents :partner="partner" />  
        </li>
        <li v-else-if="step==2" class="active">
          <profile-settings :partner="partner" />
        </li>
        <li v-else-if="step==3" class="active">
          <profile-schedule :partner="partner" />
        </li>
        <li v-else-if="step==4" class="active">
          <finalize :partner="partner" />
        </li>
        
      </ul>
    </div>
    <div class="step-nav">
      <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">&lt; &lt; Back</a>
      <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
      <a href="" class="next" :class="{disabled: !canNext}" v-if="step<4" v-on:click.prevent="nextStep">Next &gt; &gt;</a>
      <a href="" class="fin" :class="{disabled: !canNext}" v-else-if="step==5" v-on:click.prevent="finish">Finish</a>
    </div>
  </div>
</div>
</template>
<script>
import Documents from './Documents'
import Finalize from './Finalize'
import ProfileSettings from './ProfileSettings'
import ProfileSchedule from './ProfileSchedule'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  data: ()=>({
    step: 1,
  }),
  computed: {
    isDone() {
      return [
        true,
        !!this.$store.getters['partners/get'](this.partner.id).documentsChecklist,
        !!this.partner?.settings,
        !!this.partner?.schedule,
        this.partner?.status=='Pending' || this.partner?.status=='Active',
      ];
    },
    canNext() {
      return this.isDone[this.step];
    },
  },
  methods: {
    prevStep() {
      this.step--;
    },
    nextStep() {
      if (!this.canNext)
        return;
      this.step++;
    },
    finish() {
      // TODO
    },
    cancel() {
      history.go(-1);
    },
  },
  components: { 
    Documents,
    Finalize,
    ProfileSettings,
    ProfileSchedule,
  },
  mounted() {
    for(var i=1;i<4;i++)
      if (this.isDone[i])
      this.step=i+1;
  },
};
</script>
