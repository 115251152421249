<template>
<div>
<section class="content-tlist">
<table class="content-table">
  <thead>
    <tr>
      <td>Role</td>
      <td>Group</td>
      <td>Parent</td>
      <td>Actions</td>
    </tr>
  </thead>
  <tbody>
    <tr v-for="role in roles">
      <td>{{ role.name }}</td>
      <td>{{ role.group?role.group.name:"(no group)" }}</td>
      <td>{{ role.parent?(rolesById[role.parent] || {}).name:"(no parent)" }}</td>
      <td>
        <a class="btn btn-secondary" href="" @click.prevent="editRole(role)">Edit</a>
        <a class="btn btn-danger" href="" @click.prevent="deleteRole(role)">Delete</a>
      </td>
    </tr>
  </tbody>
</table>
</section>
<a class="btn btn-primary" href="" @click.prevent="newRole()">New Role</a>
<role-edit v-if="currentRole" :role="currentRole" :roles="roles" />
</div>
</template>

<script>
export default {
  data: ()=>({
    roles: [],
    currentRole: null,
  }),
  computed: {
    rolesById() {
      var result={};
      for(var role of this.roles)
        result[role.id]=role;
      return result;
    },
  },
  methods: {
    async loadRoles() {
      this.roles=(await api.list("Role")).items;
    },
    editRole(role) {
      this.currentRole=role;
    },
    deleteRole(role) {
    },
    newRole() {
      this.currentRole={
        name: "",
        perms: [],
      };
    },
  },
  mounted() {
    this.loadRoles();
  },
}
</script>
