<template>
<RegisterForm2 :internal="true"></RegisterForm2>
</template>

<script>
export default {
  name: 'CustomerNewreg',

  

}
</script>
