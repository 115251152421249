<template>
  <div>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 15%;">Fee Name</th>
          <th style="width: 10%;">Fee</th>
          <th style="width: 10%;">Deposit</th>
          <th style="width: 10%;">Withdrawal</th>
          <th style="width: 13%;">Description</th>
        </tr>
        <tr v-for="(item, i) in fees" :key="i">
          <template v-if="i==0">
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template> {{ item.fee | formattedTwoDecimal }} </template>
              %
            </td>
            <td></td>
            <td></td>
            <td>
              <span>Description</span>
              <template> {{ item.description }}</template>
            </td>
          </template>
          <template v-else>
            <td>
              <span>Fee Name</span>
              {{ item.name }}
            </td>
            <td>
              <span>Fee</span>
              <template v-if="item.fee != null">
                $<template> {{ item.fee | formattedTwoDecimal }} </template>
              </template>
            </td>
            <td>
              <span>Per Deposit</span>
              <template v-if="item.per_deposit != null">
                $<template> {{ item.per_deposit | formattedTwoDecimal }} </template>
              </template>
            </td>
            <td>
              <span>Per Withdrawal</span>
              <template v-if="item.per_withdrawal != null">
                $<template> {{ item.per_withdrawal | formattedTwoDecimal }} </template>
              </template>
            </td>
            <td>
              <span>Description</span>
              <template> {{ item.description }}</template>
            </td>
          </template>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>

export default {
  name: 'fee-schedule',

  data() {
    return {
    }
  },

  computed: {
    fees () {
      const data = this.$store.state.fee_schedule.data || []
      return data
    }
  },

  async created () {
    await this.$store.dispatch('fee_schedule/load')
  },

  methods: {

  },

}
</script>

<style lang="scss" scoped>
  .fee-input {
    max-width: 50px;
  }
  .fee-input::-webkit-inner-spin-button,
  .fee-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .description {
    max-width: 100%;
  }
  // .text-center {
  //   text-align: center;
  // }
</style>