<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Service Providers</h2>
      <div class="status">
        Status
        <status-icon :color="serviceProviders ? 'green' : null" />
      </div>
    </div>
    <table class="content-table" style="min-width: unset;">
      <tr>
        <th>Date/Time</th>
        <th>Account</th>
        <th>Compliance</th>
        <th>ACH/EFT</th>
        <th>Account Transfers</th>
        <th>Checks</th>
        <th>Dom Wires</th>
        <th>Intl Wires</th>
        <th>Assets</th>
        <th>Multi-Currency</th>
        <th>Debit Cards</th>
        <th v-if="registration.status !== 'Complete' && !mainAccount">Options</th>
      </tr>
      <tr>
        <td><span>Date/Time</span>
          <template v-if="!serviceProviders">N/A</template>
          <template v-else>{{ serviceProviders.createdAt | date('LL') }} {{ serviceProviders.createdAt | date('LT') }}</template>
        </td>
        <td><span>Account</span>
          <mnb-select v-model="form.provider" :options="providerOptions" :disabled="!!serviceProviders"/>
        </td>
        <td><span>Compliance</span>
          <template v-if="provider">
            {{ provider.kyc == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>ACH</span>
          <template v-if="provider">
            {{ provider.ach == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Account</span>
          <template v-if="provider">
            {{ provider.acct == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Checks</span>
          <template v-if="provider">
            {{ provider.checks == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="provider">
            {{ provider.domWires == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Wire Transfer</span>
          <template v-if="provider">
            {{ provider.intlWires == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Assets</span>
          <template v-if="provider">
            {{ provider.assets == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Multi-currency</span>
          <template v-if="provider">
            {{ provider.multiCurrency == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td><span>Debit Cards</span>
          <template v-if="provider">
            {{ provider.debitCards == 1 ? provider.provider_name : 'Not Assigned' }}
          </template>
          <template v-else>N/A</template>
        </td>
        <td v-if="registration.status !== 'Complete'"><span>Options</span>
          <button v-if="!serviceProviders" class="main" :disabled="$v.form.$invalid" @click="create()">Create</button>
          <button v-else class="second" @click="remove()">Remove</button>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'

  import StatusIcon from './StatusIcon'
  import MnbSelect from '../../../components/ui/forms/Select'

  export default {
    props: {
      registration: {
        type: Object,
        required: true
      }
    },

    components: {
      StatusIcon,
      MnbSelect
    },

    data () {
      return {
        form: {
          provider: null
        }
      }
    },

    computed: {
      serviceProviders () {
        return this.registration?.serviceProviders || null
      },

      provider () {
        return this.providers.find(provider => provider.id === this.form.provider)
      },

      providers () {
        return this.$store.state.providersList.data || []
      },

      mainAccount () {
        return this.registration.mainAccount || null
      },

      providerOptions () {
        return this.providers.filter(provider => ['Active', 'Testing'].includes(provider.status) && provider.kyc).map(provider => ({
          id: provider.id,
          text: provider.provider_name
        }))
      }
    },

    async created () {
      await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
      this.form.provider = this.serviceProviders?.provider?.id
    },

    methods: {
      providersName (id) {
        const provider = this.providers.find(item => parseInt(item.id) === parseInt(id))
        return provider?.provider_name || 'Not Assigned'
      },

      async remove () {
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('reg_service_providers/delete', this.serviceProviders.id)
          await this.$store.dispatch('registrations/update', {
            id: this.registration.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              serviceProviders: null
            }
          })
          await this.addNote('Service Providers removed')
          this.form.provider = null
          this.$toast.success('Service Providers successfully removed.')
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      async create () {
        if (this.$v.form.$invalid) return
        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('registrations/update', {
            id: this.registration.id,
            payload: {
              updatedAt: 'CURRENT_TIMESTAMP',
              serviceProviders: {
                ...this.form,
                createdAt: 'CURRENT_TIMESTAMP'
              }
            }
          })
          await this.addNote('Service Providers created')
          this.$toast.success('Service Providers successfully created.')
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      },

      addNote (notes) {
        return this.$store.dispatch('profilenotes/create', {
          createdBy: window.appData.currentUser.id,
          registrationId: this.registration.id,
          notes,
          createdAt: 'CURRENT_TIMESTAMP'
        })
      }
    },

    validations: {
      form: {
        provider: { required }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    padding: 15px 34px;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    .status {
      font-size: 16px;
    }
  }
</style>
