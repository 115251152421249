<template>
  <section  class="content-tlist" style="margin: 30px 0;">
    <div class="header">
      <h2>Finalize</h2>
      <div class="status">
        Status
        <status-icon :color="complianceScan && complianceScan.confirm === 'Pass' ? 'green' : null" />
      </div>
    </div>
    <table class="content-table" style="min-width: unset;">
      <tr>
<!--         <th style="width: 15%;">Type</th> -->
        <th style="width: 13%;">Provider</th>
        <th style="width: 10%;">IDV</th>
        <th style="width: 13%;">Date/Time</th>
        <th style="width: 10%;">Account</th>
        <th style="width: 10%;">Status</th>
        <th style="width: 13%;">Result</th>
        <!--<th v-if="registration.status !== 'Complete'" style="width: 20%;">Options</th>-->
      </tr>
      <tr>
<!--        <td><span>Type</span>
          Account Opening
        </td>-->
        <td><span>Provider</span>
          <template v-if="serviceProviders">{{ serviceProverName }}</template>
          <template v-else>N/A</template>
        </td>
        <td><span>IDV</span>
          <template v-if="!complianceScan">
            <mnb-select v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'" v-model="form.idv" class="select"  :options="yesNoOptions" />
            <mnb-select v-else v-model="form.idv = 0" class="select"  :options="yesNoOptions" :disabled="true" />
          </template>
          <template v-else>
            <mnb-select v-model="complianceScan.idv" class="select"  :options="yesNoOptions" :disabled="true"/>
          </template>
        </td>
        <td><span>Date/Time</span>
          <template v-if="!complianceScan">N/A</template>
          <template v-else>{{ complianceScan.createdAt | date('LL') }} {{ complianceScan.createdAt | date('LT') }}</template>
        </td>
        <td><span>Account</span>
          <template v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'">
            <template v-if="solidAccount">{{ solidAccount.accountNumber }}</template>
          </template>
          <template v-else>
            <template v-if="!complianceScan">N/A</template>
            <template v-else>{{ complianceScan.accountNumber }}</template>
          </template>
        </td>
        <td><span>Status</span>
          {{ registration.status }}
        </td>
        <td><span>Result</span>
          <template v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'">
            <template v-if="solidAccount">{{ statusColorText[solidAccount.accountStatus] }}</template>
          </template>
          <template v-else>
            <template v-if="!complianceScan"></template>
            <template v-else>{{ complianceScan.result }}</template>
          </template>
        </td>
        <!--<td><span>Options</span>
          <template v-if="complianceScan && complianceScan.result == 'Opened' && complianceScan.cipStatus == 'APP' && complianceScan.amlStatus == 'APP' && complianceScan.kycStatus == 'APP' && complianceScan.confirm != 'Pass'">
            <button  :disabled="!isAvailable || complianceScan.confirm == 'Pass'" class="main" @click="confirm()">
              Confirm
            </button>
          </template>
          <template v-else-if="complianceScan && complianceScan.confirm != 'Pass'" >

            <button v-if="systemMaintenanceStatus" @click="update()" :disabled="!isAvailable" class="main forcebtt">
            Update
            </button>
            <template v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'">
              <button v-if="solidAccount" class="main" @click="confirm()">
                Confirm
              </button>
              <template v-else-if="registration.type !='Business'">
                <button v-if="complianceScan && complianceScan.kycStatus == 'approved'" @click="setAccount()" class="main">
                  Set Account
                </button>
                <button v-else @click="documentOpen = true" class="main">
                  Document
                </button>
              </template>
              <template v-else>
                <button v-if="complianceScan && complianceScan.kycStatus == 'approved' && complianceScan.kybStatus == 'approved'" @click="setAccount()" class="main">
                  Set Account
                </button>
                <button v-else @click="documentOpen = true" class="main">
                  Document
                </button>
              </template>
            </template>
            <template v-else>
              <button @click="resubmit()" :disabled="!isAvailable" class="main">
                Resubmit
              </button>
              <button @click="documentOpen = true" class="main">
                Document
              </button>
            </template>
            <template v-if="systemMaintenanceStatus">
              <button v-if="!noforce" class="main forcebtt" @click="force()">Force</button>
              <button v-if="registration.type == 'Business'" class="main forcebtt" @click="addContacts()">Add Contacts</button>
            </template>
          </template>
          <button v-else-if="complianceScan" :disabled="!isAvailable" class="second" @click="reset()">
            Reset
          </button>
          <template v-else>
            <template v-if="registration.accounts[registration.accounts.length-1].profileSettings">
              <button v-if="!complianceScan" class="main" @click="run()" :disabled="!isAvailable">Run</button>
            </template>
          </template>
        </td>-->
      </tr>
    </table>
    <div class="header">
      <h2>Options</h2>
    </div>
    <div class="n-acc-check-opt">
      <div class="n-acc-actions">
        <template v-if="isMannual">
          <template v-if="complianceScan && complianceScan.result == 'Opened' && complianceScan.cipStatus == 'APP' && complianceScan.amlStatus == 'APP' && complianceScan.kycStatus == 'APP' && complianceScan.confirm != 'Pass'">
            <button  :disabled="!isAvailable || complianceScan.confirm == 'Pass'" class="main" @click="confirmManual()">
              Confirm
            </button>
          </template>
          <template v-else-if="complianceScan && complianceScan.confirm != 'Pass'" >
            <button class="main" @click="approveManual()" :disabled="!isAvailable">Approve</button>
          </template>
          <template v-else>
            <template v-if="registration.accounts[registration.accounts.length-1].profileSettings">
              <button v-if="!complianceScan" class="main" @click="runManual()" :disabled="!isAvailable">Run</button>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="argenseAccount && complianceScan">
            <template v-if="complianceScan.result == 'Opened' || complianceScan.result == 'Approved' || complianceScan.result == 'Activation'">
              <button  :disabled="!isAvailable || complianceScan.confirm == 'Pass'" class="main" @click="confirm()">
                Confirm
              </button>
            </template>
          </template>
          <template v-if="complianceScan && complianceScan.result == 'Opened' && complianceScan.cipStatus == 'APP' && complianceScan.amlStatus == 'APP' && complianceScan.kycStatus == 'APP' && complianceScan.confirm != 'Pass'">
            <button  :disabled="!isAvailable || complianceScan.confirm == 'Pass'" class="main" @click="confirm()">
              Confirm
            </button>
          </template>
          <template v-else-if="complianceScan && complianceScan.confirm != 'Pass'" >

            <button v-if="systemMaintenanceStatus" @click="update()" :disabled="!isAvailable" class="main forcebtt">
            Update
            </button>
            <template v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'">
              <button v-if="solidAccount" class="main" @click="confirm()">
                Confirm
              </button>
              <template v-else-if="registration.type !='Business'">
                <button v-if="complianceScan && complianceScan.kycStatus == 'approved'" @click="setAccount()" class="main">
                  Set Account
                </button>
                <button v-else @click="documentOpen = true" class="main">
                  Document
                </button>
              </template>
              <template v-else>
                <button v-if="complianceScan && complianceScan.kycStatus == 'approved' && complianceScan.kybStatus == 'approved'" @click="setAccount()" class="main">
                  Set Account
                </button>
                <button v-else @click="documentOpen = true" class="main">
                  Document
                </button>
              </template>
            </template>
            <template v-else>
              &nbsp;
              <!-- <button @click="resubmit()" :disabled="!isAvailable" class="main">
                Resubmit
              </button>
              <button @click="documentOpen = true" class="main">
                Document
              </button> -->
            </template>
            <template v-if="systemMaintenanceStatus">
              <button v-if="!noforce" class="main forcebtt" @click="force()">Force</button>
              <button v-if="registration.type == 'Business'" class="main forcebtt" @click="addContacts()">Add Contacts</button>
            </template>
          </template>
          <!--<button v-else-if="complianceScan" :disabled="!isAvailable" class="second" @click="reset()">
            Reset
          </button>-->
          <template v-else>
            <template v-if="registration.accounts[registration.accounts.length-1].profileSettings">
              <button v-if="!complianceScan" class="main" @click="run()" :disabled="!isAvailable">Run</button>
            </template>
          </template>
        </template>
      </div>
    </div>
    <div class="header">
      <h2>Compliance</h2>
    </div>
    <table class="content-table">
      <tr>
        <th style="width: 15%;">
          Name
        </th>
        <th style="width: 13%;">
          Documents
        </th>
        <th style="width: 13%;">
          Compliance
        </th>
        <th style="width: 20%;">
          Messages
        </th>
      </tr>
      <template v-if="isMannual">
        <template v-if="registration.type != 'Business'">
          <tr v-if="complianceScan">
            <td>
              {{ registration.data.firstName }} {{ registration.data.lastName }}
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.amlStatus]">{{ statusColorText[complianceScan.amlStatus] }}</em>
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.cipStatus]">{{ statusColorText[complianceScan.cipStatus] }}</em>
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.kycStatus]">{{ statusColorText[complianceScan.kycStatus] }}</em>
            </td>
            <td>
            </td>
          </tr>
        </template>
        <template v-else-if="complianceScan">
          <tr>
            <td>
              {{ registration.data.legal_name }}
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.amlStatus]">{{ statusColorText[complianceScan.amlStatus] }}</em>
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.cipStatus]">{{ statusColorText[complianceScan.cipStatus] }}</em>
            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.kycStatus]">{{ statusColorText[complianceScan.kycStatus] }}</em>
            </td>
            <td>
            </td>
          </tr>
        </template>
      </template>
      <template v-else-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'">
        <template v-if="registration.type != 'Business'">
          <tr v-if="complianceScan">
            <td>
              {{ registration.data.firstName }} {{ registration.data.lastName }}
            </td>
            <td>

            </td>
            <td>

            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.kycStatus]">{{ statusColorText[complianceScan.kycStatus] }}</em>
            </td>
            <td>
              <button class="btn main" @click="viewSolidMessage()">View</button>
            </td>
          </tr>
        </template>
        <template v-else-if="complianceScan">
          <tr>
            <td>
              {{ registration.data.legal_name }}
            </td>
            <td>

            </td>
            <td>

            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.kybStatus]">{{ statusColorText[complianceScan.kybStatus] }}</em>
            </td>
            <td>
              <button class="btn main" @click="viewSolidBusinessMessage()">View</button>
            </td>
          </tr>
          <tr v-for="contact in registration.data.contacts">
            <td>
              {{ contact.firstName }} {{ contact.lastName }}
            </td>
            <td>

            </td>
            <td>

            </td>
            <td>
              <em :class="statusColorClasses[complianceScan.kycStatus]">{{ statusColorText[complianceScan.kycStatus] }}</em>
            </td>
            <td>
              <button class="btn main" @click="viewSolidContactMessage(contact.firstName + ' ' + contact.lastName)">View</button>
            </td>
          </tr>
        </template>
      </template>
      <template v-else-if="serviceProverName == 'Argense Sandbox' || serviceProverName == 'Argense'">
        <template v-if="registration.type != 'Business'">
          <tr v-if="complianceScan">
            <td>
              {{ registration.data.firstName }} {{ registration.data.lastName }}
            </td>
            <td>
              {{ complianceScan.document_status }}
            </td>
            <td>
              {{ complianceScan.compliance_status }}
            </td>
            <td>
              
            </td>
          </tr>
        </template>
        <template v-else-if="complianceScan">
          <tr>
            <td>
              {{ registration.data.legal_name }}
            </td>
            <td>
              {{ complianceScan.document_status }}
            </td>
            <td>
              {{ complianceScan.compliance_status }}
            </td>
            <td>
              
            </td>
          </tr>
          <tr v-for="(contact, index) in registration.data.contacts" :key="index">
            <td>
              {{ contact.firstName }} {{ contact.lastName }}
            </td>
            <td>
              {{ complianceScan.contacts_status[index].document_status }}
            </td>
            <td>
              {{ complianceScan.contacts_status[index].compliance_status }}
            </td>
            <td>
             
            </td>
          </tr>
        </template>
      </template>
      <template v-else-if="complianceScan && complianceScan.response">
        <tr v-for="include in complianceScan.response.included" v-if="include.type == 'contacts'">
          <td>
            {{ include.attributes.name }}
          </td>
          <td>
            <template v-if="include.attributes['aml-cleared']"><em class="c1">Passed</em></template>
            <template v-else><em class="c2">Pending</em></template>
          </td>
          <td>
            <template v-if="include.attributes['cip-cleared']"><em class="c1">Passed</em></template>
            <template v-else><em class="c2">Pending</em></template>
          </td>
          <td>
            <template v-if="include.attributes['identity-confirmed']"><em class="c1">Passed</em></template>
            <template v-else><em class="c2">Pending</em></template>
          </td>
          <!--<td>-->
            <!--<template v-if="include.attributes['kyc-documents-verified']"><em class="c1">Passed</em></template>-->
            <!--<template v-else><em class="c2">Pending</em></template>-->
          <!--</td>-->
          <!--<td>-->
            <!--<template v-if="include.attributes['ready-for-review']"><em class="c1">Passed</em></template>-->
            <!--<template v-else><em class="c2">Pending</em></template>-->
          <!--</td>-->
          <td>
            <template v-if="complianceScan && ((complianceScan.amlMessage && complianceScan.amlMessage[include.id]) || (complianceScan.cipMessage && complianceScan.cipMessage[include.id]) || (complianceScan.cipMessage && complianceScan.kycMessage[include.id]))">
              <button class="btn main" @click="viewMessage(include.id, include.attributes.name)">View</button>
            </template>
            <template v-else>
              <button class="btn btn-secondary" @click="viewMessage(include.id, include.attributes.name)">View</button>
            </template>
          </td>
        </tr>
      </template>
    </table>
    <popup :open.sync="errorAccount" :width="400">
      <div class="popup-header">
        <h3>Error</h3>
      </div>
      <div class="scrollable">
        Error - {{errorDate}}
        <p>{{ errorMessage }}</p>
        <p v-for="message in messages" style="text-align: center;"><b>{{ message }} is missing.</b></p>
        <p v-for="providerMessage in providerMessages" style="text-align: center;"><b>{{ providerMessage }}</b></p>
      </div>
    </popup>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Enter Admin PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
                v-on:keyup="pinInput"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="messageOpen" :width="900">
      <h2>Compliance Messages</h2>
      <div v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'" class="pf-row split comliance-header">
        <template v-if="registration.type == 'Business'">
          <template v-if="business">
            <p v-if="complianceScan" class="subtitle">{{ statusColorText[complianceScan.kybStatus] }}</p>
            <p v-if="complianceScan" class="subtitle">{{ registration.data.legal_name }}</p>
            <p v-if="complianceScan" class="subtitle">{{ solidAccount? solidAccount.accountNumber : ''}}</p>
            <p v-if="complianceScan" class="subtitle">{{ complianceScan.createdAt }}</p>
          </template>
          <template v-else>
            <p v-if="complianceScan" class="subtitle">{{ statusColorText[complianceScan.kycStatus] }}</p>
            <p v-if="complianceScan" class="subtitle">{{ contactName }}</p>
            <p v-if="complianceScan" class="subtitle">{{ solidAccount? solidAccount.accountNumber : ''}}</p>
            <p v-if="complianceScan" class="subtitle">{{ complianceScan.createdAt }}</p>
          </template>
        </template>
        <template v-else>
          <p v-if="complianceScan" class="subtitle">{{ statusColorText[complianceScan.kycStatus] }}</p>
          <p v-if="complianceScan" class="subtitle">{{ registration.data.firstName }} {{ registration.data.lastName }}</p>
          <p v-if="complianceScan" class="subtitle">{{ solidAccount? solidAccount.accountNumber : ''}}</p>
          <p v-if="complianceScan" class="subtitle">{{ complianceScan.createdAt }}</p>
        </template>
      </div>
      <div v-else class="pf-row split comliance-header">
          <p v-if="complianceScan" class="subtitle">{{ complianceScan.result }}</p>
          <p v-if="complianceScan" class="subtitle">{{ contactName }}</p>
          <p v-if="complianceScan" class="subtitle">{{ complianceScan.accountNumber }}</p>
          <p v-if="complianceScan" class="subtitle">{{ complianceScan.createdAt }}</p>
      </div>
      <table v-if="serviceProverName == 'Solid' || serviceProverName == 'Solid Test'" class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">Review Code</th>
          <th style="width: 50%;">Review Message</th>
        </tr>
        <tr v-if="complianceScan && complianceScan.reviewCode">
          <td>{{ complianceScan.createdAt }}</td>
          <td>{{ complianceScan.reviewCode }}</td>
          <td>{{ complianceScan.reviewMessage }}</td>
        </tr>
      </table>
      <br/>
      <table class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">AML Message</th>
          <th style="width: 50%;">AML Message Details</th>
        </tr>
        <template v-if="complianceScan && contactId && complianceScan.amlMessage">
          <tr v-if="item.date" v-for="item in complianceScan.amlMessage[contactId]">
            <td>{{ item.date }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.detail }}</td>
          </tr>
        </template>
      </table>
      <br/>
      <table class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">CIP Message</th>
          <th style="width: 50%;">CIP Message Details</th>
        </tr>
        <template  v-if="complianceScan && contactId && complianceScan.cipMessage">
          <tr v-if="item.date" v-for="item in complianceScan.cipMessage[contactId]">
            <td>{{ item.date }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.detail }}</td>
          </tr>
        </template>
      </table>
      <br/>
      <table class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">KYC Message</th>
          <th style="width: 50%;">KYC Message Details</th>
        </tr>
        <template  v-if="complianceScan && contactId && complianceScan.kycMessage">
          <tr v-if="item.date" v-for="item in complianceScan.kycMessage[contactId]">
            <td>{{ item.date }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.detail }}</td>
          </tr>
        </template>
      </table>
      <br/>
      <table class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">KYC Action Items Message</th>
          <th style="width: 50%;">KYC Action Items Message Details</th>
        </tr>
        <template  v-if="complianceScan && contactId && complianceScan.kycActionItems">
          <tr v-if="item.date" v-for="item in complianceScan.kycActionItems[contactId]">
            <td>{{ item.date }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.detail }}</td>
          </tr>
        </template>
      </table>
      <table v-if="business" class="content-table">
        <tr>
          <th style="width: 25%;">Date</th>
          <th style="width: 25%;">KYB Message</th>
          <th style="width: 50%;">KYB Message Details</th>
        </tr>
        <template  v-if="complianceScan && complianceScan.kycResults">
          <tr>
            <td>{{ complianceScan.createdAt }}</td>
            <td>{{  }}</td>
            <td>{{ complianceScan.kycResults }}</td>
          </tr>
        </template>
      </table>
      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="messageOpen=false">Close</a>
      </div>
    </popup>
    <popup :open.sync="documentOpen" :width="900">
      <h2>Document Submission</h2>
      <div class="pf-row split comliance-header">
        <p class="subtitle">{{ serviceProverName }}</p>
        <p class="subtitle" v-if="registration.type == 'Business'">{{ registration.data.legal_name }}</p>
        <p class="subtitle" v-else>{{ registration.data.firstName }} {{ registration.data.lastName }}</p>
        <p class="subtitle">{{ registration.accounts[this.registration.accounts.length-1].accountNumber }}</p>
      </div>
      Documents List
      <table class="content-table">
        <tr>
          <th>Select </th>
          <th>Description</th>
          <th>Type</th>
          <th>Number</th>
          <th>Filename</th>
        </tr>
        <tr v-for="document in reorderedDocuments" :key="document.id">
          <td><input v-model="selectedDocument" type="radio" class="fee-checkbox" name="document-select" :value="document.id"></td>
          <td>{{ document.description }}</td>
          <td>{{ document.type }}</td>
          <td>{{ document.number }}</td>
          <td>{{ document.file }}</td>
        </tr>
      </table>
      <br/>
      <div class="pf-row buttons">
        <button :disabled="!selectedDocument" @click="submitDocument()" class="main">Submit</button>
        <button class="second" @click.prevent="documentOpen=false">Close</button>
      </div>
    </popup>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import StatusIcon from './StatusIcon'
import MnbSelect from '../../../components/ui/forms/Select'

export default {
  props: {
    registration: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      errorAccount: false,
      errorMessage: null,
      errorDate: null,
      messages: null,
      providerMessages: null,

      pin: '',
      pinFailed: false,
      open: false,
      noforce: appData.customEnv.noforce,
      contactId: null,
      contactName: null,
      messageOpen: false,
      documentOpen: false,
      systemMaintenanceStatus: window.maintenance,
      yesNoOptions: [
        {
          id: 1,
          text: 'Yes'
        },
        {
          id: 0,
          text: 'No'
        },
      ],
      form: { idv: 0 },
      business:false,

      selectedDocument: null
    }
  },

  components: {
    StatusIcon,
    MnbSelect
  },

  computed: {
    isAvailable () {
      return !!(this.registration.terms &&
        this.registration.documentsChecklist &&
        this.registration.mainAccount)
    },

    serviceProviders () {
      return this.registration?.accounts[this.registration.accounts.length-1]?.serviceProviders || null
    },

    isMannual () {
      return this.serviceProviders?.connectionType == 'Manual'? true : false
    },

    complianceScan () {
      return this.registration.accounts[this.registration.accounts.length-1]?.complianceScan || null
    },

    providers () {
      return this.$store.state.providersList.data || []
    },
    
    serviceProverName () {
      return this.serviceProviders?.provider_name || 'Not Assigned'
    },

    solidAccount () {
      return this.registration.accounts[this.registration.accounts.length-1]?.solidAccount[0] || null
    },

    argenseAccount () {
      return this.registration.accounts[this.registration.accounts.length-1]?.argenseAccount[0] || null
    },

    statusColorClasses () {
      return {
        APP: 'c1',
        approved: 'c1',
        inReview: 'c2',
        declined: 'c3',
        submitted: 'c2',
      }
    },

    statusColorText () {
      return {
        APP: 'APP',
        approved: 'Approved',
        inReview: 'In Review',
        declined: 'Declined',
        active: 'Active',
        submitted: 'Submitted',
        pending: 'Pending',
        New: 'New',
        Opened: 'Opened',
        Approved: 'Approved',
      }
    },

    documents () {
      return this.registration.documents.filter(item => item.file && item.docFor !== 'terms_and_conditions')
    },

    reorderedDocuments () {
      let indexes = {
        'Terms and Conditions': 1,
        'Business Incorporation': 2,
        'Business Address Confirmation': 3,
        'Contact 1 Passport': 4,
        'Contact 1 Government Issued ID': 5,
        'Contact 1 Address Confirmation': 6,
        'Contact 2 Passport': 7,
        'Contact 2 Government Issued ID': 8,
        'Contact 2 Address Confirmation': 9,
        'Contact 3 Passport': 10,
        'Contact 3 Government Issued ID': 11,
        'Contact 3 Address Confirmation': 12,
        'Contact 4 Passport': 13,
        'Contact 4 Government Issued ID': 14,
        'Contact 4 Address Confirmation': 15,
        'Contact 5 Passport': 16,
        'Contact 5 Government Issued ID': 17,
        'Contact 5 Address Confirmation': 18,
      };
      return this.documents.sort((a,b)=>{ return (indexes[a.description] || 100) - (indexes[b.description] || 100); })
    },
  },

  async created () {
    await this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
    await this.$store.dispatch('registrations/get', this.registration.id)
    this.provider = this.complianceScan?.provider || null
  },

  methods: {
    formatStatus(status) {
      return status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'
    },

    async run () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        const item = await this.$store.dispatch('accounts/update', {
          id: this.registration.accounts[this.registration.accounts.length-1].id,
          payload: {
            complianceScan: {
              createdAt: 'CURRENT_TIMESTAMP',
            }
          }
        })

        let action
        if(this.serviceProverName == 'Solid Test' || this.serviceProverName == 'Solid') {
          action = (await this.$store.dispatch('compliance_scan/action', {
            id: item.complianceScan.id,
            action: 'RunScanSolid',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id,
              idv: this.form.idv
            }
          })).action
        }else{
          action = (await this.$store.dispatch('compliance_scan/action', {
            id: item.complianceScan.id,
            action: 'RunScan',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id
            }
          })).action
        }

        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage,
          this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
          this.$toast.success('Compliance scan successfully ran.')
        }

        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async runManual () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        const item = await this.$store.dispatch('accounts/update', {
          id: this.registration.accounts[this.registration.accounts.length-1].id,
          payload: {
            complianceScan: {
              createdAt: 'CURRENT_TIMESTAMP',
            }
          }
        })

        let action
        action = (await this.$store.dispatch('compliance_scan/action', {
          id: item.complianceScan.id,
          action: 'RunScanManual',
          payload: {
            provider: this.serviceProviders?.id,
            registrationId: this.registration.id
          }
        })).action

        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage,
            this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
          this.$toast.success('Compliance scan successfully ran.')
        }

        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async approveManual () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        let action
        action = (await this.$store.dispatch('compliance_scan/action', {
          id: this.complianceScan.id,
          action: 'RunApproveManual',
          payload: {
            provider: this.serviceProviders?.id,
            registrationId: this.registration.id
          }
        })).action

        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage,
            this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
        }

        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async confirmManual () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_scan/action', {
          id: this.complianceScan.id,
          action: 'ConfirmManual',
          payload: {
            provider: this.serviceProviders?.id,
            registrationId: this.registration.id
          }
        })
        this.$store.dispatch('registrations/get', this.registration.id)
        this.$toast.success('Compliance scan successfully confirmed.')
        
        if(this.registration.accounts.length <= 1) {
          await this.$store.dispatch('registrations/action', {
              id: this.registration.id,
              action: 'SetProfile'
            })
          await this.$store.dispatch('profilenotes/load', { params: { registrationId: this.id } })
          this.$toast.success('Profile is set.')
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async setAccount () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('compliance_scan/action', {
            id: this.complianceScan.id,
            action: 'SetAccount',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id
            }
        })).action

        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage
          this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
          this.$toast.success('Compliance scan successfully ran.')
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async update () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_scan/action', {
          id: this.complianceScan.id,
          action: 'UpdateScan',
          payload: {
            provider: this.serviceProviders?.id,
          registrationId: this.registration.id
        }
      })
        this.$store.dispatch('registrations/get', this.registration.id)
        this.$toast.success('Compliance scan successfully updated.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async force () {
      if (!this.isAvailable) return
      this.open = true
    },

    async resubmit () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('compliance_scan/action', {
            id: this.complianceScan.id,
            action: 'ResubmitScan',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id
            }
        })).action
        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage
          this.errorDate = action.errorDate
          this.messages = action.message
          this.providerMessages = action.providerMessage
        }else{
          this.errorAccount = false
          this.$toast.success('Compliance scan successfully updated.')
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async addContacts () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        const action = (await this.$store.dispatch('compliance_scan/action', {
            id: this.complianceScan.id,
            action: 'AddContacts',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id
            }
        })).action
        if(action.error){
          this.errorAccount = true
          this.errorMessage = action.errorMessage,
            this.errorDate = action.errorDate
        }else{
          this.errorAccount = false
          this.$toast.success('Compliance scan successfully updated.')
        }
        this.$store.dispatch('registrations/get', this.registration.id)
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async confirm () {
      if (!this.isAvailable) return
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_scan/action', {
          id: this.complianceScan.id,
          action: 'Confirm',
          payload: {
            provider: this.serviceProviders?.id,
            registrationId: this.registration.id
          }
        })
        this.$store.dispatch('registrations/get', this.registration.id)
        this.$toast.success('Compliance scan successfully confirmed.')

        if(this.registration.accounts.length <= 1) {
          await this.$store.dispatch('registrations/action', {
            id: this.registration.id,
            action: 'SetProfile'
          })
          await this.$store.dispatch('profilenotes/load', { params: { registrationId: this.id } })
          this.$toast.success('Profile is set.')
        }
        
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async reset () {
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('compliance_scan/delete', this.complianceScan.id)
        await this.$store.dispatch('accounts/update', {
          id: this.registration.accounts[this.registration.accounts.length-1].id,
          payload: {
            complianceScan: null
          }
        })
        this.provider = null
        this.$toast.success('Compliance scan successfully reset.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false

        const loader = this.$loading.show()
        try {
          await this.$store.dispatch('compliance_scan/action', {
              id: this.complianceScan.id,
              action: 'ForceScan',
              payload: {
                provider: this.serviceProviders?.id,
            registrationId: this.registration.id
        }
        })
          this.$store.dispatch('registrations/get', this.registration.id)
          this.$toast.success('Compliance scan successfully updated.')
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }

      }else{
        this.pinFailed = true
      }
    },

    pinInput: function(e) {
      if (e.keyCode === 13) {
        this.checkPin()
      }
    },

    cancelPin() {
      this.pin = ''
      this.open = false
      this.pinFailed = false
    },

    viewMessage(contactId, contactName) {
      this.contactId = contactId
      this.contactName = contactName
      this.messageOpen = true
    },

    viewSolidMessage() {
      this.messageOpen = true
    },

    viewSolidBusinessMessage() {
      this.messageOpen = true
      this.business = true
    },

    viewSolidContactMessage(contactName) {
      this.contactName = contactName
      this.business = false
      this.messageOpen = true
    },

    async submitDocument() {
      const loader = this.$loading.show()
      try {
        if(this.serviceProverName == 'Solid Test' || this.serviceProverName == 'Solid') {
          const action = (await this.$store.dispatch('compliance_scan/action', {
            id: this.complianceScan.id,
            action: 'RunSubmitDocumentSolid',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id,
              accountId: this.registration.accounts[this.registration.accounts.length-1].id,
              documentId: this.selectedDocument
            }
         })).action
          if(action.error){
            this.errorAccount = true
            this.errorMessage = action.errorMessage,
              this.errorDate = action.errorDate
            this.messages = action.message
            this.providerMessages = action.providerMessage
          }else{
            this.errorAccount = false
            this.$toast.success('Document successfully ran.')
          }
        }else {
          const action = (await this.$store.dispatch('compliance_scan/action', {
            id: this.complianceScan.id,
            action: 'RunSubmitDocument',
            payload: {
              provider: this.serviceProviders?.id,
              registrationId: this.registration.id,
              accountId: this.registration.accounts[this.registration.accounts.length-1].id,
              documentId: this.selectedDocument
            }
          })).action
          if(action.error){
            this.errorAccount = true
            this.errorMessage = action.errorMessage,
              this.errorDate = action.errorDate
            this.messages = action.message
            this.providerMessages = action.providerMessage
          }else{
            this.errorAccount = false
            this.$toast.success('Document successfully ran.')
          }
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
        this.documentOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 15px 34px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .status {
    font-size: 16px;
  }
}
.n-acc-actions {
  background: #f6f8f9;
  margin: 0 -30px;
  padding: 10px 30px;
}
.comliance-header {
  border-bottom: 1px solid #99aabb;
}
</style>
