<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle" :no-margin="noMargin">
    <textarea 
      v-model="val" 
      :placeholder="`Enter ${label || 'text'}`" 
      :disabled="disabled" 
      @blur="$emit('blur')" 
      :class="classes"
      :rows="rows"
      @input="mixin_autoResize_resize" />
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'
import mixinAutoResize from "./autoresize.js"

export default {
  mixins: [inputMixin, mixinAutoResize],

  props: {
    rows: {
      type: String,
      default: '1'
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  // .full {
  //   width: 100%;
  // }
  // .messageField {
  //   width: 100%;
  //   border: none;
  //   resize: none;
  // }
  // .noUpdate {
  //   background-color: transparent;
  //   border: none; 
  // }
  // .connexNote {
  //   width: 100%; 
  //   outline: none;
  //   border: 1px solid #99aabb;
  //   border-radius: 6px; 
  //   padding: 5px 8px 0;
  //   box-sizing: border-box;
  //   height: auto;  
  //   overflow: hidden;
  //   font-size: 13px;
  //   font-weight: bold;
  //   color: #63717d;
  //   background: white;
  //   resize: none;
  // }
  // .statement-message {
  //   width: 100%; 
  //   outline: none;
  //   border: 1px solid #99aabb;
  //   height: 90px;
  //   box-sizing: border-box;
  //   padding: 8px;
  //   border-radius: 6px;
  //   font-size: 13px;
  //   font-weight: bold;
  //   color: #63717d;
  //   background: white;
  // }

  // textarea:disabled {
  //   background: unset;
  //   border: none;
  //   resize: none;
  // }

  // textarea.noResize {
  //   resize: none;
  // }
</style>