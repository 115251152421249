<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>
        <section>
          <div>
            <h3 class="inner-sub-headers">Profiles</h3>
          </div>
        </section>
        <section  class="content-tlist">
          <table class="content-table text-center">
            <tr>
              <th class="text-center" style="width: 15%">Date</th>
              <th class="text-center" style="width: 15%">Number</th>
              <th class="text-center" style="width: 25%">Name</th>
              <th class="text-center" style="width: 15%">Updated</th>
              <th class="text-center" style="width: 15%">Status</th>
            </tr>
            <template v-if="registrations">
              <tr v-for="index in 5" v-if="registrations[index-1]" :key="index">
                <td><span>Registration Date</span>
                  {{ registrations[index-1].createdAt | date('MM/D/YYYY') }}
                </td>
                <td>
                  <span>Registration</span>
                  <a class="link" :href="`/system-customers/registrations/${registrations[index-1].id}`">{{ registrations[index-1].profileNumber || registrations[index-1].regId }}</a>
                </td>
                <td>
                  <span>Name</span>
                  <template v-if="registrations[index-1].type === 'Personal'">{{ registrations[index-1].data.firstName }} {{ registrations[index-1].data.lastName }}</template>
                  <template v-else>{{ registrations[index-1].data.legal_name }}</template>
                </td>
                <td>
                  <span>Last Update</span>
                  <template v-if="registrations[index-1].accounts[registrations[index-1].accounts.length-1] && registrations[index-1].accounts[registrations[index-1].accounts.length-1].complianceScan">
                    <template v-if="registrations[index-1].accounts[registrations[index-1].accounts.length-1].complianceScan">
                      {{registrations[index-1].accounts[registrations[index-1].accounts.length-1].complianceScan.createdAt | date('MM/D/YYYY hh:mm A')}}
                    </template>
                    <template v-else></template>
                  </template>
                  <template v-else></template>
                </td>
                <td><span>Status</span><strong :class="statusColorClasses[registrations[index-1].status]">{{ registrations[index-1].status }}</strong></td>
              </tr>
              <tr v-else>
                <td colspan="5" class="no-data"></td>
              </tr>
              <tr>
                <td colspan="5" class="view-href">
                  <a href="/system-customers/profiles">>View Profiles</a>
                </td>
              </tr>
            </template>
          </table>
        </section>
        <section>
          <div>
            <h3 class="inner-sub-headers">Accounts</h3>
          </div>
        </section>
        <section  class="content-tlist">
          <table class="content-table text-center">
            <tr>
              <th class="text-center" style="width: 15%">Account</th>
              <th class="text-center" style="width: 15%">Name</th>
              <th class="text-center" style="width: 15%">Available</th>
              <th class="text-center" style="width: 15%">Balance</th>
              <th class="text-center" style="width: 15%">Status</th>
            </tr>
            <template v-if="filteredAccounts">
              <tr v-for="index in 5" v-if="filteredAccounts[index-1]" :key="index">
                <td>
                  <span>Account</span>
                  <a class="link" :href="`/system-customers/registrations/${filteredAccounts[index-1].regId}`">{{ filteredAccounts[index-1].accountNumber }}</a>
                </td>
                <td>
                  <span>Name</span>
                  {{ filteredAccounts[index-1].regName }}
                </td>
                <td><span>Available</span>{{ filteredAccounts[index-1].systemAvailableBalance | balance }}</td>
                <td><span>Balance</span>{{ filteredAccounts[index-1].systemTotalBalance | balance }}</td>
                <td><span>Account Status</span><strong :class="statusAccountColorClasses[getStatus(filteredAccounts[index-1].accountStatus)]">
                  {{ getStatus(filteredAccounts[index-1].accountStatus) }}</strong></td>
              </tr>
              <tr v-else>
                <td colspan="5" class="no-data"></td>
              </tr>
              <tr>
                <td></td>
                <td>Account Totals</td>
                <td>{{ totalBalance | balance }}</td>
                <td>{{ totalAvailable | balance }}</td>
                <td class="view-href">
                  <a href="/system-customers/accounts">>View Accounts</a>
                </td>
              </tr>
            </template>
          </table>
        </section>
        <section>
          <div>
            <h3 class="inner-sub-headers">Transactions</h3>
          </div>
        </section>
        <section  class="content-tlist">
          <table class="content-table text-center">
            <tr>
              <th class="text-center" style="width: 15%">Date</th>
              <th class="text-center" style="width: 15%">Number</th>
              <th class="text-center" style="width: 25%">Entry</th>
              <th class="text-center" style="width: 15%">Amount</th>
              <th class="text-center" style="width: 15%">Status</th>
            </tr>
            <template v-if="transactions">
              <tr v-for="index in 5" :key="index" v-if="transactions[index-1]">
                <td><span>Transaction Date</span>
                  {{ transactions[index-1].createdAt | date('MM/D/YYYY hh:mm A') }}
                </td>
                <td><span>Transaction</span>{{ transactions[index-1].transactionId }}</td>
                <td><span>Entry</span>{{ transactions[index-1].entryType == 'Deposit'? 'DEP' : 'W/D' }}</td>
                <td><span>Amount</span>
                  <template v-if="transactions[index-1].currencyType == 'Assets'">{{ parseFloat(transactions[index-1].assetAmount) }}</template>
                  <template v-else>{{ transactions[index-1].currencySymbol }} {{ transactions[index-1].amount | balance_without_symbol }}</template>
                </td>
                <td><span>Status</span><strong :class="statusTransactionColorClasses[transactions[index-1].status]">{{ transactions[index-1].status }}</strong></td>
              </tr>
              <tr v-else>
                <td colspan="5" class="no-data"></td>
              </tr>
              <tr>
                <td colspan="5" class="view-href">
                  <a href="/transactions/reporting">>View Transactions</a>
                </td>
              </tr>
            </template>
          </table>
        </section>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  import DateTimePicker from 'vue-vanilla-datetime-picker';

  export default {
    name: 'AdminDashboard',

    components: {
      VueEnglishdatepicker,
      DateTimePicker
    },

    data: () => ({
      registrations: null,
      registrationsProfiles: null,
      registrationsAccounts: null,
      transactions: null,
      totalBalance: null,
      totalAvailable: null,
    }),

    computed: {
      customerStatuses () {
        return window.customerStatuses
      },

      filteredAccounts () {
        let accounts = this.registrationsAccounts || []
        return accounts.sort((a, b) => {
          if (Number(a.systemTotalBalance) < Number(b.systemTotalBalance)) return 1
          if (Number(a.systemTotalBalance) > Number(b.systemTotalBalance)) return -1
          return 0
        })
      },

      statusColorClasses () {
        return {
          New: 'c1',
          Pending: 'c2',
          'In Process': 'c2',
          Denied: 'c4',
          Complete: 'c3',
          Active: 'c1'
        }
      },

      statusProfileColorClasses () {
        return {
          Active: 'c1',
          Restricted: 'c2',
          Complete: 'c2',
          Suspended: 'c2',
          Dormant: 'c3',
          Closed: 'c1'
        }
      },

      statusAccountColorClasses () {
        return {
          Open: 'c1',
          Restricted: 'c2',
          Pending: 'c2',
          Closed: 'c4',
          Dormant: 'c3',
          Hold: 'c2'
        }
      },

      statusTransactionColorClasses () {
        return {
          New: 'c1',
          Pending: 'c2',
          Review: 'c2',
          'On Hold': 'c2',
          Denied: 'c4',
          Complete: 'c1',
          Reversed: 'c3',
          Cancelled: 'c3',
          Error: 'c3'
        }
      },
    },

    async created () {
      await this.refreshRegistrations()
    },

    methods: {
      async refreshRegistrations () {
        const loader = this.$loading.show()
        this.loading=true;
        let params = {}
        params.corporate = ['!=', '1']
        params.status=["OR", "New", "Pending", "Denied", "In Process"];
        let paramstrans = {}
        paramstrans.accountId = ['not like', '9%'];

        var awaits=[
          this.$store.dispatch('registrations/load', {params, skip: ['user', 'contact', 'fees', 'terms', 'serviceProviders', 'confirmation', 'documentsChecklist', 'documents', 'primeAccount', 'createdBy', 'profileSettings'], order: ['createdAt:DESC'], limit:5}),
          this.$store.dispatch('registrations/load', { params: {'status':['OR', 'Complete']}, skip: ['user', 'contact', 'fees', 'terms', 'serviceProviders', 'confirmation', 'documentsChecklist', 'documents', 'primeAccount', 'createdBy', 'profileSettings', 'accounts'], order: ['approvalDate:DESC'], limit:5 }),
          api.action("Account", "new", "totalBalance"),
          this.$store.dispatch('transactions/load', { order: ['transactionId:DESC'], params: {'accountId': ['not like', '9%']}, limit:5, skip: ["regName", "regId", "walletAddress", "qPaymentReference", ] }),
        ];
        var results=await Promise.all(awaits);
        
        this.registrations = results[0]
        this.registrationsProfiles = results[1]
        this.registrationsAccounts = results[2].action.top5;
        this.transactions = results[3]
        this.totalBalance = results[2].action.totalBalance;
        this.totalAvailable = results[2].action.totalAvailable;
        this.loading=false;
        loader.hide()
      },

      getStatus (status) {
        const names = {
          Complete: 'Pending',
          Active: 'Open'
        }
        return names[status] || status
      },
    },
  }
</script>
<style lang="scss" scoped>
  .inner-sub-headers {
    font-size: 20px;
    color: #1f2d4e;
    padding: 10px;
    padding-top: 24px;
    padding-left: 30px;
  }

  .information {
    display: flex;
    justify-content: space-between;

    .sub {
      width: 50%;
    }
  }
  
  .view-href {
    text-align: right;
  }

  .content-table strong.c1 {
    color: #13ce67;
  }
  .content-table strong.c2 {
    color: #ffa700;
  }
  .content-table strong.c3 {
    color: #41566c;
  }
  .content-table strong.c4 {
    color: #e23434;
  }
  .content-table td {
    font-weight: 400;
  }
  .content-table .link {
    color: #51aeff;
  }
</style>
