<template>
  <div>
    <template v-if="$hasPerm('partners-update')">
      <button :disabled="!selected || !selected.activationCode" class="btn btn-success" @click="resetActivationCode()">Reset Activation Code</button>
      <button :disabled="!selected || selected.activationCode" class="btn btn-success" @click="resetPassword()">Reset Password</button>
      <button v-if="selected" :disabled="!selected" class="btn btn-block" @click="toggleBlock()">{{ selected.active === 'Blocked' ? 'Unblock' : 'Block' }}</button>
      <button v-if="partner.status != 'Closed'" :disabled="partner.status == 'Dormant'" class="btn btn-primary" @click="openAddUser()">Add Sub-User</button>
      <button
        :disabled="!selected || selected.id === partner.user.id"
        class="btn btn-primary"
        @click="remove()">
        Remove Sub-User
      </button>
    </template>

    <section  class="content-tlist" style="margin: 30px 0;">
      <table class="content-table">
        <tr>
          <th></th>
          <th>Username</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Activation Code</th>
          <th>Activation Code Expires</th>
          <th>Activation Date</th>
          <th>Reset Pass Request</th>
          <th>Reset Pass Complete</th>
          <th>Blocked</th>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <td><span></span>
            <input type="radio" @change="onCbxClicked($event, user)" name="access-user">
          </td>
          <td><span>Username</span>{{ user.username }}</td>
          <td><span>First Name</span>{{ user.firstName }}</td>
          <td><span>Last Name</span>{{ user.lastName }}</td>
          <td><span>Activation Code</span>
            <em v-if="user.activationCode === 'Expired'" class="c4">{{ user.activationCode }}</em>
            <template v-else>{{ user.activationCode || 'N/A' }}</template>
          </td>
          <td><span>Activation Code Expires</span>{{ user.activationCodeExpiryDate | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Activation Date</span>{{ user.activationDate | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Reset Pass Request</span>{{ user.resetPasswordRequestedDateTime | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Reset Pass Complete</span>{{ user.resetPasswordCompletedDateTime | date('MM/D/YYYY hh:mm A') }}</td>
          <td><span>Blocked</span>{{ user.active === 'Blocked' ? 'Yes' : 'No' }}</td>
        </tr>
      </table>
    </section>

    <popup :open.sync="addModal">
      <template v-if="addModal">
        <h4 class="popup-title">Add Sub-User</h4>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row form-split">
              <form-field-text v-model="form.firstName" :maxlength="25" :validator="$v.form.firstName" label="First Name" required />
              <form-field-text v-model="form.lastName" :maxlength="25" :validator="$v.form.lastName" label="Last Name" required />
            </div>
            <div class="form-row form-split">            
              <form-field-text v-model="form.email" :validator="$v.form.email" label="Email Address" required />
              <form-field-text v-model="form.phone" :maxlength="15" :validator="$v.form.phone" label="Phone Number" required />
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="main" @click="add()">Add</button>
          <button class="second" @click="addModal = false">Cancel</button>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { alphaNum } from '../../lib/validators'
import { required, numeric, email } from 'vuelidate/lib/validators'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      },

      selected: null,
      addModal: false
    }
  },

  computed: {
    users () {
      return this.$store.getters['users/list'].filter(user => parseInt(user.id) === parseInt(this.partner.user.id) || parseInt(user.subuserOf) === parseInt(this.partner.user.id))
    }
  },

  created () {
    if (this.partner?.user?.id) {
      this.$store.dispatch('users/get', this.partner.user.id)
      this.$store.dispatch('users/load', { params: { subuserOf: this.partner.user.id } })
    }
  },

  methods: {
    async remove () {
      if (!this.selected || this.selected.id === this.partner.user.id) return
      if (!confirm('Are you sure you want to remove selected Sub-User?')) return
      
      const loader = this.$loading.show()
      try {
        await this.$store.dispatch('users/delete', this.selected.id)
        this.$toast.success('Sub-User removed successfully.')
        this.selected = null
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    openAddUser () {
      this.addModal = true
      this.form = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      }
      this.$v.$reset()
    },

    async add () {
      this.$v.$touch()
      if (this.$v.$invalid) return null

      const loader = this.$loading.show()
      try {
        const response = await this.$store.dispatch('registrations/action', {
          id: this.registration.id,
          action: 'CreateSubUser',
          payload: {
            ...this.form,
            regId: this.registration.id
          }
        })
        this.$store.commit('users/add', response.action)
        this.$store.commit('users/setSingle', response.action)
        this.$toast.success('Sub-User added successfully.')
        this.addModal = false
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async toggleBlock () {
      if (!this.selected) return
      const toBlock = this.selected.active !== 'Blocked'
      if (!confirm(`Are you sure you want to ${toBlock ? 'block' : 'unblock'} selected user?`)) return null

      const loader = this.$loading.show()
      try {
        if (parseInt(this.selected.id) !== this.registration.user.id) {
          const user = await this.$store.dispatch('users/action', { id: this.selected.id, action: toBlock ? 'Block' : 'Unblock' })
          this.$toast.success(`Selected user is now ${toBlock ? 'blocked' : 'unblocked'}.`)
          this.selected = user.item
          this.$forceUpdate()
        } else {
          this.$toast.error('Can\'t perform this action on selected user.')
        }
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async resetPassword () {
      if (!this.selected) return
      if (!confirm('Are you sure you want to reset password for selected user?')) return null

      const loader = this.$loading.show()
      try {
        if (this.selected.active === 'Yes') {
          await this.$store.dispatch('users/action', {
            id: this.selected.id,
            action: 'ResetPassword'
          })
        }
        this.$toast.success('Password has been successfully reset for selected user.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async resetActivationCode () {
      if (!this.selected) return
      if (!confirm('Are you sure you want to reset activation code for selected user?')) return null

      const loader = this.$loading.show()
      try {
//         if (this.selected.active === 'No') {
          await this.$store.dispatch('users/action', {
            id: this.selected.id,
            action: 'ResetActivationCode'
          })
//         }
        this.$toast.success('Activation Code has been successfully reset for selected user.')
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    onCbxClicked (event, user) {
      this.selected = event.target.checked ? user : null
    }
  },

  validations: {
    form: {
      firstName: { required, alphaNum },
      lastName: { required, alphaNum },
      phone: { required, numeric },
      email: { required, email },
    }
  }
}
</script>

