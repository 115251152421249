<template>
  <div>
    <section>
      <div>
        <h2 class="inner-headers"></h2>
      </div>
    </section>


    <section class="content-filters">
      <basic-form>
        <h4>Connex Connection Manager</h4>
        <div class="cof-row" style="margin-bottom: 70px;">
          <mnb-select v-model="connexForm.connex_connect" :options="yesNoOptions" :disabled="!updateConnex" label="Connex Connect" />
          <mnb-input v-model="connexForm.version" :disabled="!updateConnex" label="Version" />
          <mnb-input v-model="connexForm.username" :disabled="!updateConnex" label="Username" />
          <mnb-input v-model="connexForm.code" :disabled="!updateConnex" label="Key" />
          <mnb-select v-model="connexForm.status" :options="statusOptions" :disabled="!updateConnex" label="Status" />
          <mnb-textarea
            v-model="connexForm.notes"
            label="Notes"
            :disabled="!updateConnex"
            @input="mixin_autoResize_resize" 
            :classes="{ 'connexNote': true}"
            ref="notesTextarea"
            style="flex-grow: 1;"/>
          <div class="cof-field mobinl" style="max-width: 100px;">
            <label>&nbsp;</label>
            <button v-if="!updateConnex" class="main" @click="updateConnexConnection()" style="min-width: 100px; margin-left: 20px">Update</button>
            <button v-else class="main save-connex" @click="saveConnex()" style="min-width: 100px; margin-left: 20px; background-color: #67c23a;">Save</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetFieldsConnex()">Reset Fields</button>
          </div>    
        </div>

        <!--<div class="cof-row"> -->
          <!--<div class="cof-field cof-type">-->
            <!--<mnb-select v-model="form.type" :validator="$v.form.type" :options="typeOptions" label="Type" />-->
          <!--</div>-->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-select v-model="form.provider" :options="providerOptions" :validator="$v.form.provider" label="Provider" />-->
          <!--</div> -->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-input v-model="form.version" :validator="$v.form.version" label="Version" />-->
          <!--</div> -->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-input v-model="form.username" :validator="$v.form.username" label="Username" />-->
          <!--</div> -->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-input v-model="form.password" label="Password" />-->
          <!--</div> -->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-input v-model="form.key" label="Key" />-->
          <!--</div> -->
          <!--<div class="cof-field sysint-page">-->
            <!--<mnb-input v-model="form.notes" label="Notes" />-->
          <!--</div>                  -->
        <!--</div>-->
        <!--<div class="cof-row">                          -->
          <!--<div class="cof-field mobinl">-->
            <!--<label>&nbsp;</label>-->
            <!--<button class="main" @click="save()">Add New</button>-->
          <!--</div>-->
          <!--<div class="cof-field mobinl">-->
            <!--<label>&nbsp;</label>-->
            <!--<button class="second" @click="resetFields()">Reset Fields</button>-->
          <!--</div>             -->
        <!--</div>-->
      </basic-form>
    </section>
    <!--<section  class="content-tlist">-->
      <!--<table class="content-table">           -->
        <!--<tr>-->
          <!--<th>Type</th>              -->
          <!--<th>Provider</th>-->
          <!--<th>Version</th>              -->
          <!--<th>Date</th>-->
          <!--<th>Username</th>-->
          <!--<th>Password</th>-->
          <!--<th>Key</th>              -->
          <!--<th>Status</th>-->
          <!--<th>Notes</th>-->
          <!--<th>Options</th>                          -->
        <!--</tr>-->
        <!--<tr v-for="(integration, i) in integrations" :key="i">-->
          <!--<td><span>Type</span>{{ formatType(integration.type) }}</td>               -->
          <!--<td><span>Provider</span><a >{{ integration.provider }}</a></td>-->
          <!--<td><span>Version</span>{{ integration.version }}</td>              -->
          <!--<td><span>Date</span>{{ integration.createdAt | date('LL') }}</td>-->
          <!--<td><span>Username</span>{{ integration.username }}</td>-->
          <!--<td><span>Password</span>{{ integration.password }}</td>-->
          <!--<td><span>Key</span>{{ integration.code }}</td>              -->
          <!--<td>-->
            <!--<span>Status</span>-->
            <!--<em -->
              <!--:class="{ c1: integration.status == 'Active', c2: integration.status == 'Review', c3: integration.status === 'Testing'}"-->
              <!--@click="changeStatus(integration.id)">-->
              <!--{{ formatStatus(integration.status) }}-->
            <!--</em>-->
          <!--</td>-->
          <!--<td><span>Notes</span>{{ integration.notes }}</td>-->
          <!--<td><span>Options</span>n/a</td>          -->
        <!--</tr>-->
      <!--</table>-->
    <!--</section>-->
    <popup :open.sync="open">
      <h2>Update Status</h2>
      <div class="pf-field status-options">
        <select v-model="status">
          <option value="Review">Review</option>
          <option value="Testing">Testing</option>
          <option value="Active">Active</option>
          <option value="Disabled">Disabled</option>
        </select>
      </div>
      <div class="pf-row buttons">
        <a href="" class="pop-btn" @click.prevent="open=false">Cancel</a>
        <button class="pop-btn" @click.prevent="updateStatus()">Confirm</button>
      </div>
    </popup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import mixinAutoResize from "../../components/ui/forms/autoresize.js"

import BasicForm from '../../components/ui/forms/Form'
import MnbInput from '../../components/ui/forms/Input'
import MnbSelect from '../../components/ui/forms/Select'
import Popup from '../popup'
import MnbTextarea from '../../components/ui/forms/Textarea'

export default {
  name: 'integrations-management',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    Popup,
    MnbTextarea
  },

  data () {
    return {
      open: false,

      form: {
        type: null,
        provider: null,
        version: null,
        username: null,
        password: null,
        key: null,
        notes: null
      },

      connexForm: {
        connex_connect: null,
        version: null,
        username: null,
        code: null,
        status: null,
        notes: null
      },

      updateConnex: false,

      status: 'Review',
      integrationId: '',

      typeOptions: [
        {
          id: 'payments',
          text: 'Payments'
        },
        {
          id: 'manage',
          text: 'Manage'
        },
        {
          id: 'reports',
          text: 'Reports'
        },
        {
          id: 'compliance',
          text: 'Compliance'
        },
        {
          id: 'flyBoard',
          text: 'FlyBoard'
        }
      ],

      statusOptions: [
        {
          id: 'Review',
          text: 'Review'
        },
        {
          id: 'Testing',
          text: 'Testing'
        },
        {
          id: 'Active',
          text: 'Active'
        },
        {
          id: 'Disabled',
          text: 'Disabled'
        }
      ],

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ]
    }
  },

  computed: {
    integrations () {
      return this.$store.state.integration.data
    },

    providers () {
      return this.$store.state.providersList.data || []
    },

    providerOptions () {
      return this.providers.map(provider => {
        return {
          id: provider.provider_name,
          text: provider.provider_name
        }
      })
    }
  },

  async created () {
    await this.$store.dispatch('connex/load')
    this.connexForm = this.$store.state.connex.data[0]
    this.$store.dispatch('integration/load', { order: ['createdAt:DESC'] })
    this.$store.dispatch('providersList/load', { order: ['createdAt:DESC'] })
  },

  watch: {
    'form.provider' (value) {
      if (value) {
        this.form.version = this.providers.find( item => item.provider_name === value).version
      }
    },

    'connexForm.notes' (value) {
      if (value) {
        this.$refs.notesTextarea.$el.children[1].value = value
        this.$el.getElementsByTagName('textarea')[0].style.height = `${this.$el.getElementsByTagName('textarea')[0].scrollHeight}px`
      } else {
        this.$el.getElementsByTagName('textarea')[0].style.height = '29px'
      }
    },
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = {
            createdAt: 'CURRENT_TIMESTAMP',
            type: this.form.type,
            provider: this.form.provider,
            version: this.form.version,
            username: this.form.username,
            password: this.form.password,
            code: this.form.key,
            notes: this.form.notes,
            status: this.status
          }
          
          await this.$store.dispatch('integration/create', payload)
          Object.keys(this.form).map(item => this.form[item] = null)
          this.$toast.success('Provider successfully created')
          this.$v.$reset()
        } catch {
          this.$toast.error(window.errorMessage)
        }
      }
    },

    async saveConnex () {
      try {
        const payload = {
          connex_connect: this.connexForm.connex_connect,
          version: this.connexForm.version,
          username: this.connexForm.username,
          code: this.connexForm.code,
          status: this.connexForm.status,
          notes: this.connexForm.notes
        }
        
        await this.$store.dispatch('connex/update', { id: 1, payload })
        this.$toast.success('Connex connection successfully updated')
        this.updateConnex = false
      } catch {
        this.$toast.error(window.errorMessage)
      }
    },

    changeStatus(id) {
      this.open = true
      this.integrationId = id
      const integrationChange = this.integrations.find(integration => integration.id === id)
      this.form.type = integrationChange.type,
      this.form.provider =  integrationChange.provider,
      this.form.version =  integrationChange.version,
      this.form.username =  integrationChange.username,
      this.form.password =  integrationChange.password,
      this.form.checks =  integrationChange.checks,
      this.form.code =  integrationChange.code,
      this.form.notes =  integrationChange.notes
    },

    async updateStatus () {
      try {
        const payload = {
          createdAt: 'CURRENT_TIMESTAMP',
          type: this.form.type,
          provider: this.form.provider,
          version: this.form.version,
          username: this.form.username,
          password: this.form.password,
          code: this.form.key,
          notes: this.form.notes,
          status: this.status
        }
          await this.$store.dispatch('integration/update', { id: this.integrationId, payload })
          this.$toast.success('Status successfully updated')
          this.open = false
        } catch {
          this.$toast.error(window.errorMessage)
        }
    },

    resetFields() {
      Object.keys(this.form).map(item => this.form[item] = null)
    },

    resetFieldsConnex() {
      Object.keys(this.connexForm).map(item => this.connexForm[item] = null)
    },

    formatType(type) {
      return this.typeOptions.find(item => item.id === type).text
    },

    formatStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1)
    },

    updateConnexConnection() {
      this.updateConnex = true
    }
  },

  validations () {
    const rules = {
      form: {
        type: { required },
        provider: { required },
        version: { required },
        username: { required }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .cof-field {
    min-width: 120px; 
    margin-right: 5px; 
  }
  .cof-type {
    min-width: 200px; 
  }
  // .status-options {
  //   margin-bottom: 30px; 
  // }
</style>